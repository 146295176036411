import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { generateString, titleCaseToReadable } from 'src/utils/utility';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './DealDetailModal.css';
import Input from '../Form/Input';
import {
  AddPaymentInvoicesInput,
  PaymentStatus,
  UserRole,
  useGenerateInvoiceMutation,
  useGetGsTforinvoiceLazyQuery,
  useGetInvoiceQuery,
  useInvoiceDetailsQuery,
  useSendLazyQuery,
  useUpdateLeadPaymentInvoiceMutation
} from 'src/generated/hooks_and_more';
import { useToasts } from 'react-toast-notifications';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
import { environments } from 'src/config';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1
};

const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  cursor: 'pointer'
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
export default function PaymentDetailSubTable({
  metadata,
  reconciliation,
  invoiceId
}: any) {
  const [generateInvoice] = useGenerateInvoiceMutation();
  const [updatePaymentForInvoice, { loading: receiptUploading }] =
    useUpdateLeadPaymentInvoiceMutation();
  const { data } = useGetInvoiceQuery({
    variables: { id: metadata?.leadId }
  });
  const { addToast } = useToasts();
  const [checked, setChecked] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [share, setShare] = React.useState(false);
  const [receipt, setReceipt] = React.useState(false);
  const [receiptDetails, setReceiptDetails] = React.useState(null);
  const [generatedInvoiceId, setGeneratedInvoice] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleShareModal = () => setShare(false);
  const handleReceiptModal = () => setReceipt(false);
  const [checkValue, setcheckValue] = React.useState('');
  const { loggedInUser } = useLoggedInUser();

  const [getLeadgst, { data: gstData, loading }] =
    useGetGsTforinvoiceLazyQuery();
  React.useEffect(() => {
    if (receiptDetails?.state) {
      getLeadgst({
        variables: {
          state: `/.*${receiptDetails?.state}.*/i`
        },
        onCompleted(data) {
          setcheckValue(data?.queryActiveStateWithGSTN?.[0]?.GSTNumber);
        }
      });
    }
  }, [receiptDetails]);

  const [getInvoice, { data: invoice }] = useSendLazyQuery();
  function handleSendMessage() {
    getInvoice({
      variables: { id: invoiceId as string, env: environments },
      onCompleted(data) {
        addToast('SMS has been sent successfully.', {
          appearance: 'success',
          autoDismiss: true
        });
        handleShareModal();
      },
      onError(err) {
        addToast('Something went wrong.', {
          appearance: 'error',
          autoDismiss: true
        });
        handleShareModal();
      }
    });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="checkFOrttt">
      <p
        onClick={() => {
          handleOpen();
        }}
        style={{ color: 'blue', cursor: 'pointer', fontWeight: 'bold' }}
      >
        View{' '}
      </p>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        style={{ zIndex: 150 }}
      >
        <Box
          sx={style}
          style={{ overflow: 'auto', maxHeight: '80%', zIndex: 150 }}
        >
          <Box sx={closeBtnStyle}>
            <Typography
              id="custom-underline"
              fontWeight="bold"
              sx={{ fontSize: '22px' }}
            >
              {metadata?.title ? titleCaseToReadable(metadata?.title) : '-'}
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          {metadata?.data?.map((item, index) =>
            item.type === 'loan_ignore' ? null : (
              <>
                <StyledTableRow>
                  <StyledTableCell
                    id="keep-mounted-modal-title"
                    sx={{ width: '300px' }}
                  >
                    {item.key}
                  </StyledTableCell>
                  {item?.key === 'Payment Receipt' &&
                  item?.receiptDetails?.status === PaymentStatus.Approved ? (
                    <StyledTableCell>
                      <Box sx={{ display: 'flex', gap: '20px' }}>
                        <Link
                          sx={{
                            cursor: 'pointer',
                            textDecoration: 'underline'
                          }}
                          href={`/invoice/${invoiceId}`}
                          target="_blank"
                        >
                          View Receipt
                        </Link>
                        {loggedInUser?.role === UserRole.CentreManager && (
                          <Link
                            sx={{
                              cursor: 'pointer',
                              textDecoration: 'underline'
                            }}
                            onClick={() => setShare(true)}
                          >
                            Share
                          </Link>
                        )}
                      </Box>
                    </StyledTableCell>
                  ) : item?.type === 'url' && item.value !== '-' ? (
                    <StyledTableCell
                      id="keep-mounted-modal-description"
                      sx={{ width: '300px' }}
                    >
                      <Link
                        href={`${item.value}`}
                        style={{
                          color: 'rgb(17,25,42)',
                          textDecoration: 'underline'
                          // display: 'flex',
                          // justifyContent: 'flex-start'
                        }}
                        underline="hover"
                        target="_blank"
                      >
                        View Document
                      </Link>
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell
                      id="keep-mounted-modal-description"
                      sx={{ width: '300px' }}
                    >
                      {item.value ?? '-'}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              </>
            )
          )}
          <Box sx={closeBtnStyle}>
            <Typography
              id="custom-underline"
              fontWeight="bold"
              sx={{ fontSize: '22px' }}
            >
              {reconciliation?.title
                ? titleCaseToReadable(reconciliation?.title)
                : '-'}
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          {reconciliation?.data?.map((item, index) => (
            <>
              <StyledTableRow>
                <StyledTableCell
                  id="keep-mounted-modal-title"
                  sx={{ width: '300px' }}
                >
                  {item.key}
                </StyledTableCell>

                {item?.type === 'url' && item.value !== '-' ? (
                  <StyledTableCell
                    id="keep-mounted-modal-description"
                    sx={{ width: '300px' }}
                  >
                    <Link
                      href={`${item.value}`}
                      style={{
                        color: 'rgb(17,25,42)',
                        textDecoration: 'underline'
                        // display: 'flex',
                        // justifyContent: 'flex-start'
                      }}
                      underline="hover"
                      target="_blank"
                    >
                      View Document
                    </Link>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell
                    id="keep-mounted-modal-description"
                    sx={{ width: '300px' }}
                  >
                    {item.value ?? '-'}
                  </StyledTableCell>
                )}
              </StyledTableRow>
            </>
          ))}
        </Box>
      </Modal>

      <Modal
        open={share}
        onClose={handleShareModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '300px', margin: 0, padding: 0 }}>
          <Box sx={{ ...closeBtnStyle, justifyContent: 'flex-end', margin: 0 }}>
            <CloseIcon
              onClick={() => {
                handleShareModal();
              }}
            />
          </Box>
          <Grid p={1}>
            <h5 style={{ textAlign: 'center', paddingTop: '4px' }}>
              {' '}
              Customer's copy Confirmation
            </h5>
          </Grid>
          <hr style={{ height: '1px', padding: 0, margin: 0 }}></hr>

          {/* <FormGroup >
            <FormControlLabel style={{textAlign: 'center', padding: '0px 0px 0px 20px'}} control={<Checkbox />} label="Send via SMS" />
          </FormGroup> */}
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              paddingLeft: 1.5,
              justifyContent: 'flex-start'
            }}
          >
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Grid pt={1}>
              <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                {' '}
                Send Via SMS
              </Typography>
            </Grid>
          </Box>

          <hr style={{ height: '1px', padding: 0, margin: 0 }}></hr>

          <Box sx={{ display: 'flex', gap: '20px', padding: 1.5 }}>
            <Button
              variant="contained"
              fullWidth
              style={{ background: '#cac5c5', color: 'white' }}
              onClick={handleShareModal}
            >
              No
            </Button>
            <Button
              disabled={!checked}
              style={
                !checked
                  ? { background: '#cac5c5', color: 'white' }
                  : { background: '#5569ff', color: 'white' }
              }
              variant="contained"
              fullWidth
              color="primary"
              onClick={handleSendMessage}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
