import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import {
  LeadRef,
  useGetDistrictsByStateLazyQuery,
  useGetAllStateQuery,
  useGetAllActiveStateQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import CtaButton from '../CTA_Button/CtaButton';
import { BookingDetailsAddedSchema } from '../../ValidationSchema/ValidationSchema';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { ActionType } from 'src/constants/constant';
import Input from 'src/components/Form/Input';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { titleCaseToReadable } from 'src/utils/utility';
import { boolean } from 'yup';

function BookingDetailsAdded(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    onPressOfDesiredOrUndesiredButton
  } = props;
  const { data: stateOption } = useGetAllActiveStateQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
    }
  });

  const [getDistricts, { data: districtOptions }] =
    useGetDistrictsByStateLazyQuery();
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      customerName: null,
      customerNo: null,
      state: null,
      district: null,
      aadharNo: null,
      aadharFrontImage: null,
      aadharBackImage: null,
      panNumber: null,
      panImage: null,
      voterIdNo: '',
      voterIdImage: null,
      rcTransfer: 'yes',
      insurance: 'yes',
      bookingDocument: null
    },
    enableReinitialize: true,
    validationSchema: BookingDetailsAddedSchema,
    onSubmit: (values) => {
      leadInput = {
        ...leadInput,
        activeBooking: {
          isInsuranceReq: values?.insurance === 'yes' ? true : false,
          isRCTransferReq: values?.rcTransfer === 'yes' ? true : false,
          vehicleTransferFormUrl: values?.bookingDocument,
          customer: {
            name: values?.customerName,
            phoneNo: values?.customerNo,
            customerState: { state: values?.state?.name },
            customerDistrict: { name: values?.district?.district_name },

            identificationDocuments: {
              aadhaarFrontPhotoUrl: values?.aadharFrontImage,
              aadhaarBackPhotoUrl: values?.aadharBackImage,
              aadhaarNumber: values?.aadharNo,
              panNumber: values?.panNumber,
              panPhotoUrl: values?.panImage,
              voterIdNumber: values?.voterIdNo ?? '',
              voterIdPhotoUrl: values?.voterIdImage ?? ''
            }
          }
        }
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Input
            placeholder="Customer Name*"
            onChange={(e) => {
              formik.setFieldValue('customerName', e.target.value);
            }}
            value={formik.values.customerName}
          />
          {formik.errors.customerName && formik.touched.customerName ? (
            <div style={{ color: 'red' }}>{formik.errors?.customerName}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            placeholder="Mobile Number*"
            onChange={(e) => {
              formik.setFieldValue('customerNo', e.target.value);
            }}
            value={formik.values.customerNo}
          />
          {formik.values.customerNo &&
          (formik.errors.customerNo || formik.touched.customerNo) ? (
            <div style={{ color: 'red' }}>{formik.errors?.customerNo}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            onChange={(value) => {
              formik.setFieldValue('state', value);
              formik.setFieldValue('district', null);

              getDistricts({ variables: { id: Number(value?.id) } });
            }}
            value={formik?.values?.state}
            options={
              stateOption?.getStateInfo?.data.map((i) => ({
                label: titleCaseToReadable(i?.state_name),
                name: i?.state_name,
                id: i?.state_id
              })) ?? []
            }
            placeholder="Select State"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            onChange={(value) => {
              formik.setFieldValue('district', value);
            }}
            value={formik.values.district}
            options={districtOptions?.getDistrictByStateInfo?.data ?? []}
            optionString="district_name"
            placeholder="Select Districts"
            disabled={!formik.values.state}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Aadhar Card
          </Typography>
          <Input
            placeholder="Aadhar Number*"
            onChange={(e) => {
              formik.setFieldValue('aadharNo', e.target.value);
            }}
            value={formik.values.aadharNo}
          />
          {formik.values.aadharNo &&
          (formik.errors.aadharNo || formik.touched.aadharNo) ? (
            <div style={{ color: 'red' }}>{formik.errors?.aadharNo}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="aadharFrontImage"
            value={formik.values.aadharFrontImage}
            onChange={(value) => {
              formik.setFieldValue('aadharFrontImage', value);
            }}
            placeholder="Front Side*"
          />
          {formik.errors.aadharFrontImage && formik.touched.aadharFrontImage ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.aadharFrontImage}
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="aadharBackImage"
            value={formik.values.aadharBackImage}
            onChange={(value) => {
              formik.setFieldValue('aadharBackImage', value);
            }}
            placeholder="Back Side*"
          />
          {formik.errors.aadharBackImage && formik.touched.aadharBackImage ? (
            <div style={{ color: 'red' }}>{formik.errors?.aadharBackImage}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            PAN Card
          </Typography>
          <Input
            placeholder="PAN Number*"
            onChange={(e) => {
              formik.setFieldValue('panNumber', e.target.value);
            }}
            value={formik.values.panNumber}
          />
          {formik.errors.panNumber && formik.touched.panNumber ? (
            <div style={{ color: 'red' }}>{formik.errors?.panNumber}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="panImage"
            value={formik.values.panImage}
            onChange={(value) => {
              formik.setFieldValue('panImage', value);
            }}
            placeholder="PAN card image*"
          />
          {formik.errors.panImage && formik.touched.panImage ? (
            <div style={{ color: 'red' }}>{formik.errors?.panImage}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Voter ID card (Optional)
          </Typography>
          <Input
            placeholder="Voter Id Number"
            onChange={(e) => {
              formik.setFieldValue('voterIdNo', e.target.value);
            }}
            value={formik.values.voterIdNo}
          />
          {formik.errors.voterIdNo && formik.touched.voterIdNo ? (
            <div style={{ color: 'red' }}>{formik.errors?.voterIdNo}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12} >
          <FileUploadToS3
            accept="image/*,.pdf"
            id="voterIdImage"
            value={formik.values.voterIdImage}
            onChange={(value) => {
              formik.setFieldValue('voterIdImage', value);
            }}
            placeholder="Voter Id image*"
          />
          {formik.errors.voterIdImage && formik.touched.voterIdImage ? (
            <div style={{ color: 'red' }}>{formik.errors?.voterIdImage}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl>
            <Typography variant="h4">Is RC transfer required?*</Typography>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={'yes'}
              sx={{
                width: '300px',
                marginLeft: '10px',
                marginTop: '10px',
                display: 'flex',
                gap: '10px'
              }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                onClick={() => formik.setFieldValue('rcTransfer', 'yes')}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                onClick={() => formik.setFieldValue('rcTransfer', 'no')}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl>
            <Typography variant="h4">Is Insurance required?*</Typography>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={'yes'}
              sx={{
                width: '300px',
                marginLeft: '10px',
                marginTop: '10px',
                display: 'flex',
                gap: '10px'
              }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                onClick={() => formik.setFieldValue('insurance', 'yes')}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                onClick={() => formik.setFieldValue('insurance', 'no')}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Booking Form
          </Typography>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="bookingDocument"
            value={formik.values.bookingDocument}
            onChange={(value) => {
              formik.setFieldValue('bookingDocument', value);
            }}
            placeholder="Upload document*"
          />
          {formik.errors.bookingDocument && formik.touched.bookingDocument ? (
            <div style={{ color: 'red' }}>{formik.errors?.bookingDocument}</div>
          ) : null}
        </Grid>
      </Grid>

      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        undesireButtonText={undesireButtonText}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
}

export default BookingDetailsAdded;
