import {
  Box,
  Button,
  Grid,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import LeadListRow from 'src/components/Composite/LeadListRow';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import TableWrapper from 'src/components/Tables';
import { LeadListTableHeader } from 'src/constants/constant';
import {
  LeadStatus,
  UserRole,
  useFilteredInventoryLazyQuery,
  useGetCentreListQuery
} from 'src/generated/hooks_and_more';
import { ExportCSV } from '../leads/leadExport';
import { format } from 'date-fns';
import {
  getUserData,
  getYearFromDate,
  titleCaseToReadable
} from 'src/utils/utility';

function Refurbishment() {
  const [leadData, setLeadData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [getLeads, { data, loading }] = useFilteredInventoryLazyQuery({
    onCompleted(data) {
      setLeadData([...leadData, ...data?.filteredLeads]);
    }
  });
  const userData = getUserData();

  const isUserCM = userData?.role === UserRole.CentreManager;
  const [exportRefurbishments, { data: exports, loading: exportLoading }] =
    useFilteredInventoryLazyQuery({
      onCompleted(data) {
        const exportData = data?.filteredLeads?.map((item, index) => {
          const source = titleCaseToReadable(item?.source ?? '-');
          const make = titleCaseToReadable(
            item?.vehicle?.vehicleBrand?.name ?? '-'
          );
          const year = item?.vehicle?.manufacturingDate
            ? getYearFromDate(item?.vehicle?.manufacturingDate ?? '-')
            : '-';
          const createdAt = item?.createdAt;
          const lastEvent =
            item?.statusEvents &&
            item?.statusEvents?.length &&
            item?.statusEvents;

          const lastEventUpdatedDate =
            lastEvent && lastEvent.length && lastEvent[0]?.createdAt;

          return {
            Registration_Number: item?.regNo,
            Status: lastEvent
              ? titleCaseToReadable(lastEvent?.[0]?.status ?? '-')
              : '-',
            Pending_On: titleCaseToReadable(
              lastEvent?.[0]?.assignTaskTo ?? '-'
            ),
            Alotted_Centre: item?.centre?.name ?? '-',
            Purchase_Type: source,
            Make: make,
            Model: item?.vehicle?.vehicleModel?.name ?? '-',
            Mfg_Year: year,
            Created_By: item?.createdBy?.name ?? '',
            Created_Date: !!createdAt
              ? format(Date.parse(createdAt), 'dd-MM-yyyy')
              : '-',
            Updated_Date: !!lastEventUpdatedDate
              ? format(Date.parse(lastEventUpdatedDate), 'dd-MM-yyyy')
              : '-'
          };
        });
        setDataExport(exportData);
      }
    });

  const center = useGetCentreListQuery();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    centre: null,
    status: LeadStatus?.VehicleInStock
  });
  const exportQuery = () => {
    exportRefurbishments({
      variables: {
        centreName: filters?.centre?.name,
        status: LeadStatus?.VehicleInStock,
        offset: null
      }
    });

    return dataExport;
  };
  const resetExport = () => {
    setDataExport([]);
  };
  useEffect(() => {
    if (isUserCM) {
      const centreAssigned = JSON.parse(
        localStorage.getItem('centreData')
      )?.centreDetails;
      setFilters({ ...filters, centre: centreAssigned });
    }
  }, [isUserCM]);
  useEffect(() => {
    if ((isUserCM && filters?.centre) || !isUserCM) {
      if (filters?.centre) {
        getLeads({
          variables: {
            offset: filters?.pageNumber,
            status: LeadStatus?.VehicleInStock,
            centreName: filters?.centre?.name
          }
        });
      } else {
        getLeads({
          variables: {
            offset: filters?.pageNumber,
            status: LeadStatus?.VehicleInStock
          }
        });
      }
    }
  }, [filters]);
  if (loading && !leadData?.length) {
    return (
      <div
        className="d-flex flex-column height-100 justify-content-center align-items-center gap-4"
        style={{ height: '80vh' }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }
  return (isUserCM && filters?.centre) || !isUserCM ? (
    <Box sx={{ px: 2, pb: 2 }}>
      <PageTitleWrapper>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Typography variant="h3" component="h3" gutterBottom>
              Refurbishment Leads
            </Typography>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <InputAutoComplete
              onChange={(value) => {
                setFilters({ ...filters, centre: value, pageNumber: 1 });
                setLeadData([]);
              }}
              value={filters?.centre}
              disabled={isUserCM}
              placeholder="Select Center"
              options={center?.data?.queryCentre ?? []}
              optionString="name"
              style={{ width: '300px' }}
            />

            <Box sx={{ paddingLeft: '20px' }}>
              <ExportCSV
                callQuery={exportQuery}
                reset={resetExport}
                loading={exportLoading}
                csvData={dataExport}
                fileName={
                  filters?.centre?.name
                    ? `${filters?.centre?.name} Leads`
                    : 'Refurbishment Leads'
                }
              />
            </Box>
          </Box>
        </Grid>
      </PageTitleWrapper>
      <>
        <TableWrapper header={LeadListTableHeader}>
          {leadData?.length ? (
            leadData?.map((item, index) => {
              return (
                <LeadListRow key={item?.id} leadData={item} index={index} />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '20px',
                    fontWeight: '900',
                    width: '110vw'
                  }}
                >
                  <Typography>No data found</Typography>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableWrapper>

        {loading && leadData?.length ? (
          <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '30px'
            }}
          >
            <Button
              variant="contained"
              style={{
                display: data?.filteredLeads?.length ? 'block' : 'none'
              }}
              onClick={() => {
                setFilters({ ...filters, pageNumber: filters?.pageNumber + 1 });
              }}
            >
              Load More
            </Button>
          </Box>
        )}
      </>
    </Box>
  ) : (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography variant="h3" component="h3" gutterBottom>
        You've not assigned to any centres
      </Typography>
    </Box>
  );
}

export default Refurbishment;
