import { googleLogout } from '@react-oauth/google';
import { useUserStatusSubscription } from '../generated/hooks_and_more';
import useLoggedInUser from './useLoggedInUser';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';

export default function useUserStatus() {
  const navigation = useNavigate();
  const { addToast } = useToasts();

  const { loggedInUser } = useLoggedInUser();
  console.log(loggedInUser?.userId, '..................loggedInUser');
  const {} = useUserStatusSubscription({
    variables: {
      userId: loggedInUser?.userId
    },
    skip: !loggedInUser?.userId,
    fetchPolicy: 'no-cache',
    onError: (error) =>
      console.log('error from user status subscription', error),
    onData: ({ data, client }) => {
      console.log(data, 'ws called');
      if (data?.data?.queryUser?.length > 0) {
        const userActive = data?.data?.queryUser?.[0]?.isActive;
        console.log(userActive, 'userActive');
        if (!userActive) {
          addToast(
            `${data?.data?.queryUser?.[0]?.name} is no longer active. Please connect with admin!!!!`,
            {
              appearance: 'success',
              autoDismiss: true
            }
          );

          localStorage.clear();
          googleLogout();
          navigation('/vms-admin');
        } else {
        }
      }
    }
  });
}
