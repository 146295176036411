import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import React from 'react';
import { LeadCreateAndUpdateProps } from 'src/types';
import CtaButton from './CTA_Button/CtaButton';
import { useFormik } from 'formik';
import {
  LeadRef,
  LeadStatus,
  useDropQuery
} from 'src/generated/hooks_and_more';
import { titleCaseToReadable } from 'src/utils/utility';
import Input from 'src/components/Form/Input';

function Drop(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    source,
    canGoBack,
    onPressOfDesiredOrUndesiredButton
  } = props;
  const { data } = useDropQuery({ variables: { leadId: leadId } });
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const status = titleCaseToReadable(
    data?.queryLead?.[0]?.statusEvents?.[0]?.status
  );
  const formik = useFormik({
    initialValues: {
      remarks: ''
    },
    onSubmit: () => {
      onPressOfDesiredOrUndesiredButton(leadInput, formik?.values?.remarks);
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TableContainer
        sx={{ mt: 4, backgroundColor: '#f6f6f6' }}
        component={Paper}
      >
        <Table>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ fontWeight: 600 }} component="th" scope="row">
                Source
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {titleCaseToReadable(source)}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ fontWeight: 600 }} component="th" scope="row">
                Registration No
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {regNo}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ fontWeight: 600 }} component="th" scope="row">
                Status
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {status}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} md={12} sx={{ mt: 5 }}>
            <Input
              placeholder="Enter the remarks *"
              onChange={(e) => {
                formik.setFieldValue('remarks', e.target.value);
              }}
              value={formik.values.remarks}
            />
          </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        backBtn={canGoBack}
        disabled={!Boolean(formik?.values?.remarks)}
      />
    </form>
  );
}

export default Drop;
