import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import React, { useState } from 'react';
import {
  LeadRef,
  PaymentStatus,
  UserRole,
  useFinalCostQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import { calculateParkingCharge } from 'src/utils/utility';
import './form.css';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import CtaButton from './CTA_Button/CtaButton';
import { useFormik } from 'formik';
import { ActionType } from 'src/constants/constant';
function ApproveParkingPayment(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    undesireButtonText,
    canGoBack
  } = props;

  const [choose, setChoose] = useState('calculated');
  const { data, loading } = useFinalCostQuery({
    variables: { id: leadId }
  });
  const expectedPickupDate = data?.getLead?.expectedPickupDate;
  const perDayParkingCharge = data?.getLead?.yardPerDayParkingCharges;
  const repoDate = data?.getLead?.vehicle?.repossessionDate;
  const amount = data?.getLead?.finalBidAmount;

  const tempRepoDate = data?.getLead?.vehicle?.tempRepossessionDate;
  // const tempPerDayParkingCharge = data?.getLead?.yardPerDayParkingCharges;

  const tempPerDayParkingCharge = data?.getLead?.yardPerDayParkingChargesByDriver;

  const actualPickupDate = data?.getLead?.actualPickupDate;


  const estimatedParkingCharge = () => {
    return calculateParkingCharge({
      expectedPickupDate,
      perDayParkingCharge,
      repossessionDate: repoDate
    });
  };

  // const actualParkingCharge = () => {
  //   return calculateParkingCharge({
  //     expectedPickupDate: actualPickupDate ?? null,
  //     perDayParkingCharge: tempPerDayParkingCharge,
  //     repossessionDate: new Date(tempRepoDate)
  //   });
  // };

 const actualParkingCharge =   data?.getLead?.actualParkingCharges;
  const calculatedEstimatedParkingCharge = estimatedParkingCharge() + amount;
  const actualParkingChargeCost = actualParkingCharge + amount;
  const CalculatedProcurementCost = [
    {
      key: 'Expected Repoessession Date',
      value: new Date(repoDate).toDateString() ?? '-'
    },
    { key: 'Deal Amount', value: amount ?? '-' },
    {
      key: 'Calculated Per Day Parking Charge',
      value: perDayParkingCharge ?? '-'
    },
    {
      key: 'Calculated Parking Charge',
      value: estimatedParkingCharge() ?? '-'
    },
    {
      key: 'Calculated Procurement Cost',
      value: calculatedEstimatedParkingCharge ?? ''
    }
  ];
  const ActualProcurementCost = [
    {
      key: 'Actual Repoessession Date',
      value: new Date(tempRepoDate).toDateString() ?? '-'
    },
    { key: 'Deal Amount', value: amount ?? '-' },
    {
      key: 'Actual Per Day Parking Charge',
      value: tempPerDayParkingCharge ?? '-'
    },
    {
      key: 'Actual Parking Charge',
      value: actualParkingCharge ?? '-'
    },
    {
      key: 'Actual Procurement Cost',
      value: actualParkingChargeCost ?? ''
    }
  ];
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      if (choose === 'calculated') {
        leadInput = {
          id: leadId,
          regNo: regNo,
          approvedParkingChargesFor: UserRole.LogisticsManager,
          finalParkingCharges: Number(estimatedParkingCharge()),
          vehicle: {
            repossessionDate: repoDate
          },
          yardPerDayParkingCharges: Number(perDayParkingCharge),

          payments: [{ status: PaymentStatus.Estimated }]
        };
      } else {
        leadInput = {
          id: leadId,
          regNo: regNo,
          approvedParkingChargesFor: UserRole.Driver,
          finalParkingCharges: Number(actualParkingCharge),
          vehicle: {
            repossessionDate: tempRepoDate
          },
          yardPerDayParkingCharges: Number(tempPerDayParkingCharge),

          payments: [{ status: PaymentStatus.Requested }]
        };
      }
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        onClick={() => setChoose('calculated')}
        id={choose === 'calculated' ? 'card-clicked' : 'approve-parking-card'}
        sx={{ padding: '20px' }}
      >
        <h5>Calculated Procurement Cost (By Logistics Manager)</h5>
        <TableContainer
          sx={{ mt: 2, backgroundColor: '#f6f6f6' }}
          component={Paper}
        >
          <Table>
            <TableBody>
              {CalculatedProcurementCost?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    sx={{ fontWeight: 600 }}
                    component="th"
                    scope="row"
                  >
                    {item?.key}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    {item.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        onClick={() => setChoose('actual')}
        id={choose === 'actual' ? 'card-clicked' : 'approve-parking-card'}
        sx={{ mt: 4, padding: '20px' }}
      >
        <h5>Actual Procurement Cost (By Driver)</h5>
        <TableContainer
          sx={{ mt: 4, backgroundColor: '#f6f6f6' }}
          component={Paper}
        >
          <Table>
            <TableBody>
              {ActualProcurementCost?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    sx={{ fontWeight: 600 }}
                    component="th"
                    scope="row"
                  >
                    {item?.key}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    {item.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        backBtn={canGoBack}
        onlyRed={true}
        disabled={false}
        reject={reject}
      />
    </form>
  );
}

export default ApproveParkingPayment;
