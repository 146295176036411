import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { Fragment, useCallback, useState } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import TableWrapper from 'src/components/Tables';
import {
  LeadListTableHeader,
  STOCK_TABLE_HEADER
} from 'src/constants/constant';
import {
  Typography,
  Button,
  Grid,
  Box,
  TableRow,
  TableCell
} from '@mui/material';
import StockListRow from 'src/components/Composite/StockListRow';
import {
  LeadStatus,
  useGetCenterDetailsQuery,
  useGetStocksDetailsQuery,
  useStockLeadsQuery
} from 'src/generated/hooks_and_more';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import routeConstant from 'src/constants/routeContant';
import InventoryDetailCard from 'src/components/Composite/InventoryDetailCard';
import { searchString } from 'src/constants/reactiveVar';
import CenterLead from 'src/components/Composite/CenterLead';

function CenterDetails() {
  const location = useLocation();
  const { Id } = useParams();

  const { data: stocksData } = useGetStocksDetailsQuery({
    skip: !Id,
    fetchPolicy: 'no-cache',
    variables: {
      centreId: Id
    },
    onCompleted(data) {
      console.log('stocks details ', data);
    },
    onError(err) {
      console.log('stocks details error', err);
    }
  });

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [id, setId] = useState('');

  const { data, loading, error } = useGetCenterDetailsQuery({
    skip: !Id,
    variables: {
      id: Id
    },
    fetchPolicy: 'no-cache'
  });
  const { data: stockDataValue, loading: stockLoading } = useStockLeadsQuery({
    fetchPolicy: 'no-cache',
    skip: !data?.getCentre?.name,
    variables: {
      centerName: data?.getCentre?.name as string,
      status: LeadStatus.VehicleInStock
    }
  });


  const handleGlobalSearch = useCallback((value: string) => {

    searchString(value);
  }, []);

  const lat = data?.getCentre?.location?.latitude;
  const long = data?.getCentre?.location?.longitude;

  function getUrl(lat, long) {
    return `https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}`;
  }

  const details = [
    { key: 'State', value: data?.getCentre?.state },
    {
      key: 'Vehicle stocked',
      value: data?.getCentre?.leads?.length ?? '-'
    },
    { key: 'Spoc Name', value: data?.getCentre?.incharge?.name ?? '-' },
    {
      key: 'Location',
      value: !!(lat && long) ? getUrl(lat, long) : '-',
      type: 'map'
    },
    { key: 'City', value: data?.getCentre?.city },
    { key: 'Refurb items', value: '-' },
    { key: 'email', value: data?.getCentre?.incharge?.email ?? '-' },
    {
      key: 'Created on',
      value: data?.getCentre?.createdAt?.slice(0, 10) ?? '-'
    },
    { key: 'Pincode', value: data?.getCentre?.pincode ?? '-' },
    { key: 'Status', value: data?.getCentre?.status ?? '-' },
    { key: 'Mobile', value: data?.getCentre?.incharge?.phoneNo ?? '-' },
    {
      key: 'Last Updated on',
      value: data?.getCentre?.updatedAt?.slice(0, 10) ?? '-'
    }
  ];

  const navigate = useNavigate();

  return (
    <Fragment>
      <>
        <PageTitleWrapper>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h3" component="h3" gutterBottom>
                {data?.getCentre?.name ?? 'Center Details'}
              </Typography>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {/* <Grid item>
                <Button
                  sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                  variant="contained"
                  onClick={() => {
                    // setShowModal(true);
                    navigate(
                      `${routeConstant.INVENTORY}/${routeConstant.LEADS_IN_CENTRE}`,
                      { state: data?.getCentre?.name }
                    );
                  }}
                >
                  Vehicle Inventory
                </Button>
              </Grid> */}
              <Grid item>
                <Button
                  sx={{ mt: { xs: 0, md: 0 } }}
                  variant="contained"
                  onClick={() => {
                    navigate(`${routeConstant.INVENTORY}/center/edit/${Id}`);
                  }}
                >
                  Edit Center
                </Button>
              </Grid>
            </Box>
          </Grid>
        </PageTitleWrapper>
        <InventoryDetailCard details={details} />
      </>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            sx={{
              marginTop: '2rem',
              marginLeft: '2rem',
              marginBottom: '0.5rem',
              width: '40%'
            }}
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab label="Stock" value="1" sx={{ width: '30%' }} />
            <Tab label="Tractor" value="2" sx={{ width: '30%' }} />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Fragment>
            <Box sx={{ px: 2, pb: 2 }}>
              {loading ? (
                <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                  <h5>Loading..</h5>
                </div>
              ) : (
                <TableWrapper header={STOCK_TABLE_HEADER}>
                  {stocksData?.inventoryDetails?.items?.length ? (
                    stocksData?.inventoryDetails?.items?.map((item) => {
                      return (
                        <StockListRow
                          key={item?.id}
                          stockData={item}
                          centreId={Id}
                        />
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '20px',
                            fontWeight: '900'
                          }}
                        >
                          <Typography>No data found</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableWrapper>
              )}

              {/* <TableWrapper header={STOCK_TABLE_HEADER}>
          {stocksData?.inventoryDetails?.items?.map((item) => {
            return (
              <StockListRow key={item?.id} stockData={item} centreId={Id} />
            );
          })}
        </TableWrapper> */}
            </Box>
          </Fragment>
        </TabPanel>
        <TabPanel value="2">
          <Fragment>
            <Box sx={{ px: 2, pb: 2 }}>
              {stockLoading ? (
                <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                  <h5>Loading..</h5>
                </div>
              ) : (
                <>
                  <TableWrapper
                    handleGlobalSearch={handleGlobalSearch}
                    header={LeadListTableHeader}
                  >
                    {stockDataValue?.filteredLeads?.length ? (
                      stockDataValue?.filteredLeads?.map((item, index) => {
                        return (
                          <CenterLead
                            key={item.id}
                            centerLead={item}
                            index={index}
                          />
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '20px',
                              fontWeight: '900'
                            }}
                          >
                            <Typography>No data found</Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableWrapper>
                </>
              )}
            </Box>
          </Fragment>
        </TabPanel>
      </TabContext>
    </Fragment>
  );
}

export default CenterDetails;
