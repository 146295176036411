import { UserRole } from 'src/generated/hooks_and_more';

export const sidebarAccess = {
  Leads: [
    UserRole.Admin,
    UserRole.SuperAdmin,
    UserRole.ProcurementExecutive,
    UserRole.ProcurementHead,
    UserRole.OperationsManager,
    UserRole.LogisticsManager,
    UserRole.FinanceExecutive,
    UserRole.OperationsHead,
    UserRole.FinanceManager,
    UserRole.Driver,
    UserRole.ProcurementManager
  ],
  Task_management: [
    UserRole.Admin,
    UserRole.FinanceExecutive,
    UserRole.SuperAdmin,
    UserRole.ProcurementExecutive,
    UserRole.ProcurementHead,
    UserRole.OperationsManager,
    UserRole.LogisticsManager,
    UserRole.OperationsHead,
    UserRole.FinanceManager,
    UserRole.Driver,
    UserRole.ProcurementManager,
    UserRole.CentreManager,
    UserRole.SalesHead,
    UserRole.RetailFinanceManager,
    UserRole.StateHead,
    UserRole.TerritoryManager
  ],

  Refurbishment: [
    UserRole.CentreManager,
    UserRole.SalesHead,
    UserRole.FinanceManager,
    UserRole.SalesManager,
    UserRole.FinanceExecutive,
    UserRole.StateHead,
    UserRole.TerritoryManager,
    UserRole.Admin,
    UserRole.SuperAdmin
  ],
  Booking: [
    UserRole.CentreManager,
    UserRole.SalesHead,
    UserRole.RetailFinanceManager,
    UserRole.FinanceManager,
    UserRole.SalesManager,
    UserRole.StateHead,
    UserRole.FinanceExecutive,
    UserRole.TerritoryManager,
    UserRole.Admin,
    UserRole.SuperAdmin
  ],
  Enquiry: [
    UserRole.CentreManager,
    UserRole.SalesHead,
    UserRole.SalesExecutive,
    UserRole.StateHead,
    UserRole.TerritoryManager,
    UserRole.Admin,
    UserRole.SuperAdmin
  ],
  Inventory: [
    UserRole.CentreManager,
    UserRole.FinanceManager,
    UserRole.StateHead,
    UserRole.FinanceExecutive,
    UserRole.TerritoryManager,
    UserRole.Admin,
    UserRole.SuperAdmin
  ],
  Document_Management_System: [
    UserRole.Admin,
    UserRole.SuperAdmin,
    UserRole.OperationsManager,
    UserRole.LogisticsManager,
    UserRole.Admin,
    UserRole.SuperAdmin
  ],
  User_Management: [UserRole.Admin, UserRole.SuperAdmin],
  logs_Management: [UserRole.Admin, UserRole.SuperAdmin],

  Enquiry_Dashboard: [UserRole.Admin, UserRole.SuperAdmin, UserRole.SalesHead]
};
