import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Grid, TextField } from '@mui/material';
import * as Yup from 'yup';

import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import {
  LeadRef,
  useGetAllYardQuery,
  useGetYardDetailsQuery,
  useYardlistQuery,
  useYardlistValueLazyQuery
} from 'src/generated/hooks_and_more';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import { isValid } from 'date-fns';
import Input from 'src/components/Form/Input';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import CtaButton from './CTA_Button/CtaButton';

import { ActionType } from 'src/constants/constant';

import { SchedulePickFormValidation } from '../ValidationSchema/ValidationSchema';

const SchedulePickForm = (props: LeadCreateAndUpdateProps) => {
  const { data, loading } = useYardlistQuery();
  const [yard, setYard] = useState([]);
  const [yardName, setYardName] = useState('');
  const [repoDate, setRepoDate] = useState(null);
  const [yardCity, setyardCity] = useState('');
  const [yardAddress, setyardAddress] = useState('');
  const [yardSPOCName, setyardSPOCName] = useState('');
  const [yardSPOCMobile, setyardSPOCMobile] = useState(null);
  const [yardLocationurl, setyardLocationurl] = useState('');
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    onPressOfDesiredOrUndesiredButton
  } = props;
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const { data: yardDetails } = useGetYardDetailsQuery({
    variables: { id: leadId },
    onCompleted(data) {
      setRepoDate(data?.getLead?.vehicle?.repossessionDate);
    }
  });
  const [getYardData, { data: yardValue, loading: yardLoading }] =
    useYardlistValueLazyQuery();



  const queryValue = data?.queryYard?.flatMap((i) => ({ name: i.name }));
  const {data: yarvalues} = useGetAllYardQuery()
  useEffect(() => {
    if (yardValue) {
      setYardName(yardValue?.queryYard?.[0]?.name);
      setyardCity(yardValue?.queryYard?.[0]?.city);
      setyardAddress(yardValue?.queryYard?.[0]?.address);
      setyardSPOCName(yardValue?.queryYard?.[0]?.spocName);
      setyardSPOCMobile(yardValue?.queryYard?.[0]?.spocNo);
      setyardLocationurl(yardValue?.queryYard?.[0]?.locationUrl);
    }
  }, [yardValue]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      yardName,
      yardCity,
      yardAddress,
      yardSPOCName,
      yardSPOCMobile,
      yardLocationurl
    });
  }, [
    yardName,
    yardCity,
    yardAddress,
    yardSPOCName,
    yardSPOCMobile,
    yardLocationurl
  ]);

      

  const formik = useFormik({
    initialValues: {
      pickupDate: null,
      pickupYard: null,
      yardName: '',
      yardCity: '',
      yardAddress: '',
      yardSPOCName: '',
      yardSPOCMobile: null,
      yardLocationurl: '',
      newYard: false //used for validation purpose only
    },
    validationSchema: SchedulePickFormValidation,

    onSubmit: (values) => {


      const yardPerDayParkingChargesValue = yarvalues?.queryYard?.filter((i) => i?.name ===  formik?.values?.yardName)
      const valuess = yardPerDayParkingChargesValue[0]?.perDayParkingCharge
      leadInput = {
        regNo: props?.regNo,
        id: props?.leadId,
        expectedPickupDate: values?.pickupDate,
        yardPerDayParkingCharges: !!valuess ? Number(valuess) : 0,
        yard: {
          name: values?.yardName,
          address: values?.yardAddress,
          city: values?.yardCity,
          spocName: values?.yardSPOCName,
          spocNo: values?.yardSPOCMobile,
          perDayParkingCharge: !!valuess ? valuess : 0  ?? 0,
          locationUrl: values?.yardLocationurl
        }
      };

      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });


  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <DatePickerCalender
            placeholder="Select the Pickup Date"
            value={formik.values.pickupDate}
            minDate={repoDate ? new Date(repoDate) : null}
            onChange={(value) => {
              formik.setFieldValue('pickupDate', value);
            }}
          />
          {formik.errors.pickupDate && formik.touched.pickupDate ? (
            <div style={{ color: 'red' }}>{formik.errors?.pickupDate}</div>
          ) : null}
        </Grid>
        <Grid item md={12} xs={12}>
          <InputAutoComplete
            placeholder="Select Pickup Yard *"
            value={formik.values.pickupYard}
            onChange={(value) => {
              if (value?.name === 'Add New yard details') {
                formik.setValues({
                  ...formik.values,
                  pickupYard: value,
                  newYard: true
                });
              } else {
                formik.setFieldValue('pickupYard', value);
              }

              getYardData({ variables: { name: [value?.name] } });
            }}
            options={
              data?.queryYard
                ? [
                    ...data?.queryYard,
                    { name: 'Add New yard details', value: false }
                  ]
                : []
            }
            optionString="name"
            getOptionLabel={(option) => option.name}
          />
          {formik.errors.pickupYard && formik.touched.pickupYard ? (
            <div style={{ color: 'red' }}>{formik.errors?.pickupYard}</div>
          ) : null}
        </Grid>
        <Grid item md={12} xs={12}>
          <Input
            value={formik.values?.yardName ?? ''}
            placeholder="Yard Name*"
            disabled={Boolean(yardValue?.queryYard?.[0]?.name) ? true : false}
            name="yardName"
            onChange={(e) => {
              formik.setFieldValue(
                'yardName',
                Boolean(yardValue?.queryYard?.[0]?.name)
                  ? formik.values?.yardName
                  : e?.target?.value
              );
            }}
          ></Input>
          {formik.errors.yardName && formik.touched.yardName ? (
            <div style={{ color: 'red' }}>{formik.errors?.yardName}</div>
          ) : null}{' '}
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            value={formik.values?.yardCity ?? ''}
            id="outlined-uncontrolled"
            autoComplete="off"
            label="Yard City*"
            onChange={(e) =>
              formik.setFieldValue(
                'yardCity',
                Boolean(yardValue?.queryYard?.[0]?.name)
                  ? formik.values?.yardCity
                  : e?.target?.value
              )
            }
            disabled={Boolean(yardValue?.queryYard?.[0]?.name) ? true : false}
          />
          {formik.errors.yardCity && formik.touched.yardCity ? (
            <div style={{ color: 'red' }}>{formik.errors?.yardCity}</div>
          ) : null}
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="outlined-uncontrolled"
            autoComplete="off"
            label="Yard Address*"
            value={formik.values?.yardAddress ?? ''}
            onChange={(e) =>
              formik.setFieldValue(
                'yardAddress',
                Boolean(yardValue?.queryYard?.[0]?.name)
                  ? formik.values?.yardAddress
                  : e?.target?.value
              )
            }
            disabled={Boolean(yardValue?.queryYard?.[0]?.name) ? true : false}
          />
          {formik.errors.yardAddress && formik.touched.yardAddress ? (
            <div style={{ color: 'red' }}>{formik.errors?.yardAddress}</div>
          ) : null}{' '}
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="outlined-uncontrolled"
            autoComplete="off"
            label="Yard SPOC Name*"
            value={formik?.values?.yardSPOCName ?? ''}
            onChange={(e) =>
              formik.setFieldValue(
                'yardSPOCName',
                Boolean(yardValue?.queryYard?.[0]?.name)
                  ? formik.values?.yardSPOCName
                  : e?.target?.value
              )
            }
            disabled={Boolean(yardValue?.queryYard?.[0]?.name) ? true : false}
          />
          {formik.errors.yardSPOCName && formik.touched.yardSPOCName ? (
            <div style={{ color: 'red' }}>{formik.errors?.yardSPOCName}</div>
          ) : null}{' '}
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="outlined-uncontrolled"
            label="Yard SPOC Mobile*"
            autoComplete="off"
            value={formik?.values?.yardSPOCMobile ?? ''}
            onChange={(e) =>
              formik.setFieldValue(
                'yardSPOCMobile',
                Boolean(yardValue?.queryYard?.[0]?.name)
                  ? formik.values?.yardSPOCMobile
                  : e?.target?.value
              )
            }
            disabled={Boolean(yardValue?.queryYard?.[0]?.name) ? true : false}
          />
          {formik.values.yardSPOCMobile &&
          (formik.errors.yardSPOCMobile || formik.touched.yardSPOCMobile) ? (
            <div style={{ color: 'red' }}>{formik.errors?.yardSPOCMobile}</div>
          ) : null}{' '}
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="outlined-uncontrolled"
            autoComplete="off"
            label="Yard Location URL*"
            value={formik?.values?.yardLocationurl ?? ''}
            onChange={(e) =>
              formik.setFieldValue(
                'yardLocationurl',
                Boolean(yardValue?.queryYard?.[0]?.name)
                  ? formik.values?.yardLocationurl
                  : e?.target?.value
              )
            }
            disabled={Boolean(yardValue?.queryYard?.[0]?.name) ? true : false}
          />
          {formik.errors.yardLocationurl && formik.touched.yardLocationurl ? (
            <div style={{ color: 'red' }}>{formik.errors?.yardLocationurl}</div>
          ) : null}{' '}
        </Grid>
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        backBtn={canGoBack}
        disabled={!(formik.isValid && formik.dirty)}
        reject={reject}
      />
    </form>
  );
};

export default SchedulePickForm;
