import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  APPROVED_REFURBISHMENT_ITEMS_COUNT,
  APPROVED_REFURBISHMENT_ITEM_COST,
  ActionType,
  BOOKING_DELIVERY_PAYMENT,
  BOOKING_PART_PAYMENT,
  BOOKING_TOKEN,
  POPUP_BID_AMOUNT_LIMIT,
  POPUP_DEAL_DEMANDED_AMOUNT,
  POPUP_DEAL_PROPOSED_AMOUNT,
  POPUP_DELIVERY_EXPENSE,
  POPUP_DRIVER_NAME,
  POPUP_DUPLICATE_CREATE_LEAD_DESCRIPTION,
  POPUP_DUPLICATE_CREATE_LEAD_HEADER,
  POPUP_FINAL_BID_AMOUNT,
  POPUP_HOLD_BACK_AMOUNT,
  POPUP_LEAD_LISTING_PRICE,
  POPUP_LEAD_SELLING_PRICE,
  POPUP_REG_NO,
  REFURBISHMENT_PAYMENT_AMOUNT,
  TOTAL_SALE_AMOUNT,
  BOOKING_LOAN_AMOUNT,
  RTO_CHARGES,
  INSURANCE_CHARGES
} from 'src/constants/constant';
import Input from 'src/components/Form/Input';
import {
  LeadStatus,
  LoanRejectionReason,
  Payment,
  PaymentFor,
  PaymentStatus,
  usePopupDynamicValuesQuery
} from 'src/generated/hooks_and_more';
import { useReactiveVar } from '@apollo/client';
import { LeadFlowFormsInputs, enumToItems } from 'src/utils/utility';
import { Padding } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root>h2': {
    padding: 0
  }
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  style?: any;
}
type ModalProps = {
  open: boolean;
  handleClose: () => void;
  // toastMsg: boolean;
  onConfirmationHandler: (
    actionType?: ActionType,
    dealAmount?: number,
    rejectionReasonValue?: string
  ) => void;
  reset?: () => void;
  title: string;
  description: string;
  value?: string;
  buttonLoading?: boolean;
  heading?: string;
  textToShow?: string;
  type?: ActionType | null;
  remarks?: boolean;
  setPopupRemarks?: any;
  setLeadInputValue?: any;
  inputField?: boolean;
  undesiredStatus?: any;
  desiredStatus?: any;
  regNo?: string;
  checkRegistration?: boolean;
  refurbishmentRequestId?: string;
  paymentId?: string;
};
function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 5,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export default function ConfirmationPopup({
  open,
  handleClose,
  onConfirmationHandler,
  title,
  description,
  remarks,
  setPopupRemarks,
  inputField,
  undesiredStatus,
  setLeadInputValue,
  desiredStatus,
  refurbishmentRequestId,
  regNo,
  checkRegistration,
  buttonLoading,
  paymentId
}: ModalProps) {
  const leadInput = useReactiveVar(LeadFlowFormsInputs);
  const [demandAmount, setDemandAmount] = useState(0);
  const [demandAmountError, setDemandAmountError] = useState('');
  const [rejectionReasonValue, setRejectionReason] = useState(null);
  const [popupRemarks, setPopupValue] = useState(null);
  const { data: getDynamicValue, loading } = usePopupDynamicValuesQuery({
    variables: {
      regNo:
        desiredStatus === LeadStatus.LeadGenerated
          ? leadInput?.regNo?.toUpperCase()
          : regNo,
      requestId: refurbishmentRequestId
    },
    fetchPolicy: 'cache-and-network'
    //  onCompleted(data) {
    //    if (
    //      data?.getLead?.regNo?.toUpperCase() ===
    //        leadInput?.regNo?.toUpperCase() &&
    //      desiredStatus === LeadStatus.LeadGenerated
    //    ) {
    //      log('same reg no case');
    //      setIsRegNoSame(true);
    //      setDynamicTitleValue(POPUP_DUPLICATE_CREATE_LEAD_HEADER);
    //      setDynamicDescriptionValue(
    //        `Lead ${leadInput?.regNo} ${POPUP_DUPLICATE_CREATE_LEAD_DESCRIPTION}`
    //      );
    //    } else if (data?.getLead?.regNo ?? true) {
    //      setIsRegNoSame(false);
    //      const descVal = !loading && setDynamicValueFn(description);
    //      setDynamicDescriptionValue(descVal);
    //      const titleVal = !loading && setDynamicValueFn(title);
    //      setDynamicTitleValue(titleVal);
    //    } else {
    //      const descVal = !loading && setDynamicValueFn(description);
    //      setDynamicDescriptionValue(descVal);
    //      const titleVal = !loading && setDynamicValueFn(title);
    //      setDynamicTitleValue(titleVal);
    //    }

    //    const leadRegNo = leadInput?.regNo?.toUpperCase();
    //    log('popup data', {
    //      data,
    //      leadRegNo,
    //      desiredStatus,
    //      undesiredStatus,
    //      dynamicDescriptionValue,
    //      title,
    //      dynamicTitleValue
    //    });
    //  }
  });

  const rejectionReasonList = enumToItems(LoanRejectionReason);

  function getBookingPayment(
    payments: Partial<Payment>[],
    paymentStatus: PaymentStatus,
    paymentFor: PaymentFor
  ) {
    const bookingPayment = payments?.find(
      (payment) =>
        payment?.for === paymentFor && payment?.status === paymentStatus
    );

    return !!bookingPayment?.amount
      ? bookingPayment?.amount
      : leadInput?.tokenAmount;
  }

  const bookingPrice = Number(leadInput?.saleAmount);
  const checkForMatchingid =
    getDynamicValue?.getLead?.refurbishmentDetails?.requests?.filter(
      (i) => paymentId === i?.id
    );
  const value = checkForMatchingid?.[checkForMatchingid?.length - 1];
  const approvedLoanAmount =
    getDynamicValue?.getLead?.activeBooking?.activeLoan?.sanctionedLoanAmount;

  const refurbishmentApprovedAmount = value?.purchase?.items
    ?.filter((i) => i?.isApproved)
    ?.reduce((initialPrice, p) => {
      if (!Boolean(p?.price)) {
        return p?.approvedPriceLimit + initialPrice;
      } else {
        return p?.price + initialPrice;
      }
    }, 0);

  const refurbishmentTransportationCharges = value?.transportationCharge ?? 0;
  const refurbishmentItemCount =
    leadInput?.refurbishmentDetails?.requests?.[0]?.issue?.items?.length;

  const bookingPayments = getDynamicValue?.getLead?.activeBooking?.payments;
  const tokenAmount = Number(leadInput?.tokenAmount);

  const bookingTokenAmount = getBookingPayment(
    bookingPayments,
    PaymentStatus.Requested,
    PaymentFor.BookingToken
  );
  const bookingDeliveryAmount = getBookingPayment(
    bookingPayments,
    PaymentStatus.Requested,
    PaymentFor.BookingDelivery
  );
  // console.log(bookingTokenAmount, "OOOOOOOOOOOOOOOOOOOO")
  console.log(leadInput);
  const bookingPartPaymentAmount =
    bookingPayments?.find(
      (payment) =>
        payment?.id === paymentId && payment?.status !== PaymentStatus?.Rejected
    )?.amount ?? leadInput?.tokenAmount;

  function setDynamicValueFn(desc: string) {
    //  let titleString = type ==='negative' ? negativeDesc : positiveDesc
    let titleString = desc;
    if (titleString?.includes(POPUP_BID_AMOUNT_LIMIT)) {
      ////console.log("bid amount limit called", getDynamicValue);?.getLead?.

      const proposedBidAmount = leadInput?.proposedBidLimitAmount?.toString();
      titleString = titleString.replace(
        POPUP_BID_AMOUNT_LIMIT,
        proposedBidAmount
      );
    } else if (titleString?.includes(POPUP_FINAL_BID_AMOUNT)) {
      // If final bid amount is not present in getDynamicValue then use leadInput
      const finalBidAmount =
        desiredStatus === LeadStatus.PaymentRequestSent
          ? getDynamicValue?.getLead?.finalBidAmount?.toString()
          : leadInput?.finalBidAmount?.toString();

      titleString = titleString.replace(POPUP_FINAL_BID_AMOUNT, finalBidAmount);
      console.log(
        finalBidAmount,
        desiredStatus,
        getDynamicValue?.getLead?.finalBidAmount?.toString(),
        'LKJDKD'
      );
    } else if (titleString?.includes(POPUP_DEAL_DEMANDED_AMOUNT)) {
      const leadDemandedAmount =
        getDynamicValue?.getLead?.demandAmount?.toString();
      titleString = titleString.replace(
        POPUP_DEAL_DEMANDED_AMOUNT,
        leadDemandedAmount
      );
    } else if (titleString?.includes(POPUP_DEAL_PROPOSED_AMOUNT)) {
      const leadProposedAmount =
        getDynamicValue?.getLead?.proposedDealAmount?.toString();
      titleString = titleString.replace(
        POPUP_DEAL_PROPOSED_AMOUNT,
        leadProposedAmount
      );
    } else if (titleString?.includes(POPUP_REG_NO)) {
      const regNumber = getDynamicValue?.getLead?.regNo ?? regNo;
      titleString = titleString.replace(POPUP_REG_NO, regNumber);
    } else if (titleString?.includes(POPUP_DRIVER_NAME)) {
      const driverName = getDynamicValue?.getLead?.pickup?.by?.name;
      titleString = titleString.replace(POPUP_DRIVER_NAME, driverName);
    } else if (titleString?.includes(POPUP_LEAD_SELLING_PRICE)) {
      const sellingPrice = leadInput?.sellingPrice?.toString();
      titleString = titleString.replace(POPUP_LEAD_SELLING_PRICE, sellingPrice);
    } else if (titleString?.includes(POPUP_LEAD_LISTING_PRICE)) {
      const listingPrice = leadInput?.listingPrice?.toString();
      titleString = titleString.replace(POPUP_LEAD_LISTING_PRICE, listingPrice);
    } else if (titleString?.includes(POPUP_DELIVERY_EXPENSE)) {
      let totalExpenses = 0;
      const deliveryExpense = getDynamicValue?.getLead?.expenses.map((item) => {
        if (item?.paymentStatus !== PaymentStatus.Rejected) {
          totalExpenses = totalExpenses + Number(item?.spentAmount);
        }
        return totalExpenses?.toString();
      });
      titleString = titleString.replace(
        POPUP_DELIVERY_EXPENSE,
        totalExpenses?.toString()
      );
    } else if (titleString?.includes(POPUP_HOLD_BACK_AMOUNT)) {
      let holdBackAmount = 0;
      if (leadInput?.holdbackAmount && leadInput?.documentCharges) {
        holdBackAmount =
          Number(leadInput?.holdbackAmount) +
          Number(leadInput?.documentCharges);
      } else if (leadInput?.holdbackAmount) {
        holdBackAmount = leadInput?.holdbackAmount;
      } else if (leadInput?.documentCharges) {
        holdBackAmount = leadInput?.documentCharges;
      }
      titleString = titleString.replace(
        POPUP_HOLD_BACK_AMOUNT,
        holdBackAmount?.toString()
      );
    } else if (titleString?.includes(REFURBISHMENT_PAYMENT_AMOUNT)) {
      const refurbishmentTotalPaymentAmount =
        refurbishmentApprovedAmount + refurbishmentTransportationCharges;

      // this is the sum of refurbishment cost + transporatation cost
    
      titleString = titleString.replace(
        REFURBISHMENT_PAYMENT_AMOUNT,
        refurbishmentTotalPaymentAmount?.toString()
      );
    } else if (titleString?.includes(BOOKING_TOKEN)) {
      titleString = titleString?.replace(
        BOOKING_TOKEN,
        bookingTokenAmount?.toString()
      );
    } else if (titleString?.includes(BOOKING_PART_PAYMENT)) {
      titleString = titleString?.replace(
        BOOKING_PART_PAYMENT,
        bookingPartPaymentAmount?.toString()
      );
    } else if (titleString?.includes(BOOKING_DELIVERY_PAYMENT)) {
      titleString = titleString?.replace(
        BOOKING_DELIVERY_PAYMENT,
        bookingDeliveryAmount?.toString()
      );
    } else if (titleString?.includes(APPROVED_REFURBISHMENT_ITEMS_COUNT)) {
      titleString = titleString?.replace(
        APPROVED_REFURBISHMENT_ITEMS_COUNT,
        leadInput?.totalCount?.toString()
      );
    }

    if (titleString?.includes(APPROVED_REFURBISHMENT_ITEM_COST)) {
      titleString = titleString?.replace(
        APPROVED_REFURBISHMENT_ITEM_COST,
        leadInput?.totalAmount?.toString()
      );
    }

    //   if (titleString?.includes(BOOKING_PRICE)) {
    //     titleString = titleString?.replace(
    //       BOOKING_PRICE,
    //       bookingPrice?.toString()
    //     );
    //   }
    if (titleString?.includes(TOTAL_SALE_AMOUNT)) {
      titleString = titleString?.replace(
        TOTAL_SALE_AMOUNT,
        (
          bookingPrice +
          (getDynamicValue?.getLead?.activeBooking?.isRCTransferReq
            ? RTO_CHARGES
            : 0) +
          (getDynamicValue?.getLead?.activeBooking?.isInsuranceReq
            ? INSURANCE_CHARGES
            : 0)
        )?.toString()
      );
    } else if (titleString?.includes(BOOKING_LOAN_AMOUNT)) {
      titleString = titleString?.replace(
        BOOKING_LOAN_AMOUNT,
        approvedLoanAmount?.toString()
      );
    }

    return titleString;
  }

  function setButtonDisabled() {
    if (
      remarks &&
      inputField &&
      undesiredStatus === LeadStatus.NewDealProposed
    ) {
      return !(popupRemarks && demandAmount);
    } else if (remarks) {
      return !popupRemarks;
    } else if (
      (undesiredStatus === LeadStatus.BookingLoanDORejected ||
        undesiredStatus === LeadStatus.BookingLoanFIFailed ||
        undesiredStatus === LeadStatus.BookingFinanceCaseDropped) &&
      remarks &&
      inputField
    ) {
      return !(popupRemarks && rejectionReasonValue);
    } else {
      return false;
    }
  }
  const navigate = useNavigate();
  const formInputs = useReactiveVar(LeadFlowFormsInputs);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ p: 0 }}
      >
        <BootstrapDialogTitle
          style={{ display: 'flex', justifyContent: 'end', color: 'red' }}
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <Box sx={{ width: '400px', padding: '10px' }}>
          <DialogTitle
            style={{
              cursor: 'move',
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 600
            }}
            id="draggable-dialog-title"
          >
            {setDynamicValueFn(title)}
          </DialogTitle>
          <DialogContent sx={{ textAlign: 'center' }}>
            <Typography gutterBottom>
              {setDynamicValueFn(description)}
            </Typography>
          </DialogContent>

          {undesiredStatus === LeadStatus?.NewDealProposed && inputField && (
            <DialogContent sx={{ textAlign: 'center' }}>
              <Input
                placeholder="Enter New Deal Amount"
                onChange={(e) => {
                  setDemandAmount(Number(e.target.value));
                  setDemandAmountError(e.target.value);
                }}
                required={true}
                errorValue={Boolean(
                  !/^\d+$/.test(demandAmountError) && demandAmount !== 0
                )}
              ></Input>
            </DialogContent>
          )}
          {(undesiredStatus === LeadStatus.BookingLoanDORejected ||
            undesiredStatus === LeadStatus.BookingLoanFIFailed ||
            undesiredStatus === LeadStatus.BookingFinanceCaseDropped) &&
            inputField && (
              <DialogContent sx={{ textAlign: 'center' }}>
                <InputAutoComplete
                  onChange={(newValue) => {
                    setRejectionReason(newValue);
                    LeadFlowFormsInputs({
                      ...formInputs,
                      rejectionReason: newValue.value
                    });
                  }}
                  value={rejectionReasonValue}
                  placeholder="Rejection Reason*"
                  options={rejectionReasonList}
                  optionString="label"
                  getOptionLabel={(option) => option.name}
                />
              </DialogContent>
            )}
          {undesiredStatus === LeadStatus?.NewDealRequested && inputField && (
            <DialogContent sx={{ textAlign: 'center' }}>
              <Input
                placeholder="Request New Deal"
                onChange={(e) => {
                  setDemandAmount(Number(e.target.value));
                }}
                required={true}
                errorValue={Boolean(
                  !/^\d+$/.test(demandAmountError) && demandAmount !== 0
                )}
              ></Input>
            </DialogContent>
          )}
          {remarks && (
            <DialogContent sx={{ textAlign: 'center' }}>
              <Input
                placeholder="Enter Remarks"
                value={popupRemarks}
                onChange={(e) => {
                  setPopupRemarks(e?.target?.value);
                  setPopupValue(e?.target?.value);
                }}
                required={true}
              ></Input>
            </DialogContent>
          )}
          <DialogActions
            sx={{
              marginBottom: '0.3rem',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {checkRegistration ? (
              <Button
                style={{ backgroundColor: 'red', width: '100px' }}
                variant="contained"
                id="notconfirm"
                onClick={() => onConfirmationHandler(ActionType.UNDESIRED)}
                size="medium"
              >
                Back
              </Button>
            ) : (
              <>
                {' '}
                <Button
                  style={{ backgroundColor: 'red', width: '100px' }}
                  variant="contained"
                  id="notconfirm"
                  onClick={() => onConfirmationHandler(ActionType.UNDESIRED)}
                  size="medium"
                >
                  No
                </Button>
                <Button
                  disabled={setButtonDisabled() || buttonLoading}
                  variant="contained"
                  onClick={() => {
                    if (
                      undesiredStatus === LeadStatus?.NewDealProposed ||
                      undesiredStatus === LeadStatus?.NewDealRequested
                    ) {
                      onConfirmationHandler(ActionType.DESIRED, demandAmount);
                    } else if (
                      undesiredStatus === LeadStatus.BookingLoanDORejected ||
                      undesiredStatus === LeadStatus.BookingLoanFIFailed ||
                      undesiredStatus === LeadStatus.BookingFinanceCaseDropped
                    ) {
                      console.log(
                        'PPPPPPPPPPPPPPPPPPPPPPPPPP',
                        rejectionReasonValue?.value
                      );
                      onConfirmationHandler(
                        ActionType.DESIRED,

                        0,
                        rejectionReasonValue?.value
                      );
                    } else {

                      onConfirmationHandler(ActionType.DESIRED);
                    }
                  }}
                  sx={{ width: '100px' }}
                >
                  Yes
                </Button>
              </>
            )}
          </DialogActions>
        </Box>
      </BootstrapDialog>
      {/* {toastMsg ? <ToastMessage open="true" message="Center Added Successfully" /> : <></>} */}
    </div>
  );
}
