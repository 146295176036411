import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router';
import Input from 'src/components/Form/Input';
import { useState } from 'react';
import * as Yup from 'yup';
import {
  ActionType,
  AddInventoryCentreMutationVariables,
  CenterStatus,
  ModuleType,
  TaskStatus,
  useAddLogsMutation,
  useCenterDetailsLazyQuery,
  useCenterUpdateMutation,
  useGetAllActiveStateQuery,
  useGetAllStateQuery,
  useGetCenterUserQuery,
  useGetDistrictsByStateQuery
} from 'src/generated/hooks_and_more';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { useEffect } from 'react';
import ConfimationModal from 'src/components/Composite/ConfirmationModal/ConfimationModal';
import { useToasts } from 'react-toast-notifications';
import { titleCaseToReadable } from 'src/utils/utility';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';

function EditCentre() {
  const [open, setOpen] = useState(false);
  const { addToast } = useToasts();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [stateValue, setStateValue] = useState<[] | any>(null);
  const [districtID, setDistrictID] = useState();
  const { Id } = useParams();
  const navigation = useNavigate();
  const [centerDetails, { data, loading }] = useCenterDetailsLazyQuery();
  const [on, setOn] = useState(false);
  const [stateValueForCenterList, setstateValueForCenterList] = useState([]);
  const { data: stateList } = useGetAllActiveStateQuery();

  const { data: districtOptions } = useGetDistrictsByStateQuery({
    variables: {
      id: districtID
    }
  });

  useEffect(() => {
    if (data) {
      const stateValueList = stateList?.getStateInfo?.data?.filter(
        (i) =>
          i?.state_name.toLowerCase() === data?.getCentre?.state.toLowerCase()
      );
      setstateValueForCenterList(stateValueList);
    }
  }, [data, stateList]);

  const { data: user } = useGetCenterUserQuery();
  let centerManager = [];
  user?.queryUser?.map((item: any) => {
    return (centerManager = [
      ...centerManager,
      { label: item.email, value: item.email }
    ]);
  });

  useEffect(() => {
    centerDetails({
      variables: { id: Id }
    });
  }, []);

  const [updateInventoryCentre] = useCenterUpdateMutation();
  const [variables, setVariables] = useState(null);
  const title = 'Edit Center';

  const btnTitle = 'Update Center';
  const [AddLogs] = useAddLogsMutation();
  const { loggedInUser } = useLoggedInUser();

  const initialValues = {
    name: data?.getCentre?.name ?? '',
    state: data?.getCentre?.state
      ? { label: data?.getCentre?.state, value: data?.getCentre?.state }
      : null,
    city: data?.getCentre?.city
      ? { label: data?.getCentre?.city, value: data?.getCentre?.city }
      : null,
    pincode: data?.getCentre?.pincode ? String(data?.getCentre?.pincode) : '',
    inventoryCount: '',
    stockCount: '',
    // status: {
    //   label: data?.getCentre?.status === 'ACTIVE' ? 'Active' : 'Closed',
    //   value: data?.getCentre?.status
    // },
    status: data?.getCentre?.status
      ? {
          label:
            data?.getCentre?.status === 'ACTIVE'
              ? 'ACTIVE'
              : data?.getCentre?.status === 'PENDING'
              ? 'PENDING'
              : 'CLOSED',
          value: data?.getCentre?.status
        }
      : null,
    centerSpocEmail: data?.getCentre?.incharge?.email
      ? {
          label: data?.getCentre?.incharge?.email,
          value: data?.getCentre?.incharge?.email
        }
      : null,
    centerSpocName: data?.getCentre?.incharge?.name ?? '',
    centerSpocContact: data?.getCentre?.incharge?.phoneNo ?? '',
    lat: data?.getCentre?.location?.latitude
      ? String(data?.getCentre?.location?.latitude)
      : '',
    long: data?.getCentre?.location?.longitude
      ? String(data?.getCentre?.location?.longitude)
      : ''
  };
  useEffect(() => {
    if (Boolean(stateValueForCenterList)) {
      const stateId = !Boolean(stateValue?.id)
        ? stateValueForCenterList[0]?.state_id
        : stateValue?.id;
      setDistrictID(stateId);
    }
  }, [stateValue?.id, stateValueForCenterList]);

  const onConfirmationHandler = () => {
    updateInventoryCentre({
      variables,
      onCompleted() {
        AddLogs({
          variables: {
            taskPerformedBy: { id: loggedInUser?.userId },
            actionType: ActionType.Update,
            module: ModuleType.Centre,
            createdAt: new Date(),
            sourceUpdated: variables?.state,
            updatedValue: JSON.stringify({ data: variables }),
            previousValue: JSON.stringify({
              data: {
                id: Id,
                lat: data?.getCentre?.location?.latitude,
                long: data?.getCentre?.location?.longitude,
                city: data?.getCentre?.city,
                pincode: data?.getCentre?.pincode,
                state: data?.getCentre?.state,
                status: data?.getCentre?.status
                  ? data?.getCentre?.status === 'ACTIVE'
                    ? 'ACTIVE'
                    : 'CLOSED'
                  : null,
                updatedAt: data?.getCentre?.updatedAt,
                email: data?.getCentre?.incharge?.email,
                phoneNo: data?.getCentre?.incharge?.phoneNo ?? ''
              }
            }),
            status: TaskStatus.Success
          }
        });
        handleClose();
        addToast('Centre Updated Successfully!!!', {
          appearance: 'success',
          autoDismiss: true
        });
        // action.resetForm()
        navigation(-1);
      },
      onError() {
        AddLogs({
          variables: {
            taskPerformedBy: { id: loggedInUser?.userId },
            actionType: ActionType.Update,
            module: ModuleType.Centre,
            createdAt: new Date(),
            sourceUpdated: variables?.state,
            updatedValue: '',
            previousValue: '',
            status: TaskStatus.Failed
          }
        });
      }
    });
  };

  if (loading || !data) {
    return <div>Loading...</div>;
  }
  return (
    <Box sx={{ mt: 3 }} display={'flex'} justifyContent={'center'}>
      <Box width={'80%'}>
        <Card>
          <Typography sx={{ my: 1, mx: 2 }} variant="h3">
            {title}
          </Typography>
          <Divider />
          <CardContent>
            <Formik
              initialValues={initialValues}
              onSubmit={(values, action) => {
                const variable = {
                  id: Id,
                  lat: Number(values.lat),
                  long: Number(values.long),
                  city: values?.city?.value,
                  pincode: Number(values.pincode),
                  state: values?.state?.value,
                  status: values?.status?.value,
                  updatedAt: new Date(),
                  email: values?.centerSpocEmail?.value,
                  name: values?.centerSpocName,
                  phoneNo: values?.centerSpocContact
                };
                setVariables(variable);
                handleClickOpen();
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Required*'),
                lat: Yup.number()
                  .typeError('Must be a number*')
                  .required('Required*'),
                long: Yup.number()
                  .typeError('Must be a number*')
                  .required('Required*'),
                state: Yup.object().required('*Required'),
                city: Yup.object().required('*Required'),
                pincode: Yup.string()
                  .matches(/^[0-9]+$/, 'Must be only digits')
                  .required('*Required')
                  .min(6, 'Pincode must be 6 digits')
                  .max(6, 'Pincode must be 6 digits'),
                status: Yup.object()
                  .shape({
                    label: Yup.string(),
                    value: Yup.string()
                  })
                  .required('* Required'),
                centerSpocEmail: Yup.object().shape({
                  label: Yup.string().required(),
                  value: Yup.string().required()
                })
              })}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Input
                          value={values.name}
                          name="name"
                          onChange={handleChange}
                          disabled={true}
                          placeholder="Name"
                        />
                        {errors.name && touched.name ? (
                          <div style={{ color: 'red' }}>{errors?.name}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputAutoComplete
                          value={values.state}
                          disabled={true}
                          onChange={(value) => {
                            setFieldValue('state', value);
                            setStateValue(value);
                            setFieldValue('city', null);
                          }}
                          placeholder="State"
                          options={
                            stateList?.getStateInfo?.data.map((i) => ({
                              label: titleCaseToReadable(i?.state_name),
                              value: i?.state_name,
                              id: i?.state_id
                            })) ?? []
                          }
                        />
                        {errors.state && touched.state ? (
                          <div style={{ color: 'red' }}>{errors?.state}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputAutoComplete
                          placeholder="City"
                          disabled={true}
                          onChange={(value) => {
                            setFieldValue('city', value);
                          }}
                          value={values?.city}
                          options={
                            districtOptions?.getDistrictByStateInfo?.data.map(
                              (i) => ({
                                label: i?.district_name,
                                value: i?.district_name
                              })
                            ) ?? []
                          }
                        />
                        {errors.city && touched.city ? (
                          <div style={{ color: 'red' }}>{errors?.city}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          name="pincode"
                          disabled={true}
                          placeholder="Pincode"
                          value={values.pincode}
                          onChange={handleChange}
                        />
                        {errors.pincode && touched.pincode ? (
                          <div style={{ color: 'red' }}>{errors?.pincode}</div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputAutoComplete
                          options={[
                            { label: 'ACTIVE', value: 'ACTIVE' },
                            { label: 'CLOSED', value: 'CLOSED' }
                          ]}
                          placeholder="Status"
                          value={values.status}
                          onChange={(value) => {
                            setFieldValue('status', value);
                          }}
                        />
                        {errors.status && touched.status ? (
                          <div style={{ color: 'red' }}>{errors?.status}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputAutoComplete
                          options={centerManager ?? []}
                          placeholder="Center SPOC Email"
                          value={values.centerSpocEmail}
                          onChange={(value) => {
                            setFieldValue('centerSpocEmail', value);
                          }}
                        />
                        {errors.centerSpocEmail && touched.centerSpocEmail ? (
                          <div style={{ color: 'red' }}>
                            {errors?.centerSpocEmail}
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button variant="contained" type="submit">
                        {btnTitle}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Box>
      <ConfimationModal
        textToShow="Do you want to update the center?"
        open={open}
        handleClose={handleClose}
        toastMsg={on}
        onConfirmationHandler={onConfirmationHandler}
      />
    </Box>
  );
}

export default EditCentre;
