import { Box, CircularProgress } from '@mui/material';
import React from 'react';

function Progress() {
  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: '40px'
      }}
    >
      <CircularProgress
        color={'secondary'}
        size={24}
        disableShrink
        thickness={3}
      />
    </Box>
  );
}

export default Progress;
