import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import { Button, Grid, TextField } from '@mui/material';
import Input from 'src/components/Form/Input';
import {
  BankName,
  Lead,
  LeadBusinessStatus,
  LeadRef,
  LeadSource,
  LeadStatus,
  Ownership,
  useGetAllNewBrandsQuery,
  useGetAllNewModelsByMakeLazyQuery,
  useGetAllNewModelsByMakeQuery,
  useLeadUpdateQuery,
  useModelByMakeLazyQuery
} from 'src/generated/hooks_and_more';
import { enumToItems, titleCaseToReadable } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';

import { AutoCompleteValueType, LeadCreateAndUpdateProps } from 'src/types';
import './form.css';

import { ActionType } from 'src/constants/constant';
import {
  DealershipFormValidation,
  LeadFormValidation
} from '../ValidationSchema/ValidationSchema';
import CtaButton from './CTA_Button/CtaButton';
const LeadUpdate = (props: LeadCreateAndUpdateProps) => {
  const {
    desireButtonText,
    undesireButtonText,
    toggleDrawer,
    refetch,
    onPressOfDesiredOrUndesiredButton,
    canGoBack,
    leadId,
    regNo
  } = props;

  const { data: allBrands } = useGetAllNewBrandsQuery();
  const [source, setSource] = useState('BANK_AUCTION');
  const [make, setMake] = useState(null);
  const [GetAllNewModelsByMake, { data: modelOptions }] =
    useGetAllNewModelsByMakeLazyQuery();

  const { data: leadData, loading } = useLeadUpdateQuery({
    variables: { id: leadId },
    onCompleted() {
      const initialMake = leadData?.queryLead?.[0]?.vehicle
        ?.vehicleBrand as AutoCompleteValueType;
      const initialModel = leadData?.queryLead?.[0]?.vehicle
        ?.vehicleModel as AutoCompleteValueType;
      const cacheLeadData = leadData?.queryLead?.[0];
      const initialRegNo = cacheLeadData?.regNo;
      const initialSource = {
        label: titleCaseToReadable(cacheLeadData?.source),
        value: cacheLeadData?.source
      };

      const initialYear = new Date(cacheLeadData?.vehicle?.manufacturingDate);
      const initialEngNo = cacheLeadData?.vehicle?.engineNumber;
      const initialChasNo = cacheLeadData?.vehicle?.chassisNumber;
      const initialBank = {
        label: titleCaseToReadable(cacheLeadData?.auctionByBank),
        value: cacheLeadData?.auctionByBank
      };
      const initialStatus = {
        label: titleCaseToReadable(cacheLeadData?.status),
        value: cacheLeadData?.status
      };
      const initialDemandAmount = cacheLeadData?.demandAmount;
      const initialOwnership = {
        label: titleCaseToReadable(cacheLeadData?.ownershipType),
        value: cacheLeadData?.ownershipType
      };
      const initialHoursMeter = cacheLeadData?.vehicle?.hoursMeter;
      formik.setValues({
        ...formik.values,
        source: initialSource,
        bankAuction: initialSource?.value === 'BANK_AUCTION' ? true : false,
        regNo: initialRegNo,
        make: initialMake,
        model: initialModel,
        manufacturingYear: initialYear,
        bankName: initialBank,
        engineNo: initialEngNo,
        chasisNo: initialChasNo,
        status: initialStatus,
        demandedAmount: initialDemandAmount?.toString(),
        ownershipType: initialOwnership,
        hoursMeter: initialHoursMeter?.toString()
      });
    }
  });

  const BankNameOptions = enumToItems(BankName);

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      source: null,
      regNo: '',
      make: null,
      model: null,
      manufacturingYear: null,
      bankName: null,
      engineNo: '',
      chasisNo: '',
      bankAuction: true,
      status: null,
      demandedAmount: '',
      hoursMeter: '',
      ownershipType: null,
      remarks: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      leadInput = {
        id: leadId,
        regNo: values.regNo,
        source: values?.source?.value as LeadSource,
        demandAmount: Number(values?.demandedAmount),
        ownershipType: values?.ownershipType?.value as Ownership,
        status: values?.status?.value,
        auctionByBank:
          values?.source?.value === LeadSource?.DealershipSale
            ? null
            : values?.bankName?.value,
        vehicle: {
          vehicleBrand: {
            id: values?.make?.id
          },
          vehicleModel: {
            id: values?.model?.id
          },
          engineNumber:
            values?.source?.value === LeadSource?.DealershipSale
              ? null
              : values?.engineNo,
          chassisNumber:
            values?.source?.value === LeadSource?.DealershipSale
              ? null
              : values?.chasisNo,
          hoursMeter: Number(values?.hoursMeter),
          manufacturingDate: values?.manufacturingYear
        }
      };

      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    },

    validationSchema: LeadFormValidation
  });

  const leadSource = enumToItems(LeadSource);
  if (loading) {
    return (
      <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputAutoComplete
            disableClearable={true}
            onChange={(value) => {
              formik.setValues({
                ...formik.values,
                source: value
              });
            }}
            disabled={true}
            value={formik.values.source}
            placeholder="Purchase Type*"
            options={leadSource}
            optionString="label"
            getOptionLabel={(option) => option.label}
          />
          {formik.errors.source && formik.touched.source ? (
            <div style={{ color: 'red' }}>{formik.errors?.source}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Input
            value={formik.values.regNo}
            name="regNo"
            onChange={(e) => formik?.setFieldValue('regNo', e.target.value)}
            placeholder="Registration Number*"
            disabled={true}
          />
          {formik.values.regNo &&
          (formik.errors.regNo || formik.touched.regNo) ? (
            <div style={{ color: 'red' }}>{formik.errors?.regNo}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <InputAutoComplete
            onChange={(value) => {
              formik.setValues({
                ...formik.values,
                model: null,
                make: value
              });

              GetAllNewModelsByMake({ variables: { id: value?.id } });
            }}
            value={formik?.values?.make}
            placeholder="Make*"
            options={allBrands?.queryBrand ?? []}
            optionString="name"
            getOptionLabel={(option) => option?.name}
          />
          {formik.errors.make && formik.touched.make ? (
            <div style={{ color: 'red' }}>{formik.errors?.make}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <InputAutoComplete
            value={formik.values.model}
            disabled={!formik.values.make}
            options={modelOptions?.queryBrand?.[0]?.models ?? []}
            optionString="name"
            name="model"
            onChange={(value) => {
              formik.setFieldValue('model', value);
            }}
            placeholder="Model*"
          />
          {formik.errors.model && formik.touched.model ? (
            <div style={{ color: 'red' }}>{formik.errors?.model}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year']}
              openTo="year"
              value={formik?.values?.manufacturingYear ?? null}
              onChange={(newValue) => {
                formik?.setFieldValue('manufacturingYear', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoCorrect="off"
                  size="small"
                  id="outlined-basic"
                  autoComplete="off"
                  variant="outlined"
                  value={formik?.values?.manufacturingYear}
                  style={{
                    width: '100%'
                  }}
                  error={false}
                  label={'Select Manufacturing Year*'}
                  placeholder={'Select Manufacturing Year'}
                />
              )}
              minDate={new Date('2000-01-01')}
              maxDate={new Date(`${new Date().getFullYear() + 1}-01-01`)}
            />
          </LocalizationProvider>
          {formik.errors.manufacturingYear &&
          formik.touched.manufacturingYear ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.manufacturingYear}
            </div>
          ) : null}
        </Grid>
        {formik?.values?.source?.value === 'BANK_AUCTION' && (
          <Grid item xs={12}>
            <InputAutoComplete
              onChange={(value) => {
                formik.setFieldValue('bankName', value);
              }}
              value={formik.values.bankName}
              placeholder="Bank Name*"
              options={BankNameOptions}
              optionString="label"
              getOptionLabel={(option) => option.label}
            />
            {formik.errors.bankName && formik.touched.bankName ? (
              <div style={{ color: 'red' }}>{formik.errors?.bankName}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'BANK_AUCTION' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.engineNo}
              name="EngNo"
              onChange={(e) =>
                formik?.setFieldValue('engineNo', e.target.value)
              }
              placeholder="Engine Number*"
            />
            {formik.values.engineNo &&
            (formik.errors.engineNo || formik.touched.engineNo) ? (
              <div style={{ color: 'red' }}>{formik.errors?.engineNo}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'BANK_AUCTION' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.chasisNo}
              name="ChasNo"
              onChange={(e) =>
                formik?.setFieldValue('chasisNo', e.target.value)
              }
              placeholder="Chassis Number*"
            />
            {formik.values.chasisNo &&
            (formik.errors.chasisNo || formik.touched.chasisNo) ? (
              <div style={{ color: 'red' }}>{formik.errors?.chasisNo}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'DEALERSHIP_SALE' && (
          <Grid item xs={12}>
            <InputAutoComplete
              value={formik.values.ownershipType}
              name="ownershipType"
              options={enumToItems(Ownership)}
              onChange={(val) => formik?.setFieldValue('ownershipType', val)}
              placeholder="Ownership Type*"
            />
            {formik.errors.ownershipType && formik.touched.ownershipType ? (
              <div style={{ color: 'red' }}>{formik.errors?.ownershipType}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'DEALERSHIP_SALE' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.hoursMeter}
              name="hoursMeter"
              onChange={(e) =>
                formik?.setFieldValue('hoursMeter', e.target.value)
              }
              placeholder="Hours Meter*"
            />
            {formik.values.hoursMeter &&
            (formik.errors.hoursMeter || formik.touched.hoursMeter) ? (
              <div style={{ color: 'red' }}>{formik.errors?.hoursMeter}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'DEALERSHIP_SALE' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.demandedAmount}
              name="demandedAmount"
              onChange={(e) =>
                formik?.setFieldValue('demandedAmount', e.target.value)
              }
              placeholder="Demanded Amount*"
            />
            {formik.values.demandedAmount &&
            (formik.errors.demandedAmount || formik.touched.demandedAmount) ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.demandedAmount}
              </div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'DEALERSHIP_SALE' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.remarks}
              name="remarks"
              onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
              placeholder="Remarks"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <InputAutoComplete
            onChange={(value) => {
              formik.setFieldValue('status', value);
            }}
            value={formik.values.status}
            placeholder="Lead Status*"
            options={enumToItems(LeadBusinessStatus)}
            optionString="label"
            getOptionLabel={(option) => option.label}
          />
          {formik.errors.status && formik.touched.status ? (
            <div style={{ color: 'red' }}>{formik.errors?.status}</div>
          ) : null}
        </Grid>
      </Grid>
      <div style={{ marginBottom: '100px' }}>
        <CtaButton
          desireButtonText={desireButtonText}
          undesireButtonText={undesireButtonText}
          backBtn={canGoBack}
          disabled={!(formik.isValid && formik.dirty)}
          // onPressUndesireButton={()=>      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
          // }
        />
      </div>
    </form>
  );
};

export default LeadUpdate;
