import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import React, { Fragment } from 'react';
import {
  LeadRef,
  LeadStatus,
  useGetLeadDealDetailsQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import './form.css';
import { useFormik } from 'formik';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';
import { formatDate } from 'src/utils/utility';
function BankConfirmation(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    onPressOfDesiredOrUndesiredButton,
    canGoBack,
    desireButtonText,
    undesireButtonText
  } = props;

  const { data: dealData } = useGetLeadDealDetailsQuery({
    variables: { id: leadId }
  });

  // log('Lead input value at ApproveDeal', leadInput)

  const proposedAmount = dealData?.getLead?.proposedBidLimitAmount ?? '-';
  const dealAmount = dealData?.getLead?.finalBidAmount ?? '-';
  // const dealDate = dealData?.getLead?.createdAt
  //   ? new Date(dealData?.getLead?.createdAt)?.toDateString()
  //   : '-';

  const dealDate =  dealData?.getLead?.statusEvents?.find(
    (event) => event?.status === LeadStatus.BidWon
  )?.createdAt;

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <h4 style={{ textDecoration: 'underline' }}>Deal Details</h4>

      <TableContainer
        sx={{ mt: 4, backgroundColor: '#f6f6f6' }}
        component={Paper}
      >
        <Table>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ fontWeight: 600 }} component="th" scope="row">
                Proposed Amount
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {proposedAmount}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
                Deal Amount
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 600 }}>
                {dealAmount}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
                Deal Date
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 600 }}>
                {new Date(dealDate).toDateString()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        undesireButtonText={undesireButtonText}
        greenRedcombo={true}
        disabled={false}
        reject={reject}
      />
    </form>
  );
}

export default BankConfirmation;
