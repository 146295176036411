import React from 'react';
import { LeadDetailsPropsType } from './ApplicationDetails';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import {
  LeadSource,
  LeadStatus,
  PaymentFor,
  PaymentStatus,
  RefurbishmentStatus,
  UserRole
} from 'src/generated/hooks_and_more';
import { getUserData } from 'src/utils/utility';

export default function PricingAndListingDetails({
  currentLead
}: LeadDetailsPropsType) {
  const userDetails = getUserData();
  const dealAmountRefurb = currentLead?.payments?.find(
    (p) => p?.for === PaymentFor.DealPayment && p?.status === PaymentStatus.Done
  )?.amount;

  const tokenAmountRefurb = currentLead?.payments?.find(
    (p) => p?.for === PaymentFor.DealToken && p?.status === PaymentStatus.Done
  )?.amount;

  const documentChargesforPO = currentLead?.documentCharges;

  const totalIssueItemsPrice = currentLead?.refurbishmentDetails?.requests
    ?.map((req) =>
      req?.issue?.items
        ?.filter((item) => item?.isApproved)
        ?.reduce((initialPrice, i) => i?.price + initialPrice, 0)
    )
    ?.filter((item) => item)
    ?.reduce((initialCost, eachRefurbReq) => eachRefurbReq + initialCost, 0);

  const transportationChargesOnLeadRefurbishment =
    currentLead?.refurbishmentDetails?.requests
      ?.filter(
        (req) =>
          req?.requestStatus === RefurbishmentStatus.Approved &&
          !!req?.transportationCharge
      )
      ?.reduce(
        (initialValue, req) => req?.transportationCharge + initialValue,
        0
      ) ?? 0;

  let refCost = 0;
  let currentSp = 0
  currentLead?.refurbishmentDetails?.requests?.map((i) => {
    
      if (i?.issue?.status === 'ISSUED') {
        i?.issue?.items?.map((i) => {
          return (refCost = refCost + i?.price);
        });
      }
      if (
        i?.purchase?.payment?.status === PaymentStatus?.Done &&
        i?.issue?.status === 'ISSUED'
      ) {
        return (currentSp = currentSp + i?.transportationCharge);
      }
    
  });

  refCost = refCost + currentSp
  const expensesRefurb = currentLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.DeliveryExpense && p?.status === PaymentStatus.Done
  )?.amount;
  const loanAmountRefurb = currentLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.LoanRepayment && p?.status === PaymentStatus.Done
  )?.amount;
  const holdbackAmountRefurb = currentLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.HoldbackRepayment &&
      p?.status === PaymentStatus.Done
  )?.amount;
  const dealDeliveryRefurb = currentLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.DealDelivery && p?.status === PaymentStatus.Done
  )?.amount;
  const parkingRefurb = currentLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.ParkingExpense && p?.status === PaymentStatus.Done
  )?.amount;
  const totalBankAuction = Number(
    (!!dealAmountRefurb ? dealAmountRefurb : 0) +
      (!!expensesRefurb ? expensesRefurb : 0) +
      (!!parkingRefurb ? parkingRefurb : 0) +
      (!!refCost ? refCost : 0)
  );
 
  let pricingAndListingDetails = [];
  let dealerPayment
  if (userDetails.role !== UserRole?.CentreManager) {
    if (
      !holdbackAmountRefurb &&
      currentLead?.source === LeadSource.DealershipSale
    ) {
      dealerPayment =
        (dealAmountRefurb ? dealAmountRefurb : 0) -
        (loanAmountRefurb ? loanAmountRefurb : 0) -
        (documentChargesforPO ? documentChargesforPO : 0);
      dealerPayment = dealerPayment < 0 ? 0 : dealerPayment;

      pricingAndListingDetails.push({
        key: 'Dealer payment',
        value: Boolean(dealerPayment) ? dealerPayment : '-'
      });
    }

    const totalDealership =
    (!!dealDeliveryRefurb ? dealDeliveryRefurb : 0) +
    (!!tokenAmountRefurb ? tokenAmountRefurb : 0) +
    (!!expensesRefurb ? expensesRefurb : 0) +
    (!!holdbackAmountRefurb ? holdbackAmountRefurb : 0) +
    (!!loanAmountRefurb ? loanAmountRefurb : 0) + 
    (!!refCost ? refCost : 0) + (Boolean(dealerPayment > 0) ? dealerPayment : 0);


    if (currentLead?.source === LeadSource.BankAuction) {
      pricingAndListingDetails.push(
        {
          key: 'Deal payment',
          value: dealAmountRefurb ?? '-'
        },
        {
          key: 'Parking Payment',
          value: parkingRefurb ?? '-'
        }
      );
    }
    if (currentLead?.source === LeadSource.DealershipSale) {
      pricingAndListingDetails = [
        ...pricingAndListingDetails,

        {
          key: 'Delivery payment',
          value: dealDeliveryRefurb ?? '-'
        },
        {
          key: 'Token payment',
          value: tokenAmountRefurb ?? '-'
        },
        {
          key: 'Holdback payment',
          value: holdbackAmountRefurb ?? '-'
        },
        {
          key: 'Loan payment',
          value: loanAmountRefurb ?? '-'
        }
        // {
        //   key: 'Driver Expenses payment',
        //   value: expensesRefurb ?? '-'
        // },
      ];
    }

    pricingAndListingDetails = [
      ...pricingAndListingDetails,

      {
        key: 'Refurbishment payments',
        value: refCost ?? '-'
      },
      {
        key: 'Total',
        value:
          currentLead?.source === LeadSource.BankAuction
            ? totalBankAuction
            : totalDealership
      }
    ];
  }

  pricingAndListingDetails = [
    ...pricingAndListingDetails,
    {
      key: 'Selling price',
      value: currentLead?.sellingPrice ?? '-'
    },
    {
      key: 'Listing Price',
      value: currentLead?.listingPrice ?? '-'
    }
  ];
  return <DetailsTabPanel data={pricingAndListingDetails} />;
}
