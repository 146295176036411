import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Link,
  Modal,
  Paper,
  RadioGroup,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses
} from '@mui/material';
import Radio from '@mui/material/Radio';
import Table from '@mui/material/Table';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { LeadDetailsPropsType } from '../../screens/leadDetail/component/ApplicationDetails';
import { useLocation, useNavigate, useParams } from 'react-router';
import DocumentUpdate from 'src/screens/documents/UploadDocuments';
import CloseIcon from '@mui/icons-material/Close';
import {
  ActionType,
  DocumentChecklist,
  DocumentChecklistRef,
  DocumentsCount,
  Lead,
  useAddDocumentsMutation,
  useAddFollowupsMutation,
  useDocumentRequiredMutation,
  useGetLeadDetailQuery
} from 'src/generated/hooks_and_more';
import ConfimationModal from './ConfirmationModal/ConfimationModal';
import { useToasts } from 'react-toast-notifications';
import { Delete } from '@mui/icons-material';
import { replace } from 'formik';

type DetailsOptions = {
  key: string;
  queryKey: string;
  value?: any;
  type?: string;
  textColor?: string;
  isHidden?: boolean;
  isRequired?: boolean;
  metaData?: { title: string; data: { key: string; value: string | number }[] };
};

type DocumentsListTabPropsType = {
  documentCheckList: DocumentChecklistRef | null;
  data: DetailsOptions[];
  countRefetch: () => void;
  currCounts?: any;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const widthStyle = {
  width: '50%'
};

const DocumentStatus = location?.pathname.includes('documents')
  ? ['Docs Required', 'is Required?', 'Action']
  : ['Docs Required', 'is Required?', ''];

const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  // height: '80%',

  //   border: '2px solid #000',
  boxShadow: 24,
  backgroundColor: 'white',
  backdropFilter: 'blur(2px)',
  borderRadius: '10px',
  p: 4
};
const CloseBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',

  cursor: 'pointer',
  color: 'rgb(195, 62, 62)'
};
const editStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '55%',
  height: '520px',
  backgroundColor: 'white',
  //   border: '2px solid #000',
  boxShadow: 24,

  backdropFilter: 'blur(2px)',
  borderRadius: '10px',
  p: 1
};
const editCloseBtnStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '10px',
  paddingRight: '10px',
  cursor: 'pointer',
  color: 'rgb(195, 62, 62)'
};

function DocumentsListTab({
  data,
  documentCheckList
}: DocumentsListTabPropsType) {
  const [addDocument] = useAddDocumentsMutation();
  const [addFollowupsMutation] = useAddFollowupsMutation();
  const params = useParams();
  const [on, SetOn] = useState(false);
  const { addToast } = useToasts();
  const [docRequired, { loading, error }] = useDocumentRequiredMutation();
  const [requiredList, setRequiredList] = useState({});
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [documentsList, setDocumentsList] = useState<DetailsOptions[] | null>(
    null
  );
  const location = useLocation();


 
  useEffect(() => {
    setDocumentsList([...data]);
  }, [data]);
  const [documentName, setDocumentName] = useState(null);

  const closeModal = () => {
    setModal(false);
  };
  const [checked, setChecked] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [DelOpen, setDelOpen] = useState(false);
  // useEffect(() => {
  //   if (checked) {
  //     countRefetch((prev) => ({
  //       ...prev,
  //       required: prev?.required - 1,
  //       pending: prev?.required - prev?.received - 1
  //     }));
  //   } else {
  //     countRefetch((prev) => ({
  //       ...prev,
  //       required: prev?.required + 1,
  //       pending: prev?.required - prev?.received + 1
  //     }));
  //   }
  // }, [checked]);
  const handleChange = () => {
    const muatated = [];
    documentsList?.map((i, index) => {
      let obj;
      Object.keys(requiredList)?.map((j) => {
        if (i?.queryKey === j) {
          obj = { ...i, isHidden: requiredList[j] };
        }
      });
      if (obj) {
        muatated.push(obj);
      } else {
        muatated.push(i);
      }
    });

    // const arr = [...documentsList];
    // const obj = { ...arr[index], isHidden: checked };
    // arr[index] = { ...obj };
    // countRefetch();
    setDocumentsList([...muatated]);
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Modal
        open={modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={CloseBtnStyle}>
            <CloseIcon
              onClick={() => {
                setModal(false);
              }}
            />
          </Box>
          <DocumentUpdate
            handleClose={closeModal}
            documentName={documentName}
          />
        </Box>
      </Modal>

      <Modal
        open={editOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={editStyle}>
          <Box sx={editCloseBtnStyle}>
            <h6 style={{ color: 'black' }}>Document update</h6>
            <CloseIcon
              onClick={() => {
                setEditOpen(false);
              }}
            />
          </Box>
          <div style={{ padding: '10px' }}>
            <TableContainer
              sx={{ height: 400, overflowY: 'auto', borderRadius: 0 }}
              component={Paper}
            >
              <Table sx={{ minWidth: 300 }} aria-label="customized table">
                <TableBody>
                  {documentsList?.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={widthStyle}
                      >
                        {item.key}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            defaultValue={item?.isHidden ? 'yes' : 'no'}
                            sx={{
                              width: '200px',
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              onClick={() => {
                                setRequiredList({
                                  ...requiredList,
                                  [item?.queryKey]: true
                                });
                              }}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              onClick={() => {
                                setRequiredList({
                                  ...requiredList,
                                  [item?.queryKey]: false
                                });
                              }}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            >
              <Button
                sx={{ mt: 2, width: '20%' }}
                variant="contained"
                onClick={() => SetOn(true)}
              >
                Submit
              </Button>
              <ConfimationModal
                textToShow="Do you want to confirm changes ?"
                open={on}
                handleClose={() => SetOn(false)}
                toastMsg={on}
                onConfirmationHandler={() => {
                  let obj = { ...requiredList };
                  if (documentCheckList === null) {
                    documentsList.map((docList) => {
                      if (requiredList[docList?.queryKey] === undefined) {
                        obj[docList?.queryKey] = true;
                      }
                    });
                  }

                  docRequired({
                    variables: {
                      checklistPatch: {
                        ...obj,
                        lead: { regNo: params?.leadId },
                        regNo: params?.leadId
                      }
                    },
                    onCompleted() {
                      handleChange();
                      setEditOpen(false);

                      navigate(
                        `${location.pathname}?t=${Math.random() * 100}`,
                        { replace: true }
                      );

                      SetOn(false);
                      addToast('Updated Successfully', {
                        appearance: 'success',
                        autoDismiss: true
                      });
                    }
                  });
                }}
              />
            </Box>
          </div>
        </Box>
      </Modal>

      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableBody>
          {location?.pathname.includes('documents') ? (
            <TableRow style={{ backgroundColor: '#EFEFEF' }} sx={widthStyle}>
              {DocumentStatus?.map((header, index) => (
                <StyledTableCell sx={{ fontWeight: 600 }} key={index}>
                  {header}
                  {header === 'is Required?' && (
                    <Button onClick={() => setEditOpen(true)}>Edit</Button>
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          ) : (
            <TableRow style={{ backgroundColor: '#EFEFEF' }} sx={widthStyle}>
              {DocumentStatus?.map((header, index) => (
                <StyledTableCell sx={{ fontWeight: 600 }} key={index}>
                  {header}
                  {header === 'is Required?'}
                </StyledTableCell>
              ))}
            </TableRow>
          )}

          {documentsList?.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row" sx={widthStyle}>
                  {item.key}
                </StyledTableCell>

                <StyledTableCell style={{ display: 'flex' }}>
                  <Typography style={{ paddingTop: '5px', marginRight: '5px' }}>
                    {item?.isHidden ? 'Yes' : 'No'}
                  </Typography>
                </StyledTableCell>

                {(item.type === 'image' ||
                  (item.type === 'map' && item.isHidden)) &&
                item.isHidden === true &&
                item.value !== '-' &&
                item.value ? (
                  <StyledTableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <Link
                        href={item.value}
                        style={{
                          color: 'rgb(17,25,42)',
                          textDecoration: 'underline'
                        }}
                        underline="hover"
                        target="_blank"
                      >
                        View
                      </Link>
                     { location?.pathname.includes('documents') ?
                      <IconButton
                        aria-label="delete"
                        size="small"
                        color="error"
                        onClick={() => {
                          setDelOpen(true);
                        }}
                      >
                        <Delete fontSize="inherit" />
                      </IconButton> : null }
                      <ConfimationModal
                        textToShow="Do you want to delete the document ?"
                        open={DelOpen}
                        handleClose={() => setDelOpen(false)}
                        toastMsg={on}
                        onConfirmationHandler={() => {
                          const variabelesForDoc = {
                            lead: { regNo: params?.leadId },
                            regNo: params?.leadId,
                            [item?.queryKey]: ''
                          };
                          addDocument({
                            variables: {
                              addLeadDocuments: { ...variabelesForDoc }
                            },
                            onCompleted() {
                              navigate(
                                `${location.pathname}?t=${Math.random() * 100}`,
                                { replace: true }
                              );

                              setDelOpen(false);
                            }
                          });
                          addFollowupsMutation({
                            variables: {
                              regNo: params?.leadId,

                              documentName: item?.key,
                              followupDate: new Date(),
                              actionType: ActionType?.Delete,
                              updatedBy: JSON.parse(
                                localStorage?.getItem('userData')
                              )?.userId
                            },
                            onCompleted() {
                              navigate(
                                `${location.pathname}?t=${Math.random() * 100}`,
                                { replace: true }
                              );

                              SetOn(false);
                              //     setInvoiceUrl('');
                              addToast('Deleted successfully!!!', {
                                appearance: 'success',
                                autoDismiss: true
                              });
                            }
                          });
                        }}
                      />
                    </div>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell>
                    {location?.pathname.includes('documents') ? (
                      <div style={{ display: 'flex' }}>
                        <div>
                          {item?.isHidden ? (
                            <Link
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setModal(true);
                                setDocumentName(item);
                              }}
                            >
                              Upload{' '}
                            </Link>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    ) : null}
                  </StyledTableCell>
                )}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default DocumentsListTab;
