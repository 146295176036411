import { Box, Button, Grid, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { shadows } from '@mui/system';
import {
  ActionType,
  ModuleType,
  TaskStatus,
  useFilterlogLazyQuery,
  useLogTrackingLazyQuery,
  useLogTrackingQuery
} from 'src/generated/hooks_and_more';
import {
  cacheLogFilter,
  checkWhetherAnythingisUpdated,
  forlogtitleCase,
  formatDate,
  formatDatelog,
  getJSONDepth,
  getNextedValue,
  getampmtime,
  handleJustEnquiry,
  titleCaseToReadable
} from 'src/utils/utility';
import './style.css';
import { lastDayOfDecade } from 'date-fns';
import { flattenObject } from '../dashboard/data';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterForLogs from './filter';
import { useReactiveVar } from '@apollo/client';

function LogsTracking() {
  const filterInput = useReactiveVar(cacheLogFilter);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [collectLogs, setCollectLogs] = useState([]);
  const [offsetCount, setOffsetCount] = useState(0);
  const [forButtonLoad, setForbuttonLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [reset, setreset] = useState(null);
  const [mainReset, setMainReset] = useState(false);
  const [getFilterData, { data: filterData, loading: filterLoading }] =
    useFilterlogLazyQuery();
  const [getlogsTracking, { data, loading }] = useLogTrackingLazyQuery();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const handleClose = () => setOpen(false);
  useEffect(() => {

    if (filterInput) {

      getFilterData({
        variables: { ...filterInput.variable },
        onCompleted(data) {
          setCollectLogs([...data?.queryLogTracking]);
        },
        onError(err) {
        }
      });
    }
    setreset(filterInput);
  }, [filterInput]);

  function setModal(value: boolean) {
    setOpen(value);
  }

  useEffect(() => {
    cacheLogFilter(null);
    if (!filterInput) {
      getlogsTracking({
        variables: { offset: offsetCount * 6 },
        fetchPolicy: 'network-only',
        onCompleted(data) {
          setCollectLogs([...collectLogs, ...data?.queryLogTracking]);
        }
      });
    }
  }, [offsetCount]);

  function handleMainreset() {
    setOffsetCount(0);
    setCollectLogs([]);
    cacheLogFilter(null);
    setForbuttonLoad(false);
    getlogsTracking({
      variables: { offset: offsetCount * 6 },
      fetchPolicy: 'network-only',
      onCompleted(data) {
        setCollectLogs([...data?.queryLogTracking]);
      }
    });
  }
  const labels=  [
    "fieldInspectionDate",
   "loginDate",
   "deliveryOrderDate",
   "fieldInspectionConfirmedDate",
"updatedAt"
  ]

  return (
    <>
      <Grid
        item
        sm={12}
        md={12}
        mt={2}
        sx={{
          display: 'flex',
          marginRight: '3rem',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '10px'
        }}
      >
        <FilterForLogs
          filterLoading={filterLoading}
          handleMainreset={handleMainreset}
          setMainReset={setMainReset}
          mainReset={mainReset}
          reset={reset}
          open={open}
          handleClose={handleClose}
          setOpen={setOpen}
          setModal={setModal}
        />
      </Grid>

      <Box
        p={2}
        sx={{
          border: '2px',
          margin: '1rem 1rem 1rem 1rem',
          boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
          minHeight: '50vh',
          width: '100%'
        }}

      >
        {loading && Boolean(collectLogs?.length <= 0) ? (
          <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : collectLogs?.length > 0 ? (
          collectLogs?.map((i, index) => {
            let previousData = [];
            let data;
            let value1;
            let value2;
            if (i) {
              if (i.actionType === ActionType.Create && i.module === ModuleType.Inventory) {
                data = i.status === TaskStatus.Failed ? null : getNextedValue(i.actionType, i.updatedValue);
              } else if (i.actionType === ActionType.Update) {
                if (i.module === ModuleType.Enquiry) {
                  value1 = value2 = i.status === TaskStatus.Failed ? null : handleJustEnquiry(JSON.parse(i.updatedValue));
                } else if (i.module === ModuleType.Inventory) {
                  data = i.status === TaskStatus.Failed ? null : getNextedValue(i.actionType, i.updatedValue);
                } else if (
                  [ModuleType.Enquiry, ModuleType.Inventory, ModuleType.ListingPrice].every(module => 
                    i.module !== module && Boolean(i.updatedValue)
                  )
                ) {
                  previousData = checkWhetherAnythingisUpdated(
                    i.previousValue ? JSON.parse(i.previousValue) : '',
                    i.updatedValue ? JSON.parse(i.updatedValue) : ''
                  );
                }
              }
            }
            let flatten = [];
            let CreateData;
            if ((i?.actionType === ActionType.Create) || (i?.actionType == ActionType?.Update && i?.module === ModuleType.ListingPrice) || (i?.actionType == ActionType?.Delete && i?.module === ModuleType.Procurement) || Boolean(i?.actionType === ActionType.Update && i?.module === ModuleType.Inventory)) {
              CreateData = Boolean(i?.updatedValue) ? JSON.parse(i?.updatedValue) : null;
              let CreateData1 = Boolean(CreateData) ? typeof CreateData === 'string' ? CreateData : flattenObject(CreateData) : null;

              if (typeof CreateData1 === 'string') {
                flatten.push({
                  label: 'data',
                  data: CreateData1.toString()
                });
              } else {
                if (Boolean(CreateData1) && typeof CreateData1 !== 'string') {
                  Object.keys(CreateData1).forEach((item) => {
                    if (Array.isArray(CreateData1[item])) {
                      flatten.push({
                        label: item,
                        data: CreateData1[item].join(', ')
                      });
                    } else {
                      flatten.push({
                        label: item,
                        data: CreateData1[item]
                      });
                    }
                  });
                } else {
                  flatten.push({
                    label: 'No changes',
                    data: 'Operation was failed'
                  });

                }

              }


            }


            function handleitems(data) {
              return data?.map((obj, index) => (
                <>
                  <p>Price: {obj.price}</p>
                  <p>Product Name: {titleCaseToReadable(obj?.productName)}</p>
                  <p>Purchase Centre ID: {obj?.purchaseCentreId}</p>
                  <p>Quantity: {obj?.quantity}</p>
                </>
              ));
            }
          
            function showData() {
              return (
                <>
                  {Object.keys(flatten)?.map((item) => {
                    if (
                      flatten[item]?.label === 'createdAt' ||
                      flatten[item]?.label === 'updatedAt' ||
                      flatten[item]?.label === 'listingOnWebUpdatedAt'
                    ) {
                      return (
                        <>
                          <p key={item} style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 600 }}> {`${forlogtitleCase(
                              flatten[item].label
                            )}`} → </span>  {`${formatDate(flatten[item].data)}`}
                          </p>{' '}
                        </>

                      );
                    } else if (flatten[item]?.label === 'items') {
                      return (
                        <div key={item} style={{ marginBottom: '10px' }}>
                          {handleitems(data)}
                        </div>
                      );
                    } else if (flatten[item]?.label === 'invoiceUrl') {
                      return (
                        <div key={item}>
                          {titleCaseToReadable(flatten[item].label)} →{' '}
                          <Link
                            href={`${flatten[item].data}`}
                            style={{
                              color: 'rgb(17,25,42)'
                            }}
                            underline="hover"
                            target="_blank"
                          >
                            View
                          </Link>
                        </div>
                      );
                    } else {
                      return (
                        <>
                          <p key={item} style={{ marginBottom: '10px' }}>
                            <span style={{ fontWeight: 600 }}> {`${forlogtitleCase(
                              flatten[item].label
                            )}`} → </span>  {`${forlogtitleCase(flatten[item].data)}`}
                          </p>{' '}
                        </>
                      );
                    }
                  })}
                </>
              );
            }

            return (
              <Grid key={index} className="logmain">
                <Grid
                  key={index}
                  sx={{ display: 'flex', gap: 3, fontSize: '16px' }}
                >
                  <Grid>{index + 1}</Grid>
                  <Grid style={{ width: '100%' }}>
                    {<span style={{ fontWeight: 600 }}>{`${forlogtitleCase(i?.taskPerformedBy?.name)}`}</span>}

                    {` As ${titleCaseToReadable(
                      i?.taskPerformedBy?.role
                    )} 
                    
                     has ${titleCaseToReadable(
                      i?.actionType == 'CREATE' ? "Created" : i?.actionType == 'UPDATE' ? "Updated" : " Deleted"
                    )} for`} {<span style={{ fontWeight: 600 }}>{`${forlogtitleCase(i?.module)}`}</span>}
                    {' '}
                    {`${i?.sourceUpdated
                      } on ${formatDate(i?.createdAt)} at ${getampmtime(
                        i?.createdAt
                      )}  from ${titleCaseToReadable(i?.source)}`}{' '}
                    <br />
                    <div className="container">
                      <div className="box" style={{ padding: '13px 0px 0px 20px' }}> <span>Change Type:</span> {titleCaseToReadable(i?.actionType)}  </div>
                      <div className="box">  {Boolean(i?.actionType !== ActionType.Create) && Boolean(i?.module !== ModuleType.Inventory && i?.module !== ModuleType.ListingPrice) && Boolean(i?.actionType !== ActionType.Delete && i?.module !== ModuleType.Procurement) ? (

                        <Accordion
                          sx={{ width: '100%', padding: '0px', margin: '0px' }}
                          expanded={expanded === `panel1${index}`}
                          onChange={handleChange(`panel1${index}`)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                            sx={{ padding: '0px' }}
                          >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                              <span>Input:</span>{' '}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                              View Changes
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={{ paddingTop: '0px' }}>
                            {previousData?.length > 0 ? (
                              previousData?.map((i, index) => (
                                <p key={index}>
                                  {labels.includes(i?.label)
                                    ?
                                    (<> <span style={{ fontWeight: 600 }}>{`${forlogtitleCase(i?.label)}`} :  </span>{` ${formatDatelog(
                                      i?.previous
                                    )} → ${formatDatelog(i?.current)}`}</>)
                                    : (<> <span style={{ fontWeight: 600 }}>{`${forlogtitleCase(i?.label)}`} :</span>{` ${forlogtitleCase(
                                      i?.previous
                                    )} → ${forlogtitleCase(i?.current)}`}</>)

                                  }{' '}
                                  <br />
                                </p>
                              ))
                            ) : (
                              <>
                                {Boolean(value1) && Boolean(value2) ? (
                                  <div
                                    className="columns"
                                    style={{ display: 'flex' }}
                                  >
                                    <div className="column">
                                    <p style={{ fontWeight: 500 }}>
                                        Previous Values: 
                                      </p>
                                      {value2.map((value, index) => (
                                        <p style={{padding: '5px'}}  key={index}>{value}</p>
                                      ))}
                                    </div>
                                    <div className="column">
                                      <p style={{ fontWeight: 500 }}>
                                        Updated Values:
                                      </p>
                                      {Object.keys(value1).map((keys) => (
                                        <p style={{padding: '5px'}} key={index}>{value1[keys]}</p>
                                      ))}
                                    </div>
                                  </div>
                                ) : (
                                  <p>No change done</p>
                                )}
                              </>
                            )}

                            <br />
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <Accordion
                          sx={{ width: '100%', padding: '0px', margin: '0px' }}
                          expanded={expanded === `panel${index}`}
                          onChange={handleChange(`panel${index}`)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                            sx={{
                              padding: '0px',
                              maxHeight: '0.1rem',
                              margin: '0px',
                              '& .MuiAccordionSummary-content': {
                                margin: '0px'
                              }
                            }}
                          >
                            <Typography
                              sx={{
                                width: '33%',
                                flexShrink: 0,
                                paddingLeft: '0px'
                              }}
                            >
                              <span>Input:</span>{' '}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                              View Details
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>{showData()}</AccordionDetails>
                        </Accordion>
                      )}</div>
                      <div className="box" style={{ padding: '10px 0px 0px 20px' }}><span>Action status: </span> <span style={i?.status == TaskStatus.Success ? { color: "green" } : { color: "red" }}>{titleCaseToReadable(i?.status)}</span></div>
                    </div>



                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid sx={{ textAlign: 'center', fontSize: '20px' }}>No history</Grid>
        )}
        {(loading && forButtonLoad) || (filterLoading && forButtonLoad) ? (
          <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : (
          !loading && !filterInput && (
            <button
              onClick={() => {
                if (!filterInput) {
                  setOffsetCount(offsetCount + 1);
                }
                setForbuttonLoad(true);
              }}
              className="button"
            >
              Load More
            </button>
          )
        )}
      </Box>
    </>
  );
}

export default LogsTracking;
