import React from 'react';
import { LeadRef } from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { Box, Button, Grid } from '@mui/material';
import Input from 'src/components/Form/Input';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';

import { useFormik } from 'formik';
import { SelfieUploadSchema } from '../ValidationSchema/ValidationSchema';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

function SelfieUpload(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    onPressOfDesiredOrUndesiredButton
  } = props;
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      selfieImage: '',
      remarks: ''
    },
    validationSchema: SelfieUploadSchema,
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        deliveredSelfieUrl: values?.selfieImage
      };

      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <div>
      <h6>Upload Selfie With the Center</h6>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12}>
            <FileUploadToS3
              id="selfieImage"
              accept="image/*"

              value={formik.values.selfieImage}
              onChange={(value) => {
                formik.setFieldValue('selfieImage', value);
              }}
              placeholder="Selfie Image*"
            />
            {formik.errors.selfieImage && formik.touched.selfieImage ? (
              <div style={{ color: 'red' }}>{formik.errors?.selfieImage}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.remarks}
              name="remarks"
              onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
              placeholder="Enter the Remarks"
            />
          </Grid>
        </Grid>
        <CtaButton
          desireButtonText={desireButtonText}
          undesireButtonText={undesireButtonText}
          backBtn={canGoBack}
          disabled={!(formik.isValid && formik.dirty)}
          reject={reject}
        />
      </form>
    </div>
  );
}

export default SelfieUpload;
