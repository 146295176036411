import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import Input from 'src/components/Form/Input';
import { AddStockTypeSchema } from './ValidationSchema';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { Fragment, useEffect, useState } from 'react';
import { AutoCompleteValueType } from 'src/types';
import {
  ActionType,
  ModuleType,
  PurchaseItemRef,
  PurchaseStatus,
  SparePart,
  TaskStatus,
  useAddLogsMutation,
  useAddStocksMutation,
  useGetCentreListQuery
} from 'src/generated/hooks_and_more';
import { useToasts } from 'react-toast-notifications';
import { cachedLeadFilter, enumToItems } from 'src/utils/utility';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { useNavigate } from 'react-router';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
// import FileUploadToS3 from 'src/components/Form/FileUploadToS3';

function AddStockType() {
  const [centerNameValue, setCenterNameValue] = useState<
    AutoCompleteValueType | any
  >(null);

  const [errorCentreName, setErrorCentreName] = useState('');
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const navigation = useNavigate();
  const { addToast } = useToasts();
  const [invoiceError, setInvoiceError] = useState('');
  const center = useGetCentreListQuery({
    fetchPolicy: 'no-cache'
  });
  const [addStocksMutation, { data, loading, error }] = useAddStocksMutation();
  const sparePart = enumToItems(SparePart);
  const [AddLogs] = useAddLogsMutation();
  const { loggedInUser } = useLoggedInUser();

  const userData = JSON?.parse(localStorage.getItem('userData'));
  useEffect(() => {
    cachedLeadFilter(null);
  }, []);
  console.log({ invoiceUrl });

  return (
    <Box sx={{ mt: 3 }} display={'flex'} justifyContent={'center'}>
      <Box width={'80%'}>
        <Card>
          <Typography sx={{ my: 1, mx: 2 }} variant="h3">
            Add Stocks
          </Typography>
          <Divider />
          <CardContent>
            <Grid container sx={{ mb: 2 }} spacing={2}>
              <Grid item xs={12} md={6}>
                <InputAutoComplete
                  onChange={(value) => {
                    setCenterNameValue(value);
                    if (errorCentreName) setErrorCentreName('');
                  }}
                  value={centerNameValue}
                  placeholder="Center Name"
                  options={center?.data?.queryCentre ?? []}
                  optionString="name"
                />
                {errorCentreName ? (
                  <div style={{ color: 'red' }}>{errorCentreName}</div>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FileUploadToS3
                  id="Upload-Invoice"
                  value={invoiceUrl}
                  onChange={(value) => {
                    setInvoiceUrl(value);
                    setInvoiceError('');
                  }}
                  placeholder="Upload Invoice"
                />
                {invoiceError ? (
                  <div style={{ color: 'red' }}>{invoiceError}</div>
                ) : null}
                {/* <FileUploadToS3
                  id="rtoVerificationProof"
                  value={invoiceUrl}
                  onChange={(value) => {
                    setInvoiceUrl(value);
                    console.log(value);
                    // setFieldValue('rtoVerificationProof', value);
                  }}
                  placeholder="Upload Invoice"
                /> */}
              </Grid>
            </Grid>
            <Formik
              initialValues={{
                items: [
                  {
                    type: null,
                    // centerName: '',
                    unitPrice: '',
                    quantity: '',
                    tax: '',
                    invoiceUrl: '',
                    totalPrice: null
                  }
                ]
              }}
              validationSchema={AddStockTypeSchema}
              onSubmit={(values, action) => {
                // Handle form submission
                console.log({ values });

                if (!centerNameValue) {
                  console.log('centere name error');
                  setErrorCentreName('Centre name is required');
                } else if (!invoiceUrl) {
                  setInvoiceError('Invoice is required');
                } else {
                  const items: Partial<PurchaseItemRef>[] = values?.items?.map(
                    (item) => {
                      return {
                        price: Number(item?.unitPrice),
                        product: {
                          name: item?.type?.label
                        },
                        quantity: Number(item?.quantity),
                        purchase: {
                          centre: {
                            id: centerNameValue?.id
                          }
                        }
                      };
                    }
                  );

                  const urlPrefix = invoiceUrl.split('/').pop();

                  addStocksMutation({
                    variables: {
                      centreId: centerNameValue?.id,
                      items: items,
                      madeById: userData?.userId,
                      createdAt: new Date(),
                      status: PurchaseStatus.Received,
                      invoiceUrl: `https://vms-app-assets-pdf.s3.ap-south-1.amazonaws.com/${urlPrefix}`
                    },
                    onCompleted() {
                      action.resetForm();
                      AddLogs({
                        variables: {
                          taskPerformedBy: { id: loggedInUser?.userId},
                          actionType: ActionType.Create,
                          module: ModuleType.Inventory,
                          createdAt: new Date(),
                          sourceUpdated: items?.[0]?.product?.name,
                          updatedValue: JSON.stringify({ data: {  centreId: centerNameValue?.id,
                            items: items,
                            madeById: userData?.userId,
                            createdAt: new Date(),
                            status: PurchaseStatus.Received,
                            invoiceUrl: `https://vms-app-assets-pdf.s3.ap-south-1.amazonaws.com/${urlPrefix}`} }),
                          previousValue: "",
                          status: TaskStatus.Success
                        },
                        onCompleted(data){
                          console.log(data, "KKKKK")

                        }
                      });
                      addToast('Purchase Item added successfully!!!', {
                        appearance: 'success',
                        autoDismiss: true
                      });
                      navigation(`/inventory/center/${centerNameValue?.id}`);
                    },
                    onError(error) {
                      addToast('Something went wrong!!!', {
                        appearance: 'error',
                        autoDismiss: true
                      });
                      console.log('error', error);
                    }
                  });
                }
              }}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                errors,
                touched,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>
                  <FieldArray name="items">
                    {({ push, remove }) => (
                      <div>
                        {values.items.map((_, index) => (
                          <Fragment key={index}>
                            <Grid
                              container
                              sx={{ mb: 2 }}
                              spacing={2}
                              key={index}
                            >
                              <Grid item xs={12} md={6}>
                                <InputAutoComplete
                                  value={values.items[index].type}
                                  key="stockType"
                                  options={sparePart}
                                  onChange={(value) => {
                                    console.log(value);
                                    setFieldValue(
                                      `items[${index}].type`,
                                      value
                                    );
                                  }}
                                  placeholder="Stock Type"
                                />
                                {/* <Input
                                  name={`items[${index}].type`}
                                  placeholder="Stock Type"
                                  value={values.items[index].type}
                                  onChange={handleChange}
                                /> */}
                                <ErrorMessage
                                  render={(msg) => (
                                    <div style={{ color: 'red' }}>
                                      * Required
                                    </div>
                                  )}
                                  name={`items[${index}].type`}
                                />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <Input
                                  value={values.items[index].unitPrice}
                                  name={`items[${index}].unitPrice`}
                                  placeholder="Unit Price"
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  render={(msg) => (
                                    <div style={{ color: 'red' }}>{msg}</div>
                                  )}
                                  name={`items[${index}].unitPrice`}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Input
                                  placeholder="Quantity"
                                  value={values.items[index].quantity}
                                  name={`items[${index}].quantity`}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  render={(msg) => (
                                    <div style={{ color: 'red' }}>{msg}</div>
                                  )}
                                  name={`items[${index}].quantity`}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Input
                                  placeholder="Tax in (%)"
                                  name={`items[${index}].tax`}
                                  value={values.items[index].tax}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  render={(msg) => (
                                    <div style={{ color: 'red' }}>{msg}</div>
                                  )}
                                  name={`items[${index}].tax`}
                                />
                              </Grid>
                              {/* <Grid item xs={12} md={6}>
                                <FileUpload id="invoiceUrl"
                                  value={values.items[index].invoiceUrl}
                                  onChange={(value) => {
                                    alert(2);
                                    setFieldValue('invoiceUrl', value);
                                  }}
                                  placeholder="Invoice Url" />

                              </Grid> */}
                              <Grid item xs={12} md={6}>
                                <Input
                                  placeholder="Total Price"
                                  name={`items[${index}].totalPrice`}
                                  value={String(
                                    Number(values.items[index].quantity) *
                                      Number(values.items[index].unitPrice)
                                  )}
                                  onChange={handleChange}
                                  disabled={true}
                                />
                                <ErrorMessage
                                  render={(msg) => (
                                    <div style={{ color: 'red' }}>{msg}</div>
                                  )}
                                  name={`items[${index}].unitPrice`}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                {index === values.items.length - 1 ? (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      push({
                                        type: null,
                                        centerName: '',
                                        unitPrice: '',
                                        quantity: '',
                                        price: ''
                                      });
                                    }}
                                  >
                                    Add
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                            {console.log({ errors })}
                          </Fragment>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <Button disabled={loading} variant="contained" type="submit">
                    Confirm Purchase
                  </Button>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default AddStockType;
