import { FC, Fragment, useRef, useState } from 'react';

import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from '@mui/material';
import useIsOverflow from 'src/customHooks/hooks';

import './style.css';
interface RecentOrdersTableProps {
  className?: string;
  header: { title: string; search?: boolean; dbKey?: string }[];
  counts?: number[];
  children: JSX.Element[] | JSX.Element;
  loading?: boolean;
  handleGlobalSearch?: (value: string) => void;
  TextAlign?: 'right' | 'center';
}

const TableWrapper: FC<RecentOrdersTableProps> = ({
  header,
  children,
  TextAlign,
  counts,
  handleGlobalSearch,
  loading
}) => {
  const [scrollX, setScrollX] = useState(0);

  const handleScrollLeft = () => {
    const table = containerRef.current;
    table.scrollLeft -= 200;
    setScrollX(table.scrollLeft);
  };
  const handleScrollRight = () => {
    console.log('scrollRightClicked');

    const table = containerRef.current;
    table.scrollLeft += 200;
    console.log(table.scrollLeft);
    setScrollX(table.scrollLeft);
  };

  const containerRef = useRef(null);
  const isOverflow = useIsOverflow(containerRef);

  console.log({ isOverflow });
  return (
    <Card>
      <Divider />
      <TableContainer ref={containerRef}>
        {isOverflow?.isOverflow && (
          <Fragment>
            <img
              src="/right.png"
              width="30px"
              height="30px"
              alt="right"
              onClick={handleScrollRight}
              style={{
                position: 'fixed',
                top: '90%',
                right: '2%',
                zIndex: '1000px'
              }}
              className="rightIcon"
            />
            <img
              src="/left.png"
              width="30px"
              height="30px"
              onClick={handleScrollLeft}
              style={{
                position: 'fixed',
                top: '90%',
                right: '7%',
                zIndex: '1000px'
              }}
              className="rightIcon"
            />
          </Fragment>
        )}
        <Table>
          <TableHead>
            <TableRow>
              {header.map((item, index) => {
                let columnCount: number;
                if (item?.dbKey) {
                  if (counts?.[0]?.[item?.dbKey] === undefined) {
                    columnCount = 0;
                  } else {
                    columnCount = counts?.[0]?.[item?.dbKey]?.reduce(
                      (initial: number, current: number) =>
                        Number(initial) + Number(current)
                    );
                  }
                }
                return (
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      textTransform: 'capitalize',
                      textAlign: TextAlign ? TextAlign : 'left'
                    }}
                    key={`lead-list-${index}`}
                  >
                    {item.title +
                      (columnCount != undefined ? ` (${columnCount})` : '')}
                    {/* {item?.search ? (
                      <LeadSearchModal
                        handleGlobalSearch={handleGlobalSearch}
                      />
                    ) : null} */}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <Box p={2}></Box>
    </Card>
  );
};

export default TableWrapper;
