import { TableCell, IconButton, TableRow, TableCellProps } from '@mui/material';
import { Booking, Lead, EnquiryStatus } from 'src/generated/hooks_and_more';
import Cell from './Cell';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import routeConstant from 'src/constants/routeContant';
import { ActiveBookingDetails } from 'src/types';
import Navigation from 'src/navigation';
import './BookingListRow.css';
import { titleCaseToReadable } from 'src/utils/utility';
type BookingListRowProps = {
  // enquiry?: Partial<Lead>,
  enquiry?: ActiveBookingDetails;
};
function BookingListRow({ enquiry }: BookingListRowProps) {
  const navigate = useNavigate();
  return (
    <TableRow
      onClick={() => {
          navigate(`/vms-admin/booking/${enquiry?.regNo}`, {
            state: {
              regNo: enquiry?.regNo,
              booking: enquiry?.bookingId
            }
          });
        
      }}
      className={enquiry?.status !== 'CANCELLED' ? 'bookingRow' : ''}
    >
      <TableCell>{enquiry?.regNo}</TableCell>
      <TableCell>{enquiry?.name}</TableCell>

      {/* <TableCell>{enquiry?.name}</TableCell> */}
      <TableCell>{enquiry?.phoneNo}</TableCell>
      <TableCell> {titleCaseToReadable(enquiry?.state)}
</TableCell>
      <TableCell>{enquiry?.district}</TableCell>
      <TableCell>{enquiry?.status}</TableCell>
      <TableCell>{enquiry?.statusDate}</TableCell>
      <TableCell>{enquiry?.paymentMode}</TableCell>

      <TableCell>{enquiry?.saleAmount}</TableCell>
      <TableCell>{enquiry?.balanceAmount}</TableCell>

      <TableCell>{enquiry?.createdBookingAt}</TableCell>

      <TableCell>{enquiry?.updateAt}</TableCell>

      <TableCell>
        <IconButton
          disabled={!(!!enquiry?.activeLoanId || !!enquiry.bookingPaymentId)}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/vms-admin/${routeConstant.EDIT_BOOKING}`, {
              state: enquiry
            });
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default BookingListRow;
