import { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button, TextField } from '@mui/material';
import { Fragment } from 'react';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import {
  ActionType,
  useAddDocumentsMutation,
  useAddFollowupsMutation
} from 'src/generated/hooks_and_more';
import * as Yup from 'yup';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { cachedLeadFilter } from 'src/utils/utility';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import { documentChecklist } from 'src/constants/constant';
import ConfimationModal from 'src/components/Composite/ConfirmationModal/ConfimationModal';
import { useNavigate, useParams } from 'react-router';

export const DocumentsSchema = Yup.object().shape({
  documentName: Yup.object().required('*Required')
});
function DocumentUpdate({ handleClose, documentName }) {
  const params = useParams();
  const navigate = useNavigate();
  const [addFollowupsMutation, { data, loading, error }] =
    useAddFollowupsMutation();
  const [addDocument] = useAddDocumentsMutation();
  const [followupVariables, setFollowupVariables] = useState(null);
  const [on, SetOn] = useState(false);
  const { addToast } = useToasts();

  const [invoiceURL, setInvoiceUrl] = useState('');
  const [invoiceError, setInvoiceError] = useState('');

  useEffect(() => {
    cachedLeadFilter(null);
  }, []);

  return (
    <Fragment>
      <Box sx={{ p: 2 }} display={'flex'} justifyContent={'center'}>
        <Box>
          <Typography sx={{ mb: 3 }} variant="h3">
            Update Documents
          </Typography>

          <Formik
            initialValues={{
              documentName: {
                label: documentName?.key,
                value: documentName?.queryKey
              },
              invoiceURL: '',
              followupDate: null,
              comments: ''
            }}
            validationSchema={DocumentsSchema}
            onSubmit={(values, action) => {
              // Handle form submission
              const variables = {
                regNo: params?.leadId,
                comments: values?.comments,
                documentName: values?.documentName?.label,
                followupDate: new Date(),
                nextFollowupDate: values?.followupDate,
                actionType: invoiceURL
                  ? ActionType?.Create
                  : ActionType?.Update,
                updatedBy: JSON.parse(localStorage?.getItem('userData'))
                  ?.userId,
                source: 'Admin Panel'
              };
              setFollowupVariables(variables);

              SetOn(true);
            }}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              touched,
              setValues,
              resetForm
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container sx={{ mb: 2 }} spacing={2}>
                  <Grid item md={6} xs={12}>
                    <InputAutoComplete
                      value={values?.documentName}
                      placeholder="Document Name"
                      options={documentChecklist}
                      onChange={(value) => {
                        setFieldValue('documentName', value);
                      }}
                    />
                    {errors?.documentName && touched?.documentName ? (
                      <div style={{ color: 'red' }}>{errors?.documentName}</div>
                    ) : null}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DatePickerCalender
                      onChange={(val) => setFieldValue('followupDate', val)}
                      placeholder="Next FollowUp Date"
                      minDate={new Date()}
                      value={
                        values.followupDate
                          ? new Date(values?.followupDate)
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FileUploadToS3
                      id="Upload-Invoice"
                      value={invoiceURL}
                      onChange={(value) => {
                        // setFieldValue('invoiceURL', value);

                        setInvoiceUrl(value);

                        setInvoiceError('');
                      }}
                      placeholder="Upload Document"
                    />
                    {invoiceError ? (
                      <div style={{ color: 'red' }}>{invoiceError}</div>
                    ) : null}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-uncontrolled"
                      label="Comments"
                      onChange={(e) =>
                        setFieldValue('comments', e?.target?.value)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12} sx={{ display: 'flex', gap: '20px' }}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      !invoiceURL && !values?.followupDate && !values?.comments
                    }
                  >
                    Update
                  </Button>
                  <ConfimationModal
                    textToShow="Do you want to confirm changes ?"
                    open={on}
                    handleClose={() => SetOn(false)}
                    toastMsg={on}
                    onConfirmationHandler={() => {
                      const urlPrefix = invoiceURL.split('/').pop();
                      const variabelesForDoc = {
                        lead: { regNo: params?.leadId },
                        regNo: params?.leadId,

                        [values?.documentName
                          ?.value]: `https://vms-app-assets-pdf.s3.ap-south-1.amazonaws.com/${urlPrefix}`
                      };
                      if (urlPrefix) {
                        addDocument({
                          variables: {
                            addLeadDocuments: { ...variabelesForDoc }
                          }
                        });
                      }

                      addFollowupsMutation({
                        variables: followupVariables,
                        onCompleted() {
                          navigate(
                            `${location.pathname}?t=${Math.random() * 100}`,
                            { replace: true }
                          );
                          handleClose(() => {
                            SetOn(true);
                          });
                          SetOn(false);
                          //     setInvoiceUrl('');
                          addToast('Added successfully!!!', {
                            appearance: 'success',
                            autoDismiss: true
                          });
                        }
                      });
                    }}
                    reset={resetForm}
                  />
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Fragment>
  );
}

export default DocumentUpdate;
