import React, { Fragment, useState } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import TableWrapper from 'src/components/Tables';
import { STOCKLIST_TABLE_HEADER } from 'src/constants/constant';
import { Typography, Button, Grid, Box, styled, Card } from '@mui/material';
import StockDetailListRow from 'src/components/Composite/StockDetailListRow';
import {
  Issue,
  ItemInventoryDetails,
  Purchase,
  useGetCentrePurchaseAndIssuesQuery,
  useGetStocksDetailsLazyQuery,
  useGetStocksIndividualLazyQuery,
  useGetStocksIndividualQuery
} from 'src/generated/hooks_and_more';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InventoryDetailCard from 'src/components/Composite/InventoryDetailCard';
import { getDesireDateFormate } from 'src/utils/utility';
import routeConstant from 'src/constants/routeContant';
import IssueListRow from 'src/components/Composite/IssueListRow';
import StockPopOverData from './StockPop';

function StockDetails() {
  const { Id, centreId } = useParams();
  const location = useLocation();
  const [dataStock, setDataStock] =  useState([])

  const stockData: any = location.state;
  const navigate = useNavigate(); 
  const  { data: stockDetailData, loading: stockLoading } =
    useGetStocksIndividualQuery({
      variables: {centreId: centreId},
      onCompleted(data){
        let dataplace = []
       dataplace = data?.inventoryDetails?.items?.filter((i) => i.id === stockData)
        setDataStock(dataplace)
      }

    });
   
      const { data, loading, error } = useGetCentrePurchaseAndIssuesQuery({
    skip: !Id,
    variables: {
      productId: [Id],
      centreId: centreId
    },
    fetchPolicy: 'no-cache'
  });

  const details = [
    { key: 'Available in Stock', value: dataStock[0]?.availableInStock },
    { key: 'Issue in Queue', value: dataStock[0]?.issueQueue },
    { key: 'Issued', value: dataStock[0]?.consumed },
    // {
    //   key: 'Created By',
    //   value: '-'
    // },
    {
      key: 'Created Date',
      value: dataStock[0]?.createdAt
        ? getDesireDateFormate(dataStock[0]?.createdAt)
        : '-'
    },
    { key: 'Avg Unit Price', value: dataStock[0]?.avgUnitPrice },

    {
      key: 'Total Price',
      value: Number(dataStock[0]?.avgUnitPrice) * dataStock[0]?.totalPurchase
    }
  ];

  let items = [];
  if (data?.getCentre?.purchases) {
    items = [...data?.getCentre?.purchases];
  }

  if (data?.getCentre?.issues) {
    items = [...items, ...data?.getCentre?.issues];
  }
  const [inventoryData, setInventoryData] = useState(null);
  const [purchaseId, setPurchaseId] = useState(null);
const [status, setStatus] = useState(null)
  function handleIndex(index, purchaseId, status) {
    setInventoryData(index);
    setStatus(status)
    setPurchaseId(purchaseId)
    setTimeout(() => {
      setOpen(true);

    }, 100)
  }

  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }
  return (
    <Fragment>
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {stockData?.product?.name}
            </Typography>
          </Grid>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            {/* <Grid item>
              <Button
                sx={{ mt: { xs: 2, md: 0 }, mr: 2 }}
                variant="contained"
                onClick={() => {
                  navigate(
                    `${routeConstant.INVENTORY}/${routeConstant.ADD_STOCK}`
                  );
                }}
              >
                Buy Stock
              </Button>
            </Grid> */}
          </Box>
        </Grid>
      </PageTitleWrapper>
      <InventoryDetailCard details={details} />
      <Box sx={{ px: 2, pb: 2 }}>
        {loading ? (
          <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : (
          <TableWrapper header={STOCKLIST_TABLE_HEADER}>
            <Fragment>
              {items
                ?.sort(
                  (a, b) =>
                    Number(Date.parse(b.createdAt)) -
                    Number(Date.parse(a.createdAt))
                )
                ?.map((item, index) => {
                  return (
                    <StockDetailListRow
                      handleIndex={handleIndex}
                      index={index}
                      key={item?.id}
                      purchases={item}
                      status={item?.status}
                      productName={stockData?.product?.name}
                    />
                  );
                })}
            </Fragment>
            <StockPopOverData
              open={open}
              status={status}
              purchaseId={purchaseId}
              handleClose={handleClose}
            />
          </TableWrapper>
        )}
      </Box>
    </Fragment>
  );
}

export default StockDetails;
