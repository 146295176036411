import {
  ImageStage,
  LeadRef,
  useGetDocumentDetailsFormQuery
} from 'src/generated/hooks_and_more';

import { Divider, Box, Grid } from '@mui/material';
import { LeadCreateAndUpdateProps } from 'src/types';

import { Button } from '@mui/material';
import CtaButton from '../CTA_Button/CtaButton';
import { useFormik } from 'formik';
import { ActionType } from 'src/constants/constant';


function ConfirmDeal(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    undesireButtonText,
    onPressViewImagesButton,
    canGoBack
  } = props;

  const { data: getLeadData, loading: getLeadLoading } =
    useGetDocumentDetailsFormQuery({
      variables: {
        id: leadId
      }
    });
  const leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });

  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };

  function handleViewAllImages() {}
  const documentAvailable = getLeadData?.getLead;
  let confirmData: {
    key: any;
    value: any;
  }[] = [
    {
      key: 'Demand Amount',
      value: ` ${documentAvailable?.demandAmount} INR`
    },
    {
      key: 'Make',
      value: documentAvailable?.vehicle?.vehicleBrand?.name
    },
    {
      key: 'Model',
      value: documentAvailable?.vehicle?.vehicleModel?.name
    },
    {
      key: 'Year',
      value: new Date(
        documentAvailable?.vehicle?.manufacturingDate
      ).getFullYear()
    },
    {
      key: 'Ownership',
      value: documentAvailable?.ownershipType
    },
    {
      key: 'Hours Meters',
      value: documentAvailable?.vehicle?.hoursMeter
    },
    {
      key: 'Registration no',
      value: documentAvailable?.regNo
    }
  ];

  return (
    <>
      {getLeadLoading ? (
        <div
          style={{ padding: '30px' }}
          className="d-flex flex-column  justify-content-center align-items-center gap-2"
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Box mt={2}>
            <Grid
              container
              spacing={2}
              sx={{
                border: '#C5C5C5',
                backgroundColor: '#f6f6f6',
                borderRadius: '0.5rem',
                boxShadow:
                  ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              }}
            >
              {confirmData?.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={
                      item?.key === 'Demand Amount'
                        ? {
                            display: 'flex',
                            fontSize: '18px',
                            padding: '1rem',
                            backgroundColor: '#C5C5C5',
                            borderRadius: '0.5rem 0.5rem 0rem 0rem',
                            fontWeight: 'bold'
                          }
                        : {
                            display: 'flex',
                            fontSize: '16px',
                            padding: '1rem'
                          }
                    }
                    key={index}
                  >
                    <Grid item xs={6} md={6}>
                      {item?.key}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginLeft: 'auto',
                        color: 'black'
                      }}
                    >
                      {item?.value}
                    </Grid>
                  </Grid>
                );
              })}
              <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                fontSize: '16px',
                padding: '1rem'
              }}
            >
              <Grid item xs={6} md={6}>
                Images
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  marginLeft: 'auto'
                }}
              >
                <Button variant="contained" onClick={() => {onPressViewImagesButton()}}>
                  View All
                </Button>
              </Grid>
            </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  width: '100%'
                }}
              >
                <CtaButton
                  desireButtonText={desireButtonText}
                  undesireButtonText={undesireButtonText}
                  backBtn={canGoBack}
                  disabled={false}
                  onlyRed={true}
                  reject={reject}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    </>
  );
}

export default ConfirmDeal;
