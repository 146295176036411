import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from '@mui/material';
import Input from 'src/components/Form/Input';
import {
  BookingType,
  LeadRef,
  LeadStatus,
  PaymentFor,
  PaymentMethod,
  PaymentStatus,
  useGetDeliveryPaymentQuery,
  useGetLeadDealAmountsQuery,
  useGetPartPaymentQuery
} from 'src/generated/hooks_and_more';
import {
  LeadFlowFormsInputs,
  enumToItems,
  getBalanceAmountForBooking
} from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';

import { LeadCreateAndUpdateProps } from 'src/types';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';

import { ActionType } from 'src/constants/constant';
import {
  BookingDeliveryPaymentSchema,
  BookingPartValidation,
  DealPaymentPendingSchema
} from '../../ValidationSchema/ValidationSchema';
import CtaButton from '../CTA_Button/CtaButton';
import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router';

const BookingPartPaymentForm = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    onPressOfDesiredOrUndesiredButton,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    regNo,
    leadId,
    paymentFor
  } = props;

  const { data: deliveryPaymentData } = useGetPartPaymentQuery({
    variables: {
      regNo: regNo
    }
  });
  const formInputs = useReactiveVar(LeadFlowFormsInputs);

  

  let leadInput: LeadRef = {
    regNo: regNo,
    id: deliveryPaymentData?.getLead?.id
  };
  let rejectInput = {
    id: leadId,
    regNo: regNo
  };

  const paymentModeOptions = enumToItems(PaymentMethod);
  // const payFor = PaymentFor;
  const saleAmount =
    deliveryPaymentData?.getLead?.activeBooking?.bookingPayment?.saleAmount;
  const bookingPayment = deliveryPaymentData?.getLead?.activeBooking?.payments;
  const isRcTransferRequired =
    deliveryPaymentData?.getLead?.activeBooking?.isRCTransferReq;
  const isInsuranceRequired =
    deliveryPaymentData?.getLead?.activeBooking?.isInsuranceReq;
  const isLoanRequired =
    deliveryPaymentData?.getLead?.activeBooking?.bookingPayment?.bookingType ===
    BookingType.Finance;
  const appliedLoanAmount =
    deliveryPaymentData?.getLead?.activeBooking?.activeLoan?.appliedLoanAmount;
  const sanctionedLoanAmount =
    deliveryPaymentData?.getLead?.activeBooking?.activeLoan
      ?.sanctionedLoanAmount;
  const deliveryCharges = getBalanceAmountForBooking(
    bookingPayment,
    saleAmount,
    isRcTransferRequired,
    isInsuranceRequired,
    isLoanRequired,
    appliedLoanAmount,
    sanctionedLoanAmount
  );

  const formik = useFormik({
    initialValues: {
      paymentMode: null,
      partAmount: '',
      paymentDate: '',
      paymentReceipt: '',
      remarks: ''
    },
    validationSchema: BookingPartValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      LeadFlowFormsInputs({
        ...formInputs,
        tokenAmount: values?.partAmount
      });
     
      leadInput = {
        regNo: regNo,
        id: deliveryPaymentData?.getLead?.id,
        activeBooking: {
          payments: [
            {
              mode: values?.paymentMode?.value as PaymentMethod,
              amount: Number(values?.partAmount),
              for: paymentFor,
              status: PaymentStatus.Requested,
              createdAt: values?.paymentDate,
              proofUrl: values?.paymentReceipt
            }
          ]
        }
      };

      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(rejectInput, '', ActionType.UNDESIRED);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid>
          <Typography variant="h5" sx={{ mb: 3, ml: 3 }}>
            {`Balance Payment Amount  :- ${deliveryCharges}`}{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.paymentMode}
            name="paymentMode"
            options={paymentModeOptions}
            onChange={(val) => {
              formik?.setFieldValue('paymentMode', val);
            }}
            placeholder="Select Payment Mode*"
          />
          {formik.errors.paymentMode && formik.touched.paymentMode ? (
            <div style={{ color: 'red' }}>{formik.errors?.paymentMode}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.partAmount}
            inputType="number"
            name="partAmount"
            onChange={(e) => {
              formik?.setFieldValue('partAmount', e.target.value);
            }}
            placeholder="Enter the Payment amount in INR*"
          />
          {formik.values.partAmount &&
          (formik.touched.partAmount || formik.errors.partAmount) ? (
            <div style={{ color: 'red' }}>{formik.errors?.partAmount}</div>
          ) : null}
        </Grid>
       

        <Grid item xs={12} md={12}>
          <DatePickerCalender
            maxDate={new Date()}
            onChange={(value) => {
              formik.setFieldValue('paymentDate', value);
            }}
            placeholder="Enter the Payment Date*"
            value={
              formik.values.paymentDate
                ? new Date(formik.values.paymentDate)
                : null
            }
          />
          {formik.errors.paymentDate && formik.touched.paymentDate ? (
            <div style={{ color: 'red' }}>{formik.errors?.paymentDate}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="paymentReceipt"
            value={formik.values.paymentReceipt}
            onChange={(value) => {
              formik.setFieldValue('paymentReceipt', value);
            }}
            placeholder="Payment Receipt*"
          />
          {formik.errors.paymentReceipt && formik.touched.paymentReceipt ? (
            <div style={{ color: 'red' }}>{formik.errors?.paymentReceipt}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.remarks}
            name="remarks"
            onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
            placeholder="Enter the Remarks"
          />
        </Grid>
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        reject={reject}
        onlyRed={true}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
};

export default BookingPartPaymentForm;
