import React, { useState } from 'react';
import * as Yup from 'yup';

import {
  GetLeadRefurbishmentDetailsQuery,
  GetLeadRefurbishmentDetailsQueryResult,
  LeadRef,
  RefurbishmentStatus,
  useGetCenterFormLeadQuery,
  useGetLeadRefurbishmentDetailsQuery,
  useGetStocksDetailsLazyQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import CtaButton from '../CTA_Button/CtaButton';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { ErrorMessage, useFormik } from 'formik';
import { ActionType } from 'src/constants/constant';
import Input from 'src/components/Form/Input';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { getSparePartStockStatus } from './GetSparePartStatus';
import { titleCaseToReadable } from 'src/utils/utility';

function InstallationBillUploaded(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    refurbishmentRequestId,
    onPressOfDesiredOrUndesiredButton
  } = props;

  const [AmountUpdate, setAmountUpdate] = useState<GetLeadRefurbishmentDetailsQuery>();
  const { data: refurbishmentDetailsData } =
    useGetLeadRefurbishmentDetailsQuery({
      variables: {
        leadId: leadId
      },
      onCompleted(data) {

       setAmountUpdate(data)
      },
    });
  const [getStocks, { data: stockDetailData }] = useGetStocksDetailsLazyQuery();


  const { data: centerData } = useGetCenterFormLeadQuery({
    variables: {
      leadId: leadId
    },
    onCompleted(data) {
      getStocks({ variables: { centreId: data?.getLead?.centre?.id } });
    }
  });
  function getSparePartStockStatus(value: string) {
    const sparePartStockStatus = stockDetailData?.inventoryDetails?.items?.find(
      (i) => {
        if ('OTHERS' === titleCaseToReadable(value)?.toUpperCase()) {
          return 0;
        } else {
          return (
            i?.product?.name?.toUpperCase() ===
            titleCaseToReadable(value)?.toUpperCase()
          );
        }
      }
    );

    return sparePartStockStatus?.availableInStock > 0 ? true : false;
  }

  const approvedItems =
    refurbishmentDetailsData?.queryLead?.[0]?.refurbishmentDetails?.requests?.find(
      (req) => {
        return req?.id === refurbishmentRequestId;
      }
    )?.purchase?.items ?? [];

  const requests =
    refurbishmentDetailsData?.queryLead?.[0]?.refurbishmentDetails?.requests;
  const purchase_id = requests?.[requests?.length - 1]?.purchase?.id ?? null;

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      transportation: false,
      transportationCharges: null,
      error: approvedItems?.map((i) => {
        return { amount_check: true };
      }),
      amounts: approvedItems?.map((i) => {
        return { billAmount: i?.approvedPriceLimit };
      }),
      items: approvedItems?.map((i) => {
        return {
          id: i?.id,
          billAmount: null,
          document: null,
          product_id: i?.product?.id,
          product_name: i?.product?.name
        };
      })
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      transportation: Yup.boolean(),
      amount: Yup.array().of(
        Yup.object().shape({
          billAmount: Yup.number()
        })
      ),
      transportationCharges: Yup.number()
        .typeError('*Numbers only allowed')
        .required('*Required')
        .when('transportation', {
          is: true,
          otherwise: (schema) => schema.notRequired()
        }),
      items: Yup.array().of(
        Yup.object()
          .shape({
            document: Yup.string().required()
          })
          .required('*Required')
      )
    }),

    onSubmit: (values) => {
      const purchaseItems = values?.items?.map((item) => ({
        id: item?.id,
        price: Number(item?.billAmount),
        purchaseProofUrl: item?.document,
        product: {
          id: item?.product_id,
          name: item?.product_name
        }
      }));

     

      let leadInput: LeadRef = {
        id: leadId,
        regNo: regNo,
        refurbishmentDetails: {
          requests: [
            {
              id: refurbishmentRequestId,
              items: AmountUpdate?.queryLead?.[0]?.refurbishmentDetails?.requests?.find((i) => i?.id === refurbishmentRequestId)?.items,
              hasTransportationCharge: values?.transportation,
              transportationCharge: values?.transportation
                ? Number(values?.transportationCharges)
                : 0,
              purchase: {
                id: purchase_id,

                items: purchaseItems
              }
            }
          ]
        }
      };
      // console.log(leadInput, 'Con', desireStatus);
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <TableContainer
        sx={{ mt: 4, backgroundColor: '#f6f6f6' }}
        component={Paper}
      >
        <Table>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ fontWeight: 600 }} component="th" scope="row">
                Approved Purchase
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                Approved Amount
              </TableCell>
            </TableRow>
            {approvedItems?.map((item) => (
              <TableRow
                key={item?.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ fontWeight: 600 }}>
                  {item?.product?.name} <p>{item?.description}</p>
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: 600 }}>
                  {item?.approvedPriceLimit}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {approvedItems?.length
        ? approvedItems?.map((item, index) => (
          <Grid
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'column',
              gap: '10px'
            }}
            item
            xs={12}
            md={12}
            key={item?.id}
          >
            <Typography variant="h5">{item?.product?.name} - {item?.description}</Typography>
            {/* <Typography variant="h5">{item?.description}</Typography> */}

            <Input
              placeholder="Bill Amount"
              name={`items[${index}].billAmount`}
              onChange={(e) => {
                if (
                  formik?.values?.amounts?.[index]?.billAmount >=
                  Number(e?.target?.value)
                ) {
                  formik.setFieldValue(`error[${index}].amount_check`, false);
                } else {
                  formik.setFieldValue(`error[${index}].amount_check`, true);
                }
                formik.setFieldValue(
                  `items[${index}].billAmount`,
                  e.target.value
                );

                const refurbishmentDetails = JSON.parse(JSON.stringify(AmountUpdate))
                refurbishmentDetails?.queryLead?.[0]?.refurbishmentDetails?.requests
                  ?.find(req => req?.id === refurbishmentRequestId)
                  ?.items?.map((itemValue, i) => {
                    delete itemValue?.__typename
                      delete itemValue?.product?.__typename
              
                    if (itemValue?.id == item?.requestedItemId) {
                      itemValue.price =  Number(e.target?.value);
                    }
                    // return item
                  })

                    setAmountUpdate(refurbishmentDetails)

              }}
            />

            {formik?.values?.error?.[index]?.amount_check &&
              formik?.values?.items?.[index]?.billAmount ? (
              <p style={{ color: 'red' }}>
                *Bill amount cannot be greater than approved amount{' '}
              </p>
            ) : null}

            <FileUploadToS3
              accept="image/*,.pdf"
              id={`document${index}`}
              value={formik.values?.items?.[index]?.document}
              onChange={(value) => {
                formik.setFieldValue(`items[${index}].document`, value);
              }}
              placeholder="Upload document*"
            />
          </Grid>
        ))
        : null}
      <Grid item xs={12} md={12}>
        <FormControl sx={{ mt: 4 }}>
          <Typography variant="h4">Is transporatation charges*</Typography>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue={'no'}
            sx={{
              width: '300px',
              marginLeft: '10px',
              marginTop: '10px',
              display: 'flex',
              gap: '10px'
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              onClick={() => formik.setFieldValue('transportation', true)}
              label="Yes"
            />
            <FormControlLabel
              value="no"
              onClick={() => formik.setFieldValue('transportation', false)}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
        {formik.values.transportation && (
          <Grid sx={{ mt: 2 }} item xs={12} md={6}>
            <Input
              onChange={(e) =>
                formik.setFieldValue('transportationCharges', e.target.value)
              }
              placeholder="Enter the Transportation Charges"
            />
            {formik.values.transportationCharges &&
              (formik.errors.transportationCharges ||
                formik.touched.transportationCharges) ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.transportationCharges}
              </div>
            ) : null}
          </Grid>
        )}
      </Grid>

      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        undesireButtonText={undesireButtonText}
        disabled={
          Boolean(
            formik.values.error?.filter((i) => i?.amount_check === true)?.length
          ) || Boolean(Object.keys(formik.errors)?.length)
        }
        reject={reject}
      />
    </form>
  );
}

export default InstallationBillUploaded;
