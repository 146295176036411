import { Route, Routes } from 'react-router';
import routeConstant from './constants/routeContant';
import ProtectedRoute from './layouts/ProtectedRoute';
import SidebarLayout from './layouts/SidebarLayout';
import Dashboard from './screens/dashboard';
import UpdateLeads from './screens/editLeads';
import Enquiry from './screens/enquiry';
import AddCenter from './screens/inventory/AddCenter';
import Stock from './screens/inventory/AddStock';
import AddStockType from './screens/inventory/AddStockType';
import CenterDetails from './screens/inventory/CenterDetails';
import CenterList from './screens/inventory/CenterList';
import EditCentre from './screens/inventory/EditCentre';
import LeadsInCentre from './screens/inventory/LeadsInCentre';
import StockDetails from './screens/inventory/StockDetail';
import TransferStock from './screens/inventory/TransferStock';
import LeadDetail from './screens/leadDetail';
import Leads from './screens/leads';
import Login from './screens/login';
import AddUsers from './screens/users/AddUsers';
import Users from './screens/users';
import EditUsers from './screens/users/EditUser';
import NotFound from './not-found';
import Booking from './screens/booking';
import BookingEdit from './screens/booking/edit';
import EnquiryDetail from './screens/enquiry/enquiryDetails';
import DocumentLists from './screens/documents';
import LeadProcess from './screens/lead-flow';
import MyPendingTask from './screens/lead-flow/task';
import EnquiryDashboard from './screens/enquiryDashboard';
import { UserRole } from './generated/hooks_and_more';
import PaymentInvoice from './screens/invoice';
import Refurbishment from './screens/refurbishment';
import LogsTracking from './screens/logtracking';
const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="invoice/:invoiceId" element={<PaymentInvoice />} />
      <Route
        path="/vms-admin"
        element={
          <ProtectedRoute>
            <SidebarLayout />
          </ProtectedRoute>
        }
      >
        {/* <Route index element={<Dashboard />} /> */}
        {/* <Route path ="create-or-update-leads" element={<LeadProcess />} /> */}
        <Route path="my-pending-task" element={<MyPendingTask />} />
        <Route path="leads" element={<Leads />} />
        <Route path="booking" element={<Booking />} />
        <Route path="booking/:leadId" element={<LeadDetail />} />
        <Route path="enquiry" element={<Enquiry />} />
        <Route path="refurbishments" element={<Refurbishment />} />
        <Route path="enquiry/:id" element={<EnquiryDetail />} />
        <Route path="enquiry-dashboard" element={<EnquiryDashboard />} />
        <Route path={routeConstant.EDIT_BOOKING} element={<BookingEdit />} />
        <Route path="leads/:leadId" element={<LeadDetail />} />
        <Route path="leads/edit/:leadId" element={<UpdateLeads />} />
        <Route path="enquiry" element={<Enquiry />} />
        <Route path="logs-track" element={<LogsTracking />} />
      </Route>
      <Route
        path={routeConstant.INVENTORY}
        element={
          <ProtectedRoute>
            <SidebarLayout />
          </ProtectedRoute>
        }
      >

        <Route path={routeConstant.CENTER} element={<CenterList />} />
        <Route
          path={routeConstant.TRANSFER_STOCK}
          element={<TransferStock />}
        />
        <Route
          path={routeConstant.CENTER_DETAILS}
          element={<CenterDetails />}
        />
        <Route path={routeConstant.ADD_CENTER} element={<AddCenter />} />
        <Route
          path={routeConstant.LEADS_IN_CENTRE}
          element={<LeadsInCentre />}
        />
        <Route path={routeConstant.EDIT_CENTER} element={<EditCentre />} />
        <Route path={routeConstant.STOCK} element={<Stock />} />
        <Route path={routeConstant.STOCK_DETAILS} element={<StockDetails />} />
        <Route path={routeConstant.ADD_STOCK} element={<AddStockType />} />
      </Route>
      <Route
        path={routeConstant.USERS}
        element={
          <ProtectedRoute>
            <SidebarLayout />
          </ProtectedRoute>
        }
      >
        <Route path={routeConstant.USERS_LIST} element={<Users />} />

        <Route path={routeConstant.EDIT_USERS} element={<EditUsers />} />
        <Route path={routeConstant.ADD_USER} element={<AddUsers />} />
        <Route path={'*'} element={<NotFound />} />
      </Route>
      <Route
        path={routeConstant.DOCUMENTS}
        element={
          <ProtectedRoute>
            <SidebarLayout />
          </ProtectedRoute>
        }
      >
        <Route path={routeConstant.PENDING_LEADS} element={<DocumentLists />} />
        <Route path=":leadId" element={<LeadDetail />} />
      </Route>
    </Routes>
  );
};

export default Navigation;
