import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
type LeadListProps = {
  onConfirmDelete: () => void
  handleClose: () => void
  open: boolean
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function ConfirmLeadDeletion({ onConfirmDelete, handleClose, open }: LeadListProps) {

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <Box sx={{ width: '400px' }}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            ></BootstrapDialogTitle>
            <DialogContent>
              <Typography gutterBottom>Do you want to delete this lead?</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ background: 'red' }}
                variant="contained"
                id="notconfirm"
                onClick={handleClose}
              >
                No
              </Button>
              <Button
                autoFocus
                variant="contained"
                onClick={onConfirmDelete}
              >
                Yes
              </Button>
            </DialogActions>
          </Box>
        </BootstrapDialog>
        {/* {toastMsg ? <ToastMessage open="true" message="Center Added Successfully" /> : <></>} */}
      </div>
      {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {'Do you want to delete this lead?'}
          </DialogTitle>
          <DialogActions
            style={{ display: 'flex', justifyContent: 'space-around' }}
          >
            <Button onClick={onConfirmDelete} autoFocus>
              YES
            </Button>
            <Button autoFocus onClick={handleClose}>
              NO
            </Button>
          </DialogActions>
        </Dialog> */}
    </>
  );
}

export default ConfirmLeadDeletion