import React from 'react';
import './style.css';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { useInvoiceDetailsQuery } from 'src/generated/hooks_and_more';
import { Charges } from 'src/constants/constant';
import {
  formatDate,
  getYearFromDate,
  titleCaseToReadable
} from 'src/utils/utility';
import { ToWords } from 'to-words';
function PaymentInvoice() {
  const params = useParams();
  const toWords = new ToWords();
  const { data, loading } = useInvoiceDetailsQuery({
    variables: { id: params?.invoiceId }
  });
  const invoiceData = data?.queryPaymentInvoices?.[0];
  const dated = data?.queryPaymentInvoices?.[0]?.payment?.createdAt;
  const customerNumber = invoiceData?.customerNumber;
  const regNo = invoiceData?.lead?.regNo;
  const make = invoiceData?.lead?.vehicle?.vehicleBrand?.name;
  const manufacturingYear = invoiceData?.lead?.vehicle?.manufacturingDate;
  const model = invoiceData?.lead?.vehicle?.vehicleModel?.name;
  const activeBooking = invoiceData?.lead?.activeBooking;
  const rtoCharges = activeBooking?.isRCTransferReq ? Charges?.RTO_CHARGES : 0;
  const insuranceCharges = activeBooking?.isInsuranceReq
    ? Charges?.INSURANCE_CHARGES
    : 0;
  const dealAmount =
    activeBooking?.bookingPayment?.saleAmount + rtoCharges + insuranceCharges;
  const paymentDetails = invoiceData?.payment;
  const paymentInwords = paymentDetails?.amount
    ? toWords?.convert(paymentDetails?.amount, {
        currency: true
      })
    : '-';

  const inputs = [
    {
      title: 'Bill Details',
      values: [
        { key: 'GST No.', value: invoiceData?.GSTNumber?.toUpperCase() },
        { key: 'Sr. No.', value: invoiceData?.serialNo?.toUpperCase() },
        { key: 'Received with thanks from', value: invoiceData?.customerName }
      ]
    },
    {
      title: 'Vehicle Details',
      values: [
        { key: 'Vehicle No', value: regNo },
        { key: 'Make', value: `${make}` },
        { key: 'Model', value: `${model}`},
        { key: 'Year', value: getYearFromDate(manufacturingYear) }
      ]
    },
    {
      title: 'Payment Details',
      values: [
        { key: 'Payment Account of', value: 'Purchase of Tractor' },
        { key: 'Deal Amount (in INR)', value: dealAmount },
        { key: 'Payment Amount (in INR)', value: paymentDetails?.amount },
        { key: 'Payment Amount (in words)', value: paymentInwords },
        {
          key: 'Payment Mode',
          value: titleCaseToReadable(paymentDetails?.mode)
        },
        { key: 'Payment Date', value: formatDate(paymentDetails?.createdAt) },
        { key: 'Hypothecation', value: 'N/A' }
      ]
    }
  ];

  const dateValues = [
    {
      values: [
        { key: 'Dated', value: formatDate(dated) },
        { key: 'Mobile', value: customerNumber }
      ]
    }
  ];
  
 
   
  if (loading && !data?.queryPaymentInvoices?.length) {
    return (
      <div
        className="d-flex flex-column height-100 justify-content-center align-items-center gap-4"
        style={{ height: '80vh' }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }
    
return (<>

 { !loading && !Boolean(data) ? <>  {alert("Invoice data is not available!")}</> :
   <div className="invoice-wrapper">
  <div className="invoice-container">
    <div
      id="image"
      style={{
        maxWidth: '520px',
        borderRadius: '10px',
        border: '1px solid #E0E0E0'
      }}
    >
      <div
        style={{
          maxWidth: '520px'
        }}
      >
        <div
          className="d-flex flex-column justify-content-center w-auto text-center"
          style={{
            padding: '10px',
            paddingBottom: '30px',
            lineHeight: '19.36px'
          }}
        >
          <h4 style={{ fontWeight: 700, marginTop: '10px' }}>
            AGRO WHEELS MARKETING PRIVATE LIMITED
          </h4>
          <p className="colorSubHeading1" style={{ marginTop: '4px' }}>
            Plot No 9, N.E.B. Subhash Nagar,
          </p>
          <p className="colorSubHeading1">
            {' '}
            MM Tower, Alwar, Rajasthan-301001
          </p>
          {dateValues?.map((i, index) => (
            <>
              <div
                key={index}
                style={{
                  marginLeft: '40px',
                  marginRight: '25px',
                  marginTop: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 50
                }}
              >
                {i?.values?.map((item) => (
                  <>
                    <Grid container sx={{ margin: '10px 0px 5px 0px' }}>
                      <>
                        <Grid
                          item
                          xs={4}
                          md={4}
                          sx={{
                            color: '#8D9299',
                            textAlign: 'start',
                            lineHeight: '16.41px'
                          }}
                        >
                          {item?.key}
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          md={8}
                          className="colorSubHeadingRight"
                          sx={{ textAlign: 'start' }}
                        >
                          {item?.value}
                        </Grid>
                      </>
                    </Grid>
                  </>
                ))}
              </div>
            </>
          ))}

          {inputs?.map((i, index) => (
            <>
              <div
                key={index + 1}
                style={{
                  marginLeft: '25px',
                  marginRight: '25px',
                  border: '2px solid #E0E0E0',
                  borderRadius: '20px',
                  marginTop: '10px'
                }}
              >
                <Grid
                  container
                  sx={{ padding: '12px 10px 10px 20px' }}
                  spacing={1}
                >
                  {i?.title ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                          textAlign: 'start',
                          lineHeight: '16.41px;',
                          marginBottom: '5px'
                        }}
                      >
                        <h5>{i?.title}</h5>
                      </Grid>
                    </>
                  ) : null}
                  {i?.values?.map((j) => (
                    <>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        className="colorSubHeading"
                        sx={{ textAlign: 'start', marginTop: '3px' }}
                      >
                        {j?.key}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        className="colorSubHeadingRight"
                        sx={{ textAlign: 'start', marginTop: '3px' }}
                      >
                        {j?.value}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>
              {i?.title === 'Bill Details' ||
              i?.title === 'Vehicle Details' ? (
                <hr
                  style={{
                    marginLeft: '25px',
                    marginRight: '25px',
                    border: '0px solid #8D9299',
                    borderRadius: '20px',

                    marginBottom: '15px'
                  }}
                ></hr>
              ) : null}
            </>
          ))}

          <p
            style={{
              marginLeft: '25px',
              marginTop: '10px',
              fontSize: '13px',
              color: '#02172C',
              textAlign: 'left'
            }}
          >
            * This is an electronically generated payment receipt. No
            signature required.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>    }
</>)

}
    
    
  
              


export default PaymentInvoice;
