import { number } from 'prop-types';
import { LeadDetailsPropsType } from './ApplicationDetails';
import PaymentListRow from 'src/components/Composite/PaymentListRow';
import { Charges } from 'src/constants/constant';
import { formatDate, titleCaseToReadable } from 'src/utils/utility';
import {
  PaymentFor,
  useGetGsTforinvoiceLazyQuery
} from 'src/generated/hooks_and_more';
import { useEffect, useState } from 'react';

function PaymentDetails({
  currentLead,
  addPartPayments
}: LeadDetailsPropsType) {
  const rtoCharges = currentLead?.activeBooking?.isRCTransferReq
    ? Charges?.RTO_CHARGES
    : "No";
  const insuranceCharges = currentLead?.activeBooking?.isInsuranceReq
    ? Charges?.INSURANCE_CHARGES
    : "No";

  const saleAmount =
    currentLead?.activeBooking?.bookingPayment?.saleAmount ?? 0;

  let totalSaleAmount = 0;
  let balanceAmount = 0;
  let tokenPayments = 0;
  if (saleAmount !== null) {
    totalSaleAmount =  saleAmount + (typeof rtoCharges === 'number' ? rtoCharges : 0 )+ (typeof insuranceCharges === 'number' ? insuranceCharges : 0 ) ?? 0;
  }
  balanceAmount = totalSaleAmount - tokenPayments;
  // bookingDetails.push({ key: 'Balance Amount', value: balanceAmount });
  let bookingDetails: {
    key: string;
    value?: string | number;
    status?: string;
    metaData?: {
      title: string;
      data: { key: string; value: string | number }[];
    };
    reconciliation?: {
      title: string;
      data: { key: string; value: string | number }[];
    };
    view?: boolean;
    buttonValue?: string;
    invoiceId?: string;
  }[] = [
    {
      key: 'Payment Mode',
      value: currentLead?.activeBooking?.bookingPayment?.bookingType ?? '-'
    },
    {
      key: 'Sale Amount',
      value: saleAmount ?? '-',
      status: null
    },
    {
      key: 'RC Transfer',
      value: rtoCharges ?? '-'
    },
    {
      key: 'Insurance',
      value: insuranceCharges ?? '-'
    },
    {
      key: 'Total Sale Amount',
      value: totalSaleAmount ?? '-'
    }
  ];
  let metaData = null;
  let reconciliation = null;
  let partNo = 0;
  currentLead?.activeBooking?.payments?.map((item, index) => {
    if (titleCaseToReadable(item?.for) == 'Booking Part') {
      partNo = partNo + 1;
    }
    if (item?.for) {
      metaData = {
        title: titleCaseToReadable(item.for),
        leadId: currentLead?.id,
        data: [
          { key: 'Payment Type', value: titleCaseToReadable(item?.for) },
          { key: 'Payment Date', value: formatDate(item?.createdAt) },
          { key: 'Payment Mode', value: titleCaseToReadable(item?.mode) },
          { key: 'Payment amount', value: item?.amount },
          {
            key:
              item.for === PaymentFor.BookingLoan
                ? 'Delivery Order'
                : 'Payment Proof',
            value: item?.proofUrl,
            type: !!item?.proofUrl ? 'url' : '-'
          },

          {
            key: 'Payment Receipt',
            value: item?.receiptUrl,
            type:
              item.for === PaymentFor.BookingLoan
                ? "loan_ignore"
                : !!item?.receiptUrl
                ? 'url'
                : '-',
            receiptDetails:
              item.for === PaymentFor.BookingLoan
                ? null
                : {
                    status: item?.status,
                    gstNumber: currentLead?.activeBooking,
                    invoiceId: item?.invoice?.id,
                    leadId: currentLead?.id,
                    paymentId: item?.id,
                    customerName: currentLead?.activeBooking?.customer?.name,
                    customerNo: currentLead?.activeBooking?.customer?.phoneNo,
                    state: currentLead.registrationState
                  }
          }
        ]
      };
      reconciliation = {
        title: 'Reconciliation History',
        data: [
          { key: 'Status', value: titleCaseToReadable(item?.status) },
          { key: 'Confirmed By', value: item?.confirmedBy?.name },
          {
            key: 'Confirmation Date',
            value: formatDate(item?.paymentProcessedAt)
          }
        ]
      };
    }

    if (item?.amount) {
      bookingDetails = [
        ...bookingDetails,
        {
          buttonValue: item?.for,
          key:
            titleCaseToReadable(item?.for) == 'Booking Part'
              ? `${titleCaseToReadable(item?.for)} ${partNo}`
              : titleCaseToReadable(item?.for),
          value: item?.amount ?? '-',
          status:  item?.status,
          metaData: metaData,
          reconciliation: reconciliation,
          view: true,
          invoiceId: item?.invoice?.id
        }
      ];
      if (item?.status === 'APPROVED') {
        tokenPayments += item?.amount;
      }
    }
  });

  balanceAmount = totalSaleAmount - tokenPayments;
  // bookingDetails.push({ key: 'Balance Amount', value: balanceAmount });
  bookingDetails.splice(5, 0, { key: 'Balance Amount', value: balanceAmount });
  return <PaymentListRow data={bookingDetails} handleClick={addPartPayments} />;
}

export default PaymentDetails;
