import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  BankName,
  GetFilteredLeadsQueryVariables,
  LeadSource,
  LeadStatus,
  UserRole,
  VehicleMake,
  useAllLeadsQuery,
  useGetFilteredLeadsLazyQuery
} from '../../generated/hooks_and_more';
import LeadListRow from '../../components/Composite/LeadListRow';
import { LeadListTableHeader } from '../../constants/constant';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import TableWrapper from 'src/components/Tables';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import LeadsFilter from './LeadsFilter';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { searchString } from 'src/constants/reactiveVar';
import {
  cachedLeadFilter,
  cachedBookingFilter,
  getYearFromDate,
  titleCaseToReadable,
  getUserData
} from 'src/utils/utility';
import { ExportCSV } from './leadExport';
import { format } from 'date-fns';
import LeadProcess from '../lead-flow';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
import _ from 'lodash';
import CreateBulkLeadSidebar from '../lead-flow/BulkLeads';

export type RefetchBulk = {
  refetching: () => void;
};

function Leads() {
  const filterInput = useReactiveVar(cachedLeadFilter);
  const withoutFilterCondition = _.isEqual(filterInput, {
    offset: 1,
    pageSize: 20
  });
  const location = useLocation();
  const { loggedInUser } = useLoggedInUser();
  const [openBulk, setOpenBulk] = useState(false);
  const [openApproval, setApproval] = useState('');

  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [allLeadsData, setAllLeadsData] = useState([]);
  const [dataExport, setExportData] = useState(null);
  const [varr, setVarr] = useState(null);
  const [noFilterPageNumber, setNoFilterPageNumber] = useState(0);

  const userDetails = getUserData();
  const [getFilteredLeads, { data: leads, loading, refetch }] =
    useGetFilteredLeadsLazyQuery({
      onCompleted(data) {
        const exportData = data?.filteredLeads?.map((item, index) => {
          const source = titleCaseToReadable(item?.source ?? '-');
          const make = titleCaseToReadable(
            item?.vehicle?.vehicleBrand?.name ?? '-'
          );
          const year = item?.vehicle?.manufacturingDate
            ? getYearFromDate(item?.vehicle?.manufacturingDate ?? '-')
            : '-';
          const createdAt = item?.createdAt;
          const lastEvent =
            item?.statusEvents &&
            item?.statusEvents?.length &&
            item?.statusEvents;

          const lastEventUpdatedDate =
            lastEvent && lastEvent.length && lastEvent[0]?.createdAt;

          return {
            Registration_Number: item?.regNo,
            Status: lastEvent
              ? titleCaseToReadable(lastEvent?.[0]?.status ?? '-')
              : '-',
            Pending_On: titleCaseToReadable(
              lastEvent?.[0]?.assignTaskTo ?? '-'
            ),
            Purchase_Type: source,
            Make: make,
            Model: item?.vehicle?.vehicleModel?.name ?? '-',
            Mfg_Year: year,
            Created_By: item?.createdBy?.name ?? '',
            Created_Date: !!createdAt
              ? format(Date.parse(createdAt), 'dd-MM-yyyy')
              : '-',
            Updated_Date: !!lastEventUpdatedDate
              ? format(Date.parse(lastEventUpdatedDate), 'dd-MM-yyyy')
              : '-'
          };
        });

        setExportData(exportData);
      },
      onError(error) {}
    });
  const {
    data: allLeads,
    loading: allLeadsLoading,
    refetch: allLeadRefetch
  } = useAllLeadsQuery({
    variables: { offset: noFilterPageNumber * 20 },
    skip:
      !(filterInput === null || withoutFilterCondition) ||
      [UserRole.ProcurementExecutive, UserRole.Driver].includes(
        userDetails?.role
      ),

    onCompleted(data) {
      if (
        data?.queryLead?.length &&
        allLeadsData?.length &&
        noFilterPageNumber
      ) {
        setAllLeadsData([...allLeadsData, ...data?.queryLead]);
      } else {
        setAllLeadsData([...data?.queryLead]);
      }
    }
  });
  // console.log(
  //   filterInput === null || _.isEqual(filterInput, { offset: 1, pageSize: 20 }),
  //   allLeadsData,
  //   'allLeadsData'
  // );

  let state = location.state as {
    regState: any;
    status?: [LeadStatus];
    source?: LeadSource;
    userId?: string;
    bankName?: string;
    date: DateRange;
    hasStatus: [LeadStatus];
    driverName: string;
    driverId: string;
    make: string;
    model: string;
    centreName: string;
  } | null;

  const [showModal, setShowModal] = useState<boolean>(false);
  let variables: GetFilteredLeadsQueryVariables;
  const showTheirLeadsOnly = [
    UserRole.Driver,
    UserRole.ProcurementExecutive
  ]?.includes(loggedInUser?.role);
  const [roleVariables, setRoleVariables] = useState(null);
  useEffect(() => {
    if (showTheirLeadsOnly) {
      const inputs = {
        createdBy:
          loggedInUser?.role === UserRole.ProcurementExecutive
            ? loggedInUser?.userId
            : null,
        driverName:
          loggedInUser?.role === UserRole.Driver ? loggedInUser?.name : null
      };
      cachedLeadFilter(inputs);
      setRoleVariables(inputs);
    }
  }, [showTheirLeadsOnly]);
  useEffect(() => {
    cachedBookingFilter(null);

    if (state) {
      const statuses = state?.status?.map((item) => item as LeadStatus);

      const source = state?.source;
      const auctionAgency = state?.bankName;
      const userId = state?.userId;

      if (statuses) {
        variables = { ...variables, statuses: statuses };
      }
      if (state.centreName) {
        variables = {
          ...variables,
          centreName: state?.centreName
        };
      }

      if (state && state?.hasStatus) {
        variables = {
          ...variables,
          hasStatus: state?.hasStatus as [LeadStatus]
        };
      }

      if (state && state?.driverId) {
        variables = { ...variables, driverId: state?.driverId };
      }

      if (state && state?.date?.length) {
        const dateRange = { start: state.date[0], end: state.date[1] };
        variables = { ...variables, createdAtDateRange: dateRange };
      }

      if (state?.regState) {
        console.log(state?.regState, 'state?.regState');
        variables = {
          ...variables,
          registrationState: state?.regState?.state_name
        };
      }

      if (source) {
        variables = { ...variables, source: source };
      }

      if (auctionAgency) {
        variables = {
          ...variables,
          sourcedBankName: [auctionAgency as BankName]
        };
      }

      if (state?.make && state?.model) {
        variables = {
          ...variables,
          makes: state?.make as VehicleMake,
          models: state?.model
        };
      }

      if (userId) {
        variables = { ...variables, createdBy: userId };
      }

      onFilterHandler({ ...variables, offset: pageNumber });
    } else if (pageNumber || filterInput) {
      if (Boolean(filterInput?.offset)) {
        onFilterHandler({
          ...filterInput
        });
      } else {
        onFilterHandler({
          ...filterInput,
          offset: pageNumber
        });
      }
    }
  }, [filterInput, pageNumber]);
  const onFilterHandler = (variables: GetFilteredLeadsQueryVariables) => {
    console.log(variables, 'varrrrrrrrrrr');
    setVarr(variables);
    if (!(filterInput === null || withoutFilterCondition)) {
      getFilteredLeads({
        variables: { ...variables, ...roleVariables },
        fetchPolicy: 'cache-and-network',

        onCompleted(dataa) {
          if (allLeadsData?.length) {
            setAllLeadsData([]);
          }
          if (variables?.offset === 1) {
            setData([...dataa?.filteredLeads]);
          } else {
            setData([...data, ...dataa?.filteredLeads]);
          }

          setShowModal(false);
        },
        onError() {}
      });
    } else if (withoutFilterCondition) {
      setNoFilterPageNumber(0);
    }
  };

  function checkforuserForbulk() {
    if (
      loggedInUser?.role === UserRole.Admin ||
      loggedInUser?.role === UserRole.ProcurementHead ||
      loggedInUser?.role === UserRole.ProcurementManager
    ) {
      return true;
    }
  }

  const handleGlobalSearch = useCallback((value: string) => {
    searchString(value);
  }, []);
  const handleClose = () => setShowModal(false);

  const exportQuery = () => {
    const variable = { ...variables, ...filterInput, offset: null };

    getFilteredLeads({ variables: variable });

    return dataExport;
  };
  const resetExport = () => {
    setExportData([]);
  };

  function handleCloseBulk() {
    setOpen(false);
  }

  function refetchingBulkLeads(value) {
    if (value) {
      allLeadRefetch();
    }
  }
  return (
    <Fragment>
      <PageTitleWrapper>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Typography variant="h3" component="h3" gutterBottom>
              Leads
            </Typography>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {loggedInUser?.role === UserRole.ProcurementExecutive ? (
              <Button
                sx={{
                  mt: { xs: 0, md: 0 },
                  mr: 2,
                  backgroundColor: '#1c7c1c',
                  border: 'none',
                  color: 'white'
                }}
                id="create-lead-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Create Lead
              </Button>
            ) : null}

            {loggedInUser?.role === UserRole.ProcurementHead ? (
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenBulk(true);
                  setApproval('Approval');
                }}
              >
                Bulk approval
              </Button>
            ) : null}
            {checkforuserForbulk() && (
              <Button
                sx={{ mr: 2, ml: 2, height: '36px' }}
                variant="outlined"
                size="small"
                onClick={() => {
                  setOpenBulk(true);
                  setApproval('Bulk');
                }}
              >
                Create Bulk Leads
              </Button>
            )}

            <CreateBulkLeadSidebar
              open={openBulk}
              refetchingBulk={refetchingBulkLeads}
              handleClose={handleCloseBulk}
              setOpenBulk={setOpenBulk}
              value={openApproval === 'Approval' ? 'Approval' : 'Bulk'}
            />

            <LeadsFilter
              showModal={showModal}
              setShowModal={setShowModal}
              handleClose={handleClose}
              onFilterHandler={onFilterHandler}
              filterLoading={loading}
            />
            {data?.length || allLeadsData?.length ? (
              <Box sx={{ paddingLeft: '20px' }}>
                <ExportCSV
                  callQuery={exportQuery}
                  reset={resetExport}
                  loading={loading}
                  csvData={dataExport}
                  fileName="Leads"
                />
              </Box>
            ) : null}
          </Box>
        </Grid>
      </PageTitleWrapper>

      {open && <LeadProcess open={open} setOpen={setOpen} refetch={refetch} />}

      <Box sx={{ px: 2, pb: 2 }}>
        {(allLeadsLoading && noFilterPageNumber === 0) ||
        (loading &&
          dataExport?.length &&
          (pageNumber === 1 || filterInput?.offset === 1)) ||
        (showTheirLeadsOnly && loading && pageNumber === 1) ? (
          <div
            className="d-flex flex-column height-100 justify-content-center align-items-center gap-4"
            style={{ height: '60vh' }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : (
          <>
            <TableWrapper
              handleGlobalSearch={handleGlobalSearch}
              header={LeadListTableHeader}
            >
              {filterInput === null ||
              _.isEqual(filterInput, {
                offset: 1,
                pageSize: 20
              }) ? (
                allLeadsData?.length ? (
                  allLeadsData?.map((item, index) => {
                    return (
                      <LeadListRow
                        key={item.id}
                        leadData={item}
                        index={index}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '20px',
                          fontWeight: '900',
                          width: '110vw'
                        }}
                      >
                        {leads?.filteredLeads?.length ||
                        (allLeads?.queryLead?.length && varr?.offset === 1) ? (
                          <CircularProgress color="inherit" />
                        ) : (
                          <Typography>No data found</Typography>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                )
              ) : data?.length ? (
                data?.map((item, index) => {
                  return (
                    <LeadListRow key={item.id} leadData={item} index={index} />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '20px',
                        fontWeight: '900',
                        width: '110vw'
                      }}
                    >
                      {leads?.filteredLeads?.length ||
                      (allLeads?.queryLead?.length && varr?.offset === 1) ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <Typography>No data found</Typography>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableWrapper>
            {(loading && data?.length) ||
            (allLeadsLoading && allLeadsData?.length) ? (
              <div
                style={{ padding: '30px' }}
                className="d-flex flex-column  justify-content-center align-items-center gap-2"
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
                <h5>Loading..</h5>
              </div>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '30px'
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    display:
                      leads?.filteredLeads?.length ||
                      (allLeads?.queryLead?.length && varr?.offset === 1)
                        ? 'block'
                        : 'none'
                  }}
                  onClick={() => {
                    if (
                      !(
                        filterInput === null ||
                        _.isEqual(filterInput, {
                          offset: 1,
                          pageSize: 20
                        })
                      )
                    ) {
                      if (filterInput?.offset === 1) {
                        setPageNumber(2);
                        cachedLeadFilter({ ...filterInput, offset: null });
                      } else {
                        setPageNumber(pageNumber + 1);
                      }
                    } else {
                      setNoFilterPageNumber(noFilterPageNumber + 1);
                    }
                  }}
                >
                  Load More
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Fragment>
  );
}

export default Leads;
