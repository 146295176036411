import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';

import { Box, Button, Grid } from '@mui/material';
import {
  LeadRef,
  LeadStatus,
  useDriversQuery,
  // useDriversQuery,
  useGetAllCenterQuery
} from 'src/generated/hooks_and_more';
import { enumToItems } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';

import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { LeadCreateAndUpdateProps } from 'src/types';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import './form.css';
import { PickupAssignmentSchema } from '../ValidationSchema/ValidationSchema';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';
const PickupAssignment = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    undesireButtonText,
    canGoBack
  } = props;

  const {
    data: centerLists,
    loading,
    error
  } = useGetAllCenterQuery({
    fetchPolicy: 'no-cache'
  });
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const { data: drivers } = useDriversQuery();

  const formik = useFormik({
    initialValues: {
      centerName: null,
      driverName: null,
      releaseOrder: '',
      indemnityBond: ''
    },
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        centre: {
          name: values?.centerName?.value,
          id: values?.centerName?.id
        },
        deliveryCentre: {
          name: values?.centerName?.value,
          id: values?.centerName?.id
        },
        pickup: {
          by: {
            name: values?.driverName?.value,
            id: values?.driverName?.id
          }
        },
        documents: {
          indemnityBond: values?.indemnityBond,
          releaseOrder: values?.releaseOrder
        }
      };

      onPressOfDesiredOrUndesiredButton(leadInput);
    },
    validationSchema: PickupAssignmentSchema
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.centerName}
            name="centerName"
            options={centerLists?.queryCentre?.map((item) => ({
              label: item?.name,
              value: item?.name,
              id: item?.id
            }))}
            optionString="label"
            onChange={(val) => formik?.setFieldValue('centerName', val)}
            placeholder="Select the Centre for Allocation*"
          />
          {formik.errors.centerName && formik.touched.centerName ? (
            <div style={{ color: 'red' }}>{formik.errors?.centerName}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.driverName}
            name="driverName"
            options={drivers?.queryUser?.map((item) => ({
              label: item?.name,
              value: item?.name,
              id: item?.id
            }))}
            optionString="label"
            onChange={(val) => formik?.setFieldValue('driverName', val)}
            placeholder="Select Driver For Pickup*"
          />
          {formik.errors.driverName && formik.touched.driverName ? (
            <div style={{ color: 'red' }}>{formik.errors?.driverName}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <FileUploadToS3
                        accept="image/*,.pdf"

            id="releaseOrder"
            value={formik.values.releaseOrder}
            onChange={(value) => {
              formik.setFieldValue('releaseOrder', value);
            }}
            placeholder="Release Order"
          />
          {/* {formik.errors.releaseOrder && formik.touched.releaseOrder ? (
            <div style={{ color: 'red' }}>{formik.errors?.releaseOrder}</div>
          ) : null} */}
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUploadToS3
                        accept="image/*,.pdf"

            id="indemnityBond"
            value={formik.values.indemnityBond}
            onChange={(value) => {
              formik.setFieldValue('indemnityBond', value);
            }}
            placeholder="Indemnity Bond"
          />
          {/* {formik.errors.indemnityBond && formik.touched.indemnityBond ? (
            <div style={{ color: 'red' }}>{formik.errors?.indemnityBond}</div>
          ) : null} */}
        </Grid>
      </Grid>

      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        backBtn={canGoBack}
        disabled={!(formik.isValid && formik.dirty)}
        reject={reject}
      />
    </form>
  );
};

export default PickupAssignment;
