import { Grid, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import MultiSelectAutoComplete from 'src/components/Form/MultiSelectAutoComplete';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import DatePicker from '@mui/lab/DatePicker';
import {
  useGetCentreListQuery,
  useGetAllStateQuery,
  useGetDistrictListQuery,
  BookingsQueryVariables,
  BookingStatus,
  PriceRange,
  useGetAllNewBrandsQuery,
  useGetModelByBrandIdLazyQuery,
  useGetDistrictsByStateQuery,
  useGetAllActiveStateQuery
} from 'src/generated/hooks_and_more';
import {
  cachedBookingFilter,
  cachedLeadFilter,
  titleCaseToReadable
} from 'src/utils/utility';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useLocation } from 'react-router';
import { format } from 'date-fns';

type modelType = {
  id?: string;
  name?: string;
  model_id?: number;
};

type StateType = {
  state_id: string;
  state_name: string;
};

type ValueType = {
  id: string;
  name: string;
};

// type YearType = {
//     id?: string;
//     year?: string;
// }

type MakeType = {
  brand_id: number;
  name: string;
  id: string;
};

type DistrictType = {
  id: string;
  name: string;
};
type BookingFilterType = {
  showModal: boolean;
  setShowModal: any;
  handleClose: () => void;
  onFilterHandler: (variables: any) => void;
  filterLoading: boolean;
  filterInput?: any;
  resetSearch?: any;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '55%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  // height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  p: 2
};

const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  // marginBottom: '10px',
  cursor: 'pointer',
  color: 'rgb(195, 62, 62)'
};

function BookingFilter({
  showModal,
  setShowModal,
  filterLoading,
  onFilterHandler,
  handleClose,
  filterInput,
  resetSearch
}: BookingFilterType) {
  const search = useLocation().search;
  const select = new URLSearchParams(search).get('select');
  const [stateValue, setStateValue] = useState<StateType[] | any>(null);
  const [districtValue, setDistrictValue] = useState<DistrictType[] | any>(
    null
  );
  const [make, setMake] = useState<MakeType | any>(null);
  const [yearValue, setManufacturingYear] = useState<any>(null);
  const [modelValue, setModelValue] = useState<modelType[] | any>([]);
  const [minValueSale, setminValueSelling] = useState<any>(null);
  const [maxValueSale, setMaxValueSelling] = useState<any>(null);
  const [minValueList, setminValueListing] = useState<any>(null);
  const [maxValueList, setMaxValueListing] = useState<any>(null);
  const [centerNameValue, setCenterNameValue] = useState<ValueType[] | any>(
    null
  );

  const [purchaseValue, setPurchaseValue] = useState<ValueType[] | any>(null);
  const center = useGetCentreListQuery();
  const brandOptions = useGetAllNewBrandsQuery();

  const [getModels, { data: modelName, loading }] =
    useGetModelByBrandIdLazyQuery();

  const stateOption = useGetAllActiveStateQuery({
    fetchPolicy: 'no-cache'
  });
  const allActiveStateName =
    stateOption?.data?.getStateInfo?.data?.map((i) => ({
      state_id: i?.state_id,
      state_name: titleCaseToReadable(i?.state_name),
      tr_state_id: i?.tr_state_id
    })) ?? [];

  const { data: districtOptions } = useGetDistrictsByStateQuery({
    variables: {
      id: stateValue?.state_id
    }
  });

  const onFilterClick = (e) => {
    const brand: number = make?.brand_id;
    const state: string = stateValue?.state_name;
    const districts: string[] = districtValue?.map(
      (item) => item?.district_name ?? []
    );
    const model: number[] = modelValue?.map((item) => item?.model_id);
    const centre = (centerNameValue?.name as string) ?? '';
    const sellingPrice = { min: minValueSale, max: maxValueSale };
    const listingPrice = { min: minValueList, max: maxValueList };
    let date = new Date(yearValue);
    let variables: BookingsQueryVariables = {
      state: state,
      vehicleModel: model ? model : [],
      vehicleBrand: brand ? [brand] : [],
      centre: centre,
      districts: districts,
      status: BookingStatus?.[select] ?? BookingStatus?.['Active'],
      purchaseType: purchaseValue ? purchaseValue?.name : null,
      searchString: filterInput?.searchString
    };

    if (yearValue) {
      variables = {
        ...variables,
        manufacturingDate: date.getFullYear()
      };
    }

    if (minValueSale && maxValueSale) {
      variables = {
        ...variables,
        sellingPrice: sellingPrice as PriceRange
      };
    }

    if (minValueList && maxValueList) {
      variables = {
        ...variables,
        listingPrice: listingPrice as PriceRange
      };
    }

    cachedBookingFilter({ ...variables, offset: 1, pageSize: 20 });
    onFilterHandler({ ...variables, offset: 1, pageSize: 20 });
    setShowModal(false);
  };

  console.log({ make });

  useEffect(() => {
    if (filterInput && filterInput?.vehicleBrand?.length) {
      const brands = brandOptions?.data?.queryBrand?.filter(
        (item) => filterInput?.vehicleBrand[0] === item.brand_id
      );

      if (brands?.length) {
        if (filterInput?.vehicleModel?.length) {
          getModels({
            variables: { brand_id: brands[0]?.brand_id },
            onCompleted(data) {
              const models = data?.queryBrand?.[0]?.models?.filter((item) =>
                filterInput?.vehicleModel?.some((id) => id === item?.model_id)
              );
              setModelValue(models);
            }
          });
        }

        setMake(brands[0]);
      }
    }
    setStateValue({ state_name: filterInput?.state });
    if (filterInput?.districts?.length) {
      let districts = filterInput?.districts.map((districtName) => ({
        district_name: districtName
      }));
      setDistrictValue(districts ?? null);
    }

    setPurchaseValue(
      filterInput?.purchaseType ? { name: filterInput?.purchaseType } : null
    );
    setStateValue(
      filterInput?.state ? { state_name: filterInput?.state } : null
    );
    // setMake(
    //   filterInput?.makes?.length
    //     ? {
    //         label: titleCaseToReadable(filterInput?.makes[0]),
    //         value: filterInput?.makes[0]
    //       }
    //     : null
    // );
    setMaxValueSelling(
      filterInput?.sellingPrice?.max !== ''
        ? filterInput?.sellingPrice?.max
        : null
    );
    setminValueSelling(
      filterInput?.sellingPrice?.min !== ''
        ? filterInput?.sellingPrice?.min
        : null
    );

    setMaxValueListing(
      filterInput?.listingPrice?.max !== ''
        ? filterInput?.listingPrice?.max
        : null
    );
    setminValueListing(
      filterInput?.listingPrice?.min !== ''
        ? filterInput?.listingPrice?.min
        : null
    );

    setCenterNameValue(
      filterInput?.centre ? { name: filterInput?.centre } : null
    );

    if (!!filterInput?.manufacturingDate) {
      const year = filterInput?.manufacturingDate;
      const customDate = new Date(year, 0, 1, 0, 0, 0);
      const formattedDate = format(
        customDate,
        'EEE MMM dd yyyy HH:mm:ss XXXXXX'
      );
      setManufacturingYear(formattedDate ?? null);
    }
  }, [filterInput]);

  const onResetHandler = () => {
    setStateValue(null);
    setMake(null);
    setModelValue(null);
    setCenterNameValue(null);
    // setManufacturingYear(null);
    setminValueSelling('');
    setminValueListing('');
    setMaxValueListing('');
    setMaxValueSelling('');
    setDistrictValue(null);
    cachedBookingFilter({ offset: 1, pageSize: 20 });
    setPurchaseValue(null);
    resetSearch();
    setManufacturingYear(null);
  };

  return (
    <>
      <Grid
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          gap: '13px',
          mb: '2px'
        }}
      >
        <Button
          sx={{ mt: { xs: 0, md: 0 } }}
          variant="outlined"
          onClick={() => {
            onResetHandler();
            onFilterHandler({ offset: 1, pageSize: 20 });
          }}
        >
          Reset
        </Button>

        <Button
          sx={{
            mt: { xs: 0, md: 0 }
          }}
          variant="contained"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Filter
        </Button>
      </Grid>

      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <Box sx={closeBtnStyle}>
            <CloseIcon
              onClick={() => {
                setShowModal(false);
              }}
            />
          </Box>
          <Grid
            container
            spacing={1}
            style={{ overflowY: 'auto', padding: '20px', height: '380px' }}
          >
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setCenterNameValue(value)}
                value={centerNameValue}
                placeholder="Select Centre"
                options={center?.data?.queryCentre ?? []}
                optionString="name"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setPurchaseValue(value)}
                value={purchaseValue}
                placeholder="Select Purchase Type"
                options={[{ name: 'CASH' }, { name: 'FINANCE' }]}
                optionString="name"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => {
                  setStateValue(value);
                  setDistrictValue(null);
                }}
                value={stateValue}
                options={allActiveStateName}
                placeholder="Select State"
                optionString="state_name"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => {
                  setDistrictValue(value);
                }}
                value={districtValue ?? []}
                options={districtOptions?.getDistrictByStateInfo?.data ?? []}
                optionString="district_name"
                placeholder="Select Districts"
                disabled={!stateValue?.state_name}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => {
                  setMake(value);
                  setModelValue(null);
                  if (value) {
                    getModels({
                      variables: { brand_id: [Number(value?.brand_id)] }
                    });
                  }
                }}
                value={make}
                options={brandOptions?.data?.queryBrand ?? []}
                optionString="name"
                placeholder="Select Make"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => {
                  setModelValue(value);
                }}
                value={modelValue ?? []}
                options={modelName?.queryBrand?.[0]?.models ?? []}
                placeholder="Select Model"
                optionString="name"
                disabled={!make}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year']}
                  openTo="year"
                  value={yearValue ?? null}
                  onChange={(newValue) => {
                    setManufacturingYear(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      value={yearValue}
                      style={{
                        width: '100%'
                      }}
                      error={false}
                      label={'Select Manufacturing Date'}
                      placeholder={'Select Manufacturing Date'}
                    />
                  )}
                  minDate={new Date('2000-01-01')}
                  maxDate={new Date('2100-01-01')}
                />
              </LocalizationProvider>
            </Grid>

            <Grid container spacing={1}>
              <Grid item ml={1} xs={12} md={12}>
                <Typography fontWeight={600} mb={1} ml={1}>
                  Select Selling price
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    <TextField
                      style={{
                        width: '100%'
                      }}
                      id="minValueSell"
                      placeholder="Minimum Price"
                      value={minValueSale}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label="Minimum Price"
                      onChange={(e) => {
                        setminValueSelling(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      style={{
                        width: '100%'
                      }}
                      id="maxValueList"
                      placeholder="Maximum Price"
                      value={maxValueSale}
                      size="small"
                      variant="outlined"
                      label="Maximum Price"
                      fullWidth
                      onChange={(e) => {
                        setMaxValueSelling(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Row 2: Listing Price */}
              <Grid item ml={1} xs={12} md={12}>
                <Typography fontWeight={600} mb={1} ml={1}>
                  Select Listing price
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      style={{
                        width: '100%'
                      }}
                      id="minValueList"
                      placeholder="Minimum Price"
                      value={minValueList}
                      size="small"
                      variant="outlined"
                      fullWidth
                      label="Minimum Price"
                      onChange={(e) => {
                        setminValueListing(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{
                        width: '100%'
                      }}
                      id="maxValueList"
                      placeholder="Maximum Price"
                      value={maxValueList}
                      size="small"
                      variant="outlined"
                      label="Maximum Price"
                      fullWidth
                      onChange={(e) => {
                        setMaxValueListing(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              gap: '20px',
              mb: '10px'
            }}
            md={12}
          >
            <Button
              sx={{ mt: { xs: 2, md: 0 } }}
              variant="contained"
              style={{ width: '110px' }}
              onClick={() => {
                onResetHandler();
              }}
              disabled={filterLoading}
            >
              Reset
            </Button>
            <Button
              sx={{ mt: { xs: 2, md: 0 } }}
              // style={{ width: '120px' }}
              variant="contained"
              onClick={onFilterClick}
              disabled={filterLoading}
              startIcon={filterLoading ? <CircularProgress size={20} /> : null}
            >
              Apply Filter
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default BookingFilter;

// const inputValue = e.target.value;
// if (inputValue === '' || parseInt(inputValue) >= 1) {
//   setminValueListing(inputValue);
//   setMaxValueListing('');
//   if (maxValueList === '') {
//     setShowModal(true);
//   }
// }
// disabled={!minValueSale || parseInt(minValueSale) == 0 ? true : false}
