import TableWrapper from 'src/components/Tables';
import { BookingListTableHeader } from 'src/constants/constant';
import {
  BookingStatus,
  BookingsQueryVariables,
  useBookingsLazyQuery
} from 'src/generated/hooks_and_more';
import BookingListRow from 'src/components/Composite/BookingListRow';
import {
  Box,
  InputBase,
  Grid,
  TextField,
  TableCell,
  TableRow,
  Typography,
  Autocomplete,
  styled,
  Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import format from 'date-fns/format';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { cachedBookingFilter, cachedLeadFilter } from 'src/utils/utility';
import useDebounce from 'src/customHooks/useDebounce';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import BookingFilter from './bookingFilter';

import { ExportCSV } from '../leads/leadExport';
import { useReactiveVar } from '@apollo/client';

const SearchWrap = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(188,196,255,0.25)',
  '&:hover': {
    backgroundColor: 'rgba(188,196,255,0.25)'
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const Booking = () => {
  const filterInput = useReactiveVar(cachedBookingFilter);

  const [varr, setVarr] = useState(null);
  const [searchStringValue, setSearchString] = useState('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filteredInput, setFilteredInput] = useState(null);
  const debouncedSearch = useDebounce(searchStringValue, 1000);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = searchParams.get('select');
  const navigate = useNavigate();
  const [value, setValue] = useState('Active');
  const [bookingData, setBookingData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataExport, setExportData] = useState(null);
  const [GetBooking, { data: bookings, loading, error }] =
    useBookingsLazyQuery();
  const [ExportBooking, { data: bookingExport, loading: bookingLoading }] =
    useBookingsLazyQuery({
      onCompleted(data) {
        const exportData = data?.filteredBookings?.map((item, index) => {
          return {
            Reg_no:
              item?.status === 'CANCELLED'
                ? item?.leadWithArchivedBooking?.regNo
                : item?.leadWithActiveBooking?.regNo,
            Booking_Id: item?.id,
            Active_BookingId: item?.id,
            Name: item?.customer?.name,
            // createdBy: item?.createdBy?.name,
            PhoneNo: item?.customer?.phoneNo,
            Payment_Mode: item?.bookingPayment?.bookingType ?? '-',
            Sale_Amount: item?.bookingPayment?.saleAmount ?? 0,
            Booking_PaymentId: item?.bookingPayment?.id,
            Active_LoanId: item?.activeLoan?.id,
            Field_Inspection_Date: item?.activeLoan?.fieldInspectionDate,
            Login_Date: item?.activeLoan?.loginDate,
            Delivery_Order_Date: item?.activeLoan?.deliveryOrderDate,
            Applied_LoanAmount: item?.activeLoan?.appliedLoanAmount,
            CreatedAt: item?.activeLoan?.createdAt
              ? format(Date.parse(item?.activeLoan?.createdAt), 'dd-MM-yy')
              : '-',
            Created_BookingAt: item?.createdAt
              ? format(Date.parse(item?.createdAt), 'dd-MM-yy')
              : '-',
            UpdateAt: item?.updatedAt
              ? format(Date.parse(item?.updatedAt), 'dd-MM-yy')
              : '-',
            State: item?.customer?.customerState?.state ?? '-',
            District: item?.customer?.customerDistrict?.name ?? '-',
            // paymentMode: item?.activeLoan
            //   ? 'Finance'
            //   : 'Cash',
            Status: item?.status
            // Status_Date: item?.updatedAt
            //   ? format(Date.parse(item?.updatedAt), 'dd-MM-yy')
            //   : '-'
          };
        });
        setExportData(exportData);
      }
    });
  const options = ['Active', 'Cancelled'];
  const optionSelect = !!params ? params : value;

  useEffect(() => {
    cachedLeadFilter(null);
  }, []);

  useEffect(() => {
    function booking() {
      GetBooking({
        variables: {
          status: BookingStatus?.[params] ?? BookingStatus.Active
        },
        onCompleted(data) {
          setBookingData([...data?.filteredBookings]);
        }
      });
    }
    booking();
  }, [params]);
  useEffect(() => {
    const fetchData = () => {
      GetBooking({
        variables: {
          status: BookingStatus?.[value] ?? BookingStatus.Active,
          searchString: `/.*${searchStringValue}.*/i`
        },
        onCompleted(data) {
          setBookingData([...data?.filteredBookings]);
        }
      });
    };
    if (searchStringValue || debouncedSearch) {
      fetchData();
      cachedBookingFilter({ searchString: `/.*${searchStringValue}.*/i` });
    } else {
      GetBooking({
        variables: {
          status: BookingStatus?.[params] ?? BookingStatus.Active,
          offset: pageNumber,
          pageSize: 20
        },
        onCompleted(data) {
          setBookingData([...bookingData, ...data?.filteredBookings]);
        }
      });
    }
  }, [debouncedSearch || pageNumber]);

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    if (filterInput) {
      if (Boolean(filterInput?.offset) || filterInput?.search) {
        GetBooking({
          variables: { ...filterInput },
          onCompleted(data) {
            setBookingData([...data?.filteredBookings]);
          }
        });
      } else if (!debouncedSearch && !filterInput?.search) {
        GetBooking({
          variables: { ...filterInput, offset: pageNumber, pageSize: 20 },
          onCompleted(data) {
            setBookingData([...bookingData, ...data?.filteredBookings]);
          }
        });
      }
    }

    setFilteredInput(filterInput);
  }, [filterInput]);

  const onFilterHandler = (variables: BookingsQueryVariables) => {
    setVarr(variables);
    cachedBookingFilter(variables);
  };
  const resetSearch = () => {
    setSearchString('');
  };
  const exportQuery = () => {
    const variable = { ...filterInput, offset: null };
    ExportBooking({ variables: variable });
    return dataExport;
  };
  const resetExport = () => {
    setExportData([]);
  };
  return (
    <>
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item display="flex">
            <div>
              <Typography variant="h3" component="h3" gutterBottom>
                Bookings
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0.5em'
              }}
            >
              <SearchWrap>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  onKeyUp={(e) => {
                    if (e.code === 'Enter') {
                      navigate(`?search=${searchStringValue}`, {
                        replace: true
                      });
                    }
                  }}
                  value={searchStringValue}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </SearchWrap>
            </div>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              mt: '4px'
            }}
          >
            <Autocomplete
              sx={{ width: '140px' }}
              value={optionSelect}
              onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                if (newValue) {
                  setValue(newValue);
                  setSearchParams({ select: newValue });
                  GetBooking({
                    variables: { status: BookingStatus?.[value] }
                  });
                }
              }}
              options={options}
              size="small"
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField size="small" {...params} label={'select status'} />
              )}
            />
            <Grid sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <BookingFilter
                showModal={showModal}
                handleClose={handleClose}
                setShowModal={setShowModal}
                onFilterHandler={onFilterHandler}
                filterLoading={loading}
                filterInput={filteredInput}
                resetSearch={resetSearch}
              />
              <ExportCSV
                csvData={dataExport}
                reset={resetExport}
                callQuery={exportQuery}
                fileName="Booking"
                loading={bookingLoading}
              />
            </Grid>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Box sx={{ px: 2, pb: 2 }}>
        {loading && (pageNumber === 1 || filterInput?.offset === 1) ? (
          <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : (
          <>
            <TableWrapper loading={loading} header={BookingListTableHeader}>
              {bookingData?.length ? (
                bookingData?.map((item, index) => {
                  const totalPaymentAmount = item?.payments?.length
                    ? item?.payments?.reduce(
                        (acc, payment) => acc + payment?.amount,
                        0
                      )
                    : 0;
                  const booking = {
                    regNo:
                      item?.status === 'CANCELLED'
                        ? item?.leadWithArchivedBooking?.regNo
                        : item?.leadWithActiveBooking?.regNo,
                    bookingId: item?.id,
                    activeBookingId: item?.id,
                    name: item?.customer?.name,
                    // createdBy: item?.createdBy?.name,
                    balanceAmount:
                      totalPaymentAmount == 0
                        ? 0
                        : item?.bookingPayment?.saleAmount - totalPaymentAmount,
                    phoneNo: item?.customer?.phoneNo,
                    paymentMode: item?.bookingPayment?.bookingType ?? '-',
                    saleAmount: item?.bookingPayment?.saleAmount ?? 0,
                    bookingPaymentId: item?.bookingPayment?.id,
                    activeLoanId: item?.activeLoan?.id,
                    fieldInspectionDate: item?.activeLoan?.fieldInspectionDate,
                    loginDate: item?.activeLoan?.loginDate,
                    deliveryOrderDate: item?.activeLoan?.deliveryOrderDate,
                    appliedLoanAmount: item?.activeLoan?.appliedLoanAmount,
                    createdAt: item?.activeLoan?.createdAt
                      ? format(
                          Date.parse(item?.activeLoan?.createdAt),
                          'dd-MM-yy'
                        )
                      : '-',
                    createdBookingAt: item?.createdAt
                      ? format(Date.parse(item?.createdAt), 'dd-MM-yy')
                      : '-',
                    updateAt: item?.updatedAt
                      ? format(Date.parse(item?.updatedAt), 'dd-MM-yy')
                      : '-',
                    state: item?.customer?.customerState?.state ?? '-',
                    district: item?.customer?.customerDistrict?.name ?? '-',
                    status: item?.status,
                    statusDate: item?.updatedAt
                      ? format(Date.parse(item?.updatedAt), 'dd-MM-yy')
                      : '-'
                  };
                  return <BookingListRow enquiry={booking} key={index} />;
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '20px',
                        fontWeight: '900'
                      }}
                    >
                      <Typography>No data found</Typography>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableWrapper>
            {loading && bookingData?.length ? (
              <div
                style={{ padding: '30px' }}
                className="d-flex flex-column  justify-content-center align-items-center gap-2"
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
                <h5>Loading..</h5>
              </div>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '30px'
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    display:
                      bookings?.filteredBookings?.length >= 20
                        ? 'block'
                        : 'none'
                  }}
                  onClick={() => {
                    if (filterInput?.offset === 1) {
                      setPageNumber(2);
                      cachedBookingFilter({ ...filterInput, offset: null });
                    } else {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  Load More
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default Booking;
