import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid, Link } from '@mui/material';
import {
  LeadRef,
  PaymentStatus,
  RefurbishmentStatus,
  useGetLeadDealAmountsQuery,
  useGetLeadDealDetailsQuery
} from 'src/generated/hooks_and_more';

import { LeadCreateAndUpdateProps } from 'src/types';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import CtaButton from '../CTA_Button/CtaButton';
import { array } from 'prop-types';
import './style.css';

const ConfirmInstallation = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    onPressOfDesiredOrUndesiredButton,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    regNo,
    refurbishmentRequestId
  } = props;
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };

  const { data } = useGetLeadDealDetailsQuery({
    variables: { id: leadId }
  });
  const requests = data?.getLead?.refurbishmentDetails?.requests;
  // const value = requests?.[requests?.length - 1]?.items;

  const checkForMatchingid = requests?.filter((i) => refurbishmentRequestId === i?.id);
  const value = checkForMatchingid?.[0]?.items.filter((i)=>i?.status === "APPROVED");


  const formik = useFormik({
    initialValues: {
      items: value?.map((i) => ({ installationUrl: null }))
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      items: Yup.array().of(
        Yup.object().shape({
          installationUrl: Yup.string().required()
        })
      )
    }),

    onSubmit: (values) => {
      let imageArray = formik?.values?.items?.map((i, index) => ({
        id: value[index]?.id,
        installationProofUrl: i?.installationUrl
      }));

      leadInput = {
        regNo: regNo,
        id: leadId,
        
        refurbishmentDetails: {
          requests: [
            {
              id: refurbishmentRequestId,
              items: imageArray
            }
          ]
        }
      };
      // console.log(leadInput, "LeadInput")
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
    return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {value?.map((item, index) => {
          return (
            item?.status === RefurbishmentStatus?.Approved && (
              <>
                <Grid item xs={12} md={12} key={index}>
                  <h5>{item?.product?.name}</h5>
                  <p style={{fontSize: '16px'}}>{item?.description}</p>
                </Grid>
                  <Grid
                    
                    item xs={12} md={12}
                  
                  >
                    <FileUploadToS3
                      accept="image/*,.pdf"
                      id={`installationProofUrl-${index}`}
                      value={formik?.values?.items?.[index]?.installationUrl}
                      onChange={(value) => {
                        formik?.setFieldValue(
                          `items[${index}].installationUrl`,
                          value
                        );
                      }}
                      placeholder="Vehicle part Image"
                    />
                  </Grid>
               
                
              </>
            )
          );
        })}
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
};

export default ConfirmInstallation;
