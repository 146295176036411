import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Button, Tooltip } from '@mui/material';

import RefurbishDetailSubTable from './RefurbishmentPopOver';
type DetailsOptions = {
  key?: string;
  value?: any;
  type?: string;
  status?: string;
  textColor?: string;
  isHidden?: boolean;
  title?: string;
  paymentDetailList?: {
    title?: string;
    data?: {
      key?: string;
      value?: string | number;
      status?: string;
      url?: string;
      description?: string;
    }[];
  };
  BreakDownList?: {
    title?: string;
    hide?: Boolean;
    data?: {
      key?: string;
      value?: string | number;
      status?: string;
      url?: string;
    }[];
    paymentDetailList?: {
      key?: string;
      value?: string | number;
      status?: string;
      url?: string;
    }[];
  };
  itemConfirmList?: {
    title?: string;
    data?: {
      key?: string;
      value?: string | number;
      type?: string;
      status?: string;
      url?: string;
      description?: string;
    }[];
  };
  itemsList?: {
    title?: string;
    data?: {
      key?: string;
      value?: string | number;
      type?: string;
      status?: string;
      url?: string;
      description?: string;
    }[];
  };
  view?: boolean;
  buttonValue?: string;
};

type PartTypeProps = {
  valuePart?: () => void;
};

type DetailsTabPanelPropsType = {
  data: DetailsOptions[];
  handleClick: () => void;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const widthStyle = {
  width: '50%'
};

function RefurbishmentRequestListDisplay({
  data,
  handleClick
}: DetailsTabPanelPropsType) {
  return (
    <TableContainer sx={{ mt: 3 }} component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableBody>
          {data?.map((item, index: number) => {
            return (
              <>
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row" sx={widthStyle}>
                    {item.key}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" sx={widthStyle}>
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      <>
                        <p style={{ width: '150px' }}> {item?.value} </p>
                        <RefurbishDetailSubTable
                          title={'Refurbishment'}
                          onClickValue={index}
                          paymentDetailList={item?.paymentDetailList ?? null}
                          itemConfirmList={item?.itemConfirmList}
                          itemsList={item?.itemsList}
                          BreakDownList={item?.BreakDownList}
                        />
                      </>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              </>
            );
          })}
          {/* <span>
            <Button
              onClick={() => {
                handleClick();
              }}
            >
              RAISE REFURBISHMENT REQUEST
            </Button>
          </span> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default RefurbishmentRequestListDisplay;
