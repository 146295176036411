import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';

import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import MultiSelectAutoComplete from 'src/components/Form/MultiSelectAutoComplete';
import CloseIcon from '@mui/icons-material/Close';

import {
  EnquiryStatus,
  EnquiryType,
  LeadSource,
  PaymentStatus,
  SelectTypeOfDate,
  useGetAllNewBrandsQuery,
  useGetCentreListQuery,
  useGetDistrictsByStateQuery,
  useGetModelByBrandIdLazyQuery,
  useGetTehsilsByDistrictQuery,
  useGetAllActiveStateQuery,
  UserRole
} from 'src/generated/hooks_and_more';
import {
  cachedEnquiryFilter,
  enumToItems,
  titleCaseToReadable
} from 'src/utils/utility';

import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import { StatesType } from 'src/types';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
const style = {
  position: 'absolute' as 'absolute',
  top: '55%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '80%',
  bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '10px',
  p: 2
};

const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '10px',
  cursor: 'pointer',
  color: 'rgb(195, 62, 62)'
};

type LeadFilterPropsType = {
  showModal: boolean;
  setShowModal: any;
  handleClose: () => void;
  onFilterHandler: (variables: any) => void;
  filterLoading: boolean;
  filterInput?: any;
  resetSearch?: any;
};

type modelType = {
  id?: string;
  model?: string;
};

type auctionAgencyType = {
  id: string;
  name: string;
};

type StatusType = {
  label: string;
  value: any;
};

type StateType = {
  state_name: any;
  state_id: string;
};
type DistrictType = {
  name: string;
  id: string;
};
type TehsilType = {
  name: string;
  id: string;
  stateId: string;
  distId: string;
};

function EnquiryFilter({
  showModal,
  setShowModal,
  handleClose,
  onFilterHandler,
  filterLoading,
  filterInput,
  resetSearch
}: LeadFilterPropsType) {
  // const [leadListData, setLeadListData] = useState<LeadPreviewFragment[]>([]);
  const { loggedInUser } = useLoggedInUser();
  const [stateValue, setStateValue] = useState<StateType[] | any>(null);
  const [district, setDistrict] = useState<DistrictType[] | any>(null);
  const [tehsil, setTehsil] = useState<TehsilType[] | any>(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [fromDateErrMsg, setFromDateErrorMsg] = useState(null);
  const [toDateErrMsg, setToDateErrorMsg] = useState(null);

  const [make, setMake] = useState<StatusType | any>(null);
  const [modelValue, setModelValue] = useState<modelType[] | any>([]);
  const [enquiryStatus, setEnquiryStatus] = useState<any>(null);
  const [enquiryType, setEnquiryType] = useState<any>(null);
  const [walkInType, setWalkIntype] = useState<any>(null);
  const [dateRangeType, setDateRangeType] = useState<any>(null);
  const [tehsilMerge, setTehsilMerge] = useState<any>([]);

  const [centerNameValue, setCenterNameValue] = useState<
    auctionAgencyType[] | any
  >(null);
  const [dateRange, setDateRange] = useState<[Date, Date] | null>();

  const paymentStatus = enumToItems(PaymentStatus);
  const center = useGetCentreListQuery();
  const stateOption = useGetAllActiveStateQuery();
  const enquiryStatusOptions = [
    { label: 'Open', value: false },
    { label: 'Closed', value: true }
  ];
  const EnquiryTypeOptions = enumToItems(EnquiryStatus);
  const WalkInTypeOptions = enumToItems(EnquiryType);
  const dateRangeOptions = enumToItems(SelectTypeOfDate);
  const source = enumToItems(LeadSource);
  const brandListOptions = useGetAllNewBrandsQuery();

  const [getModels, { data: modelName, loading }] =
    useGetModelByBrandIdLazyQuery();

  const [valueModel, setValueModels] = useState([]);

  useEffect(() => {
    let options =
      modelName?.queryBrand?.flatMap((item) =>
        item?.models?.map((model) => ({ id: model?.id, model: model?.name }))
      ) ?? [];
    setValueModels(options);
  }, [modelName]);

  useEffect(() => {
    if (filterInput?.models?.length) {
      let models = filterInput?.models.map((modelName) => ({
        model: modelName
      }));
      setModelValue(models ?? null);
    }

    setStateValue({ state: filterInput?.state });
    if (filterInput?.districts?.length) {
      let districts = filterInput?.districts.map((districtName) => ({
        name: districtName
      }));
      setDistrict(districts ?? null);
    }
    if (filterInput?.tehsils?.length) {
      let tehsils = filterInput?.tehsils.map((tehsilName) => ({
        name: tehsilName
      }));
      setTehsil(tehsils ?? null);
    }
    setStateValue(filterInput?.state ? { state: filterInput?.state } : null);
    setMake(
      filterInput?.makes?.length
        ? {
            label: titleCaseToReadable(filterInput?.makes[0]),
            value: filterInput?.makes[0]
          }
        : null
    );
    setDateRangeType(
      filterInput?.dateType
        ? {
            label: titleCaseToReadable(filterInput?.dateType),
            value: filterInput?.dateType
          }
        : null
    );
    setEnquiryStatus(
      filterInput?.isClosed !== undefined
        ? {
            label: filterInput?.isClosed ? 'Closed' : 'Open',
            value: filterInput?.isClosed
          }
        : null
    );
    setEnquiryType(
      filterInput?.status
        ? {
            label: titleCaseToReadable(filterInput?.status),
            value: filterInput?.status
          }
        : null
    );
    setCenterNameValue(
      filterInput?.centre ? { name: filterInput?.centre } : null
    );
    setFromDate(filterInput?.start ?? null);
    setToDate(filterInput?.end ?? null);
    setWalkIntype(
      filterInput?.type
        ? {
            label: titleCaseToReadable(filterInput?.type),
            value: filterInput?.type
          }
        : null
    );
  }, [filterInput]);
  const { data: districtOptions } = useGetDistrictsByStateQuery({
    variables: {
      id: stateValue?.state_id
    }
  });
  const { data: TehsilOptions, client } = useGetTehsilsByDistrictQuery({
    skip: !district?.length,
    variables: {
      id: district?.length && district[district?.length - 1]?.district_id
    },
    onCompleted: () => {
      setTehsilMerge([
        ...tehsilMerge,
        ...TehsilOptions?.getTehsilByDistricts?.data
      ]);
    }
  });
  const workingOnStates = stateOption.data?.getStateInfo?.data;

  const onFilterClick = () => {
    const brand = make?.name;
    const state: string = stateValue?.state_name;
    const districts: string[] = district?.map(
      (item) => item?.district_name ?? []
    );
    const tehsils: string[] = tehsil?.map((item) => item?.tehsil_name ?? []);
    const model: string[] = modelValue?.map((item) => item.model ?? '');
    const centre = (centerNameValue?.name as string) ?? '';
    const dateType = dateRangeType?.value as string;
    // let start = dateRange?.length && dateRange[0];
    // let end = dateRange?.length && dateRange[1];
    const isClosed = enquiryStatus?.value;
    const walkinType = walkInType?.value;
    const statusOfEnquiry = enquiryType?.value;

    let variables = {
      state: state,
      status: statusOfEnquiry,
      models: model,
      makes: brand,
      start: `${fromDate} 00:00:00`,
      end: ` ${toDate} 23:59:59`,
      dateType: dateType,
      centre: centre,
      type: walkinType,
      districts: districts,
      tehsils: tehsils,
      search: filterInput?.search
    };
    if (fromDate && !toDate) {
      variables['end'] = String(new Date());
    }
    if (isClosed !== null) {
      variables['isClosed'] = isClosed;
    }
    if (!Boolean(fromDate) && dateType) {
      setFromDateErrorMsg('*From Date is required');
    }
    if (!Boolean(toDate) && dateType) {
      setToDateErrorMsg('*To Date is required');
    }
    if (
      (Boolean(fromDate && !fromDateErrMsg) &&
        Boolean(toDate && !toDateErrMsg)) ||
      (!Boolean(fromDate) && !dateType && !Boolean(toDate) && !dateType)
    ) {
      cachedEnquiryFilter({ ...variables, offset: 1, pageSize: 20 });
      onFilterHandler({ ...variables, offset: 1, pageSize: 20 });
      setShowModal(false);
    }
  };

  const onResetHandler = () => {
    setStateValue(null);
    setMake(null);
    setModelValue(null);
    setDateRangeType(null);
    setEnquiryStatus(null);
    setEnquiryType(null);
    setCenterNameValue(null);
    setDistrict(null);
    setTehsil(null);
    setFromDate(null);
    setToDate(null);
    setFromDateErrorMsg(null);
    setToDateErrorMsg(null);
    setWalkIntype(null);
    resetSearch();
    cachedEnquiryFilter({ offset: 1, pageSize: 20 });
  };

  // const onDateChange = (e: DateRange | null, placehldr: string) => {
  //   setDateRange(e ? e : null);
  // };

  // console.log(dateRange, purchaseDateRange, 'sweta');
  const operatingStates = stateOption?.data?.getStateInfo?.data as StatesType[];

  return (
    <>
      <div
        style={{ display: 'flex', paddingRight: '20px', alignItems: 'center' }}
      >
        <Grid>
          <Button
            sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
            variant="outlined"
            onClick={() => {
              onResetHandler();
              onFilterHandler({ offset: 1, pageSize: 20 });
            }}
            // onClick={() => {
            //   if (location.state) {
            //     location.state = null;
            //   }
            //   getFilteredLeads({});
            // }}
          >
            Reset
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ mt: { xs: 0, md: 0 } }}
            variant="contained"
            onClick={() => {
              setShowModal(true);
            }}
            // disabled={filterLoading}
            // startIcon={filterLoading ? <CircularProgress size={20} /> : null}
          >
            Filter
          </Button>
        </Grid>
      </div>

      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={closeBtnStyle}>
            <CloseIcon
              onClick={() => {
                setShowModal(false);
              }}
            />
          </Box>
          <Grid
            container
            spacing={1}
            style={{ overflowY: 'auto', padding: '20px', height: '380px' }}
          >
            {loggedInUser?.role !== UserRole.CentreManager ? (
              <Grid item xs={6} md={6}>
                <InputAutoComplete
                  onChange={(value) => setCenterNameValue(value)}
                  value={centerNameValue}
                  placeholder="Select Center"
                  options={center?.data?.queryCentre ?? []}
                  optionString="name"
                />
              </Grid>
            ) : null}

            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => {
                  setStateValue(value);
                  setDistrict(null);
                  setTehsil([]);
                  setTehsilMerge([]);
                }}
                value={stateValue}
                options={
                  operatingStates?.map((i) => ({
                    state_id: i?.state_id,
                    state_name: titleCaseToReadable(i?.state_name)
                  })) ?? []
                } //stateOption.data?.getStateInfo?.data
                placeholder="Select State"
                optionString="state_name"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => {
                  setDistrict(value);
                }}
                value={district ?? []}
                options={districtOptions?.getDistrictByStateInfo?.data ?? []}
                optionString="district_name"
                placeholder="Select Districts"
                disabled={!stateValue?.state_name}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => setTehsil(value)}
                value={tehsil ?? []}
                options={tehsilMerge ?? []}
                optionString="tehsil_name"
                placeholder="Select Tehsils"
                disabled={!district?.length}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => {
                  setMake(value);

                  setModelValue(null);
                  if (value) {
                    getModels({
                      variables: { brand_id: [Number(value?.brand_id)] }
                    });
                  }
                }}
                value={make}
                options={brandListOptions?.data?.queryBrand}
                optionString="name"
                placeholder="Select Brands"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => setModelValue(value)}
                value={modelValue ?? []}
                disabled={!make}
                options={valueModel ?? []}
                placeholder="Select Models"
                optionString="model"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setEnquiryStatus(value)}
                value={enquiryStatus}
                options={enquiryStatusOptions}
                placeholder="Select Enquiry status"
                optionString="label"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setWalkIntype(value)}
                value={walkInType}
                options={WalkInTypeOptions}
                placeholder="Select Walkin type"
                optionString="label"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setEnquiryType(value)}
                value={enquiryType}
                options={EnquiryTypeOptions}
                placeholder="Select Enquiry type"
                optionString="label"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => {
                  setDateRangeType(value);
                  setFromDate(null);
                  setFromDateErrorMsg(null);
                  setToDateErrorMsg(null);
                  setToDate(null);
                }}
                value={dateRangeType}
                options={dateRangeOptions}
                placeholder="Select type of date"
                optionString="label"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <DatePickerCalender
                onChange={(value) => {
                  setFromDate(new Date(value).toDateString());
                  setFromDateErrorMsg(false);
                  if (
                    value == 'Invalid Date' ||
                    new Date(value).getFullYear() === 1970
                  ) {
                    setFromDateErrorMsg('*Invalid Date');
                  }
                }}
                placeholder="From Date"
                disabled={!Boolean(dateRangeType)}
                value={fromDate}
              />

              {fromDateErrMsg && (
                <p style={{ color: 'red' }}>{fromDateErrMsg}</p>
              )}
            </Grid>

            <Grid item xs={6} md={6}>
              <DatePickerCalender
                onChange={(value) => {
                  setToDate(new Date(value).toDateString());
                  setToDateErrorMsg(null);
                  if (
                    value == 'Invalid Date' ||
                    new Date(value).getFullYear() === 1970
                  ) {
                    setToDateErrorMsg('*Invalid Date');
                  }
                }}
                value={toDate}
                disabled={!Boolean(fromDate)}
                placeholder="To Date"
              />
              {toDateErrMsg && <p style={{ color: 'red' }}> {toDateErrMsg}</p>}
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Grid
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                gap: '20px',
                mb: '10px'
              }}
              md={12}
            >
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                // style={{ marginRight: '20px' }}
                style={{ width: '110px' }}
                variant="contained"
                onClick={() => {
                  onResetHandler();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                // style={{ width: '120px' }}
                variant="contained"
                onClick={onFilterClick}
                disabled={filterLoading}
                startIcon={
                  filterLoading ? <CircularProgress size={20} /> : null
                }
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default EnquiryFilter;
