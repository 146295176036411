import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import Input from 'src/components/Form/Input';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import {
  ActionType,
  AddUserMutationVariables,
  DeviceType,
  ModuleType,
  TaskStatus,
  UserRef,
  UserRole,
  useAddLogsMutation,
  useAddUserMutation,
  useGetAllActiveStateQuery,
  useGetCenterUserQuery,
  useGetCentreListQuery,
  useGetUserEmailLazyQuery,
  useGetUserforUserQuery
} from 'src/generated/hooks_and_more';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { useEffect, useState } from 'react';
import ConfimationModal from 'src/components/Composite/ConfirmationModal/ConfimationModal';
import { useToasts } from 'react-toast-notifications';
import {
  cachedLeadFilter,
  enumToItems,
  forlogtitleCase,
  getUserData,
  titleCaseToReadable
} from 'src/utils/utility';

import { AddUser } from 'src/utils/schema';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
import MultiSelectAutoComplete from 'src/components/Form/MultiSelectAutoComplete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

function AddUsers() {
  const [open, setOpen] = useState(false);
  const [on, SetOn] = useState(false);
  const { addToast } = useToasts();
  const { data, loading, error } = useGetCenterUserQuery();

  const { data: stateOption } = useGetAllActiveStateQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      console.log(data, "stateOption?.getStateInfo?.datastateOption?.getStateInfo?.data")
    }
  });

  const { data: usersValues } = useGetUserforUserQuery();
  const [setEmail, { data: EmailData }] = useGetUserEmailLazyQuery();

  let centerManager = [];
  data?.queryUser?.map((item: any) => {
    return (centerManager = [
      ...centerManager,
      { label: item.email, value: item.email }
    ]);
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    cachedLeadFilter(null);
  }, []);
  const initialValues = {
    name: '',
    email: '',
    role: { value: '', label: '' },
    center: { name: '', label: '' },
    createdAt: new Date(),
    isActive: true ? { label: 'Active' } : { label: 'Inactive' },
    reportingManager: null,
    reporterName: { value: '', label: '', id: '' },
    registrationState: null,
    reporterEmail: ''
  };

  const states = stateOption?.getStateInfo?.data?.map((item) => ({
    label: item?.state_name,
    value: item?.state_name
  }));

  console.log(stateOption, "hfhfhh")
  const [AddUsers] = useAddUserMutation();
  const [AddLogs] = useAddLogsMutation();
  const theme = useTheme();

  const { loggedInUser } = useLoggedInUser();

  const [variables, setVariables] = useState<any>([]);
  const title = 'Add User';
  const btnTitle = 'Add User';

  const onConfirmationHandler = (reset: () => void) => {
    AddUsers({
      variables: variables,
      onCompleted(data) {
        AddLogs({
          variables: {
            taskPerformedBy: { id: loggedInUser?.userId },
            actionType: ActionType.Create,
            module: ModuleType.User,
            createdAt: new Date(),
            sourceUpdated: data?.addUser?.user?.[0]?.email,
            updatedValue: JSON.stringify(data?.addUser?.user?.[0]),
            previousValue: '',
            status: TaskStatus.Success
          },
          onCompleted(data) {}
        });

        handleClose();
        reset();
        addToast('User Added Successfully!!!', {
          appearance: 'success',
          autoDismiss: true
        });
      },
      onError(err) {
        handleClose();
        AddLogs({
          variables: {
            taskPerformedBy: { id: loggedInUser?.userId },
            actionType: ActionType.Create,
            module: ModuleType.User,
            createdAt: new Date(),
            sourceUpdated: '',
            updatedValue: '',
            previousValue: '',
            status: TaskStatus.Failed
          }
        });
        if (err?.message?.includes('already exists')) {
          handleClose();
          addToast('User Already Exist!!!', {
            appearance: 'error',
            autoDismiss: true
          });
        } else {
          addToast('Something went wrong!!!', {
            appearance: 'error',
            autoDismiss: true
          });
        }
      }
    });
  };

  const userRoleList = enumToItems(UserRole);
  const center = useGetCentreListQuery();
 

 
  return (
    <Box sx={{ mt: 3 }} display={'flex'} justifyContent={'center'}>
      <Box width={'80%'}>
        <Card>
          <Typography sx={{ my: 1, mx: 2 }} variant="h3">
            {title}
          </Typography>
          <Divider />
          <CardContent>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                const variable: any = {
                  UserPatch: {
                    name: values.name,
                    email: values.email,
                    role: values?.role?.value as UserRole,
                    updatedAt: new Date(),
                    createdAt: new Date(),
                    isActive: values.isActive.label === 'Active' ? true : false
                  }
                };
                if (values?.center?.name) {
                  variable.UserPatch.centre = { name: values?.center.name };
                }
                if (!!values?.registrationState) {
                  variable.UserPatch.registrationState = values?.registrationState?.map((i)=> i?.label);
                }

                if (values?.reporterName?.value) {
                  variable.UserPatch.reportingManager = {
                    name: values.reporterName.value,
                    email: values.reporterEmail,
                    id: values?.reporterName?.id
                  };
                }

                setVariables(variable);
                handleClickOpen();
              }}
              validationSchema={AddUser}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
                resetForm
              }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Input
                          value={values.name}
                          name="name"
                          onChange={handleChange}
                          placeholder="Name"
                        />
                        {errors.name && touched.name ? (
                          <div style={{ color: 'red' }}>{errors?.name}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        {errors.email && touched.email ? (
                          <div style={{ color: 'red' }}>{errors?.email}</div>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputAutoComplete
                          options={userRoleList}
                          placeholder="Role"
                          value={values.role}
                          onChange={(value) => {
                            setFieldValue('role', value);
                          }}
                        />
                        {errors.role && touched.role ? (
                          <div style={{ color: 'red' }}>role is required</div>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputAutoComplete
                          placeholder="Center"
                          value={values.center}
                          onChange={(value) => {
                            setFieldValue('center', value);
                          }}
                          options={center?.data?.queryCentre ?? []}
                          optionString="name"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputAutoComplete
                          placeholder="Status"
                          onChange={(value) => {
                            setFieldValue('isActive', value);
                          }}
                          options={[{ label: 'Active' }, { label: 'Inactive' }]}
                          // optionString="isActive"
                          value={values.isActive}
                          getOptionLabel={(option: { label: string }) =>
                            option.label
                          }
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <MultiSelectAutoComplete
                          onChange={(value) => {
                            setFieldValue(
                              'registrationState',
                              value
                            );
                          }}
                         
                          value={values?.registrationState ?? []}
                          options={states || []}
                          optionString="label"
                          placeholder="Select State"
                        />
                       {console.log(values.registrationState, states, "hfhfhhfhfh")}
                      </Grid>
                    
                      <Grid item xs={6} md={6}>
                        <InputAutoComplete
                          onChange={(value) => {
                            setFieldValue('reporterName', value);

                            setEmail({
                              variables: { id: value?.id },
                              onCompleted() {
                                console.log(data);
                                setFieldValue(
                                  'reporterEmail',
                                  data?.queryUser?.[0]?.email
                                );
                              }
                            });
                          }}
                          value={values?.reporterName ?? ''}
                          options={
                            usersValues?.queryUser.map((i) => ({
                              value: titleCaseToReadable(i?.name),
                              label: `${i?.name}- ${titleCaseToReadable(
                                i?.role
                              )}`,

                              id: i?.id
                            })) ?? []
                          }
                          placeholder="Select Reporting Manager"
                        />
                      </Grid>
                    </Grid>

                    <Divider sx={{ my: 3 }} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button variant="contained" type="submit">
                        {btnTitle}
                      </Button>
                    </div>
                    <ConfimationModal
                      textToShow="Do you want to add a new User?"
                      open={open}
                      handleClose={handleClose}
                      toastMsg={on}
                      onConfirmationHandler={onConfirmationHandler}
                      reset={resetForm}
                    />
                  </Form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default AddUsers;
