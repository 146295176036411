import {
  LeadRef,
  LeadSource,
  LeadStatus,
  ListingStatus,
  LoanRejectionReason,
  PaymentFor,
  UserRole,
  useGetLeadDetailsQuery,
  useLeadStatusFlowQuery,
  useMarkAndSoldLazyQuery,
  useMarkAndSoldQuery,
  usePopupDynamicValuesQuery,
  useRefurbishmentRequestIdQuery
} from 'src/generated/hooks_and_more';
import LeadGenerate from './form/LeadGenerate';
import RTOformLead from './form/rtoform';
import VehicleDetailsPending from './form/VehicleDetailsPending';
import { useLocation } from 'react-router';
import BidAmountForm from './form/bidForms/bidAmountform';
import FinalBidAmountForm from './form/bidForms/finalBidAmount';
import AccessoriesUpdateForm from './form/accessoriesUpdate';
import UploadVehichleImages from './form/uploadVehicleImages';
import RaisePaymentRequestForm from './form/raisePaymentRequestform';
import SchedulePickForm from './form/schedulePickupForm';
import SchedulePaymentForm from './form/schedulePickupPayment';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import DealPaymentPending from './form/DealPaymentPending';
import { Button } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, createTheme, styled } from '@mui/material';
import PickupAssignment from './form/PickupAssignment';
import BankConfirmation from './form/BankConfirmation';
import ConfirmDeal from './form/DealershipForms/ConfirmDeals';
import VehicleDetailsUpdateDForm from './form/DealershipForms/VehicleDetailsUpdateD';
import RequestDocument from './form/DealershipForms/requestDocument';
import ConfirmDocument from './form/DealershipForms/ConfirmDocument';
import HoldBackForm from './form/DealershipForms/HoldBackForm';
import AddParkingDetails from './form/AddParkingDetails';
import ApproveParkingPayment from './form/ApproveParkingPayment';
import ParkingPayment from './form/ParkingPayment';
import SelfieUpload from './form/SelfieUpload';
import StockIn from './form/StockIn';
import useCreateLeadStatusEvents from './hooks/useCreateLeadStatusEvents';
import { ActionType } from 'src/constants/constant';
import ApproveHoldBack from './form/DealershipForms/ApproveHoldBackCharge';
import PurchaseRequestForm from './form/DealershipForms/RaisePurchaseRequest';
import Map from './form/Map';
import ConfirmPurchaseRequest from './form/DealershipForms/ConfirmPurchaseRequest';
import ConfirmationPopup from './form/bidForms/Confirmation';
import SchedulePickDealership from './form/DealershipForms/SchedulePickupForm';
import LeadUpdate from './form/LeadUpdate';
import NewConfirmDealAmount from './form/DealershipForms/NewConfirmDeal';
import AlertPopup from './form/AlertPopup';
// import InternalTranferForm from './form/InternalTransferform';
import RefurshimentRequestPayment from './form/refurbishment/RequestPayment';
import ConfirmInstallation from './form/refurbishment/ConfirmInstallation';
import AddPaymentStructureRefurbishment from './form/refurbishment/AddPaymentStructure';
import RequestForDeliveryRefurb from './form/refurbishment/RequestForDelivery';
import VerifyDeliveryRefurb from './form/refurbishment/VerifyDelivery';
import InspectVehicle from './form/refurbishment_flow/InspectVehicle';
import RefurbishmentRequest from './form/refurbishment_flow/RefurbishmentRequest';
import InstallationBillUploaded from './form/refurbishment_flow/InstallationBillUploaded';
import InstallationPayment from './form/refurbishment_flow/InstallationPayment';
import ConfirmSellingPrice from './form/refurbishment_flow/ConfirmSellingPrice';
import BookingDetailsAdded from './form/refurbishment_flow/BookingDetailsAdded';
import BookingTokenPayment from './form/refurbishment_flow/BookingTokenPayment';
import BookingPaymentReview from './form/refurbishment_flow/BookingPaymentReview';
import BookingDeliveryPayment from './form/refurbishment_flow/BookingDeliveryPayment';
import BookingDeliveryReview from './form/refurbishment_flow/BookingDeliveryReview';
import ReviewPayment from './form/refurbishment_flow/ReviewPayment';
import { InternalTranferForm } from './form/InternalTransferform';
import Drop from './form/Drop';
import { useToasts } from 'react-toast-notifications';
import { getUserData, titleCaseToReadable } from 'src/utils/utility';

import InternalTransferMap from './form/acceptpickupItmap';
import SelfieUploadAtInternalTransfer from './form/SelfieAtInternalDrop';
import { useReactiveVar } from '@apollo/client';
import { LeadFlowFormsInputs } from 'src/utils/utility';
import RaisePaymentForEachRequest from './form/DealershipForms/raisePaymentforDealership';
import UploadPurchaseOrderPaymentReceipts from './form/DealershipForms/UploadPurchaseOrderPaymentReceipts';
import LoginFile from './form/Booking/loginLoan';
import { Login } from '@mui/icons-material';
import FIForm from './form/Booking/FIform';
import DOform from './form/Booking/DOform';
import ConfirmFIForm from './form/Booking/ConfirmInspection';
import BookingPartPaymentForm from './form/refurbishment_flow/BookingPartPartment';
import BookingCancelledForm from './form/BookingCancellation';
import BookingUpdation from './form/BookingUpdation';
import { environments } from 'src/config';
import LoanDrop from './form/Booking/LoanDrop';
import SellingPriceUpdate from './form/refurbishment_flow/SellingPriceUpdate';
import UpdatePaymentStructure from './form/refurbishment/updatePaymentStructure';

// import Map from './form/Map';

type LeadProcessProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentStatus?: LeadStatus;
  leadId?: string;
  regNo?: string;
  source?: LeadSource;
  refetch?: any;
  lseId?: string;
  title?: string;
  role?: string;
  currentLead?: any;
  onPressViewImagesButton?: ()=>void
};
const styles = (theme) => ({
  drawer_container: {
    [theme.breakpoints.down('md')]: {
      width: 100
    }
  }
});
function LeadProcess({
  open,
  setOpen,
  currentStatus,
  leadId,
  regNo,
  source,
  refetch,
  lseId,
  role,
  currentLead,
  onPressViewImagesButton
  
}: LeadProcessProps) {



  const { addingEvent, onPressDesiredButton, onPressUndesireButton } =
    useCreateLeadStatusEvents(toggleDrawer, leadId, lseId, regNo, refetch);
  const userDetails = getUserData();

  const [showPopup, setShowPopup] = useState(false);
  const [alert, setAlert] = useState(false);
  const [btnActionType, setBtnActionType] = useState(null);
  const location = useLocation();

  const state: {
    currentStatus?: LeadStatus;
    leadId?: string;
    regNo?: string;
    source?: string;
  } = location.state;
  const [leadInput, setLeadInput] = useState<LeadRef | any | null>(null);
  const [remarks, setRemarks] = useState<string | null>(null);
  const { addToast } = useToasts();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const { data, loading } = useLeadStatusFlowQuery({
    fetchPolicy: 'network-only',
    skip: !source,
    variables: {
      source
    },
    onCompleted: ({ queryLeadStatusFlow }) => {
      // console.log('This is the lead status flow data', queryLeadStatusFlow) // logging Lead status flow
    }
  });
  const { data: leadDetailsData } = useRefurbishmentRequestIdQuery({
    fetchPolicy: 'cache-and-network',
    skip: !currentStatus,
    variables: {
      leadId: leadId
    }
  });
  const ref = useRef(null);
  const rejectionReasonValue = leadInput?.rejectionReason?.toString();
  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     if (e.target.classList[0] === 'MuiBackdrop-root') {
  //       setAlert(true);
  //       setOpen(true);
  //     }
  //   };
  //   document.addEventListener('click', checkIfClickedOutside);
  //   return () => {
  //     document.removeEventListener('click', checkIfClickedOutside);
  //   };
  // }, [ref]);
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);
  function getCurrentDimension() {
    return window.innerWidth;
  }

  // const notifyTo = data?.queryLeadStatusFlow?.[0]?.notifyTo

  //log('assign task to at lpscreen', assignTaskTo)
  // const taskTitle = data?.queryLeadStatusFlow?.[0]?.taskTitle
  // const taskButtonTitle = data?.queryLeadStatusFlow?.[0]?.taskButtonTitle
  const leadInputValue = useReactiveVar(LeadFlowFormsInputs);

  const leadCurrentFlow = data?.queryLeadStatusFlow?.find(
    (f) => f.current === currentStatus
  );

  const desiredStatus = !currentStatus
    ? LeadStatus.LeadGenerated
    : leadCurrentFlow?.desired;

  const undesiredStatus = !currentStatus ? null : leadCurrentFlow?.undesired;
  const leadUndesiredFlow = data?.queryLeadStatusFlow?.find(
    (f) => f.current === undesiredStatus
  );

  const assignTaskTo = !currentStatus
    ? UserRole.ProcurementExecutive
    : leadCurrentFlow?.assignTaskTo;

  const canGoBack1Step = data?.queryLeadStatusFlow?.find(
    (s) => s.current === desiredStatus
  )?.canGoBack;

  const backStatus = canGoBack1Step ? leadCurrentFlow?.previous : undefined;

  const undesiredButtonText = data?.queryLeadStatusFlow?.find(
    (f) => f.current === undesiredStatus
  )?.cta;

  const hasPopup =
    desiredStatus === LeadStatus.LeadGenerated
      ? true
      : !!data?.queryLeadStatusFlow?.find((f) => {
        if (f.current === desiredStatus || f.current === undesiredStatus) {
          return true;
        } else {
          return false;
        }
      })?.showPopup;

      console.log(hasPopup, "hasModalPopuphasModalPopuphasModalPopuphasModalPopup")

  const showRemarksPopup = Boolean(
    data?.queryLeadStatusFlow?.find((f) => {
      if (f.current === undesiredStatus) {
        return true;
      }
    })?.showPopupRemark
  );
  function handleClose() {
    setShowPopup(!showPopup);
  }
  const showPopupWithInputField = data?.queryLeadStatusFlow?.find(
    (f) => f.current === undesiredStatus
  )?.showPopupWithInputField;

  const isPopupWithRemark = data?.queryLeadStatusFlow.find(
    (f) => f.current === undesiredStatus
  )?.isNegativeCase;
  // console.log(
  //   userDetails.role === UserRole.CentreManager &&
  //     desiredStatus === LeadStatus.RefurbishmentApproved,
  //   'FGHBJNKMHBNJM'
  // );
  // console.log(userDetails.role, desiredStatus, 'hcdsjudsh');

  // const isPopupWithRemark = data?.queryLeadStatusFlow.find((f) => f.current === undesiredStatus
  // )?.isNegativeCase;

  const positivePopupTitle =
    desiredStatus === LeadStatus.LeadGenerated
      ? data?.queryLeadStatusFlow?.find((f) => {
        return true;
      })?.popupTitle
      : data?.queryLeadStatusFlow?.find((f) => f.current === desiredStatus)
        ?.popupTitle;

  const negativePopupTitle = data?.queryLeadStatusFlow?.find(
    (f) => f.current === undesiredStatus
  )?.popupTitle;
  
  const positivePopupDescription =
    desiredStatus === LeadStatus.LeadGenerated
      ? data?.queryLeadStatusFlow?.find((f) => {
        return true;
      })?.popupDescription
      : data?.queryLeadStatusFlow?.find((f) => f.current === desiredStatus)
        ?.popupDescription;

  const negativePopupDescription = data?.queryLeadStatusFlow?.find(
    (f) => f.current === undesiredStatus
  )?.popupDescription;
  function onPressOfDesiredOrUndesiredButton(
    leadRef: LeadRef,

    remarks = '',
    actionType: ActionType = ActionType.DESIRED
  ) {
    const desireStatus = desiredStatus;
    if (hasPopup) {
      setLeadInput(leadRef);

      setRemarks(remarks);
      handleClose();
      setBtnActionType(
        actionType === ActionType.DESIRED
          ? ActionType.DESIRED
          : ActionType.UNDESIRED
      );
    } else {
      if (actionType === ActionType.DESIRED) {
        onPressDesiredButton(desireStatus, leadRef, leadDetailsData, remarks);
      } else {
        // undesired button action
        onPressUndesireButton(
          undesiredStatus,
          leadRef,
          leadDetailsData,
          remarks
        );
      }
    }
  }

  const popupRemark = (val) => {
    setRemarks(val);
  };

  const desiredButtonText = !desiredStatus
    ? null
    : data?.queryLeadStatusFlow?.find((f) => f.current === desiredStatus)?.cta;

  const [requestId, setDD] = useState(null);

  useEffect(() => {
    function getScopedEventMetadata(lseId: string) {

      if (
        !leadDetailsData?.queryLead?.[0]?.statusEvents ||
        desiredStatus === LeadStatus.VehicleInspected
      ) {
        return;
      } else {
        return leadDetailsData?.queryLead?.[0]?.statusEvents?.find(
          (lse) => lse?.id === lseId
        )?.metadata;
      }
    }
    const requestId = getScopedEventMetadata(lseId)?.split(':')?.[1];
    setDD(requestId);
  }, [leadDetailsData]);

  function renderForm(status: LeadStatus) {

    switch (status) {
      case LeadStatus.LeadGenerated:
        return (
          <LeadGenerate
            desireButtonText={desiredButtonText || 'Continue'}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            toggleDrawer={toggleDrawer}
            refetch={refetch}
            desireStatus={desiredStatus}
            regNo={regNo}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.LeadUpdated:
        return (
          <LeadUpdate
            regNo={regNo}
            leadId={leadId}
            desireButtonText={desiredButtonText || 'Continue'}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            toggleDrawer={toggleDrawer}
            refetch={refetch}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );

      // case LeadStatus.VehicleDetailsUpdated:
      //   return (
      //     <VehicleDetailsPending
      //       desireButtonText={desiredButtonText}
      //       undesireButtonText={undesiredButtonText}
      //       canGoBack={canGoBack1Step}
      //       regNo={regNo}
      //       leadId={leadId}
      //       desireStatus={desiredStatus}
      //       source={source}
      //       toggleDrawer={toggleDrawer}
      //       onPressOfDesiredOrUndesiredButton={
      //         onPressOfDesiredOrUndesiredButton
      //       }
      //     />
      //   );
      case LeadStatus.RtoVerificationCompleted:
        return (
          <RTOformLead
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.BidAmountLimitProposed:
        return (
          <BidAmountForm
            regNo={regNo}
            leadId={leadId}
            toggleDrawer={toggleDrawer}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.PaymentConfirmed:
        return (
          <DealPaymentPending
            regNo={regNo}
            leadId={leadId}
            toggleDrawer={toggleDrawer}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      // case LeadStatus.AccessoriesChecklistAddedForLeadGenerated:
      //   return (
      //     <AccessoriesUpdateForm
      //       desireButtonText={
      //         desiredButtonText ||
      //         'Accessories has been updated for Lead Generated'
      //       }
      //       undesireButtonText={undesiredButtonText}
      //       canGoBack={canGoBack1Step}
      //       regNo={regNo}
      //       leadId={leadId}
      //       desireStatus={desiredStatus}
      //       source={source}
      //       toggleDrawer={toggleDrawer}
      //       onPressOfDesiredOrUndesiredButton={
      //         onPressOfDesiredOrUndesiredButton
      //       }
      //     />
      //   );

      case LeadStatus.LeadVehicleImagesUploaded:
        return (
          <UploadVehichleImages
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );

      case LeadStatus.VehicleTransferRequestRaised:
        return (
          <InternalTranferForm
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.BidWon:
        return (
          <FinalBidAmountForm
            regNo={regNo}
            leadId={leadId}
            toggleDrawer={toggleDrawer}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.DealApproved:
        return (
          <BankConfirmation
            regNo={regNo}
            leadId={leadId}
            toggleDrawer={toggleDrawer}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.PaymentConfirmed:
        return (
          <DealPaymentPending
            toggleDrawer={toggleDrawer}
            regNo={regNo}
            leadId={leadId}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );

      case LeadStatus.PickupAssignmentDetailsAdded:
        if (source === LeadSource.BankAuction) {
          return (
            <PickupAssignment
              desireButtonText={desiredButtonText}
              undesireButtonText={undesiredButtonText}
              canGoBack={canGoBack1Step}
              regNo={regNo}
              leadId={leadId}
              desireStatus={desiredStatus}
              source={source}
              toggleDrawer={toggleDrawer}
              onPressOfDesiredOrUndesiredButton={
                onPressOfDesiredOrUndesiredButton
              }
            />
          );
        } else if (source === LeadSource.DealershipSale) {
          return (
            <SchedulePickDealership
              desireButtonText={desiredButtonText}
              undesireButtonText={undesiredButtonText}
              canGoBack={canGoBack1Step}
              regNo={regNo}
              leadId={leadId}
              desireStatus={desiredStatus}
              source={source}
              toggleDrawer={toggleDrawer}
              onPressOfDesiredOrUndesiredButton={
                onPressOfDesiredOrUndesiredButton
              }
            />
          );
        }
        break;

      case LeadStatus.DealAmountUpdated:
        return (
          <NewConfirmDealAmount
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );

      case LeadStatus.DealAmountConfirmed:
        return (
          <ConfirmDeal
          onPressViewImagesButton={onPressViewImagesButton}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.VehicleDetailsUpdated:
        if (source === LeadSource.BankAuction) {
          return (
            <VehicleDetailsPending
              desireButtonText={desiredButtonText}
              undesireButtonText={undesiredButtonText}
              canGoBack={canGoBack1Step}
              regNo={regNo}
              leadId={leadId}
              desireStatus={desiredStatus}
              source={source}
              toggleDrawer={toggleDrawer}
              onPressOfDesiredOrUndesiredButton={
                onPressOfDesiredOrUndesiredButton
              }
            />
          );
        } else if (source === LeadSource.DealershipSale) {
          return (
            <VehicleDetailsUpdateDForm
              desireButtonText={desiredButtonText}
              undesireButtonText={undesiredButtonText}
              canGoBack={canGoBack1Step}
              regNo={regNo}
              leadId={leadId}
              desireStatus={desiredStatus}
              source={source}
              toggleDrawer={toggleDrawer}
              onPressOfDesiredOrUndesiredButton={
                onPressOfDesiredOrUndesiredButton
              }
            />
          );
        }
        break;
      case LeadStatus.VehicleDocumentsChecked:
        return (
          <RequestDocument
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );

      case LeadStatus.VehicleDocumentsConfirmed:
        return (
          <ConfirmDocument
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.HoldbackConfirmedByOm:
        return (
          <HoldBackForm
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );

      case LeadStatus.HoldbackConfirmedByOh:
        return (
          <ApproveHoldBack
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.HoldbackConfirmedByPm:
        return (
          <ApproveHoldBack
            regNo={regNo}
            leadId={leadId}
            toggleDrawer={toggleDrawer}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );

      case LeadStatus.PurchaseRequestApproved:
        return (
          <ConfirmPurchaseRequest
            regNo={regNo}
            leadId={leadId}
            toggleDrawer={toggleDrawer}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );
      case LeadStatus.PurchaseRequestRaised:
        return (
          <PurchaseRequestForm
            regNo={regNo}
            leadId={leadId}
            toggleDrawer={toggleDrawer}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );

      case LeadStatus.PickupAssignmentDetailsAdded:
        return;

      case LeadStatus.PaymentRequestSent:
        return (
          <RaisePaymentRequestForm
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      case LeadStatus.AccessoriesChecklistAddedForLeadGenerated:
      case LeadStatus.AccessoriesChecklistAddedBeforeDelivery:
      case LeadStatus.AccessoriesChecklistAddedAfterDelivery:
      case LeadStatus.AccessoriesChecklistAddedBeforeInternalPickup:
      case LeadStatus.AccessoriesChecklistAddedAfterInternalDelivery:
        return (
          <AccessoriesUpdateForm
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      case LeadStatus.LeadVehicleImagesUploaded:
      case LeadStatus.PickupVehicleImagesUploaded:
      case LeadStatus.DeliveryVehicleImagesUploaded:
      case LeadStatus.InternalTransferPickupImagesUploaded:
      case LeadStatus.InternalTransferDropImagesUploaded:
        return (
          <UploadVehichleImages
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.PickupYardDetailsAdded:
        return (
          <SchedulePickForm
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.PickupParkingPaymentDetailsEstimationAdded:
        return (
          <SchedulePaymentForm
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      case LeadStatus.PickupParkingPaymentFinalDetailsAdded:
        return (
          <AddParkingDetails
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.PickupParkingPaymentApproved:
        return (
          <ApproveParkingPayment
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.PickupParkingPaymentReceiptsUploaded:
        return (
          <ParkingPayment
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.InternalTransferInternalSelfieUploaded:
        return (
          <SelfieUploadAtInternalTransfer
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.DeliverySelfieUploaded:
        return (
          <SelfieUpload
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.VehicleInStock:
        return (
          <StockIn
            desireButtonText={desiredButtonText}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.PickupAcceptedForInternalTransfer:
        return (
          <InternalTransferMap
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.PickupRequested:
      case LeadStatus.PickupAccepted:
      case LeadStatus.PickupInitiated:
      case LeadStatus.DeliveryStarted:
      case LeadStatus.DeliveryCompleted:
        return (
          <Map
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            source={source}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
          />
        );

      case LeadStatus.DealTokenPaymentRequested:
        return (
          <RaisePaymentForEachRequest
            desireButtonText={desiredButtonText}
            regNo={regNo}
            source={source}
            leadId={leadId}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            paymentFor={PaymentFor.DealToken}
          />
        );
      case LeadStatus.DealDeliveryPaymentRequested:
        return (
          <RaisePaymentForEachRequest
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            source={source}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            leadId={leadId}
            paymentFor={PaymentFor.DealDelivery}
            regNo={regNo}
          />
        );
      case LeadStatus.HoldbackRepaymentRequested:
        return (
          <RaisePaymentForEachRequest
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            source={source}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            leadId={leadId}
            paymentFor={PaymentFor.HoldbackRepayment}
            regNo={regNo}
          />
        );
      case LeadStatus.LoanRepaymentRequested:
        return (
          <RaisePaymentForEachRequest
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            source={source}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            leadId={leadId}
            paymentFor={PaymentFor.LoanRepayment}
            regNo={regNo}
          />
        );
      case LeadStatus.DealerPaymentRequested:
        return (
          <RaisePaymentForEachRequest
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            source={source}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            regNo={regNo}
            leadId={leadId}
            paymentFor={PaymentFor.DealPayment}
          />
        );

      case LeadStatus.DealTokenPaymentConfirmed:
        return (
          <UploadPurchaseOrderPaymentReceipts
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            source={source}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            regNo={regNo}
            leadId={leadId}
            paymentFor={PaymentFor.DealToken}
          />
        );
      case LeadStatus.DealDeliveryPaymentConfirmed:
        return (
          <UploadPurchaseOrderPaymentReceipts
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            source={source}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            regNo={regNo}
            leadId={leadId}
            paymentFor={PaymentFor.DealDelivery}
          />
        );
      case LeadStatus.HoldbackRepaymentConfirmed:
        return (
          <UploadPurchaseOrderPaymentReceipts
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            source={source}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            regNo={regNo}
            leadId={leadId}
            paymentFor={PaymentFor.HoldbackRepayment}
          />
        );
      case LeadStatus.LoanRepaymentConfirmed:
        return (
          <UploadPurchaseOrderPaymentReceipts
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            source={source}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            regNo={regNo}
            leadId={leadId}
            paymentFor={PaymentFor.LoanRepayment}
          />
        );
      case LeadStatus.DealerPaymentConfirmed:
        return (
          <UploadPurchaseOrderPaymentReceipts
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            source={source}
            desireStatus={desiredStatus}
            toggleDrawer={toggleDrawer}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            regNo={regNo}
            leadId={leadId}
            paymentFor={PaymentFor.DealPayment}
          />
        );

      case LeadStatus.VehicleInspected:
        if (
          userDetails.role === UserRole.CentreManager &&
          desiredStatus === LeadStatus.VehicleInspected
        ) {
          return (
            <InspectVehicle
              desireButtonText={desiredButtonText}
              undesireButtonText={undesiredButtonText}
              canGoBack={canGoBack1Step}
              regNo={regNo}
              leadId={leadId}
              desireStatus={desiredStatus}
              onPressOfDesiredOrUndesiredButton={
                onPressOfDesiredOrUndesiredButton
              }
              source={source}
              toggleDrawer={toggleDrawer}
            />
          );
        } else if (
          userDetails.role !== UserRole.CentreManager &&
          desiredStatus === LeadStatus.VehicleInspected
        ) {
          return (
            <div>
              <h5> Task Pending on Centre Manager</h5>
            </div>
          );
        }
        break;

      case LeadStatus.RefurbishmentApproved:
        return (
          <RefurbishmentRequest
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            refurbishmentRequestId={requestId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.InstallationBillsUploaded:
        return (
          <InstallationBillUploaded
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            refurbishmentRequestId={requestId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.InstallationPaymentRequestRaised:
        return (
          <RefurshimentRequestPayment
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            refurbishmentRequestId={requestId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      case LeadStatus.InstallationPaymentConfirmed:
        return (
          <InstallationPayment
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            refurbishmentRequestId={requestId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.InstallationConfirmedImagesUploaded:
        return (
          <ConfirmInstallation
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            refurbishmentRequestId={requestId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.ReadyForSale:
        return (
          <ConfirmSellingPrice
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            refurbishmentRequestId={requestId}
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.BookingDetailsAdded:
        return (
          <BookingDetailsAdded
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.PaymentStructureAdded:
        return (
          <AddPaymentStructureRefurbishment
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      case LeadStatus.PaymentStructureUpdated:
        return (
          <UpdatePaymentStructure
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      case LeadStatus.BookingTokenAmountAdded:
        return (
          <BookingTokenPayment
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            refurbishmentRequestId={requestId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
            paymentFor={PaymentFor.BookingToken}
          />
        );
      case LeadStatus.BookingPartPaymentAdded:
        if (
          userDetails.role === UserRole.CentreManager &&
          desiredStatus === LeadStatus.BookingPartPaymentAdded
        ) {
          return (
            <BookingPartPaymentForm
              desireButtonText={desiredButtonText}
              undesireButtonText={undesiredButtonText}
              canGoBack={canGoBack1Step}
              regNo={regNo}
              leadId={leadId}
              refurbishmentRequestId={requestId}
              desireStatus={desiredStatus}
              onPressOfDesiredOrUndesiredButton={
                onPressOfDesiredOrUndesiredButton
              }
              source={source}
              toggleDrawer={toggleDrawer}
              paymentFor={PaymentFor.BookingPart}
            />
          );
        } else if (
          userDetails.role !== UserRole.CentreManager &&
          desiredStatus === LeadStatus.BookingPartPaymentAdded
        ) {
          return (
            <div>
              {' '}
              <h5> Task Pending on Centre Manager</h5>
            </div>
          );
        }
        break;

      case LeadStatus.BookingPartPaymentReviewApproved:
        return (
          <ReviewPayment
            requestId={requestId}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            paymentFor={PaymentFor.BookingPart}
            paymentId={requestId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.BookingTokenPaymentReviewApproved:
        return (
          <ReviewPayment
            paymentFor={PaymentFor.BookingToken}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.BookingDeliveryAmountAdded:
        return (
          <BookingDeliveryPayment
            paymentFor={PaymentFor.BookingDelivery}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.BookingDeliveryPaymentReviewApproved:
        return (
          <ReviewPayment
            requestId={requestId}
            paymentFor={PaymentFor.BookingDelivery}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      case LeadStatus.BookingDeliveryRequested:
      case LeadStatus.BookingTotalPaymentReviewApproved:
        return (
          <RequestForDeliveryRefurb
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      case LeadStatus.BookingLoanFIAdded:
        return (
          <FIForm
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
            loginDate={
              getDynamicValue?.getLead?.activeBooking?.activeLoan?.loginDate
            }
          />
        );
      case LeadStatus.BookingLoanFIPassed:
        return (
          <ConfirmFIForm
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
            loginDate={
              getDynamicValue?.getLead?.activeBooking?.activeLoan?.loginDate
            }
          />
        );
      case LeadStatus.BookingLoanDOApproved:
        return (
          <DOform
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
            fiDate={
              getDynamicValue?.getLead?.activeBooking?.activeLoan
                ?.fieldInspectionConfirmedDate
            }
          />
        );
      case LeadStatus.BookingLoanPaymentReviewed:
        return (
          <ReviewPayment
            leadId={leadId}
            regNo={regNo}
            paymentFor={PaymentFor.BookingLoan}
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );
      case LeadStatus.DeliveryPhotoUploaded:
        return (
          <VerifyDeliveryRefurb
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            canGoBack={canGoBack1Step}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
            isVehicleListedOnWebsite={checkForListingStatus}
          />
        );

      case LeadStatus.LeadDropped:
        return (
          <Drop
            desireButtonText={desiredButtonText}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      case LeadStatus.BookingCancelled:
        return (
          <BookingCancelledForm
            desireButtonText={desiredButtonText}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
            currentLead={currentLead}
          />
        );
      case LeadStatus.BookingDetailsUpdated:
        return (
          <BookingUpdation
            desireButtonText={desiredButtonText}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
            currentLead={currentLead}
          />
        );
      case LeadStatus.SellingPriceUpdated:
        return (
          <>
            <SellingPriceUpdate
              desireButtonText={desiredButtonText}
              undesireButtonText={undesiredButtonText}
              canGoBack={canGoBack1Step}
              regNo={regNo}
              leadId={leadId}
              desireStatus={desiredStatus}
              onPressOfDesiredOrUndesiredButton={
                onPressOfDesiredOrUndesiredButton
              }
              refurbishmentRequestId={requestId}
              source={source}
              toggleDrawer={toggleDrawer}
            />

          </>
        )

      case LeadStatus.LoanCancelled:
        return (
          <>
            <LoanDrop
              desireButtonText={desiredButtonText}
              undesireButtonText={undesiredButtonText}
              canGoBack={canGoBack1Step}
              regNo={regNo}
              leadId={leadId}
              desireStatus={desiredStatus}
              onPressOfDesiredOrUndesiredButton={
                onPressOfDesiredOrUndesiredButton
              }
              refurbishmentRequestId={requestId}
              source={source}
              toggleDrawer={toggleDrawer}
            />

          </>
        )


      case LeadStatus.BookingLoanFileLoggedIn:
        return (
          <LoginFile
            desireButtonText={desiredButtonText}
            undesireButtonText={undesiredButtonText}
            regNo={regNo}
            leadId={leadId}
            desireStatus={desiredStatus}
            onPressOfDesiredOrUndesiredButton={
              onPressOfDesiredOrUndesiredButton
            }
            source={source}
            toggleDrawer={toggleDrawer}
          />
        );

      default:
        return <div>Task pending on Someone else</div>;
    }
  }
  const handleAlert = () => {
    setAlert(false);
  };
  const onConfirmationHandler = (
    popupAction: ActionType = ActionType.DESIRED,

    dealAmount: number = 0,
    rejectionReasonValue: LoanRejectionReason = null
  ) => {
    if (popupAction === ActionType.UNDESIRED) {
      handleClose();
    } else if (
      btnActionType === ActionType.DESIRED &&
      popupAction === ActionType.DESIRED
    ) {
      onPressDesiredButton(
        desiredStatus,
        leadInput,
        leadDetailsData,
        remarks,
        hasPopup
      );
      handleClose();
      // addToast(`${titleCaseToReadable(desiredStatus)} Successfully`, {
      //   appearance: 'success',
      //   autoDismiss: true
      // });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (
      btnActionType === ActionType.UNDESIRED &&
      popupAction === ActionType.DESIRED
    ) {
      let leadRef = { ...leadInput };
      if (
        ActionType.DESIRED &&
        undesiredStatus === LeadStatus.NewDealProposed
      ) {
        leadRef = { ...leadInput, proposedDealAmount: dealAmount };
      } else if (
        ActionType.DESIRED &&
        undesiredStatus === LeadStatus.NewDealRequested
      ) {
        leadRef = { ...leadInput, demandAmount: dealAmount };
      } else if (
        (ActionType.DESIRED &&
          (undesiredStatus === LeadStatus.BookingLoanDORejected ||
            undesiredStatus === LeadStatus.BookingLoanFIFailed ||
            undesiredStatus === LeadStatus.BookingFinanceCaseDropped))
      ) {
        leadRef = {
          ...leadInput,
          activeBooking: {
           
            activeLoan: {rejectionReason:rejectionReasonValue as LoanRejectionReason},
            
          }
        };
      }

      onPressUndesireButton(
        undesiredStatus,
        leadRef,
        leadDetailsData,
        remarks,
        hasPopup
      );
      // handleClose();

  
    }
  };
  const formInputs = useReactiveVar(LeadFlowFormsInputs);
  const { data: getDynamicValue, loading: getloading } =
    usePopupDynamicValuesQuery({
      // skip: !regNo,
      variables: {
        regNo:
          desiredStatus === LeadStatus.LeadGenerated
            ? formInputs?.regNo?.toUpperCase()
            : regNo
      },
      fetchPolicy: 'cache-and-network'
    });

  const checkForListingStatus =
    getDynamicValue?.getLead?.listingStatus === ListingStatus?.ListedOnWebsite
      ? true
      : false;
  const RegistrationCheck =
    getDynamicValue?.getLead?.regNo?.toUpperCase() ===
      formInputs?.regNo?.toUpperCase() &&
      desiredStatus === LeadStatus.LeadGenerated
      ? true
      : false;
  function toggleDrawer() {
    setOpen(!open);
  }

  // if (
  //   loading &&
  //   currentStatus !== LeadStatus.VehicleInternalTransferInitiator &&
  //   currentStatus !== LeadStatus.BookingPartPaymentInitiator &&
  //   currentStatus !== LeadStatus.VehicleInStock
  // ) {
  //   return (
  //     <div
  //       className="d-flex flex-column height-100 justify-content-center align-items-center gap-4"
  //       style={{ height: '100vh' }}
  //     >
  //       <div className="spinner-border text-primary" role="status">
  //         <span className="sr-only"></span>
  //       </div>
  //       <h5>Loading..</h5>
  //     </div>
  //   );
  // }

  return (
    <div className="outer">
      <SwipeableDrawer
        ref={ref}
        anchor={'right'}
        open={open}
        onClose={(e) => setAlert(true)}
        onOpen={toggleDrawer}
        sx={{
          width:
            screenSize >= 600
              ? screenSize / 2
              : screenSize <= 600 && screenSize >= 400
                ? screenSize / 1.5
                : screenSize
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            padding: '10px 20px 0 10px'
          }}
        >
          <Button
            color="secondary"
            size="medium"
            style={{
              fontWeight: 600,
              borderRadius: '50%',
              minWidth: 0
            }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/507/507257.png"
              height={20}
              width={20}
            ></img>
          </Button>
          <h5>
            {desiredStatus === LeadStatus?.LeadGenerated
              ? 'Add new Lead'
              : regNo}
          </h5>
        </div>
        <Box
          style={{
            padding: '20px 50px 20px 50px',
            position: 'relative',

          }}
          sx={{
            width:
              screenSize >= 600
                ? screenSize / 2
                : screenSize <= 600 && screenSize >= 400
                  ? screenSize / 1.5
                  : screenSize
          }}
        >
          {loading ? <CircularProgress sx={{
            textAlign: "center", position: "absolute", float: "right", right: "50%"
          }} size={20} /> : renderForm(desiredStatus)}

          <ConfirmationPopup
            open={showPopup}
            checkRegistration={RegistrationCheck}
            type={btnActionType}
            handleClose={handleClose}
            buttonLoading={addingEvent}
            title={
              RegistrationCheck
                ? 'Created Lead Access Denied'
                : desiredStatus === LeadStatus.LeadGenerated &&
                  leadInput?.source === LeadSource.BankAuction
                  ? 'Fresh Lead'
                  : desiredStatus === LeadStatus.LeadGenerated &&
                    leadInput?.source === LeadSource.DealershipSale
                    ? 'Create New Lead'
                    : btnActionType === ActionType.UNDESIRED
                      ? negativePopupTitle
                      : positivePopupTitle
            }
            description={
              RegistrationCheck
                ? `Lead ${leadInput?.regNo} already exists in the system`
                : desiredStatus === LeadStatus.LeadGenerated
                  ? 'Do you want to create new lead'
                  : btnActionType === ActionType.UNDESIRED
                    ? negativePopupDescription
                    : positivePopupDescription
            }
            inputField={
              btnActionType === ActionType.UNDESIRED
                ? showPopupWithInputField
                : false
            }
            // isRejectionWithRemarks={
            //   btnActionType === ActionType.UNDESIRED && isPopupWithRemark
            // }
            onConfirmationHandler={onConfirmationHandler}
            remarks={
              btnActionType === ActionType.UNDESIRED ? showRemarksPopup : false
            }
            setPopupRemarks={popupRemark}
            undesiredStatus={undesiredStatus}
            desiredStatus={desiredStatus}
            regNo={regNo}
            paymentId={requestId}
          />
          <AlertPopup
            handleClose={handleAlert}
            open={alert}
            handleForm={() => {
              setAlert(false);
              toggleDrawer();
            }}
          />
        </Box>
      </SwipeableDrawer>
    </div>
  );
}

export default LeadProcess;
