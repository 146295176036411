import React from 'react';
import { LeadDetailsPropsType } from './ApplicationDetails';
import { titleCaseToReadable } from 'src/utils/utility';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import { format } from 'date-fns';

function VehicleDetails({ currentLead }: LeadDetailsPropsType) {
  const chassisNo = currentLead?.vehicle?.chassisNumber;
  const engineNo = currentLead?.vehicle?.engineNumber;
  // financerName=currentLead?.vehicle?.financingDetails?.financerName
  const fitnessValidUpto = currentLead?.vehicle?.fitnessValidUpto;
  const insuranceType = currentLead?.vehicle?.insuranceDetails?.insuranceType;
  const insurerName = currentLead?.vehicle?.insuranceDetails?.insurerName;
  // isLoanClosed=currentLead?.vehicle?.financingDetails?.isLoanClosed
  const isRCAvailable = currentLead?.vehicle?.isRcAvailable;
  // isVehicleFinanced=currentLead?.vehicle?.isVehicleFinanced
  const isVehicleInsured = currentLead?.vehicle?.isVehicleInsured;
  const isLoanClosed = currentLead?.vehicle?.financingDetails?.isLoanClosed;
  const pendingLoanAmount =
    currentLead?.vehicle?.financingDetails?.pendingLoanAmount;

  const loanToBeClosedBy =
    currentLead?.vehicle?.financingDetails?.tempLoanToBeClosedBy ??
    currentLead?.vehicle?.financingDetails?.loanToBeClosedBy;

  const make = currentLead?.vehicle?.vehicleBrand?.name;
  const model = currentLead?.vehicle?.vehicleModel?.name
  const manufacturingYear = currentLead?.vehicle?.manufacturingDate;
  const financerName = currentLead?.vehicle?.financingDetails?.financerName;
  const isVehicleFinanced = currentLead?.vehicle?.isVehicleFinanced;
  const policyExpiryDate =
    currentLead?.vehicle?.insuranceDetails?.policyExpiryDate;

  const policyNumber = currentLead?.vehicle?.insuranceDetails?.policyNumber;
  const registrationDate = currentLead?.vehicle?.registrationDate;
  const repossessionDate = currentLead?.vehicle?.repossessionDate;

  const vehicleDetails: { key: string; value: string }[] = [
    {
      key: 'Make',
      value: titleCaseToReadable(make) ?? '-'
    },
    {
      key: 'Model',
      value: titleCaseToReadable(model) ?? '-'
    },
    {
      key: 'Manufacturing year',
      value: !!manufacturingYear
        ? new Date(manufacturingYear)?.getFullYear()?.toString()
        : '-'
    },
    {
      key: 'Registration Date',
      // value: !!registrationDate ? registrationDate.slice(0, 10) : '-'
      value: !!registrationDate
        ? format(new Date(registrationDate), 'dd-MM-yyyy')
        : '-'
    },
    {
      key: 'Engine No',
      value: engineNo ?? '-'
    },
    {
      key: 'Chassis No',
      value: chassisNo ?? '-'
    },
    {
      key: 'Repossession Date',
      // value: !!repossessionDate ? repossessionDate?.slice(0, 10) : '-'
      value: !!repossessionDate
        ? format(new Date(repossessionDate), 'dd-MM-yyyy')
        : '-'
    },
    {
      key: 'Is RC Available?',
      value: !!isRCAvailable ? 'Yes' : isRCAvailable === false ? 'No' : '-'
    },
    {
      key: 'Fitness valid upto',
      // value: fitnessValidUpto ? fitnessValidUpto.slice(0, 10) : '-'
      value: !!fitnessValidUpto
        ? format(new Date(fitnessValidUpto), 'dd-MM-yyyy')
        : '-'
    },
    {
      key: ' Is  vehicle financed ?',
      value: !!isVehicleFinanced
        ? 'Yes'
        : isVehicleFinanced === false
          ? 'No'
          : '-'
    },
    {
      key: 'Financer Name',
      value: !!financerName ? titleCaseToReadable(financerName) : '-'
    },
    {
      key: 'Is loan closed ?',
      value: !!isLoanClosed ? 'Yes' : isLoanClosed === false ? 'No' : '-'
    },
    {
      key: 'Pending loan amount',
      value: !!pendingLoanAmount ? `₹ ${pendingLoanAmount}` : '-'
    },
    {
      key: 'Loan To be Closed By',
      value: !!loanToBeClosedBy ? titleCaseToReadable(loanToBeClosedBy) : '-'
    },
    {
      key: 'Is vehicle insured ?',
      value: !!isVehicleInsured
        ? 'Yes'
        : isVehicleInsured === false
          ? 'No'
          : '-'
    },
    {
      key: 'Insurer name',
      value: insurerName ? titleCaseToReadable(insurerName) : '-'
    },
    {
      key: 'Insurance type',
      value: insuranceType ? titleCaseToReadable(insuranceType) : '-'
    },
    {
      key: 'Policy number',
      value: policyNumber ?? '-'
    },
    {
      key: 'Policy expiry date',
      value: !!policyExpiryDate
        ? format(new Date(policyExpiryDate), 'dd-MM-yyyy')
        : '-'
      // value: !!policyExpiryDate
      //   ? new Date(policyExpiryDate).toDateString()
      //   : '-'
    }
  ];
  return <DetailsTabPanel data={vehicleDetails} />;
}

export default VehicleDetails;
