import { format } from 'date-fns';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import {
  LeadStatus,
  LeadStatusEventRef,
  Payment,
  PaymentFor,
  PaymentStatus,
  UserRole
} from 'src/generated/hooks_and_more';
import { getUserData, titleCaseToReadable } from 'src/utils/utility';
import { LeadDetailsPropsType } from './ApplicationDetails';

function DealDetails({ currentLead }: LeadDetailsPropsType) {
  const statusEvents = JSON.parse(
    JSON.stringify(currentLead?.statusEvents ?? [])
  );

  const finalBidAmount = currentLead?.finalBidAmount;
  const initialBidAmount = currentLead?.proposedBidLimitAmount;
  const payments = currentLead?.payments;
  const perDayParkingCharge = currentLead?.yardPerDayParkingCharges;
  const calculatedParkingCharges = currentLead?.estimatedParkingCharges;
  const finalParkingCharges = currentLead?.finalParkingCharges;
  const expenses = currentLead?.expenses;

  function showParkingPaymentDetails(paymentArr: Partial<Payment>[]) {
    const filteredParkingPayments = paymentArr?.filter(
      (payment) => payment?.for === PaymentFor.ParkingExpense
    );
    const approvedParkingDetails = filteredParkingPayments?.find(
      (payment) => payment?.status === PaymentStatus.Approved
    );
    const estimatedParkingDetails = filteredParkingPayments
      ?.sort(
        (a, b) =>
          new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
      )
      ?.find((payment) => payment?.status === PaymentStatus.Estimated);

    if (!!approvedParkingDetails) {
      return approvedParkingDetails;
    } else if (!!estimatedParkingDetails) {
      return estimatedParkingDetails;
    } else {
      return null;
    }
  }
  //use Current Status

  const latestStatuesEvents = statusEvents?.sort(
    (a, b) =>
      new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
  );
  const initialBidDate = statusEvents?.find(
    (event) => event?.status === LeadStatus.BidAmountLimitProposed
  )?.createdAt;

  const dealDate = statusEvents?.find(
    (event) => event?.status === LeadStatus.BidWon
  )?.createdAt;
  const isDealLost = statusEvents?.find(
    (event) => event?.status === LeadStatus.BidLost
  );
  const dealStatus = dealDate ? 'Won' : isDealLost ? 'Loss' : '-';

  const eventStatus: Partial<LeadStatusEventRef> = latestStatuesEvents?.find(
    (event) =>
      event?.status === LeadStatus?.DealRejected ||
      event?.status === LeadStatus?.DealApproved
  );

  const bankConfirmationStatus =
    eventStatus?.status === LeadStatus?.DealApproved
      ? 'Approved'
      : eventStatus?.status === LeadStatus?.DealRejected
      ? 'Not Approved'
      : '-';
  //this function takes two arguments: payment and paymentFor of type 'PaymentFor' and returns a sorted array of payment objects. ( last payment first )
  function getSortedPayments(payments, paymentFor: PaymentFor) {
    //filtering the payments based on the paymentFor
    const filteredPayments = payments?.filter(
      (payment) => payment?.for === paymentFor
    );
    const sortedDealPayments = filteredPayments?.sort(
      (a, b) =>
        new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
    );
    return sortedDealPayments;
  }

  //DEAL PAYMENTS

  //getting all Deal Payments in sorted order - last to first
  const sortedDealPayments = getSortedPayments(
    payments,
    PaymentFor.DealPayment
  );
  const dealPaymentStatus = sortedDealPayments?.[0]?.status;

  // Getting the last payment with status = 'DONE'
  const paymentDoneData = sortedDealPayments?.find(
    (payment) => payment?.status === PaymentStatus.Done
  );

  const dealPaymentDate = paymentDoneData?.createdAt;
  const dealPaymentMode = paymentDoneData?.mode;
  const dealPaymentProof = paymentDoneData?.receiptUrl;

  // Getting the last payment with status = 'REQUESTED'
  const paymentRequestedData = sortedDealPayments?.find(
    (payment) => payment?.status === PaymentStatus.Requested
  );

  const dealBankName = paymentRequestedData?.bankName;
  const dealAccountHolderName = paymentRequestedData?.accountHolderName;
  const dealAccountNumber = paymentRequestedData?.accountNo;
  const dealAccountIfsc = paymentRequestedData?.ifsc;
  const dealAccountProof = paymentRequestedData?.proofUrl;

  // Getting all parking payments in sorted order - last to first
  const sortedParkingPayments = getSortedPayments(
    payments,
    PaymentFor.ParkingExpense
  );

  const parkingPaymentStatus = sortedParkingPayments?.[0]?.status;
  const approvedPerDayParkingCharges =
    currentLead?.approvedParkingChargesFor === UserRole.Driver
      ? currentLead?.yardPerDayParkingChargesByDriver
      : currentLead?.yardPerDayParkingCharges
// console.log(approvedPerDayParkingCharges, "currentLead")
      const approvedFor =
      currentLead?.approvedParkingChargesFor === UserRole.CentreManager
        ? undefined
        : currentLead?.approvedParkingChargesFor
  function isStatusEstimated(payments) {
    return payments?.some(
      (i) =>
        i?.status === PaymentStatus.Estimated &&
        i?.for === PaymentFor.ParkingExpense
    );
  }

  const approvedParkingCharges = finalParkingCharges;
  //FIXME have to check this value
  // const parkingUpiID = parkingPaymentToShow?.upiId
  function getPaymentDetails() {
    const paymentDetails =
      payments?.find(
        (payment) =>
          payment?.status === PaymentStatus.Approved &&
          payment?.for === PaymentFor.ParkingExpense
      ) ??
      payments?.find(
        (payment) =>
          payment?.status === PaymentStatus.Estimated &&
          payment?.for === PaymentFor.ParkingExpense
      );
    return {
      upiId: paymentDetails?.upiId,
      accountHolderName: paymentDetails?.parkingBeneficiary?.accountHolderName,
      accountNumber: paymentDetails?.parkingBeneficiary?.accountNumber,
      ifscCode: paymentDetails?.parkingBeneficiary?.ifscCode,
      proofUrl: !!paymentDetails?.upiId
        ? paymentDetails?.proofUrl
        : paymentDetails?.parkingBeneficiary?.proofUrl,
      bankName: paymentDetails?.parkingBeneficiary?.bankName
    };
  }
  const {
    upiId,
    accountHolderName,
    accountNumber,
    ifscCode,
    proofUrl,
    bankName
  } = getPaymentDetails();

  // Actual parking payment details of doing Payment by Finance
  const parkingPaymentDoneData = sortedParkingPayments?.find(
    (payment) =>
      payment?.status === PaymentStatus.Done &&
      payment?.for === PaymentFor.ParkingExpense
  );
  const parkingPaymentDate = parkingPaymentDoneData?.createdAt;
  const parkingPaymentMode = parkingPaymentDoneData?.mode;
  const parkingPaymentProof = parkingPaymentDoneData?.receiptUrl;
  const userDetails = getUserData();
  //EXPENSES PAYMENT
  const sortedDeliveryExpensePayments = getSortedPayments(
    payments,
    PaymentFor.DeliveryExpense
  );


  const totalParkingCharges =
  approvedFor === UserRole.Driver
    ? approvedParkingCharges ?? '-'
    : calculatedParkingCharges ?? '-'
  const deliveryPaymentStatus = sortedDeliveryExpensePayments?.[0]?.status;
  const deliveryExpenseStatus =
    !!expenses &&
    expenses?.filter((s) => s?.paymentStatus !== PaymentStatus.Rejected)?.[0]
      ?.paymentStatus;
  //TODO: Modularize this function
  // const expensesAmount =
  //   !!expenses &&
  //   expenses
  //     ?.filter((e) => e.paymentStatus !== PaymentStatus?.Rejected)
  //     ?.reduce((acc, item) => {
  //       return acc + item?.spentAmount?;
  //     }, 0);
  const expensesRequestDate = expenses?.[0]?.createdAt;
  const expensePaymentProof = sortedDeliveryExpensePayments?.find(
    (payment) =>
      payment.for === PaymentFor.DeliveryExpense &&
      payment.status === PaymentStatus.Done
  )?.receiptUrl;
  const hasToshow = ![UserRole.LogisticsManager, UserRole.Driver].includes(
    userDetails?.role
  );
  const dealDetails: {
    key: string;
    value: string | number;
    isHiddenForSomeRole?: boolean;
    show?: boolean;
    type?: string;
    isDoc?: boolean;
    isActionSheet?: boolean;
    paytype?: PaymentFor;
    actionSheetVariant?: 'Payment' | 'DeliveryExpense' | 'PurchaseOrder';
  }[] = [
    {
      key: 'Deal Status',
      value: !!dealStatus ? dealStatus : '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Initial Bid Amount',
      value: initialBidAmount ?? '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Initial Bid Date',
      value: !!initialBidDate
        ? format(Date.parse(initialBidDate), 'dd-MMM-yyyy')
        : '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Final Bid Amount',
      value: finalBidAmount ?? '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Deal Date',
      value: !!dealDate ? format(Date.parse(dealDate), 'dd-MMM-yyyy') : '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Bank Confirmation',
      value: bankConfirmationStatus ?? '-', //!!bankConfirmation ? bankConfirmation : '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Deal Payment Status',
      value: !!dealPaymentStatus ? titleCaseToReadable(dealPaymentStatus) : '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Payment Date',
      value: !!dealPaymentDate
        ? format(Date.parse(dealPaymentDate), 'dd MMM yyyy')
        : '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Payment Mode',
      value: !!dealPaymentMode ? titleCaseToReadable(dealPaymentMode) : '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Deal Payment Confirmation',
      value: dealPaymentProof ?? '-',
      isHiddenForSomeRole: true,
      isDoc: true,
      type: 'image',
      show: hasToshow
    },
    {
      key: 'Bank Name',
      value: !!dealBankName ? titleCaseToReadable(dealBankName) : '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Account Holder Name',
      value: dealAccountHolderName ?? '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Account Number',
      value: dealAccountNumber ?? '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Account IFSC code',
      value: dealAccountIfsc ?? '-',
      isHiddenForSomeRole: true,
      show: hasToshow
    },
    {
      key: 'Account Proof',
      value: dealAccountProof ?? '-',
      isDoc: true,
      isHiddenForSomeRole: true,
      type: 'image',
      show: hasToshow
    },
    {
      key: 'Parking Payment Status',
      value: !!parkingPaymentStatus
        ? titleCaseToReadable(parkingPaymentStatus)
        : '-',
      show: true
    },
    {
      key: 'Parking Charges/ day',
      value: approvedPerDayParkingCharges ?? '-',
      show: true
    },
    {
      key: 'Total Parking Charges',
      value: totalParkingCharges,
    },
    {
      key: 'Approved For',
      value: titleCaseToReadable(approvedFor) ?? '-',
      show: true
    },
    {
      key: 'Parking Payment UPI ID',
      value: upiId ?? '-',
      show: true
    },
    {
      key: 'Parking Payment Bank Name',
      value: !!bankName ? titleCaseToReadable(bankName) : '-',
      show: true
    },
    {
      key: 'Parking Payment Account Holder Name',
      value: accountHolderName ?? '-',
      show: true
    },
    {
      key: 'Parking Payment Account Number',
      value: accountNumber ?? '-',
      show: true
    },
    {
      key: 'Parking Payment IFSC Code',
      value: ifscCode ?? '-',
      show: true
    },

    {
      key: 'Account Proof',
      value: proofUrl ?? '-',
      isDoc: true,
      type: 'image',
      show: hasToshow
    },
    {
      key: 'Parking Payment Date',
      value: !!parkingPaymentDate
        ? format(Date.parse(parkingPaymentDate), 'dd MMM yyyy')
        : '-',
      show: true
    },
    {
      key: 'Parking Payment mode',
      value: !!parkingPaymentMode
        ? titleCaseToReadable(parkingPaymentMode)
        : '-',
      show: true
    },
    {
      key: 'Parking Payment Confirmation',
      value: parkingPaymentProof ?? '-',
      isDoc: true,
      type: 'image',
      show: true
    },
    // {
    //   key: 'Delivery Expense Status',
    //   value: deliveryExpenseStatus
    //     ? titleCaseToReadable(deliveryExpenseStatus)
    //     : '-',
    //   show: true
    // },
    // {
    //   key: 'Expense Payment Status',
    //   value: !!deliveryPaymentStatus
    //     ? titleCaseToReadable(deliveryPaymentStatus)
    //     : '-',
    //   show: true
    // },
    // {
    //   key: 'Expenses Amount',
    //   value: !expensesAmount || expensesAmount === 0 ? '-' : expensesAmount
    // },
    // {
    //   key: 'Expense Request Date',
    //   value: !!expensesRequestDate
    //     ? format(Date.parse(expensesRequestDate), 'dd-MMM-yyyy')
    //     : '-',
    //   show: true
    // },
    // {
    //   key: 'Expense Payment Confirmation',
    //   value: expensePaymentProof ?? '-',
    //   isDoc: true,
    //   type: 'image',
    //   show: true
    // }
  ];

  console.log({ dealDetails });

  return <DetailsTabPanel data={dealDetails} />;
}

export default DealDetails;
