import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import Input from 'src/components/Form/Input';
import {
  BookingType,
  LeadRef,
  PaymentType,
  useGetLeadDealDetailsQuery
} from 'src/generated/hooks_and_more';
import {
  LeadFlowFormsInputs,
  enumToItems,
  titleCaseToReadable
} from 'src/utils/utility';
import { LeadCreateAndUpdateProps } from 'src/types';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import CtaButton from '../CTA_Button/CtaButton';
import { useReactiveVar } from '@apollo/client';
import { Charges } from 'src/constants/constant';

const AddPaymentStructureRefurbishment = (props: LeadCreateAndUpdateProps) => {
  const {
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton
  } = props;

  const bookingType = enumToItems(BookingType);

  const { data, loading } = useGetLeadDealDetailsQuery({
    variables: { id: leadId },
    onCompleted() {
      const saleAmountfilled =
        data?.getLead?.activeBooking?.bookingPayment?.saleAmount;
      const BookingTypefilled = {
        label: titleCaseToReadable(
          data?.getLead?.activeBooking?.bookingPayment?.bookingType
        ),
        value: data?.getLead?.activeBooking?.bookingPayment?.bookingType
      };
      const loanAmountfilled =
        data?.getLead?.activeBooking?.bookingPayment?.appliedLoanAmount;
      formik.setValues({
        ...formik.values,
        bookingType: BookingTypefilled,
        saleAmount: saleAmountfilled,
        loanAmount: loanAmountfilled
      });
    }
  });

  const RcAmount = data?.getLead?.activeBooking?.isRCTransferReq
    ? Charges?.RTO_CHARGES
    : 0;
  const InsuranceAmount = data?.getLead?.activeBooking?.isInsuranceReq
    ? Charges?.INSURANCE_CHARGES
    : 0;
  const formInputs = useReactiveVar(LeadFlowFormsInputs);

  const formik = useFormik({
    initialValues: {
      bookingType: null,
      saleAmount: 0,
      loanAmount: 0
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      saleAmount: Yup.number()
        .typeError('*Must be a Number')
        .required('*Required'),
      bookingType: Yup.object().required('*Required'),
      loanAmount: Yup.number()
        .required('*Required')
        .typeError('*Must be a Number')
        .when('bookingType', {
          is: 'FINANCE',
          otherwise: (schema) => schema.notRequired()
        })
    }),

    onSubmit: (values) => {
      let leadInput: LeadRef;
      LeadFlowFormsInputs({
        ...formInputs,
        saleAmount: values?.saleAmount
      })
      leadInput = {
        regNo: regNo,
        id: leadId,
        activeBooking: {
          bookingPayment: {
            bookingType: values.bookingType.value as BookingType,
            appliedLoanAmount: Boolean(values?.loanAmount)
              ? Number(values?.loanAmount)
              : 0,
            saleAmount: Boolean(values.saleAmount)
              ? Number(values.saleAmount)
              : 0
          }
        }
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });

  useEffect(() => {
    if (formik?.values?.bookingType?.value === BookingType.Cash) {
      formik.setFieldValue('loanAmount', 0);
    }
  }, [formik?.values?.bookingType?.value]);

  const saleAmount = Number(formik?.values?.saleAmount) ?? 0;
  const paymentMode = formik?.values?.bookingType ?? null;
  const requestedLoanAmount =
    data?.getLead?.activeBooking?.bookingPayment?.appliedLoanAmount;

  const totalSaleAmount =
    saleAmount +
    (!!data?.getLead?.activeBooking?.isInsuranceReq
      ? Charges?.INSURANCE_CHARGES
      : 0) +
    (!!data?.getLead?.activeBooking?.isRCTransferReq
      ? Charges?.RTO_CHARGES
      : 0
    )?.toString();

  let dealStructure: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Sale Amount',
      value: formik?.values?.saleAmount ?? 0
    },
    {
      key: 'RC Transfer',
      value: data?.getLead?.activeBooking?.isRCTransferReq
        ? Charges?.RTO_CHARGES?.toString()
        : '0'
    },
    {
      key: 'Insurance',
      value: data?.getLead?.activeBooking?.isInsuranceReq
        ? Charges?.INSURANCE_CHARGES?.toString()
        : '0'
    },
    {
      key: 'Total Sale Amount',
      value: !!saleAmount
        ? (saleAmount + RcAmount + InsuranceAmount)?.toString()
        : '-'
    }
  ];

  const isFinance = data?.getLead?.vehicle?.isVehicleFinanced;

  dealStructure = dealStructure?.filter((i) => i.value !== '0');

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={12}>
          <Input
            value={
              formik.values.saleAmount
                }
            name="saleAmount"
            onChange={(e) => {
             
              formik?.setFieldValue('saleAmount',  Number(e.target.value));
            }}
            placeholder="Enter Sale Amount*"
          />
          {formik.errors.saleAmount && formik.touched.saleAmount ? (
            <div style={{ color: 'red' }}>{formik.errors?.saleAmount}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12} mt={2}>
          <InputAutoComplete
            value={formik.values.bookingType}
            name="bookingType"
            options={bookingType}
            onChange={(value) => {
              formik?.setFieldValue('bookingType', value);
            }}
            placeholder="Booking Type*"
            optionString="label"
            getOptionLabel={(option) => option.label}
            disabled={
              Boolean(
                data?.getLead?.activeBooking?.activeLoan)}
          />
          {formik.errors.bookingType && formik.touched.bookingType ? (
            <div style={{ color: 'red' }}>{formik.errors?.bookingType}</div>
          ) : null}
        </Grid>

        {formik.values.bookingType?.value === BookingType.Finance && (
          <Grid item xs={12} md={12}>
            <Input
              value={
                Boolean(formik.values.loanAmount)
                  ? formik.values.loanAmount
                  : null
              }
              name="loanAmount"
              onChange={(e) => {
                formik?.setFieldValue(
                  'loanAmount',
                  formik.values?.bookingType?.value === PaymentType.PayInFull
                    ? 0
                    : Number(e.target.value)
                    
                );
              }}
              placeholder="Enter Loan Amount*"
              disabled={
                Boolean(
                  data?.getLead?.activeBooking?.activeLoan)
              }
            />
            {formik?.values?.loanAmount &&
            (formik.errors.loanAmount || formik.touched.loanAmount) ? (
              <div style={{ color: 'red' }}>{formik.errors?.loanAmount}</div>
            ) : null}
          </Grid>
        )}

        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <h4>Sale Structure </h4>
        </Grid>

        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <TableContainer sx={{ backgroundColor: '#f6f6f6' }} component={Paper}>
            <Table
              sx={{
                padding: 0,
                boxShadow:
                  ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              }}
            >
              <TableBody sx={{ borderRadius: '1rem ' }}>
                <TableRow>
                  {/* <TableCell colSpan={2}>
                    {' '}
                    <h5 style={{ marginTop: '0.5rem' }}>Deal Structure</h5>
                  </TableCell> */}
                </TableRow>
                {dealStructure.map((i, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      fontSize: '16px'
                    }}
                  >
                    <TableCell width="50%">{i?.key}</TableCell>
                    <TableCell width="50%">{i?.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
     
      <Grid item xs={12} md={12} mt={2}>
        <CtaButton
          desireButtonText={desireButtonText}
          disabled={
            !(formik.isValid && formik.dirty) ||
            ((formik?.values?.loanAmount === undefined ||
              formik?.values?.loanAmount === 0) &&
              formik?.values?.bookingType?.value === BookingType?.Finance) ||
            (Boolean(formik.values.bookingType?.value === undefined) ||
            Boolean(
              formik.values.bookingType?.value === null &&
                formik?.values?.loanAmount === 0
            )
              ? true
              : false)
          }
          backBtn={true}
        />
      </Grid>
    </form>
  );
};

export default AddPaymentStructureRefurbishment;
