import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  AggregatesCountDocumentDocument,
  AggregatesCountDocumentQueryVariables,
  BankName,
  GetFilteredLeadsQueryVariables,
  LeadSource,
  LeadStatus,
  VehicleMake,
  useAggregatesCountDocumentLazyQuery,
  useAggregatesCountDocumentQuery
} from '../../generated/hooks_and_more';
import LeadListRow from '../../components/Composite/LeadListRow';
import {
  LeadListTableHeader,
  PendingLeadListTableHeader
} from '../../constants/constant';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import {
  Box,
  Button,
  Grid,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import TableWrapper from 'src/components/Tables';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { searchString } from 'src/constants/reactiveVar';
import {
  cachedDocumentFilter,
  cachedLeadFilter,
  getDocStatusDate,
  getYearFromDate,
  titleCaseToReadable
} from 'src/utils/utility';

import DocumentTable from './DocumentTable';
import DocumentLeadsFilter from './documentFilter';
import DocumentListRow from 'src/components/Composite/DocumentListRow';
import { ExportCSV } from '../leads/leadExport';
import { format } from 'date-fns';

function DocumentLists() {
  const filterInput = useReactiveVar(cachedDocumentFilter);
  const [searchStringValue, setSearchString] = useState('');
  const [filteredInput, setFilteredInput] = useState(null);
  const [varr, setVarr] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataExport, setExportData] = useState(null);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [docLeads, setDocLeads] = useState([]);
  const handleClose = () => setShowModal(false);
  const [GetDocument, { data: documentData, loading }] =
    useAggregatesCountDocumentLazyQuery();
  const [DocExport, { data: documentExport, loading: ExportLoading }] =
    useAggregatesCountDocumentLazyQuery({
      onCompleted(data) {
        const exportData =
          data?.documentsAggregatesData?.leads?.length &&
          data?.documentsAggregatesData?.leads
            ?.map((item, index) => {
              const source = titleCaseToReadable(item?.source ?? '-');
              const make = titleCaseToReadable(
                item?.vehicle?.vehicleBrand?.name ?? '-'
              );
              const year = item?.vehicle?.manufacturingDate
                ? getYearFromDate(item?.vehicle?.manufacturingDate ?? '-')
                : '-';
              const recentStatusDate = getDocStatusDate(
                item?.documentsFollowups
              );
              const statusCount = getStatus(item);
              return {
                Registration_No: item?.regNo,
                Document_status:
                  statusCount?.pending <= 0 ? 'completed' : 'pending',
                Required: statusCount?.required,
                Received: statusCount?.received,
                Pending: statusCount?.pending,
                Purchase_type: source,
                Make: make,
                Model: item?.vehicle?.vehicleModel?.name,
                Manufacturing_year: year,
                Created_Date: !!item?.createdAt
                  ? format(Date.parse(item?.createdAt), 'dd-MM-yyyy')
                  : '-',
                Updated_Date: !!item?.updatedAt
                  ? format(Date.parse(item?.updatedAt), 'dd-MM-yyyy')
                  : '-'
              };
            })
            .reverse();
        setExportData(exportData);
      }
    });
  useEffect(() => {
    GetDocument({
      variables: {
        start: '',
        end: '',
        offset: pageNumber,
        pageSize: 20
      },
      onCompleted(data) {
        setDocLeads([...docLeads, ...data?.documentsAggregatesData?.leads]);
      }
    });
  }, []);

  function onFilterSubmit(variables: AggregatesCountDocumentQueryVariables) {
    GetDocument({
      variables: variables,
      fetchPolicy: 'cache-and-network',
      onCompleted(data) {
        setShowModal(false);
        cachedDocumentFilter(variables);
      },
      onError() {}
    });
  }

  useEffect(() => {
    if (filterInput) {
      if (Boolean(filterInput?.offset) || filterInput?.search) {
        GetDocument({
          variables: { ...filterInput },
          onCompleted(data) {
            setDocLeads([...data?.documentsAggregatesData?.leads]);
          }
        });
      } else {
        GetDocument({
          variables: { ...filterInput, offset: pageNumber, pageSize: 20 },
          onCompleted(data) {
            setDocLeads([...docLeads, ...data?.documentsAggregatesData?.leads]);
          }
        });
      }
      setFilteredInput(filterInput);
    } else {
      GetDocument({
        variables: {
          start: '',
          end: '',
          docStatus: null,
          source: null,
          offset: pageNumber,
          pageSize: 20
        },
        onCompleted(data) {
          setDocLeads([...docLeads, ...data?.documentsAggregatesData?.leads]);
        }
      });
    }
  }, [filterInput, pageNumber]);

  const getStatus = (lead) => {
    let required = 0;
    let received = 0;

    const documents = lead?.documents;
    const documentChecklist = lead?.documentChecklist;
    const sourceCheck = lead?.source;

    if (documentChecklist) {
      Object.keys(documentChecklist).map((i) => {
        if (documentChecklist?.[i] === true) {
          required += 1;
          received = documents?.[i] ? received + 1 : received;
        }
      });
    } else {
      if (lead?.source === 'BANK_AUCTION' && documentChecklist === null) {
        if (documents !== null) {
          Object.values(documents).map((i) => {
            if (i !== null && i !== 'LeadDocuments' && i !== '') {
              received = received + 1;
            }
          });
        }
        required += 15;
      } else if (
        lead?.source === 'DEALERSHIP_SALE' &&
        documentChecklist === null
      ) {
        if (documents !== null) {
          Object.values(documents).map((i) => {
            if (i !== null && i !== 'LeadDocuments' && i !== '') {
              received = received + 1;
            }
          });
        }
        required += 15;
      }
    }

    return {
      required: required,
      received: received,
      pending: required - received === -1 ? 0 : required - received
    };
  };
  const exportQuery = () => {
    const variable = { offset: null, start: '', end: '', ...filterInput };
    DocExport({ variables: variable });
    return dataExport;
  };
  const resetExport = () => {
    setExportData([]);
  };
  return (
    <Fragment>
      <PageTitleWrapper>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Typography variant="h3" component="h3" gutterBottom>
              Leads
            </Typography>

            <DocumentTable
              counts={documentData?.documentsAggregatesData?.documentsCount}
            />
          </Grid>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <DocumentLeadsFilter
              showModal={showModal}
              setShowModal={setShowModal}
              handleClose={handleClose}
              onFilterSubmit={onFilterSubmit}
              filterLoading={loading}
              filterInput={filteredInput}
            />
            <ExportCSV
              callQuery={exportQuery}
              csvData={dataExport}
              fileName="Lead Documents"
              loading={ExportLoading}
              reset={resetExport}
            />
          </Box>
        </Grid>
      </PageTitleWrapper>
      <Box sx={{ px: 2, pb: 2 }}>
        {loading && (pageNumber === 1 || filterInput?.offset === 1) ? (
          <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : (
          <>
            <TableWrapper header={PendingLeadListTableHeader}>
              {docLeads?.length ? (
                docLeads?.map((item, index) => {
                  return (
                    <DocumentListRow
                      key={item.regNo}
                      leadData={item}
                      DocumentCheckList={item}
                      getStatus={getStatus}
                      index={index}
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '20px',
                        fontWeight: '900'
                      }}
                    >
                      <Typography>No data found</Typography>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableWrapper>
            {loading && docLeads?.length ? (
              <div
                style={{ padding: '30px' }}
                className="d-flex flex-column  justify-content-center align-items-center gap-2"
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
                <h5>Loading..</h5>
              </div>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '30px'
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    display:
                      documentData?.documentsAggregatesData?.leads?.length >= 20
                        ? 'block'
                        : 'none'
                  }}
                  onClick={() => {
                    if (filterInput?.offset === 1) {
                      setPageNumber(2);
                      cachedDocumentFilter({ ...filterInput, offset: null });
                    } else {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  Load More
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Fragment>
  );
}

export default DocumentLists;
