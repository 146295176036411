import React from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root>h2': {
    padding: 0
  }
}));
function BootstrapDialogTitle(props: any) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 5,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
function AlertPopup({ handleClose, open, handleForm }) {
  return (
    <div>
      <BootstrapDialog
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ p: 0 }}
      >
        <Box sx={{ width: '400px', padding: '10px' }}>
          <DialogTitle
            style={{
              cursor: 'move',
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 600
            }}
            id="draggable-dialog-title"
          >
            Do you want to close?
          </DialogTitle>
          <DialogContent sx={{ textAlign: 'center' }}>
            <Typography gutterBottom>Your changes are not saved</Typography>
          </DialogContent>

          <DialogActions
            sx={{
              marginBottom: '0.3rem',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              style={{ backgroundColor: 'red', width: '100px' }}
              variant="contained"
              id="notconfirm"
              onClick={() => handleClose()}
              size="medium"
            >
              No
            </Button>
            <Button
              // // disabled={!remarks && desiredStatus ? false : (popupRemarks === null || popupRemarks === '')  ?  true : false}
              // disabled={
              //     ((inputField && Boolean(popupRemarks)) &&
              //       !Boolean(demandAmount) && !Boolean(popupRemarks))  ?
              //          true : (demandAmount !== 0  && !Boolean(popupRemarks)) ?
              //           true : Boolean(remarks) ?
              //            true :  false

              // }

              variant="contained"
              onClick={() => handleForm()}
              sx={{ width: '100px' }}
            >
              Yes
            </Button>
          </DialogActions>
        </Box>
      </BootstrapDialog>
      {/* {toastMsg ? <ToastMessage open="true" message="Center Added Successfully" /> : <></>} */}
    </div>
  );
}

export default AlertPopup;
