import React, { useEffect } from 'react';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import addDays from 'date-fns/addDays';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import moment from 'moment';
import 'rsuite/dist/rsuite.min.css';
import { getDate } from 'src/utils/utility';

type CalenderProps = {
  placeholder?: string;
  onDateChange: (e: DateRange, placehldr: string) => void;
  filteredDate?: DateRange | null;
  cleanable?: boolean;
  disabled?: boolean;
  name?: string;
};
function Calender({
  placeholder = 'Filters',
  onDateChange,
  filteredDate,
  cleanable = true,
  disabled,
  name
}: CalenderProps) {
  const [oneCalender, setOnecalender] = React.useState(false);
  const date = new Date();
  const todayFormatedDate = moment(date).format('MM-D-YYYY');
  const totalDays = getDaysInMonth(date);
  const currMonth = getMonth(date) + 1;
  const currYear = getYear(date);
  const prevMonthDays = getDaysInMonth(
    new Date(`${currYear},${currMonth - 1},01`)
  );
  useEffect(() => {
    if (window.innerWidth < 500) {
      setOnecalender(true);
    }
  }, []);
  return (
    <DateRangePicker
      placeholder={placeholder}
      disabled={disabled}
      character=" | "
      className="calender-input-container"
      placement="bottomEnd"
      format="yyyy-MM-dd HH:mm:ss"
      cleanable={cleanable}
      name={name}
      showMeridian
      defaultCalendarValue={[addDays(getDate(), -29), new Date()]}
      preventOverflow={true}
      onOk={(value) => {
        onDateChange(value, placeholder);
      }}
      onChange={(value: any) => {
        onDateChange(value, placeholder);
      }}
      value={filteredDate}
      style={{
        width: '100%',
        border: '1px solid #0092ff',
        borderRadius: '6.8px',
        marginBottom: '5px'
      }}
      showOneCalendar={oneCalender}
      ranges={[
        {
          label: 'Today',
          value: [new Date(`${todayFormatedDate},00:01:00`), new Date()]
        },
        {
          label: 'Yesterday',
          value: [addDays(getDate(), -1), addDays(getDate(), 0)]
        },
        {
          label: 'Last 7 Days',
          value: [addDays(getDate(), -7), addDays(getDate(), 0)]
        },
        {
          label: 'Last 30 days',
          value: [addDays(getDate(), -29), addDays(getDate(), 0)]
        },
        {
          label: 'This month',
          value: [
            addDays(new Date(`${currYear},${currMonth},01`), 0),
            addDays(new Date(`${currYear},${currMonth},01`), totalDays - 1)
          ]
        },
        {
          label: 'Last month',
          value: [
            addDays(new Date(`${currYear},${currMonth - 1},01`), 0),
            addDays(
              new Date(`${currYear},${currMonth - 1},01`),
              prevMonthDays - 1
            )
          ]
        }
      ]}
    />
  );
}

export default Calender;
