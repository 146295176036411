import { CircularProgress } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AutoCompleteValueType } from 'src/types';

// for dropdown

type InputAutoCompletePropsType = {
  onChange?: (value: AutoCompleteValueType) => void;
  value: AutoCompleteValueType | any | string;
  options: AutoCompleteValueType[] | any | [];
  optionString?: string;
  placeholder: string;
  style?: any;
  disabled?: boolean;
  disableClearable?: boolean;
  name?: string;
  getOptionLabel?: AutoCompleteValueType[] | any | [];
};

function InputAutoComplete({
  disabled,

  onChange,
  value,
  options,
  disableClearable = false,
  optionString = 'label',
  placeholder,
  getOptionLabel,
  name,
  ...rest
}: InputAutoCompletePropsType) {
  return (
    <Autocomplete
      onChange={(event, value) => onChange(value)}
      id="size-small-standard-multi"
      size="small"
      value={value}
      disabled={disabled}
      disableClearable={disableClearable}
      options={options}
      getOptionLabel={(option) => option?.[optionString]}
      renderInput={(params) => <TextField {...params} label={placeholder} />}
      style={{
        background: '#ffff'
      }}
      {...rest}
    />
  );
}

export default InputAutoComplete;
