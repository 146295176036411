import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useGetTimeLazyQuery } from 'src/generated/hooks_and_more';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Button } from '@mui/material';
import { enquiriesTimelineDetails } from 'src/types';
import CallIcon from '@mui/icons-material/Call';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import format from 'date-fns/format';
import styles from './style.json';

type EnquireProps = {
  enquire?: enquiriesTimelineDetails;
  index?: number;
  isLast?: boolean;
};

export function TimeLineShow({ enquire, index, isLast }: EnquireProps) {
  return (
    <>
      {/* <Timeline> */}
      {isLast ? (
        <Timeline>
          <TimelineItem sx={{ marginTop: 3 }}>



            {enquire?.isClosed ? (
              <>
                <TimelineSeparator>
                  <TimelineDot style={styles.line} />
                  <TimelineConnector style={styles.line} />
                </TimelineSeparator>
                <TimelineContent>
                  <h6> Enquiry Closed</h6>
                  <p> Close Reason: {enquire?.closedReason}</p>
                  <p
                    style={styles.paraRemark
                    }
                  >
                    {enquire?.closedRemark}
                  </p>

                </TimelineContent>
              </>
            ) : enquire?.isClosed === null ? null :
              <>
                {' '}
                <TimelineSeparator>
                  <TimelineDot style={styles.line} />
                  <TimelineConnector style={styles.line} />
                </TimelineSeparator>
                <TimelineContent>
                  <h6> Enquiry Closure Pending</h6>
                  {enquire?.nextFollowupDate !== null ? <p>
                    {new Date(enquire?.nextFollowupDate).toLocaleDateString(
                      'en-GB',
                      { day: 'numeric', month: 'short', year: 'numeric' }
                    )}
                  </p> : null}
                </TimelineContent>
              </>
            }
          </TimelineItem>
        </Timeline>
      ) : null}
      {(enquire?.followUpStatus === 'WARM' && enquire?.nextFollowupDate !== null) ? (

        <Timeline>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={styles.line} />
              <TimelineConnector style={styles.line} />
            </TimelineSeparator>

            <TimelineContent>
              <div style={styles.divFlex}>
                {index == 0 ? (
                  <div style={styles.divFlex}>
                    <div>
                      <h6>Enquiry Created</h6>
                    </div>
                    <div>
                      {
                        enquire.followUpStatus === 'WARM' ? <Button style={styles.buttonWarm} variant="contained">
                          {' '}
                          WARM
                        </Button> : null
                      }

                    </div>
                    <div>
                      <CallIcon fontSize="large" color="success" />
                    </div>
                  </div>
                ) : (
                  <div style={styles.divFlex}>
                    <h6> Followup Updated</h6>
                    <div>
                      <Button style={styles.buttonWarm} variant="contained">
                        {' '}
                        WARM
                      </Button>
                    </div>
                    <div>
                      {enquire.isWalkin === true ? (
                        <DirectionsWalkIcon fontSize="large" color="primary" />
                      ) : (
                        <CallIcon fontSize="large" color="success" />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <p>
                {' '}
                {new Date(enquire?.followUpDate).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                })}
              </p>
              <p>
                {' '}
                Next Follow up on:{' '}
                {new Date(enquire?.nextFollowupDate).toLocaleDateString(
                  'en-GB',
                  { day: 'numeric', month: 'short', year: 'numeric' }
                )}
              </p>

              <p>by: {enquire?.followUpBy}</p>

              <p
                style={
                  enquire?.followUpRemarks === null ? {} : styles.paraRemark
                }
              >
                {enquire?.followUpRemarks}
              </p>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ) : (enquire.followUpStatus === 'COLD' && enquire?.nextFollowupDate !== null) ? (
        <Timeline>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={styles.line} />
              <TimelineConnector style={styles.line} />
            </TimelineSeparator>

            <TimelineContent>
              <div style={styles.divFlex}>
                {index == 0 ? (
                  <div style={styles.divFlex}>
                    <div>
                      <h6>Enquiry Created</h6>
                    </div>
                    <div>
                      <Button style={styles.buttonCold} variant="contained">
                        {' '}
                        COLD
                      </Button>
                    </div>
                    <div>
                      <CallIcon fontSize="large" color="success" />
                    </div>
                  </div>
                ) : (
                  <div style={styles.divFlex}>
                    <h6> Followup Updated</h6>
                    <div>
                      <Button style={styles.buttonCold} variant="contained">
                        {' '}
                        COLD
                      </Button>
                    </div>
                    <div>
                      {enquire.isWalkin === true ? (
                        <DirectionsWalkIcon fontSize="large" color="primary" />
                      ) : (
                        <CallIcon fontSize="large" color="success" />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <p>
                {' '}
                {new Date(enquire?.followUpDate).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                })}
              </p>
              <p>
                {' '}
                Next Follow up on:{' '}
                {new Date(enquire?.nextFollowupDate).toLocaleDateString(
                  'en-GB',
                  { day: 'numeric', month: 'short', year: 'numeric' }
                )}
              </p>

              <p>by: {enquire?.followUpBy}</p>
              <p
                style={
                  enquire?.followUpRemarks === null ? {} : styles.paraRemark
                }
              >
                {enquire?.followUpRemarks}
              </p>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ) : (enquire.followUpStatus === 'HOT' && enquire?.nextFollowupDate !== null) ? (
        <Timeline>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={styles.line} />
              <TimelineConnector style={styles.line} />
            </TimelineSeparator>

            <TimelineContent>
              <div style={styles.divFlex}>
                {index == 0 ? (
                  <div style={styles.divFlex}>
                    <h6>Enquiry Created</h6>
                    <div>
                      <Button style={styles.buttonHot} variant="contained">
                        {' '}
                        HOT
                      </Button>
                    </div>
                    <div>
                      <CallIcon fontSize="large" color="success" />
                    </div>
                  </div>
                ) : (
                  <div style={styles.divFlex}>
                    <h6> Followup Updated</h6>
                    <div>
                      <Button style={styles.buttonHot} variant="contained">
                        {' '}
                        HOT
                      </Button>
                    </div>
                    <div>
                      {enquire.isWalkin === true ? (
                        <DirectionsWalkIcon fontSize="large" color="primary" />
                      ) : (
                        <CallIcon fontSize="large" color="success" />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <p>
                {' '}
                {new Date(enquire?.followUpDate).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                })}
              </p>
              {enquire?.nextFollowupDate !== null ? <p>
                {' '}
                Next Follow up on:{' '}
                {new Date(enquire?.nextFollowupDate).toLocaleDateString(
                  'en-GB',
                  { day: 'numeric', month: 'short', year: 'numeric' }
                )}
              </p> : null}
              <p>by: {enquire?.followUpBy}</p>
              <p
                style={
                  enquire?.followUpRemarks === null ? {} : styles.paraRemark
                }
              >
                {enquire?.followUpRemarks}
              </p>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ) : !!enquire?.followUpStatus ? (
        <Timeline>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={styles.line} />
              <TimelineConnector style={styles.line} />
            </TimelineSeparator>

            <TimelineContent>
              <div style={styles.divFlex}>
                {index == 0 ? (
                  <div style={styles.divFlexStatusNull}>
                    <h6>Enquiry Created</h6>
                    <div>
                      <CallIcon fontSize="large" color="success" />
                    </div>
                  </div>
                ) : (
                  <div style={styles.divFlexStatusNull}>
                    <h6> Followup Updated</h6>
                    <div style={styles.statusDiv}>
                      {enquire.isWalkin === true ? (
                        <DirectionsWalkIcon fontSize="large" color="primary" />
                      ) : (
                        <CallIcon fontSize="large" color="success" />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <p>
                {' '}
                {enquire?.followUpDate && new Date(enquire?.followUpDate).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                })}
              </p>
              <div style={{ display: "flex" }}>
                <p> Next Follow up on:-</p>
                {enquire?.nextFollowupDate.length > 5 && <span>{new Date(enquire?.nextFollowupDate).toLocaleDateString(
                  'en-GB',
                  { day: 'numeric', month: 'short', year: 'numeric' })}</span>}


              </div>

              <p>by: {enquire?.followUpBy}</p>
              <p
                style={
                  enquire?.followUpRemarks === null ? {} : styles.paraRemark
                }
              >
                {enquire?.followUpRemarks}
              </p>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ) : null}
    </>
  );
}

function EnquiryTimeLine() {
  const location = useLocation();
  const state: any = location.state;
  const [getTimeLine, { data, loading }] = useGetTimeLazyQuery({
    fetchPolicy: 'no-cache'
  });
  useEffect(() => {
    if (state) {
      const id = state?.id;
      if (id) {
        getTimeLine({
          variables: {
            id
          }
        });
      }
    }
  }, [getTimeLine, state]);
  return (
    <>
      <div>
        <h4> Followup Activity</h4>
      </div>
      <div>
        {data?.queryEnquiry
          ?.map((item, enquiryIndex) => {
            return item?.followups?.map((followup, followIndex) => {
              const enquiry = {
                id: item?.id ?? '-',
                followUpStatus: followup?.status ?? '-',
                followUpDate: followup?.followupDate ?? '-',
                followUpBy: followup?.followedUpBy?.name ?? '-',
                enquiryType: item?.enquiryType,
                followUpRemarks: followup?.remarks ?? null,
                nextFollowupStatus: followup?.nextFollowup?.status ?? '-',
                nextFollowupremarks: followup?.nextFollowup?.remarks ?? '-',
                nextFollowupDate: followup?.nextFollowup?.followupDate ?? '-',
                isWalkin: followup?.isWalkin,
                isClosed: item?.isClosed,
                closedRemark: item?.enquiryClosure?.remarks ?? '-',
                closedReason: item?.enquiryClosure?.enquiryCloseReason ?? '-',
              };
              return (
                <>
                  <TimeLineShow
                    key={followup.id}
                    enquire={enquiry}
                    index={followIndex}
                    isLast={followIndex == item.followups.length - 1}
                  />
                </>
              );
            });
          })
          .flat()
          .reverse()}
      </div>
    </>
  );
}

export default EnquiryTimeLine;

//  && enquire?.enquiryType === "FIELD" || enquire.isWalkin === true && enquire?.enquiryType === "WALKIN"
