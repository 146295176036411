import { getYearFromDate, titleCaseToReadable } from '../../utils/utility';
import { TableCell, TableRow, Typography } from '@mui/material';
import { LeadPreviewFragment, LeadRef } from '../../generated/hooks_and_more';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';

type LeadListProps = {
  leadData: Partial<LeadPreviewFragment> | any;
  index?: number;
  DocumentCheckList?: LeadRef;
  getStatus?: any;
};
function DocumentListRow({
  leadData,
  index,
  DocumentCheckList,
  getStatus
}: LeadListProps) {
  const nav = useNavigate();

  const source = titleCaseToReadable(leadData?.source ?? '-');
  const make = leadData?.vehicle?.vehicleBrand?.name;
  const year = leadData?.vehicle?.manufacturingDate
    ? getYearFromDate(leadData?.vehicle?.manufacturingDate ?? '-')
    : '-';
  const createdAt = leadData?.createdAt;
  const lastEvent =
    leadData?.statusEvents &&
    leadData?.statusEvents?.length &&
    leadData?.statusEvents;
  const length = lastEvent?.length;
  const lastEventUpdatedDate =
    lastEvent && lastEvent.length && lastEvent[length - 1]?.createdAt;
  let followUpDates = [];
  leadData?.documentsFollowups?.map((i) => {
    if (i?.nextFollowupDate !== null) {
      followUpDates?.push(new Date(i?.nextFollowupDate));
    }
  });

  const pending = getStatus(DocumentCheckList).pending;
  const received = getStatus(DocumentCheckList).received;
  const required = getStatus(DocumentCheckList).required;
  return (
    <TableRow
      hover
      onClick={() => {
        nav(`/documents/${leadData.regNo}`);
      }}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell title={leadData?.regNo ?? ''} sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {leadData?.regNo ?? '-'}
        </Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {pending <= 0 ? 'completed' : 'pending'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {required}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {received}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {pending}
        </Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {source}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '100px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {make}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '140px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {leadData?.vehicle?.vehicleModel?.name ?? ''}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '100px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {year ?? '-'}
        </Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: '120px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {!!createdAt ? format(Date.parse(createdAt), 'dd-MM-yyyy') : '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '120px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {!!lastEventUpdatedDate
            ? format(Date.parse(lastEventUpdatedDate), 'dd-MM-yyyy')
            : '-'}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default DocumentListRow;
