import React from 'react';
import {
  BankName,
  LeadRef,
  LeadStatus,
  PaymentFor,
  PaymentMethod,
  PaymentStatus,
  useFinalCostQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import { Box, Button, Grid } from '@mui/material';
import Input from 'src/components/Form/Input';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import { useFormik } from 'formik';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { enumToItems } from 'src/utils/utility';
import { ActionType } from 'src/constants/constant';
import CtaButton from '../CTA_Button/CtaButton';
import { loginfileValidationSchema } from '../../ValidationSchema/ValidationSchema';

export type Item = {
  label: string
  value: string
  id?: number | string
  model_id?: string
}
function LoginFile(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    onPressOfDesiredOrUndesiredButton
  } = props;

  const BankNameOptions = enumToItems(BankName) as Item[];
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      bankName: null,
      loginDate: '',
      applicationform: '',
      remarks: ''
    },
    validationSchema: loginfileValidationSchema,

    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        activeBooking: {
          activeLoan: {
            bankName: values?.bankName?.value as BankName,
            loginDate: values?.loginDate,
            loginApplicationFormUrl: values?.applicationform,

          },
        },
      };
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              onChange={(value) => {
                formik.setFieldValue('bankName', value);
              }}
              value={formik.values.bankName}
              placeholder="Bank Name*"
              options={BankNameOptions}
              optionString="label"
              getOptionLabel={(option) => option.name}
            />
            {formik.errors.bankName && formik.touched.bankName ? (
              <div style={{ color: 'red' }}>{formik.errors?.bankName}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue('loginDate', value);
              }}
              placeholder="Enter the Login Date*"
              value={
                formik.values.loginDate
                  ? new Date(formik.values.loginDate)
                  : null
              }
            />
            {formik.errors.loginDate && formik.touched.loginDate ? (
              <div style={{ color: 'red' }}>{formik.errors?.loginDate}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
<h6 style={{ marginLeft: '0.5rem'}}>Application Order</h6>

</Grid>

          <Grid item xs={12} md={12}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="applicationform"
              value={formik.values.applicationform}
              onChange={(value) => {
                formik.setFieldValue('applicationform', value);
              }}
              placeholder="upload Document*"
            />
            {formik.errors.applicationform && formik.touched.applicationform ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.applicationform}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.remarks}
              name="remarks"
              onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
              placeholder="Enter the Remarks"
            />
          </Grid>
        </Grid>
        <CtaButton
          desireButtonText={desireButtonText}
          undesireButtonText={undesireButtonText}
          backBtn={canGoBack}
          onlyRed={true}
          reject={reject}
          disabled={!(formik.isValid && formik.dirty)}
        />
      </form>
    </div>
  );
}

export default LoginFile;
