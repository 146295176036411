import { DetailsTabPanelPropsType } from 'src/types';
import { LeadDetailsPropsType } from './ApplicationDetails';
import { titleCaseToReadable } from 'src/utils/utility';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';

type InternalTransferDetailsType = {
  options: DetailsTabPanelPropsType[];
};

function AccessoriesChecklist({ currentLead }: LeadDetailsPropsType) {

  let data: InternalTransferDetailsType[] | undefined;

  data = Array.isArray(currentLead?.accessories)
    ? currentLead.accessories.map((item) => {
        const options = {
          options: [
            {
              key: titleCaseToReadable(item.stage),
              value: '#title'
            },
            {
              key: 'Back Left Tyre',
              value: titleCaseToReadable(item.backLeftTyre)
            },
            {
              key: 'Back Right Tyre',
              value: titleCaseToReadable(item?.backRightTyre)
            },
            {
              key: 'Bumper',
              value: titleCaseToReadable(item.bumper)
            },
            {
              key: 'Hood',
              value: titleCaseToReadable(item.hood)
            },
            {
              key: 'Battery',
              value: titleCaseToReadable(item.battery)
            },
            {
              key: 'Drawbar',
              value: titleCaseToReadable(item.drawbar)
            },
            {
              key: 'Toplink',
              value: titleCaseToReadable(item.toplink)
            },
            {
              key: 'Front Left Tyre',
              value: titleCaseToReadable(item.frontLeftTyre)
            },
            {
              key: 'Front Right Tyre',
              value: titleCaseToReadable(item.frontRightTyre)
            }
          ]
        };
        console.log(options);
        return options;
      })
    : undefined;


  return (
    <>
      {data?.map((item, index) => (
        <DetailsTabPanel key={index} data={item.options} />
      ))}
    </>
  );
}

export default AccessoriesChecklist;
