import { useLocation, useNavigate, useParams } from 'react-router';
import {
  useEnquiryFilterLazyQuery,
  EnquiryFilterQueryVariables,
  useEnquiryFilterQuery,
  useGetDetailEnquiriesQuery
} from 'src/generated/hooks_and_more';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  TableContainer
} from '@mui/material';
import useIsOverflow from 'src/customHooks/hooks';
import EnquiryDetailsRow from 'src/components/Composite/EnquiryListRow';
import { ENQURY_DETAILS_TABLE_HEADER } from 'src/constants/constant';
import format from 'date-fns/format';
import './style.css';
import EnquiryTimeLine from './enquiryTimeline';
import styles from './style.json';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { titleCaseToReadable } from 'src/utils/utility';

interface RecentOrdersTableProps {
  className?: string;
  header?: { title: string; search?: boolean; key?: string }[];
  children: JSX.Element[] | JSX.Element;
  loading?: boolean;
  handleGlobalSearch?: (value: string) => void;
}

export const EnquiryTableWrapper: FC<RecentOrdersTableProps> = ({
  header,
  children,
  handleGlobalSearch,
  loading
}) => {
  return (
    <Card>
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <Box p={2}></Box>
    </Card>
  );
};

function EnquiryDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const [getEnquiryDetails, { data, loading }] = useEnquiryFilterLazyQuery({
    onCompleted(data) {
      console.log('lead list reponse on success', data);
    }
  });

  let state: any = location.state;
  const [varr, setVarr] = useState(null);

  useEffect(() => {
    if (state) {
      const id = state?.id;
      if (id) {
        getEnquiryDetails({ variables: { id } });
      }
    }
  }, [getEnquiryDetails, state]);

  if (loading) {
    return (
      <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }

  return (
    <>
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              Enquiry Detail
            </Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Box sx={{ px: 3, pb: 2 }} style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          <EnquiryTableWrapper>
            {data?.queryEnquiry
              ?.map((item, index) => {
                const lastFollowupIndex = item?.followups?.length - 1;

                const interestedProducts =
                  item?.interestedIn
                    ?.map(
                      (item) =>
                        `${item?.brand?.name ?? ''} - ${item?.name ?? ''}`
                    )
                    .join(' | ') ?? '';

                const enquiries = {
                  id: item?.id,
                  name: item?.customer?.name,
                  phoneNo: item?.customer?.phoneNo ?? '-',
                  state: item?.customer?.customerState?.state ?? '-',
                  district: item?.customer?.customerDistrict?.name ?? '-',
                  tehsil: item?.customer?.customerTehsil?.name ?? '-',
                  budget: item?.budget ?? 0,
                  status: item?.status ?? '-',
                  updatedAt:
                    lastFollowupIndex >= 0 &&
                    item?.followups[lastFollowupIndex]?.followupDate
                      ? format(
                          Date.parse(
                            item?.followups[lastFollowupIndex]?.followupDate
                          ),
                          'dd-MM-yyyy'
                        )
                      : '-',
                  enquiryType: item?.enquiryType ?? '-',
                  centreName: item?.centre?.name ?? '-',
                  count: item?.followupsAggregate?.count - 1 ?? 0,
                  createdByname: item?.createdBy?.name ?? '-',
                  enquiryMadeOn: item?.createdAt
                    ? format(Date.parse(item?.createdAt), 'dd-MM-yyyy')
                    : '-',
                  nextfollowupDate:
                    lastFollowupIndex >= 0 &&
                    item?.followups[lastFollowupIndex]?.nextFollowup
                      ?.followupDate
                      ? format(
                          Date.parse(
                            item?.followups[lastFollowupIndex]?.nextFollowup
                              ?.followupDate
                          ),
                          'dd-MM-yyyy'
                        )
                      : '-',
                  enquiryClosureReason:
                    item?.enquiryClosure?.enquiryCloseReason ?? '-',
                  interestedInProducts: interestedProducts,
                  tentativePurchase: titleCaseToReadable(
                    item?.tentativePurchaseTime ?? ''
                  ),
                  isClosed: item?.isClosed ? 'Closed' : 'Open',
                  lastVisitDate:
                    lastFollowupIndex >= 0 &&
                    item?.followups[lastFollowupIndex]?.followupDate
                      ? format(
                          Date.parse(
                            item?.followups[lastFollowupIndex]?.followupDate
                          ),
                          'dd-MM-yyyy'
                        )
                      : '-',
                  enquiryCreatedDate: item?.followups?.[0]?.followupDate
                    ? format(
                        new Date(item?.followups?.[0]?.followupDate),
                        'dd-MM-yyyy'
                      )
                    : '-'
                };

                return (
                  <EnquiryDetailsRow
                    key={index}
                    header={ENQURY_DETAILS_TABLE_HEADER}
                    enquiriesDetails={enquiries}
                  />
                );
              })
              .flat()}
          </EnquiryTableWrapper>
        </div>
        <div
          style={{
            backgroundColor: '#fbfbfb',
            overflowY: 'scroll',
            maxHeight: '85vh',
            marginRight: '1rem',
            position: 'sticky',
            top: 80
          }}
        >
          <EnquiryTimeLine />
        </div>
      </Box>
    </>
  );
}
export default EnquiryDetail;
