import React, { useEffect, useState } from 'react';
import {
  LeadRef,
  LeadStatus,
  PaymentFor,
  PaymentMethod,
  PaymentStatus,
  useFinalCostQuery,
  usePopupDynamicValuesQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import { Box, Button, Grid } from '@mui/material';
import Input from 'src/components/Form/Input';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import { useFormik } from 'formik';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { compareDate, enumToItems } from 'src/utils/utility';
import { ActionType } from 'src/constants/constant';
import useCreateLeadStatusEvents from '../../hooks/useCreateLeadStatusEvents';
import CtaButton from '../CTA_Button/CtaButton';
import * as Yup from 'yup';

function FIForm(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireButtonText,
    canGoBack,
    loginDate,
    onPressOfDesiredOrUndesiredButton
  } = props;

  const { data: getDynamicValue, loading: getloading } =
  usePopupDynamicValuesQuery({
    variables: {
      regNo: regNo
    },
    fetchPolicy: 'cache-and-network'
  });
  const paymentModeOptions = enumToItems(PaymentMethod);
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };

  const formik = useFormik({
    initialValues: {
      fIdate: '',
      error: 'VALID',
      remarks: ''
    },
    validationSchema: Yup.object().shape({
      fIdate: Yup.string()
      .required('required*')
      // error: Yup.string().equalTo(Yup.ref('NOTVALID'), "Invalid Booking Delivery Amount")
    }),
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        activeBooking: {
          activeLoan: {
            fieldInspectionDate: values?.fIdate,
          },
        },
      };
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });
  const [FiDate, setFidate] = useState(false)


  useEffect(() => {
    if (compareDate( formik?.values?.fIdate, loginDate)) {
      setFidate(false)
    } else {
      setFidate(true)
    }
  }, [formik?.values?.fIdate, loginDate])


  return  (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ mt: 2 }}>

          <Grid item xs={12} md={12}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue('fIdate', value);
              }}
              placeholder="Enter the FI Date*"
              value={
                formik.values.fIdate
                  ? new Date(formik.values.fIdate)
                  : null
              }
            />
            {formik.values.fIdate && (formik.errors.fIdate)|| formik.values.fIdate && FiDate ? (
              <div style={{ color: 'red' }}> Please enter a valid FI date</div>
            ) : null}
          </Grid>
         
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.remarks}
              name="remarks"
              onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
              placeholder="Enter the Remarks"
            />
          </Grid>
        </Grid>
        <CtaButton
          desireButtonText={desireButtonText}
          backBtn={canGoBack}
          disabled={!(formik.isValid && formik.dirty) ||  Boolean(FiDate)}
        />
      </form>
    </div>
  );
}

export default FIForm;
