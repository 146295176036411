import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import './style.css';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  EnquiryDashboardOptions,
  EnquiryDashboardType
} from 'src/constants/constant';
import {
  SelectTypeOfDate,
  useGetDetailEnquiriesLazyQuery
} from 'src/generated/hooks_and_more';
import {
  cachedEnquiryDashBoardFilter,
  getDateRangeMonths,
  getWeeksBetweenDates,
  getYearsFromDateRange,
  makeid,
  slugsToTitleCase,
  validDateFormate
} from 'src/utils/utility';
import EnquiryDashboardFilter from './EnquiryDashboardFilter';
import { ExportCSV } from '../leads/leadExport';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import EnquiryGrid from './EnquiryGrid';
import { useReactiveVar } from '@apollo/client';

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    },
    .css-e72053-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled{
      display:none;
    }
     .MuiTabs-scrollableX {
      background-color:#dfdfdf; 
    }
    .MuiTabs-scrollButtons {
       background-color:#5569ff;
       opacity:1;
      border-radius:0;
      color:white;
    }
`
);

function EnquiryDashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleClose = () => setShowModal(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [filterInputs, setFilterInputs] = useState(null);
  const [currentTab, setCurrentTab] = useState({
    label: 'All',
    start: null,
    end: null,
    key: null
  });
  const cachedEnquiryDetails = useReactiveVar(cachedEnquiryDashBoardFilter);

  const [filterLoading, setFilterLoading] = useState(false);

  const [ExportEnquiries, { data: exportEnquiry, loading: exportLoading }] =
    useGetDetailEnquiriesLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        dateType: SelectTypeOfDate.EnquiryCreatedDateRange,
        start: filterInputs?.start,
        end: filterInputs?.end
      },
      onCompleted() {
        let exportData = [];
        if (selectedFunnel?.value === EnquiryDashboardType.BY_CENTRE) {
          exportData = exportEnquiry?.filteredEnquiry
            ?.map((item) => {
              const lastFollowupIndex = item?.followups?.length - 1;
              const interestedProducts =
                item?.interestedIn
                  ?.filter(
                    (product) =>
                      product?.brand?.name !== null && product?.name !== null
                  )
                  ?.map((product) => ({
                    make: product?.brand?.name,
                    model: product?.name + ','
                  })) ?? [];

              return {
                Name: item?.customer?.name ?? '-',
                Mobile: item?.customer?.phoneNo ?? '-',
                State: item?.customer?.customerState?.state ?? '-',
                District: item?.customer?.customerDistrict?.name ?? '-',
                Tehsil: item?.customer?.customerTehsil?.name ?? '-',
                Budget: item?.budget ?? 0,
                Demanded_Tractors: interestedProducts?.[0]?.make
                  ? interestedProducts?.[0]?.make +
                    ' ' +
                    interestedProducts?.[0]?.model
                  : '-',
                Enquiry_Status: item?.isClosed ? 'Closed' : 'Open',
                Last_Updated_On:
                  lastFollowupIndex >= 0 &&
                  item?.followups?.[lastFollowupIndex]?.followupDate
                    ? format(
                        Date.parse(
                          item?.followups?.[lastFollowupIndex]?.followupDate
                        ),
                        'dd-MM-yyyy'
                      )
                    : '-',
                Enquiry_Closure_Reason:
                  item?.enquiryClosure?.enquiryCloseReason ?? '-',
                Enquiry_Type: item?.status ?? '-',
                Tentative_Purchase: item?.tentativePurchaseTime ?? '-',
                Walk_In_Type: item?.enquiryType ?? '-',
                Centre_Name: item?.centre?.name ?? '-',
                Date_Of_Enquiry: item?.createdAt
                  ? format(new Date(item?.createdAt), 'dd/MM/yyyy')
                  : '-',
                Followup_count: item?.followupsAggregate?.count - 1 ?? 0,
                Last_Visit_Date:
                  lastFollowupIndex >= 0 &&
                  item?.followups?.[lastFollowupIndex]?.followupDate
                    ? format(
                        Date.parse(
                          item?.followups?.[lastFollowupIndex]?.followupDate
                        ),
                        'dd-MM-yyyy'
                      )
                    : '-',
                Created_By: item?.createdBy?.name ?? '-',
                Enquiry_Created_Date: item?.followups?.[0]?.followupDate
                  ? format(
                      new Date(item?.followups?.[0]?.followupDate),
                      'dd/MM/yyyy'
                    )
                  : '-',
                Next_Followup_Date:
                  lastFollowupIndex >= 0 &&
                  item?.followups?.[lastFollowupIndex]?.nextFollowup
                    ?.followupDate
                    ? format(
                        Date.parse(
                          item?.followups?.[lastFollowupIndex]?.nextFollowup
                            ?.followupDate
                        ),
                        'dd/MM/yyyy'
                      )
                    : '-'
              };
            })
            .reverse();
        } else if (
          selectedFunnel?.value ===
          EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE
        ) {
          exportEnquiry?.filteredEnquiry?.map((item, index) => {
            const lastFollowupIndex = item?.followups?.length - 1;
            const interestedProducts =
              item?.interestedIn
                ?.filter(
                  (product) =>
                    product?.brand?.name !== null && product?.name !== null
                )
                ?.map((product) => ({
                  make: product?.brand?.name,
                  model: product?.name + ','
                })) ?? [];
            item?.followups?.map((followup, index) => {
              let FollowupClosed;
              if (index !== item?.followups?.length - 1) {
                FollowupClosed = item?.followups?.[index + 1]?.followupDate;
              } else {
                FollowupClosed = item?.isClosed ? followup?.followupDate : null;
              }

              exportData?.push({
                Followups_Id: followup?.id,
                Name: item?.customer?.name ?? '-',
                Mobile: item?.customer?.phoneNo ?? '-',
                State: item?.customer?.customerState?.state ?? '-',
                District: item?.customer?.customerDistrict?.name ?? '-',
                Tehsil: item?.customer?.customerTehsil?.name ?? '-',
                Budget: item?.budget ?? 0,
                Demanded_Tractors: interestedProducts?.[0]?.make
                  ? interestedProducts?.[0]?.make +
                    ' ' +
                    interestedProducts?.[0]?.model
                  : '-',
                Enquiry_Status: item?.isClosed ? 'Closed' : 'Open',
                Last_Updated_On:
                  lastFollowupIndex >= 0 &&
                  item?.followups?.[lastFollowupIndex]?.followupDate
                    ? format(
                        Date.parse(
                          item?.followups?.[lastFollowupIndex]?.followupDate
                        ),
                        'dd-MM-yyyy'
                      )
                    : '-',
                Enquiry_Closure_Reason:
                  item?.enquiryClosure?.enquiryCloseReason ?? '-',
                Enquiry_Type: item?.status ?? '-',
                Tentative_Purchase: item?.tentativePurchaseTime ?? '-',
                Walk_In_Type: item?.enquiryType ?? '-',
                Centre_Name: item?.centre?.name ?? '-',
                Date_Of_Enquiry:
                  format(new Date(item?.createdAt), 'dd/MM/yyyy') ?? '-',
                Enquiry_Created_Date: item?.followups?.[0]?.followupDate
                  ? format(
                      new Date(item?.followups?.[0]?.followupDate),
                      'dd/MM/yyyy'
                    )
                  : '-',
                Followup_Created_Date: followup?.followupDate
                  ? format(Date.parse(followup?.followupDate), 'dd/MM/yyyy')
                  : '-',
                Followup_Closed_Date: FollowupClosed
                  ? format(Date.parse(FollowupClosed), 'dd/MM/yyyy')
                  : '-',
                Ageing_In_days:
                  followup?.followupDate && FollowupClosed
                    ? Math.ceil(
                        Math.abs(
                          new Date(followup?.followupDate).getTime() -
                            new Date(FollowupClosed)?.getTime()
                        ) /
                          (1000 * 3600 * 24)
                      )
                    : '-',
                Next_Followup_Date:
                  lastFollowupIndex >= 0 &&
                  item?.followups[lastFollowupIndex]?.nextFollowup?.followupDate
                    ? format(
                        Date.parse(
                          item?.followups[lastFollowupIndex]?.nextFollowup
                            ?.followupDate
                        ),
                        'dd/MM/yyyy'
                      )
                    : '-',
                Created_By: item?.createdBy?.name ?? '-'
              });
            });
          });
        }
        setDataToExport(exportData.reverse());
      }
    });

  const handleTabsChange = (index) => {
    // console.log(index);
  };
  const [reportType, setReportType] = useState([
    { label: 'All', start: null, end: null, key: null }
  ]);

  function setForResetValue() {
    setReportType([{ label: 'All', start: null, end: null, key: null }]);
    setCurrentTab({ label: 'All', start: null, end: null, key: null });
  }

  const params = searchParams.get('type');
  const selectedFunnel = !!params
    ? { value: params, label: slugsToTitleCase(params) }
    : EnquiryDashboardOptions[0];

  useEffect(() => {
    if (!!cachedEnquiryDetails) {
      setFilterInputs({ ...cachedEnquiryDetails });
      getFunnelFromDate(
        cachedEnquiryDetails?.reportType,
        cachedEnquiryDetails?.dateRange
      );
      setCurrentTab(
        cachedEnquiryDetails?.tab || {
          label: 'All',
          start: null,
          end: null,
          key: null
        }
      );
    }
  }, [cachedEnquiryDetails]);
  function getFunnelFromDate(type, date) {
    if (date?.length) {
      const start = new Date(date[0]);
      const end = new Date(date[1]);
      switch (type) {
        case 'Weekly':
          const data = getWeeksBetweenDates(start, end);

          if (data) {
            setCurrentTab({
              label: 'All',
              start: start,
              end: end,
              key: makeid(10)
            });
            setReportType([
              {
                label: 'All',
                start: start,
                end: end,
                key: makeid(10)
              },
              ...data
            ]);
          }
          break;

        case 'Monthly':
          const monthData = getDateRangeMonths(start, end);
          if (monthData) {
            setCurrentTab({
              label: 'All',
              start: start,
              end: end,
              key: makeid(10)
            });
            setReportType([
              {
                label: 'All',
                start: start,
                end: end,
                key: makeid(10)
              },
              ...monthData
            ]);
          }
          break;
        case 'Yearly':
          const yearData = getYearsFromDateRange(start, end);
          if (yearData) {
            setCurrentTab({
              label: 'All',
              start: start,
              end: end,
              key: makeid(10)
            });
            setReportType([
              {
                label: 'All',
                start: start,
                end: end,
                key: makeid(10)
              },
              ...yearData
            ]);
          }
          break;
      }
    }
  }

  console.log(currentTab, reportType, 'current tab and report type data');
  const onFilterHandler = (filterVariables: any) => {
    let variables = { start: '', end: '' };
    setFilterLoading(true);
    if (filterVariables?.dateRange) {
      getFunnelFromDate(
        filterVariables?.reportType,
        filterVariables?.dateRange
      );
      variables = {
        ...variables,
        start: filterVariables?.dateRange?.[0],
        end: filterVariables?.dateRange?.[1]
      };

      setTimeout(() => {
        setShowModal(false);
        setFilterLoading(false);
      }, 1000);
    }

    setFilterInputs({ ...variables, reportType: filterVariables?.reportType });
  };

  const ExportSelected = () => {
    switch (selectedFunnel?.value) {
      case EnquiryDashboardType.BY_CENTRE:
        ExportEnquiries();

        return;
      case EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE:
        ExportEnquiries();

        return;

      default:
        break;
    }
  };
  const resetExport = () => {
    setDataToExport([]);
  };
  const getExportLoadingAndFileName = () => {
    switch (selectedFunnel?.value) {
      case EnquiryDashboardType.BY_CENTRE:
        return { name: 'Enquiry Details', loading: exportLoading };
      case EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE:
        return { name: 'Follwups Ageing', loading: exportLoading };

      default:
        return { name: 'Enquiry Details', loading: exportLoading };
    }
  };

  return (
    <Box
      style={{
        width: '100%'
        // position: 'relative'
      }}
    >
      <Helmet>
        <title>Enquiry Dashboard</title>
      </Helmet>
      {/* page header component  */}
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              Enquiry Dashboard
            </Typography>
          </Grid>
          <Grid display={'flex'}>
            <Grid item sx={{ width: '300px', pr: 2 }}>
              <InputAutoComplete
                disableClearable={true}
                onChange={(value) => {
                  setSearchParams({
                    type: value?.value
                  });
                }}
                value={selectedFunnel}
                options={EnquiryDashboardOptions ?? []}
                placeholder="Type"
                optionString="label"
              />
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <EnquiryDashboardFilter
                showModal={showModal}
                setShowModal={setShowModal}
                handleClose={handleClose}
                onFilterHandler={onFilterHandler}
                resetValue={setForResetValue}
                calenderPlaceholder={
                  selectedFunnel?.value === EnquiryDashboardType?.BY_CENTRE
                    ? 'Select Created Date Range'
                    : 'Select Followup Date Range'
                }
                filterInput={filterInputs}
              />
              <Box sx={{ paddingLeft: '20px' }}>
                <ExportCSV
                  callQuery={ExportSelected}
                  loading={getExportLoadingAndFileName()?.loading}
                  csvData={dataToExport}
                  fileName={getExportLoadingAndFileName()?.name}
                  reset={resetExport}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      {!filterLoading ? (
        <>
          <Box sx={{ paddingRight: '30px', paddingLeft: '30px' }}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab?.label}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {reportType?.map((tab, index) => {
                const label = tab?.label;
                return (
                  <Tab
                    key={label}
                    title={`Start: ${validDateFormate(
                      tab?.start
                    )} End: ${validDateFormate(tab?.end)}`}
                    label={label}
                    value={label}
                    onClick={() => {
                      setCurrentTab(tab);
                      cachedEnquiryDashBoardFilter({
                        ...cachedEnquiryDetails,
                        tab
                      });
                    }}
                  />
                );
              })}
            </TabsWrapper>
          </Box>
          <EnquiryGrid
            selectedTab={currentTab?.label}
            start={currentTab?.start}
            end={currentTab?.end}
            key={currentTab?.key}
          />
        </>
      ) : null}
    </Box>
  );
}

export default EnquiryDashboard;
