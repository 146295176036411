import { Box, Button } from '@mui/material';
import './button.css';
import { useNavigate } from 'react-router';

type ctaButtonProps = {
  desireButtonText: string;
  disabled: boolean;
  undesireButtonText?: string;
  greenRedcombo?: boolean;
  backBtn?: boolean;
  onlyRed?: boolean;
  reject?: any;
  checkRegistration?: boolean
};
function CtaButton(props: ctaButtonProps) {
  const navigate = useNavigate();
  const {
    undesireButtonText,
    desireButtonText,

    greenRedcombo = false,
    onlyRed = false,
    backBtn = false,
    disabled,
    reject,
  } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        flexWrap: 'wrap',
        padding: '10px'
      }}
    >
      {(undesireButtonText || backBtn) && (
        <Button
          id={
            greenRedcombo && disabled
              ? 'cta-btn-disabled'
              : greenRedcombo || onlyRed
              ? 'reject'
              : 'back-btn'
          }
          style={{ minWidth: '40%' }}
          disabled={greenRedcombo && disabled}
          variant="contained"
          onClick={() => (backBtn ? navigate(-1) : reject())}
        >
          {backBtn ? 'Back' : undesireButtonText}
        </Button>
      )}
    
      <Button
        id={
          disabled ? 'cta-btn-disabled' : greenRedcombo ? 'success' : 'cta-btn'
        }
        disabled={disabled}
        style={{ minWidth: '40%' }}
        type="submit"
        variant="contained"
        fullWidth={undesireButtonText || backBtn ? false : true}
      >
          { desireButtonText}
      </Button>
    </Box>
  );
}

export default CtaButton;
