import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  EnquiryByCentreQuery,
  SelectTypeOfDate,
  useEnquiryDashboardListLazyQuery
} from '../../generated/hooks_and_more';
import EnquiryClosureRows from './EnquiryClosureRows';
import { Fragment, useEffect, useState } from 'react';
import { EnquiryByCentreClosureRows } from 'src/constants/constant';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import './demo.css';
import {
  cachedEnquiryDashBoardFilter,
  titleCaseToReadable
} from 'src/utils/utility';
import { Box, CircularProgress } from '@mui/material';
import { useReactiveVar } from '@apollo/client';

type props = {
  data: EnquiryByCentreQuery;
  start: Date | null;
  end: Date | null;
  key?: string;
  tab?: string;
  onClickExpand: (
    index: string,
    type: 'ADHERANCE_TABLE' | 'CENTRE_TABLE'
  ) => void;
};
function EnquiryByCentreListRow({ data, onClickExpand, start, end }: props) {
  const enquiryData = data;
  const [selectedCentreEnquiry, setSelectedCentreEnquiry] = useState<string>();
  const [showHotColumns, setShowHotColumns] = useState(false);
  const [showColdColumns, setShowColdColumns] = useState(false);
  const [showWarmColumns, setShowWarmColumns] = useState(false);
  const [viewDetailLeads, setViewDetailLeads] = useState(false);
  const [searchParams] = useSearchParams();
  const [
    EnquiryDashboardList,
    { data: enquiryDashboardListData, loading: loadingEnquiry }
  ] = useEnquiryDashboardListLazyQuery();
  const cachedEnquiryDetails = useReactiveVar(cachedEnquiryDashBoardFilter);

  let sumOfColumns: any = {};
  const navigate = useNavigate();
  useEffect(() => {
    if (!!cachedEnquiryDetails) {
      onClickExpand(cachedEnquiryDetails?.id, 'CENTRE_TABLE');
      setViewDetailLeads(true);
    }
  }, []);
  useEffect(() => {
    console.log(searchParams?.get('id'), 'searchParams');
    const centerId = searchParams?.get('id');
    if (centerId) {
      setSelectedCentreEnquiry(centerId);
      EnquiryDashboardList({
        fetchPolicy: 'no-cache',
        variables: {
          start: start,
          end: end,
          dateType:
            start && end ? SelectTypeOfDate.EnquiryCreatedDateRange : null,
          centreId: centerId
        }
      });
    } else {
      setSelectedCentreEnquiry(null);
    }
  }, [searchParams, start, end]);

  return (
    <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
      <table
        className="table table-striped table-bordered"
        style={{
          width: '100%'
        }}
      >
        {enquiryData?.enquiryAggrigateByCentre?.length ? (
          <>
            <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
              <tr className="bg-secondary bg-gradient ">
                <th
                  scope="col"
                  className="text-center text-white sticky-top"
                  // style={{
                  //   textAlign: 'center',
                  //   backgroundColor: '#808080',
                  //   color: 'white'
                  // }}
                >
                  Center
                </th>
                <th
                  scope="col"
                  className={
                    showHotColumns
                      ? 'text-center text-white slide sticky-top'
                      : 'text-center text-white sticky-top'
                  }
                  colSpan={showHotColumns ? 8 : 0}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'sticky'
                    }}
                  >
                    <p>Hot</p>
                    {showHotColumns ? (
                      <IndeterminateCheckBoxIcon
                        fontSize="medium"
                        className="bg-secondary bg-gradient"
                        style={{
                          position: 'absolute',
                          right: -20,

                          color: 'white',
                          cursor: 'pointer'
                        }}
                        onClick={() => setShowHotColumns(!showHotColumns)}
                      />
                    ) : (
                      <AddBoxIcon
                        fontSize="medium"
                        className="bg-secondary bg-gradient"
                        style={{
                          position: 'absolute',
                          right: -20,

                          color: 'white',
                          cursor: 'pointer'
                        }}
                        onClick={() => setShowHotColumns(!showHotColumns)}
                      ></AddBoxIcon>
                    )}
                  </div>
                </th>
                <th
                  scope="col"
                  className="text-center text-white sticky-top"
                  colSpan={showWarmColumns ? 8 : 0}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'sticky'
                    }}
                  >
                    <p>Warm</p>
                    {showWarmColumns ? (
                      <IndeterminateCheckBoxIcon
                        fontSize="medium"
                        className="bg-secondary bg-gradient"
                        style={{
                          position: 'absolute',
                          right: -20,

                          color: 'white',
                          cursor: 'pointer'
                        }}
                        onClick={() => setShowWarmColumns(!showWarmColumns)}
                      />
                    ) : (
                      <AddBoxIcon
                        fontSize="medium"
                        className="bg-secondary bg-gradient"
                        style={{
                          position: 'absolute',
                          right: -20,

                          color: 'white',
                          cursor: 'pointer'
                        }}
                        onClick={() => setShowWarmColumns(!showWarmColumns)}
                      ></AddBoxIcon>
                    )}
                  </div>
                </th>
                <th
                  scope="col"
                  className="text-center text-white sticky-top"
                  colSpan={showColdColumns ? 8 : 0}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'sticky'
                    }}
                  >
                    <p>Cold</p>
                    {showColdColumns ? (
                      <IndeterminateCheckBoxIcon
                        fontSize="medium"
                        className="bg-secondary bg-gradient"
                        style={{
                          position: 'absolute',
                          right: -20,

                          color: 'white',
                          cursor: 'pointer'
                        }}
                        onClick={() => setShowColdColumns(!showColdColumns)}
                      />
                    ) : (
                      <AddBoxIcon
                        fontSize="medium"
                        className="bg-secondary bg-gradient"
                        style={{
                          position: 'absolute',
                          right: -20,

                          color: 'white',
                          cursor: 'pointer'
                        }}
                        onClick={() => setShowColdColumns(!showColdColumns)}
                      ></AddBoxIcon>
                    )}
                  </div>
                </th>
                <th scope="col" className="text-center text-white sticky-top">
                  Grand
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                style={{
                  position: 'sticky',
                  top: '2.3rem',
                  backgroundColor: '#8a8c8e',
                  color: '#fff',
                  opacity: 1
                }}
              >
                <td
                  style={{
                    textAlign: 'center',
                    minWidth: '100px',
                    fontWeight: 'bold'
                  }}
                >
                  Name
                </td>
                <td
                  style={{
                    textAlign: 'center',
                    minWidth: '100px',
                    fontWeight: 'bold'
                  }}
                >
                  Total
                </td>
                {showHotColumns
                  ? EnquiryByCentreClosureRows?.map((colTitle, index) => (
                      <td
                        style={{
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          textAlign: 'center'
                        }}
                        key={`tot_${index}`}
                      >
                        {colTitle?.title}
                      </td>
                    ))
                  : null}
                <td
                  style={{
                    textAlign: 'center',
                    minWidth: '100px',
                    fontWeight: 'bold'
                  }}
                >
                  Total
                </td>
                {showWarmColumns
                  ? EnquiryByCentreClosureRows?.map((colTitle, index) => (
                      <td
                        style={{
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          textAlign: 'center'
                        }}
                        key={`tot_${index}`}
                      >
                        {colTitle?.title}
                      </td>
                    ))
                  : null}
                <td
                  style={{
                    textAlign: 'center',
                    minWidth: '100px',
                    fontWeight: 'bold'
                  }}
                >
                  Total
                </td>
                {showColdColumns
                  ? EnquiryByCentreClosureRows?.map((colTitle, index) => (
                      <td
                        style={{
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          textAlign: 'center'
                        }}
                        key={`tot_${index}`}
                      >
                        {colTitle?.title}
                      </td>
                    ))
                  : null}
                <td
                  style={{
                    textAlign: 'center',
                    minWidth: '100px',
                    fontWeight: 'bold'
                  }}
                >
                  Total
                </td>
              </tr>
              {enquiryData?.enquiryAggrigateByCentre?.map((item, index) => {
                const hotClosures = item?.hotEnquiryCloser;
                const coldClosures = item?.coldEnquiryCloser;
                const warmClosures = item?.warmEnquiryCloser;
                const grandTotal =
                  item?.totalHot + item?.totalCold + item?.totalWarm;
                if (Object?.keys(sumOfColumns)?.length) {
                  sumOfColumns = {
                    totalHot: sumOfColumns?.totalHot + item?.totalHot,
                    totalWarm: sumOfColumns?.totalWarm + item?.totalWarm,
                    totalCold: sumOfColumns?.totalCold + item?.totalCold,
                    sumOfRows: sumOfColumns?.sumOfRows + grandTotal,
                    totalHotClosureColumns: {
                      totalHotInFollowups:
                        sumOfColumns?.totalHotClosureColumns
                          ?.totalHotInFollowups + hotClosures?.infollowUp,
                      totalHotClosed:
                        sumOfColumns?.totalHotClosureColumns?.totalHotClosed +
                        hotClosures?.closed,
                      totalHotTractorBooked:
                        sumOfColumns?.totalHotClosureColumns
                          ?.totalHotTractorBooked + hotClosures?.tractorBooked,
                      totalHotNewTractors:
                        sumOfColumns?.totalHotClosureColumns
                          ?.totalHotNewTractors +
                        hotClosures?.purchaseNewTractor,
                      totalHotUsedTractors:
                        sumOfColumns?.totalHotClosureColumns
                          ?.totalHotUsedTractors +
                        hotClosures?.purchasedUsedTractor,
                      totalHotNotInterested:
                        sumOfColumns?.totalHotClosureColumns
                          ?.totalHotNotInterested + hotClosures?.notIntrested,
                      totalHotNotResponding:
                        sumOfColumns?.totalHotClosureColumns
                          ?.totalHotNotResponding + hotClosures?.notResponding
                    },
                    totalColdClosureColumns: {
                      totalColdInFollowups:
                        sumOfColumns?.totalColdClosureColumns
                          ?.totalColdInFollowups + coldClosures?.infollowUp,
                      totalColdClosed:
                        sumOfColumns?.totalColdClosureColumns?.totalColdClosed +
                        coldClosures?.closed,
                      totalColdTractorBooked:
                        sumOfColumns?.totalColdClosureColumns
                          ?.totalColdTractorBooked +
                        coldClosures?.tractorBooked,
                      totalColdNewTractors:
                        sumOfColumns?.totalColdClosureColumns
                          ?.totalColdNewTractors +
                        coldClosures?.purchaseNewTractor,
                      totalColdUsedTractors:
                        sumOfColumns?.totalColdClosureColumns
                          ?.totalColdUsedTractors +
                        coldClosures?.purchasedUsedTractor,
                      totalColdNotInterested:
                        sumOfColumns?.totalColdClosureColumns
                          ?.totalColdNotInterested + coldClosures?.notIntrested,
                      totalColdNotResponding:
                        sumOfColumns?.totalColdClosureColumns
                          ?.totalColdNotResponding + coldClosures?.notResponding
                    },
                    totalWarmClosureColumns: {
                      totalWarmInFollowups:
                        sumOfColumns?.totalWarmClosureColumns
                          ?.totalWarmInFollowups + warmClosures?.infollowUp,
                      totalWarmClosed:
                        sumOfColumns?.totalWarmClosureColumns?.totalWarmClosed +
                        warmClosures?.closed,
                      totalWarmTractorBooked:
                        sumOfColumns?.totalWarmClosureColumns
                          ?.totalWarmTractorBooked +
                        warmClosures?.tractorBooked,
                      totalWarmNewTractors:
                        sumOfColumns?.totalWarmClosureColumns
                          ?.totalWarmNewTractors +
                        warmClosures?.purchaseNewTractor,
                      totalWarmUsedTractors:
                        sumOfColumns?.totalWarmClosureColumns
                          ?.totalWarmUsedTractors +
                        warmClosures?.purchasedUsedTractor,
                      totalWarmNotInterested:
                        sumOfColumns?.totalWarmClosureColumns
                          ?.totalWarmNotInterested + warmClosures?.notIntrested,
                      totalWarmNotResponding:
                        sumOfColumns?.totalWarmClosureColumns
                          ?.totalWarmNotResponding + warmClosures?.notResponding
                    }
                  };
                } else {
                  sumOfColumns = {
                    totalHot: item?.totalHot,
                    totalWarm: item?.totalWarm,
                    totalCold: item?.totalCold,
                    sumOfRows: grandTotal,
                    totalHotClosureColumns: {
                      totalHotInFollowups: Number(
                        item?.hotEnquiryCloser?.infollowUp
                      ),
                      totalHotClosed: hotClosures?.closed,
                      totalHotTractorBooked: hotClosures?.tractorBooked,
                      totalHotNewTractors: hotClosures?.purchaseNewTractor,
                      totalHotUsedTractors: hotClosures?.purchasedUsedTractor,
                      totalHotNotInterested: hotClosures?.notIntrested,
                      totalHotNotResponding: hotClosures?.notResponding,
                      totalHotGrand: grandTotal
                    },
                    totalColdClosureColumns: {
                      totalColdInFollowups: coldClosures?.infollowUp,
                      totalColdClosed: coldClosures?.closed,
                      totalColdTractorBooked: coldClosures?.tractorBooked,
                      totalColdNewTractors: coldClosures?.purchaseNewTractor,
                      totalColdUsedTractors: coldClosures?.purchasedUsedTractor,
                      totalColdNotInterested: coldClosures?.notIntrested,
                      totalColdNotResponding: coldClosures?.notResponding,
                      totalColdGrand: grandTotal
                    },
                    totalWarmClosureColumns: {
                      totalWarmInFollowups: warmClosures?.infollowUp,
                      totalWarmClosed: warmClosures?.closed,
                      totalWarmTractorBooked: warmClosures?.tractorBooked,
                      totalWarmNewTractors: warmClosures?.purchaseNewTractor,
                      totalWarmUsedTractors: warmClosures?.purchasedUsedTractor,
                      totalWarmNotInterested: warmClosures?.notIntrested,
                      totalWarmNotResponding: warmClosures?.notResponding,
                      totalWarmGrand: grandTotal
                    }
                  };
                }

                return (
                  <>
                    <tr
                      key={`row_${index + 1}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setViewDetailLeads(true);
                        onClickExpand(item?.centre?.id, 'CENTRE_TABLE');
                      }}
                    >
                      <td style={{ textAlign: 'center', minWidth: '100px' }}>
                        {item?.centre?.name}
                      </td>

                      <td style={{ textAlign: 'center', minWidth: '100px' }}>
                        {item?.totalHot}
                      </td>

                      {showHotColumns ? (
                        <EnquiryClosureRows
                          closed={hotClosures?.closed}
                          infollowUp={hotClosures?.infollowUp}
                          notIntrested={hotClosures?.notIntrested}
                          notResponding={hotClosures?.notResponding}
                          purchaseNewTractor={hotClosures?.purchaseNewTractor}
                          purchasedUsedTractor={
                            hotClosures?.purchasedUsedTractor
                          }
                          tractorBooked={hotClosures?.tractorBooked}
                        />
                      ) : null}

                      <td style={{ textAlign: 'center', minWidth: '100px' }}>
                        {item?.totalWarm}
                      </td>

                      {showWarmColumns ? (
                        <EnquiryClosureRows
                          closed={warmClosures?.closed}
                          infollowUp={warmClosures?.infollowUp}
                          notIntrested={warmClosures?.notIntrested}
                          notResponding={warmClosures?.notResponding}
                          purchaseNewTractor={warmClosures?.purchaseNewTractor}
                          purchasedUsedTractor={
                            warmClosures?.purchasedUsedTractor
                          }
                          tractorBooked={warmClosures?.tractorBooked}
                        />
                      ) : null}
                      <td style={{ textAlign: 'center', minWidth: '100px' }}>
                        {item?.totalCold}
                      </td>

                      {showColdColumns ? (
                        <EnquiryClosureRows
                          closed={coldClosures?.closed}
                          infollowUp={coldClosures?.infollowUp}
                          notIntrested={coldClosures?.notIntrested}
                          notResponding={coldClosures?.notResponding}
                          purchaseNewTractor={coldClosures?.purchaseNewTractor}
                          purchasedUsedTractor={
                            coldClosures?.purchasedUsedTractor
                          }
                          tractorBooked={coldClosures?.tractorBooked}
                        />
                      ) : null}

                      <td style={{ textAlign: 'center', minWidth: '100px' }}>
                        {item?.totalHot + item?.totalCold + item?.totalWarm}
                      </td>
                    </tr>
                    {viewDetailLeads &&
                      (item?.centre?.id === selectedCentreEnquiry ? (
                        loadingEnquiry ? (
                          <tr style={{ height: '40px' }}>
                            <td colSpan={8}>
                              <CircularProgress
                                sx={{
                                  textAlign: 'center',
                                  position: 'absolute',
                                  float: 'right',
                                  right: '50%'
                                }}
                                size={24}
                              />
                            </td>{' '}
                          </tr>
                        ) : (
                          <>
                            <tr className="JustDetailEnquiry">
                              <td
                                colSpan={
                                  showHotColumns ||
                                  showColdColumns ||
                                  showWarmColumns
                                    ? 3
                                    : 0
                                }
                                style={{
                                  textAlign: 'center',
                                  width: '25%',
                                  fontWeight: 'bold'
                                }}
                              >
                                Customer Name
                              </td>
                              <td
                                colSpan={
                                  showHotColumns ||
                                  showColdColumns ||
                                  showWarmColumns
                                    ? 3
                                    : 0
                                }
                                style={{
                                  textAlign: 'center',
                                  width: '25%',
                                  fontWeight: 'bold'
                                }}
                              >
                                State
                              </td>
                              <td
                                colSpan={
                                  showHotColumns ||
                                  showColdColumns ||
                                  showWarmColumns
                                    ? 3
                                    : 0
                                }
                                style={{
                                  textAlign: 'center',
                                  width: '25%',
                                  fontWeight: 'bold'
                                }}
                              >
                                District
                              </td>
                              <td
                                colSpan={
                                  showHotColumns ||
                                  showColdColumns ||
                                  showWarmColumns
                                    ? 3
                                    : 2
                                }
                                style={{
                                  textAlign: 'center',
                                  width: '25%',
                                  fontWeight: 'bold'
                                }}
                              >
                                Status
                              </td>
                            </tr>

                            {enquiryDashboardListData?.filteredEnquiry?.map(
                              (enquiry) => {
                                return (
                                  <>
                                    <tr
                                      style={{ cursor: 'pointer' }}
                                      key={enquiry?.id}
                                      onClick={() => {
                                        setViewDetailLeads(true);
                                        navigate(
                                          `/vms-admin/enquiry/${enquiry.id}`,
                                          {
                                            state: {
                                              id: enquiry.id
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      {/* <td>
                                          {enquiry?.id}
                                        </td> */}
                                      <td
                                        colSpan={
                                          showHotColumns ||
                                          showColdColumns ||
                                          showWarmColumns
                                            ? 3
                                            : 0
                                        }
                                        style={{
                                          textAlign: 'center',
                                          minWidth: '25%'
                                        }}
                                      >
                                        {enquiry?.customer?.name}
                                      </td>
                                      <td
                                        colSpan={
                                          showHotColumns ||
                                          showColdColumns ||
                                          showWarmColumns
                                            ? 3
                                            : 0
                                        }
                                        style={{
                                          textAlign: 'center',
                                          minWidth: '25%'
                                        }}
                                      >
                                        {titleCaseToReadable(
                                          enquiry?.customer?.customerState
                                            ?.state
                                        )}
                                      </td>
                                      <td
                                        colSpan={
                                          showHotColumns ||
                                          showColdColumns ||
                                          showWarmColumns
                                            ? 3
                                            : 0
                                        }
                                        style={{
                                          textAlign: 'center',
                                          minWidth: '25%'
                                        }}
                                      >
                                        {titleCaseToReadable(
                                          enquiry?.customer?.customerDistrict
                                            ?.name
                                        )}
                                      </td>

                                      <td
                                        colSpan={
                                          showHotColumns ||
                                          showColdColumns ||
                                          showWarmColumns
                                            ? 3
                                            : 2
                                        }
                                        style={{
                                          textAlign: 'center',
                                          minWidth: '25%'
                                        }}
                                      >
                                        {titleCaseToReadable(enquiry?.status)}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                          </>
                        )
                      ) : null)}
                  </>
                );
              })}

              {console.log(sumOfColumns, 'sumofcolumns')}
              {Object.keys(sumOfColumns).length && (
                <tr
                  className="bg-secondary bg-gradient"
                  style={{
                    position: 'sticky',
                    bottom: 0
                  }}
                >
                  <td className="text-center fw-bolder text-white">Total</td>
                  <td className="text-center fw-bolder text-white">
                    {sumOfColumns?.totalHot}
                  </td>
                  {showHotColumns
                    ? Object.values(sumOfColumns?.totalHotClosureColumns).map(
                        (item, index) => (
                          <td
                            className="text-center fw-bolder text-white"
                            key={`last_column_Hot_${index + 1}`}
                          >
                            {item}
                          </td>
                        )
                      )
                    : null}
                  <td className="text-center fw-bolder text-white">
                    {sumOfColumns?.totalWarm}
                  </td>
                  {showWarmColumns
                    ? Object.values(sumOfColumns?.totalWarmClosureColumns).map(
                        (item, index) => (
                          <td
                            className="text-center fw-bolder text-white"
                            key={`last_column_Warm_${index + 1}`}
                          >
                            {item}
                          </td>
                        )
                      )
                    : null}
                  <td className="text-center fw-bolder text-white">
                    {sumOfColumns?.totalCold}
                  </td>
                  {showColdColumns
                    ? Object.values(sumOfColumns?.totalColdClosureColumns).map(
                        (item, index) => (
                          <td
                            className="text-center fw-bolder text-white"
                            key={`last_column_Cold${index + 1}`}
                          >
                            {item}
                          </td>
                        )
                      )
                    : null}
                  <td className="text-center fw-bolder text-white">
                    {sumOfColumns?.sumOfRows}
                  </td>
                </tr>
              )}
            </tbody>
          </>
        ) : (
          <>
            <thead>
              <tr className="bg-secondary bg-gradient">
                <th
                  scope="col"
                  className="text-center text-white"
                  // style={{
                  //   textAlign: 'center',
                  //   backgroundColor: '#808080',
                  //   color: 'white'
                  // }}
                >
                  Center
                </th>
                <th scope="col" className="text-center text-white">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative'
                    }}
                  >
                    Hot
                  </div>
                </th>
                <th scope="col" className="text-center text-white">
                  Warm
                </th>
                <th scope="col" className="text-center text-white">
                  Cold
                </th>

                <th scope="col" className="text-center text-white">
                  Grand
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center" colSpan={5}>
                  No Data Found
                </td>
              </tr>
            </tbody>
          </>
        )}
      </table>
    </div>
  );
}

export default EnquiryByCentreListRow;
