import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { LeadCreateAndUpdateProps } from 'src/types';
import CtaButton from '../CTA_Button/CtaButton';
import { enumToItems } from 'src/utils/utility';
import {
  LeadRef,
  PaymentFor,
  PaymentMethod,
  PaymentStatus,
  useGetLeadRefurbishmentDetailsQuery
} from 'src/generated/hooks_and_more';
import { useFormik } from 'formik';
import {
  DealPaymentPendingSchema,
  InstallationPaymentSchema
} from '../../ValidationSchema/ValidationSchema';
import { ActionType } from 'src/constants/constant';
import Input from 'src/components/Form/Input';

function InstallationPayment(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    onPressOfDesiredOrUndesiredButton,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    regNo,
    refurbishmentRequestId,
    desireStatus
  } = props;
  const [charges, setCharges]: any = useState(null);

  const { data: refurbishmentDetailsData, loading } =
    useGetLeadRefurbishmentDetailsQuery({
      variables: {
        leadId: leadId
      },
      onCompleted(data) {
        let refurbishmentItems =
          data?.queryLead?.[0]?.refurbishmentDetails?.requests;
        let transportation = refurbishmentItems?.find(
          (req) => req?.id === refurbishmentRequestId
        )?.transportationCharge;

        let refurbishmentCharges =
          refurbishmentItems
            ?.find((req) => req?.id === refurbishmentRequestId)
            ?.purchase?.items?.reduce((acc, item) => acc + item.price, 0) ?? 0;

        formik.setValues({
          ...formik.values,
          transportationCharge: transportation,
          refurbishmentCharges: refurbishmentCharges,
          totalCharges: refurbishmentCharges + transportation,
          refurbishedAmount: refurbishmentCharges + transportation
        });
        // setCharges({
        //   transportationCharge: transportationCharge,
        //   refurbishmentCharges: refurbishmentCharges,
        //   totalCharges: refurbishmentCharges + transportationCharge
        // });
      }
    });

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };

  const paymentModeOptions = enumToItems(PaymentMethod);
  const payFor = PaymentFor;
  const formik = useFormik({
    initialValues: {
      transportationCharge: null,

      refurbishmentCharges: null,
      totalCharges: null,
      refurbishedAmount: null,
      paymentMode: null,
      amount: '',
      paymentDate: '',
      paymentReceipt: '',
      remarks: ''
    },
    validationSchema: InstallationPaymentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        refurbishmentDetails: {
          requests: [
            {
              id: refurbishmentRequestId
            }
          ]
        },
        payments: [
          {
            modeFM: values?.paymentMode?.value as PaymentMethod,
            amount: Number(values?.amount),
            status: PaymentStatus.Done,
            for: PaymentFor.Refurbishment,
            paymentProcessedAt: values?.paymentDate,
            receiptUrl: values?.paymentReceipt
          }
        ]
      };
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });

  const reject = () => {
    let rejectInput: LeadRef = {
      regNo: regNo,
      id: leadId,
      refurbishmentDetails: {
        requests: [
          {
            id: refurbishmentRequestId
          }
        ]
      },
      payments: [
        {
          modeFM: formik?.values?.paymentMode?.value as PaymentMethod,
          amount: Number(formik?.values?.amount),
          status: PaymentStatus.Done,
          for: PaymentFor.Refurbishment,
          paymentProcessedAt: formik?.values?.paymentDate,
          receiptUrl: formik?.values?.paymentReceipt
        }
      ]
    };
    onPressOfDesiredOrUndesiredButton(rejectInput, '', ActionType.UNDESIRED);
  };
  return loading ? (
    <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>
      <h5>Loading..</h5>
    </div>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', gap: '50px', ml: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Typography variant="h5">Refurbishment Charges</Typography>
            <Typography variant="h5">Transportation Charges</Typography>
            <Typography variant="h5">Total Payment</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Typography variant="h5">
              {formik?.values?.refurbishmentCharges}
            </Typography>
            <Typography variant="h5">
              {formik?.values?.transportationCharge ?? 0} 
            </Typography>
            <Typography variant="h5">{formik?.values?.totalCharges}</Typography>
          </Box>
        </Box>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.paymentMode}
            name="paymentMode"
            options={paymentModeOptions}
            onChange={(val) => formik?.setFieldValue('paymentMode', val)}
            placeholder="Select Payment Mode*"
          />
          {formik.errors.paymentMode && formik.touched.paymentMode ? (
            <div style={{ color: 'red' }}>{formik.errors?.paymentMode}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.amount}
            inputType="number"
            name="amount"
            onChange={(e) => formik?.setFieldValue('amount', e.target.value)}
            placeholder="Enter the Payment Amount in INR*"
          />
          {formik.values.amount &&
          (formik.errors.amount || formik.touched.amount) ? (
            <div style={{ color: 'red' }}>{formik.errors?.amount}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <DatePickerCalender
            maxDate={new Date()}
            onChange={(value) => {
              formik.setFieldValue('paymentDate', value);
            }}
            placeholder="Enter the Payment Date*"
            value={
              formik.values.paymentDate
                ? new Date(formik.values.paymentDate)
                : null
            }
          />
          {formik.errors.paymentDate && formik.touched.paymentDate ? (
            <div style={{ color: 'red' }}>{formik.errors?.paymentDate}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="paymentReceipt"
            value={formik.values.paymentReceipt}
            onChange={(value) => {
              formik.setFieldValue('paymentReceipt', value);
            }}
            placeholder="Payment Receipt*"
          />
          {formik.errors.paymentReceipt && formik.touched.paymentReceipt ? (
            <div style={{ color: 'red' }}>{formik.errors?.paymentReceipt}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.remarks}
            name="remarks"
            onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
            placeholder="Enter the Remarks"
          />
        </Grid>
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        reject={reject}
        onlyRed={true}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
}

export default InstallationPayment;
