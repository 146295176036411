const routeConstant = {
  INVENTORY: '/inventory',
  CENTER: 'center',
  ADD_CENTER: 'center/add',
  EDIT_CENTER: 'center/edit/:Id',
  ADD_STOCK: 'center/stock/add',
  TRANSFER_STOCK: 'center/stock/transfer',
  STOCK: 'center/stock',
  LEADS_IN_CENTRE: 'centre/leads',
  CENTER_DETAILS: 'center/:Id',
  STOCK_DETAILS: 'center/:centreId/stock/:Id',
  USERS: '/users',
  USERS_LIST: 'users-list',
  EDIT_USERS: 'edit-list',
  ADD_USER: 'add-user',
  BOOKING: 'booking',
  ENQUIRY: 'enquiry',
  EDIT_BOOKING: 'booking/edit-booking',
  ENQUIRY_DETAILS: 'enquiry-detail',
  DOCUMENTS: '/documents',
  ALL_LEADS: 'leads',
  PENDING_LEADS: 'pending-leads',
};

export default routeConstant;
