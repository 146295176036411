import { Box } from '@mui/material';
import HeaderSearch from './Search';
import HeaderNotifications from './Notifications';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { useState } from 'react';
import './index.css';
function HeaderButtons() {
  const [val, setVal] = useState<boolean>(false);
  const [count, setCount] = useState(null);

  function handleClose() {
    setVal(false);
  }
  const notificationCount = (val) => {
    setCount(val);
  };
  return (
    <Box sx={{ mr: 1 }}>
      <HeaderSearch />
      {location.pathname.includes('documents') && (
        <Box sx={{ mx: 0.5 }} component="span">
          <HeaderNotifications
            val={val}
            setVal={setVal}
            handleClose={handleClose}
            notificationCount={notificationCount}
          />
        </Box>
      )}
    </Box>
  );
}

export default HeaderButtons;
