import { ClosedCaption } from '@mui/icons-material';
import { Box, Grid, Modal } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import Calender from 'src/components/Calender/Calender';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import CloseIcon from '@mui/icons-material/Close';
import {
  cachedDocumentFilter,
  enumToItems,
  titleCaseToReadable
} from 'src/utils/utility';
import {
  AggregatesCountDocumentQueryVariables,
  LeadSource,
  useGetAllStateQuery
} from 'src/generated/hooks_and_more';
import { getVariableValues } from 'graphql';
import { string } from 'prop-types';

const style = {
  position: 'absolute' as 'absolute',
  top: '55%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '70%',
  bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '10px',
  p: 2
};

const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '10px',
  cursor: 'pointer',
  color: 'rgb(195, 62, 62)'
};

type FilterProps = {
  showModal: boolean;
  setShowModal: any;
  handleClose: () => void;
  onFilterSubmit: (variables: AggregatesCountDocumentQueryVariables) => void;
  filterLoading: boolean;
  filterInput?: any;
};
function DocumentLeadsFilter({
  handleClose,
  onFilterSubmit,
  filterInput,
  filterLoading
}: FilterProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const { data } = useGetAllStateQuery();

  //  let states = ["Gujarat", "Haryana", "Karnataka", "Rajasthan", "Maharashtra", "Madhya Pradesh", "Punjab", "Tamil Nadu", "Uttar Pradesh"];

  let states = [];
  const selectedStates = [];
  const priorityStates = ['rajasthan', 'maharashtra', 'madhya pradesh'];
  data?.getStateInfo?.data?.map((item) => {
    const readableFormat = titleCaseToReadable(item?.state_name);

    if (priorityStates?.includes(readableFormat?.toLocaleLowerCase())) {
      console.log(readableFormat, 'read');
      selectedStates?.push({ ...item, state_name: readableFormat });
    } else {
      states?.push({ ...item, state_name: readableFormat });
    }
  });
  states = [...selectedStates, ...states];

  // Ensure the first three states are Rajasthan, Maharashtra, and Madhya Pradesh

  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (filterInput) {
      setInitialValues({
        documentStatus: filterInput?.docStatus
          ? { name: filterInput?.docStatus ?? null }
          : null,
        purchaseTypeValue: filterInput?.source
          ? { label: filterInput?.source ?? null }
          : null,
        dateRange:
          filterInput?.start || filterInput?.end
            ? [new Date(filterInput?.start), new Date(filterInput?.end)] ?? null
            : null,
        regState: filterInput?.regState
          ? { state_name: filterInput?.regState }
          : null
      });
    }
  }, [filterInput]);

  return (
    <>
      <Grid>
        <Button
          sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
          variant="outlined"
          onClick={() =>
            onFilterSubmit({
              start: '',
              end: '',
              offset: 1,
              pageSize: 20
            })
          }
        >
          Reset
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={handleOpen}
          sx={{ mt: { xs: 0, md: 0 } }}
          variant="contained"
        >
          Filter
        </Button>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={closeBtnStyle}>
            <CloseIcon
              onClick={() => {
                setOpen(false);
              }}
            />
          </Box>

          <Formik
            initialValues={
              initialValues
                ? initialValues
                : {
                    documentStatus: null,
                    purchaseTypeValue: null,
                    dateRange: null,
                    regState: null
                  }
            }
            onSubmit={(values) => {
              let variables: AggregatesCountDocumentQueryVariables = {
                source: values?.purchaseTypeValue?.value,
                docStatus: values?.documentStatus?.name,
                start: Boolean(values?.dateRange)
                  ? new Date(values?.dateRange[0])
                  : '',
                end: Boolean(values?.dateRange)
                  ? new Date(values?.dateRange[1])
                  : '',
                regState: values?.regState?.state_name
              };

              cachedDocumentFilter({
                ...variables,

                offset: 1,
                pageSize: 20
              });
              onFilterSubmit(variables);
              setOpen(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              resetForm,
              handleReset
            }) => (
              <Form
                onSubmit={handleSubmit}
                id="myForm"
                style={{
                  width: '80%',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                <Grid container spacing={2} mr={2} ml={2}>
                  <Grid item xs={10} md={10} sx={{ mt: 2 }}>
                    <InputAutoComplete
                      onChange={(value) => {
                        setFieldValue('regState', value);
                      }}
                      value={values.regState}
                      placeholder="Select State"
                      options={states}
                      optionString="state_name"
                    />
                  </Grid>
                  <Grid item xs={10} md={10} sx={{ mt: 2 }}>
                    <InputAutoComplete
                      options={enumToItems(LeadSource)}
                      placeholder="Purchase Type"
                      value={values.purchaseTypeValue}
                      onChange={(value) => {
                        setFieldValue('purchaseTypeValue', value);
                      }}
                      getOptionLabel={(option: { label: string }) =>
                        option.label
                      }
                    />
                  </Grid>
                  <Grid item xs={10} md={10} sx={{ mt: 2 }}>
                    <InputAutoComplete
                      onChange={(value) => {
                        setFieldValue('documentStatus', value);
                      }}
                      value={values.documentStatus}
                      placeholder="Select Document Status"
                      options={[{ name: 'PENDING' }, { name: 'RECEIVED' }]}
                      optionString="name"
                      getOptionLabel={(option: { name: string }) => option.name}
                    />
                  </Grid>

                  <Grid item xs={10} md={10} sx={{ mt: 2 }}>
                    <Calender
                      placeholder="Select Created Date"
                      onDateChange={(e, placehldr: string) => {
                        setFieldValue('dateRange', e);
                      }}
                      filteredDate={values?.dateRange}
                    />
                  </Grid>

                  <Grid
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      gap: '20px',
                      mb: '10px'
                    }}
                    md={12}
                  >
                    <Button
                      sx={{ mt: { xs: 1, md: 1 } }}
                      // style={{ marginRight: '20px' }}
                      style={{ width: '110px' }}
                      variant="contained"
                      type="reset"
                      // onClick={(event) => { resetForm() }}
                      onClick={() => {
                        setFieldValue('dateRange', null);
                        setFieldValue('docStatus', null);
                        setFieldValue('source', null);
                        resetForm();
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      form="myForm"
                      type="submit"
                      sx={{ mt: { xs: 1, md: 1 } }}
                      variant="contained"
                    >
                      Apply Filter
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}

export default DocumentLeadsFilter;
