import { getYearFromDate, titleCaseToReadable } from '../../utils/utility';

import { TableCell, TableRow, Typography } from '@mui/material';
import {
  Lead,
  LeadPreviewFragment,
  LeadStatusEvent,
  useCloseLeadsMutation
} from '../../generated/hooks_and_more';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import DeleteIcon from '@mui/icons-material/Delete';

type TaskListProps = {
  regNo: string;
  make: string;
  title: string;
  lseId: string;
  createdAt: string;
};

function NotificationListRow(props: TaskListProps) {
  const {
    regNo,
    make,
    title,
    createdAt,

    lseId
  } = props;

  const nav = useNavigate();

  return (
    <TableRow
      hover
      onClick={() => {
        nav(`/vms-admin/leads/${regNo}`, {
          state: { lseId: lseId }
        });
      }}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell title={regNo ?? ''} style={{ maxWidth: '150px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {regNo ?? '-'}
        </Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: '300px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
          title={title}
        >
          {title}
        </Typography>
      </TableCell>

      <TableCell title={make ?? ''} sx={{ width: '150px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {make ?? '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '150px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {createdAt}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default NotificationListRow;
