import { Padding } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import { LeadCreateAndUpdateProps } from 'src/types';
import { LeadRef, LeadStatus, useGetLeadDealDetailsQuery } from 'src/generated/hooks_and_more';
import CtaButton from '../CTA_Button/CtaButton';

const RequestDocument = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    canGoBack,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton
  } = props;

  const {data, loading} = useGetLeadDealDetailsQuery({
    variables:{
      id: leadId
    }
  })

  const formik = useFormik({
    initialValues: {
      registrationCertificate: 'no',
      formTwentySix: 'no',
      loanForeClosure: 'no',
      BankNOC: 'no',
      formthirtyFive: 'no',
      insuranceCertificate: 'no',
      formTwentyEight: 'no',
      formTwentyNine: 'no',
      formThirty: 'no',
      sellerPANorFromSixty: 'no',
      sellerAadharCard: 'no',
      ownerAdderessProof: 'no',
      formThirtySix: 'no',
      

    },
    onSubmit: (values) => {
      const leadInput: LeadRef = {
        id: leadId,
        regNo: regNo,
      
        documentChecklist: {
          bankNOC: values.BankNOC === 'yes' ? true : false,
          form26: values.formTwentySix === 'yes' ? true : false,
          form28: values.formTwentyEight === 'yes' ? true : false,
          form29: values.formTwentyNine === 'yes' ? true : false,
          form30: values.formThirty === 'yes' ? true : false,
          form35: values.formthirtyFive === 'yes' ? true : false,
          // indemnityBond: values.  === "yes" ? true : false,
          // releaseOrder: values.BankNOC  === "yes" ? true : false,
          insuranceCertificate:
            values.insuranceCertificate === 'yes' ? true : false,
          loanForeclosure: values.loanForeClosure === 'yes' ? true : false,
          registrationCertificate:
            values.registrationCertificate === 'yes' ? true : false,
          sellerAadharCard: values.sellerAadharCard === 'yes' ? true : false,
          form36: values.formThirtySix === 'yes' ? true : false,
          form60_sellerPan:
            values.sellerPANorFromSixty === 'yes' ? true : false,
          ownerAddressProof: values.ownerAdderessProof === 'yes' ? true : false
        }
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} component={Paper}  sx={{borderRadius: '7px',
backgroundColor: '#f6f6f6', border: 'solid 1px #d7d2d2 '}} >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            fontSize: '16px',
            border: '1px solid #C5C5C5',
            borderRadius: '7px'
          }}
        >
          <Grid
            item
            xs={6}
            md={6}
            sx={{ fontWeight: 'bold', fontSize: '18px' }}
          >
            Document Name
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sx={{ fontWeight: 'bold', fontSize: '18px' }}
          >
            {' '}
            Required{' '}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6} >
            Registration Certificate*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.registrationCertificate}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() =>
                    formik.setFieldValue('registrationCertificate', 'yes')
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() =>
                    formik.setFieldValue('registrationCertificate', 'no')
                  }
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.7rem' }}
        >
          <Grid item xs={6} md={6}>
            Form 26*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.formTwentySix}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('formTwentySix', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('formTwentySix', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Loan foreclosure*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.loanForeClosure}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('loanForeClosure', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('loanForeClosure', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Bank NOC*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.BankNOC}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('BankNOC', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('BankNOC', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Form 35*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.formthirtyFive}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('formthirtyFive', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('formthirtyFive', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Insurance Certificate*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.insuranceCertificate}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() =>
                    formik.setFieldValue('insuranceCertificate', 'yes')
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() =>
                    formik.setFieldValue('insuranceCertificate', 'no')
                  }
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Form 28*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.formTwentyEight}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('formTwentyEight', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('formTwentyEight', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Form 29*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.formTwentyNine}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('formTwentyNine', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('formTwentyNine', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Form 30*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.formThirty}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('formThirty', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('formThirty', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Seller PAN / Form 60*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.sellerPANorFromSixty}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() =>
                    formik.setFieldValue('sellerPANorFromSixty', 'yes')
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() =>
                    formik.setFieldValue('sellerPANorFromSixty', 'no')
                  }
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Seller Aadhar Card*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.sellerAadharCard}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() =>
                    formik.setFieldValue('sellerAadharCard', 'yes')
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('sellerAadharCard', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Owner Address Proof*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.ownerAdderessProof}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() =>
                    formik.setFieldValue('ownerAdderessProof', 'yes')
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() =>
                    formik.setFieldValue('ownerAdderessProof', 'no')
                  }
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
            Form 36*
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.formThirtySix}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('formThirtySix', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('formThirtySix', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} mt={1} mb={1} sx={{ width: '100%' }}>
        <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        disabled={false}
      />
        </Grid>
      </Grid>
    </form>
  );
};

export default RequestDocument;
