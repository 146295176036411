import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  GetFilteredLeadsQueryVariables,
  LeadStatus,
  useGetFilteredLeadsLazyQuery,
  useStockLeadsQuery
} from '../../generated/hooks_and_more';
import LeadListRow from '../../components/Composite/LeadListRow';
import { LeadListTableHeader } from '../../constants/constant';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { Box, Grid, TableCell, TableRow, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TableWrapper from 'src/components/Tables';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
// import LeadsFilter from './LeadsFilter';
import { useLocation } from 'react-router';
import { useReactiveVar } from '@apollo/client';
import { searchString } from 'src/constants/reactiveVar';
import CenterLead from 'src/components/Composite/CenterLead';

function LeadsInCentre() {
  const location = useLocation();
    const { data, loading } = useStockLeadsQuery({
    fetchPolicy: 'no-cache',
    skip: !location?.state,
    variables: {
      centerName: location?.state as string,
      status:LeadStatus.VehicleInStock
    }
  });

  const handleGlobalSearch = useCallback((value: string) => {
    console.log(value);

    searchString(value);
  }, []);

  console.log(location.state, 'data1');

  return (
    <Fragment>
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              Leads
            </Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Box sx={{ px: 2, pb: 2 }}>
        {loading ? (
          (
            <div
              style={{ padding: '30px' }}
              className="d-flex flex-column  justify-content-center align-items-center gap-2"
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
              <h5>Loading..</h5>
            </div>
          ) 
        ) : (
          <>
            <TableWrapper
              handleGlobalSearch={handleGlobalSearch}
              header={LeadListTableHeader}
            >
              {data?.filteredLeads?.length ? (
                data?.filteredLeads?.map((item, index) => {
                  return (
                    <CenterLead key={item.id} centerLead={item} index={index} />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '20px',
                        fontWeight: '900'
                      }}
                    >
                      <Typography>No data found</Typography>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableWrapper>
          </>
        )}
      </Box>
    </Fragment>
  );
}

export default LeadsInCentre;
