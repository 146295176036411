import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Grid, TextField } from '@mui/material';
import Input from 'src/components/Form/Input';
import * as Yup from 'yup';
import {
  BankName,
  LeadRef,
  LeadStatus,
  PaymentFor,
  PaymentMethod,
  PaymentStatus,
  PaymentTo,
  RefurbishmentStatus,
  useGetAllParkingBeneficiaryLazyQuery,
  useGetAllParkingBeneficiaryQuery,
  useGetAllRefurbishmentBeneficiaryLazyQuery,
  useGetAllRefurbishmentBeneficiaryQuery,
  useGetLeadRefurbishmentDetailsQuery,
  useGetYardDetailsQuery,
  useRefurbishmentRequestDetailsQuery
} from 'src/generated/hooks_and_more';
import {
  calculateParkingCharge,
  enumToItems,
  titleCaseToReadable
} from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LeadCreateAndUpdateProps } from 'src/types';

// import './form.css';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import { ActionType } from 'src/constants/constant';
import CtaButton from '../CTA_Button/CtaButton';
import {
  RaisePaymentRequestSchemaRefurbishment,
  SchedulePickFormValidation
} from '../../ValidationSchema/ValidationSchema';

const RefurshimentRequestPayment = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    undesireButtonText,
    canGoBack,
    refurbishmentRequestId
  } = props;
  const { data: refurbishmentDetailsData } =
    useGetLeadRefurbishmentDetailsQuery({
      variables: {
        leadId: leadId
      }
    });
  const [getBeneficiaryDetails, { data: refubBenificiaryDataa, loading }] =
    useGetAllRefurbishmentBeneficiaryLazyQuery({
      onCompleted(refubBenificiaryDataa) {
        const value = refubBenificiaryDataa?.queryRefurbishmentBeneficiary?.[0];
        const bankOption = {
          label: titleCaseToReadable(value?.bankName),
          value: value?.bankName
        };
        formik.setValues({
          ...formik.values,
          bankName: bankOption,
          accountHolderName: value?.accountHolderName,
          accountNumber: value?.accountNumber,
          ifscNumber: value?.ifscCode,
          accountProof: value?.proofUrl
        });
      }
    });
  const { data: refubBenificiaryData } = useGetAllRefurbishmentBeneficiaryQuery(
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const BankNameOptions = enumToItems(BankName);

  const [mode, setMode] = useState(true);
  const purchase_id =
    refurbishmentDetailsData?.queryLead?.[0]?.refurbishmentDetails
      ?.requests?.[0]?.purchase?.id;

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      beneficiary: null,
      accountNumber: '',
      bankName: null,
      bankTransfer: true,
      accountHolderName: '',
      ifscNumber: '',
      accountProof: '',
      remarks: '',
      upiId: '',
      paymentTo: 'employee'
    },
    enableReinitialize: true,
    validationSchema: RaisePaymentRequestSchemaRefurbishment,
    onSubmit: (values) => {
      if (values?.bankTransfer) {
        leadInput = {
          id: leadId,
          regNo: regNo,
          payments: [
            {
              mode: values.bankTransfer
                ? PaymentMethod.BankTransferNeft
                : PaymentMethod.Upi,
              paymentTo:
                values.paymentTo === 'employee'
                  ? PaymentTo.Employee
                  : PaymentTo.Vendor,
              status: PaymentStatus.Requested,
              refurbishmentBeneficiary: {
                id:
                  values?.beneficiary?.label === 'Add new Benificiary'
                    ? 'Add new Benificiary'
                    : values?.beneficiary?.value,
                bankName: values?.bankName?.value as BankName,
                accountHolderName: values?.accountHolderName,
                accountNumber: values?.accountNumber,
                ifscCode: values?.ifscNumber,
                proofUrl: values?.accountProof
              },
              for: PaymentFor.Refurbishment
            }
          ],
          refurbishmentDetails: {
            requests: [
              {
                id: refurbishmentRequestId,
                refurbishmentBeneficiary: {
                  id:
                    values?.beneficiary?.label === 'Add new Beneficiary'
                      ? 'Add new Benificiary'
                      : values?.beneficiary?.value,
                  bankName: values?.bankName?.value as BankName,
                  accountHolderName: values?.accountHolderName,
                  accountNumber: values?.accountNumber,
                  ifscCode: values?.ifscNumber,
                  proofUrl: values?.accountProof
                },
                purchase: {
                  id: purchase_id,
                  payment: {
                    mode: values.bankTransfer
                      ? PaymentMethod.BankTransferNeft
                      : PaymentMethod.Upi,
                    paymentTo: values.paymentTo
                      ? PaymentTo.Employee
                      : PaymentTo.Vendor,
                    for: PaymentFor.Refurbishment
                  }
                }
              }
            ]
          }
        };
      } else {
        leadInput = {
          id: leadId,
          regNo: regNo,
          payments: [
            {
              upiId: values.upiId,
              mode: values.bankTransfer
                ? PaymentMethod.BankTransferNeft
                : PaymentMethod.Upi,
              paymentTo:
                values.paymentTo === 'employee'
                  ? PaymentTo.Employee
                  : PaymentTo.Vendor,
              refurbishmentBeneficiary: values.bankTransfer
                ? null
                : {
                    proofUrl: values?.accountProof
                  },
              status: PaymentStatus.Requested,
              for: PaymentFor.Refurbishment,
              proofUrl: values?.accountProof
            }
          ],
          refurbishmentDetails: {
            requests: [
              {
                id: refurbishmentRequestId,
                refurbishmentBeneficiary: null,
                purchase: {
                  id: purchase_id,
                  payment: {
                    upiId: values.upiId,
                    for: PaymentFor.Refurbishment,
                    proofUrl: values?.accountProof,
                    mode: values.bankTransfer
                      ? PaymentMethod?.BankTransferNeft
                      : PaymentMethod?.Upi,
                    paymentTo: values.paymentTo
                      ? PaymentTo.Employee
                      : PaymentTo.Vendor
                  }
                }
              }
            ]
          }
        };
      }

      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });

  const accountHolderNameList =
    refubBenificiaryData?.queryRefurbishmentBeneficiary
      ?.map((item) => ({
        label: _.capitalize(item?.accountHolderName),
        value: item?.id,
        bank: {
          label: titleCaseToReadable(item?.bankName),
          value: item?.bankName
        },
        accountHolderName: item?.accountHolderName,
        accountNumber: item?.accountNumber,
        ifsc: item?.ifscCode,
        accountProof: item?.proofUrl,
        beneficiaryId: item?.id
      }))
      .concat([
        {
          label: 'Add new Beneficiary',
          value: null,
          bank: null,
          accountHolderName: '',
          accountNumber: '',
          ifsc: '',
          accountProof: '',
          beneficiaryId: null
        }
      ]) ?? [];
  if (loading) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center gap-4"
        style={{ height: '100vh' }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }


  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ fontSize: '17px' }}>
        <Grid item xs={12} md={12} style={{ marginLeft: '0.1rem' }}>
          Payment to*
        </Grid>
        <Grid item xs={12} md={12} style={{ marginLeft: '0.5rem' }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={formik.values.paymentTo}
              sx={{
                width: '300px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <FormControlLabel
                value="employee"
                control={<Radio />}
                onClick={() => {
                  formik.setFieldValue('paymentTo', 'employee');
                }}
                label="Employee"
              />
              <FormControlLabel
                value="vendor"
                onClick={() => {
                  formik.setFieldValue('paymentTo', 'vendor');
                }}
                control={<Radio />}
                label="Vendor"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <hr style={{ width: '100%', color: '#474141', padding: 0 }}></hr>
        <Grid
          item
          xs={12}
          md={12}
          mt={0}
          pt={0}
          style={{ marginLeft: '0.1rem' }}
        >
          Select Payment Mode*
        </Grid>
        <Grid item xs={12} md={12} style={{ marginLeft: '0.5rem' }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={'yesMode'}
              sx={{
                width: '300px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <FormControlLabel
                value="yesMode"
                control={<Radio />}
                onClick={() => {
                  formik.setValues({
                    ...formik.values,
                    upiId: null,
                    accountProof: '',
                    bankTransfer: true
                  });

                  // formik.setFieldValue('bankTransfer', true);
                }}
                label="Bank Transfer"
              />
              <FormControlLabel
                value="noMode"
                onClick={() => {
                  formik.setValues({
                    ...formik.values,
                    bankName: null,
                    accountHolderName: '',
                    beneficiary: null,
                    accountNumber: '',
                    ifscNumber: '',
                    accountProof: '',
                    bankTransfer: false
                  });
                  // formik.setFieldValue('bankTransfer', false);
                }}
                control={<Radio />}
                label="UPI"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {formik?.values?.bankTransfer && (
          <>
            <Grid item xs={12} md={12}>
              <InputAutoComplete
                onChange={(value) => {
                  if (value?.label !== 'Add new Beneficiary' && value) {
                    getBeneficiaryDetails({ variables: { id: value?.value } });
                    formik.setValues({
                      ...formik.values,
                      beneficiary: value
                    });
                  }
                  if (
                    value === null ||
                    value?.label === 'Add new Beneficiary'
                  ) {
                    formik.setValues({
                      ...formik.values,
                      bankName: null,
                      accountHolderName: '',
                      accountNumber: '',
                      ifscNumber: '',
                      accountProof: '',
                      beneficiary: value
                    });
                  }
                }}
                value={formik.values.beneficiary}
                placeholder="Select Beneficiary*"
                options={accountHolderNameList}
                optionString="label"
                getOptionLabel={(option) => option.name}
              />
              {formik?.values?.beneficiary &&
              (formik.errors.beneficiary || formik.touched.beneficiary) ? (
                <div style={{ color: 'red' }}>{formik.errors?.beneficiary}</div>
              ) : null}
            </Grid>

            <Grid item xs={12} md={12}>
              <InputAutoComplete
                onChange={(value) => {
                  formik.setFieldValue('bankName', value);
                }}
                value={formik.values.bankName}
                placeholder="Select the Bank Name*"
                options={BankNameOptions}
                optionString="label"
                getOptionLabel={(option) => option.name}
                disabled={
                  formik?.values?.beneficiary?.label !== 'Add new Beneficiary'
                }
              />
              {formik?.values?.bankName &&
              (formik.errors.bankName || formik.touched.bankName) ? (
                <div style={{ color: 'red' }}>{formik.errors?.bankName}</div>
              ) : null}
            </Grid>
            <Grid item md={12} xs={12}>
              <Input
                placeholder="Enter the Account holder Name*"
                value={formik?.values?.accountHolderName}
                onChange={(e) =>
                  formik.setFieldValue('accountHolderName', e?.target?.value)
                }
                disabled={
                  formik?.values?.beneficiary?.label !== 'Add new Beneficiary'
                }
              />
              {formik?.values?.accountHolderName &&
              (formik.errors.accountHolderName ||
                formik.touched.accountHolderName) ? (
                <div style={{ color: 'red' }}>
                  {formik.errors?.accountHolderName}
                </div>
              ) : null}{' '}
            </Grid>
            <Grid item md={12} xs={12}>
              <Input
                placeholder="Enter the Account Number*"
                value={formik?.values?.accountNumber}
                onChange={(e) =>
                  formik.setFieldValue('accountNumber', e?.target?.value)
                }
                disabled={
                  formik?.values?.beneficiary?.label !== 'Add new Beneficiary'
                }
              />
              {formik?.values?.accountNumber &&
              (formik.errors.accountNumber || formik.touched.accountNumber) ? (
                <div style={{ color: 'red' }}>
                  {formik.errors?.accountNumber}
                </div>
              ) : null}
            </Grid>
            <Grid item md={12} xs={12}>
              <Input
                value={formik?.values?.ifscNumber}
                onChange={(e) =>
                  formik.setFieldValue('ifscNumber', e?.target?.value)
                }
                placeholder="Enter IFSC code*"
                disabled={
                  formik?.values?.beneficiary?.label !== 'Add new Beneficiary'
                }
              />
              {formik.values.ifscNumber &&
              (formik.errors.ifscNumber || formik.touched.ifscNumber) ? (
                <div style={{ color: 'red' }}>{formik.errors?.ifscNumber}</div>
              ) : null}{' '}
            </Grid>
          </>
        )}

        {!formik?.values?.bankTransfer && (
          <>
            <Grid item md={12} xs={12}>
              <Input
                value={formik.values.upiId}
                onChange={(e) => {
                  formik.setFieldValue('upiId', e?.target?.value);
                }}
                placeholder="Enter UPI ID*"
              />
              {formik.values.upiId &&
              (formik.errors.upiId || formik.touched.upiId) ? (
                <div style={{ color: 'red' }}>{formik.errors?.upiId}</div>
              ) : null}{' '}
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12}>
          <Grid>
            {' '}
            <h5> Account Proof</h5>
          </Grid>
          <Grid mt={3}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="accountProof"
              value={formik.values.accountProof}
              onChange={(value) => {
                formik.setFieldValue('accountProof', value);
              }}
              placeholder="upload document*"
            />
            {formik.values.accountProof &&
            formik.errors.accountProof &&
            formik.touched.accountProof ? (
              <div style={{ color: 'red' }}>{formik.errors?.accountProof}</div>
            ) : null}
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="outlined-uncontrolled"
            autoComplete="off"
            label="Enter the remarks"
            onChange={(e) => formik.setFieldValue('remarks', e?.target?.value)}
            placeholder="Enter the remarks"
          />
        </Grid>
      </Grid>

      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        backBtn={canGoBack}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
};

export default RefurshimentRequestPayment;
