import { UserRole } from 'src/generated/hooks_and_more';
import * as Yup from 'yup';
const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const nameRegex = /^[A-Za-z\s'-]+$/;
const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
const addressRegex = /^[a-zA-Z0-9\s.,#-]+$/;

export const AddUser = Yup.object().shape({
  email: Yup.string().matches(email, 'Invalid email').required(),
  name: Yup.string().matches(nameRegex, 'Invalid name').required(),
  role: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required()
  }),
  center: Yup.object()
    .shape({
      name: Yup.string(),

      id: Yup.string()
    })
    .when('role', (role, schema) => {
      console.log(role, 'role');

      if (role?.[0]?.value === UserRole.CentreManager) {
        return schema.required('*Required');
      } else {
        return schema.notRequired();
      }
    })
});

export const EditUser = Yup.object().shape({
  name: Yup.string().matches(nameRegex, 'Invalid name').required(),
  role: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required()
  }),
  center: Yup.object()
    .shape({
      name: Yup.string().required(),

      id: Yup.string().required()
    })
    .when('role', (role, schema) => {
      console.log(role, 'role');

      if (role?.[0]?.value === UserRole.CentreManager) {
        return schema.required('*Required');
      } else {
        return schema.notRequired();
      }
    })
});

export const EditBankAuctionLeadSchema = Yup.object().shape({
  initialBidAmount: Yup.number()
    .max(5000000, 'Maximum value is 5000000')
    .typeError('*Must be a Number'),
  finalBidAmount: Yup.number()
    .max(5000000, 'Maximum value is 5000000')
    .typeError('*Must be a Number'),
  listingPrice: Yup.number()
    .max(5000000, 'Maximum value is 5000000')
    .typeError('*Must be a Number'),
  sellignPrice: Yup.number()
    .max(5000000, 'Maximum value is 5000000')
    .typeError('*Must be a Number')
});

export const EditActiveBookingAmount = Yup.object().shape({
  saleAmount: Yup.number()
    .max(5000000, 'Maximum value is 5000000')
    .typeError('*Must be a Number')
});

export const EditBookingRequestedAmount = Yup.object().shape({
  appliedLoanAmount: Yup.number()
    .max(5000000, 'Maximum value is 5000000')
    .typeError('*Must be a Number')
});
export const EditBankAuctionLeadExpense = Yup.object().shape({
  yardLocationURL: Yup.string()
    .matches(urlRegex, 'Must be a valid URL')
    .typeError('*Must Be a valid url'),
  yardSpocName: Yup.string()
    .matches(nameRegex, 'Invalid name')
    .typeError('*invalid name'),
  parkingPaymentByLM: Yup.number()
    .max(100000, 'Maximum value is 100000')
    .typeError('*Must be a Number'),
  parkingPaymentByDriver: Yup.number()
    .max(100000, 'Maximum value is 100000')
    .typeError('*Must be a Number'),
  yardAddress: Yup.string()
    .matches(addressRegex, 'invalid address')
    .typeError('*Must be valid address'),
  yardSpocMobile: Yup.string().matches(
    /^[0-9]{10}$/,
    'Mobile number must be 10 digits'
  ),
  yardName: Yup.string().required()
});
