import { Tooltip } from '@material-ui/core';
import { Info } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FollowupAdherenceQuery,
  SelectTypeOfDate,
  useEnquiryDashboardListLazyQuery
} from 'src/generated/hooks_and_more';
import { titleCaseToReadable } from 'src/utils/utility';
type props = {
  data: FollowupAdherenceQuery;
  header: any;
  start: Date | null;
  end: Date | null;
  key?: string;
  tab?: string;
  onClickExpand: (
    index: string,
    type: 'ADHERANCE_TABLE' | 'CENTRE_TABLE'
  ) => void;
};
function FollowupAdherance(props: props) {
  const { data, header, onClickExpand, start, end } = props;
  const adherenceData = data?.enquiryFollowupAdderence;
  const [searchParams] = useSearchParams();
  const [viewDetailLeads, setViewDetailLeads] = useState(false);
  const [selectedCentreEnquiry, setSelectedCentreEnquiry] = useState<string>();
  const [EnquiryDashboardList, { data: enquiryDashboardListData, loading }] =
    useEnquiryDashboardListLazyQuery();
  const navigate = useNavigate();
  useEffect(() => {
    const centerId = searchParams?.get('id');
    if (centerId) {
      setSelectedCentreEnquiry(centerId);
      EnquiryDashboardList({
        fetchPolicy: 'no-cache',
        variables: {
          start: start,
          end: end,
          dateType:
            start && end ? SelectTypeOfDate.EnquiryCreatedDateRange : null,
          centreId: centerId
        }
      });
    } else {
      setSelectedCentreEnquiry(null);
    }
  }, [searchParams, start, end]);
  let sumOfColumns: any = {};

  let rows = adherenceData?.map((i) => {
    if (Object.keys(sumOfColumns)?.length) {
      const ageingAvg =
        i.totalEnquiry !== 0
          ? Number(i?.AvgAging ?? 0) / Number(i?.totalEnquiry ?? 0)
          : 0;
      sumOfColumns = {
        ...sumOfColumns,
        Total_Followups: sumOfColumns?.Total_Followups + i?.totalFollowups,
        Opened_Enquiries: sumOfColumns?.Opened_Enquiries + i?.totalOpen,
        Closed_Followups: sumOfColumns?.Closed_Followups + i?.closedFollowups,
        Total_Enquiries: sumOfColumns?.Total_Enquiries + i?.totalEnquiry,
        Closed_Enquiries: sumOfColumns?.Closed_Enquiries + i?.totalClosed,
        ageing:
          sumOfColumns?.ageing +
          (isNaN(ageingAvg) ? 0 : Number(ageingAvg.toFixed(2)))
      };
    } else {
      const ageingAvg =
        i?.totalEnquiry !== 0
          ? Number(i?.AvgAging ?? 0) / Number(i?.totalEnquiry ?? 0)
          : 0;
      sumOfColumns = {
        Total_Followups: i?.totalFollowups,
        Opened_Enquiries: i?.totalOpen,
        Closed_Followups: i?.closedFollowups,
        Closed_Enquiries: i?.totalClosed,
        Total_Enquiries: i?.totalEnquiry,
        ageing: isNaN(ageingAvg) ? 0 : Number(ageingAvg.toFixed(2)),
        totalCount: adherenceData?.length
      };
    }
  });
  return (
    <>
      <table
        className="table table-striped table-bordered"
        style={{
          width: '100%'
        }}
      >
        <>
          <thead>
            <tr className="bg-secondary bg-gradient">
              {header?.map((item, index) =>
                item?.info ? (
                  <th
                    key={index}
                    scope="col"
                    className="text-center text-white"
                  >
                    {item?.title}
                    <Tooltip style={{ cursor: 'pointer' }} title={item?.info}>
                      <Info fontSize="small"></Info>
                    </Tooltip>
                  </th>
                ) : (
                  <th
                    key={index}
                    scope="col"
                    className="text-center text-white"
                  >
                    {item?.title}
                  </th>
                )
              )}
            </tr>
          </thead>

          <tbody>
            {adherenceData?.length ? (
              <>
                {adherenceData?.map((item, index) => {
                  const ageingAvg =
                    Number(item?.AvgAging ?? 0) /
                    Number(item?.totalEnquiry ?? 0);
                  const adherenceRatio =
                    item?.closedFollowups && item?.totalFollowups
                      ? Math.ceil(
                          (item?.closedFollowups / item?.totalFollowups) * 100
                        )
                      : 0;

                  return (
                    <>
                      <tr
                        key={index}
                        onClick={() => {
                          setViewDetailLeads(true);
                          onClickExpand(item?.centre?.id, 'CENTRE_TABLE');
                        }}
                      >
                        <td className="text-center">{item?.centre?.name}</td>
                        <td className="text-center">{item?.totalEnquiry}</td>
                        <td className="text-center">{item?.totalOpen}</td>
                        <td className="text-center">{item?.totalClosed}</td>
                        <td className="text-center">{item?.totalFollowups}</td>
                        <td className="text-center">{item?.closedFollowups}</td>
                        <td className="text-center">
                          {isNaN(ageingAvg) ? 0 : ageingAvg.toFixed(2)}
                        </td>
                        <td className="text-center">{adherenceRatio} %</td>
                      </tr>

                      {viewDetailLeads &&
                        (item?.centre?.id === selectedCentreEnquiry ? (
                          loading ? (
                            <tr style={{ height: '40px' }}>
                              <td colSpan={8}>
                                <CircularProgress
                                  sx={{
                                    textAlign: 'center',
                                    position: 'absolute',
                                    float: 'right',
                                    right: '50%'
                                  }}
                                  size={24}
                                />
                              </td>{' '}
                            </tr>
                          ) : (
                            <>
                              <tr className="JustDetailEnquiry">
                                <td
                                  colSpan={1}
                                  style={{
                                    textAlign: 'center',
                                    width: '25%',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  Customer Name
                                </td>
                                <td
                                  colSpan={3}
                                  style={{
                                    textAlign: 'center',
                                    width: '25%',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  State
                                </td>
                                <td
                                  colSpan={3}
                                  style={{
                                    textAlign: 'center',
                                    width: '25%',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  District
                                </td>
                                <td
                                  colSpan={3}
                                  style={{
                                    textAlign: 'center',
                                    width: '25%',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  Status
                                </td>
                              </tr>

                              {enquiryDashboardListData?.filteredEnquiry?.map(
                                (enquiry) => {
                                  return (
                                    <>
                                      <tr
                                        style={{ cursor: 'pointer' }}
                                        key={enquiry?.id}
                                        onClick={() => {
                                          setViewDetailLeads(true);
                                          navigate(
                                            `/vms-admin/enquiry/${enquiry.id}`,
                                            {
                                              state: {
                                                id: enquiry.id
                                              }
                                            }
                                          );
                                        }}
                                      >
                                        <td
                                          colSpan={1}
                                          style={{
                                            textAlign: 'center',
                                            minWidth: '25%'
                                          }}
                                        >
                                          {enquiry?.customer?.name}
                                        </td>
                                        <td
                                          colSpan={3}
                                          style={{
                                            textAlign: 'center',
                                            minWidth: '25%'
                                          }}
                                        >
                                          {titleCaseToReadable(
                                            enquiry?.customer?.customerState
                                              ?.state
                                          )}
                                        </td>
                                        <td
                                          colSpan={3}
                                          style={{
                                            textAlign: 'center',
                                            minWidth: '25%'
                                          }}
                                        >
                                          {titleCaseToReadable(
                                            enquiry?.customer?.customerDistrict
                                              ?.name
                                          )}
                                        </td>

                                        <td
                                          colSpan={3}
                                          style={{
                                            textAlign: 'center',
                                            minWidth: '25%'
                                          }}
                                        >
                                          {titleCaseToReadable(enquiry?.status)}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                            </>
                          )
                        ) : null)}
                    </>
                  );
                })}
                <tr
                  style={{ position: 'sticky', bottom: 0, cursor: 'pointer' }}
                  className="bg-secondary bg-gradient"
                >
                  <td className="text-center fw-bolder text-white">Total</td>
                  {[sumOfColumns]?.map((item, index) => {
                    return (
                      <>
                        <td
                          className="text-center fw-bolder text-white"
                          key={index}
                        >
                          {item?.Total_Enquiries}
                        </td>
                        <td
                          className="text-center fw-bolder text-white"
                          key={index}
                        >
                          {item?.Opened_Enquiries}
                        </td>
                        <td
                          className="text-center fw-bolder text-white"
                          key={index}
                        >
                          {item?.Closed_Enquiries}
                        </td>
                        <td
                          className="text-center fw-bolder text-white"
                          key={index}
                        >
                          {item?.Total_Followups}
                        </td>

                        <td
                          className="text-center fw-bolder text-white"
                          key={index}
                        >
                          {item?.Closed_Followups}
                        </td>
                        <td
                          className="text-center fw-bolder text-white"
                          key={index}
                        >
                          {item?.totalCount !== 0
                            ? (item?.ageing / item?.totalCount).toFixed(2)
                            : 0}
                        </td>
                      </>
                    );
                  })}
                  <td className="text-center fw-bolder text-white">
                    {sumOfColumns?.Total_Followups &&
                    sumOfColumns?.Closed_Followups
                      ? Number(
                          (
                            sumOfColumns?.Closed_Followups /
                            sumOfColumns?.Total_Followups
                          ).toFixed(2)
                        ) * 100
                      : 0}
                    %
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td className="text-center" colSpan={header?.length}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </>
      </table>
    </>
  );
}

export default FollowupAdherance;
