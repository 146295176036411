import React, { Fragment, useState, ChangeEvent, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ActionType,
  BookingType,
  InternalTransferStatus,
  LeadSource,
  LeadStatus,
  ListingStatus,
  ModuleType,
  PaymentFor,
  PaymentStatus,
  TaskStatus,
  UserRole,
  useAddLogsMutation,
  useCloseLeadsMutation,
  useCloseTaskListMutation,
  useGetLeadDetailQuery,
  useGetTaskIdLazyQuery,
  useGetTaskIdQuery,
  useLeadDocumentCountQuery,
  useLogTrackingQuery
} from '../../generated/hooks_and_more';
import { useParams } from 'react-router-dom';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Typography,
  Button,
  Grid,
  Box,
  Tabs,
  Tab,
  Popover
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import ApplicationDetails from './component/ApplicationDetails';
import VehicleDetails from './component/VehicleDetails';
import DocumentsDetails from './component/DocumentsDetails';
import LogisticsDetails from './component/LogisticsDetails';
import RTOVerification from './component/RTOVerification';
import DealDetails from './component/DealDetails';
import PurchaseOrderDetails from './component/PurchaseOrderDetails';
import DealershipDealDetails from './component/DealershipDealDetails';
import LeadImage from './component/LeadImage';
import PricingAndListingDetails from './component/PricingAndListingDetails';
import ListingDetails from './component/ListingDetails';
import { getUserData } from 'src/utils/utility';
import BookingDetails from './component/BookingDetails';
import LoanApplicationDetails from './component/LoanApplicationDetails';
import TimelineStatus from 'src/components/Timeline/Timeline';
import { useToasts } from 'react-toast-notifications';
import ConfirmLeadDeletion from '../../components/Composite/ConfirmationModal/LeadCloseConfirmation';
import './leadDetail.css';
import DocumentTimeline from 'src/components/Timeline/DocumentTimeline';
import DocumentTable from '../documents/DocumentTable';
import PaymentDetails from './component/PaymentDetails';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
import LeadProcess from '../lead-flow';
import InternalTransferDetails from './component/InternalTransferDetails';
import AccessoriesChecklist from './component/AccessoriesChecklist';
import useCreateLeadStatusEvents from '../lead-flow/hooks/useCreateLeadStatusEvents';
import ModalForListing from '../lead-flow/form/ListingTractor/ModalforListing';
import RefurbishmentDetailList from './component/Refurbishment';

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    },
    .css-e72053-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled{
      display:none;
    }
     .MuiTabs-scrollableX {
      background-color:#dfdfdf; 
    }
    .MuiTabs-scrollButtons {
       background-color:#5569ff;
       opacity:1;
      border-radius:0;
      color:white;
    }

`
);

export default function LeadDetail() {
  const { leadId } = useParams();
  const { loggedInUser } = useLoggedInUser();
  const [open, setOpen] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [openListing, setListing] = useState(false);

  const [raisePayment, setRaisePayment] = useState(false);
  const [enablePartPayment, setEnablePartPayment] = useState(false);
  const [dropOpen, setDropOpen] = useState(false);
  const [updateLead, setUpdateLead] = useState(false);
  const [counts, setCounts] = useState<any>(null);
  const { addToast } = useToasts();
  const [openDelete, setOpenDelete] = useState(false);
  const [closeLeads, { loading: closeLoading }] = useCloseLeadsMutation();
  const [closeEventStatus] = useCloseTaskListMutation();
  const [refurbishmentButton, setRefurbishmentButton] = useState(false);
  const [cancelBooking, setCancelBooking] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [bookingUpdate, setBookingUpdate] = useState(false);
  const [editSaleAmount, setEditSaleAmount] = useState(false);
  const [statusBookingOpen, setstatusBookingOpen] = useState(false);
  const [sellingPriceUpdate, setSellingPriceUpdate] = useState(false);
  const [loanDrop, setLoanDrop] = useState(false);
  const [spandldview, setSPandLDopen] = useState(false);
  const [AddLogs] = useAddLogsMutation();

  const {
    data,
    loading,
    error,
    refetch: refetchLeadDetails
  } = useGetLeadDetailQuery({
    fetchPolicy: 'no-cache',
    variables: {
      regNo: leadId
    }
  });

  const [currentTab, setCurrentTab] = useState(
    location?.pathname.includes('documents')
      ? 'Document Details'
      : 'Vehicle Images'
  );

  const [value, setValue] = useState(
    location?.pathname?.includes('documents') ? 'document' : 'lead'
  );

  const currentLead = data && data?.getLead;
  const regNo = currentLead?.regNo;
  const state: { lseId?: string } = location.state;
  let lseId = state?.lseId;
  const {
    data: dataCount,
    refetch,
    loading: dataLoading
  } = useLeadDocumentCountQuery({
    variables: {
      regNo: leadId
    },
    onCompleted() {
      calculateTrueValuesCount();
    }
  });

  const [params] = useSearchParams();
  useEffect(() => {
    if (params.get('t')) {
      refetchLeadDetails();
      refetch();
    }
  }, [params]);

  const calculateTrueValuesCount = () => {
    let required = 0;
    let received = 0;

    dataCount?.queryLead?.slice(0, 1)?.map((lead) => {
      const documentChecklist = lead?.documentChecklist;
      const sourceCheck = lead?.source;
      const documents = lead?.documents;

      if (documentChecklist) {
        Object.keys(documentChecklist).map((i) => {
          if (documentChecklist?.[i] === true) {
            if (sourceCheck === LeadSource.DealershipSale) {
              if (i !== 'indemnityBond' && i !== 'releaseOrder') {
                required += 1;
                received = documents?.[i] ? received + 1 : received;
              } else {
                // do not include these documents to document check list
              }
            } else {
              required += 1;
              received = documents?.[i] ? received + 1 : received;
            }
          }
        });
      } else {
        if (lead?.source === 'BANK_AUCTION' && documentChecklist === null) {
          if (documents !== null) {
            Object.values(documents).map((i) => {
              if (i !== null && i !== 'LeadDocuments' && i !== '') {
                received = received + 1;
              }
            });
          }
          required += 15;
        } else if (
          lead?.source === 'DEALERSHIP_SALE' &&
          documentChecklist === null
        ) {
          if (documents !== null) {
            Object.values(documents).map((i) => {
              if (i !== null && i !== 'LeadDocuments' && i !== '') {
                received = received + 1;
              }
            });
          }
          required += 13;
        }
      }
    });

    const pending = required - received;
    const countOutput = { required, received, pending };
    setCounts({ ...countOutput });
    return countOutput;
  };

  const followUpData = dataCount?.queryLead?.map((item) => {
    return item?.documentsFollowups;
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [roleForPayment, setRoleForPaymentInPart] = useState('SHOW');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  function onPressViewImagesButton() {
    setCurrentTab('Vehicle Images');
    setOpen(false);
  }

  useEffect(() => {
    if (location.pathname.includes('booking')) {
      setCurrentTab('Booking Details');
    }
    return () => {};
  }, []);

  if (loading) {
    return (
      <div
        className="d-flex flex-column height-100 justify-content-center align-items-center gap-4"
        style={{ height: '80vh' }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  } else if (error || !currentLead) {
    return (
      <div className="d-flex flex-column height-100 justify-content-center align-items-center">
        <h5>!!! Error !!!</h5>
      </div>
    );
  }

  const transferlength = data?.getLead?.internalTransfers.length;

  const leadsTabs = [
    'Vehicle Images',
    'Application Details',
    'Vehicle Details',
    'Document Details',
    'Procurement Logistics Details',
    'Accessories Checklist',
    'RTO Verification Details',
    'Internal Transfer Details',
    'Deal Details',
    'Purchase Order details',
    'Pricing and Listing details',
    'Refurbishment Request Details',
    'Booking Details',
    'Payment Details',
    'Listing Details',
    'Loan Application Details'
  ];

  const source = data?.getLead?.source;

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const checkForLeadUpdateButton = () => {
    if (
      userDetails.role === UserRole.ProcurementExecutive ||
      userDetails.role === UserRole.ProcurementHead
    ) {
      return false;
    } else {
      return true;
    }
  };
  // UKNIKITABOKING
  const checkRecentState = () => {
    let state = true;
    for (let i = 0; i < statusArr.length; i++) {
      if (statusArr[i] === LeadStatus.LeadDropped) {
        state = false;
        break;
      } else if (statusArr[i] === LeadStatus.LeadUpdated) {
        state = true;
        break;
      }
    }
    return state;
  };

console.log(currentLead?.regNo?.toUpperCase(), "currentLead?.regNo?.toUpperCase()")
  function onConfirmDelete() {
    const id = currentLead?.id;
    const statusId = currentLead?.statusEvents.map((item) => item.id);

    closeLeads({
      variables: { id: [id] },
      onCompleted() {
        closeEventStatus({
          variables: { id: statusId },
          onCompleted() {
            setOpenDelete(false);
            AddLogs({
              variables: {
                taskPerformedBy: { id: loggedInUser?.userId },
                actionType: ActionType.Delete,
                module: ModuleType.Procurement,
                createdAt: new Date(),
                sourceUpdated: currentLead?.regNo.toUpperCase(),
                updatedValue: JSON.stringify(currentLead?.regNo.toUpperCase()),
                previousValue: '',
                status: TaskStatus.Success
              },
              onCompleted(data) {}
            });
            addToast('Lead Deleted Successfully!!!', {
              appearance: 'success',
              autoDismiss: true
            });

            navigate(-1);
          },
          onError() {
            AddLogs({
              variables: {
                taskPerformedBy: { id: loggedInUser?.userId },
                actionType: ActionType.Delete,
                module: ModuleType.Procurement,
                createdAt: new Date(),
                sourceUpdated: currentLead?.regNo?.toUpperCase(),
                updatedValue: JSON.stringify(currentLead?.regNo.toUpperCase()),
                previousValue: '',
                status: TaskStatus.Failed
              },
              onCompleted(data) {}
            });
            addToast('Something went wrong!!!', {
              appearance: 'error',
              autoDismiss: true
            });
          }
        });
      }
    });
  }

  const userDetails = getUserData();
  const statuses = data?.getLead?.statusEvents;

  const scopedEvent = !lseId
    ? statuses?.[0]
    : statuses?.find((lse) => lse.id === lseId);

  function checkTaskAssignedToRole() {
    if (
      !lseId &&
      loggedInUser.role === UserRole.Driver &&
      scopedEvent?.status?.includes('TRANSFER') &&
      scopedEvent?.assignTaskTo === loggedInUser?.role &&
      !scopedEvent?.isTaskCompleted
    ) {
      const length = data?.getLead?.internalTransfers?.length - 1;
      const renderButton =
        data?.getLead?.internalTransfers?.[length]?.assignedTo?.id ===
        loggedInUser?.id;
      return renderButton;
    }
    return (
      scopedEvent?.assignTaskTo === loggedInUser?.role &&
      !scopedEvent?.isTaskCompleted
    );
  }

  const statusArr = [];

  function checkTransferValue() {
    return loggedInUser?.role === UserRole.LogisticsManager &&
      !statuses?.some(
        (event) => event?.status === LeadStatus.DeliveryPhotoUploaded
      ) &&
      statuses?.some((event) => event?.status === LeadStatus.VehicleInStock)
      ? data?.getLead?.internalTransfers?.length > 0 &&
          data?.getLead?.internalTransfers?.[
            data?.getLead?.internalTransfers?.length - 1
          ]?.status !== InternalTransferStatus.Transferred
      : true;
  }
  const checkForSomeBooking = statuses?.some(
    (i) => i?.status === LeadStatus?.BookingDetailsAdded
  );
  const checkForVehicleInspect = statuses?.some(
    (i) => i.status === LeadStatus.DeliveryPhotoUploaded
  );

  const checkForVehicleInspect1 = statuses?.some(
    (i) => i.status === LeadStatus.VehicleInspected
  );
  const checkForVehicleInspect2 = statuses?.some(
    (i) => i.status === LeadStatus.VehicleInStock
  );
  const checkForBookingToker = currentLead?.activeBooking?.payments?.some(
    (i) =>
      i?.for === PaymentFor?.BookingToken &&
      i?.status === PaymentStatus.Approved
  );

  function checkForpayment() {
    if (
      loggedInUser?.role === UserRole.CentreManager &&
      checkForBookingToker &&
      !statusArr?.includes(LeadStatus.DeliveryPhotoUploaded)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function checkForref() {
    if (checkForVehicleInspect || !checkForVehicleInspect2) {
      return true;
    } else {
      return false;
    }
  }

  const bookingStatus = data?.getLead?.statusEvents;

  data?.getLead?.statusEvents?.map((item) => statusArr.push(item?.status));
  const leadDropCondtion = () => {
    let result = false;
    if (
      userDetails.role === UserRole.ProcurementExecutive ||
      userDetails.role === UserRole.ProcurementHead
    ) {
      if (
        data?.getLead?.source === LeadSource.BankAuction &&
        statusArr?.includes(LeadStatus.PaymentConfirmed)
      ) {
        result = false;
      } else if (
        statusArr?.includes(LeadStatus.DealTokenPaymentConfirmed) ||
        statusArr?.includes(LeadStatus.DealerPaymentConfirmed) ||
        statusArr?.includes(LeadStatus.HoldbackRepaymentConfirmed) ||
        statusArr?.includes(LeadStatus.LoanRepaymentConfirmed) ||
        statusArr?.includes(LeadStatus.DealDeliveryPaymentConfirmed)
      ) {
        result = false;
      } else {
        result = true;
      }
    } else if (
      userDetails.role === UserRole.Admin ||
      userDetails.role === UserRole.SuperAdmin
    ) {
      if (statusArr?.includes(LeadStatus.VehicleInStock)) {
        result = false;
      } else {
        result = true;
      }
    }
    return result;
  };

  const isSH = loggedInUser?.role === UserRole.SalesHead;

  const enableUpdateSellingPrice =
    isSH &&
    (statusArr?.some((event) => event === LeadStatus.VehicleInStock) ||
      statusArr?.some((event) => event === LeadStatus.DeliverySelfieUploaded));

  const isVehicleDeliveryCompleted = statusArr?.some(
    (event) => event === LeadStatus.DeliveryPhotoUploaded
  );

  let currentStatus = scopedEvent?.status;

  function addPartPayments() {
    setEnablePartPayment(true);
    setAnchorEl(null);
  }
  function updatesellingprice() {
    setSellingPriceUpdate(true);
    setAnchorEl(null);
    setSPandLDopen(true);
  }

  function handlerefurbishment() {
    setRefurbishmentButton(true);
    setAnchorEl(null);
  }

  function handlePaymentRaise() {
    setRaisePayment(true);
    setAnchorEl(null);
  }

  function checkRefurbishmentUserRole() {
    if (
      userDetails.role === UserRole.CentreManager ||
      userDetails.role === UserRole.SalesHead ||
      userDetails.role === UserRole.FinanceManager ||
      userDetails.role === UserRole.FinanceExecutive ||
      userDetails.role === UserRole.RetailFinanceManager ||
      userDetails.role === UserRole.SalesManager ||
      userDetails.role === UserRole.Admin ||
      userDetails.role === UserRole.SuperAdmin
    ) {
      return true;
    } else {
      return false;
    }
  }

  function checkPurchaseOrderDetailRole() {
    if (
      userDetails.role === UserRole.FinanceManager ||
      userDetails.role === UserRole.FinanceExecutive ||
      userDetails.role === UserRole.LogisticsManager ||
      userDetails.role === UserRole.OperationsHead ||
      userDetails.role === UserRole.OperationsManager ||
      userDetails.role === UserRole.ProcurementExecutive ||
      userDetails.role === UserRole.ProcurementManager ||
      userDetails.role === UserRole.ProcurementHead ||
      userDetails.role === UserRole.SalesHead ||
      userDetails.role === UserRole.RetailFinanceManager ||
      userDetails.role === UserRole.SalesManager ||
      userDetails.role === UserRole.Admin
    ) {
      return true;
    } else {
      return false;
    }
  }
 
  const CANCEL_BOOKING_ROLES = [UserRole.CentreManager];

  // !CANCEL_BOOKING_ROLES?.includes(loggedInUser?.role) ||
  //  !currentLead?.activeBooking?.id ||
  // statusArr?.some(
  //   lse => lse?.status === LeadStatus.DeliveryPhotoUploaded,
  // )
  function checkPricingAndListing() {
    if (
      userDetails.role === UserRole.CentreManager ||
      userDetails.role === UserRole.FinanceExecutive ||
      userDetails.role === UserRole.RetailFinanceManager ||
      userDetails.role === UserRole.SalesManager ||
      userDetails.role === UserRole.Admin ||
      userDetails.role === UserRole.SalesHead ||
      userDetails.role === UserRole.FinanceManager
    ) {
      return true;
    } else {
      return false;
    }
  }
  function checkforDealDetail() {
    if (
      userDetails.role === UserRole.Driver ||
      userDetails.role === UserRole.FinanceExecutive ||
      userDetails.role === UserRole.LogisticsManager ||
      userDetails.role === UserRole.SalesManager ||
      userDetails.role === UserRole.Admin ||
      userDetails.role === UserRole.SalesHead ||
      userDetails.role === UserRole.FinanceManager ||
      userDetails.role === UserRole.RetailFinanceManager ||
      userDetails.role === UserRole.OperationsManager ||
      userDetails.role === UserRole.ProcurementManager ||
      userDetails.role === UserRole.ProcurementExecutive ||
      userDetails.role === UserRole.ProcurementHead
    ) {
      return true;
    } else {
      return false;
    }
  }
  function checkForEditBooking() {
    return (
      !Boolean(loggedInUser.role === UserRole.CentreManager) ||
      !currentLead?.activeBooking?.id ||
      bookingStatus?.some(
        (event) =>
          event?.status === LeadStatus.BookingDeliveryPaymentReviewApproved &&
          event?.metadata?.split(':')[1] === currentLead?.activeBooking?.id
      ) ||
      statusArr?.includes(LeadStatus.DeliveryPhotoUploaded)
    );
  }
  const activeLoanStatus = currentLead?.statusEvents
    ?.filter(
      (i) =>
        i?.status === LeadStatus.BookingLoanFIAdded ||
        i?.status === LeadStatus.BookingLoanFIPassed ||
        i?.status === LeadStatus.BookingLoanDOApproved ||
        i?.status === LeadStatus.BookingLoanFileLoggedIn ||
        i?.status === LeadStatus.BookingLoanPaymentRejected ||
        i?.status === LeadStatus.BookingLoanPaymentReviewed
    )

    ?.some((j) => j?.isTaskCompleted === null)
    ? true
    : false;

  function isLoanDropButtonDisable() {
    if (
      activeLoanStatus &&
      currentLead?.activeBooking?.bookingPayment?.bookingType ===
        BookingType.Finance &&
      loggedInUser?.role === UserRole.RetailFinanceManager
    ) {
      return true;
    } else if (
      !Boolean(activeLoanStatus) &&
      currentLead?.activeBooking?.bookingPayment?.bookingType ===
        BookingType.Finance &&
      loggedInUser?.role === UserRole.RetailFinanceManager
    ) {
      return false;
    } else {
      return true;
    }
  }
  // const isLoanDropButtonDisable =
  //   currentLead?.activeBooking?.bookingPayment?.bookingType ===
  //     BookingType.Finance &&
  //   loggedInUser?.role === UserRole.RetailFinanceManager &&
  //   !activeLoanStatus;

  return (
    <Fragment>
      {open && (
        <LeadProcess
          onPressViewImagesButton={onPressViewImagesButton}
          open={open}
          lseId={scopedEvent?.id}
          setOpen={(item) => {
            setOpen(item);
            setDropOpen(false);
          }}
          currentStatus={
            dropOpen
              ? LeadStatus?.LeadDropInitiator
              : updateLead
              ? LeadStatus.LeadUpdateInitiator
              : currentStatus
          }
          leadId={currentLead?.id}
          regNo={regNo}
          source={source}
          currentLead={currentLead}
        />
      )}

      {openTransfer && (
        <LeadProcess
          open={openTransfer}
          setOpen={setOpenTransfer}
          currentStatus={LeadStatus.VehicleInternalTransferInitiator}
          leadId={currentLead?.id}
          regNo={regNo}
          source={source}
        />
      )}

      {enablePartPayment && (
        <LeadProcess
          open={enablePartPayment}
          setOpen={setEnablePartPayment}
          currentStatus={LeadStatus.BookingPartPaymentInitiator}
          regNo={regNo}
          source={source}
          lseId=""
        />
      )}
      <ModalForListing
        open={openListing}
        setOpen={setListing}
        leadId={currentLead?.id}
        regNo={regNo}
        source={source}
      />

      {/* <LeadProcess
        open={enablePartPayment}
        setOpen={setEnablePartPayment}
        currentStatus={LeadStatus.BookingCancelledInitiator}
        leadId={currentLead?.id}
        regNo={regNo}
        source={source}
        
      /> */}

      {cancelBooking && (
        <LeadProcess
          open={cancelBooking}
          setOpen={setCancelBooking}
          currentStatus={LeadStatus.BookingCancelledInitiator}
          leadId={currentLead?.id}
          regNo={regNo}
          source={source}
          currentLead={currentLead}
        />
      )}

      {refurbishmentButton && (
        <LeadProcess
          open={refurbishmentButton}
          setOpen={setRefurbishmentButton}
          currentStatus={LeadStatus.VehicleInStock}
          regNo={regNo}
          leadId={currentLead?.id}
          source={source}
          lseId={lseId === '' ? '' : scopedEvent?.id}
        />
      )}
      {/* update saling price */}
      {
        <LeadProcess
          open={spandldview}
          setOpen={() => {
            setSPandLDopen(false);
            setLoanDrop(false);
          }}
          currentStatus={
            sellingPriceUpdate
              ? LeadStatus.UpdateSellingPriceInitiator
              : loanDrop
              ? LeadStatus.LoanCancelledInitiator
              : null
          }
          regNo={regNo}
          leadId={currentLead?.id}
          source={source}
        />
      }

      {/* for booking edit sale payment*/}
      {statusBookingOpen && (
        <LeadProcess
          open={statusBookingOpen}
          setOpen={(item) => {
            setOpen(item);
            setstatusBookingOpen(false);
            setEditSaleAmount(false);
          }}
          currentStatus={
            editSaleAmount
              ? LeadStatus.BookingPaymentStructureUpdateInitiator
              : bookingUpdate
              ? LeadStatus.BookingDetailsUpdateInitiator
              : null
          }
          regNo={regNo}
          leadId={currentLead?.id}
          source={source}
        />
      )}

      {/* for edit sale payment */}
      {/* <LeadProcess
        open={statusBookingOpen}
        setOpen={(item) => {
          setOpen(item);
          setstatusBookingOpen(false);
          setEditSaleAmount(false);
        }}
        currentStatus={
          editSaleAmount
            ? LeadStatus.BookingPaymentStructureUpdateInitiator
            : bookingUpdate
            ? LeadStatus.BookingDetailsUpdateInitiator
            : null
        }
        regNo={regNo}
        leadId={currentLead?.id}
        source={source}
      /> */}

      <PageTitleWrapper>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          padding="10px"
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px'
            }}
          >
            <Typography variant="h3" component="h3" gutterBottom>
              Leads Detail {`(${currentLead?.regNo})`}
            </Typography>
          </Grid>
          <Grid>
            {!location?.pathname.includes('documents') ? (
              <>
                <ConfirmLeadDeletion
                  handleClose={() => setOpenDelete(false)}
                  onConfirmDelete={onConfirmDelete}
                  open={openDelete}
                />
                <Grid item sx={{ display: 'flex', gap: '10px' }}>
                  {checkTaskAssignedToRole() && scopedEvent?.taskButtonTitle && (
                    <Button
                      sx={{ mt: { xs: 2, md: 0 } }}
                      variant="contained"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      {scopedEvent?.taskButtonTitle ?? ''}
                    </Button>
                  )}

                  <Button
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                  >
                    Lead Actions
                  </Button>

                  <Popover
                    id={id}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0.8rem',
                        gap: '0.4rem'
                      }}
                    >
                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        disabled={
                          userDetails?.role !== UserRole?.Admin &&
                          userDetails?.role !== UserRole?.SuperAdmin
                        }
                        onClick={() => {
                          navigate(`/vms-admin/leads/edit/${regNo}`);
                        }}
                      >
                        Edit Lead
                      </Button>
                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        color="error"
                        disabled={userDetails?.role !== UserRole?.SuperAdmin}
                        onClick={() => {
                          if (
                            !currentLead?.activeBooking &&
                            userDetails?.role === UserRole?.SuperAdmin
                          ) {
                            setOpenDelete(true);
                          } else {
                            setAnchorEl(null);
                            AddLogs({
                              variables: {
                                taskPerformedBy: { id: loggedInUser?.userId },
                                actionType: ActionType.Delete,
                                module: ModuleType.Procurement,
                                createdAt: new Date(),
                                sourceUpdated: currentLead?.regNo?.toUpperCase(),
                                updatedValue: JSON.stringify("Can't delete, booking is available!!!"),
                                previousValue: '',
                                status: TaskStatus.Failed
                              },
                              onCompleted(data) {}
                            });
                            addToast("Can't delete, booking is available!!!", {
                              appearance: 'error',
                              autoDismiss: true
                            });
                          }
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        onClick={() => {
                          addPartPayments();
                        }}
                        disabled={!checkForpayment()}
                      >
                        Add Payment
                      </Button>
                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        onClick={() => {
                          setLoanDrop(true);
                          setAnchorEl(null);
                          setSPandLDopen(true);
                        }}
                        disabled={isLoanDropButtonDisable()}
                      >
                        Loan Drop
                      </Button>

                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        disabled={
                          !statuses?.some(
                            (event) =>
                              event?.status === LeadStatus.ReadyForSale ||
                              event?.status === LeadStatus.SellingPriceUpdated
                          ) ||
                          loggedInUser.role !== UserRole.CentreManager ||
                          data?.getLead?.listingStatus ===
                            ListingStatus.ListedOnWebsite ||
                          !Boolean(
                            loggedInUser?.role === UserRole.CentreManager &&
                              loggedInUser?.centre?.name ===
                                currentLead?.centre?.name
                          )
                        }
                        onClick={() => {
                          setAnchorEl(null);
                          setListing(true);
                        }}
                      >
                        Listing on Website
                      </Button>
                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        onClick={() => {
                          setOpenTransfer(true);
                          setAnchorEl(null);
                        }}
                        disabled={checkTransferValue()}
                      >
                        Internal Transfer
                      </Button>
                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        onClick={() => {
                          updatesellingprice();
                        }}
                        disabled={
                          !enableUpdateSellingPrice ||
                          isVehicleDeliveryCompleted
                        }
                      >
                        Update Selling Price
                      </Button>

                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        onClick={() => {
                          setBookingUpdate(true);
                          setAnchorEl(null);
                          setstatusBookingOpen(true);
                        }}
                        disabled={
                          checkForEditBooking() ||
                          statusArr.includes(
                            LeadStatus.BookingDeliveryRequested
                          )
                        }
                      >
                        Edit Booking
                      </Button>

                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        onClick={() => {
                          setEditSaleAmount(true);
                          setAnchorEl(null);
                          setstatusBookingOpen(true);
                        }}
                        disabled={
                          !Boolean(
                            loggedInUser.role === UserRole.CentreManager
                          ) ||
                          !currentLead?.activeBooking?.id ||
                          !currentLead?.activeBooking?.bookingPayment?.id ||
                          bookingStatus?.some(
                            (event) =>
                              event?.status ===
                                LeadStatus.BookingDeliveryPaymentReviewApproved &&
                              event?.metadata?.split(':')[1] ===
                                currentLead?.activeBooking?.id
                          ) ||
                          !bookingStatus?.some(
                            (event) =>
                              event?.status ===
                                LeadStatus.PaymentStructureAdded &&
                              event?.metadata?.split(':')[1] ===
                                currentLead?.activeBooking?.id
                          ) ||
                          statusArr?.includes(
                            LeadStatus.DeliveryPhotoUploaded
                          ) ||
                          statusArr?.includes(
                            LeadStatus.DeliveryPhotoUploaded
                          ) ||
                          statusArr.includes(
                            LeadStatus.BookingDeliveryRequested
                          )
                        }
                      >
                        Edit Sale Payment
                      </Button>

                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        onClick={() => {
                          setCancelBooking(true);
                          setAnchorEl(null);
                        }}
                        disabled={
                          !CANCEL_BOOKING_ROLES?.includes(loggedInUser?.role) ||
                          !currentLead?.activeBooking?.id ||
                          statusArr.includes(
                            LeadStatus.DeliveryPhotoUploaded
                          ) ||
                          statusArr.includes(
                            LeadStatus.BookingLoanPaymentReviewed
                          ) ||
                          statusArr.includes(
                            LeadStatus.BookingDeliveryRequested
                          )
                        }
                      >
                        Cancel Booking
                      </Button>
                      {/* <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        variant="outlined"
                        onClick={() => {
                          setCancelBooking(true);
                          setAnchorEl(null);
                        }}
                        disabled={
                          !CANCEL_BOOKING_ROLES?.includes(loggedInUser?.role) ||
                          !currentLead?.activeBooking?.id ||
                          statusArr.includes(
                            LeadStatus.DeliveryPhotoUploaded
                          ) ||
                          statusArr.includes(
                            LeadStatus.BookingLoanPaymentReviewed
                          )
                        }
                      >
                        Cancel booking
                      </Button> */}
                      {
                        <Button
                          sx={{ mt: { xs: 1, md: 0 } }}
                          style={{ justifyContent: 'flex-start' }}
                          variant="outlined"
                          color="error"
                          disabled={!(leadDropCondtion() && checkRecentState())}
                          onClick={() => {
                            setAnchorEl(null);
                            setOpen(true);
                            setDropOpen(true);
                          }}
                        >
                          Lead Drop
                        </Button>
                      }
                      {
                        <Button
                          sx={{ mt: { xs: 1, md: 0 } }}
                          style={{ justifyContent: 'flex-start' }}
                          variant="outlined"
                          disabled={
                            checkRecentState() || checkForLeadUpdateButton()
                          }
                          onClick={() => {
                            setAnchorEl(null);
                            setOpen(true);
                            setUpdateLead(true);
                          }}
                        >
                          Update Lead
                        </Button>
                      }

                      <Button
                        sx={{ mt: { xs: 1, md: 0 } }}
                        style={{ justifyContent: 'flex-start' }}
                        variant="outlined"
                        onClick={() => {
                          handlerefurbishment();
                        }}
                        disabled={
                          checkForref() ||
                          !Boolean(loggedInUser.role === UserRole.CentreManager)
                        }
                      >
                        Raise Refurbishment Request
                      </Button>
                    </Grid>
                  </Popover>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </PageTitleWrapper>

      <Grid container spacing={1}>
        <Grid
          item
          xs={8.85}
          sx={{
            '&.MuiGrid-item ': {
              paddingTop: 0
            }
          }}
        >
          <Box sx={{ px: 3, pb: 2 }}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {leadsTabs?.map((tab, index) => {
                if (
                  tab === 'Purchase Details' &&
                  source === LeadSource.BankAuction
                ) {
                  return null;
                } else if (
                  tab === 'Accessories Checklist' &&
                  !currentLead?.accessories?.length
                ) {
                  return null;
                }

                if (checkRefurbishmentUserRole() === true) {
                  if (!checkForSomeBooking && tab === 'Booking Details') {
                    return (tab = '');
                  }
                } else if (
                  checkRefurbishmentUserRole() === false &&
                  tab === 'Booking Details'
                ) {
                  return (tab = '');
                }

                if (
                  !currentLead?.activeBooking?.bookingPayment &&
                  !currentLead?.activeBooking?.isInsuranceReq &&
                  !currentLead?.activeBooking?.isRCTransferReq &&
                  tab === 'Payment Details'
                ) {
                  return (tab = '');
                }
                if (
                  currentLead?.activeBooking === null &&
                  tab === 'Loan Application Details'
                ) {
                  return (tab = '');
                }

                if (checkRefurbishmentUserRole() === false) {
                  if (tab === 'Refurbishment Request Details') {
                    return (tab = '');
                  }
                } else if (checkRefurbishmentUserRole() === true) {
                  if (
                    !checkForVehicleInspect1 &&
                    tab === 'Refurbishment Request Details'
                  ) {
                    return (tab = '');
                  }
                }

                if (
                  checkPurchaseOrderDetailRole() === false &&
                  currentLead?.source === LeadSource.BankAuction
                ) {
                  if (tab === 'Purchase Order details') {
                    return (tab = '');
                  }
                }

                if (checkPricingAndListing() === false) {
                  if (tab === 'Pricing and Listing details') {
                    return (tab = '');
                  }
                }

                if (checkRefurbishmentUserRole() === false) {
                  if (tab === 'Payment Details') {
                    return (tab = '');
                  }
                }
                if (checkforDealDetail() === false) {
                  if (tab === 'Deal Details') {
                    return (tab = '');
                  }
                }

                if (checkRefurbishmentUserRole() === false) {
                  if (tab === 'Loan Application Details') {
                    return (tab = '');
                  }
                }

                return (
                  <Tab
                    key={tab}
                    label={tab}
                    value={tab}
                    onClick={() => {
                      setSelectedTab(index);
                    }}
                  />
                );
              })}
            </TabsWrapper>

            <Grid item xs={12}>
              {currentTab === 'Application Details' && (
                <ApplicationDetails currentLead={currentLead} />
              )}
              {currentTab === 'Accessories Checklist' && (
                <AccessoriesChecklist currentLead={currentLead} />
              )}
              {currentTab === 'Vehicle Details' && (
                <VehicleDetails currentLead={currentLead} />
              )}
              {currentTab === 'Document Details' && (
                <DocumentsDetails
                  currentLead={currentLead}
                  countRefetch={refetch}
                  currCounts={counts}
                />
              )}
              {currentTab === 'Procurement Logistics Details' && (
                <LogisticsDetails currentLead={currentLead} />
              )}
              {currentTab === 'Internal Transfer Details' && (
                <InternalTransferDetails currentLead={currentLead} />
              )}
              {currentTab === 'RTO Verification Details' && (
                <RTOVerification currentLead={currentLead} />
              )}
              {currentTab === 'Deal Details' ? (
                source === LeadSource.BankAuction ? (
                  <DealDetails currentLead={currentLead} />
                ) : (
                  <DealershipDealDetails currentLead={currentLead} />
                )
              ) : null}

              {currentLead?.source === LeadSource?.DealershipSale
                ? currentTab === 'Purchase Order details' && (
                    <PurchaseOrderDetails
                      leadId={currentLead.id}
                      regNo={regNo}
                      source={source}
                      currentLead={currentLead}
                      handlePaymentRaise={handlePaymentRaise}
                    />
                  )
                : null}

              {currentTab === 'Vehicle Images' && (
                <LeadImage currentLead={currentLead} />
              )}
              {currentTab === 'Pricing and Listing details' && (
                <PricingAndListingDetails currentLead={currentLead} />
              )}

              {/* {currentTab === 'Refurbishment Details' && (
                <RefurbishmentDetailList currentLead={currentLead} handlerefurbishment={handlerefurbishment} />
              )} */}

              {currentTab === 'Loan Application Details' && (
                <LoanApplicationDetails currentLead={currentLead} />
              )}
              {currentTab === 'Booking Details' && (
                <BookingDetails currentLead={currentLead} />
              )}
              {currentTab === 'Refurbishment Request Details' && (
                <RefurbishmentDetailList currentLead={currentLead} />
              )}

              {currentTab === 'Payment Details' && (
                <PaymentDetails currentLead={currentLead} />
              )}
              {currentTab === 'Listing Details' && (
                <ListingDetails currentLead={currentLead} />
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            height: '82vh',
            overflowX: 'hidden',
            overflowY: 'auto',
            backgroundColor: 'rgba(229,232,255,0.2)',
            borderRadius: '5px',
            position: 'sticky',
            zoom: '88%',
            top: 90,
            '&.MuiGrid-item': {
              paddingLeft: '0px',
              paddingTop: '0px'
            }
          }}
        >
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                '& .css-1pyy021-MuiTabs-flexContainer': {
                  backgroundColor: '#dfdfdf',
                  justifyContent: 'space-around'
                }
              }}
            >
              <TabList
                onChange={handleChange}
                sx={{
                  boxShadow: ' 5px 3px 6px 1px #a7a7a7'
                }}
              >
                <Tab label="Lead Timeline" value="lead" />
                <Tab label="Document Timeline" value="document" />
              </TabList>
            </Box>
            <TabPanel value="lead">
              <TimelineStatus statuses={statuses} />
            </TabPanel>
            <TabPanel value="document">
              <DocumentTimeline followUpData={followUpData} />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Fragment>
  );
}
