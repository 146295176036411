import { Pending } from '@mui/icons-material';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import CameraFrontTwoToneIcon from '@mui/icons-material/CameraFrontTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import ChromeReaderModeTwoToneIcon from '@mui/icons-material/ChromeReaderModeTwoTone';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import {
  Box,
  Button,
  List,
  ListItem,
  ListSubheader,
  alpha,
  styled
} from '@mui/material';
import { useContext, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import routeConstant from 'src/constants/routeContant';
import { sidebarAccess } from 'src/constants/detailsRoleAccess';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { UserRole } from 'src/generated/hooks_and_more';
import { getUserData } from 'src/utils/utility';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const location = useLocation();
  const [inventoryClick, setInventoryClick] = useState(true);
  const [userClick, setUserClick] = useState(true);
  const [DocumentClick, setDocumentClick] = useState(true);
  const userDetails = getUserData();
  return (
    <>
      {location.pathname.includes('/template') ? (
        <MenuWrapper>
          <List component="div">
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/overview"
                    startIcon={<DesignServicesTwoToneIcon />}
                  >
                    Overview
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Dashboards
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/dashboards/crypto"
                    startIcon={<BrightnessLowTwoToneIcon />}
                  >
                    Cryptocurrency
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/dashboards/messenger"
                    startIcon={<MmsTwoToneIcon />}
                  >
                    Messenger
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Management
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/management/transactions"
                    startIcon={<TableChartTwoToneIcon />}
                  >
                    Transactions List
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Accounts
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/management/profile/details"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    User Profile
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/management/profile/settings"
                    startIcon={<DisplaySettingsTwoToneIcon />}
                  >
                    Account Settings
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Components
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/components/buttons"
                    startIcon={<BallotTwoToneIcon />}
                  >
                    Buttons
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/components/modals"
                    startIcon={<BeachAccessTwoToneIcon />}
                  >
                    Modals
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/components/accordions"
                    startIcon={<EmojiEventsTwoToneIcon />}
                  >
                    Accordions
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/components/tabs"
                    startIcon={<FilterVintageTwoToneIcon />}
                  >
                    Tabs
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/components/badges"
                    startIcon={<HowToVoteTwoToneIcon />}
                  >
                    Badges
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/components/tooltips"
                    startIcon={<LocalPharmacyTwoToneIcon />}
                  >
                    Tooltips
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/components/avatars"
                    startIcon={<RedeemTwoToneIcon />}
                  >
                    Avatars
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/components/cards"
                    startIcon={<SettingsTwoToneIcon />}
                  >
                    Cards
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/components/forms"
                    startIcon={<TrafficTwoToneIcon />}
                  >
                    Forms
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Extra Pages
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/status/404"
                    startIcon={<CheckBoxTwoToneIcon />}
                  >
                    Error 404
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/status/500"
                    startIcon={<CameraFrontTwoToneIcon />}
                  >
                    Error 500
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/status/coming-soon"
                    startIcon={<ChromeReaderModeTwoToneIcon />}
                  >
                    Coming Soon
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/template/status/maintenance"
                    startIcon={<WorkspacePremiumTwoToneIcon />}
                  >
                    Maintenance
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        </MenuWrapper>
      ) : (
        <MenuWrapper>
          <List component="div">
            <SubMenuWrapper>
              {sidebarAccess?.Task_management?.includes(userDetails?.role) ? (
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/vms-admin/my-pending-task"
                      startIcon={<TableViewRoundedIcon />}
                    >
                      Task Management
                    </Button>
                  </ListItem>
                </List>
              ) : null}
              {sidebarAccess?.Leads?.includes(userDetails?.role) ? (
                // && userDetails.role !== UserRole?.LogisticsManager
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/vms-admin/leads"
                      startIcon={<TableViewRoundedIcon />}
                    >
                      Leads
                    </Button>
                  </ListItem>
                </List>
              ) : null}

              {sidebarAccess?.Booking?.includes(userDetails?.role) ? (
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/vms-admin/booking"
                      startIcon={<TableViewRoundedIcon />}
                    >
                      Bookings
                    </Button>
                  </ListItem>
                </List>
              ) : null}

              {sidebarAccess?.Enquiry?.includes(userDetails?.role) ? (
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/vms-admin/enquiry"
                      startIcon={<TableViewRoundedIcon />}
                    >
                      Enquiry
                    </Button>
                  </ListItem>
                </List>
              ) : null}
              {sidebarAccess?.Refurbishment?.includes(userDetails?.role) ? (
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/vms-admin/refurbishments"
                      startIcon={<TableViewRoundedIcon />}
                    >
                      Refurbishment
                    </Button>
                  </ListItem>
                </List>
              ) : null}
              {sidebarAccess?.Enquiry_Dashboard?.includes(userDetails?.role) ? (
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/vms-admin/enquiry-dashboard"
                      startIcon={<TableViewRoundedIcon />}
                    >
                      Enquiry Dashboard
                    </Button>
                  </ListItem>
                </List>
              ) : null}
              {sidebarAccess?.logs_Management?.includes(userDetails?.role) ? (
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/vms-admin/logs-track"
                      startIcon={<TableViewRoundedIcon />}
                    >
                      Logs Track
                    </Button>
                  </ListItem>
                </List>
              ) : null}

              <List component="div">
                {/* <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/vms-admin/enquiry"
                    startIcon={<TableViewRoundedIcon />}
                  >
                    Enquiries
                  </Button>
                </ListItem> */}
              </List>
              {sidebarAccess?.Inventory?.includes(userDetails?.role) ? (
                <List
                  component="div"
                  subheader={
                    <ListSubheader
                      component="div"
                      disableSticky
                      id="inventory-tab"
                      onClick={() => setInventoryClick(!inventoryClick)}
                    >
                      Inventory Management
                      {inventoryClick ? (
                        <ArrowRightIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </ListSubheader>
                  }
                >
                  {!inventoryClick ? (
                    <SubMenuWrapper id="inventory-container">
                      <List component="div">
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={`${routeConstant.INVENTORY}/${routeConstant.CENTER}`}
                            startIcon={<BrightnessLowTwoToneIcon />}
                          >
                            Center
                          </Button>
                        </ListItem>

                        {/* <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={`${routeConstant.INVENTORY}/${routeConstant.ADD_CENTER}`}
                            startIcon={<MmsTwoToneIcon />}
                          >
                            Add Center
                          </Button>
                        </ListItem> */}

                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={`${routeConstant.INVENTORY}/${routeConstant.ADD_STOCK}`}
                            startIcon={<MmsTwoToneIcon />}
                          >
                            Add Stock
                          </Button>
                        </ListItem>

                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={`${routeConstant.INVENTORY}/${routeConstant.TRANSFER_STOCK}`}
                            startIcon={<MmsTwoToneIcon />}
                          >
                            Transfer Stock
                          </Button>
                        </ListItem>
                      </List>
                    </SubMenuWrapper>
                  ) : (
                    <></>
                  )}
                </List>
              ) : null}
              {sidebarAccess?.Document_Management_System?.includes(
                userDetails?.role
              ) ? (
                <List
                  component="div"
                  subheader={
                    <ListSubheader
                      component="div"
                      disableSticky
                      id="inventory-tab"
                      onClick={() => setDocumentClick(!DocumentClick)}
                    >
                      Documents Management
                      {DocumentClick ? (
                        <ArrowRightIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </ListSubheader>
                  }
                >
                  {!DocumentClick ? (
                    <SubMenuWrapper id="inventory-container">
                      <List component="div">
                        {/* <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={`${routeConstant.DOCUMENTS}/${routeConstant.ALL_LEADS}`}
                            startIcon={<BrightnessLowTwoToneIcon />}
                          >
                            All Leads
                          </Button>
                        </ListItem> */}
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={`${routeConstant.DOCUMENTS}/${routeConstant.PENDING_LEADS}`}
                            startIcon={<TableViewRoundedIcon />}
                          >
                            All Leads
                          </Button>
                        </ListItem>
                      </List>
                    </SubMenuWrapper>
                  ) : (
                    <></>
                  )}
                </List>
              ) : null}
              {sidebarAccess?.User_Management?.includes(userDetails?.role) ? (
                <List
                  component="div"
                  subheader={
                    <ListSubheader
                      component="div"
                      disableSticky
                      id="inventory-tab"
                      onClick={() => setUserClick(!userClick)}
                    >
                      User Management
                      {userClick ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
                    </ListSubheader>
                  }
                >
                  {!userClick ? (
                    <SubMenuWrapper id="inventory-container">
                      <List component="div">
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={`${routeConstant.USERS}/${routeConstant.USERS_LIST}`}
                            startIcon={<BrightnessLowTwoToneIcon />}
                          >
                            Users
                          </Button>
                        </ListItem>

                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={`${routeConstant.USERS}/${routeConstant.ADD_USER}`}
                            startIcon={<MmsTwoToneIcon />}
                          >
                            Add Users
                          </Button>
                        </ListItem>
                      </List>
                    </SubMenuWrapper>
                  ) : (
                    <></>
                  )}
                </List>
              ) : null}
            </SubMenuWrapper>
          </List>
        </MenuWrapper>
      )}
    </>
  );
}

export default SidebarMenu;
