import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import React, { Fragment } from 'react';
import { ActionType } from 'src/constants/constant';
import {
  LeadRef,
  LeadSource,
  LeadStatus,
  PaymentFor,
  PaymentStatus,
  useGetBookingPaymentForReviewQuery
} from 'src/generated/hooks_and_more';
import { titleCaseToReadable } from 'src/utils/utility';
import CtaButton from '../CTA_Button/CtaButton';
import Input from 'src/components/Form/Input';
type ReviewPaymentProps = {
  paymentFor: string;
  requestId?: string;
  desireButtonText?: string;
  undesireButtonText?: string;
  canGoBack?: boolean;
  showConfirmation?: boolean;
  leadId?: string;
  regNo?: string;
  desireStatus?: LeadStatus;
  onPressOfDesiredOrUndesiredButton?: (
    leadInput: LeadRef,
    remarks?: string,
    actionType?: ActionType
  ) => void;
  source?: LeadSource;
  toggleDrawer: () => void;
  refetch?: any;
  paymentId?: string;
};
function ReviewPayment(props: ReviewPaymentProps) {
  const {
    regNo,
    leadId,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    paymentFor,
    requestId,
    onPressOfDesiredOrUndesiredButton
  } = props;
  const { data: getBookingPaymentData, loading } =
    useGetBookingPaymentForReviewQuery({
      fetchPolicy: 'cache-and-network',
      variables: { leadId: leadId }
    });

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      remarks: null
    },

    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  let getPayment =
    getBookingPaymentData?.getLead?.activeBooking?.payments?.filter((i) => {
      if (!paymentFor) {
        return i?.status === PaymentStatus?.Approved;
      } else {
        if (i?.for === PaymentFor.BookingPart) {
          return i?.status === PaymentStatus.Requested && i?.id === requestId;
        } else {
          return i?.for === paymentFor && i?.status === PaymentStatus.Requested;
        }
      }
    });

  getPayment = getPayment?.filter((i) => i?.amount !== null);
  function handleReviewPayment(){
    if(paymentFor === PaymentFor.BookingPart){
      return 'Booking payment'
    }else{
      return titleCaseToReadable(paymentFor)
    } 
  }
  return (
    <>
      {loading ? (
        <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
          <h5>Loading..</h5>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <TableContainer
            sx={{ mt: 4, backgroundColor: '#f6f6f6' }}
            component={Paper}
          >
            <Table>
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    sx={{ fontWeight: 600 }}
                    component="th"
                    scope="row"
                  >
                    Payment Type
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    Amount
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    Status
                  </TableCell>
                </TableRow>

                {getPayment?.map((i) => (
                  <>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {' '}
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: '150px' }}
                      >
                        <p>
                          {handleReviewPayment()}
                        </p>

                        <p>
                          {i?.createdAt
                            ? format(Date.parse(i?.createdAt), 'dd MMM yyyy')
                            : '-'}
                        </p>
                      </TableCell>
                      <TableCell align="right">
                        <p>{`₹${i?.amount}`}</p>
                        <p>{`${titleCaseToReadable(i?.mode)}`}</p>
                      </TableCell>
                      <TableCell align="right">
                        {titleCaseToReadable(i?.status)}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item xs={12} md={12} sx={{ mt: 5 }}>
            <Input
              placeholder="Remarks *"
              onChange={(e) => {
                formik.setFieldValue('remarks', e.target.value);
              }}
              value={formik.values.remarks}
            />
          </Grid>
          <CtaButton
            desireButtonText={desireButtonText}
            backBtn={canGoBack}
            undesireButtonText={undesireButtonText}
            disabled={false}
            onlyRed={true}
            reject={reject}
          />
        </form>
      )}
    </>
  );
}

export default ReviewPayment;
