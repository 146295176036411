import { Padding } from '@mui/icons-material';
import '../form.css';

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import React from 'react';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../../hooks/useCreateLeadStatusEvents';
import {
  ItemInventoryDetailsHasFilter,
  LeadRef,
  LeadStatus,
  useGetDocumentDetailsFormQuery
} from 'src/generated/hooks_and_more';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import Input from 'src/components/Form/Input';
import CtaButton from '../CTA_Button/CtaButton';

const ConfirmDocument = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton
  } = props;
  const { data, loading } = useGetDocumentDetailsFormQuery({
    variables: {
      id: leadId
    }
  });

  let documentItem = data?.getLead?.documentChecklist;

  const formik = useFormik({
    initialValues: {
      registrationCertificate: 'no',
      formTwentySix: 'no',
      loanForeClosure: 'no',
      BankNOC: 'no',
      formthirtyFive: 'no',
      insuranceCertificate: 'no',
      formTwentyEight: 'no',
      formTwentyNine: 'no',
      formThirty: 'no',
      sellerPANorFromSixty: 'no',
      sellerAadharCard: 'no',
      ownerAdderessProof: 'no',
      formThirtySix: 'no',
      remark: '',
      rcExpectedDate: '',
      formTwentySixExpectedDate: '',
      loanForeClosureExpectedDate: '',
      BankNOCExpectedDate: '',
      formthirtyFiveExpectedDate: '',
      insuranceCertificateExpectedDate: '',
      formTwentyEightExpectedDate: '',
      formTwentyNineExpectedDate: '',
      formThirtyExpectedDate: '',
      sellerPANorFromSixtyExpectedDate: '',
      sellerAadharCardExpectedDate: '',
      ownerAdderessProofExpectedDate: '',
      formThirtySixExpectedDate: '',
      blackStance: 'no',
      challanStance: 'no',
      hsrpStance: 'no'
    },
    validationSchema: Yup.object().shape({
      registrationCertificate: Yup.string().required('*Required'),
      rcExpectedDate: Yup.string()
        .required('*Required')
        .when('registrationCertificate', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      formTwentySix: Yup.string().required('*Required'),
      formTwentySixExpectedDate: Yup.string()
        .required('*Required')
        .when('formTwentySix', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      loanForeClosure: Yup.string().required('*Required'),
      loanForeClosureExpectedDate: Yup.string()
        .required('*Required')
        .when('loanForeClosure', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),

      BankNOC: Yup.string().required('*Required'),
      BankNOCExpectedDate: Yup.string()
        .required('*Required')
        .when('BankNOC', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      formthirtyFive: Yup.string().required('*Required'),
      formthirtyFiveExpectedDate: Yup.string()
        .required('*Required')
        .when('formthirtyFive', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      insuranceCertificate: Yup.string().required('*Required'),
      insuranceCertificateExpectedDate: Yup.string()
        .required('*Required')
        .when('insuranceCertificate', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      formTwentyEight: Yup.string().required('*Required'),
      formTwentyEightExpectedDate: Yup.string()
        .required('*Required')
        .when('formTwentyEight', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      formTwentyNine: Yup.string().required('*Required'),
      formTwentyNineExpectedDate: Yup.string()
        .required('*Required')
        .when('formTwentyNine', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      formThirty: Yup.string().required('*Required'),
      formThirtyExpectedDate: Yup.string()
        .required('*Required')
        .when('formThirty', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      sellerPANorFromSixty: Yup.string().required('*Required'),
      sellerPANorFromSixtyExpectedDate: Yup.string()
        .required('*Required')
        .when('sellerPANorFromSixty', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      sellerAadharCard: Yup.string().required('*Required'),
      sellerAadharCardExpectedDate: Yup.string()
        .required('*Required')
        .when('sellerAadharCard', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      ownerAdderessProof: Yup.string().required('*Required'),
      ownerAdderessProofExpectedDate: Yup.string()
        .required('*Required')
        .when('ownerAdderessProof', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      formThirtySix: Yup.string().required('*Required'),
      formThirtySixExpectedDate: Yup.string()
        .required('*Required')
        .when('formThirtySix', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        })
    }),

    onSubmit: (values) => {
      const leadInput: LeadRef = {
        id: leadId,
        regNo: regNo,
        dealerStance:{
          clearBlackList: values.blackStance === 'yes' ? true : null,
          clearHSRP: values.hsrpStance === 'yes' ? true : null,
          clearChallan: values.challanStance === 'yes' ? true : null,
        },
        dealershipDocuments: {
          bankNOC: {
            isAvailable: values.BankNOC === 'yes' ? true : false,
            expectedDate:
              values.BankNOC === 'yes' ? values.BankNOCExpectedDate : null
          },
          form26: {
            isAvailable: values.formTwentySix === 'yes' ? true : false,
            expectedDate:
              values.formTwentySix === 'yes'
                ? values.formTwentySixExpectedDate
                : null
          },
          form28: {
            isAvailable: values.formTwentyEight === 'yes' ? true : false,
            expectedDate:
              values.formTwentyEight === 'yes'
                ? values.formTwentyEightExpectedDate
                : null
          },
          form29: {
            isAvailable: values.formTwentyNine === 'yes' ? true : false,
            expectedDate:
              values.formTwentyNine === 'yes'
                ? values.formTwentyNineExpectedDate
                : null
          },
          form30: {
            isAvailable: values.formThirty === 'yes' ? true : false,
            expectedDate:
              values.formThirty === 'yes' ? values.formThirtyExpectedDate : null
          },
          form35: {
            isAvailable: values.formthirtyFive === 'yes' ? true : false,
            expectedDate:
              values.formthirtyFive === 'yes'
                ? values.formthirtyFiveExpectedDate
                : null
          },

          insuranceCertificate: {
            isAvailable: values.insuranceCertificate === 'yes' ? true : false,
            expectedDate:
              values.insuranceCertificate === 'yes'
                ? values.insuranceCertificateExpectedDate
                : null
          },
          loanForeclosure: {
            isAvailable: values.loanForeClosure === 'yes' ? true : false,
            expectedDate:
              values.loanForeClosure === 'yes'
                ? values.loanForeClosureExpectedDate
                : null
          },
          registrationCertificate: {
            isAvailable:
              values.registrationCertificate === 'yes' ? true : false,
            expectedDate:
              values.registrationCertificate === 'yes'
                ? values.rcExpectedDate
                : null
          },
          sellerAadharCard: {
            isAvailable: values.sellerAadharCard === 'yes' ? true : false,
            expectedDate:
              values.sellerAadharCard === 'yes'
                ? values.sellerAadharCardExpectedDate
                : null
          },
          form36: {
            isAvailable: values.formThirtySix === 'yes' ? true : false,
            expectedDate:
              values.formThirtySix === 'yes'
                ? values.formThirtySixExpectedDate
                : null
          },
          form60_sellerPan: {
            isAvailable: values.sellerPANorFromSixty === 'yes' ? true : false,
            expectedDate:
              values.sellerPANorFromSixty === 'yes'
                ? values.sellerPANorFromSixtyExpectedDate
                : null
          },
          ownerAddressProof: {
            isAvailable: values.ownerAdderessProof === 'yes' ? true : false,
            expectedDate:
              values.ownerAdderessProof === 'yes'
                ? values.ownerAdderessProofExpectedDate
                : null
          },
         
        }
        
      };
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remark);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} component={Paper}  sx={{borderRadius: '7px',
backgroundColor: '#f6f6f6', border: 'solid 1px #d7d2d2 '}}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            fontSize: '16px',
            border: '1px solid #C5C5C5',
            padding: '0.8rem',
            borderRadius: '7px'
          }}
        >
          <Grid
            item
            xs={6}
            md={6}
            sx={{ fontSize: '18px', fontWeight: 'bold' }}
          >
            Document Name
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sx={{ fontSize: '18px', fontWeight: 'bold' }}
          >
            Availablity Status
          </Grid>
        </Grid>
        {documentItem?.registrationCertificate ? (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Registration Certificate*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.registrationCertificate}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() =>
                      formik.setFieldValue('registrationCertificate', 'yes')
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() =>
                      formik.setFieldValue('registrationCertificate', 'no')
                    }
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}

        {formik.values.registrationCertificate === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'rcExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={formik.values.rcExpectedDate ? (new Date(formik.values.rcExpectedDate)) : null}
            />
            {formik.errors.rcExpectedDate && formik.touched.rcExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.rcExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.form26 && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.7rem' }}
          >
            <Grid item xs={6} md={6}>
              Form 26*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.formTwentySix}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() => formik.setFieldValue('formTwentySix', 'yes')}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() => formik.setFieldValue('formTwentySix', 'no')}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {formik.values.formTwentySix === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'formTwentySixExpectedDate',
                  value === null ? '' : value);
              }}
              placeholder="Expected Date*"
              value={formik.values.formTwentySixExpectedDate ? (new Date(formik.values.formTwentySixExpectedDate)) : null}
            />
            {formik.errors.formTwentySixExpectedDate &&
            formik.touched.formTwentySixExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.formTwentySixExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.loanForeclosure && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Loan foreclosure*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.loanForeClosure}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() =>
                      formik.setFieldValue('loanForeClosure', 'yes')
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() =>
                      formik.setFieldValue('loanForeClosure', 'no')
                    }
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}

        {formik.values.loanForeClosure === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'loanForeClosureExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={formik.values.loanForeClosureExpectedDate ? ( new Date(formik.values.loanForeClosureExpectedDate)) : null}
            />
            {formik.errors.loanForeClosureExpectedDate &&
            formik.touched.loanForeClosureExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.loanForeClosureExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.bankNOC && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Bank NOC*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.BankNOC}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() => formik.setFieldValue('BankNOC', 'yes')}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() => formik.setFieldValue('BankNOC', 'no')}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {formik.values.BankNOC === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'BankNOCExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={ formik.values.BankNOCExpectedDate ?  new Date(formik.values.BankNOCExpectedDate) : null} 
            />
            {formik.errors.BankNOCExpectedDate &&
            formik.touched.BankNOCExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.BankNOCExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.form35 && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Form 35*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.formthirtyFive}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() =>
                      formik.setFieldValue('formthirtyFive', 'yes')
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() => formik.setFieldValue('formthirtyFive', 'no')}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {formik.values.formthirtyFive === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'formthirtyFiveExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={formik.values.formthirtyFiveExpectedDate ? new Date(formik.values.formthirtyFiveExpectedDate) : null}
            />
            {formik.errors.formthirtyFiveExpectedDate &&
            formik.touched.formthirtyFiveExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.formthirtyFiveExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.insuranceCertificate && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Insurance Certificate*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.insuranceCertificate}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() =>
                      formik.setFieldValue('insuranceCertificate', 'yes')
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() =>
                      formik.setFieldValue('insuranceCertificate', 'no')
                    }
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}

        {formik.values.insuranceCertificate === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'insuranceCertificateExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={formik.values.insuranceCertificateExpectedDate ? new Date(formik.values.insuranceCertificateExpectedDate) : null}
            />
            {formik.errors.insuranceCertificateExpectedDate &&
            formik.touched.insuranceCertificateExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.insuranceCertificateExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.form28 ? (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Form 28*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.formTwentyEight}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() =>
                      formik.setFieldValue('formTwentyEight', 'yes')
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() =>
                      formik.setFieldValue('formTwentyEight', 'no')
                    }
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}

        {formik.values.formTwentyEight === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'formTwentyEightExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={formik.values.formTwentyEightExpectedDate ? new Date(formik.values.formTwentyEightExpectedDate) : null}
            />
            {formik.errors.formTwentyEightExpectedDate &&
            formik.touched.formTwentyEightExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.formTwentyEightExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.form29 && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Form 29*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.formTwentyNine}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() =>
                      formik.setFieldValue('formTwentyNine', 'yes')
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() => formik.setFieldValue('formTwentyNine', 'no')}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {formik.values.formTwentyNine === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'formTwentyNineExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={ formik.values.formTwentyNineExpectedDate ? new Date(formik.values.formTwentyNineExpectedDate) : null}
            />
            {formik.errors.formTwentyNineExpectedDate &&
            formik.touched.formTwentyNineExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.formTwentyNineExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.form30 ? (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Form 30*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.formThirty}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() => formik.setFieldValue('formThirty', 'yes')}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() => formik.setFieldValue('formThirty', 'no')}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}
        {formik.values.formThirty === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'formThirtyExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value= { formik.values.formThirtyExpectedDate ? new Date(formik.values.formThirtyExpectedDate) : null}
            />
            {formik.errors.formThirtyExpectedDate &&
            formik.touched.formThirtyExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.formThirtyExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.form60_sellerPan && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              sellerPAN / Form 60*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.sellerPANorFromSixty}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() =>
                      formik.setFieldValue('sellerPANorFromSixty', 'yes')
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() =>
                      formik.setFieldValue('sellerPANorFromSixty', 'no')
                    }
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {formik.values.sellerPANorFromSixty === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'sellerPANorFromSixtyExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={formik.values.sellerPANorFromSixtyExpectedDate ? new Date(formik.values.sellerPANorFromSixtyExpectedDate) : null}
            />
            {formik.errors.sellerPANorFromSixtyExpectedDate &&
            formik.touched.sellerPANorFromSixtyExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.sellerPANorFromSixtyExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.sellerAadharCard && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Seller Aadhar Card*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.sellerAadharCard}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() =>
                      formik.setFieldValue('sellerAadharCard', 'yes')
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() =>
                      formik.setFieldValue('sellerAadharCard', 'no')
                    }
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {formik.values.sellerAadharCard === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'sellerAadharCardExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={ formik.values.sellerAadharCardExpectedDate ? new Date(formik.values.sellerAadharCardExpectedDate) : null}
            />
            {formik.errors.sellerAadharCardExpectedDate &&
            formik.touched.sellerAadharCardExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.sellerAadharCardExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.ownerAddressProof && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Owner Address Proof*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.ownerAdderessProof}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() =>
                      formik.setFieldValue('ownerAdderessProof', 'yes')
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() =>
                      formik.setFieldValue('ownerAdderessProof', 'no')
                    }
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {formik.values.ownerAdderessProof === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'ownerAdderessProofExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={ formik.values.ownerAdderessProofExpectedDate ? new Date(formik.values.ownerAdderessProofExpectedDate) : null}
            />
            {formik.errors.ownerAdderessProofExpectedDate &&
            formik.touched.ownerAdderessProofExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.ownerAdderessProofExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}
        {documentItem?.form36 && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
          >
            <Grid item xs={6} md={6}>
              Form 36*
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={formik.values.formThirtySix}
                  sx={{
                    width: '240px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    onClick={() => formik.setFieldValue('formThirtySix', 'yes')}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() => formik.setFieldValue('formThirtySix', 'no')}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {formik.values.formThirtySix === 'yes' && (
          <Grid item xs={12} md={12} mr={2}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue(
                  'formThirtySixExpectedDate',
                  value === null ? '' : value
                );
              }}
              placeholder="Expected Date*"
              value={ formik.values.formThirtySixExpectedDate ? new Date(formik.values.formThirtySixExpectedDate) : null}
            />
            {formik.errors.formThirtySixExpectedDate &&
            formik.touched.formThirtySixExpectedDate ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.formThirtySixExpectedDate}
              </div>
            ) : null}
          </Grid>
        )}

{
  data?.getLead?.vehicle?.isChallanAvailable && 
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
          Will Dealer clear challan issue? *
                        </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.challanStance}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('challanStance', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('challanStance', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
}

{  data?.getLead?.vehicle?.isBlacklisted && 
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
          Will dealer clear blacklisted issue? *
                    </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.blackStance}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('blackStance', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('blackStance', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
}

{  data?.getLead?.vehicle?.isHSRPAvailable && 
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: 'flex', fontSize: '16px', marginTop: '0.5rem' }}
        >
          <Grid item xs={6} md={6}>
          will dealer clear HSRP issue? *
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={formik.values.hsrpStance}
                sx={{
                  width: '240px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('hsrpStance', 'yes')}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('hsrpStance', 'no')}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
}

        <Grid item xs={12} md={12} mr={2}>
          <Input
            value={formik.values.remark}
            name="remark"
            onChange={(e) => formik?.setFieldValue('remark', e.target.value)}
            placeholder="Enter Your Remark*"
          />
          {formik.errors.remark && formik.touched.remark ? (
            <div style={{ color: 'red' }}>{formik.errors?.remark}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12} mb={2}>
        <CtaButton
        desireButtonText={desireButtonText}
        disabled={false}
      />
        </Grid>
      </Grid>
    </form>
  );
};

export default ConfirmDocument;
