import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import Input from 'src/components/Form/Input';
import {
  BookingType,
  LeadRef,
  LeadStatus,
  LoanToBeClosedBy,
  PaymentFor,
  PaymentStatus,
  PaymentType,
  useGetDeliveryPaymentQuery,
  useGetDocumentDetailsFormQuery,
  useGetLeadDealDetailsQuery
} from 'src/generated/hooks_and_more';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  enumToItems,
  getBalanceAmountForBooking,
  titleCaseToReadable
} from 'src/utils/utility';
import { LeadCreateAndUpdateProps } from 'src/types';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import CtaButton from '../CTA_Button/CtaButton';
import {
  ActionType,
  INSURANCE_CHARGES,
  RTO_CHARGES
} from 'src/constants/constant';

const RequestForDeliveryRefurb = (props: LeadCreateAndUpdateProps) => {
  const {
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    undesireButtonText
  } = props;

  const { data: deliveryPaymentData } = useGetLeadDealDetailsQuery({
    variables: {
      id: leadId
    }
  });

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      remark: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId
      };
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remark);
    }
  });

  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };

  const saleAmount =
    deliveryPaymentData?.getLead?.activeBooking?.bookingPayment?.saleAmount;
  const bookingPayment = deliveryPaymentData?.getLead?.activeBooking?.payments;
  const isRcTransferRequired =
    deliveryPaymentData?.getLead?.activeBooking?.isRCTransferReq;
  const isInsuranceRequired =
    deliveryPaymentData?.getLead?.activeBooking?.isInsuranceReq;
  const isLoanRequired =
    deliveryPaymentData?.getLead?.activeBooking?.bookingPayment?.bookingType ===
    BookingType.Finance;
  const appliedLoanAmount =
    deliveryPaymentData?.getLead?.activeBooking?.activeLoan?.appliedLoanAmount;
  const sanctionedLoanAmount =
    deliveryPaymentData?.getLead?.activeBooking?.activeLoan
      ?.sanctionedLoanAmount;

  const paymentMode =
    deliveryPaymentData?.getLead?.activeBooking?.bookingPayment?.bookingType;
  const balanceAmount = getBalanceAmountForBooking(
    bookingPayment,
    saleAmount,
    isRcTransferRequired,
    isInsuranceRequired,
    isLoanRequired,
    appliedLoanAmount,
    sanctionedLoanAmount
  );
  let saleStructure: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Sale Amount',
      value: saleAmount
    },

    {
      key: 'RC transfer',
      value: isRcTransferRequired ? RTO_CHARGES : 'No'
    },
    {
      key: 'Insurance',
      value: isInsuranceRequired ? INSURANCE_CHARGES : 'No'
    },
    {
      key: 'Total Sale Amount',
      value:
        (!!saleAmount ? saleAmount : 0) +
          (isRcTransferRequired ? RTO_CHARGES : 0) +
          (isInsuranceRequired ? INSURANCE_CHARGES : 0) ?? '-'
    },
    {
      key: 'Balance Amount',
      value: balanceAmount
    }
  ];

  let paymentReceived: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Booking Token',
      value: deliveryPaymentData?.getLead?.activeBooking?.payments[0]?.amount
    },
    {
      key: 'Booking Delivery',
      value: deliveryPaymentData?.getLead?.activeBooking?.payments[1]?.amount
    }
  ];

  //   saleStructure = saleStructure?.filter((i) => i?.value !== 0);
  //   paymentReceived = paymentReceived?.filter((i) => i?.value !== 0);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={12}>
          <h4>Payment Details </h4>
        </Grid>

        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <TableContainer sx={{ backgroundColor: '#f6f6f6' }} component={Paper}>
            <Table
              sx={{
                padding: 0,
                boxShadow:
                  ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              }}
            >
              <TableBody sx={{ borderRadius: '1rem ' }}>
                <TableRow>
                  {/* <TableCell colSpan={2}>
                    {' '}
                    <p style={{ marginTop: '0.5rem' }}>Deal Structure</p>
                  </TableCell> */}
                  <TableCell colSpan={2}>
                    {' '}
                    <p style={{ marginTop: '0.5rem' }}>Payment Mode</p>
                  </TableCell>
                  <TableCell colSpan={2}>
                    {' '}
                    <p style={{ marginTop: '0.5rem' }}>{paymentMode}</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    {' '}
                    <h5 style={{ marginTop: '0.5rem' }}>Sale Structure</h5>
                  </TableCell>
                </TableRow>
                {saleStructure.map((i, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      fontSize: '16px'
                    }}
                  >
                    <TableCell colSpan={2}>{i?.key}</TableCell>
                    <TableCell colSpan={2}>{i?.value}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2}>
                    {' '}
                    <h5 style={{ marginTop: '0.5rem' }}>Payment Received</h5>
                  </TableCell>
                </TableRow>
                {deliveryPaymentData?.getLead?.activeBooking?.payments?.map(
                  (i, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        fontSize: '16px'
                      }}
                    >
                      <TableCell colSpan={2}>
                        {titleCaseToReadable(i?.for)}
                      </TableCell>
                      <TableCell colSpan={2}>
                        ₹{i?.amount}{' '}
                        {i?.status === 'APPROVED' ? (
                          <CheckCircleIcon
                            style={{
                              color: 'green',
                              float: 'right',
                              marginRight: '0.5rem'
                            }}
                          />
                        ) : i?.status === 'REQUESTED' ? (
                          <Tooltip title="Payment Requested" arrow>
                            <ErrorIcon
                              sx={{
                                cursor: 'pointer',
                                float: 'right',
                                marginRight: '0.5rem'
                              }}
                              fontSize="small"
                              color="warning"
                            />
                          </Tooltip>
                        ) : (
                          <CancelIcon
                            sx={{
                              cursor: 'pointer',
                              float: 'right',
                              marginRight: '0.5rem'
                            }}
                            fontSize="small"
                            color="error"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        { !Boolean(Number(balanceAmount) > 0) ? null : (
          <Grid item xs={12} md={12} mt={2}>
            <Typography sx={{ color: 'red' }}>
              {' '}
              Please complete the balance payment to request for delivery
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={12} mt={2}>
        <Input
          value={formik.values.remark}
          name="remark"
          onChange={(e) => formik?.setFieldValue('remark', e.target.value)}
          placeholder="Enter Your Remark*"
        />
        {formik.errors.remark && formik.touched.remark ? (
          <div style={{ color: 'red' }}>{formik.errors?.remark}</div>
        ) : null}
      </Grid>
      <Grid item xs={12} md={12} mt={2}>
        <CtaButton
          desireButtonText={desireButtonText}
          disabled={Boolean(Number(balanceAmount) > 0)}
          reject={reject}
          undesireButtonText={undesireButtonText}
          onlyRed={true}
        />
      </Grid>
    </form>
  );
};

export default RequestForDeliveryRefurb;
