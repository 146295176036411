import { TableCell, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import './demo.css';
type closureProps = {
  __typename?: 'EnquiryCloserAgg';
  closed?: number;
  infollowUp?: number;
  notIntrested?: number;
  notResponding?: number;
  purchasedUsedTractor?: number;
  purchaseNewTractor?: number;
  tractorBooked?: number;
};
function EnquiryClosureRows(closureData: closureProps) {
  const {
    closed,
    infollowUp,
    notIntrested,
    notResponding,
    purchaseNewTractor,
    purchasedUsedTractor,
    tractorBooked
  } = closureData;
  return (
    <Fragment>
      <td style={{ textAlign: 'center', minWidth: '100px' }}>{infollowUp}</td>

      <td style={{ textAlign: 'center', minWidth: '100px' }}>{closed}</td>

      <td style={{ textAlign: 'center', minWidth: '100px' }}>
        {tractorBooked}
      </td>

      <td style={{ textAlign: 'center', minWidth: '100px' }}>
        {purchaseNewTractor}
      </td>

      <td style={{ textAlign: 'center', minWidth: '100px' }}>
        {purchasedUsedTractor}
      </td>

      <td style={{ textAlign: 'center', minWidth: '100px' }}>{notIntrested}</td>

      <td style={{ textAlign: 'center', minWidth: '100px' }}>
        {notResponding}
      </td>

      {/* <TableCell>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {infollowUp}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {closed}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {tractorBooked}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {purchaseNewTractor}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {purchasedUsedTractor}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {notIntrested}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {notResponding}
        </Typography>
      </TableCell> */}
    </Fragment>
  );
}

export default EnquiryClosureRows;
