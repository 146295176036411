import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Grid, TextField } from '@mui/material';
import Input from 'src/components/Form/Input';
import * as Yup from 'yup';
import {
  BankName,
  LeadRef,
  LeadStatus,
  PaymentFor,
  PaymentMethod,
  PaymentStatus,
  useGetAllParkingBeneficiaryLazyQuery,
  useGetAllParkingBeneficiaryQuery,
  useGetYardDetailsQuery
} from 'src/generated/hooks_and_more';
import {
  calculateParkingCharge,
  enumToItems,
  titleCaseToReadable
} from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { SchedulePickupPaymentSchema } from '../ValidationSchema/ValidationSchema';
import './form.css';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

const SchedulePaymentForm = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    canGoBack,
    undesireButtonText
  } = props;

  const { data } = useGetYardDetailsQuery({
    variables: {
      id: leadId
    }
  });
  const [getBeneficiaryDetails, { data: parkingBenificiaryDataa, loading }] =
    useGetAllParkingBeneficiaryLazyQuery();
  const { data: parkingBenificiaryData } = useGetAllParkingBeneficiaryQuery({
    fetchPolicy: 'cache-and-network'
  });
  const BankNameOptions = enumToItems(BankName);

  const [mode, setMode] = useState(true);
  useEffect(() => {
    const value = parkingBenificiaryDataa?.queryParkingBeneficiary[0];
    const bankOption = {
      label: titleCaseToReadable(value?.bankName),
      value: value?.bankName
    };
    formik.setValues({
      ...formik.values,
      bankName: bankOption,
      accountHolderName: value?.accountHolderName,
      accountNumber: value?.accountNumber,
      ifscNumber: value?.ifscCode,
      accountProof: value?.proofUrl
    });
  }, [parkingBenificiaryDataa]);
  const estimatedCharges = (parkingCharge) => {
    const estimatedParkingCharge = calculateParkingCharge({
      expectedPickupDate: data?.getLead?.expectedPickupDate,
      repossessionDate: data?.getLead?.vehicle?.repossessionDate,
      perDayParkingCharge: parkingCharge
    });
    if (Number.isNaN(estimatedParkingCharge)) {
      return 0;
    } else {
      return estimatedParkingCharge;
    }
  };
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      oneDayParkCharge: data?.getLead?.yard?.perDayParkingCharge ?? 0,
      estimatingParkCharge: estimatedCharges(
        data?.getLead?.yardPerDayParkingCharges
      ),
      beneficiary: null,
      accountNumber: '',
      bankName: null,
      bankTransfer: true,
      accountHolderName: '',
      ifscNumber: '',
      accountProof: '',
      remarks: '',
      upiId: ''
    },
    enableReinitialize: true,
    validationSchema: SchedulePickupPaymentSchema,

    onSubmit: (values) => {
      let chargeforestimation = estimatedCharges(Number(formik.values.oneDayParkCharge))
      if (values?.bankTransfer) {
        leadInput = {
          id: leadId,
          regNo: regNo,
          yardPerDayParkingCharges: values?.oneDayParkCharge,

          estimatedParkingCharges: chargeforestimation
            ?chargeforestimation
            : null,
          parkingBeneficiary: {
            bankName: values?.bankName?.value as BankName,
            accountHolderName: values?.accountHolderName,
            accountNumber: values?.accountNumber,
            ifscCode: values?.ifscNumber,
            proofUrl: values?.accountProof
          },
          payments: [
            {
              for: PaymentFor.ParkingExpense,
              status: PaymentStatus.Estimated,
              mode: PaymentMethod.BankTransferNeft,

              parkingBeneficiary: {
                id:
                  values?.beneficiary?.label === 'Add new Beneficiary'
                    ? null
                    : values?.beneficiary?.value,
                bankName: values?.bankName?.value as BankName,
                accountHolderName: values?.accountHolderName,
                accountNumber: values?.accountNumber,
                ifscCode: values?.ifscNumber,
                proofUrl: values?.accountProof
              }
            }
          ],
          documents: {
            bankAccountProofUrl: values?.accountProof
          }
        };
      } else {
        leadInput = {
          id: leadId,
          regNo: regNo,
          yardPerDayParkingCharges: values?.oneDayParkCharge,

          estimatedParkingCharges: values?.estimatingParkCharge,
          payments: [
            {
              for: PaymentFor.ParkingExpense,
              status: PaymentStatus.Estimated,
              mode: PaymentMethod.Upi,
              upiId: values?.upiId
            }
          ]
        };
      }
      console.log("leadInput", leadInput)

      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  const accountHolderNameList =
    parkingBenificiaryData?.queryParkingBeneficiary
      ?.map((item) => ({
        label: _.capitalize(item?.accountHolderName),
        value: item?.id,
        bank: {
          label: titleCaseToReadable(item?.bankName),
          value: item?.bankName
        },
        accountHolderName: item?.accountHolderName,
        accountNumber: item?.accountNumber,
        ifsc: item?.ifscCode,
        accountProof: item?.proofUrl,
        beneficiaryId: item?.id
      }))
      .concat([
        {
          label: 'Add new Benificiary',
          value: null,
          bank: null,
          accountHolderName: '',
          accountNumber: '',
          ifsc: '',
          accountProof: '',
          beneficiaryId: null
        }
      ]) ?? [];
  if (loading) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center gap-4"
        style={{ height: '100vh' }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Input
            name="oneDayParkCharge"
            value={formik?.values?.oneDayParkCharge}
            onChange={(e) => {
              formik.setFieldValue('oneDayParkCharge', e?.target?.value);
            }}
            placeholder="Enter per day Parking Charges*"
          />
          {formik.errors.oneDayParkCharge || formik.touched.oneDayParkCharge ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.oneDayParkCharge}
            </div>
          ) : null}{' '}
        </Grid>
        <Grid item md={12} xs={12}>
          <Input
            disabled={true}
            value={estimatedCharges(Number(formik.values.oneDayParkCharge))}
            placeholder="Estimated Parking Charges*"
          />
          {formik.errors.estimatingParkCharge &&
          formik.touched.estimatingParkCharge ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.estimatingParkCharge}
            </div>
          ) : null}{' '}
        </Grid>

        <Grid item xs={12} md={12} style={{ marginLeft: '0.1rem' }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={'yesMode'}
              sx={{
                width: '300px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <FormControlLabel
                value="yesMode"
                control={<Radio />}
                onClick={() => {
                  if (!mode) {
                    formik.setValues({
                      ...formik.values,
                      bankName: null,
                      accountHolderName: '',
                      accountNumber: '',
                      ifscNumber: '',
                      accountProof: ''
                    });
                  }
                  formik.setFieldValue('bankTransfer', true);

                  setMode(true);
                }}
                label="Bank Transfer"
              />
              <FormControlLabel
                value="noMode"
                onClick={() => {
                  if (formik.values.bankTransfer) {
                    formik.setValues({
                      ...formik.values,
                      bankName: null,
                      accountHolderName: '',
                      accountNumber: '',
                      ifscNumber: '',
                      accountProof: ''
                    });
                  }

                  formik.setFieldValue('bankTransfer', false);
                  setMode(false);
                }}
                control={<Radio />}
                label="UPI"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {mode ? (
          <>
            <Grid item xs={12} md={12}>
              <InputAutoComplete
                onChange={(value) => {
                  if (value?.label !== 'Add new Benificiary' && value) {
                    getBeneficiaryDetails({ variables: { id: value?.value } });
                  }
                  if (
                    value === null ||
                    value?.label === 'Add new Benificiary'
                  ) {
                    formik.setValues({
                      ...formik.values,
                      bankName: null,
                      accountHolderName: '',
                      accountNumber: '',
                      ifscNumber: '',
                      accountProof: ''
                    });
                  }
                  formik.setFieldValue('beneficiary', value);
                }}
                value={formik.values.beneficiary}
                placeholder="Select Beneficiary*"
                options={accountHolderNameList}
                optionString="label"
                getOptionLabel={(option) => option.name}
              />
              {formik.errors.beneficiary && formik.touched.beneficiary ? (
                <div style={{ color: 'red' }}>{formik.errors?.beneficiary}</div>
              ) : null}
            </Grid>

            <Grid item xs={12} md={12}>
              <InputAutoComplete
                onChange={(value) => {
                  formik.setFieldValue('bankName', value);
                }}
                value={formik.values.bankName}
                placeholder="Select the Bank Name*"
                options={BankNameOptions}
                optionString="label"
                getOptionLabel={(option) => option.name}
                disabled={
                  formik?.values?.beneficiary?.label !== 'Add new Benificiary'
                }
              />
              {formik.errors.bankName && formik.touched.bankName ? (
                <div style={{ color: 'red' }}>{formik.errors?.bankName}</div>
              ) : null}
            </Grid>
            <Grid item md={12} xs={12}>
              <Input
                placeholder="Enter the Account holder Name*"
                value={formik?.values?.accountHolderName}
                onChange={(e) =>
                  formik.setFieldValue('accountHolderName', e?.target?.value)
                }
                disabled={
                  formik?.values?.beneficiary?.label !== 'Add new Benificiary'
                }
              />
              {formik.errors.accountHolderName &&
              formik.touched.accountHolderName ? (
                <div style={{ color: 'red' }}>
                  {formik.errors?.accountHolderName}
                </div>
              ) : null}{' '}
            </Grid>
            <Grid item md={12} xs={12}>
              <Input
                placeholder="Enter the Account Number*"
                value={formik?.values?.accountNumber}
                onChange={(e) =>
                  formik.setFieldValue('accountNumber', e?.target?.value)
                }
                disabled={
                  formik?.values?.beneficiary?.label !== 'Add new Benificiary'
                }
              />
              {formik.values.accountNumber &&
              (formik.errors.accountNumber || formik.touched.accountNumber) ? (
                <div style={{ color: 'red' }}>
                  {formik.errors?.accountNumber}
                </div>
              ) : null}
            </Grid>
            <Grid item md={12} xs={12}>
              <Input
                value={formik?.values?.ifscNumber}
                onChange={(e) =>
                  formik.setFieldValue('ifscNumber', e?.target?.value)
                }
                placeholder="Enter IFSC code*"
                disabled={
                  formik?.values?.beneficiary?.label !== 'Add new Benificiary'
                }
              />
              {formik.values.ifscNumber &&
              (formik.errors.ifscNumber || formik.touched.ifscNumber) ? (
                <div style={{ color: 'red' }}>{formik.errors?.ifscNumber}</div>
              ) : null}{' '}
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={12} xs={12}>
              <Input
                value={formik.values.upiId}
                onChange={(e) =>
                  formik.setFieldValue('upiId', e?.target?.value)
                }
                placeholder="Enter UPI ID*"
              />
              {formik.values.upiId &&
              (formik.errors.upiId || formik.touched.upiId) ? (
                <div style={{ color: 'red' }}>{formik.errors?.upiId}</div>
              ) : null}{' '}
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12}>
          <Grid>
            {' '}
            <h5> Account Proof</h5>
          </Grid>
          <Grid mt={3}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="accountProof"
              value={formik.values.accountProof}
              onChange={(value) => {
                formik.setFieldValue('accountProof', value);
              }}
              placeholder="upload document*"
            />
            {formik.errors.accountProof && formik.touched.accountProof ? (
              <div style={{ color: 'red' }}>{formik.errors?.accountProof}</div>
            ) : null}
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="outlined-uncontrolled"
            label="Enter the remarks"
            autoComplete="off"
            onChange={(e) => formik.setFieldValue('remarks', e?.target?.value)}
            placeholder="Enter the remarks"
          />
        </Grid>
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        backBtn={canGoBack}
        disabled={!(formik.isValid && formik.dirty)}
        reject={reject}
      />
    </form>
  );
};

export default SchedulePaymentForm;
