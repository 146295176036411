import React from 'react';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import {
  LeadStatus,
  LoanToBeClosedBy,
  PaymentFor,
  PaymentStatus,
  UserRole
} from 'src/generated/hooks_and_more';
import { format } from 'date-fns';
import { getUserData, titleCaseToReadable } from 'src/utils/utility';
import PurchaseOrderDetailList from 'src/components/Composite/PurchaseOrderDetail';

function PurchaseOrderDetails({regNo, source,leadId, currentLead, handlePaymentRaise}: any) {

  const statusEvents = currentLead?.statusEvents;
  const payments = currentLead?.payments;

  const dealAmountforPO = currentLead?.approvedDealAmount;

  const loanAmountforPO =
    currentLead?.vehicle?.financingDetails?.pendingLoanAmount;

  const documentChargesforPO = currentLead?.documentCharges;

  const loanCloserforPO =
    currentLead?.vehicle?.financingDetails?.tempLoanToBeClosedBy ??
    currentLead?.vehicle?.financingDetails?.loanToBeClosedBy;



  function getOldPurchaseStatus() {
    const hasApproved = statusEvents?.some(
      (status) => status?.status === 'PURCHASE_REQUEST_APPROVED'
    );

    const hasRaised = statusEvents?.some(
      (status) => status?.status === 'PURCHASE_REQUEST_RAISED'
    );

    const hasRejected = statusEvents?.some(
      (status) => status?.status === 'PURCHASE_REQUEST_REJECTED'
    );

    return hasApproved
      ? 'Approved'
      : hasRaised
      ? 'Pending'
      : hasRejected
      ? 'Rejected'
      : '-';
  }

  const getPurchaseOrderStatus = () => {
    const lastStatus = statusEvents?.[0]?.status;

    return lastStatus === 'PURCHASE_REQUEST_APPROVED'
      ? 'Approved'
      : lastStatus === 'PURCHASE_REQUEST_REJECTED'
      ? 'Rejected'
      : lastStatus === 'PURCHASE_REQUEST_RAISED'
      ? 'Pending'
      : getOldPurchaseStatus();
  };

  const purchaseOrderStatuss = getPurchaseOrderStatus();

  const deliveryAmountforPO = currentLead?.payments?.find(
    (p) =>
      p?.status !== PaymentStatus?.Rejected &&
      p?.for === PaymentFor.DealDelivery
  )?.amount;

  const holdbackAmountforPO = currentLead?.payments?.find(
    (p) => p?.for === PaymentFor.HoldbackRepayment
  )?.amount;

  const paymentTypeforPO = currentLead?.payments?.[0]?.type;

  const paymentAmountforPO = !!documentChargesforPO
    ? dealAmountforPO - documentChargesforPO
    : dealAmountforPO;

  const payToBankforPO = currentLead?.payments?.find(
    (p) =>
      p?.status !== PaymentStatus?.Rejected &&
      p?.for === PaymentFor.LoanRepayment
  )?.amount;
  const tokenAmountforPO = currentLead?.payments?.find(
    (p) =>
      p?.status !== PaymentStatus?.Rejected && p?.for === PaymentFor.DealToken
  )?.amount;

  const payToDealerforPO =
    loanCloserforPO === LoanToBeClosedBy.TractorJunction
      ? dealAmountforPO - loanAmountforPO - documentChargesforPO
      : dealAmountforPO - documentChargesforPO;

  const remarksforPO = currentLead?.statusEvents?.find(
    (s) => s?.status === LeadStatus?.PurchaseRequestRaised
  )?.remarks;

  const purchaseOrderStatus = !!statusEvents?.find(
    (s) => s?.status === LeadStatus.PurchaseRequestApproved
  )
    ? 'Approved'
    : !!statusEvents?.find(
        (s) => s?.status === LeadStatus.PurchaseRequestRejected
      )
    ? 'Rejected'
    : '-';
  const purchaseOrderApprovalDate = statusEvents?.find(
    (s) => s?.status === LeadStatus.PurchaseRequestApproved
  )?.createdAt;

  const purchaseDetails = statusEvents?.find(
    (s) => s?.status === LeadStatus.PurchaseRequestApproved
  );
  const userDetails = getUserData();
  const paymentsToShow = currentLead?.payments?.filter(
    (item, index, self) =>
      self?.findIndex((arr) => arr?.id === item?.id) === index &&
      item?.for !== PaymentFor.Refurbishment
  );
 
 


  const availableDocumentDetail: {
    isAvailableDoc: boolean;
    proofUrl?: string;
  }[] = [
    {
      isAvailableDoc: currentLead?.documentChecklist?.registrationCertificate,
      proofUrl: currentLead?.documents?.registrationCertificate
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.form26,
      proofUrl: currentLead?.documents?.form26
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.loanForeclosure,
      proofUrl: currentLead?.documents?.loanForeclosure
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.bankNOC,
      proofUrl: currentLead?.documents?.bankNOC
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.form35,
      proofUrl: currentLead?.documents?.form35
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.insuranceCertificate,
      proofUrl: currentLead?.documents?.insuranceCertificate
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.form28,
      proofUrl: currentLead?.documents?.form28
    },

    {
      isAvailableDoc: currentLead?.documentChecklist?.form29,
      proofUrl: currentLead?.documents?.form29
    },

    {
      isAvailableDoc: currentLead?.documentChecklist?.form30,
      proofUrl: currentLead?.documents?.form30
    },

    // {
    //   isAvailableDoc: item?.documentChecklist?.sellerPAN,
    //   proofUrl:currentLead?.documents?.sellerPAN,
    // },

    {
      isAvailableDoc: currentLead?.documentChecklist?.sellerAadharCard,
      proofUrl: currentLead?.documents?.sellerAadharCard
    },

    {
      isAvailableDoc: currentLead?.documentChecklist?.ownerAddressProof,
      proofUrl: currentLead?.documents?.ownerAddressProof
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.form60_sellerPan,
      proofUrl: currentLead?.documents?.form60_sellerPan
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.indemnityBond,
      proofUrl: currentLead?.documents?.indemnityBond
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.releaseOrder,
      proofUrl: currentLead?.documents?.releaseOrder
    },
    {
      isAvailableDoc: currentLead?.documentChecklist?.form36,
      proofUrl: currentLead?.documents?.form36
    }
  ];


  function isRequiredDocUploaded() {
    const documents = availableDocumentDetail
      .filter((i) => i?.isAvailableDoc)
      .map((i) => {
        return i?.proofUrl;
      });
    return !documents?.some((item) => !item);
  }

  let purchaseOrderDetails: {
    key?: string;
    value?: string | number;
    checkHoldBack?: boolean | string;
    checkForStatus?: PaymentFor;
    checkDocument?: boolean;
    metaData?: {
      title: string;
      data: { key: string; value: string | number }[];
    };
  }[] = [];

  purchaseOrderDetails = [
    {
      key: 'View Request',
      value: 'View',
      metaData: {
        title: 'Purchase Order',
        data: [
          {
            key: 'Loan To Be Closed By',
            value: loanCloserforPO ? titleCaseToReadable(loanCloserforPO) : ''
          },
          {
            key: 'Payment Type',
            value: paymentTypeforPO ? titleCaseToReadable(paymentTypeforPO) : ''
          },
          {
            key: 'Deal Amount',
            value: dealAmountforPO ?? '-'
          },
          {
            key: 'Loan Amount',
            value: loanAmountforPO ?? '-'
          },
          {
            key: 'Document Charges',
            value: documentChargesforPO ?? '-'
          },
          {
            key: 'Delivery Amount',
            value: deliveryAmountforPO ?? '-'
          },
          {
            key: 'Holdback Amount',
            value: holdbackAmountforPO ?? '-'
          },
          {
            key: 'Pay to Dealer',
            value: payToDealerforPO ?? '-'
          },
          {
            key: 'Remarks',
            value: remarksforPO ?? '-'
          }
        ]
      }
    },
    {
      key: 'Purchase Order',
      value: purchaseOrderStatuss ?? '-'
    },
    {
      key: 'PR Approval Date',
      value: purchaseOrderApprovalDate
        ? format(Date?.parse(purchaseOrderApprovalDate), 'dd MMM yyyy')
        : '-'
    }
  ];

  paymentsToShow?.map((p) => {
    
    if (
      p?.status === PaymentStatus.Approved &&
      userDetails.role === UserRole.ProcurementExecutive
    ) {
      purchaseOrderDetails = [
        ...purchaseOrderDetails,
        ...[
          {
            key: titleCaseToReadable(p?.for),
            value: `buttonfor${p?.for}`,
            checkHoldBack:
              p?.for === PaymentFor.HoldbackRepayment
                ? isRequiredDocUploaded()
                  ? 'holdbackEnable'
                  : 'holdBackDisable'
                : 'all',
            checkForStatus:  p?.for,
            checkDocument: isRequiredDocUploaded()
          }
        ]
      ];
    } else {
      purchaseOrderDetails = [
        ...purchaseOrderDetails,
        ...[
          {
            key: titleCaseToReadable(p?.for),
            value: p?.status
          }
        ]
      ];
    }
  });

  return <PurchaseOrderDetailList regNo={regNo} source={source} leadId={leadId} data={purchaseOrderDetails} handleRaisePaymentClick={handlePaymentRaise} />;
}

export default PurchaseOrderDetails;
