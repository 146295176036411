import moment from 'moment';
import {
  BankConfermation,
  Charges,
  DealStatus,
  WorkingOnStates,
  dateFormate,
  isRcAvailableArray
} from '../constants/constant';
import {
  AccessoryStatus,
  ActionType,
  BankName,
  BookingCancellationReason,
  BookingType,
  DeviceType,
  EnquiryStatus,
  EnquiryType,
  GetFilteredLeadsQueryVariables,
  ImageStage,
  InsuranceType,
  InsurerName,
  LeadBusinessStatus,
  LeadSource,
  LeadStatus,
  LoanRejectionReason,
  LoanToBeClosedBy,
  ModuleType,
  Ownership,
  PaymentMethod,
  PaymentStatus,
  PaymentType,
  SelectTypeOfDate,
  SparePart,
  UserRole,
  VehicleMake,
  useRefurbishmentRequestIdQuery
} from '../generated/hooks_and_more';
import { format } from 'date-fns';

import { makeVar } from '@apollo/client';
import { StateType, StatesType } from 'src/types';

export const cachedLeadFilter = makeVar<any | null>(null);
export const cachedEnquiryFilter = makeVar<any | null>(null);
export const cachedBookingFilter = makeVar<any | null>(null);
export const cacheLogFilter = makeVar<any | null>(null);

export const cachedDocumentFilter = makeVar<any | null>(null);
export const LeadFlowFormsInputs = makeVar<any | null>(null);
export const countIndex = makeVar<any | null>(null);
export const cachedRequestId = makeVar<any | null>(null);
export const cachedEnquiryDashBoardFilter = makeVar<any | null>(null);

export function titleCaseToReadable(str: string) {
  if (!str) return '';
  return str
    .split('_')
    .map((w) => upperCaseFirstLetter(w.toLowerCase()))
    .join(' ')
    .trim();
}

export function slugsToTitleCase(str: string) {
  if (!str) return '';
  return str
    .split('-')
    .map((w) => upperCaseFirstLetter(w.toLowerCase()))
    .join(' ')
    .trim();
}
export function enumToItems(
  e:
    | typeof LeadStatus
    | typeof ImageStage
    | typeof UserRole
    | typeof Ownership
    | typeof LeadBusinessStatus
    | typeof BankConfermation
    | typeof DealStatus
    | typeof VehicleMake
    | typeof BankName
    | typeof VehicleMake
    | typeof PaymentStatus
    | typeof SparePart
    | typeof LeadSource
    | typeof UserRole
    | typeof EnquiryStatus
    | typeof EnquiryType
    | typeof SelectTypeOfDate
    | typeof AccessoryStatus
    | typeof InsurerName
    | typeof InsuranceType
    | typeof PaymentMethod
    | typeof ImageStage
    | typeof LoanToBeClosedBy
    | typeof PaymentType
    | typeof BookingType
    | typeof LoanRejectionReason
    | typeof BookingCancellationReason
    | typeof DeviceType
    | typeof ModuleType
) {
  return Object.values(e).map((i) => {
    return {
      label: titleCaseToReadable(i),
      value: i
    };
  });
}

export const upperCaseFirstLetter = (s: string) =>
  `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`;

export const convertDateToFormate = (str: string): string => {
  return str;
};

export function getYearFromDate(date: string): string {
  if (date) {
    return moment(date).year().toString();
  }

  return '';
}

export function getDesireDateFormate(date: string | Date): string {
  if (date) {
    return moment(date).format(dateFormate);
  }

  return '';
}

export function getStatus(status: LeadStatus) {
  switch (status) {
    case LeadStatus.PickupAccepted:
      return 'Accepted';
    case LeadStatus.PickupRequested:
      return 'Approval Pending';
    case LeadStatus.PickupInitiated:
      return 'In Transit';
    case LeadStatus.DeliveryCompleted:
      return 'Delivered';
    case LeadStatus.DeliveryVehicleImagesUploaded:
      return 'Delivered';
    case LeadStatus.DeliverySelfieUploaded:
      return 'Delivered';
    case LeadStatus.DeliveryExpensesApproved:
      return 'Delivered';
    case LeadStatus.DeliveryExpensesPaymentReceiptsUploaded:
      return 'Delivered';
    case LeadStatus.DeliveryExpensesRejected:
      return 'Delivered';
    case LeadStatus.VehicleInStock:
      return 'Delivered';
    case LeadStatus.ReadyForSale:
      return 'Delivered';
    default:
      return '-';
  }
}

export function getCamelCase(str: string) {
  let strSplit = str?.split(' ');
  return strSplit?.reduce(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    (init = strSplit[0]?.toLocaleLowerCase(), curr, index) =>
      init +
      strSplit[index + 1]?.charAt(0).toUpperCase() +
      strSplit[index + 1]?.slice(1)?.toLocaleLowerCase()
  );
}
export function calculateParkingCharge({
  expectedPickupDate,
  repossessionDate,
  perDayParkingCharge
}: {
  expectedPickupDate: Date;
  repossessionDate: Date;
  perDayParkingCharge: number;
}) {
  // log('inputs to calculate parking charge', {
  //   expectedPickupDate,
  //   repossessionDate,
  //   perDayParkingCharge,
  // })
  const expectedPickup = new Date(expectedPickupDate);
  const expectedRepossession = new Date(repossessionDate);
  let estimatedTotalinTime = Math.abs(
    expectedPickup.getTime() - expectedRepossession.getTime()
  );
  let estimatedTotalDays = estimatedTotalinTime / (1000 * 3600 * 24);

  // console.log('Number of Days', estimatedTotalDays)
  let estimatedTotal = perDayParkingCharge * Math.round(estimatedTotalDays);

  // NOTE: if the estimated total is NaN, return 0 otherwise return the estimated total
  // This will make sure that the estimated total is not showing NaN in the UI
  return isNaN(estimatedTotal + perDayParkingCharge)
    ? 0
    : estimatedTotal + perDayParkingCharge;
}

export const validDateFormate = (date: string) => {
  if (date) {
    return format(Date.parse(date), 'dd MMM yyyy');
  }

  return '';
};

export const getDate = () => {
  let date = new Date();
  date.setHours(0, 0, 0, 0);

  return date;
};

export const Capitalize = (str: string) => {
  return str.charAt(0).toLocaleUpperCase() + str.slice(1, str.length);
};

export const getUserData = () => {
  const userDetails = JSON.parse(localStorage.getItem('userData'));

  return userDetails;
};

export const getDocStatusDate = (dateaArr) => {
  let followUpDates = [];
  dateaArr?.map((i) => {
    if (i?.nextFollowupDate !== null) {
      followUpDates?.push(new Date(i?.nextFollowupDate));
    }
  });

  const recentStatusDate =
    followUpDates?.length &&
    new Date(Math.max(...followUpDates)).toLocaleDateString();
  return recentStatusDate;
};

export function arraysHaveSameValues(arr1, arr2) {
  // Convert arrays to sets
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  // Check if sets have the same size and all values from set1 are in set2
  return arr1.length === arr2.length && arr1.every((value) => set2.has(value));
}

export function getBalanceAmountForBooking(
  payments,
  saleAmount: number,
  isRCRequired: boolean,
  isInsuranceRequired: boolean,
  isLoanRequired: boolean,
  appliedLoanAmount: number,
  sanctionedLoanAmount: number
) {
  const sumOfApprovedPayments: number = payments
    ?.filter((item) => item?.status === PaymentStatus.Approved)
    ?.reduce((acc: number, item) => acc + item?.amount, 0);
  // console.log('this is thesum of approved payments', sumOfApprovedPayments)

  const totalSaleAmount: number =
    saleAmount +
    (isRCRequired ? Charges.RTO_CHARGES : 0) +
    (isInsuranceRequired ? Charges.INSURANCE_CHARGES : 0);

  // amount should be approved loan amount
  // const totalLoanAmount = !!sanctionedLoanAmount
  //   ? sanctionedLoanAmount
  //   : appliedLoanAmount ?? 0
  // console.log('This is the totalLoanAmount', totalLoanAmount)
  let result = Number.isNaN(totalSaleAmount - sumOfApprovedPayments)
    ? '-'
    : totalSaleAmount - sumOfApprovedPayments;
  return result;
}

export function getScopedEventMetadata(
  lseId: string,
  leadDetailsData,
  desiredStatus
) {
  if (
    !leadDetailsData?.queryLead?.[0]?.statusEvents ||
    desiredStatus === LeadStatus.VehicleInspected
  ) {
    return;
  } else {
    return leadDetailsData?.queryLead?.[0]?.statusEvents?.find(
      (lse) => lse?.id === lseId
    )?.metadata;
  }
}

export function compareDate(
  d1: string,
  d2: string,
  isNotEqual?: boolean,
  isSmallerDate?: boolean
) {
  if (!!d1 && !!d2) {
    const date1 = new Date(d1).setHours(0, 0, 0, 0);
    const date2 = new Date(d2).setHours(0, 0, 0, 0);
    return isNotEqual
      ? isSmallerDate
        ? date1 < date2
        : date1 > date2
      : date1 >= date2;
  }
}
export const capitalizedWords = (inputString: string) => {
  return inputString
    ?.split(' ')
    ?.map(
      (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
    )
    ?.join(' ');
};
export function getStateOptions(states: StatesType[]) {
  let filteredstates = [];

  if (states?.length) {
    states?.map((state) => {
      if (WorkingOnStates?.includes(state?.state_name?.toLowerCase())) {
        filteredstates?.push({
          ...state,
          state_name: capitalizedWords(state?.state_name)
        });
      }
    });
  }
  return filteredstates;
}
export function formatDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    } as Intl.DateTimeFormatOptions;
    return date.toLocaleDateString('en-GB', options);
  } else {
    return '-';
  }
}

export function formatDatelog(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    } as Intl.DateTimeFormatOptions;
    return date.toLocaleDateString('en-GB', options);
  } else {
    return '-';
  }
}

export function generateString(length: number) {
  let result = ' ';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function getampmtime(time: number) {
  const dateString = time;

  const date = new Date(dateString);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  const formattedHours = hours % 12 || 12;

  const timeString = `${formattedHours}:${
    minutes < 10 ? '0' : ''
  }${minutes} ${ampm}`;

  return timeString;
}



function flattenObject(obj) {
  const flattened = {};

  function flatten(obj) {
    Object.keys(obj)?.forEach((key) => {
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        if (key === 'centre' || key === 'reportingManager') {
          flattened[key] = obj[key].name;
        } else {
          Object.assign(flattened, flatten(obj[key]));
        }
      } else {
        flattened[key] = obj[key];
      }
    });
    return flattened;
  }

  return flatten(obj);
}
export function checkWhetherAnythingisUpdated(previous, updated) {

  const previousObject =
    typeof previous === 'string' ? previous : flattenObject(previous);
  const updatedObject =
    typeof updated === 'string' ? updated : flattenObject(updated);
  let changeLogs = [];
  if (
    typeof previousObject === 'string' &&
    typeof previousObject === 'string'
  ) {
    changeLogs.push({
      label: 'data',
      previous: Boolean(previousObject) ? previousObject : 'No change',
      current: updatedObject
    });
  } else {
    Object?.keys(previousObject)?.map((item) => {
      if (Array.isArray(previousObject[item]) && Array.isArray(updatedObject[item])) {
        changeLogs.push({
          label: item,
          previous: previousObject?.[item]?.join(', ') ,
          current:  Boolean(updatedObject) ? updatedObject?.[item]?.join(', ')  : "no value"
         });
      }else if (previousObject?.[item] !== updatedObject?.[item]) {
        changeLogs.push({
          label: item,
          previous: previousObject?.[item],
          current: updatedObject?.[item]
        });
      }
    });
  }

  changeLogs = changeLogs?.filter((i) => i?.label !== 'id');
  return changeLogs;
}

export function getJSONDepth(jsonObj) {
  let maxDepth = 0;
  function traverse(obj, depth) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
        traverse(obj[key], depth + 1);
      }
    }
    maxDepth = Math.max(maxDepth, depth);
  }
  traverse(jsonObj, 0);
  return maxDepth;
}

export function getNextedValue(type, updatedValue) {
  let CreateData = [];
  let items = [];
  let count = getJSONDepth(JSON.parse(updatedValue));
  if (type === ActionType.Update) {
    CreateData = JSON.parse(updatedValue);
    let flattenedData = flattenObject(CreateData);
    Object.entries(flattenedData)?.forEach(([key, value]) => {
      if (key === 'items') {
        items.push(value);
      }
    });
    const extractedValues = items[0]?.map((item) => {
      return {
        price: item?.price,
        productName: item?.product?.name,
        quantity: item?.quantity
      };
    });
    return extractedValues;
  }

  if (count == 5) {
    if (type === ActionType.Create) {
      CreateData = JSON.parse(updatedValue);
      let flattenedData = flattenObject(CreateData);
      Object.entries(flattenedData)?.forEach(([key, value]) => {
        if (key === 'items') {
          items.push(value);
        }
      });
    }
    const extractedValues = items[0]?.map((item) => {
      return {
        price: item?.price,
        productName: item?.product?.name,
        quantity: item?.quantity,
        purchaseCentreId: item?.purchase?.centre?.id
      };
    });
    return extractedValues;
  }
}

// export function getDateRangeMonths(startDate, endDate) {
//   const months = [];
//   let currentDate = new Date(startDate);
export function getYearsFromDateRange(startDate, endDate) {
  // Copy the dates to avoid modifying the original objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Ensure that start and end dates are at the beginning and end of the day respectively
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  // Get the start year and end year
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();

  let year = startYear;

  const yearRanges = [];

  // Loop through each year in the range
  while (year <= endYear) {
    // Get the start date of the year
    const yearStartDate = new Date(year, 0, 1);
    // If year start date is before the range, set it to the range start
    if (yearStartDate < start) {
      yearStartDate.setTime(start.getTime());
    }

    // Get the end date of the year
    const yearEndDate = new Date(year, 11, 31);
    // If year end date is after the range, set it to the range end
    if (yearEndDate > end) {
      yearEndDate.setTime(end.getTime());
    }

    // Add the year range to the array
    yearRanges.push({
      label: year,
      start: yearStartDate,
      end: yearEndDate,
      key: makeid(10)
    });

    // Move to the next year
    year++;
  }

  return yearRanges;
}

// // Example usage
// const startDate = new Date('2024-01-15');
// const endDate = new Date('2024-03-31'); // Adjusted the end date to include March
// const result = getDateRangeMonths(startDate, endDate);
// console.log(result);

export function getWeeksBetweenDates(startDate, endDate) {
  // Copy the dates to avoid modifying the original objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Adjust start date to the first Monday before or on the given start date
  start.setDate(start.getDate() - ((start.getDay() + 6) % 7));

  let weekCount = 1;

  // Initialize an array to store the weeks
  const weeks = [];

  // Loop through the dates and push the week number to the array
  while (start <= end) {
    // Get the end date of the week (Sunday)
    const endOfWeek = new Date(start);
    endOfWeek.setDate(start.getDate() + 6);

    // Ensure start date is within input range
    const weekStartDate =
      start < startDate ? new Date(startDate) : new Date(start);
    weekStartDate.setHours(0, 0, 0, 0);

    // Ensure end date is within input range
    const weekEndDate =
      endOfWeek > endDate ? new Date(endDate) : new Date(endOfWeek);
    weekEndDate.setHours(23, 59, 59, 999);

    // Add the week information to the array
    weeks.push({
      label: `Week ${weekCount}`,
      start: weekStartDate,
      end: weekEndDate,
      key: makeid(10)
    });

    // Move to the next week
    start.setDate(start.getDate() + 7);
    weekCount++;
  }

  return weeks;
}

export function getDateRangeMonths(startDate, endDate) {
  // Copy the dates to avoid modifying the original objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Initialize an array to store the start and end dates of each month
  const months = [];

  // Start from the first day of the start month
  let currentMonth = new Date(start.getFullYear(), start.getMonth(), 1);

  // Iterate through each month until the end month
  while (currentMonth <= end) {
    // Calculate the start and end dates of the current month
    const startOfMonth = new Date(currentMonth);
    const endOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    );

    // Ensure the start date falls within the input range
    if (startOfMonth < start) {
      startOfMonth.setTime(start.getTime());
      startOfMonth.setHours(0, 0, 0, 0);
    }

    // Ensure the end date falls within the input range
    if (endOfMonth > end) {
      endOfMonth.setTime(end.getTime());
      endOfMonth.setHours(23, 59, 59, 999);
    }

    // Get the month name
    const monthName = startOfMonth.toLocaleString('en-US', { month: 'long' });

    // Add the start and end dates and month name of the current month to the array
    months.push({
      label: monthName,
      start: startOfMonth,
      end: endOfMonth,
      key: makeid(10)
    });

    // Move to the next month
    currentMonth.setMonth(currentMonth.getMonth() + 1);
  }

  return months;
}

export function makeid(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function filterUnique(arr, key) {
  const seen = new Set();
  return arr.filter((obj) => {
    const value = obj[key];
    if (seen.has(value)) {
      return false; // Filter out duplicate
    } else {
      seen.add(value.toUpperCase());
      return true; // Keep unique
    }
  });
}

// Example usage:
// let startDate = new Date('2020-01-01');
// let endDate = new Date('2023-12-31');
// let years = getYearsFromDateRange(startDate, endDate);
// console.log(years);

// Function to get ISO week number

export function forlogtitleCase(str: any) {
  if (typeof str === 'boolean') {
    return str;
  } else if (typeof str === 'number') {
    return str;
  } else if (!str) {
    return ' ';
  } else if (typeof str === 'string' && str.includes('_')) {
    let value = str
      .split('_')
      .map((w) => upperCaseFirstLetter(w.toLowerCase()))
      .join(' ')
      .trim();
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else if (typeof str === 'string') {
    let value = str
      .split('_')
      .map((w) => upperCaseFirstLetter(w.toLowerCase()))
      .join(' ')
      .trim();
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

export function handleJustEnquiry(obj, prefix = '') {
  let values = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        if (key !== 'initialValues' && key !== 'data') {
          values = values.concat(
            handleJustEnquiry(value, prefix ? `${prefix}.${key}` : key)
          );
        } else {
          values = values.concat(handleJustEnquiry(value, prefix));
        }
      } else if (value !== null) {
        if (key === 'createdAt' || key === 'updatedAt') {
          values.push(`${key}: ${formatDatelog(value)}`); // Call formatlog for 'createdAt' and 'updatedAt' keys
        } else {
          values.push(`${key}: ${value}`);
        }
        // values.push(`${key}: ${value}`);
      }
    }
  }
  return removeTypenameAndId(values);
}

function removeTypenameAndId(data) {
  return data.filter((entry) => {
    if (entry?.includes('interestedIn')) {
      return !entry.includes('__typename');
    } else {
      return !entry.includes('__typename') && !entry.includes('id');
    }
  });
}
