import { Timeline } from 'rsuite';
import './index.css';
import { Box, Typography } from '@mui/material';
import { titleCaseToReadable } from 'src/utils/utility';
import { format } from 'date-fns';
export default function TimelineStatus({ statuses }) {
  return (
    <Box>
      <Timeline>
        {statuses?.map((item, index) => {
          let taskNotCompletedStatus;
          const statusDate =
            format(Date.parse(item?.createdAt), 'dd-MM-yyyy') +
            ' ' +
            new Date(item.createdAt).toLocaleString([], {
              hour: 'numeric',
              minute: 'numeric'
            });

          if (!Boolean(item?.isTaskCompleted) && index === 0) {
            if (statuses[1]) {
              taskNotCompletedStatus = statuses[1]?.preTimeline;
            }
          }
          return (
            <>
              {!Boolean(item?.isTaskCompleted) && Boolean(item?.assignTaskTo) && (
                <Timeline key={index}>
                  <Timeline.Item classPrefix="pending">
                    <Typography
                      sx={{
                        width: '170px',
                        color: 'black',
                        mt: index === 0 ? '5px' : '-5px'
                      }}
                      variant="h5"
                      component="h1"
                    >
                      {item?.preTimeline}
                    </Typography>

                    <Typography component="span">{statusDate}</Typography>
                    <Typography component="p" sx={{ width: '200px' }}>
                      {'pending on ' + titleCaseToReadable(item?.assignTaskTo)}
                    </Typography>
                  </Timeline.Item>
                </Timeline>
              )}
              <Timeline>
                <Timeline.Item classPrefix="container">
                  <Typography
                    sx={{
                      width: '170px',
                      color: 'black',
                      mb: '5px'
                    }}
                    variant="h5"
                    component="h1"
                  >
                    {item?.postTimeline}
                  </Typography>
                  <Typography component="span">{statusDate}</Typography>
                  <Typography component="p" sx={{ width: '200px' }}>
                    {'by ' + item?.createdBy?.name}
                  </Typography>
                  <Typography component="p" sx={{ width: '200px' }}>
                    {'from  ' +
                      (!!item?.deviceType
                        ? titleCaseToReadable(item?.deviceType)
                        : '-')}
                  </Typography>
                  {item?.remarks && (
                    <Typography
                      component="p"
                      sx={{
                        width: '150px',
                        backgroundColor: 'RGB(220 220 220)',
                        color: 'rgb(31,39,55)',
                        padding: '5px',
                        borderRadius: '5px',
                        mt: '5px'
                      }}
                    >
                      {item?.remarks}
                    </Typography>
                  )}
                </Timeline.Item>
              </Timeline>
            </>
          );
        })}
      </Timeline>
    </Box>
  );
}
