import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import Calender from 'src/components/Calender/Calender';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import MultiSelectAutoComplete from 'src/components/Form/MultiSelectAutoComplete';
import CloseIcon from '@mui/icons-material/Close';
import {
  BankConfermation,
  DealStatus,
  isRcAvailableArray
} from 'src/constants/constant';
import {
  BankName,
  GetFilteredLeadsQueryVariables,
  LeadSource,
  LeadStatus,
  PaymentStatus,
  useGetAllNewBrandsQuery,
  useGetAllStateQuery,
  useGetBanklistQuery,
  useGetCentreListQuery,
  useGetModelByBrandIdLazyQuery,
  useGetUserByRoleQuery,
  UserRole
} from 'src/generated/hooks_and_more';
import {
  cachedLeadFilter,
  enumToItems,
  titleCaseToReadable
} from 'src/utils/utility';
import './leads.css';
import { AutoCompleteValueType } from 'src/types';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
const style = {
  position: 'absolute' as 'absolute',
  top: '55%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  // height: '80%',
  bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '10px',
  p: 2
};

const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '15px',
  cursor: 'pointer',
  color: 'rgb(195, 62, 62)'
};

type LeadFilterPropsType = {
  showModal: boolean;
  setShowModal: any;
  handleClose: () => void;
  onFilterHandler: (variables: GetFilteredLeadsQueryVariables) => void;
  filterLoading: boolean;
  // reset: any;
};

type modelType = {
  id?: string;
  model?: string;
};

type auctionAgencyType = {
  id: string;
  name: string;
};

type VehicleMake = {
  brand_id?: number;
  name?: string;
  id?: string;
};

type StatusType = {
  label: string;
  value: any;
};

type StateType = {
  state_id: string;
  state_name: string;
};

function LeadsFilter({
  showModal,
  setShowModal,
  handleClose,
  onFilterHandler,
  filterLoading
}: // reset
LeadFilterPropsType) {
  // const [leadListData, setLeadListData] = useState<LeadPreviewFragment[]>([]);
  const [leadSatusValue, setLeadSatusValue] = useState<StateType[] | any>([]);
  const [dealStatusValue, setDealStatusValue] = useState<StatusType | any>(
    null
  );
  const { loggedInUser } = useLoggedInUser();
  const showTheirLeadsOnly = [
    UserRole.Driver,
    UserRole.ProcurementExecutive
  ]?.includes(loggedInUser?.role);
  const [bankConfirmationStatusValue, setBankConfirmationStatusValue] =
    useState<StatusType | any>(null);
  const [paymentCompletedValue, setpaymentCompletedValue] = useState<
    StatusType | any
  >(null);

  const [stateValue, setStateValue] = useState<StateType[] | any>([]);

  const [make, setMake] = useState<VehicleMake | null>();
  const [modelValue, setModelValue] = useState<modelType[] | any>([]);
  const [driverNameValue, setDriverNameValue] = useState<
    auctionAgencyType[] | any
  >([]);
  const [PE_NameValue, setPE_NameValue] = useState<auctionAgencyType[] | any>(
    null
  );
  const [sourceBankValue, setSourceBankValue] = useState<StatusType[] | any>(
    []
  );
  const [centerNameValue, setCenterNameValue] = useState<
    auctionAgencyType[] | any
  >([]);
  const [isRcAvailable, setIsRcAvailable] = useState<StatusType | any>(null);

  const [auctionAgencyValue, setAuctionAgencyValue] = useState<
    auctionAgencyType[] | any
  >([]);

  const [purchaseDateRange, setPurchaseDateRange] = useState<
    [Date, Date] | null
  >();
  const [pickupDateRange, setPickupDateRange] = useState<[Date, Date] | null>();
  const [repossessionDateRange, setRepossessionDateRange] = useState<
    [Date, Date] | null
  >();

  const [leadSource, setLeadSource] = useState<AutoCompleteValueType | null>(
    null
  );

  const dealStatusOptions = enumToItems(DealStatus);

  const sourceBankOptions = enumToItems(BankName);
  const leadStatusOptions: StatusType[] = enumToItems(LeadStatus);
  const bankConfermationOptions = enumToItems(BankConfermation);
  const paymentStatus = enumToItems(PaymentStatus);
  const center = useGetCentreListQuery();
  const stateOption = useGetAllStateQuery();

  const source = enumToItems(LeadSource);

  const brandListOptions = useGetAllNewBrandsQuery();

  const [getModels, { data: modelOptions, loading }] =
    useGetModelByBrandIdLazyQuery();

  const bankAgencyNameOptions = useGetBanklistQuery();
  const { data: driverList } = useGetUserByRoleQuery({
    variables: {
      role: UserRole.Driver
    }
  });
  const { data: PE_List } = useGetUserByRoleQuery({
    variables: {
      role: UserRole.ProcurementExecutive
    }
  });
  const PE_Names = PE_List?.queryUser;
  const onFilterClick = () => {
    const statuses: LeadStatus[] =
      leadSatusValue?.map((item) => item.value as LeadStatus) ?? [];
    console.log({ make });
    // const brand = make?.map((item) => item?.name ?? '') ?? [];
    const vehicleBrand = make ? [make?.brand_id] : [];

    const state: string[] =
      stateValue?.map((item) => item?.state_name ?? '') ?? [];
    const vehicleModel: string[] = modelValue?.map(
      (item) => item?.model_id ?? ''
    );

    const rc = isRcAvailable?.value as boolean;
    const center = centerNameValue.map((item) => item.name as string);
    const driver = driverNameValue.map((item) => item.name as string);
    const bank = sourceBankValue.map((item) => item.value as BankName);
    const auction = auctionAgencyValue.map((item) => item.name as string);
    let start = pickupDateRange?.length ? pickupDateRange[0] : '';
    let end = pickupDateRange?.length ? pickupDateRange[1] : '';
    const pickupDate = { start, end };
    start = repossessionDateRange?.length ? repossessionDateRange[0] : '';
    end = repossessionDateRange?.length ? repossessionDateRange[1] : '';
    const repossessionDate = { start, end };
    start = purchaseDateRange?.length ? purchaseDateRange[0] : '';
    end = purchaseDateRange?.length ? purchaseDateRange[1] : '';
    const purchaseDate = { start, end };
    const source = leadSource?.value as LeadSource;
    const paymentStatus = paymentCompletedValue?.value as PaymentStatus;

    let variables = {
      statuses: statuses,
      vehicleBrand: vehicleBrand,
      registrationState: state,
      isRcAvailable: rc,
      vehicleModel: vehicleModel,
      centreName: center,
      driverName: driver,
      sourcedBankName: bank,
      auctioningAgencyName: auction,
      paymentStatus: paymentStatus,
      purchaseYearsRange: purchaseDate,
      repositionDateRange: repossessionDate,
      pickupDateRange: pickupDate,
      source: source,
      createdBy: PE_NameValue?.id
    };

    cachedLeadFilter({ ...variables, offset: 1 });

    if (dealStatusValue?.value) {
      variables = Object.assign(
        { dealStatus: dealStatusValue?.value as DealStatus },
        variables
      );
    }

    if (bankConfirmationStatusValue?.value) {
      variables = Object.assign(
        {
          bankConfirmationStatus:
            bankConfirmationStatusValue?.value as BankConfermation
        },
        variables
      );
    }

    console.log(variables, 'vari');
  };

  const onResetHandler = () => {
    setLeadSource(null);
    setDealStatusValue(null);
    setLeadSatusValue([]);
    setBankConfirmationStatusValue(null);
    setpaymentCompletedValue(null);

    setStateValue([]);
    setMake(null);
    setModelValue([]);
    setDriverNameValue([]);
    setSourceBankValue([]);

    setCenterNameValue([]);
    setIsRcAvailable(null);

    setAuctionAgencyValue([]);

    setPurchaseDateRange(null);
    setPickupDateRange(null);
    setRepossessionDateRange(null);
    if (showTheirLeadsOnly) {
      cachedLeadFilter({
        offset: 1,
        pageSize: 20,
        createdBy: loggedInUser?.userId
      });
    } else {
      cachedLeadFilter({ offset: 1, pageSize: 20 });
    }

    // console.log("reset clicked");
    // getFilteredLeads();
  };

  const onDateChange = (e: DateRange | null, placehldr: string) => {
    console.log(e, 'asdfghj..............', placehldr);
    if (placehldr == 'Purchase year date range') {
      setPurchaseDateRange(e ? e : null);
    } else if (placehldr == 'Pickup Date range') {
      setPickupDateRange(e ? e : null);
    } else if (placehldr == 'Repossession date range') {
      setRepossessionDateRange(e ? e : null);
    }
  };

  console.log({ modelOptions }, 'sweta');

  return (
    <>
      <Grid>
        {/* <Button
          sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
          variant="outlined"
          onClick={() => {
            onResetHandler();
          }}
          // onClick={() => {
          //   if (location.state) {
          //     location.state = null;
          //   }
          //   getFilteredLeads({});
          // }}
        >
          Reset
        </Button> */}
      </Grid>
      <Grid item>
        <Button
          sx={{ mt: { xs: 0, md: 0 } }}
          variant="contained"
          onClick={() => {
            setShowModal(true);
          }}
          // disabled={filterLoading}
          // startIcon={filterLoading ? <CircularProgress size={20} /> : null}
        >
          Filter
        </Button>
      </Grid>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={closeBtnStyle}>
            <CloseIcon
              onClick={() => {
                setShowModal(false);
              }}
            />
          </Box>
          <Grid
            container
            spacing={1}
            style={{ overflowY: 'auto', height: '360px', padding: '20px' }}
          >
            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => setLeadSatusValue(value)}
                value={leadSatusValue}
                options={leadStatusOptions}
                optionString="label"
                placeholder="Lead status"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setLeadSource(value)}
                value={leadSource}
                options={source}
                placeholder="Lead Source"
                optionString="label"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setBankConfirmationStatusValue(value)}
                value={bankConfirmationStatusValue}
                options={bankConfermationOptions}
                optionString="label"
                placeholder="Bank Confirmation Status"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => {
                  console.log({ value });
                  setMake(value);
                  setModelValue(null);
                  if (value?.brand_id) {
                    getModels({
                      variables: { brand_id: [Number(value?.brand_id)] }
                    });
                  }
                }}
                value={make}
                // options={
                //   brandListOptions?.data?.queryBrand.map((i) => ({ id: i?.id, name: i?.name })) ?? []
                // }
                options={brandListOptions?.data?.queryBrand}
                optionString="name"
                placeholder="Brand"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => setModelValue(value)}
                value={modelValue ?? []}
                disabled={!make?.name}
                options={modelOptions?.queryBrand?.[0]?.models ?? []}
                placeholder="Model"
                optionString="name"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => setCenterNameValue(value)}
                value={centerNameValue}
                placeholder="Center Name"
                options={center?.data?.queryCentre ?? []}
                optionString="name"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setDealStatusValue(value)}
                value={dealStatusValue}
                options={dealStatusOptions}
                placeholder="Deal Status"
                optionString="label"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => setStateValue(value)}
                value={stateValue}
                options={
                  stateOption?.data?.getStateInfo?.data?.map((i) => ({
                    state_id: i?.state_id,
                    state_name: titleCaseToReadable(i?.state_name),
                    tr_state_id: i?.tr_state_id
                  })) ?? []
                }
                placeholder="State"
                optionString="state_name"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => setAuctionAgencyValue(value)}
                value={auctionAgencyValue}
                options={bankAgencyNameOptions?.data?.queryAgency ?? []}
                optionString="name"
                placeholder="Auction agency name"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <Calender
                placeholder="Purchase year date range"
                onDateChange={onDateChange}
                filteredDate={purchaseDateRange}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => setSourceBankValue(value)}
                value={sourceBankValue}
                options={sourceBankOptions}
                optionString="label"
                placeholder="Sourced Bank Name"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <Calender
                placeholder="Pickup Date range"
                onDateChange={onDateChange}
                filteredDate={pickupDateRange}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setIsRcAvailable(value)}
                value={isRcAvailable}
                options={isRcAvailableArray}
                // options={isRcAvailableArray}
                optionString="label"
                placeholder="RC Available"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => setpaymentCompletedValue(value)}
                value={paymentCompletedValue}
                options={paymentStatus}
                placeholder="Payment completed?"
                optionString="label"
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <MultiSelectAutoComplete
                onChange={(value) => {
                  setDriverNameValue(value);
                }}
                value={driverNameValue}
                options={driverList?.queryUser ?? []}
                optionString="name"
                placeholder="Driver name"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <InputAutoComplete
                onChange={(value) => {
                  setPE_NameValue(value);
                }}
                value={PE_NameValue}
                options={PE_Names ?? []}
                optionString="name"
                placeholder="PE Name"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Calender
                placeholder="Repossession date range"
                onDateChange={onDateChange}
                filteredDate={repossessionDateRange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              padding: '20px',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Grid
              sx={{
                justifyContent: 'center',
                display: 'flex',
                gap: '10px'
              }}
              md={12}
            >
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                // style={{ marginRight: '20px' }}
                style={{ width: '110px' }}
                variant="contained"
                onClick={() => {
                  onResetHandler();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                // style={{ width: '120px' }}
                variant="contained"
                onClick={onFilterClick}
                disabled={filterLoading}
                startIcon={
                  filterLoading ? <CircularProgress size={20} /> : null
                }
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default LeadsFilter;
