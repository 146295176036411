import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, Grid, TextField } from '@mui/material';
import Input from 'src/components/Form/Input';
import * as Yup from 'yup';
import './form.css';
import {
  BankName,
  LeadRef,
  LeadSource,
  LeadStatus,
  useGetAllNewBrandsQuery,
  useGetAllNewModelsByMakeLazyQuery,
  useGetLeadDealDetailsQuery
} from 'src/generated/hooks_and_more';
import { enumToItems } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { RtoValidationSchema } from '../ValidationSchema/ValidationSchema';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

const RTOformLead = (props: LeadCreateAndUpdateProps) => {
  const {
    desireButtonText,
    undesireButtonText,
    leadId,
    regNo,
    source,
    desireStatus,
    toggleDrawer,
    onPressOfDesiredOrUndesiredButton,
    canGoBack
  } = props;
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const { data: allBrands } = useGetAllNewBrandsQuery();
  const [GetAllNewModelsByMake, { data: modelOptions }] =
    useGetAllNewModelsByMakeLazyQuery();
  // const [hypo, setHypo] = useState(false);
  // const [challan, setChallan] = useState(false);
  const [blackListed, setBlackListed] = useState(false);
  // const [hsrp, setHsrp] = useState(false);
  const BankNameOptions = enumToItems(BankName);
  const {data, loading: dataloading} = useGetLeadDealDetailsQuery({
    variables: {
      id: leadId
    }
  })
  const formik = useFormik({
    initialValues: {
      make: null,
      model: null,
      manufacturingYear: null,
      rtoVerificationProof: '',
      bankName: null,
      challanAmnt: '',
      challanRadio: "no",
      hypoRadio: "no",
      hypoProof: '',
      challanProof: '',
      engineNo: '',
      chasisNo: '',
      blackListConfirmation: '',
      hsrpProof: '',
      hsrpRadio: "no",
      blackListedRadio: false,
      approvalMail: '',
      enterRemark: ''
    },
    validationSchema: RtoValidationSchema,
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        vehicle: {
          isRcAvailable: data?.getLead?.vehicle?.isRcAvailable,
          isVehicleInsured: data?.getLead?.vehicle?.isVehicleInsured,
          tempVehicleBrand: {
            id: values?.make?.id
          },
          tempVehicleModel: {
            id: values?.model?.id
          },
          tempManufacturingDate: values?.manufacturingYear,
          tempChassisNumber: values?.chasisNo,
          tempEngineNumber: values?.engineNo,
          isHypo: values.hypoRadio == 'yes' ? true : false,
          isChallanAvailable: values.challanRadio == 'yes' ? true : false,
          challanAmount: values?.challanAmnt
            ? Number(values?.challanAmnt)
            : null,
          isBlacklisted: blackListed,
          isHSRPAvailable: values.hypoRadio == 'yes' ? true : false,
          financingDetails: {
            tempFinancerName: values?.bankName?.value
          },
          documents: {
            rtoVerificationProofUrl: values?.rtoVerificationProof,
            challanUrl: values?.challanProof,
            hypothecationProofUrl: values?.hypoProof,
            hsrbProofUrl: values?.hsrpProof,
            approvalMailUrl: values?.approvalMail,
            blacklistProofUrl: values?.blackListConfirmation
          }
        }
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });


  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="Upload-Invoice"
            value={formik.values?.rtoVerificationProof}
            onChange={(value) =>
              formik.setFieldValue('rtoVerificationProof', value)
            }
            placeholder="RTO Verification Proof*"
          />
          {formik.errors.rtoVerificationProof &&
          formik.touched.rtoVerificationProof ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.rtoVerificationProof}
            </div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <InputAutoComplete
            onChange={(value) => {
              formik.setValues({
                ...formik.values,
                model: null,
                make: value
              });
              GetAllNewModelsByMake({ variables: { id: value?.id } });
            }}
            value={formik?.values?.make}
            placeholder="Make*"
            options={allBrands?.queryBrand ?? []}
            optionString="name"
            getOptionLabel={(option) => option?.name}
          />
          {formik.errors.make && formik.touched.make ? (
            <div style={{ color: 'red' }}>{formik.errors?.make}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.model}
            disabled={!formik.values.make}
            options={modelOptions?.queryBrand?.[0]?.models ?? []}
            optionString="name"
            name="model"
            onChange={(value) => {
              formik.setFieldValue('model', value);
            }}
            placeholder="Model*"
          />
          {formik.errors.model && formik.touched.model ? (
            <div style={{ color: 'red' }}>{formik.errors?.model}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.engineNo}
            name="EngNo"
            onChange={(e) => formik?.setFieldValue('engineNo', e.target.value)}
            placeholder="Engine Number*"
          />
          {formik.values.engineNo &&
          (formik.errors.engineNo || formik.touched.engineNo) ? (
            <div style={{ color: 'red' }}>{formik.errors?.engineNo}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.chasisNo}
            name="ChasNo"
            onChange={(e) => formik?.setFieldValue('chasisNo', e.target.value)}
            placeholder="Chassis Number*"
          />
          {formik.values.chasisNo &&
          (formik.errors.chasisNo || formik.touched.chasisNo) ? (
            <div style={{ color: 'red' }}>{formik.errors?.chasisNo}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year']}
              openTo="year"
              value={formik?.values?.manufacturingYear ?? null}
              onChange={(newValue) => {
                formik?.setFieldValue('manufacturingYear', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  autoComplete='off'
                  id="outlined-basic"
                  variant="outlined"
                  value={formik?.values?.manufacturingYear}
                  style={{
                    width: '100%'
                  }}
                  error={false}
                  label={'Select Manufacturing Year*'}
                  placeholder={'Select Manufacturing Year'}
                />
              )}
              minDate={new Date('2000-01-01')}
              maxDate={new Date(`${new Date().getFullYear() + 1}-01-01`)}
            />
          </LocalizationProvider>
          {formik.errors.manufacturingYear &&
          formik.touched.manufacturingYear ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.manufacturingYear}
            </div>
          ) : null}
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={12}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="hypoProof"
              value={formik.values.hypoProof}
              onChange={(value) => {
                formik.setFieldValue('hypoProof', value);
              }}
              placeholder="Hypothecation proof*"
            />
            {formik.errors.hypoProof && formik.touched.hypoProof ? (
              <div style={{ color: 'red' }}>{formik.errors?.hypoProof}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue={formik.values.hypoRadio}
                sx={{
                  width: '300px',
                  marginLeft: '10px',
                  display: 'flex',
                  gap: '10px'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('hypoRadio', "yes")}
                  label="Yes Hypo"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('hypoRadio', "no")}
                  control={<Radio />}
                  label="No Hypo"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>


        {formik.values.hypoRadio === "yes" && (
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              onChange={(value) => {
                formik.setFieldValue('bankName', value);
              }}
              value={formik.values.bankName}
              placeholder="Financer Name*"
              options={BankNameOptions}
              optionString="label"
              getOptionLabel={(option) => option.name}
            />
            {formik.errors.bankName && formik.touched.bankName ? (
              <div style={{ color: 'red' }}>{formik.errors?.bankName}</div>
            ) : null}
          </Grid>
        )}
        <Grid item container spacing={2}>
          <Grid item xs={12} md={12}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="challanProof"
              value={formik.values.challanProof}
              onChange={(value) => {
                formik.setFieldValue('challanProof', value);
              }}
              placeholder="Challan proof*"
            />
            {formik.errors.challanProof && formik.touched.challanProof ? (
              <div style={{ color: 'red' }}>{formik.errors?.challanProof}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue={formik.values.challanRadio}
                sx={{
                  width: '300px',
                  marginLeft: '10px',
                  display: 'flex',
                  gap: '10px'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('challanRadio', "yes")}
                  label="Challan Found"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('challanRadio', "no")}
                  control={<Radio />}
                  label="No Found"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {formik.values.challanRadio  === "yes" && (
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.challanAmnt}
              name="challanAmnt"
              onChange={(e) =>
                formik?.setFieldValue('challanAmnt', e.target.value)
              }
              placeholder="Challan Amount*"
            />
            {(formik.values.challanAmnt && formik.errors.challanAmnt) ||
            formik.touched.challanAmnt ? (
              <div style={{ color: 'red' }}>{formik.errors?.challanAmnt}</div>
            ) : null}
          </Grid>
        )}
        <Grid item container spacing={2}>
          <Grid item xs={12} md={12}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="blackListConfirmation"
              value={formik.values.blackListConfirmation}
              onChange={(value) => {
                formik.setFieldValue('blackListConfirmation', value);
              }}
              placeholder="BlackList Confirmation*"
            />
            {formik.errors.blackListConfirmation &&
            formik.touched.blackListConfirmation ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.blackListConfirmation}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue={'notBlacklisted'}
                sx={{
                  width: '300px',
                  marginLeft: '10px',
                  display: 'flex',
                  gap: '10px'
                }}
              >
                <FormControlLabel
                  value="blacklisted"
                  control={<Radio />}
                  onClick={() => setBlackListed(true)}
                  label="BlackListed"
                />
                <FormControlLabel
                  value="notBlacklisted"
                  onClick={() => setBlackListed(false)}
                  control={<Radio />}
                  label="Not BlackListed"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={12}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="hsrpProof"
              value={formik.values.hsrpProof}
              onChange={(value) => {
                formik.setFieldValue('hsrpProof', value);
              }}
              placeholder="HSRP proof*"
            />
            {formik.errors.hsrpProof && formik.touched.hsrpProof ? (
              <div style={{ color: 'red' }}>{formik.errors?.hsrpProof}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue={formik.values.hsrpRadio}
                sx={{
                  width: '300px',
                  marginLeft: '10px',
                  display: 'flex',
                  gap: '10px'
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  onClick={() => formik.setFieldValue('hsrpRadio', "yes")}
                  label="Available"
                />
                <FormControlLabel
                  value="no"
                  onClick={() => formik.setFieldValue('hsrpRadio', "no")}
                  control={<Radio />}
                  label="Not Available"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="approvalMail"
            value={formik.values.approvalMail}
            onChange={(value) => {
              formik.setFieldValue('approvalMail', value);
            }}
            placeholder="Approval Mail(if applicable)"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            autoComplete='off'
            id="outlined-uncontrolled"
            label="Enter the remarks"
            onChange={(e) => formik.setFieldValue('remarks', e?.target?.value)}
            placeholder="Enter the remarks"
          />
        </Grid>
      </Grid>

      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        undesireButtonText={undesireButtonText}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
};

export default RTOformLead;
