import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DealDetailModal from './DealDetailModal';
import { Button } from '@mui/material';
import LeadProcess from 'src/screens/lead-flow';
import {
  LeadSource,
  LeadStatus,
  PaymentFor,
  UserRole
} from 'src/generated/hooks_and_more';
import React, { useState } from 'react';
import { getUserData, titleCaseToReadable } from 'src/utils/utility';
import RaisePaymentButton from 'src/screens/lead-flow/form/RaisePaymentButton/button';

type DetailsOptions = {
  key?: string;
  value?: any;
  type?: string;
  textColor?: string;
  isHidden?: boolean;
  metaData?: { title: string; data: { key: string; value: string | number }[] };
  checkHoldBack?: boolean | string;
  checkForStatus?: PaymentFor;
  checkDocument?: boolean;
};

type DetailsTabPanelPropsType = {
  data: DetailsOptions[];
  handleRaisePaymentClick?: () => void;
  regNo?: string;
  source?: LeadSource;
  leadId?: string;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const widthStyle = {
  width: '50%'
};

function PurchaseOrderDetailList({
  data,
  leadId,
  regNo,
  source,
  handleRaisePaymentClick
}: DetailsTabPanelPropsType) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  console.log(leadId, regNo, 'jsdjs');

  const userDetails = getUserData();

  const [open, setOpen] = useState(false);

  const [desiredStatus, setDesiredStatus] = useState(null);
  const [title, setTitle] = useState(null);

  function setValueCurrentStatus(payFor: PaymentFor) {
    switch (payFor) {
      case PaymentFor.DealToken:
        return LeadStatus.DealTokenPaymentRequested;
      case PaymentFor.DealDelivery:
        return LeadStatus.DealDeliveryPaymentRequested;
      case PaymentFor.HoldbackRepayment:
        return LeadStatus.HoldbackRepaymentRequested;
      case PaymentFor.LoanRepayment:
        return LeadStatus.LoanRepaymentRequested;
      case PaymentFor.DealPayment:
        return LeadStatus.DealerPaymentRequested;
    }
  }

  return (
    <>
      <TableContainer sx={{ mt: 3 }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            {data?.map((item, index: number) => {
              console.log(item?.checkForStatus, data, 'QWERTYUI');
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    style={{
                      fontWeight: item.value === '#title' ? '900' : 'normal'
                    }}
                    component="th"
                    scope="row"
                    sx={widthStyle}
                  >
                    {item.key}
                  </StyledTableCell>
                  {item.value !== '#title' ? (
                    (item.type === 'image' ||
                      (item.type === 'map' && item.isHidden)) &&
                    item.value &&
                    item.value !== '-' ? (
                      <StyledTableCell component="th" scope="row">
                        <Link
                          href={item.value}
                          style={{
                            color: 'rgb(17,25,42)',
                            textDecoration: 'underline',
                            display: 'flex',
                            justifyContent: 'flex-start'
                          }}
                          underline="hover"
                          target="_blank"
                        >
                          View document
                        </Link>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell
                        style={{
                          color: item?.textColor ? item?.textColor : 'primary'
                        }}
                      >
                        {item.value === 'View' ? (
                          <>
                            <DealDetailModal
                              metadata={item?.metaData ?? null}
                            />
                          </>
                        ) : item.checkHoldBack == 'all' ? (
                          <Button
                            variant="contained"
                            onClick={() => {
                              let desiredStatusValue = setValueCurrentStatus(
                                item?.checkForStatus
                              );
                              setDesiredStatus(desiredStatusValue);
                              setOpen(true);
                              setTitle(item?.checkForStatus);
                            }}
                          >
                            Raise payment
                          </Button>
                        ) : item.key === 'Holdback Repayment' &&
                          userDetails.role ===
                            UserRole.ProcurementExecutive &&
                          item?.value !== 'DONE' ? (
                          <>
                            <Button
                              disabled={
                                item?.checkHoldBack !== 'holdbackEnable'
                              }
                              variant="contained"
                              style={
                                item?.checkHoldBack === 'holdbackEnable'
                                  ? { backgroundColor: 'primary' }
                                  : { border: 'grey 1px' }
                              }
                              onClick={() => {
                                if (
                                  item?.checkForStatus ===
                                    PaymentFor.HoldbackRepayment &&
                                  item?.checkHoldBack === 'holdbackEnable'
                                ) {
                                  if (item?.checkDocument) {
                                    let desiredStatusValue =
                                      setValueCurrentStatus(
                                        item.checkForStatus
                                      );
                                    setOpen(true);
                                    setDesiredStatus(desiredStatusValue);
                                  } else {
                                    return null;
                                  }
                                  setTitle(item?.checkForStatus);
                                }
                              }}
                            >
                              Raise payment
                            </Button>
                          </>
                        ) : (
                          item?.value ?? '-'
                        )}
                      </StyledTableCell>
                    )
                  ) : (
                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <RaisePaymentButton
        open={open}
        setOpen={setOpen}
        currentStatus={desiredStatus}
        leadId={leadId}
        regNo={regNo}
        payFor={title}
        source={source}
        title={`${titleCaseToReadable(title)} Details`}
      />
    </>
  );
}

export default PurchaseOrderDetailList;
