import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { formatDate, titleCaseToReadable } from 'src/utils/utility';
import { Grid, Link, Table } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { TabContext } from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { format } from 'date-fns';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  width: '45%'
};

const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  cursor: 'pointer',
  margin: '0px 0px 10px 0px'
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableRowEven = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function RefurbishDetailSubTable({
  title,
  paymentDetailList,
  itemConfirmList,
  itemsList,
  BreakDownList,
  onClickValue,
  handleClick
}: any) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('Request');
  const defaultTab = 'Request';

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const checkurl = itemConfirmList?.data?.some(
    (item, index) => item?.url !== null || item?.url == ''
  );
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <p
        onClick={handleOpen}
        style={{ color: 'blue', cursor: 'pointer', fontWeight: 'bold' }}
      >
        View{' '}
      </p>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} style={{ overflow: 'auto', maxHeight: '75%' }}>
          <Box sx={closeBtnStyle}>
            <Typography
              id="custom-underline"
              fontWeight="bold"
              sx={{ fontSize: '22px', margin: '0px 0px 10px 30px' }}
            >
              {title
                ? `${titleCaseToReadable(title)} ${value} ${onClickValue + 1}`
                : '-'}
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                '& .css-1pyy021-MuiTabs-flexContainer': {
                  backgroundColor: '#dfdfdf',
                  justifyContent: 'space-around'
                },
                borderRadius: '10px',
                margin: '0px 20px 0px 20px'
              }}
            >
              {open && (
                <TabList
                  onChange={handleChange}
                  sx={{
                    boxShadow: ' 3px 2px 5px 1px #a7a7a7',
                    borderRadius: '10px'
                  }}
                >
                  <Tab
                    style={{ width: '50%', borderRadius: '10px' }}
                    label="Request"
                    value="Request"
                  />
                  <Tab
                    style={{ width: '50%', borderRadius: '10px' }}
                    label="Payment"
                    value="Payment"
                  />
                </TabList>
              )}
            </Box>
            <TabPanel value="Request" style={{ width: '100%' }}>
              <Table style={{ width: '100%' }}>
                <tbody>
                  {itemsList?.data?.map((item, index) => (
                    <>
                      <StyledTableRow sx={{ width: 1 }}>
                        <StyledTableCell
                          id="keep-mounted-modal-title"
                          sx={{ width: 1 / 4 }}
                        >
                          {item?.key}
                          <p>{item?.description}</p>
                        </StyledTableCell>
                        <StyledTableCell
                          id="keep-mounted-modal-title"
                          sx={{ width: 1 / 4 }}
                        >
                          {!!item?.value ? `₹${item?.value}` : '-'}
                        </StyledTableCell>

                        <StyledTableCell
                          id="keep-mounted-modal-description"
                          sx={{ width: 1 / 4 }}
                        >
                          {!!item?.url ? (
                            <Link
                              href={`${item?.url}`}
                              style={{
                                color: 'rgb(17,25,42)',
                                textDecoration: 'underline'
                              }}
                              underline="hover"
                              target="_blank"
                            >
                              View
                            </Link>
                          ) : (
                            '-'
                          )}
                        </StyledTableCell>

                        <StyledTableCell
                          id="keep-mounted-modal-title"
                          sx={{ width: 1 / 4 }}
                        >
                          {item?.status === 'APPROVED' && (
                            <CheckCircleIcon
                              sx={{ cursor: 'pointer' }}
                              fontSize="small"
                              color="success"
                            />
                          )}
                          {item?.status === 'REQUESTED' && (
                            <ErrorIcon
                              sx={{ cursor: 'pointer' }}
                              fontSize="small"
                              color="warning"
                            />
                          )}
                          {item?.status === 'REJECTED' && (
                            <CancelIcon
                              sx={{ cursor: 'pointer' }}
                              fontSize="small"
                              color="error"
                            />
                          )}{' '}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))}
                </tbody>
              </Table>

              {checkurl && (
                <Typography fontWeight="bold" sx={{ fontSize: '22px' }}>
                  {itemConfirmList?.title
                    ? titleCaseToReadable(itemConfirmList?.title)
                    : '-'}
                </Typography>
              )}

              {checkurl && (
                <Table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <tbody>
                    {itemConfirmList?.data?.map((item, index) => (
                      <>
                        <StyledTableRow sx={{ width: '100%' }}>
                          <StyledTableCell
                            id="keep-mounted-modal-title"
                            sx={{ width: 2 / 4 }}
                          >
                            {item?.key}
                            <p>{item?.description}</p>
                          </StyledTableCell>

                          {!!item?.url && item?.value !== '-' ? (
                            <StyledTableCell
                              id="keep-mounted-modal-description"
                              sx={{ width: 2 / 4 }}
                            >
                              <Link
                                href={`${item?.url}`}
                                style={{
                                  color: 'rgb(17,25,42)',
                                  textDecoration: 'underline'
                                }}
                                underline="hover"
                                target="_blank"
                              >
                                View Document
                              </Link>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell
                              id="keep-mounted-modal-description"
                              sx={{ width: 2 / 4 }}
                            >
                              {item?.value ?? '-'}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      </>
                    ))}
                  </tbody>
                </Table>
              )}
            </TabPanel>
            <TabPanel value="Payment" style={{ width: '100%' }}>
              <Table
                style={{
                  width: '100%',
                  tableLayout: 'fixed',
                  borderCollapse: 'collapse'
                }}
              >
                <tbody>
                  {paymentDetailList?.data?.map((item, index) => (
                    <React.Fragment key={index}>
                      <React.Fragment>
                        <StyledTableRow style={{ width: '50%' }}>
                          <StyledTableCell colSpan={3}>
                            {item?.key} 
                          </StyledTableCell>

                          {item?.url == 'url' && !!item?.value ? (
                            <StyledTableCell
                              sx={{ width: '50%', textAlign: 'center' }}
                            >
                              <Link
                                href={`${item?.value}`}
                                style={{
                                  color: 'rgb(17,25,42)',
                                  textDecoration: 'underline'
                                }}
                                underline="hover"
                                target="_blank"
                              >
                                View Document
                              </Link>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell
                              id="keep-mounted-modal-description"
                              // colSpan={3}
                              sx={{ width: '50%', textAlign: 'center' }}
                            >
                              {item?.status === 'Date' && !!item?.value
                                ? formatDate(item?.value)
                                : item?.value ?? '-'}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      </React.Fragment>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
              <Table
                style={{
                  width: '100%',
                  tableLayout: 'fixed',
                  borderCollapse: 'collapse'
                }}
              >
                <tbody>
                  <React.Fragment>
                    {BreakDownList?.data?.length > 0 && (
                      <React.Fragment>
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Typography
                              display="inline"
                              fontWeight="bold"
                              component="span"
                              sx={{ fontSize: '22px', width: '100%' }}
                            >
                              {BreakDownList?.title
                                ? titleCaseToReadable(BreakDownList?.title)
                                : '-'}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        {BreakDownList?.data?.map((item, index) => (
                          <StyledTableRow key={index} style={{ width: '100%' }}>
                            <StyledTableCell
                              style={{ width: '33%', paddingRight: '5px' }}
                            >

                              {item?.key}
                             <p>{item?.description} </p> 
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                width: '33%',
                                paddingRight: '100px'
                              }}
                            >
                              {item?.value ?? '-'}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: '33%', paddingRight: '5px' }}
                            >
                              {item?.status === 'url' && !!item?.url ? (
                                <Link
                                  href={`${item?.url}`}
                                  style={{
                                    color: 'rgb(17,25,42)',
                                    textDecoration: 'underline'
                                    // display: 'flex',
                                    // justifyContent: 'flex-start'
                                  }}
                                  underline="hover"
                                  target="_blank"
                                >
                                  view
                                </Link>
                              ) : (
                                '-'
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </tbody>
              </Table>
              <hr />
              <Table
                style={{
                  width: '100%',
                  tableLayout: 'fixed',
                  borderCollapse: 'collapse'
                }}
              >
                <tbody>
                  {BreakDownList?.paymentDetailList?.map((item, index) => (
                    <React.Fragment key={`key-${index}`}>
                      <React.Fragment>
                        <StyledTableRowEven style={{ width: '50%' }}>
                          <StyledTableCell>{item?.key}</StyledTableCell>
                          {item?.url == 'url' && !!item?.value ? (
                            <StyledTableCell
                              sx={{ width: '50%', textAlign: 'center' }}
                            >
                              <Link
                                href={`${item?.value}`}
                                style={{
                                  color: 'rgb(17,25,42)',
                                  textDecoration: 'underline'
                                }}
                                underline="hover"
                                target="_blank"
                              >
                                View Document
                              </Link>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell
                              id="keep-mounted-modal-2"
                              // colSpan={3}
                              sx={{ width: '50%', textAlign: 'center' }}
                            >
                              {item?.status === 'Date' && !!item?.value
                                ? formatDate(item?.value)
                                : item?.value ?? '-'}

                            </StyledTableCell>
                          )}
                        </StyledTableRowEven>
                      </React.Fragment>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
    </div>
  );
}
