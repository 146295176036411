import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ActionType } from 'src/constants/constant';
import { useToasts } from 'react-toast-notifications';
import { titleCaseToReadable } from 'src/utils/utility';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root>h2': {
    padding: 0
  }
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  style?: any;
}
type ModalProps = {
  open: boolean;
  handleClose: () => void;
  onConfirmationHandler: (actionType?: ActionType) => void;
  title?: string

};
function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 5,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export default function ConfirmationPopup({
  open,
  handleClose,
  onConfirmationHandler,
  title
  
  
}: ModalProps) {
  const { addToast } = useToasts();


  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ p: 0 }}
      >
        <BootstrapDialogTitle
          style={{ display: 'flex', justifyContent: 'end', color: 'red' }}
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <Box sx={{ width: '400px', padding: '10px' }}>
          <DialogTitle
            style={{
              cursor: 'move',
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 600
            }}
            id="draggable-dialog-title"
          >
            Raise  {title} Payment Request
          </DialogTitle>
          <DialogContent sx={{ textAlign: 'center' }}>
            <Typography gutterBottom>

                Do you wish to raise payment request For {title}
            </Typography>
          </DialogContent>

         
          <DialogActions
            sx={{
              marginBottom: '0.3rem',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
           
              <>
           
                <Button
                  style={{ backgroundColor: 'red', width: '100px' }}
                  variant="contained"
                  id="notconfirm"
                  onClick={() => {handleClose();}}
                  size="medium"
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                  
                      onConfirmationHandler(ActionType.DESIRED);
                      addToast(`${titleCaseToReadable(title)} Successfully`, {

                        appearance: 'success',
                        autoDismiss: true
                      });
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);

                  }}
                  sx={{ width: '100px' }}
                >
                  Yes
                </Button>
              </>
            
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </div>
  );
}
