import { environments } from 'src/config';

export const primary = ' ';
export const dateFormate = 'DD-MM-YYYY';
export enum BankConfermation {
  VERIFIED = 'VERIFIED',
  NOT_VERIFIED = 'NOT_VERIFIED'
}
export enum EnquiryDashboardType {
  BY_CENTRE = 'enquiry-by-centre',
  FOLLOWUP_ADHERENCE_BY_CENTRE = 'followup-adherence-by-Centre'
}
export enum Charges {
  RTO_CHARGES = 7500,
  INSURANCE_CHARGES = 11000
}

export enum ActionType {
  DESIRED = 'DESIRED',
  UNDESIRED = 'UNDESIRED'
}

export const DEFAULT_TRACTOR_IMAGE =
  'https://www.tractorjunction.com/assets/images/images/default-image.jpg';
export enum DealStatus {
  CONFIRMED = 'CONFIRMED',
  NOT_CONFIRMED = 'NOT_CONFIRMED'
}

export const isRcAvailableArray = [
  {
    label: 'yes',
    value: true
  },
  {
    label: 'no',
    value: false
  }
];

// As WorkingOnStates depends on a utility function,mention states in lower case
export const WorkingOnStates = ['rajasthan', 'maharashtra', 'madhya pradesh'];

export const CENTER_TABLE_HEADER = [
  { title: 'Name' },
  { title: 'State' },
  { title: 'City' },
  { title: 'Pincode' },
  { title: 'Vehicle Stocked' },
  // { title: 'Refurb Stock' },
  { title: 'Status' },
  { title: 'Spoc Name' },
  { title: 'Created Date' },
  { title: 'Last Updated On' }
];

export const STATEGST_TABLE_HEADER = [
  { title: 'State' },
  { title: 'GSTIN Number' },
  { title: 'Status' },
  { title: 'Created At' },
  { title: 'Updated At' },
  { title: 'Edit' }
];

export const USER_TABLE_HEADER = [
  { title: 'Name' },
  { title: 'Email' },
  { title: 'Role' },
  { title: 'Registration State' },
  { title: 'Centre' },
  { title: 'Created Date' },
  { title: 'Updated Date' },

  { title: 'Reporting Manager' },
  { title: 'Status' },
  { title: 'Action' }
];

export const ENQUIRY_TABLE_HEADER = [
  { title: 'Lead Type' },
  { title: 'Tractor Booked', dbKey: 'tractorBookedCount' },
  { title: 'Purchased New Tractor', dbKey: 'purchaseNewTractorCount' },
  { title: 'Purchased Used Tractor', dbKey: 'purchasedUsedCount' },
  { title: 'Not Interested', dbKey: 'notInterestedCount' },
  { title: 'Customer Not Responding', dbKey: 'notRespondingCount' }
];

export const STOCK_TABLE_HEADER = [
  { title: 'Stock' },
  { title: 'Total purchases' },
  { title: 'Available In stock (Qty)' },
  { title: 'Issued (Qty)' },
  // { title: 'Purchase queue (Qty)' },
  { title: 'Avg. Unit Price (₹)' },
  { title: 'Total Price (₹)' },
  { title: 'Created Date' }
  // { title: 'Updated Date' }
];

export const ENQURY_TABLE_HEADER = [
  { title: 'Customer Name' },
  { title: 'Mobile' },
  { title: 'State' },
  { title: 'District' },
  { title: 'Tehsil' },
  { title: 'Budget' },
  { title: 'Enquiry Status' },
  { title: 'Last Updated On' },
  { title: 'Enquiry Type' },
  { title: 'Center' },
  { title: 'Follow-ups Count' },
  { title: 'Date Of Enquiry' },
  { title: 'Next Follow up on' },
  { title: 'Created By' },
  { title: 'Enquiry Created Date' }
];

export const STOCKLIST_TABLE_HEADER = [
  { title: 'Purchase Id ' },
  { title: 'Type' },
  { title: 'Status' },
  { title: 'Status Date' },
  {title: 'Quantity'},
  { title: 'Unit Price (In INR)' },
  { title: 'Created By' },
  { title: 'Created Date' },
  { title: 'Last Updated on' }
];

export const LeadListTableHeader = [
  {
    title: 'Registration No.'
    // search: true
  },
  {
    title: 'Status'
  },
  {
    title: 'Pending On'
  },
  {
    title: 'Purchase Type'
    // search: true
  },
  {
    title: 'Make'
    // search: true
  },
  {
    title: 'Model'
    // search: true
  },
  {
    title: 'Mfg. Year'
  },
  {
    title: 'Created by'
  },
  {
    title: 'Alloted center'
  },
  {
    title: 'Delivery center'
  },
  {
    title: 'Created date'
  },
  {
    title: 'Updated Date'
  }
];

export const ImageAtStage = [
  {
    label: 'Lead Generated',
    value: 'LEAD_GENERATED'
  },
  {
    label: 'Before Delivery',
    value: 'BEFORE_DELIVERY'
  },
  {
    label: 'After Delivery',
    value: 'AFTER_DELIVERY'
  },

  {
    label: 'Delivery Selfie',
    value: 'DELIVERY_SELFIE'
  },

  {
    label: 'Listing',
    value: 'LISTING'
  },
  {
    label: 'Delivery Image',
    value: 'DELIVERY_IMAGE'
  },
  {
    label: 'Transfer Pickup',
    value: 'TRANSFER_PICKUP'
  },
  {
    label: 'Transfer Drop',
    value: 'TRANSFER_DROP'
  },
  {
    label: 'Transfer Drop Selfie',
    value: 'TRANSFER_DROP_SELFIE'
  }
];

export const TaskListTableHeader = [
  {
    title: 'Registration No'
    // search: true
  },
  {
    title: 'Status'
  },
  {
    title: 'Pending On'
  },

  {
    title: 'Task Title'
    // search: true
  },
  {
    title: 'Make'
    // search: true
  },
  {
    title: 'Created At'
  }
];
export const NotificationTableHeader = [
  {
    title: 'Registration No'
    // search: true
  },
  {
    title: 'Notification Title'
  },
  {
    title: 'Make'
  },

  {
    title: 'Created At'
  }
];
// for booking table
export const BookingListTableHeader = [
  { title: 'Registration Number' },
  { title: 'Customer Name' },
  { title: 'Phone No.' },
  { title: 'State' },
  { title: 'District' },
  { title: 'Booking Status' },
  { title: 'Status Date' },
  { title: 'Payment Mode' },
  { title: 'Sale Amount' },
  { title: 'Balance Payment' },
  { title: 'Create Date' },
  { title: 'Last Updated Date' },
  { title: 'Edit' }
];

export const LeadCountRowHeader = [
  {
    title: 'Lead Status'
  },
  {
    title: 'Status',
    dbKey: 'count'
  }
  // {
  //   title: 'View Lead'
  // }
];

export const VahanFunnelHeader = [
  {
    title: 'Total RTO Requested'
  },
  {
    title: 'RTO Confirmed'
  },
  {
    title: 'RTO Pending'
  }
];

export const LogisticFunnelHeader = [
  {
    title: 'Ready for Pickup'
  },
  {
    title: 'Assigned Pickups'
  },
  {
    title: 'In Transit'
  },
  {
    title: 'Vehicle Delivered'
  }
];

export const DriverReportRowHeader = [
  {
    title: 'Driver Name'
  },
  {
    title: 'Assigned Pickup',
    dbKey: 'assignedPickupsCount'
  },
  {
    title: 'Accepted',
    dbKey: 'assignedPickupsAcceptedCount'
  },
  {
    title: 'Rejected or Withdrawn',
    dbKey: 'assignedPickupsRejectedOrWithdrawnCount'
  },
  {
    title: 'In Transit',
    dbKey: 'assignedPickupsInTransitCount'
  },
  {
    title: 'Vehicle Delivered',
    dbKey: 'assignedPickupsDeliveredCount'
  }
];

export const ENQURY_DETAILS_TABLE_HEADER = [
  {
    title: 'Customer Name',
    key: 'name'
  },
  {
    title: 'Mobile',
    key: 'phoneNo'
  },
  {
    title: 'State',
    key: 'state'
  },
  {
    title: 'District',
    key: 'district'
  },
  {
    title: 'Tehsil',
    key: 'tehsil'
  },
  {
    title: 'Budget',
    key: 'budget'
  },
  {
    title: 'Demanded Tractors',
    key: 'interestedInProducts'
  },

  {
    title: 'Enquiry Status',
    key: 'isClosed'
  },
  {
    title: 'Last Updated On',
    key: 'updatedAt'
  },
  {
    title: 'Enquiry Closure Reason',
    key: 'enquiryClosureReason'
  },
  {
    title: 'Lead Type',
    key: 'status'
  },
  {
    title: 'Tentative Purchase',
    key: 'tentativePurchase'
  },
  {
    title: 'Walkin type',
    key: 'enquiryType'
  },
  {
    title: 'Center',
    key: 'centreName'
  },
  {
    title: 'Date Of Enquiry',
    key: 'enquiryMadeOn'
  },
  {
    title: 'Follow-ups Count',
    key: 'count'
  },
  {
    title: 'Next Follow up on',
    key: 'nextfollowupDate'
  },
  {
    title: 'Last Visit Date',
    key: 'lastVisitDate'
  },
  {
    title: 'Created By',
    key: 'createdByname'
  },
  {
    title: 'Created Date',
    key: 'enquiryCreatedDate'
  }
];
export const BankFunnelRowHeader = [
  { title: 'Bank Name' },
  { title: 'Vehicle in Auction', dbKey: 'vehicleInAuction' },
  { title: 'Vaahan Checked', dbKey: 'vahanChecked' },
  { title: 'Won in Auction', dbKey: 'wonInAuction' },
  { title: 'Approval received', dbKey: 'approvalRecived' }
  // { title: 'View Lead' }
];

export const CentreInventoryRowHeader = [
  {
    title: 'Center Name'
  },
  {
    title: 'Total Inventory',
    dbKey: 'totalInventory'
  },
  {
    title: 'Vehicle In Stock',
    dbKey: 'inStockVehiclesCount'
  },
  {
    title: 'Booked',
    dbKey: 'bookedVehiclesCount'
  },
  {
    title: 'Sold & Delivered',
    dbKey: 'soldAndDeliveredVehicleCount'
  }
];

export const procurementvsbookingRowHeader = [
  {
    title: 'Brand'
  },
  {
    title: 'Model'
  },

  {
    title: 'Average Booking Cost'
  },
  {
    title: 'Average Procurement Cost'
  }
];

export const dummyData = [
  {
    bankName: 'DCB Bank',
    vehicleInAuction: '23',
    vaahanChecked: '33',
    wonInAuction: '45',
    approvalRecived: '34'
  },
  {
    bankName: 'DCB Bank',
    vehicleInAuction: '23',
    vaahanChecked: '33',
    wonInAuction: '45',
    approvalRecived: '34'
  },
  {
    bankName: 'DCB Bank',
    vehicleInAuction: '23',
    vaahanChecked: '33',
    wonInAuction: '45',
    approvalRecived: '34'
  },
  {
    bankName: 'DCB Bank',
    vehicleInAuction: '23',
    vaahanChecked: '33',
    wonInAuction: '45',
    approvalRecived: '34'
  },
  {
    bankName: 'DCB Bank',
    vehicleInAuction: '23',
    vaahanChecked: '33',
    wonInAuction: '45',
    approvalRecived: '34'
  },
  {
    bankName: 'DCB Bank',
    vehicleInAuction: '23',
    vaahanChecked: '33',
    wonInAuction: '45',
    approvalRecived: '34'
  },
  {
    bankName: 'DCB Bank',
    vehicleInAuction: '23',
    vaahanChecked: '33',
    wonInAuction: '45',
    approvalRecived: '34'
  },
  {
    bankName: 'DCB Bank',
    vehicleInAuction: '23',
    vaahanChecked: '33',
    wonInAuction: '45',
    approvalRecived: '34'
  }
];

export const DealershipFunnelRowHeader = [
  { title: 'PE name' },
  { title: 'Lead generated ', dbKey: 'leadCreatedCount' },
  { title: 'Price received ', dbKey: 'priceRecivedCount' },
  { title: 'Deal Closed ', dbKey: 'dealClosedCount' }
  // { title: 'View Lead' }
];
export const EnquiryByCentreRowHeader = [
  { title: 'Center' },
  { title: 'Hot ' },
  { title: 'Warm ' },
  { title: 'Cold ' },
  { title: 'Grand ' }
];
export const EnquiryByCentreClosureRows = [
  { title: 'In Followups ' },
  { title: 'Closed ' },
  { title: 'Tractor Booked ' },
  { title: 'Purchase New Tractor' },
  { title: 'Purchase Used Tractor' },
  { title: 'Not Interested' },
  { title: 'Customer Not Responding' }
];
export const FollwupAdherenceByCentreRowHeader = [
  { title: 'Center Name' },
  { title: 'Total Enquiries' },
  { title: 'Opened Enquiries' },
  { title: 'Closed Enquiries' },
  { title: 'Total Followups ' },
  { title: 'Closed Followups' },
  {
    title: 'Ageing Avg (In Days)',
    info: '(Average Ageing Logic / Total Enquiries)'
  },
  {
    title: 'Adherence Ratio ',
    info: 'Closed Followups/Total Followups (Excluding default followup that is created at the time of logging a enquiry)'
  }
];
export const manufacturingYearOptions = () => {
  const currentYear = new Date().getFullYear() - 1;

  const years = Array(20)
    .fill('')
    .map((item, index) => {
      return { label: (currentYear - index + 1).toString() };
    });

  console.log(years);
  return years;
};

export const PendingLeadListTableHeader = [
  {
    title: 'Registration No.'
    // search: true
  },
  {
    title: 'Document Status'
  },
  // {
  //   title: 'Status Date'
  // },
  {
    title: 'Required Docs'
    // search: true
  },
  {
    title: 'Received Docs'
    // search: true
  },
  {
    title: 'Pending Docs'
    // search: true
  },
  {
    title: 'Purchase Type'
    // search: true
  },
  {
    title: 'Make'
    // search: true
  },
  {
    title: 'Model'
    // search: true
  },
  {
    title: 'Mfg. Year'
  },
  {
    title: 'Created date'
  },
  {
    title: 'Updated Date'
  }
];

export const DocumentStatusHeader = [
  {
    title: 'Docs Required'
  },
  {
    title: 'Received'
  },
  {
    title: 'Pending'
  }
];

export const documentChecklist = [
  {
    label: 'Bank NOC',
    value: 'bankNOC'
  },
  {
    label: 'Form 26',
    value: 'form26'
  },
  {
    label: 'Form 28',
    value: 'form28'
  },
  {
    label: 'Form 29',
    value: 'form29'
  },
  {
    label: 'Form 30',
    value: 'form30'
  },
  {
    label: 'Form 35',
    value: 'form35'
  },
  {
    label: 'Form 60',
    value: 'form60'
  },

  {
    label: 'Indemnity Bond',
    value: 'indemnityBond'
  },
  {
    label: 'Release Order',
    value: 'releaseOrder'
  },
  {
    label: 'Insurance Certificate',
    value: 'insuranceCertificate'
  },
  {
    label: 'Loan Foreclosure',
    value: 'loanForeclosure'
  },
  {
    label: 'Registration Certificate',
    value: 'registrationCertificate'
  },
  {
    label: 'Seller AadharCard',
    value: 'sellerAadharCard'
  },
  {
    label: 'Seller PAN',
    value: 'sellerPan'
  },
  {
    label: 'Form 36',
    value: 'form36'
  },
  {
    label: 'Owner Address Proof',
    value: 'ownerAddressProof'
  }
];

export const DealConfirmFormForDealerShip = [
  {
    title: 'Demand Amount',
    value: 'demandAmount'
  },
  {
    title: 'Make',
    value: 'name'
  },
  {
    title: 'Model',
    value: 'name'
  },
  {
    title: 'Year',
    value: 'manufacturingDate'
  },
  {
    title: 'Ownership',
    value: 'ownershipType'
  },
  {
    title: 'Hours Meters'
  },
  {
    title: 'Registration no.'
  },
  {
    title: 'Images'
  }
];

export const POPUP_BID_AMOUNT_LIMIT = '_Lead.bidAmountLimit_';
export const POPUP_FINAL_BID_AMOUNT = '_Lead.finalBidAmount_';
export const POPUP_DEAL_DEMANDED_AMOUNT = '_Lead.demandedAmount_';
export const POPUP_DEAL_PROPOSED_AMOUNT = '_Lead.proposedDealAmount_';
export const POPUP_REG_NO = '_Lead.regNo_';
export const POPUP_DRIVER_NAME = '_Lead.driver_';
export const POPUP_DELIVERY_EXPENSE = '_Lead.DeliveryExpense_';
export const POPUP_UPDATE_LEAD_HEADER = 'Update Lead';
export const POPUP_UPDATE_LEAD_DESCRIPTION = 'Do you want to Update lead?';
export const POPUP_CREATE_LEAD_HEADER = 'Fresh Lead';
export const POPUP_CREATE_LEAD_DESCRIPTION = 'Do you want to create new lead?';
export const POPUP_DUPLICATE_CREATE_LEAD_HEADER = 'Create Lead Access Denied';
export const POPUP_DUPLICATE_CREATE_LEAD_DESCRIPTION =
  'already exists in the system';
export const POPUP_HOLD_BACK_AMOUNT = '_Lead.holdBackAmount_';
export const POPUP_LEAD_SELLING_PRICE = '_Lead.sellingPrice_';
export const POPUP_LEAD_LISTING_PRICE = '_Lead.listingPrice_';
export const RTO_CHARGES = 7500;
export const INSURANCE_CHARGES = 11000;

export const APPROVED_REFURBISHMENT_ITEMS_COUNT =
  '_refurbishment.NumberOfApprovedItems_';
export const APPROVED_REFURBISHMENT_ITEM_COST =
  '_refurbishment.TotalCostOfApprovedItems_';
export const REFURBISHMENT_PAYMENT_AMOUNT = '_Lead.refurbishmentPaymentAmount_';

export const BOOKING_TOKEN = '_Lead.bookingTokenAmount_';
export const BOOKING_PART_PAYMENT = '_Lead.bookingPartPaymentAmount_';
export const BOOKING_DELIVERY_PAYMENT = '_Lead.bookingDeliveryPayment_';
export const BOOKING_PRICE = '_Lead.bookingPrice_';
export const TOTAL_SALE_AMOUNT = '_Lead.totalSaleAmount_';
export const BOOKING_LOAN_AMOUNT = '_Lead.bookingLoanAmount_';

export const TYRE_CONDITION = 4;
export const ENGINE_CONDITION = 4;
// export const Config = {
//   env: '',
//   apiUrl: 'http://35.154.33.195:8080/graphql',
//   keyPrefix: 'dev/',
//   listingUrl: 'https://a2tj.tractorfirst.com//api/service/vms/used-tractor/add',
//   listingToken: 'LJ3jWKVCrPKZESTJfiSeoETYPjyZenXU5PfXKZPeikVtPW45LmgC',
//   sentry_dsn:
//     'https://e7e22127b467458fae6a67059df1c73d@o4504473698369536.ingest.sentry.io/4504473701187584',
//   vehicleIdApiUrl: 'https://www.tractorjunction.com/api/service/vms/idsbynames',
//   vehicleIdApiKey: '2R3Lzmsp7fEdJkZ3zpTXKqcst94AamAupwdxdixSE7U9Rv7UdUy',
//   enableHiddenFeatures: true,
//   updateTractorStatusApiUrl:
//     'https://d1tj.tractorfirst.com/api/service/vms/used-tractor/update/status'
// };

export const ENV = {
  development: {
    apiUrl: 'http://35.154.33.195:8080/graphql',
    showRoleDropdown: true,
    keyPrefix: 'dev/',
    vehicleIdApiUrl:
      'https://www.tractorjunction.com/api/service/vms/idsbynames',
    vehicleIdApiKey: '2R3Lzmsp7fEdJkZ3zpTXKqcst94AamAupwdxdixSE7U9Rv7UdUy',
    listingUrl:
      'https://a2tj.tractorfirst.com/api/service/vms/used-tractor/add',
    listingToken: 'LJ3jWKVCrPKZESTJfiSeoETYPjyZenXU5PfXKZPeikVtPW45LmgC',
    updateTractorStatusApiUrl:
      'https://d1tj.tractorfirst.com/api/service/vms/used-tractor/update/status'
  },
  localhost: {
    apiUrl: 'https://c274-115-246-91-179.ngrok-free.app/graphql',
    showRoleDropdown: true,
    keyPrefix: 'dev/',
    vehicleIdApiUrl:
      'https://www.tractorjunction.com/api/service/vms/idsbynames',
    vehicleIdApiKey: '2R3Lzmsp7fEdJkZ3zpTXKqcst94AamAupwdxdixSE7U9Rv7UdUy',
    listingUrl:
      'https://a2tj.tractorfirst.com/api/service/vms/used-tractor/add',
    listingToken: 'LJ3jWKVCrPKZESTJfiSeoETYPjyZenXU5PfXKZPeikVtPW45LmgC',
    updateTractorStatusApiUrl:
      'https://d1tj.tractorfirst.com/api/service/vms/used-tractor/update/status'
  },
  staging: {
    apiUrl: 'https://vms-alb.tractorjunction.in:444/graphql',
    showRoleDropdown: true,
    keyPrefix: 'staging/',
    vehicleIdApiUrl:
      'https://www.tractorjunction.com/api/service/vms/idsbynames',
    vehicleIdApiKey: '2R3Lzmsp7fEdJkZ3zpTXKqcst94AamAupwdxdixSE7U9Rv7UdUy',
    listingUrl:
      'https://a2tj.tractorfirst.com/api/service/vms/used-tractor/add',
    listingToken: 'LJ3jWKVCrPKZESTJfiSeoETYPjyZenXU5PfXKZPeikVtPW45LmgC',
    updateTractorStatusApiUrl:
      'https://d1tj.tractorfirst.com/api/service/vms/used-tractor/update/status'
  },
  production: {
    apiUrl: 'https://vms-alb.tractorjunction.in/graphql',
    showRoleDropdown: false,
    keyPrefix: 'production/',
    vehicleIdApiUrl:
      'https://www.tractorjunction.com/api/service/vms/idsbynames',
    vehicleIdApiKey: '2R3Lzmsp7fEdJkZ3zpTXKqcst94AamAupwdxdixSE7U9Rv7UdUy',
    listingUrl:
      'https://www.tractorjunction.com/api/service/vms/used-tractor/add',
    listingToken: '2R3Lzmsp7fEdJkZ3zpTXKqcst94AamAupwdxdixSE7U9Rv7UdUy',
    updateTractorStatusApiUrl:
      'https://d1tj.tractorfirst.com/api/service/vms/used-tractor/update/status'
  }
};

export function getConfigFromEnv() {
  return ENV?.[environments.toLowerCase()];
}

export const EnquiryDashboardOptions = [
  { label: 'Enquiry By Centre', value: EnquiryDashboardType.BY_CENTRE },
  {
    label: 'Follow-ups adherence by centre',
    value: EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE
  }
];

export const ComparisonType = [
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Yearly', value: 'Yearly' }
];
