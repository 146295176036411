import { LeadDetailsPropsType } from './ApplicationDetails';
import {
  LeadFlowFormsInputs,
  countIndex,
  titleCaseToReadable
} from 'src/utils/utility';
import {
  LeadStatus,
  PaymentFor,
  PaymentStatus
} from 'src/generated/hooks_and_more';
import RefurbishmentRequestListDisplay from 'src/components/Composite/RefurbishmentListRow';
import { useReactiveVar } from '@apollo/client';
import { any } from 'prop-types';

export default function RefurbishmentDetailList({
  currentLead,
  handlerefurbishment
}: LeadDetailsPropsType) {
  const countIndexValue = useReactiveVar(countIndex);
  let refurDetails: {
    key?: string;
    value?: string | number;
    checkForViewDoc?: boolean;
    view?: boolean;
    title?: string;
    BreakDownList?: {
      title?: string;
      data?: {
        key?: string;
        value?: string | number;
        status?: string;
        url?: string;
      }[];
      paymentDetailList?: {
        key?: string;
        value?: string | number;
        status?: string;
        url?: string;
      }[];
    };

    paymentDetailList?: {
      title?: string;
      data?: {
        key?: string;
        value?: string | number;
        status?: string;
        url?: string;
      }[];
    };

    itemConfirmList?: {
      title?: string;
      data?: {
        key?: string;
        value?: string | number;
        type?: string;
        status?: string;
        url?: string;
        description?: string;
      }[];
    };
    itemsList?: {
      title?: string;
      data?: {
        key?: string;
        value?: string | number;
        type?: string;
        status?: string;
        url?: string;
        description?: string;
      }[];
    };
  }[] = [];

  refurDetails = currentLead?.refurbishmentDetails?.requests?.map(
    (item, index) => {
            let valueitems =
        currentLead?.refurbishmentDetails?.requests[index]?.items;
      if (valueitems) {
        valueitems = valueitems.map((item) => ({
          key: item?.product?.name,
          value: item?.price,
          status: item?.status,
          url: item?.refurbishmentProofUrl,
          description: item?.description
        }));
      }

            let confirmItems: any =
        currentLead?.refurbishmentDetails?.requests[index]?.items;
      if (!!confirmItems) {
        confirmItems = confirmItems.map((item) => ({
          key: item?.product?.name,
          url: item?.installationProofUrl,
          description: item?.description
        }));
      }

      let paymentAmountBreakDown: any =
        currentLead?.refurbishmentDetails?.requests[index]?.purchase?.items;

      let totalPurchase = 0;
      currentLead?.refurbishmentDetails?.requests[index]?.purchase?.items?.map(
        (i) => {
          if (
            currentLead?.refurbishmentDetails?.requests[index]?.purchase
              ?.payment?.status === PaymentStatus.Requested
          ) {
            return (totalPurchase = totalPurchase + i?.price);
          }
        }
      );

      const totalbill =
        totalPurchase +
        currentLead?.refurbishmentDetails?.requests[index]
          ?.transportationCharge;
      if (paymentAmountBreakDown) {
        paymentAmountBreakDown = paymentAmountBreakDown.map((item) => ({
          key: item?.product?.name,
          value: item?.price,
          url: item?.purchaseProofUrl,
          status: !!item?.purchaseProofUrl ? 'url' : '',
          description: item?.description
        }));
      }
      paymentAmountBreakDown?.push({
        key: 'Tranportation Cost',
        value: item?.transportationCharge,
        url: ''
      });
      return {
        key: `Refurbishment Request ${index + 1}`,
        value: item?.requestStatus ?? '-',
        itemConfirmList: {
          title: 'Installation Confirmation',
          data: confirmItems
        },
        itemsList: {
          data: valueitems
        },

        paymentDetailList: {
          data: [
            {
              key: 'Request Date',
              value: item?.purchase?.payment?.createdAt,
              status: 'Date'
            },
            {
              key: 'Payment To',
              value: item?.purchase?.payment?.paymentTo
            },
            {
              key: 'Payment Mode',
              value: !!titleCaseToReadable(item?.purchase?.payment?.mode)
                ? titleCaseToReadable(item?.purchase?.payment?.mode)
                : '-'
            },
            { key: 'UPI ID', value: item?.purchase?.payment?.upiId },
            {
              key: 'Payment Amount',
              value:
                item?.purchase?.payment?.status === PaymentStatus?.Requested
                  ? totalbill
                  : item?.purchase?.payment?.amount
            },
            {
              key: 'Bank Name',
              value: !!titleCaseToReadable(
                item?.purchase?.payment?.refurbishmentBeneficiary?.bankName
              )
                ? titleCaseToReadable(
                    item?.purchase?.payment?.refurbishmentBeneficiary?.bankName
                  )
                : '-'
            },
            {
              key: 'Account Holder name',
              value:
                item?.purchase?.payment?.refurbishmentBeneficiary
                  ?.accountHolderName
            },
            {
              key: 'Account Number',
              value:
                item?.purchase?.payment?.refurbishmentBeneficiary?.accountNumber
            },
            {
              key: 'IFSC Code',
              value: item?.purchase?.payment?.refurbishmentBeneficiary?.ifscCode
            },
            {
              key: 'Account Proof',
              value: Boolean(item?.purchase?.payment?.upiId) ? item?.purchase?.payment?.proofUrl : item?.purchase?.payment?.refurbishmentBeneficiary?.proofUrl,
              url: 'url'
            }
          ]
        },
        BreakDownList: {
          // hide: !!item?.purchase?.payment?.paymentProcessedAt,
          title: 'Item Bills Uploaded',
          data: paymentAmountBreakDown,
          paymentDetailList: [
            {
              key: 'Payment Date',
              value: item?.purchase?.payment?.paymentProcessedAt,
              status: 'Date'
            },
            { key: 'Payment Status', value: item?.purchase?.payment?.status },
            {
              key: 'Payment Amount',
              value:
                item?.purchase?.payment?.status === PaymentStatus?.Requested
                  ? totalbill
                  : item?.purchase?.payment?.amount
            },
            {
              key: 'Payment Mode',
              value: !!titleCaseToReadable(item?.purchase?.payment?.modeFM)
                ? titleCaseToReadable(item?.purchase?.payment?.modeFM)
                : '-'
            },
            {
              key: 'Payment Confirmation',
              url: 'url',
              value: item?.purchase?.payment?.receiptUrl
            }
          ]
        }
      };
    }
  );

  return (
    <RefurbishmentRequestListDisplay
      data={refurDetails}
      handleClick={handlerefurbishment}
    />
  );
}
