import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import Input from 'src/components/Form/Input';
import {
  ActionType,
  ModuleType,
  TaskStatus,
  UpdateUserMutationVariables,
  UserRole,
  useAddLogsMutation,
  useGetAllActiveStateQuery,
  useGetCenterUserQuery,
  useGetCentreListQuery,
  useGetUserEmailQuery,
  useGetUserforUserQuery,
  useUpdateCenterInchargeMutation,
  useUpdateUserMutation
} from 'src/generated/hooks_and_more';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { useEffect, useState } from 'react';
import ConfimationModal from 'src/components/Composite/ConfirmationModal/ConfimationModal';
import { useToasts } from 'react-toast-notifications';
import {
  enumToItems,
  filterUnique,
  titleCaseToReadable
} from 'src/utils/utility';
import { EditUser } from 'src/utils/schema';
import { useLocation } from 'react-router';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';

import MultiSelectAutoComplete from 'src/components/Form/MultiSelectAutoComplete';

function EditUsers() {
  const [open, setOpen] = useState(false);
  const [on, SetOn] = useState(false);
  const location = useLocation();

  const users: any = location.state as any;
  const {
    data: userData,
    loading: userLoading,
    refetch
  } = useGetUserEmailQuery({
    variables: { id: users },
    onCompleted(data) {}
  });
  const user = userData?.queryUser?.[0];

  const { addToast } = useToasts();
  const { data, loading: centerLoading, error } = useGetCenterUserQuery();

  const { data: usersValues, refetch: getUserDetail } =
    useGetUserforUserQuery();
  let centerManager = [];
  data?.queryUser?.map((item: any) => {
    return (centerManager = [
      ...centerManager,
      { label: item.email, value: item.email }
    ]);
  });

  const { data: stateOption } = useGetAllActiveStateQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {}
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const states = stateOption?.getStateInfo?.data?.map((item) => ({
    label: item?.state_name,
    value: item?.state_name
  }));

  const initialValues = {
    name: user?.name ?? '',
    email: user?.email ?? '',
    role: {
      value: user?.role ?? '',
      label: user?.role ? titleCaseToReadable(user?.role) : ''
    },
    center: user?.centre
      ? {
          name: user?.centre?.name ?? '',
          label: user?.centre?.name ?? '',
          id: user?.centre?.id
        }
      : null,
    updatedAt: user?.updatedAt ?? '',
    isActive: user?.isActive ? { label: 'Active' } : { label: 'Inactive' },
    inchargeId:
      user?.centre?.incharge?.id === user?.id
        ? { label: 'Yes' }
        : { label: 'No' },
    reportingManager: null,
    reporterName: {
      value: user?.reportingManager?.name ?? '',
      label: user?.reportingManager?.name ?? '',
      id: user?.reportingManager?.id
    },
    registrationState:
      userData?.queryUser?.[0]?.registrationState?.map((i) => {
        console.log(i, 'jfhjsjhhdhdh');
        return { label: i, value: i };
      }) || [],
    reporterEmail: ''
  };

  const [AddLogs] = useAddLogsMutation();
  const { loggedInUser } = useLoggedInUser();

  const [UpdateUsers] = useUpdateUserMutation();
  const [UpdateCentreIncharge] = useUpdateCenterInchargeMutation();

  const [variables, setVariables] = useState<UpdateUserMutationVariables>(null);
  const [isIncharge, setIsIncharge] = useState<boolean>(null);
  const title = 'Edit User';
  const btnTitle = 'Save';

  const onConfirmationHandler = (reset: () => void) => {
    UpdateUsers({
      variables: variables,
      onCompleted(data) {
        console.log(data, 'LLLLL');
        const { remove, ...updatedVariables } = variables;

        AddLogs({
          variables: {
            taskPerformedBy: { id: loggedInUser?.userId },
            actionType: ActionType.Update,
            module: ModuleType.User,
            createdAt: new Date(),
            sourceUpdated: variables?.email,
            updatedValue: JSON.stringify({
              data: {
                ...updatedVariables,
                inchargeId:
                  user?.centre?.incharge?.id === user.id ? "Active" : "Inactive"
              }
            }),
            previousValue: JSON.stringify({
              data: {
                name: user?.name ?? '',
                role: user?.role,
                updatedAt: '',
                isActive: user?.isActive ? true : false,
                registrationState: user.registrationState,
                reportingManager: {name: user.reportingManager?.name},
                email: user?.email ?? '',
                centre: {
                  name: user?.centre?.name ?? '',
                  id: user?.centre?.id
                },
                inchargeId: 'no current value'
              }
            }),
            status: TaskStatus.Success
          },
          onCompleted(data) {}
        });
        handleClose();
        addToast('User Updated Successfully!!!', {
          appearance: 'success',
          autoDismiss: true
        });
      },
      onError(err) {
        handleClose();
        AddLogs({
          variables: {
            taskPerformedBy: { id: loggedInUser?.userId },
            actionType: ActionType.Create,
            module: ModuleType.User,
            createdAt: new Date(),
            sourceUpdated: '',
            updatedValue: '',
            previousValue: '',
            status: TaskStatus.Failed
          }
        });
        if (err?.message?.includes('already exists')) {
          handleClose();
          addToast('User Already Exist!!!', {
            appearance: 'error',
            autoDismiss: true
          });
        } else {
          addToast('Something went wrong!!!', {
            appearance: 'error',
            autoDismiss: true
          });
        }
      }
    });

    if (isIncharge) {
      UpdateCentreIncharge({
        variables: {
          id: variables?.UserPatch?.centre?.id,
          inchargeId: user?.id
        }
      });
    }
  };

  const userRoleList = enumToItems(UserRole);
  const center = useGetCentreListQuery();
  useEffect(() => {
    refetch();
  }, [user, userData]);

  if (userLoading || centerLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Box sx={{ mt: 3 }} display={'flex'} justifyContent={'center'}>
      <Box width={'80%'}>
        <Card>
          <Typography sx={{ my: 1, mx: 2 }} variant="h3">
            {title}
          </Typography>
          <Divider />
          <CardContent>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={(values) => {
                const statesToRemove = initialValues?.registrationState?.filter(
                  (item) => {
                    if (!values?.registrationState?.length) {
                      return true;
                    } else {
                      return !values?.registrationState?.some(
                        (i) =>
                          i.label?.toUpperCase() === item.label?.toUpperCase()
                      );
                    }
                  }
                );

                console.log(statesToRemove, 'statesToRemovestatesToRemove');
                const variable: UpdateUserMutationVariables = {
                  UserPatch: {
                    name: values.name ?? '',
                    role: values?.role?.value as UserRole,
                    updatedAt: new Date(),
                    isActive:
                      values?.isActive?.label === 'Active' ? true : false,
                    registrationState:
                      values?.registrationState?.map((item) => item?.label) ||
                      []
                  },
                  remove: {
                    registrationState:
                      statesToRemove?.map((item) => item.label) || []
                  },
                  email: values?.email ?? ''
                };

                if (values?.center?.name) {
                  variable.UserPatch.centre = {
                    name: values?.center?.name,
                    id: values?.center?.id
                  };
                }

                if (values?.reporterName?.value) {
                  variable.UserPatch.reportingManager = {
                    name: values.reporterName?.value,
                    id: values?.reporterName?.id
                  };
                }

                if (values?.inchargeId?.label === 'Yes') {
                  setIsIncharge(true);
                }

                setVariables(variable);
                handleClickOpen();
              }}
              validationSchema={EditUser}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
                resetForm
              }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Input
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          placeholder="Email"
                          disabled={true}
                        />
                        {/* {errors.email && touched.email ? (
                          <div style={{ color: 'red' }}>{errors?.email}</div>
                        ) : null} */}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          value={values.name}
                          name="name"
                          onChange={handleChange}
                          placeholder="Name"
                        />
                        {errors.name && touched.name ? (
                          <div style={{ color: 'red' }}>{errors?.name}</div>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputAutoComplete
                          options={userRoleList}
                          placeholder="Role"
                          value={values.role}
                          onChange={(value) => {
                            setFieldValue('role', value);
                          }}
                        />
                        {errors.role && touched.role ? (
                          <div style={{ color: 'red' }}>role is required</div>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputAutoComplete
                          placeholder="Center"
                          value={values.center ?? null}
                          onChange={(value) => {
                            setFieldValue('center', value);
                          }}
                          options={center?.data?.queryCentre ?? []}
                          optionString="name"
                        />
                        {errors?.center ? (
                          <div style={{ color: 'red' }}>{errors?.center}</div>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputAutoComplete
                          placeholder="Status"
                          onChange={(value) => {
                            setFieldValue('isActive', value);
                          }}
                          options={[{ label: 'Active' }, { label: 'Inactive' }]}
                          // optionString="isActive"
                          value={values.isActive}
                          getOptionLabel={(option: { label: string }) =>
                            option.label
                          }
                        />
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <MultiSelectAutoComplete
                          onChange={(value) => {
                            const checkForDuplicate = filterUnique(
                              value,
                              'label'
                            );

                            setFieldValue(
                              'registrationState',
                              checkForDuplicate
                            );
                          }}
                          value={values?.registrationState ?? []}
                          options={states || []}
                          optionString="label"
                          placeholder="Select State"
                        />
                      </Grid>
                      {/* <Grid item xs={6} md={6}>
                        <InputAutoComplete
                          onChange={(value) => {
                            setFieldValue('registrationState', !!value ? value : {label: "" , name: ""});
                          }}
                          value={values?.registrationState ?? null}
                          options={
                            stateOption?.getStateInfo?.data.map((i) => ({
                              label: titleCaseToReadable(i?.state_name),
                              name: i?.state_name,
                              id: i?.state_id
                            })) ?? []
                          }
                          placeholder="Select State"
                        />
                      </Grid> */}
                      <Grid item xs={6} md={6}>
                        <InputAutoComplete
                          onChange={(value) => {
                            setFieldValue('reporterName', value);
                          }}
                          value={values?.reporterName ?? null}
                          options={
                            usersValues?.queryUser.map((i) => ({
                              value: titleCaseToReadable(i?.name),
                              label: `${i?.name}- ${titleCaseToReadable(
                                i?.role
                              )}`,
                              id: i?.id
                            })) ?? []
                          }
                          placeholder="Select Reporting Manager"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputAutoComplete
                          placeholder="Is Incharge"
                          onChange={(value) => {
                            setFieldValue('inchargeId', value);
                          }}
                          options={[{ label: 'Yes' }, { label: 'No' }]}
                          value={values.inchargeId}
                          getOptionLabel={(option: { label: string }) =>
                            option.label
                          }
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button variant="contained" type="submit">
                        {btnTitle}
                      </Button>
                    </div>
                    <ConfimationModal
                      textToShow="Do you want to update the user?"
                      open={open}
                      handleClose={handleClose}
                      toastMsg={on}
                      onConfirmationHandler={onConfirmationHandler}
                      reset={resetForm}
                    />
                  </Form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default EditUsers;
