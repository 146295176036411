import * as Yup from 'yup';
const regex = /^(\d|\w)+$/;
const spaceAndString = /^[a-zA-z\s]+$/;
const alphabetsOnly = /^[a-zA-z]+$/;
const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const mobileNumberRegex = /^[0]?[5-9]\d{9}$/;
const regexUPI = /^[\w.]+@[a-zA-Z0-9.-]+$/;
export const LeadFormValidation = Yup.object().shape({
  source: Yup.object().required('*Required'),
  bankAuction: Yup.boolean().required(),
  regNo: Yup.string()
    .matches(regex, '*Spaces and Special Characters not Allowed')
    .required('*Required'),
  make: Yup.object().required('*Required'),
  model: Yup.object().required('*Required'),
  manufacturingYear: Yup.string().required('*Required'),
  bankName: Yup.object()
    .required('*Required')
    .when('bankAuction', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  engineNo: Yup.string()
    .matches(regex, '*Spaces and Special Characters not Allowed')
    .required('*Required')
    .when('bankAuction', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  chasisNo: Yup.string()
    .matches(regex, '*Spaces and Special Characters not Allowed')
    .required('*Required')
    .when('bankAuction', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  status: Yup.object().required('*Required'),
  demandedAmount: Yup.number()
    .typeError('*Must be a Number')
    .required('*Required')
    .when('bankAuction', {
      is: false,
      otherwise: (schema) => schema.notRequired()
    }),
  hoursMeter: Yup.number()
    .typeError('*Must be a Number')
    .required('*Required')
    .when('bankAuction', {
      is: false,
      otherwise: (schema) => schema.notRequired()
    }),
  ownershipType: Yup.object()
    .required('*Required')
    .when('bankAuction', {
      is: false,
      otherwise: (schema) => schema.notRequired()
    })
});
export const DealershipFormValidation = Yup.object().shape({
  source: Yup.object().required('*Required'),
  regNo: Yup.string()
    .matches(regex, '*Spaces and Special Characters not Allowed')
    .required('*Required'),
  make: Yup.object().required('*Required'),
  model: Yup.object().required('*Required'),
  manufacturingYear: Yup.string().required('*Required'),

  status: Yup.object().required('*Required'),
  demandedAmount: Yup.number()
    .typeError('*Must be a Number')
    .required('*Required'),
  hoursMeter: Yup.number().typeError('*Must be a Number').required('*Required'),
  ownershipType: Yup.object().required('*Required')
});

export const loginfileValidationSchema = Yup.object().shape({
  bankName: Yup.object().required('*Required'),
  applicationform: Yup.string().required('*Required'),
  loginDate: Yup.string().required('*Required')
});

export const RtoValidationSchema = Yup.object().shape({
  make: Yup.object().required('*Required'),
  model: Yup.object().required('*Required'),
  engineNo: Yup.string()
    .matches(regex, '*Spaces and Special Characters not Allowed')
    .required('*Required'),
  chasisNo: Yup.string()
    .matches(regex, '*Spaces and Special Characters not Allowed')
    .required('*Required'),
  challanRadio: Yup.string().required(),
  challanAmnt: Yup.number()
    .typeError('*Must be a Number')
    .required('*Required')
    .when('challanRadio', {
      is: 'yes',
      otherwise: (schema) => schema.notRequired()
    }),
  hypoRadio: Yup.string().required(),
  bankName: Yup.object()
    .required('*Required')
    .when('hypoRadio', {
      is: 'yes',
      otherwise: (schema) => schema.notRequired()
    }),
  manufacturingYear: Yup.string().required('*Required'),
  rtoVerificationProof: Yup.string().required('*Required'),
  hypoProof: Yup.string().required('*Required'),
  challanProof: Yup.string().required('*Required'),
  blackListConfirmation: Yup.string().required('*Required'),
  hsrpProof: Yup.string().required('*Required')
});

export const VehicleDetailsPendingSchema = Yup.object().shape({
  agencyName: Yup.string().required('*Required'),
  agencyNo: Yup.string().matches(
    mobileNumberRegex,
    '*Please Enter Valid 10 Digit Numbers'
  ),

  regDate: Yup.string().required('*Required'),
  reposessionDate: Yup.string().required('*Required'),
  isInsured: Yup.string().required('*Required'),
  certificateAvailable: Yup.string().required('*Required'),
  fitnessDate: Yup.string()
    .required('*Required')
    .when('certificateAvailable', {
      is: 'yes',
      otherwise: (schema) => schema.notRequired()
    }),
  insurerName: Yup.object()
    .required('*Required')
    .when('isInsured', {
      is: 'yes',
      otherwise: (schema) => schema.notRequired()
    }),

  insuranceType: Yup.object()
    .required('*Required')
    .when('isInsured', {
      is: 'yes',
      otherwise: (schema) => schema.notRequired()
    }),
  policyNo: Yup.string()
    .required('*Required')
    .when('isInsured', {
      is: 'yes',
      otherwise: (schema) => schema.notRequired()
    }),
  policyExpiryDate: Yup.string()
    .required('*Required')
    .when('isInsured', {
      is: 'yes',
      otherwise: (schema) => schema.notRequired()
    })
});
export const RaisePaymentRequestSchema = Yup.object().shape({
  bankName: Yup.object().required('*Required'),
  accountHolderName: Yup.string()
    .matches(spaceAndString, '*Enter Valid Name')
    .required('*Required'),
  accountNumber: Yup.string().required('*Required'),
  ifscNumber: Yup.string()
    .length(11, 'length must be 11')
    .required('*Required'),
  accountProof: Yup.string().required('*Required')
});

export const RaisePaymentRequestSchemaRefurbishment = Yup.object().shape({
  bankTransfer: Yup.boolean().required('*Required'),
  beneficiary: Yup.object()
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  bankName: Yup.object()
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  accountHolderName: Yup.string()
    .matches(spaceAndString, '*Enter Valid Name')
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  accountNumber: Yup.string()
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  ifscNumber: Yup.string()
    .length(11, 'length must be 11')
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  accountProof: Yup.string().required('*Required'),
  upiId: Yup.string()
    .matches(regexUPI, '*Enter Valid UPI')
    .required('*Required')
    .when('bankTransfer', {
      is: false,
      otherwise: (schema) => schema.notRequired()
    })
});
export const PickupAssignmentSchema = Yup.object().shape({
  centerName: Yup.object().required('*Required'),
  driverName: Yup.object().required('*Required')
  // releaseOrder: Yup.string().required('*Required'),
  // indemnityBond: Yup.string().required('*Required')
});

export const DealPaymentPendingSchema = Yup.object().shape({
  dealAmount: Yup.number(),
  paymentMode: Yup.object().required('*Required'),
  amount: Yup.number()
    .oneOf([Yup.ref('dealAmount'), null], '*Invalid Deal Amount')
    .required('*Required'),
  paymentDate: Yup.string().required('*Required'),
  paymentReceipt: Yup.string().required('*Required')
});

export const ParkingPaymentSchema = Yup.object().shape({
  parkingAmount: Yup.number(),
  paymentMode: Yup.object().required('*Required'),
  amount: Yup.number()
    .oneOf([Yup.ref('parkingAmount'), null], "Amount doesn't match")
    .required('*Required'),
  paymentDate: Yup.string().required('*Required'),
  paymentReceipt: Yup.string().required('*Required')
});
export const SchedulePickupPaymentSchema = Yup.object().shape({
  oneDayParkCharge: Yup.number()
    .typeError('Numbers Only Allowed')
    .required('*Required'),
  estimatingParkCharge: Yup.string().required('*Required'),

  bankTransfer: Yup.boolean().required('*Required'),

  beneficiary: Yup.object()
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  bankName: Yup.object()
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  accountHolderName: Yup.string()
    .matches(spaceAndString, '*Enter Valid Name')
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  accountNumber: Yup.string()
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  ifscNumber: Yup.string()
    .length(11, 'length must be 11')
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  accountProof: Yup.string().required('*Required'),
  upiId: Yup.string()
    .matches(regexUPI, '*Enter Valid UPI')
    .required('*Required')
    .when('bankTransfer', {
      is: false,
      otherwise: (schema) => schema.notRequired()
    })
});

export const AddParkingDetailsSchema = Yup.object().shape({
  oneDayParkCharge: Yup.number().typeError('Numbers Only Allowed').required(),
  totalParkingCharge: Yup.string().required('*Required'),
  repossessionDate: Yup.string().required('*Required'),
  pickupDate: Yup.string().required('*Required'),
  bankTransfer: Yup.boolean().required('*Required'),

  beneficiary: Yup.object()
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  bankName: Yup.object()
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  accountHolderName: Yup.string()
    .matches(spaceAndString, '*Enter Valid Name')
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  accountNumber: Yup.string()
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  ifscNumber: Yup.string()
    .length(11, 'length must be 11')
    .required('*Required')
    .when('bankTransfer', {
      is: true,
      otherwise: (schema) => schema.notRequired()
    }),
  accountProof: Yup.string().required('*Required'),
  upiId: Yup.string()
    .matches(regexUPI, '*Enter Valid UPI')
    .required('*Required')
    .when('bankTransfer', {
      is: false,
      otherwise: (schema) => schema.notRequired()
    })
});
export const SelfieUploadSchema = Yup.object().shape({
  selfieImage: Yup.string().required('*Required')
});

export const SchedulePickFormValidation = Yup.object().shape({
  pickupDate: Yup.date().required('*Required'),
  pickupYard: Yup.object().required('*Required'),
  newYard: Yup.boolean(),
  yardName: Yup.string()
    .matches(/^[a-zA-Z ]+$/, 'ENTER VALID NAME')
    .required('*Required'),
  yardCity: Yup.string().required('*Required'),
  yardAddress: Yup.string().required('*Required'),
  yardSPOCName: Yup.string().required('*Required'),
  yardSPOCMobile: Yup.string()
    .matches(mobileNumberRegex, '*Please Enter Valid 10 Digit Numbers')
    .required('*Required')
});

export const InstallationPaymentSchema = Yup.object().shape({
  refurbishedAmount: Yup.number(),
  paymentMode: Yup.object().required('*Required'),
  amount: Yup.number()
    .oneOf([Yup.ref('refurbishedAmount'), null], '*Invalid Payment Amount')
    .required('*Required'),
  paymentDate: Yup.string().required('*Required'),
  paymentReceipt: Yup.string().required('*Required')
});

export const BookingDetailsAddedSchema = Yup.object().shape({
  customerName: Yup.string().required('*Required'),
  customerNo: Yup.string().matches(
    mobileNumberRegex,
    '*Please Enter Valid 10 Digit Numbers'
  ),
  state: Yup.object().required('*Required'),
  district: Yup.object().required('*Required'),
  aadharNo: Yup.number()
    .typeError('*Numbers only allowed')
    .required('*Required'),
  aadharFrontImage: Yup.string().required('*Required'),
  aadharBackImage: Yup.string().required('*Required'),
  panNumber: Yup.string().required('*Required'),
  panImage: Yup.string().required('*Required'),
  voterIdNo: Yup.string(),
  voterIdImage: Yup.string().when('voterIdNo', (voterIdNo, schema) => {
    if (voterIdNo?.[0] !== undefined) return schema.required('*Required');
    else {
      return schema.notRequired();
    }
  }),
  rcTransfer: Yup.string().required('*Required'),
  insurance: Yup.string().required('*Required'),
  bookingDocument: Yup.string().required('*Required')
});
export const BookingPartValidation = Yup.object().shape({
  partAmount: Yup.number().required('*Required'),

  paymentMode: Yup.object().required('*Required'),

  paymentDate: Yup.string().required('*Required'),
  paymentReceipt: Yup.string().required('*Required')
});

export const BookingDeliveryPaymentSchema = Yup.object().shape({
  amount: Yup.number()
    .oneOf([Yup.ref('deliveryAmount'), null], 'Invalid Booking Delivery Amount')
    .typeError('*Numbers only allowed')
    .required('*Required'),
  paymentMode: Yup.object().required('*Required'),

  paymentDate: Yup.string().required('*Required'),
  paymentReceipt: Yup.string().required('*Required')
});
export const BookingTokenPaymentSchema = Yup.object().shape({
  totalSaleAmount: Yup.number(),
  amount: Yup.number()
    .max(
      Yup.ref('totalSaleAmount'),
      '*Token Amount Should Not Be Greater Than Total Sale Amount '
    )
    .required('*Required'),
  paymentMode: Yup.object().required('*Required'),

  paymentDate: Yup.string().required('*Required'),
  paymentReceipt: Yup.string().required('*Required')
});
