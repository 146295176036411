import React from 'react';

import {
  getDesireDateFormate,
  getStatus,
  getYearFromDate,
  titleCaseToReadable
} from '../../utils/utility';

import { TableCell, TableRow, Typography } from '@mui/material';
import { CenterPreviewFragment, Centre } from '../../generated/hooks_and_more';
import { useNavigate } from 'react-router-dom';
import { TableCellProps } from '@material-ui/core';
import routeConstant from 'src/constants/routeContant';
import format from 'date-fns/format';

type CenterListRowProps = {
  centerData: Partial<CenterPreviewFragment>;
  index?: number;
};

type RowItemsType = {
  value: string | Number;
  align?: TableCellProps['align'];
};

function CenterListRow(props: CenterListRowProps) {
  const { centerData, index } = props;

  const rowItems: RowItemsType[] = [
    { value: centerData?.name },
    { value: centerData?.state },
    { value: centerData?.city },
    { value: centerData?.pincode ?? '-', align: 'center' },
    { value: centerData?.leads?.length ?? '-', align: 'center' },
    { value: centerData?.status },
    { value: centerData?.incharge?.name },
    {
      value: centerData?.createdAt
        ? format(Date.parse(centerData?.createdAt), 'dd-MM-yy')
        : '-'
    },
    {
      value: centerData?.updatedAt
        ? format(Date.parse(centerData?.updatedAt), 'dd-MM-yy')
        : '-'
    }
  ];

  const nav = useNavigate();

  return (
    <TableRow
      hover
      sx={{ cursor: 'pointer' }}
      onClick={() =>
        nav(
          `${routeConstant.INVENTORY}/${routeConstant.CENTER}/${centerData.id}`
        )
      }
    >
      {rowItems.map((item, index) => {
        return (
          <TableCell align={item.align ?? 'left'} key={index}>
            <Typography
              variant="body1"
              // fontWeight="bold"
              color="text.primary"
              gutterBottom
              noWrap
            >
              {item.value ?? '-'}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default CenterListRow;
