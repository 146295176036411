import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Button
} from '@mui/material';
import { Fragment } from 'react';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import Input from 'src/components/Form/Input';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import {
  useGetAllCenterQuery,
  useAddStocksMutation,
  useAddIssueStocksMutation,
  PurchaseStatus,
  IssueStatus,
  useGetStocksDetailsLazyQuery,
  useAddLogsMutation,
  ActionType,
  ModuleType,
  TaskStatus
} from 'src/generated/hooks_and_more';
import { TransferStockSchema, TransferTypeSchema } from './ValidationSchema';
import { AutoCompleteValueType } from 'src/types';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { cachedLeadFilter } from 'src/utils/utility';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';

function TransferStock() {
  const userData = JSON?.parse(localStorage.getItem('userData'));
  const { addToast } = useToasts();

  const { data, loading, error } = useGetAllCenterQuery();

  const [getStocksList, { data: productsList }] = useGetStocksDetailsLazyQuery({
    fetchPolicy: 'no-cache'
  });
  const [toCenter, setToCenter] = useState<AutoCompleteValueType>(null);
  const [invoiceURL, setInvoiceUrl] = useState('');
  const [invoiceError, setInvoiceError] = useState('');
  const [addStocksMutation] = useAddStocksMutation();
  const [addIssue] = useAddIssueStocksMutation();
  const [AddLogs] = useAddLogsMutation();

  const { loggedInUser } = useLoggedInUser();



  let centerNames = [];
  let products = [];
  useEffect(() => {
    cachedLeadFilter(null);
  }, []);
  data?.queryCentre?.map((item: any) => {
    return (centerNames = [
      ...centerNames,
      { label: item.name, value: item.name, id: item.id }
    ]);
  });
  products = productsList?.inventoryDetails?.items?.map((item: any) => {
    return {
      label: item?.product?.name,
      value: item?.product?.name,
      quantity: item?.availableInStock,
      id: item?.product?.id,
      price: Math.floor(Number(item?.avgUnitPrice)) ?? 0
    };
  });

  return (
    <Fragment>
      <Box sx={{ mt: 3 }} display={'flex'} justifyContent={'center'}>
        <Box width={'80%'}>
          <Card>
            <Typography sx={{ my: 1, mx: 2 }} variant="h3">
              Transfer Stocks
            </Typography>
            <Divider />
            <CardContent>
              <Formik
                initialValues={{
                  toCenter: null,
                  fromCenter: null,
                  invoiceURL: '',
                  items: [
                    {
                      type: null,
                      quantity: ''
                    }
                  ]
                }}
                validationSchema={TransferStockSchema}
                validate={(values) => {
                  console.log(values);
                  const errors: any = {};
                  values?.items?.map((item, index) => {
                    if (values.items[index]?.type?.quantity === 0) {
                      errors.items = errors?.items ?? [];
                      errors.items[index] = {
                        quantity: 'Stocks are not Available '
                      };
                    } else if (
                      values.items[index].quantity >
                      values.items[index]?.type?.quantity
                    ) {
                      errors.items = errors?.items ?? [];
                      errors.items[index] = {
                        quantity: `${values.items[index]?.type?.quantity} Stocks only available`
                      };
                    }
                  });

                  return errors;
                }}
                onSubmit={(values, action) => {
                  // Handle form submission

                  if (!invoiceURL) {
                    setInvoiceError('Invoice is required');
                  } else {
                    const items = values?.items?.map((item) => {
                      return {
                        price: Math.floor(Number(item?.type?.price)),
                        product: {
                          name: item?.type?.value,
                          id: item?.type?.id
                        },
                        quantity: Number(item?.quantity),
                        purchase: {
                          centre: {
                            id: values?.toCenter?.id,
                            name: values?.toCenter?.name
                          }
                        }
                      };
                    });

                    const issueItems = values?.items?.map((item) => {
                      console.log(item);
                      return {
                        product: {
                          name: item?.type?.value,
                          id: item?.type?.id
                        },
                        price: Number(item.type.price),
                        quantity: Number(item?.quantity),
                        issue: {
                          centre: {
                            id: values?.fromCenter?.id,
                            name: values?.fromCenter?.name
                          }
                        }
                      };
                    });

                    const urlPrefix = values?.invoiceURL.split('/').pop();
                    addStocksMutation({
                      variables: {
                        centreId: values?.toCenter?.id,
                        items: [...items],
                        madeById: userData?.userId,
                        createdAt: new Date(),
                        status: PurchaseStatus.Received,
                        invoiceUrl: `https://vms-app-assets-pdf.s3.ap-south-1.amazonaws.com/${urlPrefix}`
                      },

                      onCompleted() {
                        AddLogs({
                          variables: {
                            taskPerformedBy: { id: loggedInUser?.userId},
                            actionType: ActionType.Update,
                            module: ModuleType.Inventory,
                            createdAt: new Date(),
                            sourceUpdated: items?.[0]?.product?.name,
                            updatedValue: JSON.stringify({ data: {centreId: values?.toCenter?.id,
                              items: [...items],
                              madeById: userData?.userId,
                              createdAt: new Date(),
                              status: PurchaseStatus.Received,
                              invoiceUrl: `https://vms-app-assets-pdf.s3.ap-south-1.amazonaws.com/${urlPrefix}`
}                   }),
                            previousValue: "",
                            status: TaskStatus.Success
                          }
                        });
                        
                        action.resetForm();
                        setInvoiceUrl('');
                        addToast('Stocks Transferred successfully!!!', {
                          appearance: 'success',
                          autoDismiss: true
                        });
                      }
                    });
                    addIssue({
                      variables: {
                        centreId: values?.fromCenter?.id,
                        items: [...issueItems],
                        madeById: userData?.userId,
                        createdAt: new Date(),
                        status: IssueStatus.Issued
                      }
                    });
                  }
                }}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  errors,
                  touched,
                  setValues
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container sx={{ mb: 2 }} spacing={2}>
                      <Grid item md={6} xs={12}>
                        <InputAutoComplete
                          value={values?.fromCenter}
                          placeholder="From Center"
                          options={centerNames}
                          onChange={(value) => {
                            getStocksList({
                              variables: { centreId: value?.id }
                            });
                            setFieldValue('fromCenter', value);

                            setValues({
                              fromCenter: value,
                              toCenter: null,
                              invoiceURL: invoiceURL,
                              items: [
                                {
                                  type: null,
                                  quantity: ''
                                }
                              ]
                            });
                          }}
                        />
                        <ErrorMessage
                          render={() => (
                            <div style={{ color: 'red' }}>* Required</div>
                          )}
                          name="fromCenter"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputAutoComplete
                          value={values?.toCenter}
                          placeholder="To Center"
                          options={centerNames.filter(
                            (item) => item.label !== values.fromCenter?.label
                          )}
                          onChange={(value) => {
                            setFieldValue('toCenter', value);
                          }}
                        />
                        <ErrorMessage
                          render={() => (
                            <div style={{ color: 'red' }}>* Required</div>
                          )}
                          name="toCenter"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FileUploadToS3
                          id="Upload-Invoice"
                          value={invoiceURL}
                          onChange={(value) => {
                            // setFieldValue('invoiceURL', value);
                            setInvoiceUrl(value);
                            setInvoiceError('');
                          }}
                          placeholder="Upload transfer proof"
                        />
                        {invoiceError ? (
                          <div style={{ color: 'red' }}>{invoiceError}</div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <div style={{ paddingBottom: '15px', paddingTop: '1px' }}>
                      <hr style={{ height: '0.9px' }}></hr>
                    </div>
                    <FieldArray name="items">
                      {({ push, remove }) => (
                        <div>
                          {values.items.map((item, index) => (
                            <Fragment key={index}>
                              <Grid
                                container
                                sx={{ mb: 2 }}
                                spacing={2}
                                key={index}
                              >
                                <Grid item md={6} xs={12}>
                                  <InputAutoComplete
                                    value={values.items[index].type}
                                    key="stockType"
                                    disabled={!Boolean(values.fromCenter)}
                                    options={products ?? []}
                                    onChange={(value) => {
                                      console.log(value);
                                      setFieldValue(
                                        `items[${index}].type`,
                                        value
                                      );
                                    }}
                                    placeholder="Stock Type"
                                  />
                                  <ErrorMessage
                                    render={() => (
                                      <div style={{ color: 'red' }}>
                                        * Required
                                      </div>
                                    )}
                                    name={`items[${index}].type`}
                                  />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                  <Input
                                    placeholder="Quantity"
                                    value={values.items[index].quantity}
                                    name={`items[${index}].quantity`}
                                    onChange={handleChange}
                                  />
                                  <p>
                                    {values.items[index].type
                                      ? `${values.items[index]?.type?.quantity} stocks available`
                                      : ''}{' '}
                                  </p>
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div style={{ color: 'red' }}>{msg}</div>
                                    )}
                                    name={`items[${index}].quantity`}
                                  />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                  {index === values.items.length - 1 ? (
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      onClick={() => {
                                        push({
                                          type: null,

                                          quantity: ''
                                        });
                                      }}
                                    >
                                      add
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      onClick={() => remove(index)}
                                    >
                                      remove
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            </Fragment>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                    <Button variant="contained" type="submit">
                      Transfer Stocks
                    </Button>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Fragment>
  );
}

export default TransferStock;
