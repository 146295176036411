import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';
import ThemeProvider from './theme/ThemeProvider';
import Navigation from './navigation';
import 'react-notifications-component/dist/theme.css';
import { ReactNotifications } from 'react-notifications-component';
import { GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import { UserRole, useGetUserLazyQuery } from './generated/hooks_and_more';
import { useEffect } from 'react';
import useUserStatus from './customHooks/useUserStatus';

function App() {
  const content = useRoutes(router);
  const email = JSON.parse(localStorage.getItem('userData'))?.email;
  const role = JSON.parse(localStorage.getItem('userData'))?.role;
  useUserStatus();

  const realEmail = JSON.parse(localStorage.getItem('realEmailId'))?.email;
  const location = useLocation();
  const navigation = useNavigate();
  const [call] = useGetUserLazyQuery();
  useEffect(() => {
    call({
      variables: {
        email: role === UserRole?.CentreManager ? realEmail : email
      },
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        // if (data?.queryUser?.[0]?.isActive === false) {
        //   localStorage.clear();
        //   googleLogout();
        //   navigation('/vms-admin');
        // }
        localStorage.setItem(
          'centreData',
          JSON.stringify({
            centreDetails:
              role === UserRole?.CentreManager
                ? data?.queryUser?.[0]?.centre
                : null
          })
        );
      }
    });
  }, [location.pathname]);

  return (
    <GoogleOAuthProvider clientId="407649844081-gqtb46dhjtstb5is2gdf48f1tvjvp4c5.apps.googleusercontent.com">
      <ThemeProvider>
        {content}
        <Navigation />
        <ReactNotifications />
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
export default App;
