import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Snackbar } from '@mui/material';
import jwt_decode from 'jwt-decode';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useIsUserRoleAdminLazyQuery,
  UserRole
} from '../../generated/hooks_and_more';
import { GoogleResponseType } from '../../types';
import './login.css';
import { GoogleLogin } from '@react-oauth/google';

function Login() {
  const path = useNavigate();
  const [checkUserAccess] = useIsUserRoleAdminLazyQuery();
  const [message, setMessage] = useState('');

  const [open, setOpen] = React.useState(false);

  const responseMessage = (resp) => {
    console.log({ resp });
    if (resp?.credential) {
      const userInfo: GoogleResponseType = jwt_decode(resp?.credential);
      console.log({ userInfo });

      const email = userInfo ? userInfo?.email : '';

      if (email) {
        const variables = {
          email: email

        };

        checkUserAccess({
          fetchPolicy: 'no-cache',
          variables,
          onCompleted(response) {
            // console.log(response, 'response');
            if (
              response &&
              response?.queryUser &&
              response?.queryUser?.length &&
              response?.queryUser?.[0]?.isActive
            ) {
              console.log(response, 'response');
              let userData = {
                accessToken: resp?.credential ?? '',
                email: email,
                picture: userInfo?.picture,
                name: response?.queryUser?.[0].name,
                userId: response?.queryUser?.[0].id,
                role: response?.queryUser?.[0]?.role,
                centre: response?.queryUser?.[0]?.centre
                  ? {
                      id: response?.queryUser?.[0]?.centre.id,
                      name: response?.queryUser?.[0]?.centre?.name
                    }
                  : null,
                centreDetails:
                  response?.queryUser?.[0]?.role === UserRole?.CentreManager
                    ? response?.queryUser?.[0]?.centre
                    : null
              };

              localStorage.setItem('userData', JSON.stringify(userData));
              localStorage.setItem(
                'centreData',
                JSON.stringify({
                  centreDetails:
                    response?.queryUser?.[0]?.role === UserRole?.CentreManager
                      ? response?.queryUser?.[0]?.centre
                      : null
                })
              ); //This is only made for staging purpose as we are creating fake users
              localStorage.setItem(
                'realEmailId',
                JSON.stringify({
                  email: email
                })
              ); //This is only made for staging purpose as we are creating fake users
              if (
                userData?.role === UserRole.OperationsManager ||
                userData?.role === UserRole.LogisticsManager
              ) {
                path('documents/pending-leads');
              } else {
                path('/vms-admin/leads');
              }
            } else {
              setOpen(true);
              if (!response?.queryUser?.[0]?.isActive) {
                setMessage(
                  `${email}, is not longer active. Please connect with admin!!!`
                );
              } else {
                setMessage(`${email}, You don't have access!!!`);
              }
            }
          },
          onError(error) {
            console.log(error);
          }
        });
      }
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div className="login-container">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
        key={'top' + 'center'}
      />
      <img src="/loginlogo.png" width="140px" className="mb-3" alt="logo" />
      <GoogleLogin
        onSuccess={responseMessage}
        onError={() => {
          console.log('error while login ');
        }}
      />
    </div>
  );
}

export default Login;
