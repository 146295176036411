import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, Grid, TextField } from '@mui/material';
import * as Yup from 'yup';

import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { LeadCreateAndUpdateProps } from 'src/types';
import { ImageStage, LeadRef, LeadStatus } from 'src/generated/hooks_and_more';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

const UploadVehichleImages = (props: LeadCreateAndUpdateProps) => {
  const {
    desireButtonText,
    undesireButtonText,
    leadId,
    regNo,
    desireStatus,
    source,
    toggleDrawer,
    onPressOfDesiredOrUndesiredButton,
    canGoBack
  } = props;
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const getStage = (status) => {
    switch (status) {
      case LeadStatus.LeadVehicleImagesUploaded:
        return ImageStage.LeadGenerated;
      case LeadStatus.PickupVehicleImagesUploaded:
        return ImageStage.BeforeDelivery;
      case LeadStatus.DeliveryVehicleImagesUploaded:
        return ImageStage.AfterDelivery;
        case LeadStatus.InternalTransferPickupImagesUploaded:
        return ImageStage.TransferPickup;
        case LeadStatus.InternalTransferDropImagesUploaded:
        return ImageStage.TransferDrop;
      default:
        return null;
    }
  };
  const formik = useFormik({
    initialValues: {
      inspectionVideo: '',
      bodyImageFront: '',
      bodyImageLeft: '',
      bodyImageBack: '',
      bodyImageRight: '',
      tyreBackLeftImage: '',
      tyreBackRightImage: '',
      tyreFrontLeftImage: '',
      tyreFrontRightImage: '',
      fip: '',
      odometer: '',
      chassis: '',
      engine: '',
      imagesCount: 0
    },
    validationSchema: Yup.object().shape({
      inspectionVideo: Yup.string().matches(/\.mp4$/, '*Invalid Video Format'),
      bodyImageFront: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      bodyImageLeft: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      bodyImageBack: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      bodyImageRight: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      tyreBackLeftImage: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      tyreBackRightImage: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      tyreFrontLeftImage: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      tyreFrontRightImage: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      fip: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      odometer: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      chassis: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      engine: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      imagesCount: Yup.number().min(4, '*Atleast 4 images required').required()
    }),

    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        vehicle: {
          images: [
            {
              vehicleImagesId: `${regNo}_${getStage(desireStatus)}`,
              imagesTakenAtStage: getStage(desireStatus),
              inspectionVideoUrl: values.inspectionVideo,
              backBodySide: values.bodyImageBack,
              frontBodySide: values.bodyImageFront,
              leftBodySide: values.bodyImageLeft,
              rightBodySide: values.bodyImageRight,
              backLeftTyre: values.tyreBackLeftImage,
              backRightTyre: values.tyreBackRightImage,
              frontLeftTyre: values.tyreFrontLeftImage,
              frontRightTyre: values.tyreFrontRightImage,
              odometer: values.odometer,
              fuelInjectionPumpPlate: values.fip,
              chassisNumber: values.odometer,
              engineNumber: values.engine
            }
          ]
        }
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });

  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} style={{ marginTop: '1rem' }}>
        <Grid container spacing={2}>
          {formik.errors.imagesCount || formik.touched.imagesCount ? (
            <div style={{ color: 'red' }}>{formik.errors?.imagesCount}</div>
          ) : null}
          <Grid item xs={12} md={12}>
            <h5>Inspection Video </h5>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid item xs={12} md={12}>
              <FileUploadToS3
                accept=".mp4"
                id="inspection"
                value={formik.values.inspectionVideo}
                onChange={(value) => {
                  formik.setFieldValue('inspectionVideo', value);
                }}
                placeholder="Inspection Clip"
              />
              {formik.errors.inspectionVideo ||
              formik.touched.inspectionVideo ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.inspectionVideo}
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <h5>Please upload any four images of tractor</h5>
          </Grid>
          <Grid item xs={12} md={12}>
            {' '}
            <h5>Body Images</h5>
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="bodyImage"
              value={formik.values.bodyImageFront}
              onChange={(value) => {
                formik.setFieldValue('bodyImageFront', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              placeholder="front"
              accept="image/jpg,image/png,image/jpeg"
            />
            {formik.errors.bodyImageFront || formik.touched.bodyImageFront ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.bodyImageFront}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="bodyleftImage"
              value={formik.values.bodyImageLeft}
              onChange={(value) => {
                formik.setFieldValue('bodyImageLeft', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              accept="image/jpg,image/png,image/jpeg"
              placeholder="Left"
            />
            {formik.errors.bodyImageLeft || formik.touched.bodyImageLeft ? (
              <div style={{ color: 'red' }}>{formik.errors?.bodyImageLeft}</div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="bodyImageBack"
              value={formik.values.bodyImageBack}
              onChange={(value) => {
                formik.setFieldValue('bodyImageBack', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              accept="image/jpg,image/png,image/jpeg"
              placeholder="Back"
            />
            {formik.errors.bodyImageBack || formik.touched.bodyImageBack ? (
              <div style={{ color: 'red' }}>{formik.errors?.bodyImageBack}</div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="bodyImageRight"
              value={formik.values.bodyImageRight}
              onChange={(value) => {
                formik.setFieldValue('bodyImageRight', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              accept="image/jpg,image/png,image/jpeg"
              placeholder="Right"
            />
            {formik.errors.bodyImageRight || formik.touched.bodyImageRight ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.bodyImageRight}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            {' '}
            <h5>Tyre Images</h5>
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="tyreBackLeftImage"
              value={formik.values.tyreBackLeftImage}
              onChange={(value) => {
                formik.setFieldValue('tyreBackLeftImage', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              accept="image/jpg,image/png,image/jpeg"
              placeholder="Back left"
            />
            {formik.errors.tyreBackLeftImage ||
            formik.touched.tyreBackLeftImage ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.tyreBackLeftImage}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="tyreBackRightImage"
              value={formik.values.tyreBackRightImage}
              onChange={(value) => {
                formik.setFieldValue('tyreBackRightImage', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              accept="image/jpg,image/png,image/jpeg"
              placeholder="Back Right"
            />
            {formik.errors.tyreBackRightImage ||
            formik.touched.tyreBackRightImage ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.tyreBackRightImage}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="tyreFrontLeftImage"
              value={formik.values.tyreFrontLeftImage}
              onChange={(value) => {
                formik.setFieldValue('tyreFrontLeftImage', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              placeholder="Front left"
              accept="image/jpg,image/png,image/jpeg"
            />
            {formik.errors.tyreFrontLeftImage ||
            formik.touched.tyreFrontLeftImage ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.tyreFrontLeftImage}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="tyreFrontRightImage"
              value={formik.values.tyreFrontRightImage}
              onChange={(value) => {
                formik.setFieldValue('tyreFrontRightImage', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              placeholder="Front right"
              accept="image/jpg,image/png,image/jpeg"
            />
            {formik.errors.tyreFrontRightImage ||
            formik.touched.tyreFrontRightImage ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.tyreFrontRightImage}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            {' '}
            <h5>Others</h5>
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="fip"
              value={formik.values.fip}
              onChange={(value) => {
                formik.setFieldValue('fip', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              accept="image/jpg,image/png,image/jpeg"
              placeholder="front"
            />
            {formik.errors.fip || formik.touched.fip ? (
              <div style={{ color: 'red' }}>{formik.errors?.fip}</div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="odometer"
              value={formik.values.odometer}
              onChange={(value) => {
                formik.setFieldValue('odometer', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              accept="image/jpg,image/png,image/jpeg"
              placeholder="Odometer"
            />
            {formik.errors.odometer || formik.touched.odometer ? (
              <div style={{ color: 'red' }}>{formik.errors?.odometer}</div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="chassis"
              value={formik.values.chassis}
              onChange={(value) => {
                formik.setFieldValue('chassis', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              placeholder="chassis"
              accept="image/jpg,image/png,image/jpeg"
            />
            {formik.errors.chassis || formik.touched.chassis ? (
              <div style={{ color: 'red' }}>{formik.errors?.chassis}</div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              id="engine"
              value={formik.values.engine}
              onChange={(value) => {
                formik.setFieldValue('engine', value);
                formik.setFieldValue(
                  'imagesCount',
                  formik.values.imagesCount + 1
                );
              }}
              placeholder="engine"
              accept="image/jpg,image/png,image/jpeg"
            />
            {formik.errors.engine || formik.touched.engine ? (
              <div style={{ color: 'red' }}>{formik.errors?.engine}</div>
            ) : null}
          </Grid>
        </Grid>

        <CtaButton
          desireButtonText={desireButtonText}
          undesireButtonText={undesireButtonText}
          disabled={!(formik.isValid && formik.dirty)}
          reject={reject}
          backBtn={canGoBack}
        />
      </form>
    </>
  );
};

export default UploadVehichleImages;
