import { Typography, Box } from '@mui/material';
import React from 'react';

function NotFound() {
  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      <Typography variant="h3">404</Typography>
    </Box>
  );
}

export default NotFound;
