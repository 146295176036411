import React from 'react';
import { LeadDetailsPropsType } from './ApplicationDetails';
import {
  LeadStatus,
  LeadStatusEventRef,
  PaymentFor,
  PaymentStatus,
  UserRole
} from 'src/generated/hooks_and_more';
import { format } from 'date-fns';
import {
  formatDate,
  getUserData,
  titleCaseToReadable,
  validDateFormate
} from 'src/utils/utility';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';

function DealershipDealDetails({ currentLead }: LeadDetailsPropsType) {
  const userDetails = getUserData();
  const approvedDeal = currentLead?.approvedDealAmount;
  const demandAmount = currentLead?.demandAmount;
  const proposedDealAmount = currentLead?.proposedDealAmount;
  const statusEvents = currentLead?.statusEvents;
  const expenses = currentLead?.expenses;
  // const openBottomSheet=openBottomSheet

  const paymentType = currentLead?.payments?.filter((i) => {
    if (i?.for === PaymentFor.DealPayment  ) {
      return i?.type;
    } else {
      return i?.type;
    }
  });

  const payments = currentLead?.payments?.filter((i)=> i?.for !== PaymentFor?.Refurbishment);
  // const setBottomSheetVariant=setBottomSheetVariant
  const documentCharges = currentLead?.documentCharges;
  const hasToshow = ![UserRole.LogisticsManager, UserRole.Driver].includes(
    userDetails?.role
  );
  let totalExpenses = 0;


  // const deliveryExpensesStatus =
  //   !!expenses && expenses?.[expenses?.length - 1]?.paymentStatus;
  // const deliveryExpensesStatusDate =
  //   !!expenses && expenses?.[expenses?.length - 1]?.createdAt;
  function setStatusEvent(
    currentStatus: LeadStatus,
    statusEvents?: Partial<LeadStatusEventRef>[]
  ) {
    if (
      statusEvents.some((s) => s?.status === LeadStatus.DealAmountConfirmed)
    ) {
      return 'Approved';
    } else if (currentStatus === LeadStatus.DealAmountUpdated) {
      return 'Accepted';
    } else if (currentStatus === LeadStatus.NewDealProposed) {
      return 'Proposed';
    } else if (
      currentStatus === LeadStatus.LeadGenerated ||
      currentStatus === LeadStatus.NewDealRequested ||
      currentStatus === LeadStatus.LeadVehicleImagesUploaded
    ) {
      return 'Requested';
    }
  }
  function setStatusDate(
    currentStatus: Partial<LeadStatusEventRef>,
    statusEvents?: Partial<LeadStatusEventRef>[]
  ) {
    if (
      statusEvents.some((s) => s?.status === LeadStatus.DealAmountConfirmed)
    ) {
      return format(new Date(currentStatus?.createdAt), 'dd MMM yyyy');
    } else if (currentStatus?.status === LeadStatus.DealAmountUpdated) {
      return format(new Date(currentStatus?.createdAt), 'dd MMM yyyy');
    } else if (currentStatus?.status === LeadStatus.NewDealProposed) {
      return format(new Date(currentStatus?.createdAt), 'dd MMM yyyy');
    } else if (
      currentStatus?.status === LeadStatus.LeadGenerated ||
      currentStatus?.status === LeadStatus.NewDealRequested ||
      currentStatus?.status === LeadStatus.LeadVehicleImagesUploaded
    ) {
      return format(new Date(currentStatus?.createdAt), 'dd MMM yyyy');
    }
  }

  const ammount = approvedDeal ? approvedDeal : proposedDealAmount ?? '-';
  let dealDetail: {
    key: string;
    value: string | number;
    show: boolean;
    metaData?: {
      title: string;
      data: { key: string; value: string | number; type?: string }[];
    };
  }[] = [
    {
      key: 'Demand Amount',
      value: demandAmount,
      show: hasToshow
    },
    {
      key: 'Approved Deal',
      value: ammount,
      show: hasToshow
    },
    {
      key: 'Deal Status',
      value: setStatusEvent(statusEvents?.[0]?.status, statusEvents) ?? '-',
      show: hasToshow
    },
    {
      key: 'Status Date',
      value: setStatusDate(statusEvents?.[0], statusEvents) ?? '-',
      show: hasToshow
    },
    {
      key: 'Payment Type',
      value: titleCaseToReadable(paymentType[0]?.type) ?? '-',
      show: hasToshow
    }
  ];

  payments?.map((p) => {
    if (p.for === PaymentFor.DealDelivery) {
      dealDetail = [
        ...dealDetail,
        ...[
          {
            key: titleCaseToReadable(p?.for),
            value: p?.amount,
            show: hasToshow
          },
          {
            key: 'Document Charges',
            value: `${documentCharges ? ' - ' + documentCharges : '-'}` ?? '-',
            show: hasToshow
          },

          {
            key: 'Status Date',
            value: p?.createdAt
              ? format(Date?.parse(p?.createdAt), 'dd MMM yyyy')
              : '-',
            show: hasToshow
          },
          {
            key: 'Payment Status',
            value: p?.status,
            show: hasToshow
          },
          {
            key: 'View Request',
            value: 'View',
            show: hasToshow,
            metaData: {
              title: p?.for,
              data: [
                {
                  key: 'payment Amount',
                  value: p?.amount
                },
                {
                  key: 'Document Charges',
                  value:
                    `${documentCharges ? ' - ' + documentCharges : '-'}` ?? '-'
                },
                {
                  key: 'payment Type',
                  value: p?.type ? titleCaseToReadable(p?.for) : '-'
                },
                {
                  key: 'Bank Name',
                  value: p?.bankName ? titleCaseToReadable(p?.bankName) : '-'
                },
                {
                  key: 'Account holder Name',
                  value: p?.accountHolderName
                },
                {
                  key: 'Account Number',
                  value: p?.accountNo
                },
                {
                  key: 'IFSC code',
                  value: p?.ifsc
                },
                {
                  key: 'Account Proof',
                  value: p?.proofUrl ?? '-',
                  type: 'url'
                },
               
              ]
            }
          }
        ]
      ];
    } else {
      dealDetail = [
        ...dealDetail,
        ...[
          {
            key: titleCaseToReadable(p?.for),
            value: p?.amount,
            show: p.for === PaymentFor.DeliveryExpense ? true : hasToshow
          },

          {
            key: 'Status Date',
            value: p?.createdAt
              ? format(Date?.parse(p?.createdAt), 'dd MMM yyyy')
              : '-',
            show: p.for === PaymentFor.DeliveryExpense ? true : hasToshow
          },
          {
            key: 'Payment Status',
            value: p?.status,
            show: p.for === PaymentFor.DeliveryExpense ? true : hasToshow
          },
          {
            key: 'View Request',
            value: 'View',
            metaData: {
              title: p?.for,
              data: [
                {
                  key: 'Request Date',
                  value: formatDate(p?.createdAt)
                },
                {
                  key: 'Payment Amount',
                  value: p?.amount
                },
                {
                  key: 'Payment Type',
                  value:  titleCaseToReadable(p?.for) ?? '-'
                },
                {
                  key: 'Bank Name',
                  value: p?.bankName ? titleCaseToReadable(p?.bankName) : '-'
                },
                {
                  key: 'Account holder Name',
                  value: p?.accountHolderName
                },
                {
                  key: 'Account Number',
                  value: p?.accountNo
                },
                {
                  key: 'IFSC code',
                  value: p?.ifsc
                },
                {
                  key: 'Account Proof',
                  value: p?.proofUrl ?? '-',
                  type: 'url'
                },
                {
                  key: 'payment Date',
                  value: formatDate(p?.paymentProcessedAt)
                },
                {
                  key: 'payment Mode',
                  value: titleCaseToReadable(p?.mode)
                },
                {
                  key: 'payment confirmation',
                  value: p?.proofUrl,
                  type: 'url'
                }
              ]
            },
            show: p.for === PaymentFor.DeliveryExpense ? true : hasToshow
          }
        ]
      ];
    }
  });
  return (
    <>
      <DetailsTabPanel data={dealDetail} />
    </>
  );
}

export default DealershipDealDetails;
