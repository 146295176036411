import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '../Form/Input';
import InputAutoComplete from '../Form/InputAutoComplete';
import '../Composite/demo.css';
import {
  ActionType,
  ModuleType,
  TaskStatus,
  useAddLogsMutation,
  useAddStateWithGstnMutation,
  useGetAllActiveStateListQuery,
  useGetAllActiveStateQuery,
  useGetAllStateQuery,
  useGetStateWithGstinForUpdateQuery,
  useGetStateWithGstinQuery,
  useUpdateActiveStateWithGstnMutation
} from 'src/generated/hooks_and_more';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useToasts } from 'react-toast-notifications';
import CloseIcon from '@mui/icons-material/Close';
import { titleCaseToReadable } from 'src/utils/utility';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '15px',
  cursor: 'pointer',
  color: 'rgb(195, 62, 62)'
};

export function AddState({ open, handleClose, value, id, onAddOrUpdate }: any) {
  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  const { data: stateList } = useGetAllStateQuery();
  const { data: leadData, loading: loadingData } =
    useGetStateWithGstinForUpdateQuery({
      variables: {
        id: id
      }
    });

  const { data: stateOption } = useGetStateWithGstinQuery({
    fetchPolicy: 'no-cache'
  });
  const [AddLogs] = useAddLogsMutation();
  const { loggedInUser } = useLoggedInUser();
  const [addStateDetail] = useAddStateWithGstnMutation();
  const [updateStateDetail] = useUpdateActiveStateWithGstnMutation();
  const spaceAndString = /^[a-zA-z0-9\s]+$/;
  const { addToast } = useToasts();
  const [found, setFound] = React.useState(false);


  const initialValues =  {
    stateName: Boolean(value)
      ? {
          label: leadData?.queryActiveStateWithGSTN?.[0]?.state,
          name: leadData?.queryActiveStateWithGSTN?.[0]?.state
        }
      : null,
    gstIn: Boolean(value)
      ? leadData?.queryActiveStateWithGSTN?.[0]?.GSTNumber
      : '',
    status: value
      ? {
          label: leadData?.queryActiveStateWithGSTN?.[0]?.active
            ? 'ACTIVE'
            : 'INACTIVE',
          name: leadData?.queryActiveStateWithGSTN?.[0]?.active
            ? 'ACTIVE'
            : 'INACTIVE'
        }
      : null
  }
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      gstIn: Yup.string()
        .matches(spaceAndString, '*Enter Valid GST Number')
        .required('*Required'),
      status: Yup.object().required('Required*'),
      stateName: Yup.object().required('Required*')
    }),

    onSubmit: (values) => {
      if (found === false && value === false) {
        const variables = {
          active: values?.status?.label == 'ACTIVE' ? true : false,
          GSTNumber: values?.gstIn,
          state: values?.stateName?.label,
          createdAt: new Date(),
          updatedAt: new Date()
        };
        addStateDetail({
          variables,
          onCompleted(data) {

            AddLogs({
              variables: {
                taskPerformedBy: { id: loggedInUser?.userId},
                actionType: ActionType.Create,
                module: ModuleType.Centre,
                createdAt: new Date(),
                sourceUpdated: formik?.values?.stateName?.name,
                updatedValue:  JSON.stringify({ data: {
                  active: values?.status?.label == 'ACTIVE' ? true : false,
                  GSTNumber: values?.gstIn,
                  state: values?.stateName?.label,
                  createdAt: new Date(),
                  updatedAt: new Date()
                } }),
                previousValue:"",
                status: TaskStatus.Success
              }
            });
            handleClose();
            onAddOrUpdate();
            formik.resetForm();
            addToast('Added successfully!!!', {
              appearance: 'success',
              autoDismiss: true
            });
          },
          onError() {
            AddLogs({
              variables: {
                taskPerformedBy: { id: loggedInUser?.userId},
                actionType: ActionType.Create,
                module: ModuleType.Centre,
                createdAt: new Date(),
                sourceUpdated: "",
                updatedValue: "",
                previousValue: "",
                status: TaskStatus.Failed
              }
            });
            handleClose();
            formik.resetForm();
            addToast('Error while updating leads!!!', {
              appearance: 'error',
              autoDismiss: true
            });
          }
        });
      } else if (value === true) {
        const variables = {
          state: values?.stateName?.label,
          active: values?.status?.label == 'ACTIVE' ? true : false,
          GSTNumber: values?.gstIn,
          updatedAt: new Date()
        };


        updateStateDetail({
          variables,
          onCompleted(data) {
            AddLogs({
              variables: {
                taskPerformedBy: { id: loggedInUser?.userId},
                actionType: ActionType.Update,
                module: ModuleType.Centre,
                createdAt: new Date(),
                sourceUpdated: "",
                updatedValue: JSON.stringify({data:{state: values?.stateName?.label,
                  active: values?.status?.label == 'ACTIVE' ? true : false,
                  GSTNumber: values?.gstIn,
                  updatedAt: new Date()}
                 }),
                previousValue: JSON.stringify({data: {
                  state: Boolean(value)
                  ? 
                      leadData?.queryActiveStateWithGSTN?.[0]?.state
                    
                  : null,
                  GSTNumber: Boolean(value)
                  ? leadData?.queryActiveStateWithGSTN?.[0]?.GSTNumber
                  : '',
                  active: Boolean(value)
                  ? leadData?.queryActiveStateWithGSTN?.[0]?.active
                        ? true
                        : false
                     
                    
                  : null,
                  updatedAt: ""
                },
              }),
                status: TaskStatus.Success
              }
            });
            handleClose();
            onAddOrUpdate();
            addToast('Updated successfully!!!', {
              appearance: 'success',
              autoDismiss: true
            });
          },
          onError(){
            AddLogs({
              variables: {
                taskPerformedBy: { id: loggedInUser?.userId},
                actionType: ActionType.Update,
                module: ModuleType.Centre,
                createdAt: new Date(),
                sourceUpdated: "",
                updatedValue: "",
                previousValue: "",
                status: TaskStatus.Failed
              }
            });
          }
        });
      } else if (found === true && value === false) {
        handleClose();
        formik.resetForm();
        addToast('This State is Already Exist', {
          appearance: 'error',
          autoDismiss: true
        });
      } else {
        console.log('no');
      }
    }
  });

  useEffect(() => {
    function checkForValue() {
      const Found = stateOption?.queryActiveStateWithGSTN?.some((r) =>
        r?.state
          ?.toUpperCase()
          ?.includes(formik?.values?.stateName?.label?.toUpperCase())
      );
      setFound(Found);
    }
    checkForValue();
  }, [value, formik?.values?.stateName?.label]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} borderRadius={1}>
          <Box sx={closeBtnStyle}>
            <CloseIcon
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
          <form className="addStateForm" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <InputAutoComplete
                  disabled={value === true ? true : false}
                  value={formik?.values?.stateName}
                  onChange={(value) => {
                    formik.setFieldValue('stateName', value);
                  }}
                  placeholder="State Name"
                  options={  stateList?.getStateInfo?.data?.map((i) => ({
                    label: titleCaseToReadable(i?.state_name),
                    name: i?.state_name
                  })) || []}

                />
                {formik?.errors?.stateName && formik.touched?.stateName ? (
                  <div style={{ color: 'red' }}>
                    {formik?.errors?.stateName}
                  </div>
                ) : null}
              </Grid>
              <Grid item md={12} xs={12}>
                <Input
                  value={formik.values.gstIn ?? ''}
                  name="gstIn"
                  onChange={(e) => {
                    formik.setFieldValue('gstIn', e?.target?.value);
                  }}
                  placeholder="GST Number"
                />
                {formik.errors.gstIn && formik.touched.gstIn ? (
                  <div style={{ color: 'red' }}>{formik?.errors?.gstIn}</div>
                ) : null}
              </Grid>

              <Grid item md={12} xs={12}>
                <InputAutoComplete
                  value={formik?.values?.status}
                  onChange={(value) => {
                    formik.setFieldValue('status', value);
                  }}
                  placeholder="Status"
                  options={[
                    { label: 'ACTIVE', name: 'ACTIVE' },
                    { label: 'INACTIVE', name: 'INACTIVE' }
                  ]}
                />
                {formik?.errors?.status && formik.touched?.status ? (
                  <div style={{ color: 'red' }}>{formik?.errors?.status}</div>
                ) : null}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              type="submit"
              sx={{ width: '100%', marginTop: '1.5rem' }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
