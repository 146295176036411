import { getYearFromDate, titleCaseToReadable } from '../../utils/utility';

import { TableCell, TableRow, Typography } from '@mui/material';
import {
  Lead,
  LeadPreviewFragment,
  useCloseLeadsMutation
} from '../../generated/hooks_and_more';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import DeleteIcon from '@mui/icons-material/Delete';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';

type LeadListProps = {
  leadData: Partial<LeadPreviewFragment>;
  index?: number;
};

function LeadListRow(props: LeadListProps) {
  const { leadData, index } = props;

  const nav = useNavigate();
  const { loggedInUser } = useLoggedInUser();

  const source = titleCaseToReadable(leadData?.source ?? '-');
  const make = leadData?.vehicle?.vehicleBrand?.name ?? '-';
  const year = leadData?.vehicle?.manufacturingDate
    ? getYearFromDate(leadData?.vehicle?.manufacturingDate ?? '-')
    : '-';

  const allotedCenter = leadData?.centre?.name ?? '-';
  const deliveryCenter = leadData?.deliveryCentre?.name ?? '-';
  const createdAt = leadData?.createdAt;
  const lastEvent =
    leadData?.statusEvents &&
    leadData?.statusEvents?.length &&
    leadData?.statusEvents;


  const lastEventUpdatedDate =
    lastEvent && lastEvent.length && lastEvent[0]?.createdAt;

  const currentStatus = lastEvent
    ? titleCaseToReadable(lastEvent?.[0]?.status ?? '-')
    : '-';
  const pendingOn = titleCaseToReadable(lastEvent?.[0]?.assignTaskTo) ?? '-';
  return (
    <TableRow
      hover
      onClick={() => {
        nav(`/vms-admin/leads/${leadData.regNo}`);
      }}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell title={leadData?.regNo ?? '-'} sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {leadData?.regNo ?? '-'}
        </Typography>
      </TableCell>

      <TableCell title={currentStatus} sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {currentStatus ?? '-'}
        </Typography>
      </TableCell>
      <TableCell title={pendingOn} sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {pendingOn ?? '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '170px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {source ?? '-'}
        </Typography>
      </TableCell>

      <TableCell title={make ?? ''} sx={{ maxWidth: '100px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {make}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '140px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {leadData?.vehicle?.vehicleModel?.name ?? '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '100px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {year ?? '-'}
        </Typography>
      </TableCell>
      {!location.pathname.includes('/documents/pending-leads') && (
        <TableCell
          title={leadData?.createdBy?.name ?? '-'}
          sx={{ maxWidth: '160px' }}
        >
          <Typography
            variant="body1"
            fontSize="13px"
            color="text.primary"
            gutterBottom
            noWrap
          >
            {leadData?.createdBy?.name ?? '-'}
          </Typography>
        </TableCell>
      )}

      <TableCell sx={{ maxWidth: '160px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {allotedCenter ?? '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '160px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {deliveryCenter ?? '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '120px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {!!createdAt ? format(Date.parse(createdAt), 'dd-MM-yyyy') : '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '120px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {!!lastEventUpdatedDate
            ? format(Date.parse(lastEventUpdatedDate), 'dd-MM-yyyy')
            : '-'}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default LeadListRow;
