import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { cacheLogFilter, enumToItems } from 'src/utils/utility';
import { DeviceType, ModuleType } from 'src/generated/hooks_and_more';
import { CircularProgress, Grid } from '@mui/material';
import Calender from 'src/components/Calender/Calender';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    borderRadius: 1,
    p: 2,

};

const closeBtnStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    // marginBottom: '10px',
    cursor: 'pointer',
    color: 'rgb(195, 62, 62)'
};

export default function FilterForLogs({ handleMainreset, filterLoading, setMainReset, open, handleClose, setOpen, setModal, reset }: any) {

    const [dateRange, setDateRange] = React.useState<
        [Date, Date] | null
    >();

    const [moduleValue, setmodule] = React.useState(null);
    const [sourceValue, setSource] = React.useState(null);
    const module = enumToItems(ModuleType);
    const source = enumToItems(DeviceType);
    let min = dateRange?.length ? dateRange[0].toISOString() : '';
    let max = dateRange?.length ? dateRange[1].toISOString() : '';
    const date = min !== '' || max !== '' ? { min, max } : null;

    function handleFilterLog() {
        let variable = {
            filters: {
                module: moduleValue?.value,
                source: sourceValue?.value,
                "createdAt": { "between": date }
            }
        }
        if (moduleValue) {
            variable.filters.module = { eq: moduleValue?.value };
        }
    
        if (sourceValue) {
            variable.filters.source = { eq: sourceValue?.value };
        }
    
        if (date) {
            variable.filters.createdAt = { between: date };
        }
    
        // if (moduleValue) {
        //     variable = {
        //         filters: { "module": { "eq": moduleValue.value } }
        //     }

        // }
        // if (sourceValue) {
        //     variable = {
        //         filters: { "source": { "eq": sourceValue.value } }
        //     }
        // }
        // if (!!date) {
        //     variable = {
        //         filters: { "createdAt": { "between": date } }
        //     }
        // }

        // if (!!sourceValue && !!moduleValue && !!date) {
        //     variable = {
        //         filters: {
        //             "module": { "eq": moduleValue.value }, "source": {
        //                 "eq": sourceValue.value
        //             }, "createdAt": { "between": date }
        //         }
        //     }
        // }

        cacheLogFilter({ variable });
        setOpen(false)

    }

    React.useEffect(() => {
        if (reset) {
            setmodule(moduleValue ? moduleValue : null)
            setSource(sourceValue ? sourceValue : null)
        }

    }, [reset])

    function handlereset() {
        setmodule(null)
        setSource(null)
    }


    return (
        <div>
            <Grid
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    gap: '13px',
                    mb: '2px'
                }}
            >
                <Button
                    sx={{ mt: { xs: 0, md: 0 } }}
                    variant="outlined"
                    onClick={() => {
                        handleMainreset();

                    }}
                >
                    Reset
                </Button>

                <Button
                    sx={{
                        mt: { xs: 0, md: 0 }
                    }}
                    variant="contained"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    Filter
                </Button>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style}>
                    <Box sx={closeBtnStyle}>
                        <CloseIcon
                            onClick={() => {
                                setOpen(false);
                            }}
                        />
                    </Box>
                    <Grid
                        container
                        spacing={1}
                        style={{ overflowY: 'auto', padding: '10px', height: '200px' }}
                    >

                    </Grid>
                    <Grid
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            gap: '20px',
                            mb: '5px'
                        }}
                    // md={12}
                    >
                        <Box sx={style}>
                            <Grid>
                                <InputAutoComplete placeholder='Select the module' options={module} value={moduleValue ?? null} onChange={(value) => setmodule(value)} name="name" />

                            </Grid>
                            <Grid mt={2}>
                                <InputAutoComplete placeholder='Select the souce' options={source} value={sourceValue ?? null} onChange={(value) => setSource(value)} name="name" />

                            </Grid>
                            <Grid mt={2} item xs={12} md={12}>
                                <Calender
                                    placeholder="Purchase year date range"
                                    onDateChange={(e, placehldr: string) => {
                                        setDateRange(e ? e : null);
                                    }
                                    }
                                    filteredDate={dateRange}
                                />
                            </Grid>


                        </Box>
                        <Button
                            sx={{ mt: { xs: 2, md: 0 } }}
                            variant="contained"
                            style={{ width: '110px' }}
                            onClick={() => {
                                handlereset();
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            sx={{ mt: { xs: 2, md: 0 } }}
                            // style={{ width: '120px' }}
                            variant="contained"
                            onClick={handleFilterLog}

                            startIcon={filterLoading ? <CircularProgress size={20} /> : null}
                        >
                            Apply Filter
                        </Button>
                    </Grid>
                </Box>
            </Modal>



        </div>
    );
}