import { Formik, useFormik } from 'formik';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';
import * as Yup from 'yup';
import '../form.css';
import Input from 'src/components/Form/Input';
import {
  BankName,
  InsuranceType,
  InsurerName,
  LeadRef,
  LoanToBeClosedBy
} from 'src/generated/hooks_and_more';
import { enumToItems } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';

import { LeadCreateAndUpdateProps } from 'src/types';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import CtaButton from '../CTA_Button/CtaButton';

const VehicleDetailsUpdateDForm = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton
  } = props;
  const BankNameOptions = enumToItems(BankName);
  const InsurerNameOptions = enumToItems(InsurerName);
  const insuranceTypeOptions = enumToItems(InsuranceType);
  const spaceAndString = /^[a-zA-z\s]+$/;

  const loanClosedByList = enumToItems(LoanToBeClosedBy);
  const formik = useFormik({
    initialValues: {
      dealerName: '',
      dealerMobile: '',
      engineNumber: '',
      chassisnumber: '',
      registrationDate: '',
      registrationCertificate: 'no',
      vehicleFinanced: 'no',
      vehicleInsured: 'no',
      fitnessValidUpto: '',
      bankName: null,
      insurerName: null,
      insuranceType: null,
      policyNo: '',
      policyExpiryDate: '',
      loanClosed: 'yes',
      pendingLoanAmount: '',
      loanClosedBy: null
    },

    validationSchema: Yup.object().shape({
      dealerName: Yup.string().
      matches(spaceAndString, '*Enter Valid Name')
      .required('*Required'),
      engineNumber: Yup.string().required('*Required'),
      chassisnumber: Yup.string().required('*Required'),
      registrationDate: Yup.string().required('*Required'),
      loanClosed: Yup.string().required('*Required'),
      dealerMobile: Yup.string()
        .matches( /^[0]?[5-9]\d{9}$/, 'Please enter a valid mobile number')
        .required('Phone is required'),
      registrationCertificate: Yup.string().required('*Required'),
      vehicleInsured: Yup.string().required('*Required'),
      vehicleFinanced: Yup.string().required('*Required'),
      fitnessValidUpto: Yup.string()
        .required('*Required')
        .when('registrationCertificate', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),

      pendingLoanAmount: Yup.number()
      .typeError('*Must be a Number')
        .required('*Required')
        .when('loanClosed', {
          is: 'no',
          otherwise: (schema) => schema.notRequired()
        }),

      loanClosedBy: Yup.object()
        .required('*Required')
        .when('loanClosed', {
          is: 'no',
          otherwise: (schema) => schema.notRequired()
        }),

      bankName: Yup.object()
        .required('*Required')
        .when('vehicleFinanced', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),

      insurerName: Yup.object()
        .required('*Required')
        .when('vehicleInsured', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),

      insuranceType: Yup.object()
        .required('*Required')
        .when('vehicleInsured', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      policyNo: Yup.string()
        .required('*Required')
        .when('vehicleInsured', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        }),
      policyExpiryDate: Yup.string()
        .required('*Required')
        .when('vehicleInsured', {
          is: 'yes',
          otherwise: (schema) => schema.notRequired()
        })
    }),
    onSubmit: (values) => {
      const leadInput: LeadRef = {
        id: leadId,
        regNo: regNo,
        vehicle: {
          registrationDate: values?.registrationDate,
          chassisNumber: values.chassisnumber,
          engineNumber: values.engineNumber,
          isRcAvailable:
            values.registrationCertificate === 'yes' ? true : false,
          fitnessValidUpto:
            values.registrationCertificate === 'yes'
              ? values.fitnessValidUpto
              : null,
          isVehicleFinanced: values.vehicleFinanced === 'yes' ? true : false,
          isVehicleInsured: values.vehicleInsured === 'yes' ? true : false,
          financingDetails:
            values.vehicleFinanced === 'yes'
              ? {
                  isLoanClosed: values.loanClosed === 'yes' ? true : false,
                  pendingLoanAmount:
                    values.loanClosed === 'yes'
                      ? null
                      : parseInt(values.pendingLoanAmount),
                  financerName:
                    values.vehicleFinanced === 'yes'
                      ? values.bankName.value
                      : null,
                  loanToBeClosedBy:
                    values.loanClosed === 'yes'
                      ? null
                      : values.loanClosedBy.value
                }
              : null,
          insuranceDetails:
            values.vehicleInsured === 'yes'
              ? {
                  insurerName: values.insurerName.value,
                  policyExpiryDate: values.policyExpiryDate,
                  policyNumber: values.policyNo,
                  insuranceType: values.insuranceType.value
                }
              : null
        },
        dealer: {
          name: values.dealerName,
          phoneNo: values.dealerMobile
        }
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            value={formik.values.dealerName}
            name="dealerName"
            onChange={(e) =>
              formik?.setFieldValue('dealerName', e.target.value)
            }
            placeholder="Dealer Name*"
          />
          {formik.values.dealerName && (formik.errors.dealerName || formik.touched.dealerName) ? (
            <div style={{ color: 'red' }}>{formik.errors?.dealerName}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Input
            value={formik.values.dealerMobile}
            name="dealerMobile"
            onChange={(e) =>
              formik?.setFieldValue('dealerMobile', e.target.value)
            }
            placeholder="Dealer Mobile*"
          />
          
          { formik.values.dealerMobile && (formik.errors.dealerMobile || formik.touched.dealerMobile) ? (
            <div style={{ color: 'red' }}>{formik.errors?.dealerMobile}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Input
            value={formik.values.engineNumber}
            name="engineNumber"
            onChange={(e) =>
              formik?.setFieldValue('engineNumber', e.target.value)
            }
            placeholder="Engine Number*"
          />
          {formik.values.engineNumber && (formik.errors.engineNumber || formik.touched.engineNumber) ? (
            <div style={{ color: 'red' }}>{formik.errors?.engineNumber}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Input
            value={formik.values.chassisnumber}
            name="chassisnumber"
            onChange={(e) =>
              formik?.setFieldValue('chassisnumber', e.target.value)
            }
            placeholder="Chassis Number*"
          />
          {formik.values.chassisnumber && (formik.errors.chassisnumber || formik.touched.chassisnumber) ? (
            <div style={{ color: 'red' }}>{formik.errors?.chassisnumber}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <DatePickerCalender
            onChange={(value) => {
              formik.setFieldValue(
                'registrationDate',
                value === null ? '' : value
              );
            }}
            placeholder="Registration Date*"
            value={
              formik.values.registrationDate ? new Date(formik.values.registrationDate) : null
            }
          />
          {formik.values.registrationDate &&
          (formik.errors.registrationDate ||
            formik.touched.registrationDate) ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.registrationDate}
            </div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid sx={{ fontSize: '16px' }} mt={2}>
            Registration certificate Available?*
          </Grid>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              value={formik.values.registrationCertificate}
              sx={{
                width: '300px',
                display: 'flex',
                justifyContent: 'space-around',
                marginTop: '12px'
              }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                onClick={() =>
                  formik.setFieldValue('registrationCertificate', 'yes')
                }
                label="Yes"
              />
              <FormControlLabel
                value="no"
                onClick={() =>
                  formik.setFieldValue('registrationCertificate', 'no')
                }
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {formik.values.registrationCertificate === 'yes' && (
          <>
            <Grid item xs={12} md={12}>
              <DatePickerCalender
                minDate={
                  formik.values.registrationDate
                    ? new Date(
                        new Date(formik.values.registrationDate).setDate(
                          new Date(formik.values.registrationDate).getDate() + 1
                        )
                      )
                    : null
                }
                onChange={(value) => {
                  formik.setFieldValue(
                    'fitnessValidUpto',
                    value === null ? '' : value
                  );
                }}
                placeholder="Fitness Valid Upto*"
                value={formik?.values?.fitnessValidUpto ? new Date(formik?.values?.fitnessValidUpto) : null}

              />
              { formik.values.fitnessValidUpto && (formik.errors.fitnessValidUpto ||
              formik.touched.fitnessValidUpto) ? (
                <div style={{ color: 'red' }}>
                  {formik.errors?.fitnessValidUpto}
                </div>
              ) : null}
            </Grid>
          </>
        )}
      </Grid>

      <Grid item xs={12} md={12} mt={2}>
        <Grid sx={{ fontSize: '16px' }} mt={2}>
          Is Vehicle Financed?*
        </Grid>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            value={formik.values.vehicleFinanced}
            sx={{
              width: '300px',
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '12px'
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              onClick={() => formik.setFieldValue('vehicleFinanced', 'yes')}
              label="Yes"
            />
            <FormControlLabel
              value="no"
              onClick={() => formik.setFieldValue('vehicleFinanced', 'no')}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>

        {formik.values.vehicleFinanced === 'yes' && (
          <>
            <Grid item xs={12} md={12} mt={2}>
              <InputAutoComplete
                onChange={(value) => {
                  formik.setFieldValue('bankName', value);
                }}
                name="bankName"
                value={formik.values.bankName}
                placeholder="Financer Name*"
                options={BankNameOptions}
                optionString="label"
                getOptionLabel={(option) => option.name}
              />
              { formik.values.bankName && (formik.errors.bankName && formik.touched.bankName) ? (
                <div style={{ color: 'red' }}>{formik.errors?.bankName}</div>
              ) : null}
            </Grid>
            {}

            {formik.values.vehicleFinanced === 'yes' && (
              <Grid>
                <Grid sx={{ fontSize: '16px' }} mt={2}>
                  Is Loan Closed?*
                </Grid>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue={formik.values.loanClosed}
                    sx={{
                      width: '300px',
                      display: 'flex',
                      justifyContent: 'space-around',
                      marginTop: '12px'
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      onClick={() => formik.setFieldValue('loanClosed', 'yes')}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      onClick={() => formik.setFieldValue('loanClosed', 'no')}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}

            {formik.values.loanClosed === 'no' && (
              <>
                <Grid mt={2}>
                  <Input
                    value={formik.values.pendingLoanAmount}
                    name="pendingLoanAmount"
                    onChange={(e) =>
                      formik?.setFieldValue('pendingLoanAmount', e.target.value)
                    }
                    placeholder="Enter pending Loan Amount*"
                  />
                  { formik.values.pendingLoanAmount && ( formik.errors.pendingLoanAmount ||
                  formik.touched.pendingLoanAmount) ? (
                    <div style={{ color: 'red' }}>
                      {formik.errors?.pendingLoanAmount}
                    </div>
                  ) : null}
                </Grid>

                <Grid mt={2}>
                  <InputAutoComplete
                    value={formik.values.loanClosedBy}
                    name="loanClosedBy"
                    options={loanClosedByList}
                    onChange={(value) =>
                      formik?.setFieldValue('loanClosedBy', value)
                    }
                    placeholder="Loan to be Closed by*"
                    optionString="label"
                    getOptionLabel={(option) => option.name}
                  />
                  {formik.errors.loanClosedBy && formik.touched.loanClosedBy ? (
                    <div style={{ color: 'red' }}>
                      {formik.errors?.loanClosedBy}
                    </div>
                  ) : null}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <Grid mt={2}>
        <Grid sx={{ fontSize: '16px' }}> Is vehicle Insured?*</Grid>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue={formik.values.vehicleInsured}
            sx={{
              width: '300px',
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '12px'
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              onClick={() => formik.setFieldValue('vehicleInsured', 'yes')}
              label="Yes"
            />
            <FormControlLabel
              value="no"
              onClick={() => formik.setFieldValue('vehicleInsured', 'no')}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>

        {formik.values.vehicleInsured === 'yes' && (
          <>
            <Grid mt={2}>
              <InputAutoComplete
                value={formik.values.insurerName}
                name="insurerName"
                options={InsurerNameOptions}
                onChange={(val) => formik?.setFieldValue('insurerName', val)}
                placeholder="Select Insurer Name*"
                optionString="label"
                getOptionLabel={(option) => option.name}
              />
              { formik.values.insurerName && (formik.errors.insurerName || formik.touched.insurerName) ? (
                <div style={{ color: 'red' }}>{formik.errors?.insurerName}</div>
              ) : null}
            </Grid>
            <Grid mt={2}>
              <InputAutoComplete
                value={formik.values.insuranceType}
                name="insuranceType"
                options={insuranceTypeOptions}
                onChange={(val) => formik?.setFieldValue('insuranceType', val)}
                placeholder="Insurance Type*"
                optionString="label"
                getOptionLabel={(option) => option.name}
              />
              {formik.errors.insuranceType && formik.touched.insuranceType ? (
                <div style={{ color: 'red' }}>
                  {formik.errors?.insuranceType}
                </div>
              ) : null}
            </Grid>
            <Grid mt={2}>
              <Input
                value={formik.values.policyNo}
                name="policyNo"
                onChange={(e) =>
                  formik?.setFieldValue('policyNo', e.target.value)
                }
                placeholder="Enter Policy Number*"
              />
              { formik.values.policyNo && (formik.errors.policyNo || formik.touched.policyNo) ? (
                <div style={{ color: 'red' }}>{formik.errors?.policyNo}</div>
              ) : null}
            </Grid>
            <Grid mt={2}>
              <DatePickerCalender
                minDate={
                  formik.values.registrationDate
                    ? new Date(
                        new Date(formik.values.registrationDate).setDate(
                          new Date(formik.values.registrationDate).getDate() + 1
                        )
                      )
                    : null
                }
                onChange={(value) => {
                  formik.setFieldValue(
                    'policyExpiryDate',
                    value === null ? '' : value
                  );
                }}
                placeholder="Enter Policy Expiry Date*"
                value={formik?.values?.policyExpiryDate ? new Date(formik?.values?.policyExpiryDate) : null}
              />
              {formik.errors.policyExpiryDate &&
              formik.touched.policyExpiryDate ? (
                <div style={{ color: 'red' }}>
                  {formik.errors?.policyExpiryDate}
                </div>
              ) : null}
            </Grid>
          </>
        )}
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        disabled={!(formik.isValid && formik.dirty)}
      />
    
    </form>
  );
};

export default VehicleDetailsUpdateDForm;
