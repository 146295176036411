import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import {
  Button,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import Input from 'src/components/Form/Input';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { Fragment, useEffect, useState } from 'react';
import { AutoCompleteValueType, LeadCreateAndUpdateProps } from 'src/types';
import {
  LeadRef,

  RefurbishmentStatus,
  SparePart,
  useAddStocksMutation,
  useGetCenterFormLeadQuery,
  useGetStocksDetailsLazyQuery,
} from 'src/generated/hooks_and_more';
import { useToasts } from 'react-toast-notifications';
import {
  enumToItems,
  titleCaseToReadable
} from 'src/utils/utility';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { useNavigate } from 'react-router';
import CtaButton from '../CTA_Button/CtaButton';
import { InspectVehicleSchema } from './ValidationSchema';
import { ActionType } from 'src/constants/constant';
// import FileUploadToS3 from 'src/components/Form/FileUploadToS3';

function InspectVehicle(props: LeadCreateAndUpdateProps) {
  const {
    desireButtonText,
    undesireButtonText,
    toggleDrawer,
    refetch,
    onPressOfDesiredOrUndesiredButton,
    canGoBack,
    leadId,
    desireStatus,
    regNo
  } = props;
  const [getStocks, { data: stockDetailData }] = useGetStocksDetailsLazyQuery();

  const { data: centerData } = useGetCenterFormLeadQuery({
    variables: {
      leadId: leadId
    },
    onCompleted(data) {
      getStocks({ variables: { centreId: data?.getLead?.centre?.id } });
    }
  });

  const navigation = useNavigate();
  const { addToast } = useToasts();

  const [addStocksMutation, { data, loading, error }] = useAddStocksMutation();
  const sparePart = enumToItems(SparePart);

  const userData = JSON?.parse(localStorage.getItem('userData'));

  function getSparePartStockStatus(value: string) {
    const sparePartStockStatus = stockDetailData?.inventoryDetails?.items?.find(
      (i) => {
        if ('OTHERS' === titleCaseToReadable(value)?.toUpperCase()) {
          return 0;
        } else {
          return (
            i?.product?.name?.toUpperCase() ===
            titleCaseToReadable(value)?.toUpperCase()
          );
        }
      }
    );

    return sparePartStockStatus?.availableInStock > 0
      ? 'Available'
      : 'Not Available';
  }
  const reject = () => {
    let leadInput: LeadRef = {
      regNo: regNo,
      id: leadId
    };
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <Grid container>
      <Formik
        initialValues={{
          items: [
            {
              type: null,
              stockAvailable: null,
              amount: null,
              others: false,
              imageUpload: null,
              description: null
            }
          ]
        }}
        validationSchema={InspectVehicleSchema}
        onSubmit={(values, action) => {
          const requestedItems = values?.items?.map((item) => ({
            price: Number(item?.amount) ?? null,
            refurbishmentProofUrl: item?.imageUpload,

            status: RefurbishmentStatus.Requested,
            product: {
              name: item?.type?.label
            },
            description: item?.description ?? null

          }));
          let leadInput: LeadRef = {
            id: leadId,
            regNo: regNo,
            refurbishmentDetails: {
              requests: [
                {
                  items: requestedItems
                }
              ]
            }
          };

          onPressOfDesiredOrUndesiredButton(leadInput);
        }}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          errors,
          touched,
          setFieldValue,
          dirty,
          isValid
        }) => (
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <FieldArray name="items">
                {({ push, remove }) => (
                  <Fragment>
                    {values.items.map((_, index) => (
                      <Fragment key={`form - ${index}`}>
                        <Grid container sx={{ mb: 2 }} spacing={2} key={index}>
                          <Grid item xs={12} md={7} >
                            <InputAutoComplete
                              value={values.items[index].type}
                              key="stockType"
                              options={sparePart}
                              disableClearable
                              onChange={(value) => {
                                const status = getSparePartStockStatus(
                                  value?.value
                                );

                                if (value?.value === 'OTHERS') {
                                  setFieldValue(`items[${index}].others`, true);
                                }
                                setFieldValue(`items[${index}].type`, value);
                                setFieldValue(
                                  `items[${index}].stockAvailable`,
                                  status === 'Available' ? true : false
                                );
                              }}
                              placeholder="Select part to be refurbished*"
                            />
                            {values.items[index].type && (
                              <p style={{ marginTop: '5px', color: '#1F2737' }}>
                                Status:-{' '}
                                {values?.items[index]?.stockAvailable ? (
                                  <span style={{ color: 'green' }}>
                                    Available
                                  </span>
                                ) : (
                                  <span style={{ color: 'red' }}>
                                    Not Available
                                  </span>
                                )}
                              </p>
                            )}
                            <ErrorMessage
                              render={(msg) => (
                                <div style={{ color: 'red' }}>* Required</div>
                              )}
                              name={`items[${index}].type`}
                            />
                          </Grid>

                          {values?.items[index]?.stockAvailable === false && (
                            <Grid item xs={12} md={5}>
                              <Input
                                value={values.items[index].amount}
                                inputType="number"
                                name={`items[${index}].amount`}
                                placeholder="Amount"
                                onChange={handleChange}
                              />
                              {
                                <ErrorMessage
                                  render={(msg) => (
                                    <div style={{ color: 'red' }}>{msg}</div>
                                  )}
                                  name="amount"
                                />
                              }
                            </Grid>
                          )}
                          {values?.items[index]?.type?.value === 'OTHERS' && (
                            <Grid item xs={12} md={7}>
                              <Input
                                value={values.items[index].description}
                                name={`items[${index}].description`}
                                placeholder="Description"
                                onChange={handleChange}
                              />
                              {
                                <ErrorMessage
                                  render={(msg) => (
                                    <div style={{ color: 'red' }}>{msg}</div>
                                  )}
                                  name="description"
                                />
                              }
                            </Grid>
                          )}

                          <Grid item xs={12} md={5}>
                            <FileUploadToS3
                              id={`Upload_${String(Math.random() * 100000)}`}
                              value={values.items[index].imageUpload}
                              onChange={(value) => {
                                setFieldValue(
                                  `items[${index}].imageUpload`,
                                  value
                                );
                              }}
                              placeholder="Upload"
                            />
                            <ErrorMessage
                              render={(msg) => (
                                <div style={{ color: 'red' }}>{msg}</div>
                              )}
                              name={`items[${index}].imageUpload`}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} sx={{ display: 'flex' }}>
                            {values?.items?.length === 1 ? (
                              <>
                                <Grid item xs={12} md={12} >
                                  <Grid item xs={12} md={12}>
                                    <hr style={{
                                      borderRadius: "1px"
                                    }}></hr>
                                  </Grid>
                                  <Grid>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color='primary'
                                      onClick={() => {
                                        push({
                                          type: null,
                                          stockAvailable: null,
                                          amount: null,
                                          others: false,
                                          imageUpload: null,
                                          description: null
                                        });
                                      }}
                                    >
                                      add More +
                                    </Button> </Grid>
                                </Grid>


                              </>

                            ) : (
                              <Grid item xs={12} md={12}>


                                <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                                  {values?.items?.length > 0 && (
                                    <Button
                                      style={{ backgroundColor: '#e30000', color: 'white' }}
                                      variant="contained"
                                      size="small"
                                      // color='error'
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      remove
                                    </Button>
                                  )}
                                </Grid>
                                <hr style={{
                                  borderRadius: "1px"
                                }}></hr>
                                <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                                  {index === values?.items?.length - 1 && (

                                    <Button
                                      variant="contained"
                                      size="small"
                                      color='primary'
                                      // style={{ width: "30%" }}
                                      onClick={() => {
                                        push({
                                          type: null,
                                          stockAvailable: null,
                                          amount: null,
                                          others: false,
                                          imageUpload: null,
                                          description: null
                                        });
                                      }}
                                    >
                                      Add More +
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>

                            )}
                          </Grid>

                        </Grid>


                      </Fragment>
                    ))}
                  </Fragment>
                )}
              </FieldArray>

              <CtaButton
                desireButtonText={desireButtonText}
                undesireButtonText={undesireButtonText}
                backBtn={canGoBack}
                reject={reject}
                onlyRed={true}
                disabled={!(isValid && dirty)}
              />
            </form>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
}

export default InspectVehicle;
