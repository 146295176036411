import React from 'react';
import { LeadDetailsPropsType } from './ApplicationDetails';
import { LeadSource, BookingPaymentRef, LeadStatus, BookingType, LoanStatus } from 'src/generated/hooks_and_more';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import format from 'date-fns/format';
import { formatDate, titleCaseToReadable } from 'src/utils/utility';


function LoanApplicationDetails({ currentLead }: LeadDetailsPropsType) {
  

  const isFirstLoanApplication =
    currentLead?.activeBooking?.bookingPayment?.bookingType === BookingType.Finance &&
    !currentLead?.activeBooking?.activeLoan &&
    currentLead?.activeBooking?.archivedLoans?.length === 0

  const isNonFirstLoanApplication =
    currentLead?.activeBooking?.bookingPayment?.bookingType === BookingType.Finance &&
    !currentLead?.activeBooking?.activeLoan &&
    currentLead?.activeBooking?.archivedLoans?.length > 0

  const isLoanCancelledAndRequestedAgain =
    currentLead?.statusEvents?.some(
      lse => lse?.status === LeadStatus.PaymentStructureUpdated,
    ) &&
    currentLead?.statusEvents?.some(lse => lse?.status === LeadStatus.LoanCancelled) &&
    currentLead?.statusEvents?.findIndex(
      lse => lse?.status === LeadStatus.PaymentStructureUpdated,
    ) <
    currentLead?.statusEvents?.findIndex(
      lse => lse?.status === LeadStatus.LoanCancelled,
    )
  const isLoanCaseDroppedAfterCancel =
    currentLead?.statusEvents?.findIndex(
      lse => lse?.status === LeadStatus.BookingFinanceCaseDropped,
    ) <
    currentLead?.statusEvents?.findIndex(lse => lse?.status === LeadStatus.LoanCancelled)

  const fileStatus =  isFirstLoanApplication
    ? (titleCaseToReadable(LoanStatus.Requested) as LoanStatus)
    : isNonFirstLoanApplication
      ? isLoanCancelledAndRequestedAgain &&
        !isLoanCaseDroppedAfterCancel
        ? (titleCaseToReadable(
          LoanStatus.Requested,
        ) as LoanStatus)
        : titleCaseToReadable(
          currentLead?.activeBooking?.archivedLoans?.[0]?.loanStatus,
        )
      : (titleCaseToReadable(
        currentLead?.activeBooking?.activeLoan?.loanStatus,
      ) as LoanStatus)



  const afterLoanDropFileStatus = LoanStatus.Cancelled

  const loginApplicationFormUrl = currentLead?.activeBooking?.activeLoan?.loginApplicationFormUrl;
  const deliveryOrderDocUrl = currentLead?.activeBooking?.activeLoan?.deliveryOrderDocUrl;

  const sanctionedLoanAmount = currentLead?.activeBooking?.activeLoan?.sanctionedLoanAmount;
  const appliedLoanAmount = currentLead?.activeBooking?.bookingPayment?.bookingType ===
    BookingType.Finance || currentLead?.activeBooking?.isLoanDrop === true
    ? currentLead?.activeBooking?.bookingPayment?.appliedLoanAmount
    : 0
  const fieldInspectionConfirmedDate =  formatDate(currentLead?.activeBooking?.activeLoan?.fieldInspectionConfirmedDate) ?? formatDate(currentLead?.activeBooking?.activeLoan?.fieldInspectionDate) 
  const deliveryOrderDate = currentLead?.activeBooking?.activeLoan?.deliveryOrderDate ? formatDate(currentLead?.activeBooking?.activeLoan?.deliveryOrderDate) : '-';
 
  const bankName = currentLead?.activeBooking?.activeLoan?.bankName;
  const deliveryOrderValidity = currentLead?.activeBooking?.activeLoan?.deliveryOrderValidity

  const LoanRejectionRemarks = currentLead?.activeBooking?.isLoanDrop
    ? titleCaseToReadable(currentLead?.activeBooking?.loanCancellationReason)
    : '-'
  const loginDate = currentLead?.activeBooking?.activeLoan?.loginDate ? format(Date.parse(currentLead?.activeBooking?.activeLoan?.loginDate), 'dd-MM-yy') : '-';
  const StatusDate = currentLead?.statusEvents
    ?.filter(
      i =>
        i?.status === LeadStatus.BookingLoanFileLoggedIn ||
        i?.status === LeadStatus.BookingFinanceCaseDropped ||
        i?.status === LeadStatus.BookingLoanFIAdded ||
        i?.status === LeadStatus.BookingLoanFIPassed ||
        i?.status === LeadStatus.BookingLoanDOApproved ||
        i?.status === LeadStatus.PaymentStructureAdded
    )
    ?.sort(
      (a, b) =>
        new Date(b?.createdAt)?.getTime() - new Date(a?.createdAt)?.getTime(),
    )

    const statusFile =  currentLead?.activeBooking?.isLoanDrop
      ? (titleCaseToReadable(
          afterLoanDropFileStatus,
        ) as LoanStatus)
      : (fileStatus as LoanStatus)
  // const requestedDate = currentLead?.statusEvents?.map((i) => {
  //     if (i.status === LeadStatus.PaymentStructureAdded) {
  //         return currentLead?.activeBooking?.activeLoan?.createdAt
  //             ? format(
  //                 Date.parse(currentLead?.activeBooking?.activeLoan?.createdAt),
  //                 'dd-MM-yy'
  //             )
  //             : "-";
  //     } else {
  //         return null;
  //     }


  // });

  const DorejectionRemarks = !currentLead?.activeBooking?.activeLoan && currentLead?.activeBooking?.archivedLoans?.[0]?.rejectionReason
  const requestDate = currentLead?.statusEvents?.find(
    i => i?.status === LeadStatus.PaymentStructureAdded,
  )?.createdAt

  const requestedDate = !!statusFile
  ? !!requestDate
    ? formatDate(requestDate)
    : '-'
  : '-'

  const loanStatusDate = !!statusFile
    ? !!StatusDate
      ? formatDate(StatusDate?.[0]?.createdAt)
      : '-'
    : '-'
  const loanApplicagionDetails: {
    key: string;
    value: string | number | string[];
    isHidden?: boolean;
    type?: string;
  }[] = [
      {
        key: 'File Status',
        value: currentLead?.activeBooking?.isLoanDrop
          ? (titleCaseToReadable(
            afterLoanDropFileStatus,
          ) as LoanStatus)
          : fileStatus,

      },
      {
        key: 'Status Date',
        value: loanStatusDate ?? '-'
      },
      {
        key: 'Approved Amount',
        value: sanctionedLoanAmount ?? "-",
      },
      {
        key: 'Requested Amount',
        value: appliedLoanAmount === 0 || !appliedLoanAmount
          ? '-'
          : appliedLoanAmount ?? 0,
      },
      {
        key: 'Requested Date',
        value:   requestedDate ?? '-'
      },

      {
        key: 'Bank Name',
        value: Boolean(bankName) ? titleCaseToReadable(bankName) : "-" ?? "-"
      },
      {
        key: 'Login Date',
        value:  loginDate ?? "-",
      },
      {
        key: 'FI Date',
        value: fieldInspectionConfirmedDate ?? "-",
      },
      {
        key: 'DO Date',
        value: deliveryOrderDate ?? "-",
      },
      {
        key: 'Delivery Order',
        value: deliveryOrderDocUrl ?? "-",
        type: 'image',

        isHidden:
          currentLead?.source === LeadSource.DealershipSale
            ? currentLead?.documentChecklist?.indemnityBond
            : true
      },
      {
        key: 'DO valid upto(in Days)',
        value: deliveryOrderValidity ?? "-"
      },
      {
        key: 'login Application Form',
        value: loginApplicationFormUrl ?? "-",
        type: 'image',

        isHidden:
          currentLead?.source === LeadSource.DealershipSale
            ? currentLead?.documentChecklist?.indemnityBond
            : true

      },
      {
        key: 'DO Rejection Remarks',
        value: fileStatus === "Request" ? '-' : titleCaseToReadable(DorejectionRemarks) ? titleCaseToReadable(DorejectionRemarks) : '-'  ?? '-',

      },
      {
        key: 'Loan Rejection Remarks',
        value:   LoanRejectionRemarks  ?? "-",

      }
    ];

  return <DetailsTabPanel data={loanApplicagionDetails} />;
}

export default LoanApplicationDetails;
;
