import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import Input from 'src/components/Form/Input';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { manufacturingYearOptions } from 'src/constants/constant';
import {
  LeadDetailsFragment,
  LeadSource,
  UserRole,
  VehicleMake,
  useGetCentreListQuery,
  useGetUserByRoleQuery,
  useModelByMakeQuery,
  useUpdateLeadDetailsMutation,
  useUpdateLeadVehicleDetailsMutation,
  useUpdateLeadCenterMutation,
  useUpdatePickupDetailsMutation,
  useUpdateYardDetailsMutation,
  useGetAllNewBrandsQuery,
  useGetModelByBrandIdQuery
} from 'src/generated/hooks_and_more';
import {
  EditBankAuctionLeadExpense,
  EditBankAuctionLeadSchema
} from 'src/utils/schema';
import { enumToItems } from 'src/utils/utility';

type VehicleDetailsPropsType = {
  leadInput: LeadDetailsFragment | null;
  loading: boolean;
};

function EditBankAuctionLead({ leadInput, loading }: VehicleDetailsPropsType) {
  const vehicleMake = enumToItems(VehicleMake);
  const brandListOptions = useGetAllNewBrandsQuery();
  const center = useGetCentreListQuery();

  console.log(leadInput, "leadInputleadInputleadInput")
  const { data: driverList } = useGetUserByRoleQuery({
    variables: {
      role: UserRole.Driver
    }
  });
  const { addToast } = useToasts();

  const [updatePickupDetails, { loading: updatePickup }] =
    useUpdatePickupDetailsMutation();
  const [updateLeadDetailsMutation, { data, error, loading: updateLoading }] =
    useUpdateLeadDetailsMutation();
  const [updateLeadCenter, { loading: updatCenter }] =
    useUpdateLeadCenterMutation();
  const [updateLeadVehicleDetails, { loading: updateVehicle }] =
    useUpdateLeadVehicleDetailsMutation();
  const [models, setModel] = useState([]);
  const [UpdateYardDetails] = useUpdateYardDetailsMutation();

  const { refetch: getModelByMake, data: modelOptions } =
    useGetModelByBrandIdQuery({
      skip: !leadInput?.vehicle?.vehicleBrand?.name,
      variables: { brand_id: Number(leadInput?.vehicle?.vehicleBrand?.id) },
      onCompleted(data) {
        setModel(data?.queryBrand?.[0]?.models);
      }
    });
  const initialValues = {
    initialBidAmount: leadInput?.proposedBidLimitAmount ?? '',
    finalBidAmount: leadInput?.finalBidAmount ?? '',
    make: leadInput?.vehicle?.vehicleBrand,
    model: leadInput?.vehicle?.vehicleModel,
    year: {
      label: leadInput?.vehicle?.manufacturingDate
        ? new Date(leadInput?.vehicle?.manufacturingDate).getFullYear()
        : ''
    },
    parkingPaymentByDriver: leadInput?.yard?.perDayParkingCharge ?? '',
    parkingPaymentByLM: leadInput?.finalParkingCharges ?? '',
    repossesionDateByDriver: leadInput?.vehicle?.repossessionDate
      ? new Date(leadInput?.vehicle?.repossessionDate)
      : null,
    repossesionDateByPE: leadInput?.vehicle?.tempRepossessionDate
      ? new Date(leadInput?.vehicle?.tempRepossessionDate)
      : null,
    sellignPrice: leadInput?.sellingPrice ?? '',
    listingPrice: leadInput?.listingPrice ?? '',
    centre: { name: leadInput?.centre?.name ?? '' },
    driver: {
      name: leadInput?.pickup?.by?.name ?? '',
      email: leadInput?.pickup?.by?.email ?? ''
    },
    demandAmount: leadInput?.demandAmount ?? '',
    approvedDealAmount: leadInput?.approvedDealAmount ?? '',
    expectedPickupDate: leadInput?.expectedPickupDate
      ? new Date(leadInput?.expectedPickupDate)
      : null,
    yardName: leadInput?.yard?.name ?? '',
    yardAddress: leadInput?.yard?.address ?? '',
    yardLocationURL: leadInput?.yard?.locationUrl ?? '',
    yardSpocName: leadInput?.yard?.spocName ?? '',
    yardSpocMobile: leadInput?.yard?.spocNo ?? ''
  };

  const manufacturingYear = manufacturingYearOptions();
  if (loading) {
    return <Typography variant="h3">loading</Typography>;
  }

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={EditBankAuctionLeadSchema}
        onSubmit={(values) => {
          updateLeadDetailsMutation({
            variables: {
              regNo: leadInput.regNo,
              finalBidAmount: values.finalBidAmount
                ? (Number(values.finalBidAmount) as number)
                : null,
              proposedBidLimitAmount: values.initialBidAmount
                ? (Number(values.initialBidAmount) as number)
                : null,
              sellingprice: values.sellignPrice
                ? (Number(values.sellignPrice) as number)
                : null,
              listingPrice: values.listingPrice
                ? (Number(values.listingPrice) as number)
                : null,
              demandAmount: values?.demandAmount
                ? Number(values?.demandAmount)
                : null,
              approvedDealAmount: values?.approvedDealAmount
                ? Number(values?.approvedDealAmount)
                : null,
              expectedPickupDate: values?.expectedPickupDate,
              finalParkingCharges: values?.parkingPaymentByLM
                ? Number(values?.parkingPaymentByLM)
                : null
            },
            onCompleted() {
              addToast('Lead Updated Successfully!!!', {
                appearance: 'success',
                autoDismiss: true
              });
            },
            onError(error) {
              console.log(error);
              addToast('Error while updating leads!!!', {
                appearance: 'error',
                autoDismiss: true
              });
            }
          });
        }}
      >
        {({ errors, touched, values, handleChange, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                {leadInput?.source === LeadSource.BankAuction ? (
                  <>
                    <Grid item xs={6} md={6}>
                      <Input
                        value={values.initialBidAmount}
                        onChange={(e) => {
                          setFieldValue('initialBidAmount', e.target.value);
                        }}
                        name="initialBidAmount"
                        placeholder="Propose Bid"
                      />
                      {errors.initialBidAmount && touched.initialBidAmount ? (
                        <div style={{ color: 'red' }}>
                          {errors.initialBidAmount}
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Input
                        value={values.finalBidAmount}
                        onChange={(e) => {
                          setFieldValue('finalBidAmount', e.target.value);
                        }}
                        name="finalBidAmount"
                        placeholder="Final Bid"
                      />
                      {errors.finalBidAmount && touched.finalBidAmount ? (
                        <div style={{ color: 'red' }}>
                          {errors.finalBidAmount}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Input
                        value={values.parkingPaymentByLM}
                        onChange={(e) => {
                          setFieldValue('parkingPaymentByLM', e.target.value);
                        }}
                        name="parkingPaymentByLM"
                        placeholder="Final Parking charges"
                      />
                      {errors.parkingPaymentByLM &&
                      touched.parkingPaymentByLM ? (
                        <div style={{ color: 'red' }}>
                          {errors.parkingPaymentByLM}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <DatePickerCalender
                        placeholder="Expected Pickup Date (By LM)"
                        value={values.expectedPickupDate}
                        onChange={(e) => {
                          setFieldValue('expectedPickupDate', e);
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6} md={6}>
                      <Input
                        value={values.demandAmount}
                        onChange={(e) => {
                          setFieldValue('demandAmount', e.target.value);
                        }}
                        name="demandAmount"
                        placeholder="Demand amount"
                      />
                      {errors.demandAmount && touched.demandAmount ? (
                        <div style={{ color: 'red' }}>
                          {errors.demandAmount}
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Input
                        value={values.approvedDealAmount}
                        onChange={(e) => {
                          setFieldValue('approvedDealAmount', e.target.value);
                        }}
                        name="approvedDealAmount"
                        placeholder="Approved amount"
                      />
                      {errors.approvedDealAmount &&
                      touched.approvedDealAmount ? (
                        <div style={{ color: 'red' }}>
                          {errors.approvedDealAmount}
                        </div>
                      ) : null}
                    </Grid>
                  </>
                )}

                <Grid item xs={6} md={6}>
                  <Input
                    value={values.sellignPrice}
                    onChange={(e) => {
                      setFieldValue('sellignPrice', e.target.value);
                    }}
                    name="sellignPrice"
                    placeholder="Sellign Price (By SH)"
                  />
                  {errors.sellignPrice && touched.sellignPrice ? (
                    <div style={{ color: 'red' }}>{errors.sellignPrice}</div>
                  ) : null}
                </Grid>
                <Grid item xs={6} md={6}>
                  <Input
                    value={values.listingPrice}
                    onChange={(e) => {
                      setFieldValue('listingPrice', e.target.value);
                    }}
                    name="listingPrice"
                    placeholder="Listing Price (By CM)"
                  />

                  {errors.listingPrice && touched.listingPrice ? (
                    <div style={{ color: 'red' }}>{errors.listingPrice}</div>
                  ) : null}
                </Grid>
              </Grid>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  sx={{ mt: 2, mb: 2 }}
                  style={{ width: '120px' }}
                  variant="contained"
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Divider sx={{ mb: 3, backgroundColor: 'gray', height: 2 }} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => {
          updateLeadVehicleDetails({
            variables: {
              regNo: leadInput?.regNo,
              brand_id: values?.make?.id,
              model_id: values?.model?.id,

              manufacturingDate: values?.year?.label,
              repossesionDateByDriver: values?.repossesionDateByDriver,
              repossesionDateByPE: values?.repossesionDateByPE
            },
            onCompleted() {
              addToast('Vehicle Details Updated Successfully!!!', {
                appearance: 'success',
                autoDismiss: true
              });
            },
            onError(error) {
              console.log(error);
              addToast('Error while updating leads!!!', {
                appearance: 'error',
                autoDismiss: true
              });
            }
          });
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <InputAutoComplete
                    placeholder="Vehicle make"
                    value={values?.make ?? null}
                    onChange={(value) => {
                      setFieldValue('make', value);
                      getModelByMake({ brand_id: Number(value?.brand_id) });
                      setFieldValue('model', null);
                    }}
                    optionString="name"
                    options={brandListOptions?.data?.queryBrand ?? []}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <InputAutoComplete
                    placeholder="Vehicle model"
                    value={values?.model ?? null}
                    disabled={!values?.make?.name}
                    onChange={(value) => {
                      setFieldValue('model', value);
                    }}
                    options={models ?? []}
                    optionString="name"
                  />
                </Grid>

                {leadInput.source === LeadSource.BankAuction ? (
                  <>
                    <Grid item xs={6} md={6}>
                      <DatePickerCalender
                        placeholder="Repossession date (By PE)"
                        value={values.repossesionDateByPE}
                        onChange={(e) => {
                          setFieldValue('repossesionDateByPE', e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <DatePickerCalender
                        placeholder="Repossession date (By Driver)"
                        value={values.repossesionDateByDriver}
                        onChange={(e) => {
                          setFieldValue('repossesionDateByDriver', e);
                        }}
                      />
                    </Grid>
                  </>
                ) : null}
                <Grid item md={6} xs={6}>
                  <InputAutoComplete
                    placeholder="Manufacturing year"
                    value={values?.year ? values?.year : null}
                    onChange={(value) => {
                      setFieldValue('year', value);
                    }}
                    options={manufacturingYear ?? []}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  sx={{ mt: 2, mb: 2 }}
                  style={{ width: '120px' }}
                  variant="contained"
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <Divider sx={{ mb: 3, backgroundColor: 'gray', height: 2 }} />

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (values.centre.name) {
            updateLeadCenter({
              variables: {
                regNo: leadInput?.regNo,
                centerName: values?.centre?.name
              },
              onCompleted() {
                addToast('Centre Updated Successfully!!!', {
                  appearance: 'success',
                  autoDismiss: true
                });
              },
              onError(error) {
                console.log(error);
                addToast('Error while updating leads!!!', {
                  appearance: 'error',
                  autoDismiss: true
                });
              }
            });
          }
        }}
      >
        {({ errors, touched, values, handleChange, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <InputAutoComplete
                    placeholder="Centre"
                    value={values?.centre ?? ''}
                    onChange={(value) => {
                      setFieldValue('centre', value);
                    }}
                    disableClearable={true}
                    options={center?.data?.queryCentre ?? []}
                    optionString="name"
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  sx={{ mt: 2, mb: 2 }}
                  style={{ width: '120px' }}
                  variant="contained"
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Divider sx={{ mb: 3, backgroundColor: 'gray', height: 2 }} />

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (values.centre.name) {
            updatePickupDetails({
              variables: {
                regNo: leadInput?.regNo,
                driverEmail: values?.driver?.email,
                driverName: values?.driver?.name
                // centerName: values?.centre?.name
              },
              onCompleted() {
                addToast('Driver Updated Successfully!!!', {
                  appearance: 'success',
                  autoDismiss: true
                });
              },
              onError(error) {
                console.log(error);
                addToast('Error while updating leads!!!', {
                  appearance: 'error',
                  autoDismiss: true
                });
              }
            });
          }
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <InputAutoComplete
                    placeholder="Driver"
                    disabled={!leadInput?.pickup?.by?.name}
                    value={values?.driver ?? ''}
                    disableClearable={true}
                    onChange={(value) => {
                      setFieldValue('driver', value);
                    }}
                    options={driverList?.queryUser ?? []}
                    optionString="name"
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  sx={{ mt: 2, mb: 2 }}
                  style={{ width: '120px' }}
                  variant="contained"
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      {leadInput.source === LeadSource.BankAuction ? (
        <>
          <Divider sx={{ mb: 3, backgroundColor: 'gray', height: 2 }} />

          <Formik
            initialValues={initialValues}
            validationSchema={EditBankAuctionLeadExpense}
            onSubmit={(values) => {
              UpdateYardDetails({
                variables: {
                  // regNo: leadInput?.regNo,
                  address: values.yardAddress,
                  locationURL: values.yardLocationURL,
                  spocMobile: values.yardSpocMobile,
                  spocName: values.yardSpocName,
                  name: values.yardName
                  // perDayParkingCharges: values.parkingPaymentByDriver
                  //   ? Number(values.parkingPaymentByDriver)
                  //   : null,
                  // finalParkingCharges: values.parkingPaymentByLM
                  //   ? Number(values?.parkingPaymentByLM)
                  //   : null
                },
                onCompleted() {
                  addToast('Yard Updated Successfully!!!', {
                    appearance: 'success',
                    autoDismiss: true
                  });
                },
                onError(error) {
                  console.log(error);
                  addToast('Error while updating leads!!!', {
                    appearance: 'error',
                    autoDismiss: true
                  });
                }
              });
            }}
          >
            {({ values, setFieldValue, errors, touched }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={6} md={6}>
                      <Input
                        value={values.parkingPaymentByDriver}
                        onChange={(e) => {
                          setFieldValue(
                            'parkingPaymentByDriver',
                            e.target.value
                          );
                        }}
                        name="parkingPaymentByDriver"
                        placeholder="Parking expense amount per day (By Driver)"
                      />
                      {errors.parkingPaymentByDriver &&
                      touched.parkingPaymentByDriver ? (
                        <div style={{ color: 'red' }}>
                          {errors.parkingPaymentByDriver}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Input
                        value={values.parkingPaymentByLM}
                        onChange={(e) => {
                          setFieldValue('parkingPaymentByLM', e.target.value);
                        }}
                        name="parkingPaymentByLM"
                        placeholder="Parking expense amount per day (By LM)"
                      />
                      {errors.parkingPaymentByLM &&
                      touched.parkingPaymentByLM ? (
                        <div style={{ color: 'red' }}>
                          {errors.parkingPaymentByLM}
                        </div>
                      ) : null}
                    </Grid> */}
                    {/* <InputAutoComplete
                      placeholder="Driver"
                      disabled={!leadInput?.pickup?.by?.name}
                      value={values?.driver ?? ''}
                      disableClearable={true}
                      onChange={(value) => {
                        setFieldValue('driver', value);
                      }}
                      options={yard ?? []}
                      optionString="name"
                    /> */}

                    <Grid item md={6} xs={6}>
                      <Input
                        value={values.yardName}
                        disabled={!!leadInput?.yard?.name}
                        onChange={(e) => {
                          setFieldValue('yardName', e.target.value);
                        }}
                        name="yardName"
                        placeholder="Yard Name"
                      />
                      {errors.yardName && touched.yardName ? (
                        <div style={{ color: 'red' }}>{errors.yardName}</div>
                      ) : null}
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Input
                        value={values.yardAddress}
                        onChange={(e) => {
                          setFieldValue('yardAddress', e.target.value);
                        }}
                        name="yardAddress"
                        placeholder="Yard Address"
                      />
                      {errors.yardAddress && touched.yardAddress ? (
                        <div style={{ color: 'red' }}>{errors.yardAddress}</div>
                      ) : null}
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Input
                        value={values.yardLocationURL}
                        onChange={(e) => {
                          setFieldValue('yardLocationURL', e.target.value);
                        }}
                        name="yardLocationURL"
                        placeholder="Location URL"
                      />
                      {errors.yardLocationURL && touched.yardLocationURL ? (
                        <div style={{ color: 'red' }}>
                          {errors.yardLocationURL}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Input
                        value={values.yardSpocName}
                        onChange={(e) => {
                          setFieldValue('yardSpocName', e.target.value);
                        }}
                        name="yardSpocName"
                        placeholder="Spoc Name"
                      />
                      {errors.yardSpocName && touched.yardSpocName ? (
                        <div style={{ color: 'red' }}>
                          {errors.yardSpocName}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Input
                        value={values.yardSpocMobile}
                        onChange={(e) => {
                          setFieldValue('yardSpocMobile', e.target.value);
                        }}
                        name="yardSpocMobile"
                        placeholder="Spoc Mobile"
                      />
                      {errors.yardSpocMobile && touched.yardSpocMobile ? (
                        <div style={{ color: 'red' }}>
                          {errors.yardSpocMobile}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{ mt: 2, mb: 2 }}
                      style={{ width: '120px' }}
                      variant="contained"
                      type="submit"
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      ) : null}

      {updateLoading || updateVehicle || updatCenter || updatePickup ? (
        <SuspenseLoader />
      ) : null}
    </Box>
  );
}

export default EditBankAuctionLead;
