import { Formik, useFormik } from 'formik';

import {  Grid } from '@mui/material';
import {
  BookingCancellationReason,
  LeadRef,
  LeadStatus,
  LoanRejectionReason,
  useDriversQuery,
  // useDriversQuery,
  useGetAllCenterQuery
} from 'src/generated/hooks_and_more';
import { enumToItems } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';

import { LeadCreateAndUpdateProps } from 'src/types';
import './form.css';
import CtaButton from './CTA_Button/CtaButton';
import Input from 'src/components/Form/Input';
const BookingCancelledForm = (props: LeadCreateAndUpdateProps) => {

  const {
    toggleDrawer,
    leadId,
    regNo,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    undesireButtonText,
    canGoBack,
    currentLead
  } = props;


  const rejectionReasonList = enumToItems(BookingCancellationReason);

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };

  const formik = useFormik({
    initialValues: {
      bookingCancellation: null,
      remarks: '',
    },
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        activeBooking: {
            cancellationReason: values?.bookingCancellation?.value,
          },
       
      };
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} mt={3}>
          <InputAutoComplete
            value={formik.values.bookingCancellation}
            name="bookingCancellation"
            options={rejectionReasonList}
            optionString="label"
            onChange={(val) => formik?.setFieldValue('bookingCancellation', val)}
            placeholder="Select Booking Cancellation Reason*"
          />
          {formik.errors.bookingCancellation && formik.touched.bookingCancellation ? (
            <div style={{ color: 'red' }}>{formik.errors?.bookingCancellation}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt:3}}>
            <Input
              placeholder="Remarks *"
              onChange={(e) => {
                formik.setFieldValue('remarks', e.target.value);
              }}
              value={formik.values.remarks}
            />
          </Grid>
       
      </Grid>
      
        
      {
     Boolean(currentLead?.activeBooking?.activeLoan?.id) &&
     <Grid> <p style={{color: 'red'}}>Please Cancel the loan first</p>  </Grid>
      }
     
    

      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        backBtn={canGoBack}
        disabled={!(formik.isValid && formik.dirty) || Boolean(currentLead?.activeBooking?.activeLoan?.id)}
      />
    </form>
  );
};

export default BookingCancelledForm;
