import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useGetLeadDetailQuery } from 'src/generated/hooks_and_more';
import EditBankAuctionLead from './component/EditBankAuctionLead';

function UpdateLeads() {
  const { leadId } = useParams();

  const { data, loading, refetch } = useGetLeadDetailQuery({
    variables: {
      regNo: leadId
    },
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      console.log(response);
    }
  });

  if (loading) {
    return (
      <div
        className="d-flex flex-column height-100 justify-content-center align-items-center gap-4"
        style={{ height: '80vh' }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }

  return (
    <Fragment>
      <Box sx={{ px: 3, py: 3 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Update Leads" />
            <Divider />
            <CardContent>
              <EditBankAuctionLead
                leadInput={data?.getLead}
                loading={loading}
                // refetch={refetch}
              />
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </Fragment>
  );
}

export default UpdateLeads;
