import BulkUpload from './bulkUpload';

import { Box, SwipeableDrawer, Button, Typography } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

import { useEffect, useState } from 'react';
import BulkApproval from './PhApproval';
type refetch = {
  refetchingBulk?: (value) => void;
};
function CreateBulkLeadSidebar(
  { value, open, handleClose, setOpenBulk }: any,
  { refetchingBulk }: refetch
) {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);
  function getCurrentDimension() {
    return window.innerWidth;
  }
  function handleSwitch(value) {
    switch (value) {
      case 'Bulk':
        return <BulkUpload refetchingBulk={refetchingBulk} />;
      case 'Approval':
        return <BulkApproval refetchingBulk={refetchingBulk} />;
      default:
        break;
    }
  }

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => {}}
        onOpen={() => {
          setOpenBulk(true);
        }}
        sx={{
          width:
            screenSize >= 600
              ? screenSize / 2
              : screenSize <= 600 && screenSize >= 400
              ? screenSize / 1.5
              : screenSize,
          zIndex: 900
        }}
      >
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="secondary"
              size="medium"
              style={{
                borderRadius: '50%',
                minWidth: 0
              }}
              onClick={() => {
                setOpenBulk(false);
              }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/128/507/507257.png"
                height={20}
                width={20}
              ></img>
            </Button>
            <h5>{value === 'Bulk' ? 'Create Bulk Leads' : 'Bulk Approval'}</h5>
          </div>

          <Box
            style={{
              padding: '20px 50px 20px 50px',
              position: 'relative'
            }}
            sx={{
              width:
                screenSize >= 600
                  ? screenSize / 2
                  : screenSize <= 600 && screenSize >= 400
                  ? screenSize / 1.5
                  : screenSize
            }}
          >
            {handleSwitch(value)}
          </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default CreateBulkLeadSidebar;
