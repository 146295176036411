import AWS from 'aws-sdk';

const accessKey: string = 'AKIASSLHSG4WHXQEPGVW';
const secretKey: string = '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';
const AWSConfig = {
  accessKeyId: accessKey,
  secretAccessKey: secretKey,
  region: 'ap-south-1'
};

const s3 = new AWS.S3(AWSConfig);

export default s3;
