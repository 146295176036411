import * as Yup from 'yup';
export const TransferTypeSchema = Yup.object().shape({
  quantity: Yup.number().typeError('*Must be a Number').required('*Required'),
  stockType: Yup.object().required('*Required'),
  fromCenter: Yup.object().required('*Required'),
  toCenter: Yup.object().required('*Required')
});

const regex = /^[a-zA-Z\s0-9-]+$/;
const spaceAndString = /^[a-zA-z\s]+$/;
const email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const AddCenterSchema = Yup.object().shape({
  name: Yup.string()
    .matches(regex, 'Must be a Alpha Numeric')
    .required('*Required'),
  // state: Yup.string()
  //   .matches(spaceAndString, 'Must be a Alphabet')
  //   .required('*Required'),
  // city: Yup.string()
  //   .matches(spaceAndString, 'Must be a Alphabet')
  //   .required('*Required'),
  state: Yup.object().required('*Required'),
  city: Yup.object().required('*Required'),
  pincode: Yup.string().matches(/^[0-9]+$/, "Must be only digits").required('*Required').min(6, 'Pincode must be 6 digits').max(6, 'Pincode must be 6 digits'),
  status: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('* Required'),
  centerSpocEmail: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required()
  }),
  // centerSpocEmail : Yup.object().shape({
  //   label : Yup.string().matches(email , "Invalid email"),
  //   value : Yup.string().matches(email , "Invalid email")
  // }),
  // centerSpocName: Yup.string().matches(spaceAndString, 'Must be a Alphabet'),
  // centerSpocContact: Yup.number().typeError('*Must be a Number'),

  lat: Yup.number().typeError('*Must be a Number').required('*Required'),
  long: Yup.number().typeError('*Must be a Number').required('*Required')
});

export const AddCenterDEVSchema = Yup.object().shape({
  name: Yup.string()
    .matches(regex, 'Must be a Alpha Numeric')
    .required('*Required'),
    state: Yup.object().required('*Required'),
    city: Yup.object().required('*Required'),
    pincode: Yup.string().matches(/^[0-9]+$/, "Must be only digits").required('*Required').min(6, 'Pincode must be 6 digits').max(6, 'Pincode must be 6 digits')  ,
  status: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('* Required'),

  centerSpocEmail: Yup.object().shape({
    label: Yup.string().matches(email, 'Invalid email'),
    value: Yup.string().matches(email, 'Invalid email')
  }),

  lat: Yup.number().typeError('*Must be a Number').required('*Required'),
  long: Yup.number().typeError('*Must be a Number').required('*Required')
});

export const AddStockTypeSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      type: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required()
      }),

      unitPrice: Yup.number()
        .typeError('*Must be a Number')
        .required('*Required'),
      quantity: Yup.number()
        .typeError('*Must be a Number')
        .required('*Required'),
      tax: Yup.number().typeError('*Must be a Number').required('*Required')
    })
  )
});

export const TransferStockSchema = Yup.object().shape({
  fromCenter: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
    id: Yup.string()
  }),
  toCenter: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
    id: Yup.string()
  }),
  items: Yup.array().of(
    Yup.object().shape({
      type: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        id: Yup.string()
      }),
      quantity: Yup.number()
        .typeError('*Must be a Number')
        .required('*Required')
    })
  )
});
