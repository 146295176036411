import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';

import { Box, Button, Grid } from '@mui/material';
import {
  LeadRef,
  LeadStatus,
  useDriversQuery,
  useGetAllCenterQuery
} from 'src/generated/hooks_and_more';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';

import { LeadCreateAndUpdateProps } from 'src/types';
import '../form.css';
import useCreateLeadStatusEvents from '../../hooks/useCreateLeadStatusEvents';
import CtaButton from '../CTA_Button/CtaButton';
const SchedulePickDealership = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    onPressOfDesiredOrUndesiredButton
  } = props;

  const { addingEvent, onPressDesiredButton } =
    useCreateLeadStatusEvents(toggleDrawer);
  const {
    data: centerLists,
    loading,
    error
  } = useGetAllCenterQuery({
    fetchPolicy: 'no-cache'
  });

  const { data: drivers } = useDriversQuery();

  const formik = useFormik({
    initialValues: {
      centerName: null,
      driverName: null
    },
    validationSchema: Yup.object().shape({
      centerName: Yup.object().required('*Required'),
      driverName: Yup.object().required('*Required')
    }),

    onSubmit: (values) => {
      const leadInput: LeadRef = {
        regNo: regNo,
        id: leadId,
        centre: {
          name: values?.centerName?.value,
          id: values?.centerName?.id
        },
        deliveryCentre: {
          name: values?.centerName?.value,
          id: values?.centerName?.id
        },
        pickup: {
          by: {
            name: values?.driverName?.value,
            id: values?.driverName?.id
          }
        }
      };

      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.centerName}
            name="centerName"
            options={centerLists?.queryCentre?.map((item) => ({
              label: item?.name,
              value: item?.name,
              id: item?.id
            }))}
            optionString="label"
            onChange={(val) => formik?.setFieldValue('centerName', val)}
            placeholder="Select the Centre for Allocation*"
          />
          {formik.errors.centerName && formik.touched.centerName ? (
            <div style={{ color: 'red' }}>{formik.errors?.centerName}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.driverName}
            name="driverName"
            options={drivers?.queryUser?.map((item) => ({
              label: item?.name,
              value: item?.name,
              id: item?.id
            }))}
            optionString="label"
            onChange={(val) => formik?.setFieldValue('driverName', val)}
            placeholder="Select Driver For Pickup*"
          />
          {formik.errors.driverName && formik.touched.driverName ? (
            <div style={{ color: 'red' }}>{formik.errors?.driverName}</div>
          ) : null}
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} mt={1} mb={1} sx={{ width: '100%' }}>
        <CtaButton
          desireButtonText={desireButtonText}
          // undesireButtonText={undesireButtonText}

          disabled={!(formik.isValid && formik.dirty)}
          backBtn={true}
        />
      </Grid>
    </form>
  );
};

export default SchedulePickDealership;
