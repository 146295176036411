import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import Input from 'src/components/Form/Input';
import {
  LeadRef,
  LeadStatus,
  LoanToBeClosedBy,
  PaymentFor,
  PaymentStatus,
  PaymentType,
  useGetDocumentDetailsFormQuery
} from 'src/generated/hooks_and_more';
import { enumToItems } from 'src/utils/utility';
import { LeadCreateAndUpdateProps } from 'src/types';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import CtaButton from '../CTA_Button/CtaButton';

const PurchaseRequestForm = (props: LeadCreateAndUpdateProps) => {
  const {
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton
  } = props;

  const loanClosedByList = enumToItems(LoanToBeClosedBy);
  const paymentType = enumToItems(PaymentType);

  const { data, loading } = useGetDocumentDetailsFormQuery({
    variables: { id: leadId }
  });


  const formik = useFormik({
    initialValues: {
      paymentTypeValue:
        (data?.getLead?.holdbackAmount > 0
          ? { label: 'Pay In Parts', value: PaymentType.PayInParts }
          : { label: 'Pay In Full', value: PaymentType.PayInFull }) ?? null,
      tokenAmount: 0,
      loanClosedBy: null,
      remark: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      tokenAmount: Yup.number().typeError('*Must be a Number'),

    }),
    onSubmit: (values) => {
      
      let leadInput: LeadRef;

      if (
        values?.paymentTypeValue?.value === PaymentType.PayInFull &&
        !!loanAmount &&
        formik?.values?.loanClosedBy?.value === LoanToBeClosedBy.TractorJunction
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null,
              pendingLoanAmount: loanAmount

            }
          },
          paymentType: formik.values?.paymentTypeValue?.value as PaymentType,
          payments: [
            {
              for: PaymentFor.LoanRepayment,
              status: PaymentStatus.Estimated,
              amount: loanAmount,
              type: values?.paymentTypeValue?.value,
              loanClosedBy: values?.loanClosedBy?.value as LoanToBeClosedBy
            },
            {
              for: PaymentFor.DealPayment,
              status: PaymentStatus.Estimated,
              amount: dealAmount,
              type: values?.paymentTypeValue?.value,
              loanClosedBy: values?.loanClosedBy?.value as LoanToBeClosedBy
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInFull &&
        !!loanAmount &&
        formik?.values?.loanClosedBy?.value !== LoanToBeClosedBy.TractorJunction
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
            }
          },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealPayment,
              status: PaymentStatus.Estimated,
              amount:  dealAmount,
              type: values?.paymentTypeValue?.value,
              loanClosedBy: values?.loanClosedBy?.value as LoanToBeClosedBy
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInFull &&
        !loanAmount
      ) {
        {
          leadInput = {
            regNo: regNo,
            id: leadId,
            vehicle: {
              financingDetails: {
                loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
              }
            },
            paymentType: values?.paymentTypeValue?.value,
            payments: [
              {
                for: PaymentFor.DealPayment,
                status: PaymentStatus.Estimated,
                amount: dealAmount,
                type: values?.paymentTypeValue?.value
              }
            ]
          };
        }
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !!loanAmount &&
        !!holdBackAmount &&
        formik?.values?.loanClosedBy?.value === LoanToBeClosedBy.TractorJunction &&
        !!tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
            }
          },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealToken,
              status: PaymentStatus.Estimated,
              amount: values.tokenAmount == 0 ? null : Number(tokenAmount),
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.HoldbackRepayment,
              status: PaymentStatus.Estimated,
              amount: holdBackAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.LoanRepayment,
              status: PaymentStatus.Estimated,
              amount: loanAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !!loanAmount &&
        !!holdBackAmount &&
        values?.loanClosedBy?.value === LoanToBeClosedBy.TractorJunction &&
        !tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,

          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
            }
          },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.HoldbackRepayment,
              status: PaymentStatus.Estimated,
              amount: holdBackAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.LoanRepayment,
              status: PaymentStatus.Estimated,
              amount: loanAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !!loanAmount &&
        !holdBackAmount &&
        values?.loanClosedBy?.value === LoanToBeClosedBy.TractorJunction &&
        !!tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
            }
          },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealToken,
              status: PaymentStatus.Estimated,
              amount: values.tokenAmount == 0 ? null : Number(tokenAmount),
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.LoanRepayment,
              status: PaymentStatus.Estimated,
              amount: loanAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !!loanAmount &&
        !holdBackAmount &&
        values?.loanClosedBy?.value === LoanToBeClosedBy.TractorJunction &&
        !tokenAmount
      ) {
        // Case 4: Loan paid by Tractor Junction, no holdback amount, no token amount
        leadInput = {
          regNo: regNo,
          id: leadId,
          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
            }
          },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.LoanRepayment,
              status: PaymentStatus.Estimated,
              amount: loanAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !!loanAmount &&
        !!holdBackAmount &&
        values?.loanClosedBy?.value !== LoanToBeClosedBy.TractorJunction &&
        !!tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
            }
          },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealToken,
              status: PaymentStatus.Estimated,
              amount: values.tokenAmount == 0 ? null : Number(tokenAmount),

              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.HoldbackRepayment,
              status: PaymentStatus.Estimated,
              amount: holdBackAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !!loanAmount &&
        !!holdBackAmount &&
        values?.loanClosedBy?.value !== LoanToBeClosedBy.TractorJunction &&
        !tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
            }
          },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.HoldbackRepayment,
              status: PaymentStatus.Estimated,
              amount: holdBackAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !!loanAmount &&
        !holdBackAmount &&
        values?.loanClosedBy?.value !== LoanToBeClosedBy.TractorJunction &&
        !!tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
            }
          },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealToken,
              status: PaymentStatus.Estimated,
              amount: values.tokenAmount == 0 ? null : Number(tokenAmount),

              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !!loanAmount &&
        !holdBackAmount &&
        values?.loanClosedBy?.value !== LoanToBeClosedBy.TractorJunction &&
        !tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          vehicle: {
            financingDetails: {
              loanToBeClosedBy: Boolean(values?.loanClosedBy?.value) ? values?.loanClosedBy?.value as LoanToBeClosedBy  :  null
            }
          },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !loanAmount &&
        !!holdBackAmount &&
        !!tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          // vehicle: {
          // ...leadInput?.vehicle,
          // financingDetails: {
          //   ...leadInput?.vehicle?.financingDetails,
          // },
          // },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealToken,
              status: PaymentStatus.Estimated,
              amount: values.tokenAmount == 0 ? null : Number(tokenAmount),

              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.HoldbackRepayment,
              status: PaymentStatus.Estimated,
              amount: holdBackAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !loanAmount &&
        !!holdBackAmount &&
        !tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          // vehicle: {
          //   ...leadInput?.vehicle,
          //   financingDetails: {
          //     ...leadInput?.vehicle?.financingDetails,
          //   },
          // },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.HoldbackRepayment,
              status: PaymentStatus.Estimated,
              amount: holdBackAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !loanAmount &&
        !holdBackAmount &&
        !!tokenAmount
      ) {

        leadInput = {
          regNo: regNo,
          id: leadId,
          
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealToken,
              status: PaymentStatus.Estimated,
              amount: values.tokenAmount == 0 ? null : Number(tokenAmount),
              type: values?.paymentTypeValue?.value
            },
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      } else if (
        values?.paymentTypeValue?.value === PaymentType.PayInParts &&
        !loanAmount &&
        !holdBackAmount &&
        !tokenAmount
      ) {
        leadInput = {
          regNo: regNo,
          id: leadId,
          // ...leadInput,
          // vehicle: {
          //   ...leadInput?.vehicle,
          //   financingDetails: {
          //     ...leadInput?.vehicle?.financingDetails,
          //   },
          // },
          paymentType: values?.paymentTypeValue?.value,
          payments: [
            {
              for: PaymentFor.DealDelivery,
              status: PaymentStatus.Estimated,
              amount: deliveryAmount,
              type: values?.paymentTypeValue?.value
            }
          ]
        };
      }
    
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remark);
    }
  });



  const [payToDealer, setPayToDealer] = useState(0);
  const [payToBank, setPayToBank] = useState(0);
  const [deliveryAmount, setDelivery] = useState(0);

  const purchaseDetailsAvailable = data?.getLead;
  const dealAmount = purchaseDetailsAvailable?.approvedDealAmount;
  const loanAmount =
    purchaseDetailsAvailable?.vehicle?.financingDetails?.pendingLoanAmount;
  const documentCharges = purchaseDetailsAvailable?.documentCharges;
  const holdBackAmount = !!purchaseDetailsAvailable?.holdbackAmount ? purchaseDetailsAvailable?.holdbackAmount : 0;
  const tokenAmount = formik.values.tokenAmount
  useEffect(() => {
    if (formik.values.loanClosedBy && formik?.values?.loanClosedBy?.value !== null) {
      const payToDealervalue =
        formik?.values?.loanClosedBy?.value === LoanToBeClosedBy.TractorJunction
          ? dealAmount - loanAmount - documentCharges
          : dealAmount - documentCharges;
      const payToBankvalue =
        formik.values?.loanClosedBy.value === LoanToBeClosedBy.TractorJunction
          ? loanAmount
          : 0;
      const deliveryAmountvalue = Boolean(tokenAmount)
        ? dealAmount -
          (formik?.values?.loanClosedBy?.value === LoanToBeClosedBy.TractorJunction
            ? loanAmount
            : 0) -
          holdBackAmount -
          documentCharges -
          formik.values.tokenAmount
        : dealAmount -
          (formik?.values?.loanClosedBy?.value === LoanToBeClosedBy.TractorJunction
            ? loanAmount
            : 0) -
          holdBackAmount -
          documentCharges;
      setPayToDealer(payToDealervalue);
      setPayToBank(payToBankvalue);
      setDelivery(deliveryAmountvalue);
    } else {
      const payToDealervalue = dealAmount - documentCharges;
      const payToBankvalue = 0;
      const deliveryAmountvalue = Boolean(tokenAmount)
        ? dealAmount -
          holdBackAmount -
          documentCharges -
          formik.values.tokenAmount
        : dealAmount - holdBackAmount - documentCharges;
      setPayToDealer(payToDealervalue);
      setPayToBank(payToBankvalue);
      setDelivery(deliveryAmountvalue);
    }
  }, [
    tokenAmount,
    holdBackAmount,
    loanAmount,
    documentCharges,
    dealAmount,
    payToDealer,
    payToBank, formik.initialValues.loanClosedBy, formik.values.loanClosedBy, formik.initialValues?.paymentTypeValue.value
  ]);

  useEffect(() => {
    if (formik.values.paymentTypeValue?.value === PaymentType.PayInFull) {
      formik.setFieldValue('tokenAmount', 0);
    }
  }, [formik.values.paymentTypeValue?.value]);


  const paymentAmount = !!documentCharges
    ? data?.getLead?.approvedDealAmount - documentCharges
    : data?.getLead?.approvedDealAmount;


  let dealStructure: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Deal Amount',
      value: data?.getLead?.approvedDealAmount
    },
    {
      key: 'Loan Amount',
      value: Boolean(loanAmount) ? loanAmount : 0
    }
  ];
  let paymentStructure: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Document Charges',
      value: data?.getLead?.documentCharges
    },

    {
      key: 'Token Amount',
      value: tokenAmount
    },
    {
      key: 'Delivery Amount',
      value: deliveryAmount
    },
    {
      key: 'Holdback Amount',
      value: Boolean(data?.getLead?.holdbackAmount)
        ? data?.getLead?.holdbackAmount
        : 0
    },
    {
      key: 'Payment Amount',
      value:
        formik.values?.paymentTypeValue?.value === PaymentType.PayInFull
          ? paymentAmount
          : 0
    }
  ];

  let paymentBreakUp: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Pay To Dealer',
      value: payToDealer ?? 0
    },
    {
      key: 'Pay To Bank',
      value: payToBank ?? 0
    }
  ];
  const isLoanClosed = data?.getLead?.vehicle?.financingDetails?.isLoanClosed;
  const isFinance = data?.getLead?.vehicle?.isVehicleFinanced;
  dealStructure = dealStructure?.filter((i) => i?.value !== 0);
  paymentBreakUp = paymentBreakUp?.filter((i) => i?.value !== 0);
  paymentStructure = paymentStructure?.filter((i) => i?.value !== 0);



  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {!isLoanClosed && isFinance && (
          <Grid item xs={12} md={12} mt={2}>
            <InputAutoComplete
              value={formik.values.loanClosedBy}
              name="loanClosedBy"
              options={loanClosedByList}
              onChange={(value) => formik?.setFieldValue('loanClosedBy', value)}
              placeholder="Loan to be Closed by*"
              optionString="label"
              getOptionLabel={(option) => option.label}
            />
            {formik.errors.loanClosedBy && formik.touched.loanClosedBy ? (
              <div style={{ color: 'red' }}>{formik.errors?.loanClosedBy}</div>
            ) : null}
          </Grid>
        )}

        <Grid item xs={12} md={12} mt={2}>
          <InputAutoComplete
            value={formik.values.paymentTypeValue}
            name="paymentTypeValue"
            options={paymentType}
            disabled={Boolean(holdBackAmount) ? true : false}
            onChange={(value) => {
              formik?.setFieldValue('paymentTypeValue', value);
            }}
            placeholder="Payment Type*"
            optionString="label"
            getOptionLabel={(option) => option.label}
          />
          {formik.errors.paymentTypeValue && formik.touched.paymentTypeValue ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.paymentTypeValue}
            </div>
          ) : null}
        </Grid> 
        {formik.values.paymentTypeValue?.value === PaymentType.PayInParts && (
          <Grid item xs={12} md={12}>
            <Input
        value={ Boolean(formik.values.tokenAmount) ? formik.values.tokenAmount : null }
              name="tokenAmount"
              onChange={(e) => {
                formik?.setFieldValue(
                  'tokenAmount',
                  formik.values?.paymentTypeValue?.value === PaymentType.PayInFull
                    ? 0
                    : e.target.value
                );
              }}
              placeholder="Token Amount in INR*"
            />
            {formik.values.tokenAmount || formik.errors.tokenAmount && formik.touched.tokenAmount ? (
              <div style={{ color: 'red' }}>{formik.errors?.tokenAmount}</div>
            ) : null}
          </Grid>
        )}


        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <h4>Purchase Order </h4>
        </Grid>

        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <TableContainer sx={{ backgroundColor: '#f6f6f6' }} component={Paper}>
            <Table
              sx={{
                padding: 0,
                boxShadow:
                  ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              }}
            >
              <TableBody sx={{ borderRadius: '1rem ' }}>
                <TableRow>
                  <TableCell colSpan={2}>
                    {' '}
                    <h5 style={{ marginTop: '0.5rem' }}>Deal Structure</h5>
                  </TableCell>
                </TableRow>
                {dealStructure.map((i, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      fontSize: '16px'
                    }}
                  >
                    <TableCell width="50%">{i?.key}</TableCell>
                    <TableCell width="50%">{i?.value}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2}>
                    {' '}
                    <h5 style={{ marginTop: '0.5rem' }}>Payment Structure</h5>
                  </TableCell>
                </TableRow>

                {paymentStructure.map((i, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      fontSize: '16px'
                    }}
                  >
                    <TableCell width="50%">{i?.key}</TableCell>
                    <TableCell width="50%">{i?.value}</TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell colSpan={2}>
                    {' '}
                    <h5 style={{ marginTop: '0.5rem' }}> Payment Breakup</h5>
                  </TableCell>
                </TableRow>

                {paymentBreakUp.map((i, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      fontSize: '16px'
                    }}
                  >
                    <TableCell width="50%">{i?.key}</TableCell>
                    <TableCell width="50%">{i?.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} mt={2}>
        <Input
          value={formik.values.remark}
          name="remark"
          onChange={(e) => formik?.setFieldValue('remark', e.target.value)}
          placeholder="Enter Your Remark*"
        />
        {formik.errors.remark && formik.touched.remark ? (
          <div style={{ color: 'red' }}>{formik.errors?.remark}</div>
        ) : null}
      </Grid>
      <Grid item xs={12} md={12} mt={2}>

      <CtaButton
        desireButtonText={desireButtonText}
        disabled={false}
      />
      </Grid>

    </form>
  );
};

export default PurchaseRequestForm;
