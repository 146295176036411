import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import Input from 'src/components/Form/Input';
import { AddCenterDEVSchema, AddCenterSchema } from './ValidationSchema';
import {
  AddInventoryCentreMutationVariables,
  CenterStatus,
  useAddInventoryCentreMutation,
  useGetCenterUserQuery,
  useCheckDuplicateCenterLazyQuery,
  useGetDistrictsByStateQuery,
  useGetAllActiveStateQuery,
  useAddLogsMutation,
  ActionType,
  ModuleType,
  TaskStatus
} from 'src/generated/hooks_and_more';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { useEffect, useState } from 'react';
import ConfimationModal from 'src/components/Composite/ConfirmationModal/ConfimationModal';
import { environments } from 'src/config';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router';
import { cachedLeadFilter, titleCaseToReadable } from 'src/utils/utility';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';

function AddCenter() {
  const [open, setOpen] = useState(false);
  const [on, SetOn] = useState(false);
  const { addToast } = useToasts();
  const navigation = useNavigate();
  const { data: stateList } = useGetAllActiveStateQuery({
    fetchPolicy: 'no-cache'
  });
  const { data, loading, error } = useGetCenterUserQuery();
  const [stateValue, setStateValue] = useState<[] | any>(null);

  const { data: districtOptions } = useGetDistrictsByStateQuery({
    variables: {
      id: stateValue?.id
    }
  });
  const [centerMnagerDetails, setCenterMnagerDetails] = useState([]);

  let centerManager = [];
  data?.queryUser?.map((item: any) => {
    return (centerManager = [
      ...centerManager,
      { label: item.email, value: item.email }
    ]);
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    cachedLeadFilter(null);
  }, []);
  const initialValues = {
    name: '',
    state: null,
    city: null,
    pincode: '',
    inventoryCount: '',
    stockCount: '',
    status: { label: 'Active' },
    centerSpocEmail: null,
    centerSpocName: null,
    centerSpocContact: null,
    lat: '',
    long: ''
  };
  const [addInvetoryCentre] = useAddInventoryCentreMutation();
  const [CheckDuplicateCenter, { loading: checking }] =
    useCheckDuplicateCenterLazyQuery();
    const [AddLogs] = useAddLogsMutation();
    const { loggedInUser } = useLoggedInUser();

  const [variables, setVariables] =
    useState<AddInventoryCentreMutationVariables>(null);
  const title = 'Add Center';
  const btnTitle = 'Add Center';

 
  const onConfirmationHandler = (reset: () => void) => {
    addInvetoryCentre({
      variables,
      onCompleted() {
        AddLogs({
          variables: {
            taskPerformedBy: { id: loggedInUser?.userId},
            actionType: ActionType.Create,
            module: ModuleType.User,
            createdAt: new Date(),
            sourceUpdated: variables?.state,
            updatedValue: JSON.stringify({ data: variables }),
            previousValue: "",
            status: TaskStatus.Success
          }
        });
        handleClose();
        reset();
        addToast('Centre Added Successfully!!!', {
          appearance: 'success',
          autoDismiss: true
        });

        navigation('/inventory/center');
      },
      onError(){
        AddLogs({
          variables: {
            taskPerformedBy: { id: loggedInUser?.userId},
            actionType: ActionType.Create,
            module: ModuleType.Centre,
            createdAt: new Date(),
            sourceUpdated: "",
            updatedValue: "",
            previousValue: "",
            status: TaskStatus.Failed
          }
        });
      }
    });
  };

  const userHandle = (value) => {
    const filteredDetail = data?.queryUser.filter(
      (item) => item.email === value?.label
    );
    setCenterMnagerDetails(filteredDetail);
  };
  return (
    <Box sx={{ mt: 3 }} display={'flex'} justifyContent={'center'}>
      <Box width={'80%'}>
        <Card>
          <Typography sx={{ my: 1, mx: 2 }} variant="h3">
            {title}
          </Typography>
          <Divider />
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values) => {

                const variable = {
                  name: values.name,
                  lat: Number(values.lat),
                  long: Number(values.long),
                  city: values.city?.district_name,
                  pincode: Number(values.pincode),
                  state: values.state?.label,
                  status: values?.status?.label.toUpperCase() as CenterStatus,
                  updatedAt: new Date(),
                  createdAt: new Date(),
                  email: values?.centerSpocEmail?.value
                };

                setVariables(variable);
                CheckDuplicateCenter({
                  variables: { name: `/.*${values?.name}.*/i` }
                }).then((data) => {
                  if (data.data?.queryCentre?.length) {
                    addToast(`Center with name already exist.`, {
                      appearance: 'error',
                      autoDismiss: true
                    });
                  } else {
                    handleClickOpen();
                  }
                });
              }}
              validationSchema={
                environments === 'DEVELOPMENT' || 'STAGING'
                  ? AddCenterSchema
                  : AddCenterDEVSchema
              }
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
                resetForm
              }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Input
                          value={values.name}
                          name="name"
                          onChange={handleChange}
                          placeholder="Center Name"
                        />
                        {errors.name && touched.name ? (
                          <div style={{ color: 'red' }}>{errors?.name}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputAutoComplete
                          name="name"
                          value={values.state}
                          onChange={(value) => {
                            setFieldValue('state', value);
                            setStateValue(value);
                          }}
                          placeholder="State"
                          options={
                            stateList?.getStateInfo?.data.map((i) => ({
                              label: titleCaseToReadable(i?.state_name),
                              name: i?.state_name,
                              id: i?.state_id
                            })) ?? []
                          }
                        />
                        {errors.state && touched.state ? (
                          <div style={{ color: 'red' }}>{errors?.state}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputAutoComplete
                          name="city"
                          placeholder="City"
                          onChange={(value) => {
                            setFieldValue('city', value);
                          }}
                          value={values?.city}
                          options={
                            districtOptions?.getDistrictByStateInfo?.data ?? []
                          }
                          optionString="district_name"
                          disabled={!stateValue?.id}
                        />
                        {errors.city && touched.city ? (
                          <div style={{ color: 'red' }}>{errors?.city}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          name="pincode"
                          placeholder="Pincode"
                          value={values.pincode}
                          onChange={handleChange}
                        />
                        {values?.pincode && errors.pincode && touched?.pincode ? (
                          <div style={{ color: 'red' }}>{errors?.pincode}</div>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputAutoComplete
                          options={[
                            { label: 'Active', value: 'ACTIVE' },
                            { label: 'Closed', value: 'CLOSED' }
                          ]}
                          placeholder="Status"
                          value={values.status}
                          onChange={(value) => {
                            setFieldValue('status', value);
                          }}
                        />
                        {errors.status && touched.status ? (
                          <div style={{ color: 'red' }}>{errors?.status}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          name="lat"
                          placeholder="Latitude"
                          value={values.lat}
                          onChange={handleChange}
                        />
                        {errors.lat && touched.lat ? (
                          <div style={{ color: 'red' }}>{errors?.lat}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          placeholder="Longitude"
                          name="long"
                          value={values.long}
                          onChange={handleChange}
                        />
                        {errors.long && touched.long ? (
                          <div style={{ color: 'red' }}>{errors?.long}</div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputAutoComplete
                          options={centerManager}
                          placeholder="Spoc email"
                          value={values.centerSpocEmail}
                          onChange={(value) => {
                            setFieldValue('centerSpocEmail', value);
                            userHandle(value);
                          }}
                        />
                        {errors.centerSpocEmail && touched.centerSpocEmail ? (
                          <div style={{ color: 'red' }}>
                            {errors?.centerSpocEmail}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          name="centerSpocName"
                          disabled={true}
                          placeholder="Center SPOC Name"
                          value={centerMnagerDetails[0]?.name ?? 'Spoc Name'}
                          onChange={handleChange}
                        />
                        {errors.centerSpocName && touched.centerSpocName ? (
                          <div style={{ color: 'red' }}>
                            {errors?.centerSpocName}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          name="centerSpocContact"
                          disabled={true}
                          placeholder="spoc phone no"
                          value={centerMnagerDetails[0]?.phoneNo ?? 'spoc no'}
                          onChange={handleChange}
                        />
                        {errors.centerSpocContact &&
                        touched.centerSpocContact ? (
                          <div style={{ color: 'red' }}>
                            {errors?.centerSpocContact}
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        // onClick={handleClickOpen}
                      >
                        {btnTitle}
                      </Button>
                      {/* <ConfimationModal open={open} handleClose={handleClose}/> */}
                    </div>
                    <ConfimationModal
                      textToShow="Do you want to add a new center?"
                      open={open}
                      handleClose={handleClose}
                      toastMsg={on}
                      onConfirmationHandler={onConfirmationHandler}
                      reset={resetForm}
                    />
                  </Form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Box>

      {/* {open ? <ToastMessage open="true" message="Center Added Successfully" /> : <></>} */}
    </Box>
  );
}

export default AddCenter;
