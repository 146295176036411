import React from 'react';
import { LeadDetailsPropsType } from './ApplicationDetails';
import { titleCaseToReadable } from 'src/utils/utility';
import { format } from 'date-fns';
import { LeadStatus } from 'src/generated/hooks_and_more';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';

function RTOVerification({ currentLead }: LeadDetailsPropsType) {
  const approveMailUrl = currentLead?.vehicle?.documents?.approvalMailUrl;
  const challanUrl = currentLead?.vehicle?.documents?.challanUrl;
  // const currentStatus=status?.status
  const hsrpUrl = currentLead?.vehicle?.documents?.hsrbProofUrl;
  const hypothecationUrl =
    currentLead?.vehicle?.documents?.hypothecationProofUrl;
  const rtoVerificationUrl =
    currentLead?.vehicle?.documents?.rtoVerificationProofUrl;

  const statusEvents = JSON.parse(JSON.stringify(currentLead?.statusEvents));
  const dueChallanAmount = currentLead?.vehicle?.challanAmount;
  const financerName = currentLead?.vehicle?.financingDetails?.tempFinancerName;
  const blackListedConfirmation =
    currentLead?.vehicle?.documents?.blacklistProofUrl;

  const make = currentLead?.vehicle?.vehicleBrand?.name;
  const model = currentLead?.vehicle?.vehicleModel?.name;
  const chassis = currentLead?.vehicle?.chassisNumber;
  const mfg = currentLead?.vehicle?.manufacturingDate
    ? new Date(currentLead?.vehicle?.manufacturingDate).getFullYear()
    : '';
  const engine = currentLead?.vehicle?.engineNumber;
  const tempChassis = currentLead?.vehicle?.tempChassisNumber;
  const tempEngine = currentLead?.vehicle?.tempEngineNumber;
  const tempMake = currentLead?.vehicle?.tempVehicleBrand?.name;
  const tempMfg = currentLead?.vehicle?.tempManufacturingDate
    ? new Date(currentLead?.vehicle?.tempManufacturingDate).getFullYear()
    : '';
  const tempModel = currentLead?.vehicle?.tempVehicleModel?.name;
  const hypoStatus = currentLead?.vehicle?.isHypo;
  const hsrpStatus = currentLead?.vehicle?.isHSRPAvailable;
  const challanStatus = currentLead?.vehicle?.isChallanAvailable;
  const blackListedStatus = currentLead?.vehicle?.isBlacklisted;
  const isMake = make === tempMake;
  const isModel = model === tempModel;

  const isMfg = mfg === tempMfg;
  const isChassis = chassis === tempChassis;
  const isEngine = engine === tempEngine;

  const noRTODataYet =
    !tempEngine && !tempChassis && !tempMake && !tempModel && !tempMfg;
  const rtoStatus = isMake && isModel && isMfg && isChassis && isEngine;
  const cardMake =
    (!!make ? titleCaseToReadable(make) : '-') +
    '/' +
    (!!tempMake ? titleCaseToReadable(tempMake) : '-');
  const cardModel =
    (!!model ? titleCaseToReadable(model) : '-') +
    '/' +
    (!!tempModel ? titleCaseToReadable(tempModel) : '-');
  const cardMfg =
    !!mfg || !!tempMfg ? mfg + '/' + (!!tempMfg ? tempMfg : '-') : '-';
  const cardEngine = engine ? engine + '/' + (tempEngine ?? '-') : '-';
  const cardChassis = chassis ? chassis + '/' + (tempChassis ?? '-') : '-';

  let latestRTOVerificationStatues = statusEvents?.sort(
    (a, b) =>
      new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
  );

  console.log({ chassis }, 'ddddddd');

  // // Finding out if the current lead reached the RTO verification stage.
  const rtoVerificationDetails = latestRTOVerificationStatues?.find(
    (currentStatus) =>
      currentStatus?.status === LeadStatus.RtoVerificationCompleted ||
      currentStatus?.status === LeadStatus.RtoVerificationRejected
  );

  const rtoVerificationDate =
    !!rtoVerificationDetails && rtoVerificationDetails?.createdAt;

  const rtoDetails: {
    key: string;
    value: string;
    isDoc?: boolean;
    textColor?: string;
    isHidden?: boolean;
    type?: string;
  }[] = [
    {
      key: 'Confirmation Date',
      value: !!rtoVerificationDate
        ? format(new Date(rtoVerificationDate), 'dd-MMM-yyyy')
        : '-',
      isHidden: false
    },
    {
      key: 'RTO Confirmation',
      value: rtoVerificationUrl ?? '-',
      isDoc: true,
      isHidden: false,
      type: 'image'
    },
    {
      key: 'Status',
      value: noRTODataYet ? '-' : rtoStatus ? 'Matched' : 'Not Matched',
      isHidden: false
    },
    {
      key: 'Make',
      value: cardMake,
      textColor: isMake ? 'green' : 'red',
      isHidden: isMake
    },
    {
      key: 'Model',
      value: cardModel,
      textColor: isModel ? 'green' : 'red',
      isHidden: isModel
    },
    {
      key: 'MFG Year',
      value: cardMfg,
      textColor: isMfg ? 'green' : 'red',
      isHidden: isMfg
    },
    {
      key: 'Chassis Number',
      value: cardChassis,
      textColor: isChassis ? 'green' : 'red',
      isHidden: isChassis
    },
    {
      key: 'Engine Number',
      value: cardEngine,
      textColor: isEngine ? 'green' : 'red',
      isHidden: isEngine
    },
    {
      key: 'Hypo Confirmation ',
      value: hypothecationUrl ?? '-',
      isDoc: true,
      isHidden: false,
      type: 'image'
    },
    {
      key: 'Status',
      value: !!hypoStatus
        ? 'Available'
        : hypoStatus === false
        ? 'Not Available'
        : '-',
      isHidden: false
    },
    {
      key: 'Financer Name',
      value: !!financerName ? titleCaseToReadable(financerName) : '-',
      isHidden: !hypoStatus
    },
    {
      key: 'Challan Confirmation ',
      value: challanUrl ?? '-',
      isDoc: true,
      isHidden: false,
      type: 'image'
    },
    {
      key: 'Status',
      value: !!challanStatus
        ? 'challan found'
        : challanStatus === false
        ? 'challan not found'
        : '-',
      isHidden: false
    },

    {
      key: 'Challan amount',
      value: dueChallanAmount ? dueChallanAmount?.toString() : '-',
      isHidden: !challanStatus
    },
    {
      key: 'Blacklisted Confirmation ',
      value: blackListedConfirmation ?? '-',
      isDoc: true,
      isHidden: false,
      type: 'image'
    },
    {
      key: 'Status',
      value: !!blackListedStatus
        ? 'BlackListed'
        : blackListedStatus === false
        ? 'Not BlackListed'
        : '-',
      isHidden: false
    },
    {
      key: 'Hsrp Confirmation',
      value: hsrpUrl ?? '-',
      isDoc: true,
      isHidden: false,
      type: 'image'
    },
    {
      key: 'Status',
      value: !!hsrpStatus
        ? 'Available'
        : hsrpStatus === false
        ? 'Not Available'
        : '-',
      isHidden: false
    },
    {
      key: 'Approval mail (If Any)',
      value: approveMailUrl ?? '-',
      isDoc: true,
      isHidden: false,
      type: 'image'
    }
  ];
  return <DetailsTabPanel data={rtoDetails} />;
}

export default RTOVerification;
