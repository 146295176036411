import { useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { googleLogout } from '@react-oauth/google';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { styled } from '@mui/material/styles';
import { enumToItems, titleCaseToReadable } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import {
  useCreateFakeUserWithRoleMutation,
  UserRole
} from 'src/generated/hooks_and_more';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
import { Environments, environments } from 'src/config';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        width:100%;
`
);

function HeaderUserbox() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [userRole, setUserRole] = useState(null);
  const navigation = useNavigate();
  const [centreDetails, setCentreDetails] = useState([]);
  const { setUserToken, loggedInUser } = useLoggedInUser();
  const centre = JSON.parse(localStorage.getItem('centreData'));
  const [createFakeUserWithRole, { data, loading }] =
    useCreateFakeUserWithRoleMutation({
      onCompleted: ({ addUser }) => {
        console.log('addUser?.user?.[0]', addUser?.user?.[0]);
        // change the user token and loggedin user with new value
        if (addUser?.user?.[0]?.id) {
          console.log(addUser?.user, 'gggggggggggggg');
          const userData = {
            accessToken: loggedInUser?.accessToken ?? '',
            email: addUser?.user?.[0]?.email,
            picture: loggedInUser?.picture,
            name: addUser?.user?.[0]?.name,
            userId: addUser?.user?.[0].id,
            role: addUser?.user?.[0]?.role,
            centre: addUser?.user?.[0]?.centre
              ? {
                  id: addUser?.user?.[0]?.centre?.id,
                  name: addUser?.user?.[0]?.centre?.name
                }
              : null
          };
          setUserToken('userData', userData);
          window.location.reload();
          // setUserToken(addUser?.user?.[0]?.id);
          // setLoggedInUser(addUser?.user?.[0]);
        }
      }
    });

  function onPressModalItem(value: string) {
    // log('user role picker selected', {value, index})
    const userRole = value as UserRole;
    const fakeName =
      loggedInUser?.name?.split(' ')?.[0] + ' ' + titleCaseToReadable(userRole);

    // upsert a fake user with role and then update the userToken and loggedInUser
    createFakeUserWithRole({
      variables: {
        role: userRole,
        fakeEmail: fakeName,
        fakeName,
        phoneNo: Math.floor(Math.random() * 10000000000)?.toString(),
        createdAt: new Date(),
        centre: loggedInUser?.centre
        // pnsToken: loggedInUser?.expoPushNotificationAddress
      }
    });
    // closePicker();
  }

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const userDetails = JSON.parse(localStorage.getItem('userData'));

  return (
    <>
      <UserBoxButton
        color="secondary"
        ref={ref}
        onClick={handleOpen}
        style={{ display: 'flex', gap: '10px' }}
      >
        <Avatar
          variant="rounded"
          alt={userDetails?.name}
          src={userDetails?.picture}
        />
        <p>{userDetails?.name}</p>

        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>

      <Popover
        anchorEl={ref.current}
        // sx={{ zIndex: 2000 }}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{ minWidth: 210 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <Avatar
            variant="rounded"
            alt={userDetails?.name}
            src={userDetails?.picture}
          />
          <UserBoxText>
            <UserBoxLabel align="center" variant="body1">
              {userDetails?.name}
            </UserBoxLabel>
            <UserBoxLabel align="center" variant="body1">
              {userDetails?.role
                ? `( ${titleCaseToReadable(userDetails?.role ?? '')} )`
                : ''}
            </UserBoxLabel>
          </UserBoxText>
          {environments === Environments.STAGING ? (
            <UserBoxLabel>
              <InputAutoComplete
                style={{ width: '100%' }}
                placeholder="Select role"
                options={enumToItems(UserRole)}
                value={userRole}
                onChange={(newValue) => {
                  setUserRole(newValue);
                  onPressModalItem(newValue.value);
                }}
                getOptionLabel={(item) => item.label}
                optionString="label"
              />
            </UserBoxLabel>
          ) : null}
        </MenuUserBox>

        <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              localStorage.clear();
              googleLogout();
              navigation('/vms-admin');
            }}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
