import React from 'react';
import {
  LeadRef,
  LeadStatus,
  PaymentFor,
  PaymentMethod,
  PaymentStatus,
  useFinalCostQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { Box, Button, Grid } from '@mui/material';
import Input from 'src/components/Form/Input';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import { useFormik } from 'formik';
import { ParkingPaymentSchema } from '../ValidationSchema/ValidationSchema';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { enumToItems } from 'src/utils/utility';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

function ParkingPayment(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    onPressOfDesiredOrUndesiredButton
  } = props;

  const { data, loading } = useFinalCostQuery({
    variables: { id: leadId },
    onCompleted(data) {
      formik.setFieldValue('parkingAmount', data?.getLead?.finalParkingCharges);
    }
  });
  const paymentModeOptions = enumToItems(PaymentMethod);
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      paymentMode: null,
      parkingAmount: '',
      amount: '',
      paymentDate: '',
      paymentReceipt: '',
      remarks: ''
    },
    validationSchema: ParkingPaymentSchema,
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        payments: [
          {
            mode: values?.paymentMode?.value as PaymentMethod,
            paymentProcessedAt: values?.paymentDate,
            status: PaymentStatus.Done,
            for: PaymentFor.ParkingExpense,
            amount: Number(values?.amount),
            receiptUrl: values?.paymentReceipt
          }
        ]
      };

      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  const amount = data?.getLead?.finalParkingCharges;
  return loading ? (
    <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>
      <h5>Loading..</h5>
    </div>
  ) : (
    <div>
      <h6>Approved Parking Charges :- {`₹ ${amount}`}</h6>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              value={formik.values.paymentMode}
              name="paymentMode"
              options={paymentModeOptions}
              onChange={(val) => formik?.setFieldValue('paymentMode', val)}
              placeholder="Select Payment Mode*"
            />
            {formik.errors.paymentMode && formik.touched.paymentMode ? (
              <div style={{ color: 'red' }}>{formik.errors?.paymentMode}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.amount}
              inputType="number"
              name="amount"
              onChange={(e) => formik?.setFieldValue('amount', e.target.value)}
              placeholder="Enter the Payment Amount in INR*"
            />
            {formik.values.amount &&
            (formik.errors.amount || formik.touched.amount) ? (
              <div style={{ color: 'red' }}>{formik.errors?.amount}</div>
            ) : null}
          </Grid>

          <Grid item xs={12} md={12}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue('paymentDate', value);
              }}
              placeholder="Enter the Payment Date*"
              value={
                formik.values.paymentDate
                  ? new Date(formik.values.paymentDate)
                  : null
              }
            />
            {formik.errors.paymentDate && formik.touched.paymentDate ? (
              <div style={{ color: 'red' }}>{formik.errors?.paymentDate}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="paymentReceipt"
              value={formik.values.paymentReceipt}
              onChange={(value) => {
                formik.setFieldValue('paymentReceipt', value);
              }}
              placeholder="Payment Receipt*"
            />
            {formik.errors.paymentReceipt && formik.touched.paymentReceipt ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.paymentReceipt}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.remarks}
              name="remarks"
              onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
              placeholder="Enter the Remarks"
            />
          </Grid>
        </Grid>
        <CtaButton
          desireButtonText={desireButtonText}
          undesireButtonText={undesireButtonText}
          backBtn={canGoBack}
          onlyRed={true}
          reject={reject}
          disabled={!(formik.isValid && formik.dirty)}
        />
      </form>
    </div>
  );
}

export default ParkingPayment;
