import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DealDetailModal from './DealDetailModal';
import { useState } from 'react';
import { Typography } from '@mui/material';

type DetailsOptions = {
  key?: string;
  value?: any;
  type?: string;
  show?: boolean;
  textColor?: string;
  isHidden?: boolean;
  metaData?: { title: string; data: { key: string; value: string | number }[] };
  checkHoldBack?: boolean | string;
};

type DetailsTabPanelPropsType = {
  data: DetailsOptions[];
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const widthStyle = {
  width: '50%'
};

function DetailsTabPanel({ data }: DetailsTabPanelPropsType) {
  return (
    <TableContainer sx={{ mt: 3 }} component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableBody>
          {data?.map((item, index: number) => {
            return item?.show === undefined || item?.show === true ? (
              <StyledTableRow key={index}>
                <StyledTableCell
                  style={{
                    fontWeight: item.value === '#title' ? '900' : 'normal'
                  }}
                  component="th"
                  scope="row"
                  sx={widthStyle}
                >
                  {item.key}
                </StyledTableCell>
                {item.value !== '#title' ? (
                  (item.type === 'image' ||
                    (item.type === 'map' && item.isHidden)) &&
                  item.value &&
                  item.value !== '-' ? (
                    <StyledTableCell component="th" scope="row">
                      <Link
                        href={item.value}
                        style={{
                          color: 'rgb(17,25,42)',
                          textDecoration: 'underline',
                          display: 'flex',
                          justifyContent: 'flex-start'
                        }}
                        underline="hover"
                        target="_blank"
                      >
                        View document
                      </Link>
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell
                      style={{
                        color: item?.textColor ? item?.textColor : 'primary'
                      }}
                    >
                      {item.value === 'View' ? (
                        <>
                          <DealDetailModal metadata={item?.metaData ?? null} />
                        </>
                      ) : (
                        !!item?.value ? item?.value : "-" ?? '-'
                      )}
                    </StyledTableCell>
                  )
                ) : (
                  <StyledTableCell component="th" scope="row"></StyledTableCell>
                )}
              </StyledTableRow>
            ) : (
              <></>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DetailsTabPanel;
