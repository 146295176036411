import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import BidConfirmation from './Confirmation';
import Input from 'src/components/Form/Input';
import { LeadCreateAndUpdateProps } from 'src/types';
import { LeadRef } from 'src/generated/hooks_and_more';
import '../form.css';
import CtaButton from '../CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';
import { LeadFlowFormsInputs } from 'src/utils/utility';
import { useReactiveVar } from '@apollo/client';
function BidAmountForm(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    onPressOfDesiredOrUndesiredButton,
    canGoBack
  } = props;
  const [modal, setModal] = useState(false);
  const formInputs = useReactiveVar(LeadFlowFormsInputs);
  const [bidValue, setBidValue] = useState('');
  const formik = useFormik({
    initialValues: {
      bidAmount: ''
    },
    onSubmit: (values) => {
      let leadInput: LeadRef = {
        regNo: regNo,
        id: leadId,
        proposedBidLimitAmount: values?.bidAmount
          ? Number(values?.bidAmount)
          : null,
       auctionHistory: [
        {
          createdAt: new Date(),
          amount: values?.bidAmount
          ? Number(values?.bidAmount)
          : null,
        }
       ]

       
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    },

    validationSchema: yup.object().shape({
      bidAmount: yup
        .string()
        .required('*Required')
        .matches(/^[0-9]+$/, '*Numbers only Allowed')
        .max(9, '*Maximum value should be in 9 digits')
    })
  });
  const reject = () => {
    let leadInput: LeadRef = {
      id: leadId,
      regNo: regNo,
      finalBidAmount: formik.values?.bidAmount
        ? Number(formik.values?.bidAmount)
        : null,
        // auctionHistory: [
        //   {
        //     createdAt: new Date(),
        //     amount: formik.values?.bidAmount
        //     ? Number(formik.values?.bidAmount)
        //     : null,
        //   }
        //  ]
    };
    // onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Input
            name="bidAmount"
            placeholder="Enter Bid Amount Limit"
            value={formik.values.bidAmount}
            onChange={(event) => {
              formik.setFieldValue('bidAmount', event.target.value);
              LeadFlowFormsInputs({
                ...formInputs,
                proposedBidLimitAmount: event?.target?.value
              });
            }}
          />
          {formik.values.bidAmount &&
          (formik?.touched.bidAmount || formik.errors.bidAmount) ? (
            <div style={{ color: 'red' }}>{formik?.errors?.bidAmount}</div>
          ) : null}
        </Grid>
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        undesireButtonText={undesireButtonText}
        disabled={!(formik.isValid && formik.dirty)}
        reject={reject}
      />
      {/* <BidConfirmation
        open={modal}
        handleClose={() => {
          setModal(false);
        }}
        onConfirmationHandler={() => {
          console.log('hello world');
        }}
        heading="Bid Confirmation"
        textToShow={`Do you want to update ${bidValue} as initial bid amount? `}
        reset={formik.resetForm}
      /> */}
    </form>
  );
}
export default BidAmountForm;
