import React, { useState } from 'react';

import { IconButton, TableCell, TableRow, Typography } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { AddState } from './AddStatePopOver';
import { formatDate, titleCaseToReadable } from 'src/utils/utility';
import format from 'date-fns/format';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
import { UserRole } from 'src/generated/hooks_and_more';

type StateWithGSTINType = {
  id?: string;
  state?: string;
  active?: boolean;
  GSTNumber?: string;
  updatedAt?: string;
  createdAt?: string;
};

type listOfGSTState = {
  stateData: StateWithGSTINType;
  index?: any;
  onEdit?: () => void;
  handleSelectId?: (id: string) => void;
};
function StateWithGSTINRow(props: listOfGSTState) {
  const { loggedInUser } = useLoggedInUser();

  const { stateData, index, onEdit, handleSelectId } = props;
  return (
    <TableRow hover sx={{ cursor: 'pointer' }}>
      <TableCell key={index}>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {titleCaseToReadable(stateData?.state) ?? '-'}
        </Typography>
      </TableCell>
      <TableCell key={index}>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {stateData?.GSTNumber ?? '-'}
        </Typography>
      </TableCell>

      <TableCell key={index}>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {stateData?.active == true ? 'ACTIVE' : 'INACTIVE' ?? '-'}
        </Typography>
      </TableCell>
      <TableCell key={index}>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {formatDate(stateData?.createdAt) ?? '-'}
        </Typography>
      </TableCell>
      <TableCell key={index}>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {formatDate(stateData?.updatedAt) ?? '-'}
        </Typography>
      </TableCell>
      <TableCell key={index}>
        <IconButton
          disabled={loggedInUser?.role !== UserRole.Admin}
          onClick={() => {
            onEdit();
            handleSelectId(stateData?.id);
          }}
        >
          <EditIcon fontSize="small" values={stateData?.id} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default StateWithGSTINRow;
