import React from 'react';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import {
  LeadRef,
  BookingPaymentRef,
  DocumentsCount,
  LeadSource
} from 'src/generated/hooks_and_more';
import { titleCaseToReadable } from 'src/utils/utility';

export type LeadDetailsPropsType = {
  currentLead: LeadRef;
  countRefetch?: () => void;
  currCounts?: any;
  openP?: boolean;
  setOpenP?: () => void;
  handlerefurbishment?: () => void;
  addPartPayments?: () => void;

  // status: BookingPaymentRef;
  handlePaymentRaise?: () => void;
};

function ApplicationDetails({ currentLead }: LeadDetailsPropsType) {
  const currentStatus = currentLead?.statusEvents ?? [];
  const regNo = currentLead?.regNo;
  const auctionByBankName = currentLead?.auctionByBank;
  const auctioningAgency = currentLead?.auctioningAgency?.name;
  const purchaseType = currentLead?.source ?? '-';
  const status = currentStatus && currentStatus?.[0]?.status;
  const LeadStatus = currentLead?.status;
  const vehicle = 'Tractor';
  const dealerName = currentLead?.dealer;
  const source = currentLead?.source;
  const ownershipType = currentLead?.ownershipType;

  const ApplicationDetails = {
    groupName: 'Application Details',
    groupItems: [
      { key: 'Registration No', value: regNo ?? '-' },
      {
        key: 'Status',
        value: status ? titleCaseToReadable(status) : '-'
      },
      //FIXME: Remove hard-coded tractor and change it to vehicle type
      { key: 'Vehicle', value: vehicle ?? '-' },
      {
        key: 'Purchase Type',
        value: titleCaseToReadable(purchaseType) ?? '-'
      },
      {
        key: 'Auction agency',
        value: auctioningAgency ?? '-'
      },
      {
        key: 'Bank name',
        value: !!auctionByBankName
          ? titleCaseToReadable(auctionByBankName)
          : '-'
      },
      {
        key: 'Dealer Name',
        value: dealerName?.name ?? '-'
      },
      {
        key: 'Lead Status',
        value: LeadStatus ?? '-'
      }
    ]
  };

  if (purchaseType === LeadSource.DealershipSale) {
    ApplicationDetails?.groupItems.push({
      key: 'Ownership Type',
      value: titleCaseToReadable(ownershipType) ?? '-'
    });
  }
  return <DetailsTabPanel data={ApplicationDetails.groupItems} />;
}

export default ApplicationDetails;
