import { Formik, useFormik, yupToFormErrors } from 'formik';
import { Box, Button, Grid } from '@mui/material';
import Input from 'src/components/Form/Input';
import { LeadRef, LeadStatus } from 'src/generated/hooks_and_more';
import * as Yup from 'yup';

import { LeadCreateAndUpdateProps } from 'src/types';
import ShowTable from './DocumentDataShow';
import CtaButton from '../CTA_Button/CtaButton';
import { LeadFlowFormsInputs } from 'src/utils/utility';
import { useReactiveVar } from '@apollo/client';

const HoldBackForm = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton
  } = props;
  const formInputs = useReactiveVar(LeadFlowFormsInputs);
  const formik = useFormik({
    initialValues: {
      documentAmount: '',
      holdBackAmount: ''
    },
    validationSchema: Yup.object().shape({
      documentAmount: Yup.number().typeError('*Must be a Number'),
      holdBackAmount: Yup.number().typeError('*Must be a Number')

    }),

    onSubmit: (values) => {
      const leadInput: LeadRef = {
        regNo: regNo,
        id: leadId,
        holdbackAmount: Number(values.holdBackAmount),
        documentCharges: Number(values.documentAmount)
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.documentAmount}
            name="documentAmount"
            onChange={(e) => {

              formik?.setFieldValue('documentAmount', e.target.value)
              LeadFlowFormsInputs({
                ...formInputs,
                documentCharges: e.target.value
              });
            }
            }
            placeholder="Document Charges*"
          />
          {formik.values.documentAmount || formik.errors.documentAmount && formik.touched.documentAmount ? (
            <div style={{ color: 'red' }}>{formik.errors?.documentAmount}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <Input
            value={formik.values.holdBackAmount}
            name="holdBackAmount"
            onChange={(e) => {

              formik?.setFieldValue('holdBackAmount', e.target.value)
              LeadFlowFormsInputs({
                ...formInputs,
                holdbackAmount: e.target.value
              });
            }
            }
            placeholder="Hold Back Amount*"
          />
          {formik.values.holdBackAmount || formik.errors.holdBackAmount && formik.touched.holdBackAmount ? (
            <div style={{ color: 'red' }}>{formik.errors?.holdBackAmount}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} mt={2}>
          <ShowTable leadId={leadId} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} mt={2}>
        <CtaButton
          desireButtonText={desireButtonText}
          disabled={Boolean(formik.values.documentAmount && formik.errors.documentAmount) || Boolean(formik.values.documentAmount && formik.errors.holdBackAmount)}
        />
      </Grid>
    </form>
  );
};

export default HoldBackForm;
