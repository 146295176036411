import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import {
  useGetIssueItemDetailsLazyQuery,
  useGetIssueItemDetailsQuery,
  useGetPurchaseItemDetailsLazyQuery,
  useGetPurchaseItemDetailsQuery
} from 'src/generated/hooks_and_more';
import { Button, Table, TableBody } from '@mui/material';
import { SatelliteTwoTone } from '@mui/icons-material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  width: '45%'
};

export type Detail = {
  open?: boolean;
  handleClose: () => void;
  purchaseId?: string;
  status?: string;
};
const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  cursor: 'pointer',
  margin: '0px 0px 10px 0px'
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

export default function StockPopOverData({
  open,
  handleClose,
  purchaseId,
  status
}: Detail) {
  const [getPurchase, { data: purchaseItems, loading }] =
    useGetPurchaseItemDetailsLazyQuery();

  const [getIssue, { data: issueItems, loading: issueLoading }] =
    useGetIssueItemDetailsLazyQuery();

  React.useEffect(() => {
    if (
      status === 'RECEIVED' ||
      status === 'REFURBISHMENT_APPROVED' ||
      status === 'BILLS_UPLOADED' ||
      status === 'PAYMENT_REQUESTED' || status?.includes("PURCHASE")
    ) {
      getPurchase({
        variables: {
          purchaseId: purchaseId
        }
      });
    } else
      getIssue({
        variables: {
          purchaseId: purchaseId
        }
      });
  }, [open]);

  const data =
    status === 'RECEIVED' ||
    status === 'REFURBISHMENT_APPROVED' ||
    status === 'BILLS_UPLOADED' ||
    status === 'PAYMENT_REQUESTED' || status?.includes("PURCHASE")
      ? 'PURCHASE'
      : 'ISSUE';

  let itemOutput = issueItems?.getIssue?.items?.map((i) => {
    if (Boolean(i?.description)) {
      return {
        'Reg No.': !!issueItems?.getIssue?.regNo ? issueItems?.getIssue?.regNo : '-',
        'Item Name': i?.product?.name,
        Description: Boolean(i?.description) ? i?.description : null,
        Quantity: i?.quantity
      };
    } else {
      return {
        'Reg No.':  !!issueItems?.getIssue?.regNo ? issueItems?.getIssue?.regNo : '-',
        'Item Name': i?.product?.name,
        Quantity: i?.quantity
      };
    }
  });

  let purchaseOut = purchaseItems?.getPurchase?.items?.map((i) => {
    if (Boolean(i?.description)) {
      return {
        'item Name': i?.product?.name,
        Description: Boolean(i?.description) ? i?.description : null,
        Quantity: i?.quantity
      };
    } else {
      return {
        'item Name': i?.product?.name,
        Quantity: i?.quantity
      };
    }
  });

  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} style={{ overflow: 'auto', maxHeight: '75%' }}>
          <Box sx={closeBtnStyle}>
            <Table>
              <TableBody>
                <StyledTableRow>
                  <TableCell>
                    {data === 'ISSUE' ? (
                      <Typography> Issue Details </Typography>
                    ) : (
                      <Typography> Purchase Details </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {data === 'ISSUE' ? (
                      <Button variant="contained" color="success">
                        {' '}
                        Issue
                      </Button>
                    ) : (
                      <Button variant="contained" color="primary">
                        {' '}
                        Purchase
                      </Button>
                    )}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow sx={{ width: '100%' }}>
                  <TableCell>
                    <Typography>Created By</Typography>
                  </TableCell>
                  
                  <TableCell sx={{ width: '50%' }}>
                    {Boolean(data === 'ISSUE') ? (
                      <Typography>
                        {issueItems?.getIssue?.madeBy?.name}
                      </Typography>
                    ) : (
                      <Typography>
                        {purchaseItems?.getPurchase?.madeBy?.name}
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow sx={{ width: '100%' }}>
                  <TableCell>
                    <Typography>Center</Typography>
                  </TableCell>
                  
                  <TableCell sx={{ width: '50%' }}>
                  {Boolean(data === 'ISSUE') ? (
                      <Typography>
                        {issueItems?.getIssue?.centre?.name}
                      </Typography>
                    ) : (
                      <Typography>
                        {purchaseItems?.getPurchase?.centre?.name}
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
                <>
                  {data === 'ISSUE'
                    ? itemOutput?.map((item, i) =>
                        Object.keys(item).map((key) => {
                          if (item['Item Name'] === 'Others') {
                            return (
                              <>
                                <StyledTableRow key={i}>
                                  <StyledTableCell
                                    key={`${key}-header`}
                                    id="keep-mounted-modal-title"
                                    sx={{ width: '300px' }}
                                  >
                                    {key}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    key={`${key}-value`}
                                    id="keep-mounted-modal-title"
                                    sx={{ width: '300px' }}
                                  >
                                    {item[key]}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <StyledTableRow key={i}>
                                  <StyledTableCell
                                    key={`${key}-header`}
                                    id="keep-mounted-modal-title"
                                    sx={{ width: '300px' }}
                                  >
                                    {item['item Name'] !== 'Others' &&
                                    key === 'description'
                                      ? null
                                      : key}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    key={`${key}-value`}
                                    id="keep-mounted-modal-title"
                                    sx={{ width: '300px' }}
                                  >
                                    {item['item Name'] !== 'Others' &&
                                    key === 'description'
                                      ? null
                                      : item[key]}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            );
                          }
                        })
                      )
                    : null}

                  {data === 'PURCHASE'
                    ? purchaseOut?.map((item, i) =>
                        Object.keys(item).map((key) => (
                          <>
                            <StyledTableRow key={i}>
                              <StyledTableCell
                                key={`${key}-header`}
                                id="keep-mounted-modal-title"
                                sx={{ width: '300px' }}
                              >
                                {key}
                              </StyledTableCell>
                              <StyledTableCell
                                key={`${key}-value`}
                                id="keep-mounted-modal-title"
                                sx={{ width: '300px' }}
                              >
                                {item[key]}
                              </StyledTableCell>
                            </StyledTableRow>
                          </>
                        ))
                      )
                    : null}
                </>
              </TableBody>
            </Table>

            <CloseIcon onClick={handleClose} />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
