import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql.client';
import { ToastProvider } from 'react-toast-notifications';

ReactDOM.render(
  <HelmetProvider>
    <ToastProvider >
      <ApolloProvider client={client}>
        <SidebarProvider>
          <BrowserRouter>
            <App /> 
          </BrowserRouter>
        </SidebarProvider>
      </ApolloProvider>
    </ToastProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
