import {
  alpha,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';

import { format, formatDistance, subDays } from 'date-fns';
import { useNotificationDataQuery } from 'src/generated/hooks_and_more';
import { Link } from 'react-router-dom';
type NotificationsProps = {
  val?: boolean;
  setVal?: any;
  handleClose: () => void;
  notificationCount: any;
};

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications({
  val,
  setVal,
  handleClose,
  notificationCount
}: NotificationsProps) {
  const { data, loading } = useNotificationDataQuery();
  const [anchorEl, setAnchorEl] = useState(null);

  const ref = useRef<any>(null);

  let followUpDates = data?.queryDocumentsFollowups?.filter((i) => {
    const todayDate = new Date().toLocaleDateString();
    return todayDate === new Date(i?.nextFollowupDate).toLocaleDateString();
  });

  useEffect(() => {
    notificationCount(followUpDates?.length);
  }, []);
  return (
    <>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={val}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ padding: '30px', width: '300px' }}>
          <Typography variant="h5">Notifications</Typography>
          <Divider />
          <Box sx={{ p: 0, mt: 2 }} display="flex">
            <Grid>
              {followUpDates?.map((followUps, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography>
                    <span style={{ fontWeight: 600 }}>Reg No :- </span>
                    <Link
                      to={`${followUps?.lead?.regNo}`}
                      onClick={() => setVal(false)}
                    >
                      {followUps?.lead?.regNo}
                    </Link>
                  </Typography>
                  {followUps?.documentName && (
                    <Typography>
                      <span style={{ fontWeight: 600 }}>Doc Name :- </span>
                      {followUps?.documentName}
                    </Typography>
                  )}

                  <Typography key={index}>
                    <Typography component="span" fontWeight={600}>
                      Followup on :-
                    </Typography>
                    {new Date(followUps?.nextFollowupDate).toLocaleDateString(
                      'en-GB',
                      {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                      }
                    )}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Box>
        </Box>
      </Popover>
      <div
        style={{ position: 'relative', cursor: 'pointer' }}
        onClick={() => setVal(!val)}
      >
        {followUpDates?.length && !loading ? (
          <p className="notificationCount">{followUpDates?.length}</p>
        ) : null}
        <NotificationsActiveTwoToneIcon />
      </div>
    </>
  );
}

export default HeaderNotifications;
