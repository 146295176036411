import React from 'react';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import { LeadDetailsPropsType } from './ApplicationDetails';
import { LeadSource, LeadStatus } from 'src/generated/hooks_and_more';
import { useLocation, useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';
import DocumentPanel from '../../../components/Composite/DocumentsListTab';
import DocumentsListTab from '../../../components/Composite/DocumentsListTab';
function DocumentsDetails({
  currentLead,
  countRefetch,
  currCounts
}: LeadDetailsPropsType) {
  const location = useLocation();
  const sourceType = currentLead?.source;
    const bankNOC = currentLead?.documents?.bankNOC;
  const form26 = currentLead?.documents?.form26;
  const form28 = currentLead?.documents?.form28;
  const form29 = currentLead?.documents?.form29;
  const form30 = currentLead?.documents?.form30;
  const form35 = currentLead?.documents?.form35;
  const form36 = currentLead?.documents?.form36;
  const form60 = currentLead?.documents?.form60;
  const form60_sellerPan = currentLead?.documents?.form60_sellerPan;
  const indemnityBond = currentLead?.documents?.indemnityBond;
  const insuranceCertificate = currentLead?.documents?.insuranceCertificate;
  const loanForeclosure = currentLead?.documents?.loanForeclosure;
  const registrationCertificate =
    currentLead?.documents?.registrationCertificate;
  const releaseOrder = currentLead?.documents?.releaseOrder;
  const sellerAadharCard = currentLead?.documents?.sellerAadharCard;
  const sellerPan = currentLead?.documents?.sellerPAN;
  const ownerAddressProof = currentLead?.documents?.ownerAddressProof;

  console.log(currentLead?.documentChecklist, currentLead?.source === LeadSource.DealershipSale
    ? Boolean(currentLead?.documentChecklist?.bankNOC ) ? true : false
     : currentLead?.source === LeadSource.BankAuction
     ? currentLead?.statusEvents?.some(
      item => item?.status == LeadStatus.PaymentConfirmed,
    ) && !currentLead?.documentChecklist
    ? true
    : currentLead?.documentChecklist?.bankNOC
  : false,  "currentLead?.documentChecklist")
    let availableDocuments: {
    key: string;
    queryKey: string;
    value: string;
    isHidden?: boolean;
    type?: string;
      }[] = [
    {
      key: 'Bank NOC',
      queryKey: 'bankNOC',
      value: bankNOC ? bankNOC: '-',
      type: 'image',


      isHidden:  currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.bankNOC ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.bankNOC
    : false,
        
    },
    {
      key: 'Form 26',
      queryKey: 'form26',
      value: form26 ? form26: '-',
      type: 'image',

      isHidden:  currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.form26 ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.form26
    : false
       
    },
    {
      key: 'Form 28',
      queryKey: 'form28',
      value: form28 ? form28: '-',
      type: 'image',

      isHidden:
      currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.form28 ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.form28
    : false,
      
    },
    {
      key: 'Form 29',
      queryKey: 'form29',
      value: form29 ? form29: '-',
      type: 'image',

      isHidden:  currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.form29 ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.form29
    : false,
       
    },
    {
      key: 'Form 30',
      queryKey: 'form30',
      value: form30 ? form30: '-',
      type: 'image',

      isHidden:  currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.form30) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.form30
    : false
       
    },
    {
      key: 'Form 35',
      queryKey: 'form35',
      value: form35 ? form35: '-',
      type: 'image',
      
      isHidden:
      currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.form35 ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.form35
    : false
    },
    {
      key: 'Seller Pan / Form 60',
      queryKey: 'form60_sellerPan',
      value: form60_sellerPan ? form60_sellerPan: '-',
      type: 'image',

      isHidden:  currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.form60_sellerPan ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.form60_sellerPan
    : false
       
    },

    {
      key: 'Insurance Certificate',
      queryKey: 'insuranceCertificate',
      value: insuranceCertificate ? insuranceCertificate: '-',
      type: 'image',

      isHidden:
      currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.insuranceCertificate ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.insuranceCertificate
    : false
    },
    {
      key: 'Loan Foreclosure',
      queryKey: 'loanForeclosure',
      value: loanForeclosure ? loanForeclosure: '-',
      type: 'image',

      isHidden:
      currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.loanForeclosure ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.loanForeclosure
    : false
    },
    {
      key: 'Registration Certificate',
      queryKey: 'registrationCertificate',
      value: registrationCertificate ? registrationCertificate: '-',
      type: 'image',

      isHidden:
      currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.registrationCertificate ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.registrationCertificate
    : false
    },
    {
      key: 'Seller AadharCard',
      queryKey: 'sellerAadharCard',
      value: sellerAadharCard ? sellerAadharCard: '-',
      type: 'image',

      isHidden:
      currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.sellerAadharCard ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.sellerAadharCard
    : false
    },

    {
      key: 'Form 36',
      queryKey: 'form36',
      value: form36 ? form36: '-',
      type: 'image',
      
      isHidden:
      currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.form36 ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.form36
    : false
    },
    {
      key: 'Owner Address Proof',
      queryKey: 'ownerAddressProof',
      value: ownerAddressProof ? ownerAddressProof: '-',
      type: 'image',

      isHidden:
      currentLead?.source === LeadSource.DealershipSale
      ? Boolean(currentLead?.documentChecklist?.ownerAddressProof ) ? true : false
       : currentLead?.source === LeadSource.BankAuction
       ? currentLead?.statusEvents?.some(
        item => item?.status == LeadStatus.PaymentConfirmed,
      ) && !currentLead?.documentChecklist
      ? true
      : currentLead?.documentChecklist?.ownerAddressProof
    : false
    }
  ];

  if (currentLead?.source === LeadSource.BankAuction) {
    availableDocuments = [
      ...availableDocuments,
      {
        key: 'Indemnity Bond',
        queryKey: 'indemnityBond',
        value: indemnityBond ? indemnityBond: '-',
        type: 'image',
        isHidden:  currentLead?.statusEvents?.some(
          item => item?.status == LeadStatus.PaymentConfirmed,
        ) && !currentLead?.documentChecklist ? true : false
        
       
      },
      {
        key: 'Release Order',
        queryKey: 'releaseOrder',
        value: releaseOrder ? releaseOrder: '-',
        type: 'image',

        isHidden: currentLead?.statusEvents?.some(
          item => item?.status == LeadStatus.PaymentConfirmed,
        ) && !currentLead?.documentChecklist ? true : false
      }
    ];
  }
  if (
    currentLead?.source === 'DEALERSHIP_SALE' &&
    currentLead?.documentChecklist === null
  ) {
    let modifiedDocuments = [];
    availableDocuments?.map((doc, index) => {

      modifiedDocuments.push({ ...doc, isHidden: false });
    });
    availableDocuments = modifiedDocuments;
  }
  return (
    <DocumentsListTab
      documentCheckList={currentLead?.documentChecklist}
      data={availableDocuments}
      countRefetch={countRefetch}
      currCounts={currCounts}
    />
  );
}

export default DocumentsDetails;
