import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { Button, Grid } from '@mui/material';
import * as Yup from 'yup';

import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { LeadCreateAndUpdateProps } from 'src/types';
import {
  ActionType,
  ImageStage,
  LeadSource,
  LeadStatus,
  ListingStatus,
  ModuleType,
  TaskStatus,
  VehicleImages,
  useAddLogsMutation,
  useAddVehicleImagesMutation,
  useGetIdsByNameForListingLazyQuery,
  useProcurementCostQuery,
  useUpdateImagesMutation,
  useUpdateListingPriceMutation,
  useUpdateListingStatusOnVmsMutation
} from 'src/generated/hooks_and_more';

import Input from 'src/components/Form/Input';
import { LeadFlowFormsInputs, getUserData } from 'src/utils/utility';
import { useReactiveVar } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { useLocation, useNavigate } from 'react-router';
import { environments } from 'src/config';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';

type TractorListingBottomSheetProps = {
  listingImages?: Partial<VehicleImages>[];
  sellingPrice: number;
  listingPrice: string;
  regNo: string;
  leadId: string;
  brand?: string;
  district: string;
  model?: string;
  state?: string;
  tehsil?: string;
  engine_hours: number;
  manufacture_month: string;
  manufacture_year: number;
  rc_available: string;
  seller_name: string;
  mobile_number: string;
  desireButtonText?: string;
  desireStatus?: LeadStatus;
  source?: LeadSource;
  toggleDrawer: () => void;
};

const ListingTractorForm = (
  {
    leadId,
    desireButtonText,
    desireStatus,
    regNo,
    listingPrice,
    sellingPrice,
    engine_hours,
    manufacture_month,
    manufacture_year,
    mobile_number,
    brand,
    district,
    tehsil,
    model,
    state,
    rc_available,
    seller_name
  }: TractorListingBottomSheetProps,
  props: LeadCreateAndUpdateProps
) => {
  const { toggleDrawer } = props;

  const userDetails = getUserData();

  const userDetail = getUserData();
  const { addToast } = useToasts();
  const location = useLocation();
  const [GetIdsByNameForListing, { data, loading: idsLoading }] =
    useGetIdsByNameForListingLazyQuery();
  const [images, setImages] = useState([]);
  const [dataList, setData] = useState(null);

  const formInputs = useReactiveVar(LeadFlowFormsInputs);
  const [isLoading, setIsLoading] = useState(false);
  const leadInput = useReactiveVar(LeadFlowFormsInputs);
  const [updateListingPrice] = useUpdateListingPriceMutation();
  const [updateImagesForListing] = useAddVehicleImagesMutation();
  const { data: costData, loading } = useProcurementCostQuery({
    variables: {
      leadId: leadId
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      const images = data.getLead?.vehicle.images.filter(
        (image) =>
          image.vehicleImagesId ===
          `${data?.getLead?.regNo.toUpperCase()}_LISTING`
      );

      const inspectionVideoStart = images[0]?.fuelInjectionPumpPlate;
      const bodyImageFrontStart = images[0]?.frontBodySide;
      const bodyImageLeftStart = images[0]?.leftBodySide;
      const bodyImageBackStart = images[0]?.backBodySide;
      const bodyImageRightStart = images[0]?.rightBodySide;
      const tyreBackRightImageStart = images[0]?.backRightTyre;
      const tyreFrontLeftImageStart = images[0]?.frontLeftTyre;
      const tyreFrontRightImageStart = images[0]?.frontRightTyre;
      const fipStart = images[0]?.fuelInjectionPumpPlate;
      const odometerStart = images[0]?.odometer;
      const chassisStart = images[0]?.chassisNumber;
      const engineStart = images[0]?.engineNumber;

      formik.setValues({
        ...formik.values,
        inspectionVideo: inspectionVideoStart,
        bodyImageFront: bodyImageFrontStart,
        bodyImageLeft: bodyImageLeftStart,
        bodyImageBack: bodyImageBackStart,
        bodyImageRight: bodyImageRightStart,
        tyreBackLeftImage: bodyImageRightStart,
        tyreBackRightImage: tyreBackRightImageStart,
        tyreFrontLeftImage: tyreFrontLeftImageStart,
        tyreFrontRightImage: tyreFrontRightImageStart,
        fip: fipStart,
        odometer: odometerStart,
        chassis: chassisStart,
        engine: engineStart
      });
    }
  });
  const [AddLogs] = useAddLogsMutation();
  const { loggedInUser } = useLoggedInUser();

  const [updateListingDetailsOnVMS, { loading: isVMSLoading }] =
    useUpdateListingStatusOnVmsMutation({
      variables: {
        leadId,
        regNo: { allofterms: regNo },
        leadPatch: {
          listingOnWebUpdatedAt: new Date(),
          listingStatus: ListingStatus.ListedOnWebsite,
          listingPrice: Number(leadInput?.listingPrice),
          listingUpdatedBy: {
            id: userDetail?.id,
            email: userDetail?.email
          }
        }
      },
    });

  const formik = useFormik({
    initialValues: {
      inspectionVideo: '',
      bodyImageFront: '',
      bodyImageLeft: '',
      bodyImageBack: '',
      bodyImageRight: '',
      tyreBackLeftImage: '',
      tyreBackRightImage: '',
      tyreFrontLeftImage: '',
      tyreFrontRightImage: '',
      fip: '',
      odometer: '',
      chassis: '',
      engine: '',
      imagesCount: 0,
      listingPrice: 0,
      sellingPrice: costData?.getLead?.sellingPrice
    },
    validationSchema: Yup.object().shape({
      listingPrice: Yup.number()
        .moreThan(
          Yup.ref('sellingPrice'),
          '*Listing Price Should be Greater than selling Cost'
        )
        .required(),
      inspectionVideo: Yup.string()
        .matches(/\.mp4$/, '*Invalid Video Format')
        .notRequired(),
      bodyImageFront: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .required('Required*'),
      bodyImageLeft: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .required('Required*'),
      bodyImageBack: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .required('Required*'),
      bodyImageRight: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .required('Required*'),
      tyreBackLeftImage: Yup.string().matches(
        /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
        '*Invalid Image Format'
      ),
      tyreBackRightImage: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .notRequired(),
      tyreFrontLeftImage: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .notRequired(),
      tyreFrontRightImage: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .notRequired(),
      fip: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .notRequired(),
      odometer: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .notRequired(),
      chassis: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .notRequired(),
      engine: Yup.string()
        .matches(/(\.jpg|\.jpeg|\.png|\.pdf)$/i, '*Invalid Image Format')
        .notRequired()
      // imagesCount: Yup.number().min(4, '*Atleast 4 images required').required()
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        console.log('call Onlisting website');
        event.preventDefault();
        await onListingToWebsite();

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const onFormSubmit = (event, handleSubmit) => {
    event.preventDefault();
    updateImagesForListing({
      variables: {
        input: {
          vehicle: {
            id: costData?.getLead?.vehicle?.id
          },
          vehicleImagesId: `${regNo}_${ImageStage.Listing}`,
          imagesTakenAtStage: ImageStage.Listing,
          inspectionVideoUrl: formik.values.inspectionVideo ?? "",
          backBodySide: formik.values.bodyImageBack ?? "",
          frontBodySide: formik.values.bodyImageFront ?? "",
          leftBodySide: formik.values.bodyImageLeft ?? "",
          rightBodySide: formik.values.bodyImageRight ?? "",
          backLeftTyre: formik.values.tyreBackLeftImage ?? "",
          backRightTyre: formik.values.tyreBackRightImage ?? "",
          frontLeftTyre: formik.values.tyreFrontLeftImage ?? "",
          frontRightTyre: formik.values.tyreFrontRightImage ?? "",
          odometer: formik.values.odometer ?? "",
          fuelInjectionPumpPlate: formik.values.fip ?? "",
          chassisNumber: formik.values.odometer ?? "",
          engineNumber: formik.values.engine ?? ""
        }


      }

    });
    let leadInput = {
      regNo: regNo,
      id: leadId
    };
    updateListingPrice({
      variables: {
        id: leadId,
        ListingPrice: Number(formik.values.listingPrice)
      }
    });
    setData(leadInput);
    // Manually call formik handleSubmit
    handleSubmit();
  };

  async function onListingToWebsite() {
    try {
      setIsLoading(true);

      GetIdsByNameForListing({
        fetchPolicy: 'no-cache',
        variables: {
          brand,
          district,
          model,
          state,
          tehsil,
          env: environments,
          vehicle_registration_no: regNo,
          manufacture_month,
          manufacture_year: Number(manufacture_year),
          rc_available,
          seller_name,
          mobile_number,
          engine_hours,
          price: leadInput?.listingPrice,
          images: images ?? null
        },

        onCompleted(data) {
          if (data?.getIdsByNameForListing?.status) {
            updateListingDetailsOnVMS({
              onCompleted(){
                addToast('Vehicle listed on website successfully.', {
                  appearance: 'success',
                  autoDismiss: true
                });
                AddLogs({
                  variables: {
                    taskPerformedBy: { id: loggedInUser?.userId},
                    actionType: ActionType.Create,
                    module: ModuleType.Listing,
                    createdAt: new Date(),
                    sourceUpdated: regNo,
                    updatedValue: JSON.stringify({ data: {
                      listingOnWebUpdatedAt: new Date(),
                      listingStatus: ListingStatus.ListedOnWebsite,
                      listingPrice: leadInput?.listingPrice.toString(),
                      listingUpdatedBy: {
                        id: userDetail?.id,
                        email: userDetail?.email
                      }
                    } })  ,
                    previousValue: "",
                    status: TaskStatus.Success
                  }})
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              },
              onError(err) {
                addToast(`Error occurred`, {
                  appearance: 'error',
                  autoDismiss: true
                });
                AddLogs({
                  variables: {
                    taskPerformedBy: { id: loggedInUser?.userId},
                    actionType: ActionType.Update,
                    module: ModuleType.Listing,
                    createdAt: new Date(),
                    sourceUpdated: regNo,
                    updatedValue: "",
                    previousValue: "",
                    status: TaskStatus.Failed
                  }})
              }
            });
          } else {
            addToast(`Failed to list vehicle on website!!!`, {
              appearance: 'error',
              autoDismiss: true
            });
          }
        }
      });

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      addToast(`Lisitng failed: ${err}`, {
        appearance: 'error',
        autoDismiss: true
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      console.error(err);
    }
  }

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      sellingPrice: formik.values.sellingPrice
    });
  }, [formik?.values?.listingPrice]);

  useEffect(() => {
    let filledImage = [
      formik?.values?.bodyImageFront ? formik?.values?.bodyImageFront : null,
      formik?.values?.bodyImageLeft ? formik?.values?.bodyImageLeft : null,
      formik?.values?.bodyImageRight ? formik?.values?.bodyImageRight : null,
      formik?.values?.bodyImageBack ? formik?.values?.bodyImageBack : null,
      formik?.values?.inspectionVideo ? formik?.values?.inspectionVideo : null,
      formik?.values?.fip ? formik?.values?.fip : null,
      formik?.values?.odometer ? formik?.values?.odometer : null,
      formik?.values?.tyreBackLeftImage
        ? formik?.values?.tyreBackLeftImage
        : null,
      formik?.values?.tyreBackRightImage
        ? formik?.values?.tyreBackRightImage
        : null,
      formik?.values?.tyreFrontLeftImage
        ? formik?.values?.tyreFrontLeftImage
        : null,
      formik?.values?.tyreFrontRightImage
        ? formik?.values?.tyreFrontRightImage
        : null
    ];

    filledImage = filledImage.filter((value) => value !== null);

    setImages(filledImage);
  }, [formik.values]);

  return (
    <form onSubmit={(event) => onFormSubmit(event, formik.handleSubmit)}>
      <Grid container spacing={2}>
        {formik.errors.imagesCount || formik.touched.imagesCount ? (
          <div style={{ color: 'red' }}>{formik.errors?.imagesCount}</div>
        ) : null}
        <Grid item xs={12} md={12}>
          <h5>Need to upload images to list vehicle on Website</h5>
        </Grid>
        <Grid item xs={12} md={12}>
          <h5>Inspection Video </h5>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid item xs={6} md={6}>
            <FileUploadToS3
              accept=".mp4"
              id="inspection"
              value={formik.values.inspectionVideo}
              onChange={(value) => {
                formik.setFieldValue('inspectionVideo', value);
              }}
              placeholder="Inspection Clip"
            />
            {formik.errors.inspectionVideo || formik.touched.inspectionVideo ? (
              <div style={{ color: 'red' }}>
                {formik.errors.inspectionVideo}
              </div>
            ) : null}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <h5>Body Images</h5>
        </Grid>
        <Grid item xs={6} md={6} >
          <FileUploadToS3
            id="bodyImage"
            value={formik.values.bodyImageFront}
            onChange={(value) => {
              formik.setFieldValue('bodyImageFront', value);
              formik.setFieldValue(
                'imagesCount',
                formik.values.imagesCount + 1
              );
            }}
            placeholder="front"
            accept="image/jpg,image/png,image/jpeg"
          />
          {formik.errors.bodyImageFront || formik.touched.bodyImageFront ? (
            <div style={{ color: 'red' }}>{formik.errors?.bodyImageFront}</div>
          ) : null}
        </Grid>

        <Grid item xs={6} md={6} >
          <FileUploadToS3
            id="bodyleftImage"
            value={formik.values.bodyImageLeft}
            onChange={(value) => {
              formik.setFieldValue('bodyImageLeft', value);

              formik.setFieldValue(
                'imagesCount',
                formik.values.imagesCount + 1
              );
            }}
            accept="image/jpg,image/png,image/jpeg"
            placeholder="Left"
          />
          {formik.errors.bodyImageLeft || formik.touched.bodyImageLeft ? (
            <div style={{ color: 'red' }}>{formik.errors?.bodyImageLeft}</div>
          ) : null}
        </Grid>

        <Grid item xs={6} md={6} >
          <FileUploadToS3
            id="bodyImageBack"
            value={formik.values.bodyImageBack}
            onChange={(value) => {
              formik.setFieldValue('bodyImageBack', value);
              formik.setFieldValue(
                'imagesCount',
                formik.values.imagesCount + 1
              );
            }}
            accept="image/jpg,image/png,image/jpeg"
            placeholder="Back"
          />
          {formik.errors.bodyImageBack || formik.touched.bodyImageBack ? (
            <div style={{ color: 'red' }}>{formik.errors?.bodyImageBack}</div>
          ) : null}
        </Grid>
        <Grid item xs={6} md={6} >
          <FileUploadToS3
            id="bodyImageRight"
            value={formik.values.bodyImageRight}
            onChange={(value) => {
              formik.setFieldValue('bodyImageRight', value);
              formik.setFieldValue(
                'imagesCount',
                formik.values.imagesCount + 1
              );
            }}
            accept="image/jpg,image/png,image/jpeg"
            placeholder="Right"
          />
          {formik.errors.bodyImageRight || formik.touched.bodyImageRight ? (
            <div style={{ color: 'red' }}>{formik.errors?.bodyImageRight}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          {' '}
          <h5>Tyre Images</h5>
        </Grid>
        <Grid item xs={6} md={6} >
          <FileUploadToS3
            id="tyreBackLeftImage"
            value={formik.values.tyreBackLeftImage}
            onChange={(value) => {
              formik.setFieldValue('tyreBackLeftImage', value);
            }}
            accept="image/jpg,image/png,image/jpeg"
            placeholder="Back left"
          />
          {formik.errors.tyreBackLeftImage &&
            formik.touched.tyreBackLeftImage ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.tyreBackLeftImage}
            </div>
          ) : null}
        </Grid>
        <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
          <FileUploadToS3
            id="tyreBackRightImage"
            value={formik.values.tyreBackRightImage}
            onChange={(value) => {
              formik.setFieldValue('tyreBackRightImage', value);
            }}
            accept="image/jpg,image/png,image/jpeg"
            placeholder="Back Right"
          />
          {formik.errors.tyreBackRightImage &&
            formik.touched.tyreBackRightImage ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.tyreBackRightImage}
            </div>
          ) : null}
        </Grid>
        <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
          <FileUploadToS3
            id="tyreFrontLeftImage"
            value={formik.values.tyreFrontLeftImage}
            onChange={(value) => {
              formik.setFieldValue('tyreFrontLeftImage', value);
            }}
            placeholder="Front left"
            accept="image/jpg,image/png,image/jpeg"
          />
          {formik.errors.tyreFrontLeftImage &&
            formik.touched.tyreFrontLeftImage ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.tyreFrontLeftImage}
            </div>
          ) : null}
        </Grid>
        <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
          <FileUploadToS3
            id="tyreFrontRightImage"
            value={formik.values.tyreFrontRightImage}
            onChange={(value) => {
              formik.setFieldValue('tyreFrontRightImage', value);
            }}
            placeholder="Front right"
            accept="image/jpg,image/png,image/jpeg"
          />
          {formik.errors.tyreFrontRightImage &&
            formik.touched.tyreFrontRightImage ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.tyreFrontRightImage}
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          {' '}
          <h5>Others</h5>
        </Grid>
        <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
          <FileUploadToS3
            id="fip"
            value={formik.values.fip}
            onChange={(value) => {
              formik.setFieldValue('fip', value);
            }}
            accept="image/jpg,image/png,image/jpeg"
            placeholder="front"
          />
          {formik.errors.fip && formik.touched.fip ? (
            <div style={{ color: 'red' }}>{formik.errors?.fip}</div>
          ) : null}
        </Grid>
        <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
          <FileUploadToS3
            id="odometer"
            value={formik.values.odometer}
            onChange={(value) => {
              formik.setFieldValue('odometer', value);
            }}
            accept="image/jpg,image/png,image/jpeg"
            placeholder="Odometer"
          />
          {formik.errors.odometer && formik.touched.odometer ? (
            <div style={{ color: 'red' }}>{formik.errors?.odometer}</div>
          ) : null}
        </Grid>
        <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
          <FileUploadToS3
            id="chassis"
            value={formik.values.chassis}
            onChange={(value) => {
              formik.setFieldValue('chassis', value);
            }}
            placeholder="chassis"
            accept="image/jpg,image/png,image/jpeg"
          />
          {formik.errors.chassis && formik.touched.chassis ? (
            <div style={{ color: 'red' }}>{formik.errors?.chassis}</div>
          ) : null}
        </Grid>
        <Grid item xs={6} md={6} sx={{ display: 'flex' }}>
          <FileUploadToS3
            id="engine"
            value={formik.values.engine}
            onChange={(value) => {
              formik.setFieldValue('engine', value);
            }}
            placeholder="engine"
            accept="image/jpg,image/png,image/jpeg"
          />
          {formik.errors.engine && formik.touched.engine ? (
            <div style={{ color: 'red' }}>{formik.errors?.engine}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <h4>Selling price: {costData?.getLead?.sellingPrice}</h4>
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <Input
            inputType="number"
            value={
              Boolean(formik.values.listingPrice)
                ? formik.values.listingPrice
                : null
            }
            name="listingPrice"
            onChange={(e) => {
              formik?.setFieldValue('listingPrice', Number(e.target.value));
              LeadFlowFormsInputs({
                ...formInputs,
                listingPrice: e.target.value
              });
              formik?.setFieldValue(
                'sellingPrice',
                costData?.getLead?.sellingPrice
              );
            }}
            placeholder="Add Listing Price*"
          />
          {(formik.values.listingPrice && formik.errors.listingPrice) ||
            formik.touched.listingPrice ? (
            <div style={{ color: 'red' }}>{formik.errors?.listingPrice}</div>
          ) : null}
        </Grid>
      </Grid>

      <Button
        id={!(formik.isValid && formik.dirty) ? 'cta-btn-disabled' : 'cta-btn'}
        disabled={!(formik.isValid && formik.dirty)}
        style={{ minWidth: '100%' }}
        type="submit"
        variant="contained"
      >
        {desireButtonText}
      </Button>
    </form>
  );
};

export default ListingTractorForm;
