const previous = {
  data: {
      stateName: { label: 'MADHYA PRADESH', name: 'MADHYA PRADESH' },
      gstIn: 'GST',
      status: { label: 'ACTIVE', name: 'ACTIVE' }
    }
  
};
const updated = {

  data: {
    stateName: { label: 'MADHYA PRADESH', name: 'MADHYA PRADESH' },
    gstIn: 'GSSSHFGEHDT',
    status: { label: 'ACTIVE', name: 'ACTIVE' }
  }
};

let array5 = [];

export function flattenObject(obj) {
  const flattened = {};

  function flatten(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        Object.assign(flattened, flatten(obj[key]));
      }else {
        flattened[key] = obj[key];
      }
    });
    return flattened;

  }

return  flatten(obj);

}


function checkWhetherAnythingisUpdated(previous, updated) {
  
const previousObject = flattenObject(previous)
const updatedObject = flattenObject(updated)

  const changeLogs = [];
  
  Object?.keys(previousObject).map((item) => {
 
      if (previousObject?.[item] !== updatedObject[item]) {
        changeLogs.push({
          label: item,
          previous:  previousObject?.[item],
          current:  updatedObject?.[item]
        });
      }
    
  });

  return changeLogs;
}
const changeLogs = checkWhetherAnythingisUpdated(previous, updated);


 console.log(changeLogs)
