import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { StateType, StatusType } from 'src/types';

type MultiSelectAutoCompletePropsType = {
  onChange: (value: string[]) => void;
  value: StatusType[] | any;
  options: StateType[] | any;
  optionString: string;
  placeholder: string;
  disabled?: boolean;
};

function MultiSelectAutoComplete({
  onChange,
  value,
  options,
  optionString,
  placeholder,
  disabled
}: MultiSelectAutoCompletePropsType) {
  // console.log(value, options, optionString, disabled);
  return (
    <Autocomplete
      onChange={(event, value) => onChange(value)}
      multiple={true}
      disabled={disabled}
      id="size-small-standard-multi"
      size="small"
      value={value}
      options={options}
      // getOptionLabel={(option) => {
      //   if (option?.[optionString]) return option?.[optionString];
      // }}
      getOptionLabel={(option) => option?.[optionString]}
      renderInput={(params) => <TextField {...params} label={placeholder} />}
      style={{
        // border: '1px solid #0092ff',
        // borderRadius: '6.8px',
        // marginBottom: '10px',
        background: '#ffff'
      }}
    />
  );
}

export default MultiSelectAutoComplete;
