import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import { Booking } from 'src/generated/hooks_and_more';
import { LeadDetailsPropsType } from './ApplicationDetails';
import { titleCaseToReadable } from 'src/utils/utility';

function BookingDetails({ currentLead }: LeadDetailsPropsType) {
  const BookingArray = [];
  let bookings = [];

  if (currentLead?.activeBooking) {
    bookings?.push(currentLead?.activeBooking);
  }

  if (currentLead?.archivedBookings) {
    bookings = [...bookings, ...currentLead?.archivedBookings];
  }

  if (bookings.length > 0) {
    bookings.map((i, index) => {
      const archivedBookings = [
        {
          key: `Booking #${index + 1}`,
          value: '#title'
        },
        {
          key: 'Booking Status',
          value: i?.status ? titleCaseToReadable(i?.status) : '-'
        },
        {
          key: 'Customer Name',
          value: i?.customer?.name ?? '-'
        },
        {
          key: 'State',
          value: i?.customer?.customerState?.state ?? '-'
        },
        {
          key: 'District',
          value: i?.customer?.customerDistrict?.name ?? '-'
        },
        {
          key: 'Aadhar Number',
          value: i?.customer?.identificationDocuments?.aadhaarNumber ?? '-'
        },
        {
          key: 'Aadhar card(front)',
          value:
            i?.customer?.identificationDocuments?.aadhaarFrontPhotoUrl ?? '-',
          type: 'image'
        },
        {
          key: 'Aadhar card(back)',
          value:
            i?.customer?.identificationDocuments?.aadhaarBackPhotoUrl ?? '-',
          type: 'image'
        },
        {
          key: 'Pan Number',
          value: i?.customer?.identificationDocuments?.panNumber ?? '-'
        },
        {
          key: 'Pan Card',
          value: i?.customer?.identificationDocuments?.panPhotoUrl ?? '-',
          type: 'image'
        },
        {
          key: 'Vote Id number',
          value: Boolean(i?.customer?.identificationDocuments?.voterIdNumber) ? i?.customer?.identificationDocuments?.voterIdNumber : '-' ?? '-'
        },
        {
          key: 'Vote Id card',
          value: Boolean(i?.customer?.identificationDocuments?.voterIdPhotoUrl) ? i?.customer?.identificationDocuments?.voterIdPhotoUrl : '-'  ?? '-',
          type: 'image'
        },
        {
          key: 'RC Transfer Required',
          value: i?.isRCTransferReq ? 'Yes' : 'No'
        },
        {
          key: 'Insurance Required',
          value: i?.isInsuranceReq ? 'Yes' : 'No'
        },
        {
          key: 'Booking form',
          value: i?.vehicleTransferFormUrl ?? '-',
          type: 'image'
        },
        {
          key: 'cheque',
          value: i?.cancelledChequeUrl ?? '-',
          type: 'image'
        },
        {
          key: 'Delivery form',
          value: i?.deliveryPhotoUrl ?? '-',
          type: 'image'
        },
        {
          key: 'Cancel Reason',
          value: i?.cancellationReason
            ? titleCaseToReadable(i?.cancellationReason)
            : '-'
        }
      ];
      BookingArray.push(archivedBookings);
    });
  }

  return (
    <>
      {BookingArray?.map((item, index) => (
        <>
          <DetailsTabPanel key={index} data={item} />
        </>
      ))}
    </>
  );
  // return <DetailsTabPanel data={bookingDetails} />;
}

export default BookingDetails;
