import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import { Button, Grid, TextField } from '@mui/material';
import Input from 'src/components/Form/Input';
import {
  BankName,
  Lead,
  LeadBusinessStatus,
  LeadRef,
  LeadSource,
  LeadStatus,
  ListingStatus,
  Ownership,
  useGetAllNewBrandsQuery,
  useGetAllNewModelsByMakeLazyQuery,
  useGetAllStateQuery,
  useModelByMakeLazyQuery,
  usePopupDynamicValuesQuery
} from 'src/generated/hooks_and_more';
import { LeadFlowFormsInputs, enumToItems } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import {
  DealershipFormValidation,
  LeadFormValidation
} from '../ValidationSchema/ValidationSchema';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { AutoCompleteValueType, LeadCreateAndUpdateProps } from 'src/types';
import './form.css';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';
import { useReactiveVar } from '@apollo/client';
const LeadGenerate = (props: any) => {
  const {
    desireButtonText,
    undesireButtonText,
    toggleDrawer,
    desireStatus,
    onPressOfDesiredOrUndesiredButton,
    canGoBack,
    leadId,
    regNo
  } = props;
  const { data: allBrands } = useGetAllNewBrandsQuery();
  const [source, setSource] = useState('BANK_AUCTION');
  const [GetAllNewModelsByMake, { data: modelOptions }] =
    useGetAllNewModelsByMakeLazyQuery();
  const formInputs = useReactiveVar(LeadFlowFormsInputs);
  const [open, setOpen] = useState(false);
  const [regstate, setregState] = useState(null);

  function handleClose() {
    setOpen(!open);
  }
  const BankNameOptions = enumToItems(BankName);

  const formik = useFormik({
    initialValues: {
      source: {
        label: 'Bank Auction',
        value: 'BANK_AUCTION'
      } as AutoCompleteValueType,
      bankAuction: true,
      regNo: '',
      make: null,
      model: null,
      manufacturingYear: null,
      bankName: null,
      engineNo: '',
      chasisNo: '',
      status: null,
      demandedAmount: '',
      hoursMeter: '',
      ownershipType: null,
      remarks: ''
    },
    onSubmit: (values) => {
      let leadInput: LeadRef = {
        regNo: values.regNo,
        registrationState: regstate,
        source: values?.source?.value as LeadSource,
        listingStatus: ListingStatus.PendingOnWebsite,
        demandAmount: Number(values?.demandedAmount),
        ownershipType: values?.ownershipType?.value as Ownership,
        status: values?.status?.value,
        auctionByBank: values?.bankName?.value,
        vehicle: {
          vehicleBrand: {
            id: values?.make?.id
          },
          vehicleModel: {
            id: values?.model?.id
          },
          engineNumber: values?.engineNo,
          chassisNumber: values?.chasisNo,
          hoursMeter: Number(values?.hoursMeter),
          manufacturingDate: values?.manufacturingYear
        }
      };
      LeadFlowFormsInputs({ ...formInputs, regNo: values.regNo });
      console.log('bsjdfs', leadInput);
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    },

    validationSchema: LeadFormValidation
  });
  const reject = () => {
    let input: LeadRef = { regNo: formik.values.regNo };
    onPressOfDesiredOrUndesiredButton(input, '', ActionType.UNDESIRED);
  };
  const leadSource = enumToItems(LeadSource);
  const { data: stateList } = useGetAllStateQuery();
  const regNoRTOcode =
    formik?.values?.regNo &&
    formik?.values?.regNo.length > 2 &&
    formik?.values?.regNo.slice(0, 2);
  useEffect(() => {
    const regNoState = stateList?.getStateInfo?.data?.find((state) => {
      if (regNoRTOcode) {
        return state?.state_code === regNoRTOcode?.toUpperCase();
      }
      return false;
    })?.state_name;
    setregState(regNoState);
  }, [formik?.values?.regNo, stateList]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputAutoComplete
            disableClearable={true}
            onChange={(value) => {
              formik.setValues({
                ...formik.values,
                source: value,
                bankAuction: value?.value === 'BANK_AUCTION' ? true : false
              });
            }}
            value={formik.values.source}
            placeholder="Purchase Type*"
            options={leadSource}
            optionString="label"
            getOptionLabel={(option) => option.label}
          />
          {formik.errors.source && formik.touched.source ? (
            <div style={{ color: 'red' }}>{formik.errors?.source}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Input
            value={formik.values.regNo}
            name="regNo"
            onChange={(e) => {
              formik?.setFieldValue('regNo', e.target.value);
              LeadFlowFormsInputs({
                ...formInputs,
                regNo: e?.target?.value
              });
            }}
            placeholder="Registration Number*"
          />
          {formik.values.regNo &&
          (formik.errors.regNo || formik.touched.regNo) ? (
            <div style={{ color: 'red' }}>{formik.errors?.regNo}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <InputAutoComplete
            onChange={(value) => {
              formik.setValues({
                ...formik.values,
                model: null,
                make: value
              });

              GetAllNewModelsByMake({ variables: { id: value?.id } });
            }}
            value={formik?.values?.make}
            placeholder="Make*"
            options={allBrands?.queryBrand ?? []}
            optionString="name"
            getOptionLabel={(option) => option?.name}
          />
          {formik.errors.make && formik.touched.make ? (
            <div style={{ color: 'red' }}>{formik.errors?.make}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <InputAutoComplete
            value={formik.values.model}
            disabled={!formik.values.make}
            options={modelOptions?.queryBrand?.[0]?.models ?? []}
            optionString="name"
            name="model"
            onChange={(value) => {
              formik.setFieldValue('model', value);
            }}
            placeholder="Model*"
          />
          {formik.errors.model && formik.touched.model ? (
            <div style={{ color: 'red' }}>{formik.errors?.model}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year']}
              openTo="year"
              value={formik?.values?.manufacturingYear ?? null}
              onChange={(newValue) => {
                formik?.setFieldValue('manufacturingYear', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  value={formik?.values?.manufacturingYear}
                  style={{
                    width: '100%'
                  }}
                  error={false}
                  label={'Select Manufacturing Year*'}
                  placeholder={'Select Manufacturing Year'}
                />
              )}
              minDate={new Date('2000-01-01')}
              maxDate={new Date(`${new Date().getFullYear() + 1}-01-01`)}
            />
          </LocalizationProvider>
          {formik.errors.manufacturingYear &&
          formik.touched.manufacturingYear ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.manufacturingYear}
            </div>
          ) : null}
        </Grid>
        {formik?.values?.source?.value === 'BANK_AUCTION' && (
          <Grid item xs={12}>
            <InputAutoComplete
              onChange={(value) => {
                formik.setFieldValue('bankName', value);
              }}
              value={formik.values.bankName}
              placeholder="Bank Name*"
              options={BankNameOptions}
              optionString="label"
              getOptionLabel={(option) => option.label}
            />
            {formik.errors.bankName && formik.touched.bankName ? (
              <div style={{ color: 'red' }}>{formik.errors?.bankName}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'BANK_AUCTION' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.engineNo}
              name="EngNo"
              onChange={(e) =>
                formik?.setFieldValue('engineNo', e.target.value)
              }
              placeholder="Engine Number*"
            />
            {formik.values.engineNo &&
            (formik.errors.engineNo || formik.touched.engineNo) ? (
              <div style={{ color: 'red' }}>{formik.errors?.engineNo}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'BANK_AUCTION' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.chasisNo}
              name="ChasNo"
              onChange={(e) =>
                formik?.setFieldValue('chasisNo', e.target.value)
              }
              placeholder="Chassis Number*"
            />
            {formik.values.chasisNo &&
            (formik.errors.chasisNo || formik.touched.chasisNo) ? (
              <div style={{ color: 'red' }}>{formik.errors?.chasisNo}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'DEALERSHIP_SALE' && (
          <Grid item xs={12}>
            <InputAutoComplete
              value={formik.values.ownershipType}
              name="ownershipType"
              options={enumToItems(Ownership)}
              onChange={(val) => formik?.setFieldValue('ownershipType', val)}
              placeholder="Ownership Type*"
            />
            {formik.errors.ownershipType && formik.touched.ownershipType ? (
              <div style={{ color: 'red' }}>{formik.errors?.ownershipType}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'DEALERSHIP_SALE' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.hoursMeter}
              name="hoursMeter"
              onChange={(e) =>
                formik?.setFieldValue('hoursMeter', e.target.value)
              }
              placeholder="Hours Meter*"
            />
            {formik.values.hoursMeter &&
            (formik.errors.hoursMeter || formik.touched.hoursMeter) ? (
              <div style={{ color: 'red' }}>{formik.errors?.hoursMeter}</div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'DEALERSHIP_SALE' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.demandedAmount}
              name="demandedAmount"
              onChange={(e) =>
                formik?.setFieldValue('demandedAmount', e.target.value)
              }
              placeholder="Demanded Amount*"
            />
            {formik.values.demandedAmount &&
            (formik.errors.demandedAmount || formik.touched.demandedAmount) ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.demandedAmount}
              </div>
            ) : null}
          </Grid>
        )}
        {formik?.values?.source?.value === 'DEALERSHIP_SALE' && (
          <Grid item xs={12}>
            <Input
              value={formik.values.remarks}
              name="remarks"
              onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
              placeholder="Remarks"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <InputAutoComplete
            onChange={(value) => {
              formik.setFieldValue('status', value);
            }}
            value={formik.values.status}
            placeholder="Lead Status*"
            options={enumToItems(LeadBusinessStatus)}
            optionString="label"
            getOptionLabel={(option) => option.label}
          />
          {formik.errors.status && formik.touched.status ? (
            <div style={{ color: 'red' }}>{formik.errors?.status}</div>
          ) : null}
        </Grid>
      </Grid>
      <div style={{ marginBottom: '100px' }}>
        {
          <CtaButton
            desireButtonText={desireButtonText}
            undesireButtonText={undesireButtonText}
            backBtn={canGoBack}
            disabled={!(formik.isValid && formik.dirty)}
            reject={reject}
            // onPressUndesireButton={()=>      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
          />
        }
      </div>
    </form>
  );
};

export default LeadGenerate;
