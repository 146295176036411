import {
  LeadRef,
  useGetDocumentDetailsFormQuery
} from 'src/generated/hooks_and_more';
import { Divider, Box, Grid } from '@mui/material';
import { LeadCreateAndUpdateProps } from 'src/types';
import ShowTable from './DocumentDataShow';
import { Formik, useFormik } from 'formik';
import CtaButton from '../CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

function ApproveHoldBack(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    onPressOfDesiredOrUndesiredButton
  } = props;
  const { data: getLeadData, loading: getLeadLoading } =
    useGetDocumentDetailsFormQuery({
      variables: {
        id: leadId
      }
    });

  let documentAvailable = getLeadData?.getLead;
  const leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    const leadInput: LeadRef = {
      regNo: regNo,
      id: leadId
    };
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  let confirmData: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Document charges',
      value: `INR ${documentAvailable?.documentCharges} `
    },
    {
      key: 'Hold Back Charge',
      value:
        documentAvailable?.holdbackAmount !== null
          ? `INR ${documentAvailable?.holdbackAmount}`
          : 0
    }
  ];

  return (
    <>
      {getLeadLoading ? (
        <div
          style={{ padding: '30px' }}
          className="d-flex flex-column  justify-content-center align-items-center gap-2"
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Box mt={2}>
            <Grid
              container
              spacing={2}
              sx={{ border: '#C5C5C5', borderRadius: '0.5rem' }}
            >
              {confirmData?.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    key={index}
                    sx={{ display: 'flex' }}
                  >
                    <Grid
                      item
                      xs={6}
                      md={6}
                      sx={{ fontWeight: 'bold', fontSize: '18px' }}
                    >
                      {item?.key}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginLeft: 'auto',
                        fontSize: '18px'
                      }}
                    >
                      {item?.value}
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12} mt={2}>
                <ShowTable leadId={leadId} />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  width: '100%'
                }}
              >
                <CtaButton
                  desireButtonText={desireButtonText}
                  undesireButtonText={undesireButtonText}
                  disabled={false}
                  onlyRed={true}
                  reject={reject}
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    </>
  );
}

export default ApproveHoldBack;
