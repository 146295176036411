import { Box, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import { styled } from '@mui/material/styles';
import s3 from 'src/aws.config';
import { TextField, InputAdornment } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const File = styled('input')({
  display: 'none'
});
export type CType = 'image/jpeg' | 'video/mp4' | 'application/pdf';

type FileUploadToS3PropsType = {
  placeholder: string;
  value: string;
  id?: string;
  accept?: string;
  onChange?: (value: string) => void;
  onClick?: (value: string) => void;
  loading?: true;
};

function FileUploadToS3({
  placeholder,
  onClick,
  loading,
  value,
  onChange,
  id,
  accept = 'image/*,.pdf,.mp4'
}: FileUploadToS3PropsType) {
  const [uploading, setUploading] = useState(false);

  const uploadFile = (event) => {
    const file = event.target.files[0];

    const params = {
      Bucket: 'vms-app-assets-pdf',
      Key: file?.name,
      Body: file,
      ACL: 'public-read', // Optional: Set the access control level as per your requirement
      ContentType: file?.type,
      ContentDisposition: 'inline'
    };
    setUploading(true);
    s3.upload(params, (err, data) => {
      setUploading(false);

      if (err) {
        console.error(err);
        return;
      }
      onChange(data.Location);
      console.log('File uploaded successfully.', data.Location);
    });
  };

  return (
    <Fragment>
      <File
        id={id}
        type="file"
        accept={accept}
        onChange={(e) => {
          console.log(e.target.files);
          uploadFile(e);
        }}
      />
      <label htmlFor={id} className="flex-grow-1 d-flex">
        <TextField
          placeholder={placeholder}
          fullWidth
          disabled
          autoComplete="off"
          label={placeholder}
          size="small"
          value={value ? value : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment
                style={{ backgroundColor: '#f2f2f2', cursor: 'pointer' }}
                position="end"
              >
                <Box display="flex" id="fullWidth">
                  {uploading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <>
                      <Typography>Upload</Typography>
                      <UploadTwoToneIcon />
                    </>
                  )}
                </Box>
              </InputAdornment>
            )
          }}
        />
      </label>
    </Fragment>
  );
}

export default FileUploadToS3;
