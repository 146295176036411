import { Box, Button, Grid } from '@mui/material';
import {
  CentreRef,
  LeadRef,
  LeadStatus,
  useAllCentresQuery,
  useDriversQuery,
  useFormDataQuery,
  useGetAllCenterQuery
} from 'src/generated/hooks_and_more';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import * as Yup from 'yup';

import { LeadCreateAndUpdateProps } from 'src/types';
// import './form.css';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';
import Input from 'src/components/Form/Input';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Label } from '@mui/icons-material';
export const InternalTranferForm = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    undesireButtonText,
    canGoBack
  } = props;

  const {
    data: centerLists,
    loading,
    error
  } = useGetAllCenterQuery({
    fetchPolicy: 'no-cache'
  });
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const { data: drivers } = useDriversQuery();
const {data: pickck}  = useFormDataQuery({
  variables: {
    regNo: regNo
  },
  fetchPolicy: 'network-only'
})
  const formik = useFormik({
    initialValues: {
      pickupCenter:   !!pickck?.queryLead?.[0]?.centre ? {label: pickck?.queryLead?.[0]?.centre?.name, value: pickck?.queryLead?.[0]?.centre?.name, id: pickck?.queryLead?.[0]?.centre?.id} : null, 
      dropCenter: null,
      driverName: null,
      pickupDate: '',
      remarks: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      pickupCenter: Yup.object().required('Required*'),
      dropCenter: Yup.object().required('Required*'),
      driverName: Yup.object().required('Required*'),
      pickupDate: Yup.string().required('Required*')
    }),

    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
      
        internalTransfers: [
          {
            pickupDate: values?.pickupDate,
            remarks: values?.remarks,
            assignedTo: {
              name: values?.driverName?.value,
              id: values?.driverName?.id
            },
            from: {
              name: values?.pickupCenter?.value?.toString() ,
              id: values?.pickupCenter?.id
            },
            to: {
              name: values?.dropCenter?.value,
              id: values?.dropCenter?.id
            }
          }
        ]
      };

      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });



  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <DatePickerCalender
            onChange={(value) => {
              formik.setFieldValue('pickupDate', value);
            }}
            minDate={new Date()}
            placeholder="Enter the Pickup Date*"
            value={
              formik.values.pickupDate
                ? new Date(formik.values.pickupDate)
                : null
            }
          />
          {formik.errors.pickupDate && formik.touched.pickupDate ? (
            <div style={{ color: 'red' }}>{formik.errors?.pickupDate}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.pickupCenter}
            name="pickupCenter"
            disabled={true}
            options={formik.values.pickupCenter}
            optionString="label"
            // onChange={(val) => formik?.setFieldValue('pickupCenter', val)}
            placeholder="Select Pickup Center*"
          />
          {formik.errors.pickupCenter && formik.touched.pickupCenter ? (
            <div style={{ color: 'red' }}>{formik.errors?.pickupCenter}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.dropCenter}
            name="dropCenter"
            options={centerLists?.queryCentre?.map((item) => ({
              label: item?.name,
              value: item?.name,
              id: item?.id
            }))}
            optionString="label"
            onChange={(val) => formik?.setFieldValue('dropCenter', val)}
            placeholder="Select Drop Center*"
          />
          {formik.values.dropCenter &&
          formik.values.pickupCenter &&
          formik.values.dropCenter.value ===
            formik.values.pickupCenter.value ? (
            <div style={{ color: 'red' }}>
              pickup and drop location cannot be same
            </div>
          ) : formik.errors.dropCenter && formik.touched.dropCenter ? (
            <div style={{ color: 'red' }}>{formik.errors?.dropCenter}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.driverName}
            name="driverName"
            options={drivers?.queryUser?.map((item) => ({
              label: item?.name,
              value: item?.name,
              id: item?.id
            }))}
            optionString="label"
            onChange={(val) => formik?.setFieldValue('driverName', val)}
            placeholder="Select Driver For Pickup*"
          />
          {formik.errors.driverName && formik.touched.driverName ? (
            <div style={{ color: 'red' }}>{formik.errors?.driverName}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <Input
            value={formik.values.remarks}
            name="remarks"
            onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
            placeholder="Enter Your remark*"
          />
          {formik.errors.remarks && formik.touched.remarks ? (
            <div style={{ color: 'red' }}>{formik.errors?.remarks}</div>
          ) : null}
        </Grid>
      </Grid>

      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        disabled={
          !(formik.isValid && formik.dirty) ||
          (formik.values.dropCenter &&
            formik.values.pickupCenter &&
            formik.values.dropCenter.value === formik.values.pickupCenter.value)
        }
      />
    </form>
  );
};
