import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { CircularProgress, Grid } from '@mui/material';
import {
  BookingCancellationReason,
  LeadRef,
  LeadStatus,
  LoanRejectionReason,
  useDriversQuery,
  // useDriversQuery,
  useGetAllCenterQuery,
  useGetBookingInforQuery
} from 'src/generated/hooks_and_more';
import { enumToItems } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';

import { LeadCreateAndUpdateProps } from 'src/types';
import Input from 'src/components/Form/Input';
import CtaButton from '../CTA_Button/CtaButton';
import { format } from 'date-fns';
const LoanDrop = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    undesireButtonText,
    canGoBack,
    currentLead
  } = props;

  const rejectionReasonList = enumToItems(LoanRejectionReason);

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };

  const {data: activeBookingData, loading} = useGetBookingInforQuery({
    variables: {
        regNo: regNo
    }
  })
  const formik = useFormik({
    initialValues: {
      loanRejectionReason: null,
      remarks: ''
    },
    validationSchema: Yup.object({
     
      loanRejectionReason: Yup.object().required('Required*'),
      remarks: Yup.string().required('Required*')
    }),
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        activeBooking: {
          loanCancellationReason: values?.loanRejectionReason?.value,
          activeLoan: {
            loanCancelReason: values?.loanRejectionReason?.value
          }
        }
      };
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {
        loading ?  <CircularProgress sx={{
          textAlign: "center", position: "absolute", float: "right", right: "50%"
        }} size={20} /> :   <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={12} sx={{display: "flex", justifyContent: "space-around", gap: '5rem'}}>
          <Grid  item xs={6} md={6} sx={{color: "#565353", fontSize: "16px"}}>Loan Requested</Grid>
          <Grid  item xs={6} md={6} sx={{ fontSize: "16px"}}>
          {
          !!activeBookingData?.getLead?.activeBooking?.activeLoan
            ?.appliedLoanAmount
            ? activeBookingData?.getLead?.activeBooking?.activeLoan
                ?.appliedLoanAmount
            : activeBookingData?.getLead?.activeBooking?.bookingPayment
                ?.appliedLoanAmount
        }
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{display: "flex", justifyContent: "space-around", gap: '5rem'}}>
          <Grid  item xs={6} md={6} sx={{color: "#565353", fontSize: "16px"}}>Date Requested</Grid>
          <Grid  item xs={6} md={6} sx={{ fontSize: "16px"}}>
          {
          !!activeBookingData?.getLead?.activeBooking?.activeLoan?.createdAt
            ? format(
                new Date(
                  activeBookingData?.getLead?.activeBooking?.activeLoan?.createdAt,
                ),
                'dd MMM yyyy',
              )
            : !!activeBookingData?.getLead?.activeBooking?.bookingPayment
                ?.createdAt &&
              format(
                new Date(
                  activeBookingData?.getLead?.activeBooking?.bookingPayment?.createdAt,
                ),
                'dd MMM yyyy',
              )
        }
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} mt={3}>
          <InputAutoComplete
            value={formik.values.loanRejectionReason}
            name="loanRejectionReason"
            options={rejectionReasonList}
            optionString="label"
            onChange={(val) =>
              formik?.setFieldValue('loanRejectionReason', val)
            }
            placeholder="Select Loan Cancellation Reason*"
          />
          {formik.errors.loanRejectionReason &&
          formik.touched.loanRejectionReason ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.loanRejectionReason}
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12} sx={{mt: 1 }}>
          <Input
            placeholder="Remarks *"
            onChange={(e) => {
              formik.setFieldValue('remarks', e.target.value);
            }}
            value={formik.values.remarks}
          />
        </Grid>
      </Grid>
      }
    

      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        backBtn={canGoBack}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
};

export default LoanDrop;
