import { Box, Grid, Typography } from '@mui/material';
import { DocumentsCount } from 'src/generated/hooks_and_more';
export type LeadDetailCountPropsType = {
  counts?: DocumentsCount | null;
};
import './style.css';
function DocumentTable({ counts }) {
  return counts === null || counts === 0 ? null : (
    <Box className="documents">
      <Grid
        item
        sx={{
          display: 'flex',
          gap: '20px',
          alignItems: 'center'
        }}
      >
        <Typography variant="body1" fontSize="20px" gutterBottom noWrap>
          Docs Required{' '}
          <span
            className="badge"
            style={{
              backgroundColor: '#F86F15'
            }}
          >
            {counts?.required ?? 0}
          </span>
        </Typography>
        <Typography variant="body1" fontSize="20px" gutterBottom noWrap>
          Received{' '}
          <span
            className="badge"
            style={{
              backgroundColor: 'green'
            }}
          >
            {counts?.received ?? 0}
          </span>
        </Typography>
        <Typography variant="body1" fontSize="20px" gutterBottom noWrap>
          Pending{' '}
          <span
            className="badge"
            style={{
              backgroundColor: 'red'
            }}
          >
            {counts?.pending ?? 0}
          </span>
        </Typography>
      </Grid>
    </Box>
  );
}
export default DocumentTable;
