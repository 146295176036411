import React from 'react';
import { LeadDetailsPropsType } from './ApplicationDetails';
import {
  LeadSource,
  LeadStatus,
  useCheckStatusQuery
} from 'src/generated/hooks_and_more';
import { format } from 'date-fns';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';

function LogisticsDetails({ currentLead }: LeadDetailsPropsType) {
  const actualDate =
    currentLead?.source === LeadSource.DealershipSale
      ? currentLead?.statusEvents?.find(
          (e) => e?.status === LeadStatus.DeliverySelfieUploaded
        )?.createdAt
      : currentLead?.actualPickupDate;

  const regNo = currentLead?.regNo;
  const currentStatus = currentLead?.statusEvents?.[0]?.status;
  const centre = currentLead?.centre?.name;
  const deliveryCenter = currentLead?.deliveryCentre?.name;
  const driver = currentLead?.pickup?.by?.name;
  const driverPhone = currentLead?.pickup?.by?.phoneNo;
  const expectedPickUpDate = currentLead?.expectedPickupDate;
  // const actualPickupDate = findActualPickupDate();
  const deliveryDate = currentLead?.statusEvents?.find(
    (event) => event?.status === LeadStatus.DeliverySelfieUploaded
  )?.createdAt;

  const yardName = currentLead?.yard?.name;
  const yardAddress = currentLead?.yard?.address;
  const yardLocationUrl = currentLead?.yard?.locationUrl;
  const yardLat = currentLead?.yard?.location?.latitude;
  const yardLong = currentLead?.yard?.location?.longitude;
  const centreLat = currentLead?.centre?.location?.latitude;
  const centreLong = currentLead?.centre?.location?.longitude;
  const yardSpocName = currentLead?.yard?.spocName;
  const yardSpocNo = currentLead?.yard?.spocNo;

  function getUrl(lat, long) {
    return `https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}`;
  }

  function getStatus(status) {
    const value = currentLead?.statusEvents?.some(
      (i) => i.status == LeadStatus.DeliverySelfieUploaded
    );
    if(value){
      return 'Delivered'
    }

    switch (status) {
      case LeadStatus.PickupAccepted:
        return 'Accepted';
      case LeadStatus.PickupRejected:
        return 'Rejected';
      case LeadStatus.PickupRequested:
        return 'Approval Pending';
      case LeadStatus.PickupInitiated:
        return 'In Transit';
      case LeadStatus.DeliveryCompleted:
        return 'In Transit';
      case LeadStatus.DeliverySelfieUploaded:
        return 'Delivered';
      case LeadStatus.DeliveryExpensesApproved:
        return 'Delivered';
      case LeadStatus.DeliveryExpensesPaymentReceiptsUploaded:
        return 'Delivered';
      case LeadStatus.DeliveryExpensesRejected:
        return 'Delivered';
      case LeadStatus.VehicleInStock:
        return 'Delivered';
      case LeadStatus.ReadyForSale:
        return 'Delivered';
      case LeadStatus.PickupVehicleImagesUploaded:
        return 'In Transit';
      case LeadStatus.PickupParkingPaymentApproved:
        return 'In Transit';
      case LeadStatus.ParkingPaymentRejected:
        return 'In Transit';
      case LeadStatus.PickupParkingPaymentFinalDetailsAdded:
        return 'In Transit';
      case LeadStatus.AccessoriesChecklistAddedBeforeDelivery:
        return 'In Transit';
      case LeadStatus.PickupParkingPaymentReceiptsUploaded:
        return 'In Transit';
      case LeadStatus.PickupParkingPaymentFinalDetailsAdded:
        return 'In Transit';
      case LeadStatus.ParkingPaymentRejectedByFinance:
        return 'In Transit';
      case LeadStatus.AccessoriesChecklistAddedAfterDelivery:
        return 'In Transit';
      case LeadStatus.DeliveryStarted:
        return 'In Transit';
      default:
        return '-';
    }
  }
  const logisticsDetail: {
    key: string;
    value: string;
    isLink?: boolean;
    type?: string;
  }[] = [
    { key: 'Alloted centre', value: centre ?? '-' },
    { key: 'Delivery centre', value: deliveryCenter ?? '-' },
    {
      key: 'Alloted Centre Location',
      value: centreLat && centreLong ? getUrl(centreLat, centreLong) : '-',
      isLink: true,
      type: 'image'
    }, //FIXME: add centre Location URL in Centre Type
    {
      key: 'Alloted Driver',
      value: driver ?? '-'
    },
    {
      key: 'Driver Mobile',
      value: driverPhone ?? '-'
    },
    {
      key: 'Delivery Status',
      value: !!currentStatus ? getStatus(currentStatus) : '-'
    },
    {
      key: 'Expected Pickup Date',
      value: !!expectedPickUpDate
        ? format(new Date(expectedPickUpDate), 'dd-MMM-yyyy')
        : '-'
    },
    {
      key: ' Actual Pickup Date',
      value: !!actualDate ? format(new Date(actualDate), 'dd-MMM-yyyy') : '-'
    },
    {
      key: 'Delivery Date',
      value: !!deliveryDate
        ? format(new Date(deliveryDate), 'dd-MMM-yyyy')
        : '-'
    },
    {
      key: 'Yard Name',
      value: yardName ?? '-'
    },
    {
      key: ' Yard Address',
      value: yardAddress ?? '-'
    },
    {
      key: 'Yard Location URL',
      value:
        yardLat && yardLong
          ? getUrl(yardLat, yardLong) ?? '-'
          : yardLocationUrl ?? '-',
      isLink: true,
      type: 'image'
    },
    {
      key: 'Yard SPOC name',
      value: yardSpocName ?? '-'
    },
    { key: 'Yard SPOC Mobile', value: yardSpocNo ?? '-' }
  ];

  return <DetailsTabPanel data={logisticsDetail} />;
}

export default LogisticsDetails;
