import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DealDetailModal from './DealDetailModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Tooltip } from '@mui/material';
import {
  PaymentFor,
  PaymentStatus,
  UserRole
} from 'src/generated/hooks_and_more';
import PaymentDetailSubTable from './PaymentDetailSubTable';
import { getUserData } from 'src/utils/utility';
type DetailsOptions = {
  key: string;
  value?: any;
  type?: string;
  status?: string;
  textColor?: string;
  isHidden?: boolean;
  invoiceId?: string;
  metaData?: { title: string; data: { key: string; value: string | number }[] };
  reconciliation?: {
    title: string;
    data: { key: string; value: string | number; type?: string }[];
  };
  view?: boolean;
  buttonValue?: string;
};

type PartTypeProps = {
  valuePart?: () => void;
};

type DetailsTabPanelPropsType = {
  data: DetailsOptions[];
  handleClick: () => void;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const widthStyle = {
  width: '50%'
};

function PaymentListRow({ data, handleClick }: DetailsTabPanelPropsType) {
  const checkForBookingToken = data?.some((i) => i?.key === 'Booking Token');
  return (
    <TableContainer sx={{ mt: 3 }} component={Paper} style={{zIndex: 1100}}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableBody>
          {data?.map((item, index: number) => {
            return (
              <>
                {item?.key === 'Sale Amount' ? (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row" sx={widthStyle}>
                      <span className="fw-bolder fs-6 text-decoration-underline">
                        Sale Structure
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  <></>
                )}
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row" sx={widthStyle}>
                    {item.key}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" sx={widthStyle}>
                    <div
                      style={{
                        display: 'flex'
                      }}
                    >
                      {item.view === true && item?.key ? (
                        <>
                          <p style={{ width: '150px' }}> {item?.value} </p>

                          <PaymentDetailSubTable
                            metadata={item?.metaData ?? null}
                            reconciliation={item?.reconciliation}
                            invoiceId={item?.invoiceId}
                          />
                        </>
                      ) : (
                        <>
                          <p style={{ width: '150px' }}> {item?.value} </p>
                        </>
                      )}

                      {item?.status === 'APPROVED' && (
                        <Tooltip
                          title="Payment Success"
                          placement="right"
                          arrow
                        >
                          <CheckCircleIcon
                            sx={{ cursor: 'pointer' }}
                            fontSize="small"
                            color="success"
                          />
                        </Tooltip>
                      )}
                      {item?.status === 'REQUESTED' && (
                        <Tooltip
                          title="Payment Requested"
                          placement="right"
                          arrow
                        >
                          <ErrorIcon
                            sx={{ cursor: 'pointer' }}
                            fontSize="small"
                            color="warning"
                          />
                        </Tooltip>
                      )}
                      {item?.status === 'REJECTED' && (
                        <Tooltip
                          title="Payment Rejected"
                          placement="right"
                          arrow
                        >
                          <CancelIcon
                            sx={{ cursor: 'pointer' }}
                            fontSize="small"
                            color="error"
                          />
                        </Tooltip>
                      )}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>

                {item?.key === 'Balance Amount' && checkForBookingToken ? (
                  <>
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={widthStyle}
                      >
                        <span className="fw-bolder fs-6 text-decoration-underline">
                          Payment Received
                        </span>
                      </StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>
                  </>
                ) : null}
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PaymentListRow;
