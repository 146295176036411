import React, { useState } from 'react';
import { useFormik } from 'formik';
import {  Grid} from '@mui/material';
import * as Yup from 'yup';

import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import BidConfirmation from './bidForms/Confirmation';
import { enumToItems } from 'src/utils/utility';
import {
  Accessory,
  AccessoryStatus,
  ImageStage,
  LeadRef,
  LeadStatus
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

const AccessoriesUpdateForm = (props: LeadCreateAndUpdateProps) => {
  const {
    leadId,
    regNo,
    desireStatus,
    toggleDrawer,
    onPressOfDesiredOrUndesiredButton,
    desireButtonText,
    undesireButtonText,
    canGoBack
  } = props;
  const [modal, setModal] = useState(false);
  const optionsList = enumToItems(AccessoryStatus);
  const { loggedInUser } = useLoggedInUser();
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };


  const getStage = (status) => {
    switch (status) {
      case LeadStatus.AccessoriesChecklistAddedForLeadGenerated:
        return ImageStage.LeadGenerated;
      case LeadStatus.AccessoriesChecklistAddedAfterDelivery:
        return ImageStage.AfterDelivery;
      case LeadStatus.AccessoriesChecklistAddedBeforeDelivery:
        return ImageStage.BeforeDelivery;
      case LeadStatus.AccessoriesChecklistAddedBeforeInternalPickup:
        return ImageStage.TransferPickup;
      case LeadStatus.AccessoriesChecklistAddedAfterInternalDelivery:
        return ImageStage.TransferDrop;
      default:
        return null;
    }
  };
  const formik = useFormik({
    initialValues: {
      BumperStatus: null,
      hoodStatus: null,
      BatteryStatus: null,
      DrawbarStatus: null,
      topLinkStatus: null,
      frontLeftTyreStatus: null,
      frontRightTyreStatus: null,
      backLeftTyreStatus: null,
      backRightTyreStatus: null
    },
    validationSchema: Yup.object().shape({
      BumperStatus: Yup.object().required('*Required'),
      hoodStatus: Yup.object().required('*Required'),
      BatteryStatus: Yup.object().required('*Required'),
      DrawbarStatus: Yup.object().required('*Required'),
      topLinkStatus: Yup.object().required('*Required'),
      frontLeftTyreStatus: Yup.object().required('*Required'),
      frontRightTyreStatus: Yup.object().required('*Required'),
      backLeftTyreStatus: Yup.object().required('*Required'),
      backRightTyreStatus: Yup.object().required('*Required')
    }),
    onSubmit: (values) => {
      leadInput = {
        id: leadId,
        regNo: regNo,

        accessories: [
          {
            stage: getStage(desireStatus),
            regNo: regNo,
            hood: values?.hoodStatus.value,
            bumper: values?.BumperStatus.value,
            battery: values?.BatteryStatus.value,
            drawbar: values?.DrawbarStatus.value,
            toplink: values?.topLinkStatus.value,
            backRightTyre: values?.backRightTyreStatus.value,
            backLeftTyre: values?.backLeftTyreStatus.value,
            frontRightTyre: values?.frontRightTyreStatus.value,
            frontLeftTyre: values?.frontLeftTyreStatus.value
          }
        ]
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
      // setModal(true);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  const title = (status) => {
    switch (status) {
      case LeadStatus.AccessoriesChecklistAddedBeforeDelivery:
        return 'Update Accessories Status at Before Delivery';
      case LeadStatus.AccessoriesChecklistAddedAfterDelivery:
        return 'Update Accessories Status at After Delivery';
      case LeadStatus.AccessoriesChecklistAddedBeforeInternalPickup:
        return 'Update Accessories Status at Transfer Pickup';
        case LeadStatus.AccessoriesChecklistAddedAfterInternalDelivery:
          return 'Update Accessories Status at Transfer Drop';

      default:
        return 'Update Accessories Status at Lead Generated';
    }
  };
  return (
    <>
      <h4 style={{ textDecoration: 'underline', textAlign: 'center' }}>
        {title(desireStatus)}
      </h4>
      <form onSubmit={formik.handleSubmit} style={{ marginTop: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              name="BumperStatus"
              onChange={(value) => {
                formik.setFieldValue('BumperStatus', value);
              }}
              value={formik?.values?.BumperStatus}
              placeholder="Select Bumper Status"
              options={optionsList}
              optionString="label"
              getOptionLabel={(option) => option?.name}
            />
            {formik.errors.BumperStatus && formik.touched.BumperStatus ? (
              <div style={{ color: 'red' }}>{formik.errors?.BumperStatus}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              name="hoodStatus"
              onChange={(value) => {
                formik.setFieldValue('hoodStatus', value);
              }}
              value={formik?.values?.hoodStatus}
              placeholder="Select Hood Status"
              options={optionsList}
              optionString="label"
              getOptionLabel={(option) => option?.name}
            />
            {formik.errors.hoodStatus && formik.touched.hoodStatus ? (
              <div style={{ color: 'red' }}>{formik.errors?.hoodStatus}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              name="BatteryStatus"
              onChange={(value) => {
                formik.setFieldValue('BatteryStatus', value);
              }}
              value={formik?.values?.BatteryStatus}
              placeholder="Select Battery Status"
              options={optionsList}
              optionString="label"
              getOptionLabel={(option) => option?.name}
            />
            {formik.errors.BatteryStatus && formik.touched.BatteryStatus ? (
              <div style={{ color: 'red' }}>{formik.errors?.BatteryStatus}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              name="DrawbarStatus"
              onChange={(value) => {
                formik.setFieldValue('DrawbarStatus', value);
              }}
              value={formik?.values?.DrawbarStatus}
              placeholder="Select Drawbar Status"
              options={optionsList}
              optionString="label"
              getOptionLabel={(option) => option?.name}
            />
            {formik.errors.DrawbarStatus && formik.touched.DrawbarStatus ? (
              <div style={{ color: 'red' }}>{formik.errors?.DrawbarStatus}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              name="topLinkStatus"
              onChange={(value) => {
                formik.setFieldValue('topLinkStatus', value);
              }}
              value={formik?.values?.topLinkStatus}
              placeholder="Select Toplink Status"
              options={optionsList}
              optionString="label"
              getOptionLabel={(option) => option?.name}
            />
            {formik.errors.topLinkStatus && formik.touched.topLinkStatus ? (
              <div style={{ color: 'red' }}>{formik.errors?.topLinkStatus}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              name="frontLeftTyreStatus"
              onChange={(value) => {
                formik.setFieldValue('frontLeftTyreStatus', value);
              }}
              value={formik?.values?.frontLeftTyreStatus}
              placeholder="Select Front Left Tyre Status"
              options={optionsList}
              optionString="label"
              getOptionLabel={(option) => option?.name}
            />
            {formik.errors.frontLeftTyreStatus &&
            formik.touched.frontLeftTyreStatus ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.frontLeftTyreStatus}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              name="frontRightTyreStatus"
              onChange={(value) => {
                formik.setFieldValue('frontRightTyreStatus', value);
              }}
              value={formik?.values?.frontRightTyreStatus}
              placeholder="Select Front Right tyre Status"
              options={optionsList}
              optionString="label"
              getOptionLabel={(option) => option?.name}
            />
            {formik.errors.frontRightTyreStatus &&
            formik.touched.frontRightTyreStatus ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.frontRightTyreStatus}
              </div>
            ) : null}
          </Grid>

          <Grid item xs={12} md={12}>
            <InputAutoComplete
              name="backLeftTyreStatus"
              onChange={(value) => {
                formik.setFieldValue('backLeftTyreStatus', value);
              }}
              value={formik?.values?.backLeftTyreStatus}
              placeholder="Select Back Left tyre Status"
              options={optionsList}
              optionString="label"
              getOptionLabel={(option) => option?.name}
            />
            {formik.errors.backLeftTyreStatus &&
            formik.touched.backLeftTyreStatus ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.backLeftTyreStatus}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <InputAutoComplete
              name="backRightTyreStatus"
              onChange={(value) => {
                formik.setFieldValue('backRightTyreStatus', value);
              }}
              value={formik?.values?.backRightTyreStatus}
              placeholder="Select Back Right Tyre Status"
              options={optionsList}
              optionString="label"
              getOptionLabel={(option) => option?.name}
            />
            {formik.errors.backRightTyreStatus &&
            formik.touched.backRightTyreStatus ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.backRightTyreStatus}
              </div>
            ) : null}
          </Grid>
        </Grid>

        {/* <BidConfirmation
                    open={modal}
                    handleClose={() => {
                        setModal(false);
                    }}
                    onConfirmationHandler={() => {
                        console.log('hello world');
                    }}
                    heading="Accessories Confirmation"
                    textToShow="Do you want to confirm the accessories?"
                    reset={formik.resetForm}
                /> */}
        <CtaButton
          desireButtonText={desireButtonText}
          undesireButtonText={undesireButtonText}
          backBtn={canGoBack}
          disabled={!(formik.isValid && formik.dirty)}
          reject={reject}
        />
      </form>
    </>
  );
};

export default AccessoriesUpdateForm;
