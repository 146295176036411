import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './form.css';
import { Icon } from 'leaflet';
import {
  useGetDriverQuery,
  useGetYardDetailsQuery
} from 'src/generated/hooks_and_more';
import { Button, Box } from '@mui/material';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { LeadStatus } from 'src/generated/hooks_and_more';
import CtaButton from './CTA_Button/CtaButton';
import { Formik, useFormik } from 'formik';
import { ActionType } from 'src/constants/constant';
export default function Map({
  desireButtonText,
  regNo,
  leadId,
  desireStatus,
  source,
  toggleDrawer,
  undesireButtonText,
  onPressOfDesiredOrUndesiredButton
}) {
  const { data, loading } = useGetDriverQuery({
    variables: { leadId: leadId }
  });

  const cenLatitude = data?.getLead?.centre?.location?.latitude;
  const cenLongitude = data?.getLead?.centre?.location?.longitude;
  // const pos = (val) => {
  //   console.log(val, 'vvvvvvvvvvvv');
  // };
  // console.log(navigator.geolocation.getCurrentPosition(pos), 'PPPPPPPPPPPPPP');
  console.log(cenLatitude, cenLongitude, 'latitude');
  const icon = new Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/128/684/684908.png',
    iconSize: [38, 38]
  });
  const driverName = data?.getLead?.pickup?.by?.name;
  const centerAddress = data?.getLead?.centre?.address;
  const yardName = data?.getLead?.yard?.name;

  const leadInput = {
    id: leadId,
    regNo: regNo
  };
  const title = (Status) => {
    switch (Status) {
      case LeadStatus.PickupRequested:
        return 'Expected to Pickup';
      case LeadStatus.PickupAccepted:
        return 'About to Accept/Reject the Pickup';
      case LeadStatus.PickupInitiated:
        return 'Going to Initiate the Pickup';
      case LeadStatus.DeliveryStarted:
        return 'Going to Start the Delivery';
      case LeadStatus.DeliveryCompleted:
        return 'About to Finish the Delivery';

      default:
        break;
    }
  };
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };

  if (loading) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center gap-4"
        style={{ height: '100vh' }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {
        <h4 style={{ textAlign: 'center', padding: '5px' }}>
          {' '}
          {driverName} {title(desireStatus)}
        </h4>
      }

      {
        <h5 style={{ textAlign: 'center', padding: '5px' }}>
          Yard: {yardName}
        </h5>
      }
      <MapContainer center={[cenLatitude, cenLongitude]} zoom={15}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[cenLatitude, cenLongitude]} icon={icon}>
          <Popup>{centerAddress}</Popup>
        </Marker>
      </MapContainer>
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        onlyRed={true}
        reject={reject}
      />
    </form>
  );
}
