import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import {
  LeadRef,
  LoanToBeClosedBy,
  PaymentType,
  useGetDocumentDetailsFormQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../../hooks/useCreateLeadStatusEvents';
import { enumToItems } from 'src/utils/utility';
import CtaButton from '../CTA_Button/CtaButton';
import { useFormik } from 'formik';
import { ActionType } from 'src/constants/constant';

function ConfirmPurchaseRequest(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    undesireButtonText,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton
  } = props;
  const { onPressDesiredButton } = useCreateLeadStatusEvents(toggleDrawer);
  const { data, loading } = useGetDocumentDetailsFormQuery({
    variables: { id: leadId }
  });
  const loanClosedByList = enumToItems(LoanToBeClosedBy);
  const paymentType = enumToItems(PaymentType);
  const leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });

  const reject = () => {
    const leadInput: LeadRef = {
      regNo: regNo,
      id: leadId
    };
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };

  const filteredObjects = data?.getLead?.payments
    .filter((obj) => obj.for === 'DEAL_DELIVERY')
    .map((i) => ({ for: i.for, amount: i.amount }));

  const filteredToken = data?.getLead?.payments
    .filter((obj) => obj.for === 'DEAL_TOKEN')
    .map((i) => ({ for: i.for, amount: i.amount }));

    
    const remarks = data?.getLead?.statusEvents
  .filter((obj) => {
    return obj.status === 'PURCHASE_REQUEST_RAISED' && obj.remarks !== null;
  })
  .map((i) => i.remarks);

  
  const dealAmount = data?.getLead?.approvedDealAmount;
  const loanAmount =
    data?.getLead?.vehicle?.financingDetails?.pendingLoanAmount;
  const documentCharges = data?.getLead?.documentCharges;
  const holdBackAmount = !!data?.getLead?.holdbackAmount
    ? data?.getLead?.holdbackAmount
    : 0;

  const paymentAmount = !!documentCharges
    ? data?.getLead?.approvedDealAmount - documentCharges
    : data?.getLead?.approvedDealAmount;
  const payToDealer =
    data?.getLead?.vehicle?.financingDetails?.loanToBeClosedBy ===
    LoanToBeClosedBy.TractorJunction
      ? dealAmount - loanAmount - documentCharges
      : dealAmount - documentCharges;
  const payToBank =
    data?.getLead?.vehicle?.financingDetails?.loanToBeClosedBy ===
    LoanToBeClosedBy.TractorJunction
      ? loanAmount
      : 0;
    
  let purchaseOrder: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Loan To be Closed By',
      value: !Boolean(loanAmount)
        ? 0
        : data?.getLead?.vehicle?.financingDetails?.loanToBeClosedBy ===
          LoanToBeClosedBy.TractorJunction
        ? 'Tractor Junction'
        : data?.getLead?.vehicle?.financingDetails?.loanToBeClosedBy ===
          LoanToBeClosedBy.Customer
        ? 'Customer'
        : data?.getLead?.vehicle?.financingDetails?.loanToBeClosedBy ===
          LoanToBeClosedBy.Dealer
        ? 'Dealer'
        : 0
    },
    {
      key: 'Payment Type',
      value:
        data?.getLead?.payments?.[0]?.type === PaymentType.PayInFull
          ? 'Pay In Full'
          : 'Pay In Parts'
    }
  ];

  let dealStructure: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Deal Amount',
      value: data?.getLead?.approvedDealAmount
    },
    {
      key: 'Loan Amount',
      value: Boolean(loanAmount) ? loanAmount : 0
    }
  ];
  let paymentStructure: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Document Charges',
      value: data?.getLead?.documentCharges
    },

    {
      key: 'Token Amount',
      value: filteredToken?.[0]?.amount ?? 0
    },
    {
      key: 'Delivery Amount',
      value: filteredObjects?.[0]?.amount ?? 0
    },
    {
      key: 'Holdback Amount',
      value: Boolean(data?.getLead?.holdbackAmount)
        ? data?.getLead?.holdbackAmount
        : 0
    },
    {
      key: 'Payment Amount',
      value:
        data?.getLead?.payments[0]?.type === PaymentType.PayInFull
          ? paymentAmount
          : 0
    }
  ];

  let paymentBreakUp: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Pay To Dealer',
      value: payToDealer ?? 0
    },
    {
      key: 'Pay To Bank',
      value: payToBank ?? 0
    }
  ];

  let remarkRow: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Remark',
      value: Boolean(remarks?.length > 0) ? remarks[remarks?.length - 1].toString()  : 0
    }
  ];

  remarkRow = remarkRow?.filter((i) => i?.value !== 0)
  paymentBreakUp = paymentBreakUp?.filter((i) => i?.value !== 0);
  purchaseOrder = purchaseOrder?.filter((i) => i?.value !== 0);
  paymentStructure = paymentStructure?.filter((i) => i?.value !== 0);
  dealStructure = dealStructure?.filter((i) => i?.value !== 0);
  return (
    <>
    {loading ? (
        <div
          style={{ padding: '30px' }}
          className="d-flex flex-column  justify-content-center align-items-center gap-2"
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) :   <form onSubmit={formik.handleSubmit}>
      <TableContainer sx={{ backgroundColor: '#f6f6f6' }} component={Paper}>
        <Table
          sx={{
            padding: 0,
            boxShadow:
              ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }}
        >
          <TableBody sx={{ borderRadius: '1rem ' }}>
            <TableRow>
              <TableCell colSpan={2}>
                {' '}
                <h5 style={{ marginTop: '0.5rem' }}>Purchase Order</h5>
              </TableCell>
            </TableRow>
            {purchaseOrder.map((i, index) => (
              <TableRow
                key={index}
                sx={{
                  fontSize: '16px'
                }}
              >
                <TableCell width="50%">{i?.key}</TableCell>
                <TableCell width="50%">{i?.value}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}>
                {' '}
                <h5 style={{ marginTop: '0.5rem' }}>Deal Structure</h5>
              </TableCell>
            </TableRow>
            {dealStructure.map((i, index) => (
              <TableRow
                key={index}
                sx={{
                  fontSize: '16px'
                }}
              >
                <TableCell width="50%">{i?.key}</TableCell>
                <TableCell width="50%">{i?.value}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}>
                {' '}
                <h5 style={{ marginTop: '0.5rem' }}>Payment Structure</h5>
              </TableCell>
            </TableRow>
            {paymentStructure.map((i, index) => (
              <TableRow
                key={index}
                sx={{
                  fontSize: '16px'
                }}
              >
                <TableCell width="50%">{i?.key}</TableCell>
                <TableCell width="50%">{i?.value}</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell colSpan={2}>
                {' '}
                <h5 style={{ marginTop: '0.5rem' }}> Payment Breakup</h5>
              </TableCell>
            </TableRow>
            {paymentBreakUp.map((i, index) => (
              <TableRow
                key={index}
                sx={{
                  fontSize: '16px'
                }}
              >
                <TableCell width="50%">{i?.key}</TableCell>
                <TableCell width="50%">{i?.value}</TableCell>
              </TableRow>
            ))}
            {remarkRow.map((i, index) => (
              <>
                <TableRow>
                  <TableCell colSpan={2}>
                    {' '}
                    <h5 style={{ marginTop: '0.5rem' }}> {i.key}</h5>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    fontSize: '16px'
                  }}
                >
                  <TableCell width="50%">{i.value}</TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          width: '100%'
        }}
      >
        <CtaButton
          desireButtonText={desireButtonText}
          undesireButtonText={undesireButtonText}
          disabled={false}
          onlyRed={true}
          reject={reject}
        />
      </Grid>
    </form>}
     
    </>
  );
}

export default ConfirmPurchaseRequest;
