import React from 'react';
import { LeadDetailsPropsType } from './ApplicationDetails';
import { DetailsTabPanelPropsType } from 'src/types';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import format from 'date-fns/format';
import { Typography } from '@mui/material';

type InternalTransferDetailsType = {
  options: DetailsTabPanelPropsType[];
};

function InternalTransferDetails({ currentLead }: LeadDetailsPropsType) {

  // Use Array.isArray to handle the case when currentLead?.internalTransfers is not an array
  const internalTransfersData: InternalTransferDetailsType[] | undefined =
    Array.isArray(currentLead?.internalTransfers)
      ? currentLead.internalTransfers.map((transfer, index) => {
          return {
            options: [
              {
                key: `Transfer Req ${index + 1}`,
                value: '#title',
                isHidden: false
              },
              {
                key: 'Requested On',
                value: transfer?.requestedOn
                  ? format(Date.parse(transfer?.requestedOn), 'dd-MM-yyyy')
                  : '-'
              },
              {
                key:"From",
                value:transfer?.from?.name

              },
              {
                key:"To",
                value:transfer?.to?.name

              },
              {
                key: 'Pickup date',
                value: transfer?.pickupDate
                  ? format(Date.parse(transfer?.pickupDate), 'dd-MM-yyyy')
                  : '-'
              },
              { key: 'Status', value: transfer?.status },
              { key: 'Delivered By', value: transfer?.assignedTo?.name },
              {
                key: 'Delivered On',
                value: transfer?.deliveredOn
                  ? format(Date.parse(transfer?.deliveredOn), 'dd-MM-yyyy')
                  : '-'
              }
            ]
          };
        })
      : undefined;


  return (
    <>
      {internalTransfersData?.length?internalTransfersData?.map((item, index) => (
        <>
          <DetailsTabPanel key={index} data={item?.options} />
        </>
      )):    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px'
      }}
    >
      <Typography>There is no transfer request</Typography>
    </div>}
    </>
  );
}

export default InternalTransferDetails;
