import { Box, Grid, InputBase, Typography, styled } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';

import UserListRow from 'src/components/Composite/users/UserListRow';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import TableWrapper from 'src/components/Tables';
import { USER_TABLE_HEADER } from 'src/constants/constant';

import { useGetUsersLazyQuery, useGetUsersSecondLazyQuery } from 'src/generated/hooks_and_more';
import SearchIcon from '@mui/icons-material/Search';

import { useNavigate } from 'react-router-dom';
import { cachedLeadFilter } from 'src/utils/utility';

import useDebounce from 'src/customHooks/useDebounce';

const SearchWrap = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(188,196,255,0.25)',
  '&:hover': {
    backgroundColor: 'rgba(188,196,255,0.25)'
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

function Users() {
  const [searchString, setSearchString] = useState('');

  const debouncedSearch = useDebounce(searchString, 1500); // 2 seconds

  const navigate = useNavigate();

  const [GetUsers, { data, loading, error }] = useGetUsersSecondLazyQuery({
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    // if (debouncedSearch) {
    GetUsers({ variables: { name: `/.*${searchString}.*/i` } });
    // }
    // console.log(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    // GetUsers({ variables: { name: `/.*${searchString}.*/i` } });
    cachedLeadFilter(null);
  }, []);

  if (error) {
    return <div>error...</div>;
  }

  return (
    <Fragment>
      <PageTitleWrapper>
        <Grid>
          <Grid container alignItems="center" gap={10}>
            <Typography variant="h4" component="h4" gutterBottom>
              Users
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchWrap>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  onKeyUp={(e) => {
                    if (e.code === 'Enter') {
                      navigate(`?search=${searchString}`, { replace: true });
                      GetUsers({
                        variables: { name: `/.*${searchString}.*/i` }
                      });

                    }
                  }}
                  value={searchString}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </SearchWrap>
            </div>
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Box sx={{ px: 2, pb: 2 }}>
        {loading ? (
          <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : (
          <TableWrapper loading={loading} header={USER_TABLE_HEADER}>
            {data?.queryUser?.map((item, index) => {
              const value = {
                id: item?.id,
                name: item?.name,
                email: item?.email,
                role: item?.role,
                centre: item?.centre?.name,
                registrationState: !!item?.registrationState ? item?.registrationState : [] ,
                reportingManager: item?.reportingManager?.name,
                createdAt: item?.createdAt,
                updatedAt: item?.updatedAt,
                isActive: item?.isActive,
              }
              return <UserListRow key={item.id} usersData={value} />;
            })}
          </TableWrapper>
        )}
      </Box>
    </Fragment>
  );
}

export default Users;
