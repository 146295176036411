import { Table, TableCell, TableRow } from '@mui/material';
import { EnquiryStatus, Enquiry } from 'src/generated/hooks_and_more';
import Cell from './Cell';
import { EnquiriesDetailView } from 'src/types';
import { useNavigate } from 'react-router';

type EnquiryRowProps = {
  enquiriesDetails?: EnquiriesDetailView;
  header?: { title: string; search?: boolean; key?: string }[];
};

function EnquiryDetailsRow({ enquiriesDetails, header }: EnquiryRowProps) {
  const navigation = useNavigate();
  return (
    <>
      {header.map((headerItem, index) => (
        <TableRow key={`row-${index}`}>
          <TableCell key={index}>{headerItem?.title}</TableCell>
          <TableCell key={`table-${index}`}>
            <div>{enquiriesDetails?.[headerItem.key]}</div>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}

export default EnquiryDetailsRow;
