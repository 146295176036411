import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { ActionType } from 'src/constants/constant';
import {
  LeadRef,
  LeadSource,
  PaymentFor,
  PaymentStatus,
  PurchaseStatus,
  useGetLeadRefurbishmentDetailsQuery,
  useProcurementCostLazyQuery,
  useProcurementCostQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import CtaButton from '../CTA_Button/CtaButton';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import Input from 'src/components/Form/Input';
import { useReactiveVar } from '@apollo/client';
import { LeadFlowFormsInputs } from 'src/utils/utility';

function SellingPriceUpdate(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    onPressOfDesiredOrUndesiredButton,
    refurbishmentRequestId
  } = props;

  const formInputs = useReactiveVar(LeadFlowFormsInputs);

  const { data: costData, loading } = useProcurementCostQuery({
    variables: {
      leadId: leadId
    }
  });
  const dealAmount =
    costData?.getLead?.payments?.find(
      (p) =>
        p?.for === PaymentFor.DealPayment && p?.status === PaymentStatus.Done
    )?.amount ?? 0;

  const expenses = costData?.getLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.DeliveryExpense && p?.status === PaymentStatus.Done
  )?.amount;

  const parking = costData?.getLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.ParkingExpense && p?.status === PaymentStatus.Done
  )?.amount;

  const dealDelivery = costData?.getLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.DealDelivery && p?.status === PaymentStatus.Done
  )?.amount;

  const checkLeadType = costData?.getLead?.source;
  const holdbackAmount = costData?.getLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.HoldbackRepayment &&
      p?.status === PaymentStatus.Done
  )?.amount;

  const loanAmount = costData?.getLead?.payments?.find(
    (p) =>
      p?.for === PaymentFor.LoanRepayment && p?.status === PaymentStatus.Done
  )?.amount;
  const documentCharges = costData?.getLead?.documentCharges;
  const tokenAmount = costData?.getLead?.payments?.find(
    (p) => p?.for === PaymentFor.DealToken && p?.status === PaymentStatus.Done
  )?.amount;

  // const r = costData?.getLead?.payments?.filter((p, index) => {
  //   if (
  //     p?.for === PaymentFor.Refurbishment &&
  //     p?.status === PaymentStatus.Done
  //   ) {
  //     return {
  //       for: p?.for,
  //       status: p?.status,
  //       amount: p?.amount
  //     };
  //   }
  // });
  // const refCost =
  //   r?.find(
  //     (p, index) =>
  //       (r?.length ?? 1) - 1 == index &&
  //       p?.for === PaymentFor.Refurbishment &&
  //       p?.status === PaymentStatus.Done
  //   )?.amount ?? 0;

  const valueForloanAmount = loanAmount === undefined ? 0 : loanAmount;
  let dealerPayment = dealAmount - valueForloanAmount - documentCharges ?? 0;

  let refCost = 0;
  let currentSp = 0;

  const req = costData?.getLead?.refurbishmentDetails?.requests?.filter(
    (i) => i?.issue?.status === 'ISSUED'
  );

  costData?.getLead?.refurbishmentDetails?.requests?.map((i) => {
    if (i?.id === refurbishmentRequestId) {
      if (i?.issue?.status === 'ISSUED') {
        i?.issue?.items?.map((i) => {
          return (refCost = refCost + i?.price);
        });
      }
      if (
        i?.purchase?.payment?.status === PaymentStatus?.Done &&
        i?.issue?.status === 'ISSUED'
      ) {
        return (currentSp = currentSp + i?.transportationCharge);
      }
    }
  });
  const totalrefCost = currentSp + refCost;
  let sum = 0;
  let totalSP = 0;
  const transportationCharges =
    costData?.getLead?.refurbishmentDetails?.requests?.filter(
      (i) => i?.id === refurbishmentRequestId
    )?.[0]?.transportationCharge;

  req?.map((item) => {
    item?.issue?.items?.map((i) => {
      return (sum = sum + i?.price);
    });
  });

  costData?.getLead?.refurbishmentDetails?.requests?.filter((i) => {
    if (
      i?.purchase?.payment?.status === PaymentStatus?.Done &&
      i?.issue?.status === 'ISSUED'
    ) {
      totalSP = totalSP + i?.transportationCharge;
    }
  });
  const lastRefCost = sum + totalSP - totalrefCost;

  const total = dealAmount + parking + totalrefCost;
  // const refCost =
  // costData?.getLead?.payments?.find(
  //   (p, index) =>
  //     (costData?.getLead?.payments?.length ?? 1) - 1 == index &&
  //     p?.for === PaymentFor.Refurbishment &&
  //     p?.status === PaymentStatus.Done
  // )?.amount ?? 0;
  const lastSubLast = costData?.getLead?.refurbishmentDetails?.requests?.filter(
    (i) => i?.id === refurbishmentRequestId
  );

  // costData?.getLead?.payments?.map((i) => {
  //   if (
  //     i?.for === PaymentFor?.Refurbishment &&
  //     i?.status === PaymentStatus?.Done
  //   ) {
  //     sum = i?.amount + sum;
  //     return sum;
  //   }
  // });

  const totalBankAuction = Number(
    (!!dealAmount ? dealAmount : 0) +
      (!!expenses ? expenses : 0) +
      (!!parking ? parking : 0) +
      (!!refCost ? refCost : 0) +
      (!!lastRefCost ? lastRefCost : 0)
  );

  const totalDealership =
    (!!dealDelivery ? dealDelivery : 0) +
    (Boolean(tokenAmount > 0) ? Number(tokenAmount) : 0) +
    (!!expenses ? Number(expenses) : 0) +
    (!!holdbackAmount ? holdbackAmount : 0) +
    (!!loanAmount ? loanAmount : 0) +
    (!!totalrefCost ? totalrefCost : 0) +
    (!!lastRefCost ? lastRefCost : 0) +
    (Boolean(dealerPayment > 0) ? Number(dealerPayment) : 0);

  const formik = useFormik({
    initialValues: {
      sellingPrice: null,
      totalCost:
        checkLeadType === 'BANK_AUCTION' ? totalBankAuction : totalDealership
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      totalCost: Yup.number(),
      sellingPrice: Yup.number()
        .moreThan(
          Yup.ref('totalCost'),
          '*Selling Price Should be Greater than Procurement Cost'
        )
        .required('*Required')
    }),
    onSubmit: (values) => {
      LeadFlowFormsInputs({
        ...formInputs,
        sellingPrice: values.sellingPrice
      });
      let leadInput: LeadRef = {
        regNo: regNo,
        id: leadId,
        sellingPrice: Number(values?.sellingPrice)
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    let leadInput: LeadRef = {
      regNo: regNo,
      id: leadId
    };
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };

  let bankDataList: {
    key: string;
    value: any;
  }[] = [
    {
      key: 'Deal Payment',
      value: dealAmount ?? '-'
    },
    {
      key: 'Parking Payment',
      value: parking ?? '-'
    },
    {
      key: ' Refurbishment Payment',
      value: lastRefCost ?? 0
    },
    {
      key: 'Total',
      value: totalBankAuction ?? 0
    }
  ];

  let dealerShipDataList: {
    key: string;
    value: any;
  }[] = [
    {
      key: !holdbackAmount ? 'Dealer Payment' : null,
      value:
        Boolean(dealerPayment) && !Boolean(holdbackAmount)
          ? dealerPayment < 0
            ? 0
            : dealerPayment
          : '-' ?? '-'
    },
    {
      key: 'Delivery Payment',
      value: dealDelivery ?? '-'
    },
    {
      key: 'Token Payment',
      value: tokenAmount ?? '-'
    },

    {
      key: 'HoldBack Payment',
      value: holdbackAmount ?? '-'
    },
    {
      key: 'Loan Payment',
      value: loanAmount ?? '-'
    },
    {
      key: 'Refurbishment Payment',
      value: lastRefCost ?? 0
    },
    {
      key: 'Total',
      value: totalDealership ?? 0
    }
  ];

  dealerShipDataList = dealerShipDataList?.filter((i) => i.key !== null);
  bankDataList = bankDataList?.filter((i) => i.value !== 0);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item xs={12} md={12} sx={{ mb: 4 }}>
        <Input
          placeholder="Enter Selling Price *"
          onChange={(e) => {
            formik.setFieldValue('sellingPrice', e.target.value);
          }}
          value={formik.values.sellingPrice}
        />
        {formik.values.sellingPrice &&
        (formik.errors.sellingPrice || formik.touched.sellingPrice) ? (
          <div style={{ color: 'red' }}>{formik.errors?.sellingPrice}</div>
        ) : null}
      </Grid>
      <h5>Procurement Cost Breakup</h5>
      {loading ? (
        <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
          <h5>Loading..</h5>
        </div>
      ) : (
        <TableContainer
          sx={{ mt: 4, backgroundColor: '#f6f6f6' }}
          component={Paper}
        >
          <Table>
            <TableBody>
              {checkLeadType === 'BANK_AUCTION' ? (
                <>
                  {' '}
                  {bankDataList.map((i, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        fontSize: '16px'
                      }}
                    >
                      <TableCell width="50%">{i?.key}</TableCell>
                      <TableCell width="50%">{i?.value}</TableCell>
                    </TableRow>
                  ))}{' '}
                </>
              ) : (
                <>
                  {' '}
                  {dealerShipDataList.map((i, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        fontSize: '16px'
                      }}
                    >
                      <TableCell width="50%">{i?.key}</TableCell>
                      <TableCell width="50%">{i?.value}</TableCell>
                    </TableRow>
                  ))}{' '}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        undesireButtonText={undesireButtonText}
        disabled={!(formik.isValid && formik.dirty)}
        reject={reject}
      />
    </form>
  );
}

export default SellingPriceUpdate;
