import {
  Box,
  Button,
  CardContent,
  Card,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import Input from 'src/components/Form/Input';
import { useState } from 'react';
import {
  useUpdateActiveBookingSaleAmountMutation,
  useUpdateLoanMutation,
  UpdateActiveBookingSaleAmountMutationVariables,
  useGetBookingQuery,
  useAddLogsMutation,
  ActionType,
  ModuleType,
  TaskStatus
} from 'src/generated/hooks_and_more';
import { useLocation } from 'react-router';
import ConfimationModal from 'src/components/Composite/ConfirmationModal/ConfimationModal';
import { useToasts } from 'react-toast-notifications';
import { ActiveBookingDetails } from 'src/types';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import {
  EditActiveBookingAmount,
  EditBookingRequestedAmount
} from 'src/utils/schema';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';

function BookingEdit() {
  const [open, setOpen] = useState(false);
  const [on, SetOn] = useState(false);
  const { addToast } = useToasts();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const Edit = 'Save';
  const title = 'Booking';
  const [updateBookingAmount] = useUpdateActiveBookingSaleAmountMutation();
  const [updateActiveLoan] = useUpdateLoanMutation();
  const location = useLocation();
  const lead: ActiveBookingDetails = location.state as ActiveBookingDetails;
  const [AddLogs] = useAddLogsMutation();
  const [AddLogsFOr] = useAddLogsMutation();

  const [variables, setVariable] =
    useState<UpdateActiveBookingSaleAmountMutationVariables>(null);
  const { data: bookingData, loading } = useGetBookingQuery({
    variables: { id: lead?.activeBookingId || lead?.archiveBookingId }
  });
  // const [variables, setActiveVariable] = useState<UpdateLoanMutationVariables>(null)

  const initialValues = {
    id: bookingData?.queryBooking?.[0]?.bookingPayment?.id ?? '',
    saleAmount: bookingData?.queryBooking?.[0]?.bookingPayment?.saleAmount ?? 0,
    activeLoanId: bookingData?.queryBooking?.[0]?.activeLoan?.id,
    fieldInspectionDate: bookingData?.queryBooking?.[0]?.activeLoan
      ?.fieldInspectionDate
      ? new Date(
        bookingData?.queryBooking?.[0]?.activeLoan?.fieldInspectionDate
      )
      : null,
    loginDate: bookingData?.queryBooking?.[0]?.activeLoan?.loginDate
      ? new Date(bookingData?.queryBooking?.[0]?.activeLoan?.loginDate)
      : null,
    deliveryOrderDate: bookingData?.queryBooking?.[0]?.activeLoan
      ?.deliveryOrderDate
      ? new Date(bookingData?.queryBooking?.[0]?.activeLoan?.deliveryOrderDate)
      : null,
    appliedLoanAmount:
      bookingData?.queryBooking?.[0]?.activeLoan?.appliedLoanAmount,
    fieldInspectionConfirmedDate: bookingData?.queryBooking?.[0]?.activeLoan
      ?.fieldInspectionConfirmedDate
      ? new Date(
        bookingData?.queryBooking?.[0]?.activeLoan?.fieldInspectionConfirmedDate
      )
      : null
    // createdAt: new Date(bookingData?.queryBooking?.[0]?.createdAt)
  };
  const { loggedInUser } = useLoggedInUser();


  const onConfirmationHandler = (reset: () => void) => {
    updateBookingAmount({
      variables,
      onCompleted(data) {
        handleClose();
        AddLogs({
          variables: {
            taskPerformedBy: { id: loggedInUser?.userId },
            actionType: ActionType.Update,
            module: ModuleType.Booking,
            createdAt: new Date(),
            sourceUpdated: variables?.saleAmount.toString(),
            updatedValue: JSON.stringify({
              data: {
              saleAmount:  variables?.saleAmount
              }
            }),
            previousValue: JSON.stringify({
              data: {
                saleAmount:  initialValues?.saleAmount
              }
            }),
            status: TaskStatus.Success
          },
        });
        handleClose();
        addToast('Updated Successfully!!!', {
          appearance: 'success',
          autoDismiss: true
        });
      },
      onError(err) {
        handleClose();

        if (err?.message?.includes('already exists')) {
          handleClose();
          addToast('Already Exist!!!', {
            appearance: 'error',
            autoDismiss: true
          });
        } else {
          addToast('Something went wrong!!!', {
            appearance: 'error',
            autoDismiss: true
          });
        }
      }
    });
  };
  if (loading) return null;

  return (
    <Box sx={{ mt: 3 }} display={'flex'} justifyContent={'center'}>
      <Box width={'80%'}>
        <Card>
          <Typography sx={{ my: 1, mx: 2 }} variant="h3">
            {title}
          </Typography>
          <Divider />
          <CardContent>
            <Formik
              initialValues={initialValues}
              validationSchema={EditActiveBookingAmount}
              onSubmit={(values) => {
                const variables = {
                  id: values?.id ?? '',
                  saleAmount: Number(values?.saleAmount ?? 0)
                };
                updateBookingAmount({
                  variables,
                  onCompleted(data) {
                    
                    handleClose();
                    AddLogs({
                      variables: {
                        taskPerformedBy: { id: loggedInUser?.userId },
                        actionType: ActionType.Update,
                        module: ModuleType.Booking,
                        createdAt: new Date(),
                        sourceUpdated: variables?.saleAmount.toString(),
                        updatedValue: JSON.stringify({
                          data: {
                          saleAmount:  variables?.saleAmount
                          }
                        }),
                        previousValue: JSON.stringify({
                          data: {
                            saleAmount:  initialValues?.saleAmount
                          }
                        }),
                        status: TaskStatus.Success
                      },
                      onCompleted(data){
                        console.log(data, "PPPPP")
                      },
                      onError(err){
                        console.log(err, "LLLLL")
                      }
                    });
                    console.log(data, 'ggggggggggg');

                    addToast('Updated Successfully!!!', {
                      appearance: 'success',
                      autoDismiss: true
                    });
                  },
                  
                  onError(err) {

                    addToast('Something went wrong!!!', {
                      appearance: 'error',
                      autoDismiss: true
                    });
                  }
                });
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
                resetForm
              }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <Input
                          value={values.saleAmount}
                          name="saleAmount"
                          onChange={handleChange}
                          placeholder="Sale amount"
                        />
                        {errors.saleAmount && touched.saleAmount ? (
                          <div style={{ color: 'red' }}>
                            {errors?.saleAmount}
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        sx={{ mt: 0, mb: 5 }}
                        variant="contained"
                        type="submit"
                      >
                        {Edit}
                      </Button>
                    </div>
                    <ConfimationModal
                      textToShow="Do you want to update the sale amount ?"
                      open={open}
                      handleClose={handleClose}
                      toastMsg={on}
                      onConfirmationHandler={onConfirmationHandler}
                      reset={resetForm}
                    />
                  </Form>
                );
              }}
            </Formik>
            {lead?.activeLoanId ? (
              <Formik
                initialValues={initialValues}
                validationSchema={EditBookingRequestedAmount}
                onSubmit={(values) => {
                  updateActiveLoan({
                    variables: {
                      id: values?.activeLoanId ?? '',
                      appliedLoanAmount: Number(values?.appliedLoanAmount),
                      fieldInspectionDate: values?.fieldInspectionDate,
                      loginDate: values?.loginDate,
                      deliveryOrderDate: values?.deliveryOrderDate,
                      fieldInspectionConfirmedDate:
                        values?.fieldInspectionConfirmedDate
                    },
                    onCompleted(data) {
                      AddLogsFOr({
                        variables: {
                          taskPerformedBy: { id: loggedInUser?.userId },
                          actionType: ActionType.Update,
                          module: ModuleType.Booking,
                          createdAt: new Date(),
                          sourceUpdated:  bookingData?.queryBooking?.[0]?.bookingPayment?.id ,
                          updatedValue: JSON.stringify({
                            data: {
                              id: values?.activeLoanId ?? '',
                              appliedLoanAmount: Number(values?.appliedLoanAmount),
                              fieldInspectionDate: values?.fieldInspectionDate,
                              loginDate: values?.loginDate,
                              deliveryOrderDate: values?.deliveryOrderDate,
                              fieldInspectionConfirmedDate:
                                values?.fieldInspectionConfirmedDate
                            }
                          }),
                          previousValue: JSON.stringify({
                            data: {
                              id: initialValues?.activeLoanId ?? '',
                              appliedLoanAmount: Number(initialValues?.appliedLoanAmount),
                              fieldInspectionDate: initialValues?.fieldInspectionDate,
                              loginDate: initialValues?.loginDate,
                              deliveryOrderDate: initialValues?.deliveryOrderDate,
                              fieldInspectionConfirmedDate:
                              initialValues?.fieldInspectionConfirmedDate
                            }
                          }),
                          status: TaskStatus.Success
                        },
                        onCompleted(data){
                          console.log(data, "PPPPP")

                        },
                        onError(err){
                          console.log(err, "errorrrr")
                        }
                      });
                      addToast('Updated Successfully!!!', {
                        appearance: 'success',
                        autoDismiss: true
                      });
                    },
                    onError(err) {
                      addToast('Error Successfully!!!', {
                        appearance: 'success',
                        autoDismiss: true
                      });
                    }
                  });
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  setFieldValue,
                  resetForm
                }) => {
                  return (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <Input
                            value={values.appliedLoanAmount}
                            name="appliedLoanAmount"
                            onChange={handleChange}
                            placeholder="Requested Amount"
                          />
                          {errors.appliedLoanAmount &&
                            touched.appliedLoanAmount ? (
                            <div style={{ color: 'red' }}>
                              {errors?.appliedLoanAmount}
                            </div>
                          ) : null}
                        </Grid>

                        <Grid item xs={6} md={6}>
                          <DatePickerCalender
                            placeholder="Login Date"
                            value={values.loginDate}
                            onChange={(e) => {
                              setFieldValue('loginDate', e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <DatePickerCalender
                            placeholder="DO Date "
                            value={values.deliveryOrderDate}
                            onChange={(e) => {
                              setFieldValue('deliveryOrderDate', e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <DatePickerCalender
                            placeholder="FI Date "
                            value={values.fieldInspectionDate}
                            onChange={(e) => {
                              setFieldValue('fieldInspectionDate', e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <DatePickerCalender
                            placeholder="FI confirm date"
                            value={values.fieldInspectionConfirmedDate}
                            onChange={(e) => {
                              setFieldValue('fieldInspectionConfirmedDate', e);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ my: 3 }} />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Button variant="contained" type="submit">
                          {Edit}
                        </Button>
                      </div>
                      <ConfimationModal
                        textToShow="Do you want to update the sale amount ?"
                        open={open}
                        handleClose={handleClose}
                        toastMsg={on}
                        onConfirmationHandler={onConfirmationHandler}
                        reset={resetForm}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : null}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default BookingEdit;
