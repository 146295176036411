import {
  IconButton,
  TableCell,
  TableCellProps,
  TableRow,
  Typography
} from '@mui/material';
import {
  User,
  useDealershipLeadCountByPeNameLazyQuery
} from 'src/generated/hooks_and_more';
import { titleCaseToReadable } from 'src/utils/utility';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import routeConstant from 'src/constants/routeContant';
import format from 'date-fns/format';
import { UserType } from 'src/types';

interface UserListRowType {
  usersData: UserType;
}
type RowItemsType = {
  value: string | Number | boolean | string[];
  align?: TableCellProps['align'];
};

function UserListRow({ usersData }: UserListRowType) {
  const navigate = useNavigate();
  const users: RowItemsType[] = [
    {
      value: usersData.name
    },
    {
      value: usersData.email
    },

    {
      value: titleCaseToReadable(usersData.role) ?? '-'
    },
    {
      value: titleCaseToReadable(usersData?.registrationState?.join(', '))
    },
    {
      value: usersData?.centre ?? '-'
    },
    {
      value: usersData?.createdAt
        ? format(Date.parse(usersData?.createdAt), 'dd-MM-yy')
        : '-'
    },
    {
      value: usersData?.updatedAt
        ? format(Date.parse(usersData?.updatedAt), 'dd-MM-yy')
        : '-'
    },
    {
      value: usersData?.reportingManager
    },

    {
      value: usersData?.isActive ? 'Active' : 'Inactive'
    },
    {
      value: 'edit'
    }
  ];

  return (
    <>
      <TableRow hover sx={{ cursor: 'pointer' }}>
        {users.map((item, index) => {
          return (
            <TableCell  style={{fontSize: '14px', fontWeight: 400}} align={item?.align ?? 'left'} key={index}>
              
                {item.value !== 'edit' ? (
                  !!item?.value ? item?.value : '-'
                ) : (
                  <IconButton
                    onClick={() => {
                      navigate(
                        `${routeConstant.USERS}/${routeConstant.EDIT_USERS}`,
                        {
                          state: usersData?.id
                        }
                      );
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
             
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
}

export default UserListRow;
