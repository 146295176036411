import { ApolloClient, InMemoryCache } from '@apollo/client';
import { Environments, environments } from './config';

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value

let url: string;

if (environments === Environments.STAGING) {
  url = 'https://vms-alb.tractorjunction.in:444/graphql';
} else if (environments === Environments.DEVELOPMENT) {
  url = 'http://35.154.33.195:8080/graphql';
} else if (environments === Environments.PRODUCTION) {
  url = 'https://vms-alb.tractorjunction.in/graphql';
} else {
  url = 'https://13e8-115-245-193-141.ngrok-free.app/graphql';
}

export const client = new ApolloClient({
  uri: url,
  // cache: new InMemoryCache(),
  cache: new InMemoryCache()
});
// const cache = new InMemoryCache({
//   typePolicies
// });
// live url
// https://vms-alb.tractorjunction.in/graphql
