import { useFormik } from 'formik';
import { Grid, Table, TableCell, TableHead, TableRow } from '@mui/material';
import * as yup from 'yup';
import Input from 'src/components/Form/Input';
import { LeadRef, useGetLeadDetailQuery } from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import '../form.css';
import CtaButton from '../CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';
import { LeadFlowFormsInputs, formatDate } from 'src/utils/utility';
import { useReactiveVar } from '@apollo/client';

function FinalBidAmountForm(props: LeadCreateAndUpdateProps) {
  const {
    leadId,
    regNo,
    desireButtonText,
    undesireButtonText,
    onPressOfDesiredOrUndesiredButton,
    canGoBack
  } = props;

  const { data, loading } = useGetLeadDetailQuery({
    variables: {
      regNo: regNo
    }
  });
  const formInputs = useReactiveVar(LeadFlowFormsInputs);
  const formik = useFormik({
    initialValues: {
      finalBidAmount: ''
    },
    onSubmit: (values) => {
      let leadInput: LeadRef = {
        id: leadId,
        regNo: regNo,
        finalBidAmount: values?.finalBidAmount
          ? Number(values?.finalBidAmount)
          : null,
        auctionHistory: [
          {
            createdAt: new Date(),
            amount: values?.finalBidAmount
              ? Number(values?.finalBidAmount)
              : null
          }
        ]
      };

      onPressOfDesiredOrUndesiredButton(leadInput);
      console.log(leadInput);
    },

    validationSchema: yup.object().shape({
      finalBidAmount: yup
        .string()
        .required('*Required')
        .matches(/^[0-9]+$/, '*Numbers only Allowed')
        .max(9, '*Maximum value should be in 9 digits')
    })
  });

  const reject = () => {
    let leadInput: LeadRef = {
      id: leadId,
      regNo: regNo,
      finalBidAmount: formik.values?.finalBidAmount
        ? Number(formik.values?.finalBidAmount)
        : null,
      auctionHistory: [
        {
          createdAt: new Date(),
          amount: formik?.values?.finalBidAmount
            ? Number(formik?.values?.finalBidAmount)
            : null
        }
      ]
    };
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Input
              name="finalBidAmount"
              placeholder="Enter Final Bid Amount"
              value={formik.values.finalBidAmount}
              onChange={(event) => {
                formik.setFieldValue('finalBidAmount', event.target.value);
                LeadFlowFormsInputs({
                  ...formInputs,
                  finalBidAmount: event?.target?.value
                });
              }}
            />
            {formik.values.finalBidAmount &&
            (formik?.touched.finalBidAmount || formik.errors.finalBidAmount) ? (
              <div style={{ color: 'red' }}>
                {formik?.errors?.finalBidAmount}
              </div>
            ) : null}
          </Grid>
        </Grid>
        <CtaButton
          desireButtonText={desireButtonText}
          backBtn={canGoBack}
          undesireButtonText={undesireButtonText}
          greenRedcombo={true}
          disabled={!(formik.isValid && formik.dirty)}
          reject={reject}
        />
      </form>

      {Boolean(data?.getLead?.auctionHistory.length > 0) && (
        <Grid>
          <Grid sx={{mt: 4}}>
            <h5>Auction History</h5>
          </Grid>
          <Table>
            <TableHead>
              <TableCell>Bid Amount</TableCell>
              <TableCell>Date</TableCell>
            </TableHead>
            {data?.getLead?.auctionHistory?.map((i) => (
              <>
                <TableRow >
                  <TableCell style={i?.status === false ? {color: 'red'}: {color: 'green'}}>{i?.amount}</TableCell>
                  <TableCell style={i?.status === false ? {color: 'red'}: {color: 'green'}}>{formatDate(i?.createdAt)}</TableCell>
                </TableRow>
              </>
            )).reverse()}
          </Table>
        </Grid>
      )}
    </>
  );
}
export default FinalBidAmountForm;
