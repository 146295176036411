import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { titleCaseToReadable } from 'src/utils/utility';
import { Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './DealDetailModal.css';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1
};
const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  cursor: 'pointer'
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
export default function DealDetailModal({ metadata }: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <p onClick={handleOpen} style={{ color: "blue", cursor: "pointer", fontWeight: "bold" }}>View </p>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} style={{ overflow: "auto", maxHeight: "80%" }}>
          <Box sx={closeBtnStyle}>
            <Typography
              id="custom-underline"
              fontWeight="bold"
              sx={{ fontSize: '22px' }}
            >
              {metadata?.title ? titleCaseToReadable(metadata?.title) : '-'}
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>

          {metadata?.data?.map((item, index) => {
            return (
              <>
                <StyledTableRow>
                  <StyledTableCell
                    id="keep-mounted-modal-title"
                    sx={{ width: '300px' }}
                  >
                    {item.key}
                  </StyledTableCell>

                  {item?.type === 'url' && item.value !== '-' ? (
                    <StyledTableCell
                      id="keep-mounted-modal-description"
                      sx={{ width: '300px' }}
                    >
                      <Link
                        href={`${item.value}`}
                        style={{
                          color: 'rgb(17,25,42)',
                          textDecoration: 'underline'
                          // display: 'flex',
                          // justifyContent: 'flex-start'
                        }}
                        underline="hover"
                        target="_blank"
                      >
                        View Document
                      </Link>
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell
                      id="keep-mounted-modal-description"
                      sx={{ width: '300px' }}
                    >
                      {item.value ?? '-'}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              </>
            );
          })}
        </Box>
      </Modal>
    </div>
  );
}
