import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import React from 'react';
import {
  LeadRef,
  LeadStatus,
  useGetLeadDetailForAdminQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { format } from 'date-fns';
import CtaButton from './CTA_Button/CtaButton';
import { useFormik } from 'formik';
import { ActionType } from 'src/constants/constant';

function StockIn(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    onPressOfDesiredOrUndesiredButton
  } = props;

  const { data } = useGetLeadDetailForAdminQuery({
    variables: { leadId: leadId }
  });

  const queryLead =
    data && data.queryLead && data.queryLead.length && data.queryLead[0];

  const firstEvent = queryLead?.statusEvents?.filter(
    (item) => item?.status === LeadStatus?.LeadGenerated
  );

  const createdBy =
    firstEvent && firstEvent.length && firstEvent?.[0]?.createdBy?.name;
  const createAt =
    firstEvent && firstEvent.length && firstEvent?.[0]?.createdAt;

  const deliveryComplete = queryLead?.statusEvents?.filter(
    (item) => item?.status === LeadStatus?.DeliverySelfieUploaded
  );

  const driverName =
    deliveryComplete &&
    deliveryComplete.length &&
    deliveryComplete?.[0]?.createdBy?.name;
  const deliveryDate =
    deliveryComplete &&
    deliveryComplete.length &&
    deliveryComplete?.[0]?.createdAt;

  const center = queryLead?.centre?.name;

  const procurementDetails: {
    key: string;
    value: string;
    isHidden?: boolean;
  }[] = [
    { key: 'Registration no ', value: data?.queryLead?.[0]?.regNo ?? '-' },
    { key: 'Sourced by  ', value: createdBy },
    {
      key: 'Sourced on ',
      value: createAt ? format(new Date(createAt), 'dd-MMM-yyyy') : '-'
    },
    { key: 'Delivered by ', value: driverName },
    {
      key: 'Delivered on ',
      value: deliveryDate ? format(new Date(deliveryDate), 'dd-MMM-yyyy') : '-'
    },
    { key: 'Delivered at ', value: center ?? '-' }
  ];
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      leadInput = {
        id: leadId,
        regNo: regNo
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <h6>Procurement Details</h6>
      <TableContainer
        sx={{ mt: 4, backgroundColor: '#f6f6f6' }}
        component={Paper}
      >
        <Table>
          <TableBody>
            {procurementDetails?.map((item, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ fontWeight: 600 }} component="th" scope="row">
                  {item?.key}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }} align="right">
                  {item.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        backBtn={canGoBack}
        disabled={false}
        reject={reject}
      />
    </form>
  );
}

export default StockIn;
