import {
  forwardRef,
  Ref,
  useState,
  ReactElement,
  ChangeEvent,
  useEffect,
  useRef
} from 'react';
import {
  Link,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import useDebounce from 'src/customHooks/useDebounce';
import { useGetLeadForSearchLazyQuery } from 'src/generated/hooks_and_more';
import { useLocation, useNavigate } from 'react-router-dom';
import Progress from 'src/components/Progress';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`
);

const DialogTitleWrapper = styled(DialogTitle)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

function HeaderSearch() {
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce(searchValue, 1500); // 2 seconds
  const [GetLeadForSearch, { data, loading }] = useGetLeadForSearchLazyQuery();
  const location = useLocation();
  const ref = useRef<HTMLInputElement | null>(null);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchResults) {
        setOpenSearchResults(true);
      }
    } else {
      setOpenSearchResults(false);
    }
  };

  const [open, setOpen] = useState(false);
  const navigate = useNavigate()

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (debouncedSearch) {
      GetLeadForSearch({
        variables: { searchString: `/.*${debouncedSearch}.*/i` }
      });
    }
    console.log(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    if (ref && ref?.current) {
      ref.current.focus();
    }
  }, [ref]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {location.pathname.includes('vms-admin/leads') ? (
        <IconButton color="primary" onClick={handleClickOpen}>
          <SearchTwoToneIcon />
        </IconButton>
      ) : null}
      {open && (
        <DialogWrapper
          open={open}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth
          scroll="paper"
          onClose={handleClose}
          sx={{ marginLeft: '200px', marginTop: '70px' }}
        >
          <DialogTitleWrapper>
            <SearchInputWrapper
              value={searchValue}
              autoFocus={true}
              ref={ref}
              size="small"
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
              placeholder="Search terms here..."
              fullWidth
              label="Search"
            />
          </DialogTitleWrapper>
          <Divider />
          {loading ? (
            <Progress />
          ) : (
            openSearchResults && (
              <DialogContent>
                <Box>
                  {data?.queryLead?.map((item) => {
                    return (
                      <>
                        <Box
                          key={item.regNo}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography
                            onClick={() => {
                              navigate(`/vms-admin/leads/${item.regNo}`);
                              setOpenSearchResults(false)
                              setOpen(false)
                              setSearchValue("")
                            }}
                            sx={{ fontWeight: 'bold', cursor:"pointer" }}
                            variant="h5"
                          >
                            {item.regNo}
                          </Typography>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                      </>
                    );
                  })}
                </Box>
              </DialogContent>
            )
          )}
        </DialogWrapper>
      )}
    </>
  );
}

export default HeaderSearch;
