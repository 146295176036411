import React, { useEffect, useState } from 'react';

function useLoggedInUser() {
  const [loggedInUser, setLoggedInUser] = useState(null);

  function setUserToken(key, value) {
    console.log(value, '<<<<<<<<<<<<<<,,');
    localStorage.setItem('userData', JSON.stringify(value));
  }
  useEffect(() => {
    const data = localStorage.getItem('userData');
    setLoggedInUser(JSON.parse(data));
  }, [localStorage]);

  return { loggedInUser, setLoggedInUser, setUserToken };
}

export default useLoggedInUser;
