import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import Input from 'src/components/Form/Input';
import {
  BankName,
  Lead,
  LeadBusinessStatus,
  LeadRef,
  LeadSource,
  LeadStatus,
  Ownership,
  useGetAllActiveStateQuery,
  useGetAllNewBrandsQuery,
  useGetAllNewModelsByMakeLazyQuery,
  useGetAllNewModelsByMakeQuery,
  useGetAllStateQuery,
  useGetBookingInforQuery,
  useGetDeliveryPaymentQuery,
  useGetDistrictsByStateLazyQuery,
  useLeadUpdateQuery,
  useModelByMakeLazyQuery
} from 'src/generated/hooks_and_more';
import { enumToItems, titleCaseToReadable } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';

import { AutoCompleteValueType, LeadCreateAndUpdateProps } from 'src/types';
import './form.css';

import { ActionType } from 'src/constants/constant';
import {
  DealershipFormValidation,
  LeadFormValidation
} from '../ValidationSchema/ValidationSchema';
import CtaButton from './CTA_Button/CtaButton';
import LeadDetail from 'src/screens/leadDetail';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
const BookingUpdation = (props: LeadCreateAndUpdateProps) => {
  const {
    desireButtonText,
    undesireButtonText,
    toggleDrawer,
    refetch,
    onPressOfDesiredOrUndesiredButton,
    canGoBack,
    leadId,
    regNo
  } = props;

  const { data: stateOption } = useGetAllActiveStateQuery({
    fetchPolicy: 'no-cache'
  });

    const [getDistricts, { data: districtOptions }] =
    useGetDistrictsByStateLazyQuery();

  const { data: leadData, loading } = useGetBookingInforQuery({
    variables: { regNo: regNo },
    onCompleted() {
      const customerNameFilled =
        leadData?.getLead?.activeBooking?.customer?.name;
      const MobileNumberFilled =
        leadData?.getLead?.activeBooking?.customer?.phoneNo;

      const adharCardFilled =
        leadData?.getLead?.activeBooking?.customer?.identificationDocuments
          ?.aadhaarNumber;
      const stateFilled = {
        label: titleCaseToReadable(
          leadData?.getLead?.activeBooking?.customer?.customerState?.state
        ),
        name: leadData?.getLead?.activeBooking?.customer?.customerState?.state,
        id: leadData?.getLead?.activeBooking?.customer?.customerState?.id
      };
      const districtFilled = {
        label: titleCaseToReadable(
          leadData?.getLead?.activeBooking?.customer?.customerDistrict?.name
        ),
        name: leadData?.getLead?.activeBooking?.customer?.customerDistrict?.name
      };
      const adharCardFrontUrlFilled =
        leadData?.getLead?.activeBooking?.customer?.identificationDocuments
          ?.aadhaarFrontPhotoUrl;
      const addharCardBackUrlFilled =
        leadData?.getLead?.activeBooking?.customer?.identificationDocuments
          ?.aadhaarBackPhotoUrl;
      const panCardFilled =
        leadData?.getLead?.activeBooking?.customer?.identificationDocuments
          ?.panNumber;
      const pancardFilledUrl =
        leadData?.getLead?.activeBooking?.customer?.identificationDocuments
          ?.panPhotoUrl;
      const voterIdFilled =
        leadData?.getLead?.activeBooking?.customer?.identificationDocuments
          ?.voterIdNumber;
      const voterIdFilledUrl =
        leadData?.getLead?.activeBooking?.customer?.identificationDocuments
          ?.voterIdPhotoUrl;
      const rcFilled = leadData?.getLead?.activeBooking?.isRCTransferReq
        ? 'yes'
        : 'no';
      const insuranceFilled = leadData?.getLead?.activeBooking?.isInsuranceReq
        ? 'yes'
        : 'no';
      const bookingFormFilled =
        leadData?.getLead?.activeBooking?.vehicleTransferFormUrl;

      formik.setValues({
        ...formik.values,
        customerName: customerNameFilled,
        customerNo: MobileNumberFilled,
        aadharNo: adharCardFilled,
        state: stateFilled,
        district: districtFilled,
        aadharBackImage: addharCardBackUrlFilled,
        aadharFrontImage: adharCardFrontUrlFilled,
        voterIdNo: voterIdFilled,
        voterIdImage: voterIdFilledUrl,
        panNumber: panCardFilled,
        panImage: pancardFilledUrl,
        rcTransfer: rcFilled,
        insurance: insuranceFilled,
        bookingDocument: bookingFormFilled
      });
    }
  });

  const BankNameOptions = enumToItems(BankName);

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      customerName: null,
      customerNo: null,
      state: null,
      district: null,
      aadharNo: null,
      aadharFrontImage: null,
      aadharBackImage: null,
      panNumber: null,
      panImage: null,
      voterIdNo: '',
      voterIdImage: null,
      rcTransfer: 'yes',
      insurance: 'yes',
      bookingDocument: null,
      remarks: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      leadInput = {
        id: leadId,
        regNo: regNo,
        activeBooking: {
          isInsuranceReq: values?.insurance === 'yes' ? true : false,
          isRCTransferReq: values?.rcTransfer === 'yes' ? true : false,
          vehicleTransferFormUrl: values?.bookingDocument,
          customer: {
            name: values?.customerName,
            phoneNo: values?.customerNo,
            customerState: { state: values?.state?.name },
            customerDistrict: { name: values?.district?.name },

            identificationDocuments: {
              aadhaarFrontPhotoUrl: values?.aadharFrontImage,
              aadhaarBackPhotoUrl: values?.aadharBackImage,
              aadhaarNumber: values?.aadharNo,
              panNumber: values?.panNumber,
              panPhotoUrl: values?.panImage,
              voterIdNumber: values?.voterIdNo ?? '',
              voterIdPhotoUrl: values?.voterIdImage ?? ''
            }
          }
        }
      };

      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });

  const leadSource = enumToItems(LeadSource);
  if (loading) {
    return (
      <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            value={formik.values.customerName}
            name="customerName"
            onChange={(e) =>
              formik?.setFieldValue('customerName', e.target.value)
            }
            placeholder="Customer Name*"
          />
          {formik.values.customerName &&
          (formik.errors.customerName || formik.touched.customerName) ? (
            <div style={{ color: 'red' }}>{formik.errors?.customerName}</div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Input
            value={formik.values.customerNo}
            name="customerNo"
            onChange={(e) =>
              formik?.setFieldValue('customerNo', e.target.value)
            }
            placeholder="Mobile Number*"
            disabled={true}
          />
          {formik.values.customerNo &&
          (formik.errors.customerNo || formik.touched.customerNo) ? (
            <div style={{ color: 'red' }}>{formik.errors?.customerNo}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            onChange={(value) => {
              formik.setFieldValue('state', value);
              formik.setFieldValue('district', null);
              getDistricts({ variables: { id: Number(value?.id) } });
            }}
            value={formik?.values?.state}
            options={
              stateOption?.getStateInfo?.data.map((i) => ({
                label: titleCaseToReadable(i?.state_name),
                name: i?.state_name,
                id: i?.state_id
              })) ?? []
            }
            placeholder="Select State"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            onChange={(value) => {
              formik.setFieldValue('district', value);
            }}
            value={formik.values.district}
            options={
              districtOptions?.getDistrictByStateInfo?.data.map((i) => ({
                label: titleCaseToReadable(i?.district_name),
                name: i?.district_name
              })) ?? []
            }
            placeholder="Select Districts"
            disabled={!formik.values.state}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Aadhar Card
          </Typography>
          <Input
            placeholder="Aadhar Number*"
            onChange={(e) => {
              formik.setFieldValue('aadharNo', e.target.value);
            }}
            value={formik.values.aadharNo}
          />
          {formik.values.aadharNo &&
          (formik.errors.aadharNo || formik.touched.aadharNo) ? (
            <div style={{ color: 'red' }}>{formik.errors?.aadharNo}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="aadharFrontImage"
            value={formik.values.aadharFrontImage}
            onChange={(value) => {
              formik.setFieldValue('aadharFrontImage', value);
            }}
            placeholder="Front Side*"
          />
          {formik.errors.aadharFrontImage && formik.touched.aadharFrontImage ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.aadharFrontImage}
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="aadharBackImage"
            value={formik.values.aadharBackImage}
            onChange={(value) => {
              formik.setFieldValue('aadharBackImage', value);
            }}
            placeholder="Back Side*"
          />
          {formik.errors.aadharBackImage && formik.touched.aadharBackImage ? (
            <div style={{ color: 'red' }}>{formik.errors?.aadharBackImage}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            PAN Card
          </Typography>
          <Input
            placeholder="PAN Number*"
            onChange={(e) => {
              formik.setFieldValue('panNumber', e.target.value);
            }}
            value={formik.values.panNumber}
          />
          {formik.errors.panNumber && formik.touched.panNumber ? (
            <div style={{ color: 'red' }}>{formik.errors?.panNumber}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="panImage"
            value={formik.values.panImage}
            onChange={(value) => {
              formik.setFieldValue('panImage', value);
            }}
            placeholder="PAN card image*"
          />
          {formik.errors.panImage && formik.touched.panImage ? (
            <div style={{ color: 'red' }}>{formik.errors?.panImage}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Voter ID card
          </Typography>
          <Input
            placeholder="Voter Id Number"
            onChange={(e) => {
              formik.setFieldValue('voterIdNo', e.target.value);
            }}
            value={formik.values.voterIdNo}
          />
          {formik.errors.voterIdNo && formik.touched.voterIdNo ? (
            <div style={{ color: 'red' }}>{formik.errors?.voterIdNo}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="voterIdImage"
            value={formik.values.voterIdImage}
            onChange={(value) => {
              formik.setFieldValue('voterIdImage', value);
            }}
            placeholder="Voter Id image*"
          />
          {formik.errors.voterIdImage && formik.touched.voterIdImage ? (
            <div style={{ color: 'red' }}>{formik.errors?.voterIdImage}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <FormControl>
            <Typography variant="h4">Is RC transfer required?*</Typography>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={
                Boolean(leadData?.getLead?.activeBooking && leadData?.getLead?.activeBooking?.isRCTransferReq)? 'yes' : 'no' ?? 'yes'
              }
              sx={{
                width: '300px',
                marginLeft: '10px',
                marginTop: '10px',
                display: 'flex',
                gap: '10px'
              }}
            >
              <FormControlLabel
                value={formik?.values?.rcTransfer === 'yes' ? 'yes' : null}
                control={<Radio />}
                onClick={() => formik.setFieldValue('rcTransfer', 'yes')}
                label="Yes"
              />
              <FormControlLabel
                value={formik?.values?.rcTransfer === 'no' ? 'no' : null}
                onClick={() => formik.setFieldValue('rcTransfer', 'no')}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl> */}
          <FormControl>
            <Typography variant="h4">Is RC transfer required?*</Typography>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              value={formik?.values?.rcTransfer}
              onChange={(event) =>
                formik.setFieldValue('rcTransfer', event.target.value)
              }
              sx={{
                width: '300px',
                marginLeft: '10px',
                marginTop: '10px',
                display: 'flex',
                gap: '10px'
              }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <FormControl>
            <Typography variant="h4">Is Insurance required?*</Typography>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={
                leadData?.getLead?.activeBooking && leadData?.getLead?.activeBooking?.isInsuranceReq ? 'yes' : 'no' ?? 'yes'
              }
              sx={{
                width: '300px',
                marginLeft: '10px',
                marginTop: '10px',
                display: 'flex',
                gap: '10px'
              }}
            >
              <FormControlLabel
                value={formik?.values?.insurance === 'yes' ? 'yes' : null}
                control={<Radio />}
                onClick={() => formik.setFieldValue('insurance', 'yes')}
                label="Yes"
              />
              <FormControlLabel
                value={formik?.values?.insurance === 'no' ? 'no' : null}
                onClick={() => formik.setFieldValue('insurance', 'no')}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl> */}
          <FormControl>
            <Typography variant="h4">Is RC transfer required?*</Typography>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              value={formik?.values?.insurance}
              onChange={(event) =>
                formik.setFieldValue('insurance', event.target.value)
              }
              sx={{
                width: '300px',
                marginLeft: '10px',
                marginTop: '10px',
                display: 'flex',
                gap: '10px'
              }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Booking Form
          </Typography>
          <FileUploadToS3
            accept="image/*,.pdf"
            id="bookingDocument"
            value={formik.values.bookingDocument}
            onChange={(value) => {
              formik.setFieldValue('bookingDocument', value);
            }}
            placeholder="Upload document*"
          />
          {formik.errors.bookingDocument && formik.touched.bookingDocument ? (
            <div style={{ color: 'red' }}>{formik.errors?.bookingDocument}</div>
          ) : null}
        </Grid>
      </Grid>

      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        undesireButtonText={undesireButtonText}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
};

export default BookingUpdation;
