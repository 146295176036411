import { Box, Grid, Typography } from '@mui/material';
import './style.css';
import { Fragment, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import EnquiryByCentreListRow from 'src/components/Composite/EnquiryByCentreListRow';

import {
  EnquiryByCentreRowHeader,
  EnquiryDashboardOptions,
  EnquiryDashboardType,
  FollwupAdherenceByCentreRowHeader
} from 'src/constants/constant';
import {
  EnquiryByCentreQuery,
  FollowupAdherenceQuery,
  useEnquiryByCentreLazyQuery,
  useFollowupAdherenceLazyQuery
} from 'src/generated/hooks_and_more';
import FollowupAdherance from './FollowupAdherance';
import { cachedEnquiryDashBoardFilter, slugsToTitleCase } from 'src/utils/utility';
import { validDateFormate } from '../../utils/utility';
import { useReactiveVar } from '@apollo/client';

type EnquiryGridPropsType = {
  selectedTab: string;
  start: Date | null;
  end: Date | null;
  key: string;
};

function EnquiryGrid({ selectedTab, start, end, key }: EnquiryGridPropsType) {
  const [searchParams, setSearchParams] = useSearchParams();
  const cachedEnquiryDetails = useReactiveVar(cachedEnquiryDashBoardFilter);


  const [
    getEnquiryByCentre,
    { data: EnquiryCentreData, loading: EnquiryCentreLoading }
  ] = useEnquiryByCentreLazyQuery();
  const [
    getFollowupAdherence,
    { data: adherenceData, loading: adherenceLoading }
  ] = useFollowupAdherenceLazyQuery();
  const params = searchParams.get('type');
  const selectedFunnel = !!params
    ? { value: params, label: slugsToTitleCase(params) }
    : EnquiryDashboardOptions[0];
  useEffect(() => {
    getEnquiryByCentre({ variables: { start: start, end: end, key: key } });
    getFollowupAdherence({ variables: { start: start, end: end, key: key } });
  }, [selectedTab, start, end, key]);

  function getTableData(selectedFunnel: string) {
    console.log(selectedFunnel, 'type');
    switch (selectedFunnel) {
      case EnquiryDashboardType.BY_CENTRE:
        return {
          tableData: EnquiryCentreData as EnquiryByCentreQuery,
          tableHeader: EnquiryByCentreRowHeader
        };
      case EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE:
        return {
          tableData: adherenceData,
          tableHeader: FollwupAdherenceByCentreRowHeader
        };

      default:
        return {
          tableData: EnquiryCentreData?.enquiryAggrigateByCentre,
          tableHeader: EnquiryByCentreRowHeader
        };
    }
  }

  const table = getTableData(selectedFunnel?.value);

  const getLoadingStatus = () => {
    switch (selectedFunnel?.value) {
      case EnquiryDashboardType?.BY_CENTRE:
        return EnquiryCentreLoading;
      case EnquiryDashboardType?.FOLLOWUP_ADHERENCE_BY_CENTRE:
        return adherenceLoading;

      default:
        return EnquiryCentreLoading;
    }
  };

  const RenderRows = (selectedValue) => {
    switch (selectedValue) {
      case EnquiryDashboardType.BY_CENTRE:
        return (
          <EnquiryByCentreListRow
            start={start}
            end={end}
            onClickExpand={onClickExpand}
            data={table?.tableData as EnquiryByCentreQuery}
          />
        );

      case EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE:
        return (
          <FollowupAdherance
            start={start}
            end={end}
            onClickExpand={onClickExpand}
            data={table?.tableData as FollowupAdherenceQuery}
            header={table?.tableHeader}
          />
        );
      default:
        return (
          <EnquiryByCentreListRow
            start={start}
            end={end}
            onClickExpand={onClickExpand}
            data={table?.tableData as EnquiryByCentreQuery}
          />
        );
    }
  };


  
  function onClickExpand(value) {
    const id = searchParams?.get('id');
    cachedEnquiryDashBoardFilter({...cachedEnquiryDetails, id})

    if (id !== value) {
      switch (selectedFunnel?.value) {
        case EnquiryDashboardType.BY_CENTRE:
          setSearchParams({
            id: value,
            type: EnquiryDashboardType.BY_CENTRE
          });
          break;
        case EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE:
          setSearchParams({
            id: value,
            type: EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE
          });
          break;
      }
    } else {
      switch (selectedFunnel?.value) {
        case EnquiryDashboardType.BY_CENTRE:
          setSearchParams({
            type: EnquiryDashboardType.BY_CENTRE
          });
          break;
        case EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE:
          setSearchParams({
            type: EnquiryDashboardType.FOLLOWUP_ADHERENCE_BY_CENTRE
          });
          break;
      }
    }
  }

  if (getLoadingStatus()) {
    return (
      <div
        style={{ height: '80vh' }}
        className="d-flex flex-column height-100 justify-content-center align-items-center gap-4"
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }
  return (
    <Fragment>
      <Box sx={{ paddingRight: '10px', paddingLeft: '10px' }}>
        <Grid
          sx={{
            overflowX: 'auto',
            padding: '20px',
            paddingTop: '10px'
          }}
          spacing={1}
          className="contain"
        >
          {start && end && (
            <Typography
              sx={{ paddingBottom: '10px' }}
            >{`From: ${validDateFormate(
              start.toDateString()
            )} |  To: ${validDateFormate(end.toDateString())}`}</Typography>
          )}
          {RenderRows(selectedFunnel?.value)}
        </Grid>
      </Box>
    </Fragment>
  );
}

export default EnquiryGrid;
