import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import './style.css';
import {
  BulkLeadInput,
  useCreateBulkLeadsLazyQuery
} from 'src/generated/hooks_and_more';
import {
  arraysHaveSameValues,
  getUserData,
  titleCaseToReadable
} from 'src/utils/utility';
import * as FileSaver from 'file-saver';
import { useToasts } from 'react-toast-notifications';

type refetch = {
  refetchingBulk?: (value) => void;
};
function BulkUpload({ refetchingBulk }: refetch) {
  const user = getUserData();
  const userDetails: string = user.userId;
  const [file, setFile] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [open, setOpen] = useState(false);
  const { addToast } = useToasts();
  const [totalCount, setLeadTotalCount] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [downloadButton, setDownloadButton] = useState(false);
  const [sheetName, setSheetName] = useState('');
  const [createBulkLead, { data: databulk, loading: bulkloading }] =
    useCreateBulkLeadsLazyQuery({
      fetchPolicy: 'no-cache'
    });
  const handleConvert = (inputFile) => {
    if (inputFile) {
      console.log('inside');
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        console.log(workbook);
        const keys = Object?.keys(json[0]);
        const header = [
          'regNo',
          'registrationState',
          'make',
          'model',
          'source',
          'status',
          'auctionByBank',
          'engineNumber',
          'chassisNumber',
          'manufacturingDate',
          'hoursMeter',
          'demandAmount',
          'ownershipType',
          'remark',
          'email'
        ];
        if (arraysHaveSameValues(keys, header)) {
          setJsonData(json);
          setSheetName(sheetName);
          setOpen(true);
          setDownloadButton(false);
          setExportData(null);
        } else {
          addToast('Invalid file uploaded!!!', {
            appearance: 'error',
            autoDismiss: true
          });
        }
        // console.log({ keys });

        console.log(json, 'jsonnnnnnnnnnnn');
      };
      reader.readAsBinaryString(inputFile);
    }
  };

  function handleUploadLeads() {
    const valueJson = jsonData?.map((item) => {
      return {
        ...item,
        model: item?.model.toString(),
        manufacturingDate: item?.manufacturingDate
          ? item?.manufacturingDate?.toString()
          : null,
        auctionByBank: item?.auctionByBank
          ? item?.auctionByBank?.toString()
          : null,
        ownershipType: item?.ownershipType
          ? item?.ownershipType?.toString()
          : null,
        demandAmount: item?.demandAmount
          ? item?.demandAmount?.toString()
          : null,
        chassisNumber: item?.chassisNumber
          ? item?.chassisNumber?.toString()
          : null,
        engineNumber: item?.engineNumber
          ? item?.engineNumber?.toString()
          : null,
        hoursMeter: item?.demandAmount ? item?.hoursMeter?.toString() : null,
        remark: item?.remark ? item?.remark?.toString() : null
      };
    });
    createBulkLead({
      variables: {
        userId: userDetails,
        leads: valueJson as BulkLeadInput[]
      },
      onCompleted(value) {
        countLeadStatus(value);
        setExportData(value);
        downloadReport(value);
        setDownloadButton(true);
        setOpen(false);
        addToast('Leads has been created', {
          appearance: 'success',
          autoDismiss: true
        });
      },

      onError() {
        addToast('Error occurred while creating leads!!!', {
          appearance: 'error',
          autoDismiss: true
        });
        // setDownloadButton(true);
        setOpen(false);
        setFile('');
      }
    });
  }

  const leadDetailKey = [
    { headerKey: 'RegNo' },
    { headerKey: 'Registration State' },
    { headerKey: 'Source' },
    { headerKey: 'Make' },
    { headerKey: 'Model' },
    { headerKey: 'Manufacturing Date' },
    { headerKey: 'Auction by bank' },
    { headerKey: 'Demand Amount' },
    { headerKey: 'Ownership Type' },
    { headerKey: 'Engine number' },
    { headerKey: 'ChassisNumber' },
    { headerKey: 'Hours Meter' },
    { headerKey: 'Remark' },
    { headerKey: 'email' },
    { headerKey: 'Status' }
  ];

  const keys = [
    { headerKey: 'regNo' },
    { headerKey: 'registrationState' },
    { headerKey: 'source' },
    { headerKey: 'make' },
    { headerKey: 'model' },
    { headerKey: 'manufacturingDate' },
    { headerKey: 'auctionByBank' },
    { headerKey: 'demandAmount' },
    { headerKey: 'ownershipType' },
    { headerKey: 'engineNumber' },
    { headerKey: 'chassisNumber' },
    { headerKey: 'hoursMeter' },

    { headerKey: 'remark' },

    { headerKey: 'email' },
    { headerKey: 'status' }
  ];

  function countLeadStatus(value) {
    let failure = 0;
    let success = 0;
    let total = 0;
    value?.createBulkLeads?.map((i) => {
      Object?.keys(i)?.map((item) => {
        if (item == 'processingStatus') {
          if (i[item] === 'Success' || i[item] === 'success') {
            success = success + 1;
          } else if (i[item] === 'Failed' || i[item] === 'failed') {
            failure = failure + 1;
          }
        }
      });
    });
    total = value?.createBulkLeads?.length;
    const countingValues = { failure, success, total };
    setLeadTotalCount({ ...countingValues });
    return countingValues;
  }

  function downloadReport(count) {
    const exportValue = count?.createBulkLeads?.map((i) => {
      return {
        regNo: i?.regNo ?? '-',
        registrationState: i?.registrationState ?? '-',
        make: i?.make ?? '-',
        model: i?.model ?? '-',
        source: i?.source ?? '-',
        status: i?.status ?? '-',
        auctionByBank: i?.auctionByBank ?? '-',
        engineNumber: i?.engineNumber ?? '-',
        chassisNumber: i?.chassisNumber ?? '-',
        manufacturingDate: i?.manufacturingDate ?? '-',
        hoursMeter: i?.hoursMeter ?? '-',
        demandAmount: i?.demandAmount ?? '-',
        ownershipType: i?.ownershipType ?? '-',
        remark: i?.remark,
        email: i?.email ?? '-',
        processingStatus: i?.processingStatus ?? '-',
        failureReason: i?.failureReason ?? '-'
      };
    });

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10); // Format: YYYY-MM-DD
    const formattedTime = currentDate
      .toTimeString()
      .slice(0, 8)
      .replace(/:/g, ':'); // Format: HHMMSS

    // Construct the filename with current date and time
    const fileName = `${sheetName}_${formattedDate}_${formattedTime}.xlsx`;
    exportToCSV(exportValue, `${fileName}`);

    addToast('Report file has been downloaded', {
      appearance: 'success',
      autoDismiss: true
    });
  }
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const colWidths = [
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 }
    ];
    ws['!cols'] = colWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    console.log(fileName, fileExtension, 'hfhfhfh');

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      {!downloadButton ? (
        !open ? (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Grid className="InputBox">
              <input
                type="file"
                accept=".xls,.xlsx"
                value={file?.[0]?.fileName || ''}
                onChange={(e) => {
                  console.log('input called');
                  const selectedFile = e.target.files[0];
                  setFile(selectedFile);
                  handleConvert(selectedFile);
                }}
              />
            </Grid>

            <Button variant="contained">
              <Link
                href="/bulk-creation-template.xlsx"
                target="_blank"
                sx={{
                  color: 'white',
                  ':hover': {
                    color: 'white',
                    textDecoration: 'none'
                  }
                }}
              >
                Download template
              </Link>
            </Button>
          </Grid>
        ) : (
          <Grid
            sx={{
              paddingBottom: 1,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Grid className="InputBox">
              <Typography variant="h3">
                Total Leads - {jsonData?.length || 0}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                disabled={bulkloading ? true : false}
                variant="contained"
                onClick={() => {
                  handleUploadLeads();
                  // refetchingBulk(true);
                }}
              >
                Create Bulk Leads
              </Button>

              {bulkloading && (
                <CircularProgress
                  size={20}
                  color="primary"
                  sx={{ marginLeft: '0.5rem' }} // Add margin to separate from the button
                />
              )}
            </Grid>
          </Grid>
        )
      ) : null}

      <Grid mt={1}>
        {open && (
          <TableContainer className="table-container-bulk" component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ whiteSpace: 'nowrap' }}>
                  {leadDetailKey.map((key, index) => (
                    <TableCell key={index}>{key?.headerKey}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {jsonData.length > 0 && jsonData[0]?.regNo != '' ? (
                  jsonData?.map((item, index) => (
                    <TableRow key={index}>
                      {keys.map((key, index) => (
                        <TableCell className="no-wrap-table" key={index}>
                          {key?.headerKey === 'source' ||
                          key?.headerKey === 'auctionByBank'
                            ? titleCaseToReadable(item[key?.headerKey])
                            : item[key?.headerKey]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '20px',
                          fontWeight: '900'
                        }}
                      >
                        <Typography>No data found</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      {downloadButton && !open && (
        <>
          <Grid>
            <Typography variant="h3" mt={2}>
              Excel Upload Status
            </Typography>
            <TableContainer sx={{ mt: 2 }} component={Paper}>
              <Table>
                <TableRow>
                  <TableCell sx={{ fontWeight: '500' }}>
                    Leads Requested
                  </TableCell>
                  <TableCell>{totalCount?.total ?? 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Leads created sucessfully</TableCell>
                  <TableCell>{totalCount?.success ?? 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Leads rejected</TableCell>
                  <TableCell>{totalCount?.failure ?? 0}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            md={6}
            xs={6}
            mt={5}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              disabled={!exportData ? true : false}
              sx={{ marginRight: 1 }}
              variant="outlined"
              onClick={() => {
                setOpen(true);
                setDownloadButton(false);
                // downloadReport(exportData);
              }}
            >
              Back
            </Button>
            <Button
              disabled={!exportData ? true : false}
              variant="contained"
              onClick={() => {
                downloadReport(exportData);
              }}
            >
              Download
            </Button>
          </Grid>
        </>
      )}
    </>
  );
}

export default BulkUpload;
