import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  Tab,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router';
import NotificationListRow from 'src/components/Composite/NotificationListRow';
import TaskListRow from 'src/components/Composite/TaskListRow';
import TableWrapper from 'src/components/Tables';
import {
  NotificationTableHeader,
  TaskListTableHeader
} from 'src/constants/constant';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
import {
  UserRole,
  useMyPendingTasksQuery,
  useUpdatesForMyRoleQuery
} from 'src/generated/hooks_and_more';
import { titleCaseToReadable } from 'src/utils/utility';

function MyPendingTask() {
  const { loggedInUser } = useLoggedInUser();
  const navigate = useNavigate();
  const [tab, setTab] = useState('task');
  const [notificationOffest, setNotificationOffest] = useState(0);
  const [taskOffset, setTaskOffset] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [data, setData] = useState([]);

  const {
    data: tasks,
    refetch,
    loading: taskLoading,
    fetchMore
  } = useMyPendingTasksQuery({
    skip: !loggedInUser?.userId || !loggedInUser?.role,
    variables: {
      myId: loggedInUser?.userId,
      role: loggedInUser?.role,
      offset: taskOffset * 10,
      centreId:
        loggedInUser?.role === UserRole?.CentreManager
          ? loggedInUser?.centre?.id
          : null
    },
    onCompleted(task) {
      if (!task?.myPendingTasks?.length) {
      }
      setData([...data, ...tasks?.myPendingTasks]);
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      // log('Error in fetching tasks', {error})
      // ToastAndroid.showWithGravity(
      //   'Server error',
      //   (ToastAndroid.SHORT = 10),
      //   ToastAndroid.CENTER,
      // )
    }
  });
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const { data: notifications, loading: notificationLoading } =
    useUpdatesForMyRoleQuery({
      fetchPolicy: 'network-only',
      variables: {
        offset: notificationOffest * 10
      },
      onCompleted() {
        setNotificationData([
          ...notificationData,
          ...notifications?.queryLeadStatusEvent
        ]);
      }
    });

  return (
    <Fragment>
      <Box sx={{ px: 2, pb: 2, mt: 4 }}>
        {
          <>
            <TabContext value={tab}>
              <Box
                sx={{
                  '& .css-1pyy021-MuiTabs-flexContainer': {
                    backgroundColor: '#DFDFDF',
                    borderRadius: '5px',
                    justifyContent: 'space-around',
                    width: '210px'
                  }
                }}
              >
                <TabList onChange={handleChange}>
                  <Tab label="Tasks" value="task" />
                  <Tab label="Notifications" value="notification" />
                </TabList>
              </Box>

              {(taskLoading && !data?.length) ||
              (notificationLoading && !notificationData?.length) ? (
                <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                  <h5>Loading..</h5>
                </div>
              ) : (
                <>
                  <TabPanel value="task">
                    <TableWrapper header={TaskListTableHeader}>
                      {data?.length ? (
                        data?.map((taskData, index) => {
                          const regNo = taskData?.lead?.regNo;
                          const pendingOn = taskData?.assignTaskTo;
                          const currentStatus = taskData?.status;
                          const make =
                            taskData?.lead?.vehicle?.vehicleBrand?.name;
                          const title = taskData?.taskTitle;
                          const taskPerformBy = taskData?.createdBy?.name;

                          const createdAt = taskData?.createdAt;

                          return (
                            <TaskListRow
                              regNo={regNo}
                              lseId={taskData?.id}
                              pendingOn={pendingOn}
                              currentStatus={currentStatus}
                              make={make}
                              taskPerformBy={taskPerformBy}
                              title={title}
                              createdAt={
                                !!createdAt
                                  ? format(Date.parse(createdAt), 'dd-MM-yyyy')
                                  : '-'
                              }
                              key={taskData.id}
                              index={index}
                            />
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '20px',
                                fontWeight: '900'
                              }}
                            >
                              <Typography>No data found</Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableWrapper>
                  </TabPanel>
                  <TabPanel value="notification">
                    <TableWrapper header={NotificationTableHeader}>
                      {notificationData?.length ? (
                        notificationData?.map((item, index) => {
                          const date = new Date(item?.createdAt).toDateString();
                          const time = new Date(item?.createdAt)
                            .toTimeString()
                            .split(' ')[0]
                            .slice(0, 5);
                          const dateString = date + ' ' + time;
                          const regNo = item?.lead?.regNo;

                          const make = item?.lead?.vehicle?.vehicleBrand?.name;
                          const title =
                            titleCaseToReadable(item?.status) +
                            ' ' +
                            item?.createdBy?.name;

                          return (
                            <NotificationListRow
                              regNo={regNo}
                              lseId={item?.id}
                              make={make}
                              title={title}
                              createdAt={dateString}
                              key={item?.id}
                            />
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '20px',
                                fontWeight: '900'
                              }}
                            >
                              <Typography>No data found</Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableWrapper>
                    {/* {notificationData?.length ? (
                      notificationData?.map((item, index) => {
                        const date = new Date(item?.createdAt).toDateString();
                        const time = new Date(item?.createdAt)
                          .toTimeString()
                          .split(' ')[0]
                          .slice(0, 5);
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Button
                              onClick={() =>
                                navigate(
                                  `/vms-admin/leads/${item?.lead?.regNo}`
                                )
                              }
                            >
                              {item?.lead?.regNo}
                            </Button>
                            <div>
                              {item?.lead?.vehicle?.vehicleBrand && (
                                <Button disabled={true}>
                                  {item?.lead?.vehicle?.vehicleBrand.name}
                                </Button>
                              )}
                              {titleCaseToReadable(item?.status)} by{' '}
                              {item?.createdBy?.name}{' '}
                              <span style={{ fontWeight: 600 }}>
                                {date} {time}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '20px',
                              fontWeight: '900'
                            }}
                          >
                            <Typography>No data found</Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    )} */}
                  </TabPanel>
                </>
              )}
            </TabContext>
          </>
        }
        {(notificationLoading && notificationData?.length) ||
        (taskLoading && data.length) ? (
          <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : null}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            variant="contained"
            style={{
              display:
                (tab === 'notification' &&
                  !Boolean(notifications?.queryLeadStatusEvent)) ||
                tab === 'task' ||
                notificationData?.length < 10
                  ? 'none'
                  : 'block',
              width: '140px'
            }}
            onClick={() => setNotificationOffest(notificationOffest + 1)}
          >
            Load More
          </Button>
          <Button
            variant="contained"
            style={{
              display:
                (tab === 'task' && !Boolean(tasks?.myPendingTasks?.length)) ||
                tab === 'notification' ||
                data?.length < 10
                  ? 'none'
                  : 'block',
              width: '140px'
            }}
            onClick={() => setTaskOffset(taskOffset + 1)}
          >
            Load More
          </Button>
        </Box>

        {/* <Pagination
          count={totPage}
          onChange={(e: any) =>
            tab === 'notification'
              ? setNotificationPage(Number(e.target.innerText))
              : null
          } //
          color="primary"
        /> */}
      </Box>
    </Fragment>
  );
}

export default MyPendingTask;
