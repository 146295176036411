import * as Yup from 'yup';
export const InspectVehicleSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      type: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required()
      }),
      stockAvailable: Yup.boolean(),
      amount: Yup.number()
        .required('*Required')
        .when('stockAvailable', {
          is: false,
          otherwise: (schema) => schema.notRequired()
        }),
      others: Yup.boolean(),
      description: Yup.string()
        .required('*Required')
        .when('others', {
          is: true,
          otherwise: (schema) => schema.notRequired()
        }),
      imageUpload: Yup.string().required('*Required')
    })
  )
});
