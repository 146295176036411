import { Box, Grid, Typography, Card, styled } from '@mui/material';
import React from 'react';
import Link from '@mui/material/Link';

const CardCc = styled(Card)(
  ({ theme }) => `
     border: 1px solid ${theme.colors.alpha.black[5]};
     background: ${theme.colors.alpha.black[5]};
     box-shadow: none;
`
);

type PropsType = {
  key?: string;
  value?: string | number;
  type?: string;
};

type InventoryDetailCardProp = {
  details: PropsType[];
};

function InventoryDetailCard({ details }: InventoryDetailCardProp) {
  return (
    <Box sx={{ px: 2, pb: 2 }}>
      <CardCc sx={{ p: 2 }}>
        <Grid container spacing={1}>
          {details.map((item, index) => {
            return (
              <Grid key={index} item xs={6} sm={4} md={3}>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography variant="subtitle2" pb={1}>
                      {item?.key}:{' '}
                      {item.type == 'map' && item.value !== '-' ? (
                        <Typography component="span" color="text.primary">
                          <Link
                            href={`${item.value}`}
                            style={{
                              color: 'rgb(17,25,42)',
                              textDecoration: 'underline'
                              // display: 'flex',
                              // justifyContent: 'flex-start'
                            }}
                            underline="hover"
                            target="_blank"
                          >
                            view Location
                          </Link>
                        </Typography>
                      ) : (
                        <Typography component="span" color="text.primary">
                          {item.value ?? '-'}
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}

          {/* <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  State:{' '}
                  <Typography component="span" color="text.primary">
                    Rajasthan
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  City:{' '}
                  <Typography component="span" color="text.primary">
                    Sambalpur
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2">
                  Pincode:{' '}
                  <Typography component="span" color="text.primary">
                    768017
                  </Typography>
                </Typography>
              </Box>
            </Box> */}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={3}>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  Vehicle stocked:{' '}
                  <Typography component="span" color="text.primary">
                    6
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  Refurb items:{' '}
                  <Typography component="span" color="text.primary">
                    5
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  Status:{' '}
                  <Typography component="span" color="text.primary">
                    Actice
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  Spoc Name:{' '}
                  <Typography component="span" color="text.primary">
                    John Wick
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  Email:{' '}
                  <Typography component="span" color="text.primary">
                    johnwick@gmail.com
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  Mobile:{' '}
                  <Typography component="span" color="text.primary">
                    8976452314
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  Location Lat & Long:{' '}
                  <Typography component="span" color="text.primary">
                    view Location
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  Created on:{' '}
                  <Typography component="span" color="text.primary">
                    05-May-23
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="subtitle2" pb={1}>
                  Last updated on:{' '}
                  <Typography component="span" color="text.primary">
                    05-May-23
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </CardCc>
    </Box>
  );
}

export default InventoryDetailCard;
