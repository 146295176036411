import React, { useState } from 'react';
import { ErrorMessage, useFormik } from 'formik';
import { Box, Button, Grid, TextField } from '@mui/material';
import Input from 'src/components/Form/Input';
import * as Yup from 'yup';
import {
  BankName,
  LeadRef,
  PaymentFor,
  PaymentStatus
} from 'src/generated/hooks_and_more';
import { enumToItems } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { LeadCreateAndUpdateProps } from 'src/types';
import { RaisePaymentRequestSchema } from '../ValidationSchema/ValidationSchema';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

const RaisePaymentRequestForm = (props: LeadCreateAndUpdateProps) => {
  const BankNameOptions = enumToItems(BankName);

  const {
    toggleDrawer,
    leadId,
    regNo,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    onPressOfDesiredOrUndesiredButton
  } = props;
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      bankName: null,
      accountHolderName: null,
      accountNumber: '',
      ifscNumber: '',
      accountProof: null
    },

    validationSchema: RaisePaymentRequestSchema,

    onSubmit: (values) => {
      leadInput = {
        id: leadId,
        regNo: regNo,
        payments: [
          {
            bankName: values?.bankName?.value,
            accountHolderName: values?.accountHolderName,
            accountNo: values?.accountNumber,
            ifsc: values?.ifscNumber,
            for: PaymentFor?.DealPayment,
            status: PaymentStatus.Requested,
            proofUrl: values?.accountProof
          }
        ]
      };

      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <InputAutoComplete
            placeholder="Select a Bank Name*"
            value={formik.values.bankName}
            onChange={(value) => {
              formik.setFieldValue('bankName', value);
            }}
            options={BankNameOptions}
            optionString="label"
            getOptionLabel={(option) => option.name}
          />
          {formik.errors.bankName || formik.touched.bankName ? (
            <div style={{ color: 'red' }}>{formik.errors?.bankName}</div>
          ) : null}
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            id="outlined-uncontrolled"
            label="Enter the Account holder Name*"
            onChange={(e) =>
              formik.setFieldValue('accountHolderName', e?.target?.value)
            }
            placeholder="Enter the Account holder Name*"
          />
          {formik.values.accountHolderName &&
          (formik.touched.accountHolderName ||
            formik.errors.accountHolderName) ? (
            <div style={{ color: 'red' }}>
              {formik.errors?.accountHolderName}
            </div>
          ) : null}{' '}
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="outlined-uncontrolled"
            autoComplete="off"
            label="Enter the Account Number*"
            onChange={(e) =>
              formik.setFieldValue('accountNumber', e?.target?.value)
            }
            placeholder="Enter the Account holder Number*"
          />
          {formik.values.accountNumber &&
          (formik.errors.accountNumber || formik.touched.accountNumber) ? (
            <div style={{ color: 'red' }}>{formik.errors?.accountNumber}</div>
          ) : null}
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="outlined-uncontrolled"
            label="Enter IFSC code*"
            name="ifscNumber"
            autoComplete="off"
            onChange={(e) =>
              formik.setFieldValue('ifscNumber', e?.target?.value)
            }
            placeholder="Enter IFSC code*"
          />
          {formik.values.ifscNumber &&
          (formik?.touched?.ifscNumber || formik?.errors?.ifscNumber) ? (
            <div style={{ color: 'red' }}>{formik.errors?.ifscNumber}</div>
          ) : null}{' '}
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid>
            {' '}
            <h5> Account Proof</h5>
          </Grid>
          <Grid mt={3}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="accountProof"
              value={formik.values.accountProof}
              onChange={(value) => {
                formik.setFieldValue('accountProof', value);
              }}
              placeholder="upload document*"
            />
            {formik.values.accountProof &&
            (formik.errors.accountProof || formik.touched.accountProof) ? (
              <div style={{ color: 'red' }}>{formik.errors?.accountProof}</div>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        undesireButtonText={undesireButtonText}
        disabled={!(formik.isValid && formik.dirty)}
        reject={reject}
      />
    </form>
  );
};

export default RaisePaymentRequestForm;
