import React from 'react';
import { LeadDetailsPropsType } from './ApplicationDetails';
import DetailsTabPanel from 'src/components/Composite/DetailsTabPannel';
import { formatDate, titleCaseToReadable } from 'src/utils/utility';

export default function ListingDetails({ currentLead }: LeadDetailsPropsType) {
  const listingDetails = [
    {
      key: 'Listing Price',
      value: currentLead?.listingPrice ?? '-'
    },
    {
      key: 'Listing Status',
      value: currentLead?.listingStatus
        ? titleCaseToReadable(currentLead?.listingStatus)
        : '-'
    },
    {
      key: 'Listing on Website',
      value: formatDate(currentLead?.listingOnWebUpdatedAt) ?? '-'
    },
    {
      key: 'Listing updated By',
      value: currentLead?.listingUpdatedBy?.name
    }
  ];
  return <DetailsTabPanel data={listingDetails} />;
}
