import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Box from '@mui/material/Box';
interface DatePickerCalenderProps {
  placeholder: string;
  value: Date;
  onChange: (e) => void;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

function DatePickerCalender({
  placeholder,
  value,
  disabled,
  onChange,
  minDate,
  maxDate
}: DatePickerCalenderProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={placeholder}
        inputFormat="dd/MM/yyyy"
        value={value}
        minDate={minDate ?? new Date('01/01/2000')}
        maxDate={maxDate}
        disabled={disabled}
        onChange={onChange}
        renderInput={(params) => (
          <TextField fullWidth autoComplete='off'
          size="small" {...params} />
        )}
      />
    </LocalizationProvider>
  );
}

export default DatePickerCalender;
