import { Box, Button, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import CenterListRow from 'src/components/Composite/CenterListRow';
import TableWrapper from 'src/components/Tables';
import {
  CENTER_TABLE_HEADER,
  STATEGST_TABLE_HEADER
} from 'src/constants/constant';
import {
  UserRole,
  useGetCentreDataFromTjQuery,
  useGetStateWithGstinQuery
} from 'src/generated/hooks_and_more';
import { cachedLeadFilter } from 'src/utils/utility';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import StateWithGSTINRow from 'src/components/Composite/StateWithGSTINRow';
import { AddState } from 'src/components/Composite/AddStatePopOver';
import '../../components/Composite/demo.css';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';

function CenterList() {
  const { data, loading, error } = useGetCentreDataFromTjQuery({
    fetchPolicy: 'no-cache'
  });
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState<[] | any>(null);
  const { loggedInUser } = useLoggedInUser();

  const [label, setLabel] = useState(null);

  const {
    data: stateWithGSTINData,
    loading: statloading,
    refetch
  } = useGetStateWithGstinQuery({
    fetchPolicy: 'no-cache'
  });
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [id, setId] = useState('');

  useEffect(() => {
    cachedLeadFilter(null);
  }, []);

  function onEdit() {
    setOpen(true);
    setEdit(true);
  }

  function onAddOrUpdate() {
    refetch();
  }

  if (error) {
    return <div>error...</div>;
  }

  function handleClose() {
    setOpen(false);
  }

  function getIdforUpdate(id) {
    setId(id);
  }

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          sx={{
            marginTop: '2rem',
            marginLeft: '2rem',
            marginBottom: '0.5rem',
            width: '40%'
          }}
          onChange={handleChange}
          aria-label="lab API tabs example"
        >
          <Tab label="Center" value="1" sx={{ width: '30%' }} />
          <Tab label="State" value="2" sx={{ width: '30%' }} />
        </TabList>
      </Box>
      <TabPanel value="1">
        <Fragment>
          <Box mt={0}>
            {loading ? (
              <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
                <h5>Loading..</h5>
              </div>
            ) : (
              <TableWrapper loading={loading} header={CENTER_TABLE_HEADER}>
                {loggedInUser?.role === UserRole?.CentreManager
                  ? data?.getCentreDataFromTJ
                      ?.filter((item) => item?.id === loggedInUser?.centre?.id)
                      .map((item, index) => {
                        return (
                          <CenterListRow key={item.id} centerData={item} />
                        );
                      })
                  : data?.getCentreDataFromTJ
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      ?.map((item, index) => {
                        return (
                          <CenterListRow key={item.id} centerData={item} />
                        );
                      })
                      .sort()}
              </TableWrapper>
            )}
          </Box>
        </Fragment>
      </TabPanel>
      <TabPanel value="2">
        <Fragment>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className="mainTableForAddState"
          >
            <Grid sx={{ marginLeft: '2rem', width: '20%' }}>
              <InputAutoComplete
                onChange={(value) => {
                  setStatus(value);
                  if (value?.label === 'ACTIVE') {
                    setLabel(true);
                  } else if (value?.label === 'INACTIVE') {
                    setLabel(false);
                  }
                }}
                value={status ?? null}
                placeholder="Select State status"
                options={[
                  { label: 'ACTIVE', name: 'ACTIVE' },
                  { label: 'INACTIVE', name: 'INACTIVE' }
                ]}
                optionString="name"
              />
            </Grid>
            {loggedInUser?.role === UserRole?.Admin ? (
              <Grid
                sx={{
                  float: 'left',
                  marginRight: '1rem',
                  marginBottom: '0.5rem'
                }}
              >
                <Button
                  onClick={() => {
                    setOpen(true);
                    setEdit(false);
                  }}
                  variant="contained"
                >
                  ADD STATE
                </Button>
              </Grid>
            ) : null}
          </Grid>

          <Box sx={{ px: 2, pb: 2 }}>
            {statloading ? (
              <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
                <h5>Loading..</h5>
              </div>
            ) : label === null ? (
              <TableWrapper loading={loading} header={STATEGST_TABLE_HEADER}>
                {stateWithGSTINData?.queryActiveStateWithGSTN
                  ?.sort((a, b) =>
                    a.active === b.active ? 0 : a.active ? -1 : 1
                  )
                  ?.map((item, index) => {
                    return (
                      <StateWithGSTINRow
                        onEdit={onEdit}
                        handleSelectId={getIdforUpdate}
                        key={item.id}
                        stateData={item}
                      />
                    );
                  })}
              </TableWrapper>
            ) : (
              <TableWrapper loading={loading} header={STATEGST_TABLE_HEADER}>
                {stateWithGSTINData?.queryActiveStateWithGSTN
                  ?.filter?.((i) => i?.active === label)
                  ?.map((item, index) => {
                    const value = {
                      id: item?.id,
                      GSTNumber: item?.GSTNumber,
                      active: item?.active,
                      state: item?.state,
                      createdAt: item?.createdAt,
                      updatedAt: item?.updatedAt
                    };
                    return (
                      <StateWithGSTINRow
                        onEdit={onEdit}
                        handleSelectId={getIdforUpdate}
                        key={item.id}
                        stateData={value}
                      />
                    );
                  })}
              </TableWrapper>
            )}
          </Box>
          <AddState
            open={open}
            value={edit}
            id={id}
            handleClose={handleClose}
            onAddOrUpdate={onAddOrUpdate}
          />
        </Fragment>
      </TabPanel>
    </TabContext>
  );
}

export default CenterList;
