import React, { useEffect, useState } from 'react';

import {
  Capitalize,
  getDesireDateFormate,
  getStatus,
  getYearFromDate,
  titleCaseToReadable
} from '../../utils/utility';

import { TableCell, TableRow, Typography } from '@mui/material';
import {
  CenterPreviewFragment,
  Centre,
  Issue,
  Purchase,
  PurchaseItem,
  useGetIssueItemDetailsLazyQuery,
  useGetPurchaseItemDetailsLazyQuery
} from '../../generated/hooks_and_more';
import Link from '@mui/material/Link';
import { TableCellProps } from '@material-ui/core';
import routeConstant from 'src/constants/routeContant';
import StockPopOverData from 'src/screens/inventory/StockPop';

type StockDetailListRowProps = {
  purchases?: any;

  index?: number;
  status: any;
  productName?: string;
  handleIndex?: (index, purchaseId, status) => void;
};

type RowItemsType = {
  value: string | number;
  align?: TableCellProps['align'];
  type?: string;
};

function StockDetailListRow({
  purchases,
  index,
  status,
  productName,
  handleIndex
}: StockDetailListRowProps) {
  let quantityForStock = purchases?.items?.filter(
    (i) => i?.product?.name === productName
  );

  const refCost = quantityForStock?.reduce((acc, sum) => {
    return acc + sum?.quantity;
  }, 0);

  const rowItems: RowItemsType[] = [
    { value: purchases?.id },

    {
      value:
        status === 'RECEIVED' ||
        status === 'REFURBISHMENT_APPROVED' ||
        status === 'BILLS_UPLOADED' ||
        status === 'PAYMENT_REQUESTED' ||
        status?.includes('PURCHASE')
          ? 'Purchase'
          : 'Issue'
    },
    {
      value: titleCaseToReadable(status)
    },
    {
      value: purchases?.createdAt
        ? getDesireDateFormate(purchases?.createdAt)
        : '-',
      align: 'center'
    },
    { value:  purchases?.items?.[0]?.quantity, align: 'center' },
    { value: purchases?.items?.[0]?.price?.toString(), align: 'center' },
    // { value: refCost, align: 'center' },
    {
      value: Capitalize(purchases?.madeBy?.name) ?? '-'
    },
    {
      value: purchases?.createdAt
        ? getDesireDateFormate(purchases?.createdAt)
        : '-'
    },
    {
      value: purchases?.createdAt
        ? getDesireDateFormate(purchases?.createdAt)
        : '-'
    }
  ];

  return (
    <TableRow
      hover
      onClick={() => {
        handleIndex(index, purchases?.id, status);
      }}
      style={{ cursor: 'pointer' }}
    >
      {rowItems.map((item, index) => {
        return (
          <TableCell align={item.align ?? 'left'} key={index}>
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
              noWrap
            >
              {item.value ?? '-'}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default StockDetailListRow;
