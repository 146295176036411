import { LeadRef } from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import { Box, Button, Grid } from '@mui/material';
import Input from 'src/components/Form/Input';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { useFormik } from 'formik';
import { SelfieUploadSchema } from '../../ValidationSchema/ValidationSchema';
import CtaButton from '../CTA_Button/CtaButton';
import * as Yup from 'yup';

function VerifyDeliveryRefurb(props: LeadCreateAndUpdateProps) {
  const {
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    onPressOfDesiredOrUndesiredButton,
    isVehicleListedOnWebsite
  } = props;
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      deliveryPhoto: '',
      chequePhoto: '',
      deliveryForm: '',
      remarks: ''
    },
    validationSchema: Yup.object().shape({
      deliveryPhoto: Yup.string().required('*Required'),
      chequePhoto: Yup.string().required('*Required'),
      deliveryForm: Yup.string().required('*Required')
    }),
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        activeBooking:{
          deliveryPhotoUrl: values?.deliveryPhoto,
          deliveryForm: values?.deliveryForm,
          cancelledChequeUrl: values?.chequePhoto
        }
      };

      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });

  return (
    <div>

      {isVehicleListedOnWebsite  ? <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12} mt={1}>
          <h6 style={{marginBottom: "0.6rem"}}>Upload Delivery Photo*</h6>

            <FileUploadToS3
              id="deliveryPhoto"
              accept="image/*"

              value={formik.values.deliveryPhoto}
              onChange={(value) => {
                formik.setFieldValue('deliveryPhoto', value);
              }}
              placeholder="delivery Photo"
            />
            {formik.errors.deliveryPhoto && formik.touched.deliveryPhoto ? (
              <div style={{ color: 'red' }}>{formik.errors?.deliveryPhoto}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12} mt={1}>
          <h6 style={{marginBottom: "0.6rem"}}>Upload Cheque Photo*</h6>

            <FileUploadToS3
              id="chequePhoto"
              accept="image/*"

              value={formik.values.chequePhoto}
              onChange={(value) => {
                formik.setFieldValue('chequePhoto', value);
              }}
              placeholder="Cheque Photo"
            />
            {formik.errors.chequePhoto && formik.touched.chequePhoto ? (
              <div style={{ color: 'red' }}>{formik.errors?.chequePhoto}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
          <h6 style={{marginBottom: "0.6rem"}}>Upload Delivery Form*</h6>

            <FileUploadToS3
              id="deliveryForm"
              accept="image/*,pdf/*"

              value={formik.values.deliveryForm}
              onChange={(value) => {
                formik.setFieldValue('deliveryForm', value);
              }}
              placeholder="Upload Document"

            />
            {formik.errors.deliveryForm && formik.touched.deliveryForm ? (
              <div style={{ color: 'red' }}>{formik.errors?.deliveryForm}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.remarks}
              name="remarks"
              onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
              placeholder="Enter the Remarks"
            />
          </Grid>
        </Grid>
        <CtaButton
          desireButtonText={desireButtonText}
          disabled={!(formik.isValid && formik.dirty)}
        />
      </form>  : <>
      <Grid>
      <h4 style={{textAlign: 'center'}}>No Listing Found!</h4>
            <h4 style={{textAlign: 'center'}}>
              Please list tractor on website to mark delivery </h4>
            
      </Grid>
      
      </>}
    
    </div>
  );
}

export default VerifyDeliveryRefurb;
