import { Box, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { Timeline } from 'rsuite';
import './documentTimeline.css';
import { format } from 'date-fns';

function DocumentTimeline({ followUpData }) {

  return (
    <Fragment>
      <Timeline align="left" style={{ padding: '10px' }}>
        {followUpData?.length &&
          followUpData?.[0]
            ?.map((i, index) => {
              return (
                <Timeline.Item
                  style={{ paddingBottom: '0.7rem' }}
                  key={index}
                  time={
                    i?.followupDate
                      ? new Date(i?.followupDate)
                          .toLocaleString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false
                          })
                          .replace(/,/, '')
                      : '-'
                  }
                  classPrefix={'task-completed'}
                >
                  <Typography fontWeight={600} fontSize={15}>
                    {i?.documentName ?? '-'}
                  </Typography>
                  {i?.remarks && (
                    <Typography component="p" fontStyle={'italic'}>
                      {i?.remarks}
                    </Typography>
                  )}

                  {i?.nextFollowupDate && (
                    <Box>
                      <Typography
                        fontWeight={600}
                        fontStyle={'italic'}
                        sx={{
                          textDecoration: 'underline',
                          textUnderlineOffset: '3px'
                        }}
                      >
                        Next Followup on
                      </Typography>
                      <Typography component="p" fontStyle={'italic'}>
                        {new Date(i?.nextFollowupDate).toLocaleDateString(
                          'en-GB',
                          { day: 'numeric', month: 'short', year: 'numeric' }
                        ) ?? '-'}
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    <Typography
                      fontWeight={600}
                      fontStyle={'italic'}
                      sx={{
                        textDecoration: 'underline',
                        textUnderlineOffset: '3px'
                      }}
                    >
                      {i?.actionType === 'DELETE'
                        ? 'Deleted By'
                        : i?.actionType === 'CREATE'
                        ? 'Uploaded By'
                        : 'Updated by'}
                    </Typography>
                    <Typography component="p" fontStyle={'italic'}>
                      {i?.followedUpBy?.name ?? '-'}
                    </Typography>
                    <Typography
                      fontWeight={600}
                      fontStyle={'italic'}
                      sx={{
                        textDecoration: 'underline',
                        textUnderlineOffset: '3px'
                      }}
                    >
                      Source
                    </Typography>
                    <Typography component="p" fontStyle={'italic'}>
                      {i?.source ?? '-'}
                    </Typography>
                  </Box>
                </Timeline.Item>
              );
            })
            .reverse()}
      </Timeline>
    </Fragment>
  );
}

export default DocumentTimeline;
