import React, { ChangeEventHandler } from 'react';
import TextField from '@mui/material/TextField';
import { Customer } from 'src/generated/hooks_and_more';

type InputPropsType = {
  value?: string | number | Customer;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  placeholder: string;
  name?: string;
  inputType?: string;
  required?: boolean;
  errorValue?: Boolean;
  autoComplete?: string

};

function Input({
  value,
  disabled = false,
  onChange,
  name,
  placeholder,
  inputType,
  required,
  errorValue,
  autoComplete

}: InputPropsType) {
  return (
    <>

    <TextField
      error={errorValue ? true : false}
      helperText={ errorValue ? "Enter numbers only" : null }
      value={value}
      disabled={disabled}
      type={inputType}      
      name={name}
      size="small"
      onChange={onChange}
      fullWidth
      label={placeholder}
      id="fullWidth"
      required={required}
      autoComplete='off'
    />
    </>
  );
}

export default Input;
