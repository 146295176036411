import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './form.css';
import { Icon } from 'leaflet';
import { useGetDriverQuery, useGetYardDetailsQuery } from 'src/generated/hooks_and_more';
import { Button, Box } from '@mui/material';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { LeadStatus } from 'src/generated/hooks_and_more';
import CtaButton from './CTA_Button/CtaButton';
import { Formik, useFormik } from 'formik';
import { ActionType } from 'src/constants/constant';
export default function InternalTransferMap({
  desireButtonText,
  regNo,
  leadId,
  desireStatus,
  source,
  toggleDrawer,
  undesireButtonText,
  onPressOfDesiredOrUndesiredButton
}) {
  

  const { data: internalTransferData, loading } = useGetYardDetailsQuery({
    variables: { id: leadId }
  });


  const icon = new Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/128/684/684908.png',
    iconSize: [38, 38]
  });
  const dataIT = internalTransferData?.getLead?.internalTransfers

  const driverNameIT = internalTransferData?.getLead?.internalTransfers?.[dataIT?.length - 1]?.assignedTo?.name
  const pickup = internalTransferData?.getLead?.internalTransfers?.[dataIT?.length - 1]?.from?.name;
  const drop = internalTransferData?.getLead?.internalTransfers?.[dataIT?.length - 1]?.to?.name;

  const cenLatitude = internalTransferData?.getLead?.internalTransfers?.[dataIT?.length - 1].to?.location?.latitude;
  const cenLongitude = internalTransferData?.getLead?.internalTransfers?.[dataIT?.length - 1].to?.location?.longitude;



  const leadInput = {
    id: leadId,
    regNo: regNo,
    internalTransfers: [
      {
        id: internalTransferData?.getLead?.internalTransfers?.[dataIT?.length - 1]?.id,
      },
    ],
  };
  const title = (Status) => {
    switch (Status) {
    
      case LeadStatus.PickupAcceptedForInternalTransfer:
        return 'expected to pickup'
      default:
        break;
    }
  };
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };

  if (loading) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center gap-4"
        style={{ height: '100vh' }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>

      <h4 style={{ textAlign: 'center', padding: '5px' }}> {driverNameIT}  {title(desireStatus)}  </h4> 
    

       <p style={{ textAlign: 'center', padding: '3px', fontSize: "18px" }}>Internal Transfer</p> 
      
      <p style={{ textAlign: 'center', padding: '3px',fontSize: "16px" }}>Pickup: {pickup}</p> 
      
       <p style={{ textAlign: 'center', padding: '3px 3px 6px 3px', fontSize: "16px"}}>drop: {drop}</p> 
      <MapContainer center={[cenLatitude, cenLongitude]} zoom={15}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[cenLatitude, cenLongitude]} icon={icon}>
          <Popup>{drop}</Popup>
        </Marker>
      </MapContainer>
      
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        onlyRed={true}
        reject={reject}
      />
    </form>
  );
}
