import React, { useEffect, useState } from 'react';
import {
  LeadRef,
  LeadStatus,
  PaymentFor,
  PaymentMethod,
  PaymentStatus,
  useFinalCostQuery,
  useGetDeliveryPaymentQuery
} from 'src/generated/hooks_and_more';
import { LeadCreateAndUpdateProps } from 'src/types';
import { Box, Button, Grid } from '@mui/material';
import Input from 'src/components/Form/Input';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import { useFormik } from 'formik';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import { compareDate, enumToItems } from 'src/utils/utility';
import { ActionType } from 'src/constants/constant';
import CtaButton from '../CTA_Button/CtaButton';
import * as Yup from 'yup';


function DOform(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    fiDate,
    onPressOfDesiredOrUndesiredButton,
    loginDate
  } = props;


  const {data: DoAmountData, loading} = useGetDeliveryPaymentQuery({
    variables: {
      leadId: leadId
    }
  })
 const [doDate, setDoDate] = useState(false)
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const formik = useFormik({
    initialValues: {
      DOdate: '',
      DOValidity: 0,
      approvedLoanAmount: '',
      deliveryOrder: '',
      remarks: ''
    },
    validationSchema: Yup.object().shape({
      DOdate: Yup.string().required('*Required'),
      DOValidity: Yup.number().required('*Required'),
      approvedLoanAmount: Yup.number().required('*Required'),
      deliveryOrder: Yup.string().required('*Required'),
      
    }),
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        activeBooking: {
          activeLoan:{
            deliveryOrderDate: values.DOdate,
            deliveryOrderValidity: Number(values?.DOValidity),
            sanctionedLoanAmount: Number(values?.approvedLoanAmount),
            deliveryOrderDocUrl: values?.deliveryOrder
          }
        }
      };

      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };



  useEffect(() => {
    if (compareDate( formik?.values?.DOdate, fiDate)) {
      setDoDate(false)
    } else {
      setDoDate(true)
    }
  }, [formik?.values?.DOdate, fiDate])

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={12}>
            <DatePickerCalender
              onChange={(value) => {
                formik.setFieldValue('DOdate', value);
              }}
              placeholder="Enter the DO Date*"
              value={
                formik.values.DOdate
                  ? new Date(formik.values.DOdate)
                  : null
              }
            />
            {formik.values.DOdate && formik.errors.DOdate  || formik.values.DOdate && Boolean(doDate) ? (
              <div style={{ color: 'red' }}>DO date should be greater than FI date</div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.DOValidity}
              inputType="number"
              name="DOValidity"
              onChange={(e) => formik?.setFieldValue('DOValidity', e.target.value)}
              placeholder="DO validity days*"
            />
            {formik.values.DOValidity &&
            (formik.errors.DOValidity || formik.touched.DOValidity) ? (
              <div style={{ color: 'red' }}>{formik.errors?.DOValidity}</div>
            ) : null}
          </Grid>

        
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.approvedLoanAmount}
              inputType="number"
              name="approvedLoanAmount"
              onChange={(e) => formik?.setFieldValue('approvedLoanAmount', e.target.value)}
              placeholder="Enter Approved Loan Amount*"
            />
            {formik.values.approvedLoanAmount &&
            (formik.errors.approvedLoanAmount && formik.touched.approvedLoanAmount) || (Number(formik?.values?.approvedLoanAmount)> Number(DoAmountData?.getLead?.activeBooking?.activeLoan?.appliedLoanAmount)) ? (
              <div style={{ color: 'red' }}>{formik.errors?.approvedLoanAmount}  Invalid approve loan Amount </div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
          <h6>Delivery Order</h6>

          </Grid>

          <Grid item xs={12} md={12}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id="deliveryOrder"
              value={formik.values.deliveryOrder}
              onChange={(value) => {
                formik.setFieldValue('deliveryOrder', value);
              }}
              placeholder="upload Document*"
            />
            {formik.errors.deliveryOrder && formik.touched.deliveryOrder ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.deliveryOrder}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              value={formik.values.remarks}
              name="remarks"
              onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
              placeholder="Enter the Remarks"
            />
          </Grid>
        </Grid>
        <CtaButton
          desireButtonText={desireButtonText}
          undesireButtonText={undesireButtonText}
          backBtn={canGoBack}
          onlyRed={true}
          reject={reject}
          disabled={!(formik.isValid && formik.dirty) || Boolean(doDate) || Boolean((Number(formik?.values?.approvedLoanAmount)> Number(DoAmountData?.getLead?.activeBooking?.activeLoan?.appliedLoanAmount)))}
        />
      </form>
    </div>
  );
}

export default DOform;
