import { TableCell, TableRow } from '@mui/material';
import { EnquiryStatus, Enquiry } from 'src/generated/hooks_and_more';
import Cell from './Cell';
import { EnquiriesDetail } from 'src/types';
import { useNavigate } from 'react-router';
import './EnquiryRow.css';
type EnquiryRowProps = {
  enquiries?: EnquiriesDetail;
};

function EnquiryRow({ enquiries }: EnquiryRowProps) {
  const navigation = useNavigate();
  return (
    <TableRow
      onClick={() =>
        navigation(`/vms-admin/enquiry/${enquiries.id}`, {
          state: {
            id: enquiries.id
          }
        })
      }
      className="enquiryRow"
    >
      <TableCell>{enquiries?.name}</TableCell>
      <TableCell>{enquiries?.phoneNo}</TableCell>
      <TableCell>{enquiries?.state}</TableCell>
      <TableCell>{enquiries?.district}</TableCell>
      <TableCell>{enquiries?.tehsil}</TableCell>
      <TableCell>{enquiries?.budget}</TableCell>
      {/* <TableCell>{enquiries?.name}</TableCell> */}
      <TableCell>{enquiries?.status}</TableCell>
      <TableCell>{enquiries?.updatedAt}</TableCell>
      <TableCell>{enquiries?.enquiryType}</TableCell>
      <TableCell>{enquiries?.centreName}</TableCell>
      <TableCell>{enquiries?.count}</TableCell>
      <TableCell>{enquiries?.createdAt}</TableCell>
      <TableCell>{enquiries?.nextfollowupDate}</TableCell>

      <TableCell>{enquiries?.createdByname}</TableCell>
      <TableCell>{enquiries?.enquiryCreatedDate}</TableCell>
    </TableRow>
  );
}

export default EnquiryRow;

{
  /* <TableCell
        style={{
          padding: '0',
          textAlign: 'center'
        }}
      >
        <p
          style={{
            width: '20em'
          }}
        >
          {enquiries?.name}
        </p>
        <p>{enquiries?.phoneNo}</p>
      </TableCell>
      <TableCell style={{ display: 'flex', padding: '0' }}>
        {enquiries?.followups?.map((followup) => {
          return (
            <Cell
              key={followup?.id}
              // label={followup?.status}
              backgroundColor={
                followup?.status === EnquiryStatus.Hot
                  ? 'red'
                  : followup?.status === EnquiryStatus.Warm
                    ? 'orange'
                    : 'blue' ?? 'grey'
              }
              hoverData={{
                followupDate: followup?.followupDate
              }}
            />
          );
        })}
        {enquiries?.isClosed ? (
          <Cell label={'X'} backgroundColor="grey" hoverData={{}} />
        ) : (
          <Cell label={'>>'} backgroundColor={'green'} opacity={'0.25'} />
        )}
      </TableCell> */
}
