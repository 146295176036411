import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import Calender from 'src/components/Calender/Calender';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import CloseIcon from '@mui/icons-material/Close';
import { ComparisonType } from 'src/constants/constant';

import { AutoCompleteValueType } from 'src/types';
import { cachedEnquiryDashBoardFilter } from 'src/utils/utility';
import { DateRange } from '@mui/icons-material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  p: 2
};

const closeBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '10px',
  cursor: 'pointer',
  color: 'rgb(195, 62, 62)'
};

type LeadFilterPropsType = {
  showModal: boolean;
  setShowModal: any;
  handleClose: () => void;
  onFilterHandler: (variables: any) => void;
  filterLoading?: boolean;
  calenderPlaceholder: string;
  filterInput?: any;
  resetSearch?: any;
  resetValue?: () => void;
  setreset?: () => void;
};

function EnquiryDashboardFilter({
  showModal,
  setShowModal,
  handleClose,
  onFilterHandler,
  filterLoading,
  filterInput,
  calenderPlaceholder,
  resetValue
}: LeadFilterPropsType) {
  const [filterVariables, setFilterVariables] = useState(null);
  const [comparisionType, setComparisionType] = useState<AutoCompleteValueType>(
    {
      label: 'Weekly',
      value: 'Weekly'
    }
  );

  useEffect(() => {
    if (filterInput?.start && filterInput?.end) {
      setFilterVariables({ dateRange: [filterInput?.start, filterInput?.end] });
    }
  }, []);

  const onFilterClick = () => {
    const variables = {
      dateRange: filterVariables?.dateRange,
      reportType: comparisionType?.label
    };
    cachedEnquiryDashBoardFilter({...variables})
    onFilterHandler(variables);
  };
  const onResetHandler = () => {
    setFilterVariables(null);
    resetValue()
  };

  return (
    <>
      <div
        style={{ display: 'flex', paddingRight: '20px', alignItems: 'center' }}
      >
        <Grid item>
          <Button
            sx={{ mt: { xs: 0, md: 0 } }}
            variant="contained"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Filter
          </Button>
        </Grid>
      </div>

      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={closeBtnStyle}>
            <CloseIcon
              onClick={() => {
                setShowModal(false);
              }}
            />
          </Box>
          <Grid container spacing={1} style={{ padding: '20px' }}>
            <Grid item xs={12} md={12}>
              <Calender
                placeholder={calenderPlaceholder}
                onDateChange={(e, placehldr: string) => {
                  setFilterVariables({ ...filterVariables, dateRange: e });
                }}
                filteredDate={filterVariables?.dateRange ?? []}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputAutoComplete
                disableClearable={true}
                onChange={(value) => {
                  console.log(value, 'jjjj');
                  setComparisionType(value);
                }}
                value={comparisionType}
                options={ComparisonType ?? []}
                placeholder="Report Type"
                optionString="label"
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Grid
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
                mb: '10px'
              }}
              md={12}
            >
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                style={{ width: '110px' }}
                variant="contained"
                onClick={() => {
                  onResetHandler();
                }}
              >
                Reset
              </Button>
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                
                style={{ width: '120px' }}
                variant="contained"
                onClick={onFilterClick}
                disabled={!Boolean(filterVariables) || !filterVariables?.dateRange }
                startIcon={
                  filterLoading ? <CircularProgress size={20} /> : null
                }
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default EnquiryDashboardFilter;
