import { Navigate } from "react-router-dom";
type ProtectedRoutePropsType = {
    children: JSX.Element
}

const ProtectedRoute = ({ children }) => {

    const userDetails = JSON.parse(localStorage.getItem('userData'));
    const isLoggedIn = userDetails && userDetails?.accessToken
    if (!isLoggedIn) {
        return <Navigate to="/" replace />;
    }
    return children;
};
export default ProtectedRoute;