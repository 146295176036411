import { useGetDocumentDetailsFormQuery } from 'src/generated/hooks_and_more';

import {
  Divider,
  Box,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer
} from '@mui/material';

import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles({
  root: {
    '& MuiGrid': {
      paddingLeft: '0px !important',
      paddingTop: '0px !important'
    }
  }
});

function ShowTable({ leadId }) {
  const { data, loading } = useGetDocumentDetailsFormQuery({
    variables: { id: leadId }
  });

  const classes = useStyles();
  let dealerStance = data?.getLead?.dealerStance

  function dealStanceCheck(){
    if(dealerStance){
      if(dealerStance?.clearChallan){
        return "Yes"
      }else if(dealerStance?.clearChallan === null){
        return null
      }
    }else{
      return null
    }
  }
  function blacklistedCheck(){
    if(dealerStance){
      if(dealerStance?.clearBlackList){
        return "Yes"
      }else if(dealerStance?.clearBlackList === null){
        return null
      }
    }else{
      return null
    }
  }


  function hsrpCheck(){
    
    if(dealerStance){
      if(dealerStance?.clearHSRP){
        return "Yes"
      }else if(dealerStance?.clearHSRP === null){
        return null
      }
    }else{
      return null
    }
  }

  const documentAvailable = data?.getLead?.dealershipDocuments;
  let dataValue = data?.getLead?.documentChecklist;
  let availableDocuments: {
    key: string;
    value: string;
  }[] = [
    {
      key: 'Registration Certificate',
      value:
        dataValue?.registrationCertificate &&
        documentAvailable?.registrationCertificate?.isAvailable
          ? `Yes, ${format(
              Date.parse(
                documentAvailable?.registrationCertificate?.expectedDate
              ),
              'dd-MM-yy'
            )}`
          : dataValue?.registrationCertificate &&
            !documentAvailable?.registrationCertificate?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Form 26',
      value:
        dataValue?.form26 && documentAvailable?.form26?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.form26?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.form26 && !documentAvailable?.form26?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Loanfore closure',
      value:
        dataValue?.loanForeclosure &&
        documentAvailable?.loanForeclosure?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.loanForeclosure?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.loanForeclosure &&
            !documentAvailable?.loanForeclosure?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Bank NOC',
      value:
        dataValue?.bankNOC && documentAvailable?.bankNOC?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.bankNOC?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.bankNOC && !documentAvailable?.bankNOC?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Form 35',
      value:
        dataValue?.form35 && documentAvailable?.form35?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.form35?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.form35 && !documentAvailable?.form35?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Insurance Certificate',
      value:
        dataValue?.insuranceCertificate &&
        documentAvailable?.insuranceCertificate?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.insuranceCertificate?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.insuranceCertificate &&
            !documentAvailable?.insuranceCertificate?.isAvailable
          ? 'No'
          : null
    },
   
    {
      key: 'Seller PAN/Form 60',
      value:
        dataValue?.form60_sellerPan &&
        documentAvailable?.form60_sellerPan?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.form60_sellerPan?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.form60_sellerPan &&
            !documentAvailable?.form60_sellerPan?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Form 28',
      value:
        dataValue?.form28 && documentAvailable?.form28?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.form28?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.form28 && !documentAvailable?.form28?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Form 29',
      value:
        dataValue?.form29 && documentAvailable?.form29?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.form29?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.form29 && !documentAvailable?.form29?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Form 30',
      value:
        dataValue?.form30 && documentAvailable?.form30?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.form30?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.form30 && !documentAvailable?.form30?.isAvailable
          ? 'No'
          : null
    },
   
   
   
    {
      key: 'Form 36',
      value:
        dataValue?.form36 && documentAvailable?.form36?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.form36?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.form36 && !documentAvailable?.form36?.isAvailable
          ? 'No'
          : null
    },
  
   
    {
      key: 'Seller Aadhar Card',
      value:
        dataValue?.sellerAadharCard &&
        documentAvailable?.sellerAadharCard?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.sellerAadharCard?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.sellerAadharCard &&
            !documentAvailable?.sellerAadharCard?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Owner Address Proof',
      value:
        dataValue?.ownerAddressProof &&
        documentAvailable?.ownerAddressProof?.isAvailable
          ? `Yes, ${format(
              Date.parse(documentAvailable?.ownerAddressProof?.expectedDate),
              'dd-MM-yy'
            )}`
          : dataValue?.ownerAddressProof &&
            !documentAvailable?.ownerAddressProof?.isAvailable
          ? 'No'
          : null
    },
    {
      key: 'Will Dealer clear challan issue? *',
      value: dealStanceCheck()

    },
    {
      key: 'Will dealer clear blacklisted issue? *',
      value: blacklistedCheck()

    },
    {
      key: 'will dealer clear HSRP issue? *',
      value: hsrpCheck()

    },
  ];
  availableDocuments = availableDocuments.filter((item) => item.value !== null);

  return (
    <>
      <TableContainer sx={{ backgroundColor: '#f6f6f6' }} component={Paper}>
        <Table
          sx={{
            padding: 0,
            boxShadow:
              ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }}
        >
          <TableBody sx={{ borderRadius: '1rem ' }}>
            <TableRow
              sx={{
                backgroundColor: '#C5C5C5',
                mt: '0'
              }}
            >
              <TableCell sx={{ fontWeight: 'bold' }} width="50%">
                Document Name
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} width="50%">
                Availability Status 
              </TableCell>
            </TableRow>

            {availableDocuments.map((i, index) => (
              <TableRow
                key={index}
                sx={{
                  fontSize: '16px'
                }}
              >
                <TableCell width="50%">{i?.key}</TableCell>
                <TableCell width="50%">{i?.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ShowTable;
