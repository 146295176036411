import { Box, SwipeableDrawer, Button } from '@mui/material';

import { useEffect, useState } from 'react';
import ListingTractorForm from './ListingTractor';
import {
  LeadStatus,
  useGetLeadDetailQuery,
  useLeadStatusFlowQuery
} from 'src/generated/hooks_and_more';
import { useParams } from 'react-router';

function ModalForListing({ open, setOpen, leadId, source, regNo }) {
  // const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const {
    data: leadDetailsData,
    refetch,
    loading
  } = useGetLeadDetailQuery({
    fetchPolicy: 'network-only',
    variables: {
      regNo: regNo
    }
  });

  const listingPrice = leadDetailsData?.getLead?.listingPrice;
  const sellingPrice = leadDetailsData?.getLead?.sellingPrice;
  //   const leadId = leadDetailsData.getLead?.id;
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);
  function getCurrentDimension() {
    return window.innerWidth;
  }

  function toggleDrawer(){
    setOpen(true);
  }
  return (
    <div>
      <SwipeableDrawer
        anchor={'right'}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={(e) => setAlert(true)}
        sx={{
          width:
            screenSize >= 600
              ? screenSize / 2
              : screenSize <= 600 && screenSize >= 400
              ? screenSize / 1.5
              : screenSize,
              zIndex:  22

        }}
      >
        <div>
          <Button
            color="secondary"
            size="medium"
            style={{
              fontWeight: 600,
              borderRadius: '50%',
              minWidth: 0
            }}
            onClick={() => setOpen(false)}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/507/507257.png"
              height={20}
              width={20}
            ></img>
          </Button>

          <Box
            style={{
              padding: '20px 50px 20px 50px',
              position: 'relative'
            }}
            sx={{
              width:
                screenSize >= 600
                  ? screenSize / 2
                  : screenSize <= 600 && screenSize >= 400
                  ? screenSize / 1.5
                  : screenSize,
            }}
          >
            <ListingTractorForm
              toggleDrawer={toggleDrawer}
              regNo={regNo}
              leadId={leadId}
              desireButtonText="Listing on Website"
              desireStatus={LeadStatus.ListingImagesUploaded}
              // listingImages={listingImages}
              listingPrice={listingPrice?.toString()}
              sellingPrice={sellingPrice}
              // onUpdateListingImage={navigateToUploadListingImages}
              brand={
                leadDetailsData?.getLead?.vehicle?.vehicleBrand?.name ?? ''
              }
              model={
                leadDetailsData?.getLead?.vehicle?.vehicleModel?.name ?? ''
              }
              district={leadDetailsData?.getLead?.centre?.district}
              state={leadDetailsData?.getLead?.centre?.state}
              tehsil={leadDetailsData?.getLead?.centre?.tehsil}
              manufacture_year={Number(new Date(
                leadDetailsData?.getLead?.vehicle?.manufacturingDate
              )?.getFullYear())}
              manufacture_month={new Date(
                leadDetailsData?.getLead?.vehicle?.manufacturingDate
              )?.toLocaleString('default', {
                month: 'long'
              })}
              engine_hours={
                !!leadDetailsData?.getLead?.vehicle?.hoursMeter
                  ? Number(leadDetailsData?.getLead?.vehicle?.hoursMeter)
                  : Number(900)
              }
              rc_available={
                leadDetailsData?.getLead?.vehicle?.isRcAvailable ? 'Yes' : 'No'
              }
              seller_name="Tractor Junction"
              mobile_number={leadDetailsData?.getLead?.centre?.phoneNumber?.toString()}
            />
          </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default ModalForListing;

