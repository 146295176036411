import React, { useState } from 'react';
import { ErrorMessage, useFormik } from 'formik';
import { Box, Button, Grid, TextField } from '@mui/material';
import Input from 'src/components/Form/Input';
import * as Yup from 'yup';
import '../CTA_Button/button.css';
import {
  BankName,
  LeadRef,
  PaymentFor,
  PaymentStatus
} from 'src/generated/hooks_and_more';
import { enumToItems, titleCaseToReadable } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import { LeadCreateAndUpdateProps } from 'src/types';
import { ActionType } from 'src/constants/constant';
import { RaisePaymentRequestSchema } from '../../ValidationSchema/ValidationSchema';
import ConfirmationPopup from '../RaisePaymentButton/ConfirmPopforPR';
import useCreateLeadStatusEvents from '../../hooks/useCreateLeadStatusEvents';

const RaisePaymentForEachRequest = (props: LeadCreateAndUpdateProps) => {
  const BankNameOptions = enumToItems(BankName);
  const [open, setOpen] = useState(false);
  const [leadInput, setLeadInput] = useState(null);

  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    paymentFor
  } = props;

  const { addingEvent, onPressDesiredButton, onPressUndesireButton } =
    useCreateLeadStatusEvents(toggleDrawer);

  const formik = useFormik({
    initialValues: {
      bankName: null,
      accountHolderName: null,
      accountNumber: '',
      ifscNumber: '',
      accountProof: ''
    },

    validationSchema: RaisePaymentRequestSchema,
    onSubmit: (values) => {
      const leadInput = {
        id: leadId,
        regNo: regNo,
        payments: [
          {
            bankName: values?.bankName?.value,
            accountHolderName: values?.accountHolderName,
            accountNo: values?.accountNumber,
            ifsc: values?.ifscNumber,
            for: paymentFor,
            status: PaymentStatus.Requested,
            proofUrl: formik?.values?.accountProof
          }
        ],
        documents: {
          bankAccountProofUrl: formik?.values?.accountProof
        }
      };

      setLeadInput(leadInput);
      // onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <h4> {titleCaseToReadable(paymentFor)} Details </h4>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} mt={1}>
          <Grid item md={12} xs={12}>
            <InputAutoComplete
              placeholder="Select a Bank Name*"
              value={formik.values.bankName}
              onChange={(value) => {
                formik.setFieldValue('bankName', value);
              }}
              options={BankNameOptions}
              optionString="label"
              getOptionLabel={(option) => option.name}
            />
            {formik.errors.bankName || formik.touched.bankName ? (
              <div style={{ color: 'red' }}>{formik.errors?.bankName}</div>
            ) : null}
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              size="small"
              autoComplete="off"
              id="outlined-uncontrolled"
              label="Enter the Account holder Name*"
              onChange={(e) =>
                formik.setFieldValue('accountHolderName', e?.target?.value)
              }
              placeholder="Enter the Account holder Name*"
            />
            {formik.values.accountHolderName &&
            (formik.touched.accountHolderName ||
              formik.errors.accountHolderName) ? (
              <div style={{ color: 'red' }}>
                {formik.errors?.accountHolderName}
              </div>
            ) : null}{' '}
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              size="small"
              id="outlined-uncontrolled"
              autoComplete="off"
              label="Enter the Account Number*"
              onChange={(e) =>
                formik.setFieldValue('accountNumber', e?.target?.value)
              }
              placeholder="Enter the Account holder Number*"
            />
            {formik.values.accountNumber &&
            (formik.errors.accountNumber || formik.touched.accountNumber) ? (
              <div style={{ color: 'red' }}>{formik.errors?.accountNumber}</div>
            ) : null}
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              size="small"
              id="outlined-uncontrolled"
              label="Enter IFSC code*"
              name="ifscNumber"
              autoComplete="off"
              onChange={(e) =>
                formik.setFieldValue('ifscNumber', e?.target?.value)
              }
              placeholder="Enter IFSC code*"
            />
            {formik.values.ifscNumber &&
            (formik?.touched?.ifscNumber || formik?.errors?.ifscNumber) ? (
              <div style={{ color: 'red' }}>{formik.errors?.ifscNumber}</div>
            ) : null}{' '}
          </Grid>
          <Grid item xs={12} md={12}>
            <h5> Account Proof</h5>
          </Grid>
          <Grid item xs={12} md={12} sx={{marginBottom: '0.7rem'}}>
            <FileUploadToS3
              accept="image/*,.pdf"
              id={String(Math.random())}
              value={formik?.values?.accountProof}
              onChange={(value) => {
                formik.setValues({
                  ...formik?.values,
                  accountProof: String(value)
                });
              }}
              placeholder="Payment Receipt*"
            />
            {formik.errors.accountProof && formik.touched.accountProof ? (
              <div style={{ color: 'red' }}>{formik.errors?.accountProof}</div>
            ) : null}
          </Grid>
        </Grid>

        <Button
          sx={{ marginTop: '0.5rem', width: '100%' }}
          id={
            !(formik?.dirty && formik?.isValid) ? 'cta-btn-disabled' : 'cta-btn' 
          }
          variant="contained"
          disabled={!(formik?.dirty && formik?.isValid)}
          type="submit"
          onClick={() => setOpen(true)}
        >
          Raise Payment Request
        </Button>

        <ConfirmationPopup
          open={open}
          handleClose={() => setOpen(false)}
          title={titleCaseToReadable(paymentFor)}
          onConfirmationHandler={() => {
            onPressDesiredButton(desireStatus, leadInput);
          }}
        />
      </form>
    </>
  );
};

export default RaisePaymentForEachRequest;
