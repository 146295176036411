import React, { useState } from 'react';
import { Formik, useFormik } from 'formik';
import './form.css';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';
import Input from 'src/components/Form/Input';
import {
  BankName,
  InsuranceType,
  InsurerName,
  Lead,
  LeadPatch,
  LeadRef,
  LeadSource,
  LeadStatus,
  useGetAllNewBrandsQuery,
  useModelByMakeLazyQuery,
  useModelByMakeQuery
} from 'src/generated/hooks_and_more';
import { enumToItems } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import {
  DealershipFormValidation,
  LeadFormValidation,
  VehicleDetailsPendingSchema
} from '../ValidationSchema/ValidationSchema';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { LeadCreateAndUpdateProps } from 'src/types';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

const VehicleDetailsPending = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    onPressOfDesiredOrUndesiredButton,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    leadId,
    regNo
  } = props;

  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  const { data: allBrands } = useGetAllNewBrandsQuery();
  const InsurerNameOptions = enumToItems(InsurerName);
  const insuranceTypeOptions = enumToItems(InsuranceType);

  const formik = useFormik({
    initialValues: {
      agencyName: '',
      agencyNo: '',
      regDate: '',
      certificateAvailable: 'no',
      isInsured: 'no',
      reposessionDate: '',
      fitnessDate: '',
      insurerName: null,
      insuranceType: null,
      policyNo: '',
      policyExpiryDate: ''
    },
    onSubmit: (values) => {
      leadInput = {
        regNo: props?.regNo,
        id: props?.leadId,
        auctioningAgency: {
          name: values?.agencyName,
          spocNo: values?.agencyNo
        },
        vehicle: {
          regNo: props?.regNo,
          registrationDate: values?.regDate,
          repossessionDate: values?.reposessionDate,
          isRcAvailable: values?.certificateAvailable === 'yes' ? true : false,
          fitnessValidUpto:
            values?.certificateAvailable === 'yes' ? values?.fitnessDate : null,
          isVehicleInsured: values?.isInsured === 'yes' ? true : false,
          insuranceDetails:
            values?.isInsured === 'yes'
              ? {
                  insurerName: values?.insurerName?.value,
                  insuranceType: values?.insuranceType?.value,
                  policyNumber: values?.policyNo,
                  policyExpiryDate: values?.policyExpiryDate
                }
              : null
        }
      };
      onPressOfDesiredOrUndesiredButton(leadInput);
    },
    validationSchema: VehicleDetailsPendingSchema
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.agencyName}
            name="agencyName"
            onChange={(e) =>
              formik?.setFieldValue('agencyName', e.target.value)
            }
            placeholder="Auction Agency Name*"
          />
          {formik.values.agencyName &&
          (formik.errors.agencyName || formik.touched.agencyName) ? (
            <div style={{ color: 'red' }}>{formik.errors?.agencyName}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.agencyNo}
            name="agencyNo"
            onChange={(e) => formik?.setFieldValue('agencyNo', e.target.value)}
            placeholder="Agency Spoc Mobile*"
          />
          {formik.values.agencyNo &&
          (formik.errors.agencyNo || formik.touched.agencyNo) ? (
            <div style={{ color: 'red' }}>{formik.errors?.agencyNo}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <DatePickerCalender
            onChange={(value) => {
              formik.setFieldValue('regDate', value);
            }}
            placeholder="Registration Date*"
            value={
              formik.values.regDate ? new Date(formik.values.regDate) : null
            }
          />
          {formik.values.regDate &&
          (formik.errors.regDate || formik.touched.regDate) ? (
            <div style={{ color: 'red' }}>{formik.errors?.regDate}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <DatePickerCalender
            minDate={
              formik.values.regDate
                ? new Date(
                    new Date(formik.values.regDate).setDate(
                      new Date(formik.values.regDate).getDate() + 1
                    )
                  )
                : null
            }
            onChange={(value) => {
              formik.setFieldValue(
                'reposessionDate',
                value === null ? '' : value
              );
            }}
            placeholder="Reposession Date*"
            value={
              formik.values.reposessionDate
                ? new Date(formik.values.reposessionDate)
                : null
            }
          />
          {formik.errors.reposessionDate && formik.touched.reposessionDate ? (
            <div style={{ color: 'red' }}>{formik.errors?.reposessionDate}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <p>Registration Certificate Available?*</p>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={'no'}
              sx={{
                width: '300px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                onClick={() =>
                  formik.setFieldValue('certificateAvailable', 'yes')
                }
                label="Yes"
              />
              <FormControlLabel
                value="no"
                onClick={() =>
                  formik.setFieldValue('certificateAvailable', 'no')
                }
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {formik.values.certificateAvailable === 'yes' && (
          <Grid item xs={12} md={12}>
            <DatePickerCalender
              minDate={
                formik.values.regDate
                  ? new Date(
                      new Date(formik.values.regDate).setDate(
                        new Date(formik.values.regDate).getDate() + 1
                      )
                    )
                  : null
              }
              onChange={(value) => {
                formik.setFieldValue('fitnessDate', value);
              }}
              placeholder="Fitness Valid upto*"
              value={
                formik.values.fitnessDate
                  ? new Date(formik.values.fitnessDate)
                  : null
              }
            />
            {formik.errors.fitnessDate && formik.touched.fitnessDate ? (
              <div style={{ color: 'red' }}>{formik.errors?.fitnessDate}</div>
            ) : null}
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <p>Is Vehicle Insured ?*</p>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue={'no'}
              sx={{
                width: '300px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                onClick={() => formik.setFieldValue('isInsured', 'yes')}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                onClick={() => formik.setFieldValue('isInsured', 'no')}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {formik.values.isInsured === 'yes' && (
          <>
            <Grid item xs={12} md={12}>
              <InputAutoComplete
                value={formik.values.insurerName}
                name="insurerName"
                options={InsurerNameOptions}
                onChange={(val) => formik?.setFieldValue('insurerName', val)}
                placeholder="Select Insurer Name*"
              />
              {formik.errors.insurerName && formik.touched.insurerName ? (
                <div style={{ color: 'red' }}>{formik.errors?.insurerName}</div>
              ) : null}
            </Grid>
            <Grid item xs={12} md={12}>
              <InputAutoComplete
                value={formik.values.insuranceType}
                name="insuranceType"
                options={insuranceTypeOptions}
                onChange={(val) => formik?.setFieldValue('insuranceType', val)}
                placeholder="Insurance Type*"
              />
              {formik.errors.insuranceType && formik.touched.insuranceType ? (
                <div style={{ color: 'red' }}>
                  {formik.errors?.insuranceType}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12} md={12}>
              <Input
                value={formik.values.policyNo}
                name="policyNo"
                onChange={(e) =>
                  formik?.setFieldValue('policyNo', e.target.value)
                }
                placeholder="Enter Policy Number*"
              />
              {formik.errors.policyNo && formik.touched.policyNo ? (
                <div style={{ color: 'red' }}>{formik.errors?.policyNo}</div>
              ) : null}
            </Grid>
            <Grid item xs={12} md={12}>
              <DatePickerCalender
                minDate={
                  formik.values.regDate
                    ? new Date(
                        new Date(formik.values.regDate).setDate(
                          new Date(formik.values.regDate).getDate() + 1
                        )
                      )
                    : null
                }
                onChange={(value) => {
                  formik.setFieldValue('policyExpiryDate', value);
                }}
                placeholder="Enter Policy Expiry Date*"
                value={
                  formik.values.policyExpiryDate
                    ? new Date(formik.values.policyExpiryDate)
                    : null
                }
              />
              {formik.errors.policyExpiryDate &&
              formik.touched.policyExpiryDate ? (
                <div style={{ color: 'red' }}>
                  {formik.errors?.policyExpiryDate}
                </div>
              ) : null}
            </Grid>
          </>
        )}
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        backBtn={canGoBack}
        undesireButtonText={undesireButtonText}
        disabled={!(formik.isValid && formik.dirty)}
        reject={reject}
      />
    </form>
  );
};

export default VehicleDetailsPending;
