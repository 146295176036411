import React, { useState } from 'react';
import { useFormik } from 'formik';

import { Box, Button, Grid } from '@mui/material';
import Input from 'src/components/Form/Input';
import {
  LeadRef,
  LeadStatus,
  PaymentFor,
  PaymentMethod,
  PaymentStatus,
  useGetLeadDealAmountsQuery
} from 'src/generated/hooks_and_more';
import { enumToItems } from 'src/utils/utility';
import InputAutoComplete from 'src/components/Form/InputAutoComplete';
import DatePickerCalender from 'src/components/Calender/DatePickerCalender';
import { DealPaymentPendingSchema } from '../ValidationSchema/ValidationSchema';
import useCreateLeadStatusEvents from '../hooks/useCreateLeadStatusEvents';
import { LeadCreateAndUpdateProps } from 'src/types';
import FileUploadToS3 from 'src/components/Form/FileUploadToS3';
import CtaButton from './CTA_Button/CtaButton';
import { ActionType } from 'src/constants/constant';

const DealPaymentPending = (props: LeadCreateAndUpdateProps) => {
  const {
    toggleDrawer,
    leadId,
    onPressOfDesiredOrUndesiredButton,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    regNo
  } = props;
  let leadInput: LeadRef = {
    regNo: regNo,
    id: leadId
  };
  let rejectInput = {
    id: leadId,
    regNo: regNo
  };
  const { data } = useGetLeadDealAmountsQuery({
    variables: { id: leadId },
    onCompleted(data) {
      formik.setFieldValue('dealAmount', data?.getLead?.finalBidAmount);
    }
  });
  const paymentModeOptions = enumToItems(PaymentMethod);
  const payFor = PaymentFor;
  const formik = useFormik({
    initialValues: {
      paymentMode: null,
      amount: '',
      paymentDate: '',
      paymentReceipt: '',
      remarks: ''
    },
    validationSchema: DealPaymentPendingSchema,
    onSubmit: (values) => {
      leadInput = {
        regNo: regNo,
        id: leadId,
        payments: [
          {
            mode: values?.paymentMode?.value as PaymentMethod,
            amount: Number(values?.amount),
            status: PaymentStatus.Done,
            for: payFor?.DealPayment,
            createdAt: values?.paymentDate,
            receiptUrl: values?.paymentReceipt
          }
        ],
        documents: {
          dealPaymentProofUrl: values?.paymentReceipt
        }
      };
      onPressOfDesiredOrUndesiredButton(leadInput, values?.remarks);
    }
  });
  const reject = () => {
    onPressOfDesiredOrUndesiredButton(rejectInput, '', ActionType.UNDESIRED);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <h5>{`Deal Amount:- ${data?.getLead?.finalBidAmount}`}</h5>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={12}>
          <InputAutoComplete
            value={formik.values.paymentMode}
            name="paymentMode"
            options={paymentModeOptions}
            onChange={(val) => formik?.setFieldValue('paymentMode', val)}
            placeholder="Select Payment Mode*"
          />
          {formik.errors.paymentMode && formik.touched.paymentMode ? (
            <div style={{ color: 'red' }}>{formik.errors?.paymentMode}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.amount}
            inputType="number"
            name="amount"
            onChange={(e) => formik?.setFieldValue('amount', e.target.value)}
            placeholder="Enter the Payment Amount in INR*"
          />
          {formik.values.amount &&
          (formik.errors.amount || formik.touched.amount) ? (
            <div style={{ color: 'red' }}>{formik.errors?.amount}</div>
          ) : null}
        </Grid>

        <Grid item xs={12} md={12}>
          <DatePickerCalender
            onChange={(value) => {
              formik.setFieldValue('paymentDate', value);
            }}
            placeholder="Enter the Payment Date*"
            value={
              formik.values.paymentDate
                ? new Date(formik.values.paymentDate)
                : null
            }
          />
          {formik.errors.paymentDate && formik.touched.paymentDate ? (
            <div style={{ color: 'red' }}>{formik.errors?.paymentDate}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <FileUploadToS3
                        accept="image/*,.pdf"

            id="paymentReceipt"
            value={formik.values.paymentReceipt}
            onChange={(value) => {
              formik.setFieldValue('paymentReceipt', value);
            }}
            placeholder="Payment Receipt*"
          />
          {formik.errors.paymentReceipt && formik.touched.paymentReceipt ? (
            <div style={{ color: 'red' }}>{formik.errors?.paymentReceipt}</div>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            value={formik.values.remarks}
            name="remarks"
            onChange={(e) => formik?.setFieldValue('remarks', e.target.value)}
            placeholder="Enter the Remarks"
          />
        </Grid>
      </Grid>
      <CtaButton
        desireButtonText={desireButtonText}
        undesireButtonText={undesireButtonText}
        reject={reject}
        onlyRed={true}
        disabled={!(formik.isValid && formik.dirty)}
      />
    </form>
  );
};

export default DealPaymentPending;
