import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ToastMessage from 'src/screens/inventory/ToastMessage';
import './style.css';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
type ModalProps = {
  open: boolean;
  handleClose: () => void;
  toastMsg: boolean;
  onConfirmationHandler: (reset: () => void) => void;
  textToShow: string;
  reset?: () => void;
};

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ConfimationModal({
  open,
  handleClose,
  onConfirmationHandler,
  toastMsg,
  textToShow,
  reset
}: ModalProps) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box sx={{ width: '400px' }}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Typography gutterBottom>{textToShow}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ background: 'red' }}
              variant="contained"
              id="notconfirm"
              onClick={handleClose}
            >
              Not Confirm
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={() => {
                onConfirmationHandler(reset);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </BootstrapDialog>
      {/* {toastMsg ? <ToastMessage open="true" message="Center Added Successfully" /> : <></>} */}
    </div>
  );
}
