import EnquiryRow from 'src/components/Composite/EnquiryRow';
import TableWrapper from 'src/components/Tables';
import { ENQURY_TABLE_HEADER } from 'src/constants/constant';

import {
  UserRole,
  useGetDetailEnquiriesLazyQuery
} from 'src/generated/hooks_and_more';
import {
  Box,
  InputBase,
  Grid,
  TableCell,
  TableRow,
  Typography,
  styled,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useDebounce from 'src/customHooks/useDebounce';
import EnquiryFilter from './enquiryFilter';
import { ExportCSV } from '../leads/leadExport';
import { useReactiveVar } from '@apollo/client';
import { cachedEnquiryFilter } from 'src/utils/utility';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';

const SearchWrap = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(188,196,255,0.25)',
  '&:hover': {
    backgroundColor: 'rgba(188,196,255,0.25)'
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

export default function Enquiry() {
  const filterInput = useReactiveVar(cachedEnquiryFilter);
  const [searchStringValue, setSearchString] = useState('');
  const [filteredInput, setFilteredInput] = useState(null);
  const [varr, setVarr] = useState(null);
  const debouncedSearch = useDebounce(searchStringValue, 1500);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const handleClose = () => setShowModal(false);
  const [enquiriesDataa, setEnquiriesData] = useState([]);
  const [dataExport, setExportData] = useState(null);

  const { loggedInUser } = useLoggedInUser();

  const [GetEnquiries, { data: enquiriesData, loading }] =
    useGetDetailEnquiriesLazyQuery({
      onCompleted() {
        setEnquiriesData([
          ...enquiriesDataa,
          ...enquiriesData?.filteredEnquiry
        ]);
      }
    });
  const [ExportEnquiries, { data: exportEnquiry, loading: exportLoading }] =
    useGetDetailEnquiriesLazyQuery({
      fetchPolicy: 'network-only',

      onCompleted(data) {
        const exportData = exportEnquiry?.filteredEnquiry
          ?.map((item, index) => {
            const lastFollowupIndex = item?.followups?.length - 1;
            const interestedProducts =
              item?.interestedIn
                ?.filter(
                  (product) =>
                    product?.brand?.name !== null && product?.name !== null
                )
                ?.map((product) => ({
                  make: product?.brand?.name,
                  model: product?.name + ','
                })) ?? [];

            return {
              Name: item?.customer?.name ?? '-',
              Mobile: item?.customer?.phoneNo ?? '-',
              State: item?.customer?.customerState?.state ?? '-',
              District: item?.customer?.customerDistrict?.name ?? '-',
              Tehsil: item?.customer?.customerTehsil?.name ?? '-',
              Budget: item?.budget ?? 0,
              Demanded_Tractors: interestedProducts?.[0]?.make
                ? interestedProducts?.[0]?.make +
                  ' ' +
                  interestedProducts?.[0]?.model
                : '-',
              Enquiry_Status: item?.isClosed ? 'Closed' : 'Open',
              Last_Updated_On:
                lastFollowupIndex >= 0 &&
                item?.followups[lastFollowupIndex]?.followupDate
                  ? format(
                      Date.parse(
                        item?.followups[lastFollowupIndex]?.followupDate
                      ),
                      'dd-MM-yyyy'
                    )
                  : '-',
              Enquiry_Closure_Reason:
                item?.enquiryClosure?.enquiryCloseReason ?? '-',
              Enquiry_Type: item?.status ?? '-',
              Tentative_Purchase: item?.tentativePurchaseTime ?? '-',
              Walk_In_Type: item?.enquiryType ?? '-',
              Centre_Name: item?.centre?.name ?? '-',
              Date_Of_Enquiry: item?.createdAt
                ? format(new Date(item?.createdAt), 'dd/MM/yyyy')
                : '-',
              Followup_count: item?.followupsAggregate?.count - 1 ?? 0,
              Last_Visit_Date:
                lastFollowupIndex >= 0 &&
                item?.followups[lastFollowupIndex]?.followupDate
                  ? format(
                      Date.parse(
                        item?.followups[lastFollowupIndex]?.followupDate
                      ),
                      'dd-MM-yyyy'
                    )
                  : '-',
              Created_By: item?.createdBy?.name ?? '-',
              Enquiry_Created_Date: item?.followups?.[0]?.followupDate
                ? format(
                    new Date(item?.followups?.[0]?.followupDate),
                    'dd/MM/yyyy'
                  )
                : '-',
              Next_Followup_Date:
                lastFollowupIndex >= 0 &&
                item?.followups[lastFollowupIndex]?.nextFollowup?.followupDate
                  ? format(
                      Date.parse(
                        item?.followups[lastFollowupIndex]?.nextFollowup
                          ?.followupDate
                      ),
                      'dd/MM/yyyy'
                    )
                  : '-',
              remarks:
                lastFollowupIndex > 0
                  ? item?.followups[lastFollowupIndex]?.remarks
                  : '-'
            };
          })
          .reverse();
        setExportData(exportData);
      }
    });

  const onFilterHandler = (variables: any) => {
    setVarr(variables);

    GetEnquiries({
      variables: {
        ...variables,
        centre:
          loggedInUser?.role === UserRole.CentreManager
            ? loggedInUser?.centre.name
            : null
      },
      onCompleted(data) {
        setEnquiriesData([...enquiriesDataa, ...data?.filteredEnquiry]);
      }
    });
  };

  console.log(
    { loggedInUser },
    loggedInUser?.role === UserRole.CentreManager
      ? loggedInUser?.centre.name
      : null
  );

  useEffect(() => {
    if (debouncedSearch) {
      GetEnquiries({
        variables: {
          search: `/.*${searchStringValue}.*/i`,
          centre:
            loggedInUser?.role === UserRole.CentreManager
              ? loggedInUser?.centre.name
              : null
        },
        onCompleted(data) {
          setEnquiriesData([...data?.filteredEnquiry]);
        }
      });
      cachedEnquiryFilter({ search: `/.*${searchStringValue}.*/i` });
    } else {
      GetEnquiries({
        variables: {
          offset: pageNumber,
          pageSize: 20,
          centre:
            loggedInUser?.role === UserRole.CentreManager
              ? loggedInUser?.centre.name
              : null
        },
        onCompleted(data) {
          setEnquiriesData([...enquiriesDataa, ...data?.filteredEnquiry]);
        }
      });
    }
  }, [debouncedSearch]);
  useEffect(() => {
    if (filterInput) {
      if (Boolean(filterInput?.offset) || filterInput?.search) {
        GetEnquiries({
          variables: {
            ...filterInput,
            centre:
              loggedInUser?.role === UserRole.CentreManager
                ? loggedInUser?.centre.name
                : null
          },
          onCompleted(data) {
            setEnquiriesData([...data?.filteredEnquiry]);
          }
        });
      } else {
        GetEnquiries({
          variables: {
            ...filterInput,
            offset: pageNumber,
            pageSize: 20,
            centre:
              loggedInUser?.role === UserRole.CentreManager
                ? loggedInUser?.centre.name
                : null
          },
          onCompleted(data) {
            setEnquiriesData([...enquiriesDataa, ...data?.filteredEnquiry]);
          }
        });
      }
    } else {
      GetEnquiries({
        variables: {
          offset: pageNumber,
          pageSize: 20,
          centre:
            loggedInUser?.role === UserRole.CentreManager
              ? loggedInUser?.centre.name
              : null
        }
      });
    }
  }, [filterInput, pageNumber, loggedInUser]);
  const resetSearch = () => {
    setSearchString('');
  };
  const exportQuery = () => {
    const variable = { ...filterInput, offset: null };
    ExportEnquiries({ variables: variable });

    return dataExport;
  };
  // console.log(enquiriesDataa, ';;;;;');
  const resetExport = () => {
    setExportData([]);
  };
  return (
    <>
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item display="flex">
            <div>
              <Typography variant="h3" component="h3" gutterBottom>
                Enquiry
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0.5em'
              }}
            >
              <SearchWrap>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  // onKeyUp={(e) => {
                  //   if (e.code === 'Enter') {
                  //     navigate(`?search=${searchStringValue}`, {
                  //       replace: true
                  //     });
                  //   }
                  // }}
                  value={searchStringValue}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </SearchWrap>
            </div>
          </Grid>

          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <EnquiryFilter
              showModal={showModal}
              setShowModal={setShowModal}
              handleClose={handleClose}
              onFilterHandler={onFilterHandler}
              filterLoading={loading}
              filterInput={filteredInput}
              resetSearch={resetSearch}
            />
            {enquiriesDataa?.length ? (
              <Box sx={{ paddingLeft: '20px' }}>
                <ExportCSV
                  callQuery={exportQuery}
                  loading={exportLoading}
                  csvData={dataExport}
                  fileName="Enquiries"
                  reset={resetExport}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Box sx={{ px: 2, pb: 2 }}>
        {(pageNumber === 1 || filterInput?.offset === 1) && loading ? (
          <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <h5>Loading..</h5>
          </div>
        ) : (
          <>
            <TableWrapper header={ENQURY_TABLE_HEADER} TextAlign="center">
              {enquiriesDataa?.length ? (
                enquiriesDataa?.map((item, index) => {
                  const lastFollowupIndex = item?.followups?.length - 1;
                  const enquiries = {
                    id: item?.id,
                    name: item?.customer?.name,
                    phoneNo: item?.customer?.phoneNo ?? '-',
                    state: item?.customer?.customerState?.state ?? '-',
                    district: item?.customer?.customerDistrict?.name ?? '-',
                    tehsil: item?.customer?.customerTehsil?.name ?? '-',
                    budget: item?.budget ?? 0,
                    status: item?.isClosed ? 'Closed' : 'Open',
                    updatedAt:
                      lastFollowupIndex >= 0 &&
                      item?.followups?.[lastFollowupIndex]?.followupDate
                        ? format(
                            Date.parse(
                              item?.followups?.[lastFollowupIndex]?.followupDate
                            ),
                            'dd-MM-yyyy'
                          )
                        : '-',
                    enquiryType: item?.status ?? '-',
                    centreName: item?.centre?.name ?? '-',
                    count: item?.followupsAggregate?.count - 1 ?? 0,
                    createdByname: item?.createdBy?.name ?? '-',
                    createdAt: item?.createdAt
                      ? format(Date.parse(item?.createdAt), 'dd-MM-yyyy')
                      : '-',
                    enquiryCreatedDate: item?.followups?.[0]?.followupDate
                      ? format(
                          new Date(item?.followups?.[0]?.followupDate),
                          'dd-MM-yyyy'
                        )
                      : '-',
                    nextfollowupDate:
                      lastFollowupIndex >= 0 &&
                      item?.followups?.[lastFollowupIndex]?.nextFollowup
                        ?.followupDate
                        ? format(
                            Date.parse(
                              item?.followups?.[lastFollowupIndex]?.nextFollowup
                                ?.followupDate
                            ),
                            'dd-MM-yyyy'
                          )
                        : '-'
                  };

                  return (
                    <>
                      <EnquiryRow key={item?.id} enquiries={enquiries} />
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '20px',
                        fontWeight: '900'
                      }}
                    >
                      <Typography>No data found</Typography>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableWrapper>
            {loading && enquiriesDataa?.length ? (
              <div
                style={{ padding: '30px' }}
                className="d-flex flex-column  justify-content-center align-items-center gap-2"
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
                <h5>Loading..</h5>
              </div>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '30px'
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    display:
                      enquiriesData?.filteredEnquiry?.length >= 20
                        ? 'block'
                        : 'none'
                  }}
                  onClick={() => {
                    if (filterInput?.offset === 1) {
                      setPageNumber(2);
                      cachedEnquiryFilter({ ...filterInput, offset: null });
                    } else {
                      setPageNumber(pageNumber + 1);
                    }
                  }}
                >
                  Load More
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}
