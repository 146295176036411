import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import './style.css';
import {
  BankConfirmationStatus,
  BulkLeadInput,
  useCreateBulkLeadsLazyQuery,
  useLeadsForBulkApprovalLazyQuery
} from 'src/generated/hooks_and_more';
import {
  arraysHaveSameValues,
  forlogtitleCase,
  getUserData
} from 'src/utils/utility';

import * as FileSaver from 'file-saver';
import { useToasts } from 'react-toast-notifications';
function BulkApproval({ refetchingBulk }: any) {
  const user = getUserData();
  const userDetails: string = user.userId;
  const [file, setFile] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [exportData, setExportData] = useState(null);
  const [downloadButton, setDownloadButton] = useState(false);
  const [totalCount, setLeadTotalCount] = useState(null);

  const [sheetName, setSheetName] = useState('');

  const [open, setOpen] = useState(false);
  const { addToast } = useToasts();
  const [
    readyForApprovalData,
    { data: approvalData, loading: approvalLoading }
  ] = useLeadsForBulkApprovalLazyQuery({
    fetchPolicy: 'no-cache'
  });
  const handleConvert = (inputFile) => {
    if (inputFile) {
      console.log('inside');
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        const keys = Object?.keys(json[0]);
        const header = [
          'regNo',
          'finalBidAmount',
          'proposedBidAmount',
          'source',
          'bidStatus',
          'email',
          'bankConfirmationStatus'
        ];

        if (arraysHaveSameValues(keys, header)) {
          setSheetName(sheetName);
          setJsonData(json);
          setOpen(true);
          setDownloadButton(false);
        } else {
          addToast('Invalid file uploaded!!!', {
            appearance: 'error',
            autoDismiss: true
          });
        }
      };
      reader.readAsBinaryString(inputFile);
    }
  };

  function downloadReport(value) {
    const exportValue = value?.bulkApproval?.map((i) => {
      return {
        regNo: i?.regNo ?? '-',
        finalBidAmount: i?.finalBidAmount ?? '-',
        proposedBidAmount: i?.proposedBidAmount ?? '-',
        bidStatus: i?.bidStatus ?? '-',
        bankConfirmationStatus: i?.bankConfirmationStatus ?? '-',
        email: i?.email ?? '-',
        source: i?.source ?? '-',
        processingStatus: i?.processingStatus ?? '-',
        failureReason: i?.failureReason ?? '-'
      };
    });
    const date = new Date();
    exportToCSV(exportValue, `${sheetName}_report_${date.toISOString()}`);

    addToast('Report file has been downloaded', {
      appearance: 'success',
      autoDismiss: true
    });
  }
  function handleUploadLeads() {
    const valueJson = jsonData?.map((item) => {
      return {
        ...item,
        regNo: item?.regNo ? item?.regNo?.toString() : null,
        source: item?.source ? item?.source?.toString() : null,
        proposedBidAmount: item?.proposedBidAmount
          ? item?.proposedBidAmount
          : null,
        finalBidAmount: item?.finalBidAmount ? item?.finalBidAmount : null,
        bidStatus: item?.bidStatus ? item?.bidStatus?.toString() : null,
        bankConfirmationStatus: item?.bankConfirmationStatus
          ? item?.bankConfirmationStatus?.toString()
          : null,
        email: item?.email ? item?.email?.toString() : null
      };
    });

    readyForApprovalData({
      variables: {
        createdBy: userDetails,
        leads: valueJson as BulkLeadInput[]
      },
      onCompleted(value) {
        console.log(value);
        downloadReport(value);
        setExportData(value);
        countLeadStatus(value);
        addToast('Leads has been approved', {
          appearance: 'success',
          autoDismiss: true
        });
        setDownloadButton(true);
        setOpen(false);
        setFile('');
      },

      onError(err) {
        addToast('Error occurred while creating leads!!!', {
          appearance: 'error',
          autoDismiss: true
        });
        setOpen(false);
        setDownloadButton(true);
        setFile('');
      }
    });
  }

  const keys = [
    { headerKey: 'regNo', label: 'Reg. No.' },
    { headerKey: 'proposedBidAmount', label: 'Proposed Bid Amount' },
    { headerKey: 'finalBidAmount', label: 'Final Bid Amount' },
    { headerKey: 'bidStatus', label: 'Bid Status' },
    { headerKey: 'bankConfirmationStatus', label: 'Bank Confirmation Status' },
    { headerKey: 'email', label: 'email' },
    { headerKey: 'source', label: 'source' }
  ];
  function countLeadStatus(value) {
    let failure = 0;
    let success = 0;
    let total = 0;
    value?.bulkApproval?.map((i) => {
      Object?.keys(i)?.map((item) => {
        if (item == 'processingStatus') {
          if (i[item] === 'Success' || i[item] === 'success') {
            success = success + 1;
          } else if (i[item] === 'Failed' || i[item] === 'failed') {
            failure = failure + 1;
          }
        }
      });
    });
    total = value?.createBulkLeads?.length;
    const countingValues = {
      failure,
      success,
      total: value?.bulkApproval?.length || 0
    };
    setLeadTotalCount({ ...countingValues });
    return countingValues;
  }

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    console.log(fileName);
    const ws = XLSX.utils.json_to_sheet(csvData);
    const colWidths = [
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 }
    ];
    ws['!cols'] = colWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  console.log(downloadButton, open, '...............hdhdh');

  return (
    <>
      {!downloadButton ? (
        !open ? (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Grid className="InputBox">
              <input
                type="file"
                accept=".xls,.xlsx"
                value={file?.[0]?.fileName || ''}
                onChange={(e) => {
                  console.log('input called');
                  const selectedFile = e.target.files[0];
                  setFile(selectedFile);
                  handleConvert(selectedFile);
                }}
              />
            </Grid>

            <Button variant="contained">
              <Link
                href="/PH-approval-sheet.xlsx"
                target="_blank"
                sx={{
                  color: 'white',
                  ':hover': {
                    color: 'white',
                    textDecoration: 'none'
                  }
                }}
              >
                Download template
              </Link>
            </Button>
          </Grid>
        ) : (
          <Grid
            sx={{
              paddingBottom: 1,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Grid className="InputBox">
              <Typography variant="h3">
                Total Leads - {jsonData?.length || 0}
              </Typography>
            </Grid>

            <Grid item>
              <Button
                disabled={approvalLoading ? true : false}
                variant="contained"
                onClick={() => {
                  handleUploadLeads();
                  // refetchingBulk(true);
                }}
              >
                Bulk Approval
              </Button>

              {approvalLoading && (
                <CircularProgress
                  size={20}
                  color="primary"
                  sx={{ marginLeft: '0.5rem' }} // Add margin to separate from the button
                />
              )}
            </Grid>
          </Grid>
        )
      ) : null}

      <Grid mt={2}>
        {open && (
          <TableContainer className="table-container-bulk" component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {keys.map((key, index) => (
                    <TableCell className="no-wrap-table" key={index}>
                      {key?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {jsonData.length > 0 && jsonData[0]?.regNo != '' ? (
                  jsonData?.map((item, index) => (
                    <TableRow key={index}>
                      {keys.map((key, index) => (
                        <TableCell className="no-wrap-table" key={index}>
                          {key?.headerKey === 'regNo'
                            ? item[key?.headerKey]
                            : forlogtitleCase(item[key?.headerKey])}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '20px',
                          fontWeight: '900'
                        }}
                      >
                        <Typography>No data found</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      {downloadButton && !open && (
        <>
          <Grid>
            <TableContainer sx={{ mt: 3 }} component={Paper}>
              <Table>
                <TableRow>
                  <TableCell sx={{ fontWeight: '500' }}>
                    Leads Requested
                  </TableCell>
                  <TableCell>{totalCount?.total ?? 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Leads accepted</TableCell>
                  <TableCell>{totalCount?.success ?? 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Leads rejected</TableCell>
                  <TableCell>{totalCount?.failure ?? 0}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            md={6}
            xs={6}
            mt={5}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              disabled={!exportData ? true : false}
              sx={{ marginRight: 1 }}
              variant="outlined"
              onClick={() => {
                setOpen(true);
                setDownloadButton(false);
                // downloadReport(exportData);
              }}
            >
              Back
            </Button>
            <Button
              disabled={!exportData ? true : false}
              variant="contained"
              onClick={() => {
                downloadReport(exportData);
              }}
            >
              Download
            </Button>
          </Grid>
        </>
      )}
    </>
  );
}

export default BulkApproval;
