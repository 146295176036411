import React, { useState, useEffect, Fragment } from 'react';
import { LeadDetailsPropsType } from './ApplicationDetails';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { DEFAULT_TRACTOR_IMAGE, ImageAtStage } from 'src/constants/constant';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { enumToItems, titleCaseToReadable } from 'src/utils/utility';
import { ImageStage } from 'src/generated/hooks_and_more';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
type TypeImageAtStage = {
  stage: string;
  images: ImagesType[];
};

type ImagesType = {
  label: string;
  imgPath: string;
};

export default function LeadImage({ currentLead }: LeadDetailsPropsType) {
  const [images, setImages] = useState([]);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [stageValue, setStageValue] = useState<TypeImageAtStage | any>(null);
  const maxSteps = stageValue?.images?.length ?? 0;

  useEffect(() => {
    
    const image: (TypeImageAtStage | any)[] = ImageAtStage?.map((item) => {
      const imagesAtStage = currentLead?.vehicle?.images.filter(
        (img) => img.imagesTakenAtStage === item.value
      )?.[0];
      const inspectionVideo = imagesAtStage?.inspectionVideoUrl; //?? DEFAULT_TRACTOR_IMAGE;
      const frontBody = imagesAtStage?.frontBodySide; //?? DEFAULT_TRACTOR_IMAGE;
      const leftBody = imagesAtStage?.leftBodySide; //?? DEFAULT_TRACTOR_IMAGE;
      const backBody = imagesAtStage?.backBodySide; //?? DEFAULT_TRACTOR_IMAGE;
      const rightBody = imagesAtStage?.rightBodySide; //?? DEFAULT_TRACTOR_IMAGE;

      const backLeftTyre = imagesAtStage?.backLeftTyre; //?? DEFAULT_TRACTOR_IMAGE;
      const backRightTyre = imagesAtStage?.backRightTyre; //?? DEFAULT_TRACTOR_IMAGE;
      const frontLeftTyre = imagesAtStage?.frontLeftTyre; //?? DEFAULT_TRACTOR_IMAGE;
      const frontRightTyre = imagesAtStage?.frontRightTyre; //?? DEFAULT_TRACTOR_IMAGE;

      const fuelInjectionPumpPlate = imagesAtStage?.fuelInjectionPumpPlate; //?? DEFAULT_TRACTOR_IMAGE;
      const odometer = imagesAtStage?.odometer; //?? DEFAULT_TRACTOR_IMAGE;
      const chassis = imagesAtStage?.chassisNumber; //?? DEFAULT_TRACTOR_IMAGE;
      const engine = imagesAtStage?.engineNumber; //?? DEFAULT_TRACTOR_IMAGE;
      const selfie = currentLead?.deliveredSelfieUrl;

      if (item.value === ImageStage.DeliverySelfie) {
        const selfie = currentLead?.deliveredSelfieUrl;

        return {
          stage: titleCaseToReadable(item?.value),
          images: [
            {
              label: 'Delivery Selfie',
              imgPath: selfie
            }
          ]
        };
      } else if (item.value === ImageStage.TransferDropSelfie) {
          // Step 1: Reverse the array
        const reversedArray = currentLead?.internalTransfers?.length
        ? [...currentLead?.internalTransfers].reverse()
        : []

        // Step 2: Find an element based on a condition
      const targetElement = reversedArray.find(element => element?.selfieUrl)
      const latestDriverSelifieForInternalTransfer = targetElement?.selfieUrl
        // const lastIndex:number = currentLead?.internalTransfers?.length  - 1 
        // const transeferSelfie =
        //   currentLead?.internalTransfers?.[lastIndex]?.selfieUrl;
        return {
          stage: titleCaseToReadable(item?.value),
          images: [
            {
              label: 'Transfer Selfie',
              imgPath: latestDriverSelifieForInternalTransfer
            }
          ]
        };
      }else if (item.value === ImageStage.DeliveryImage) {
        const deliveryPhotoUrl =
          currentLead?.activeBooking?.deliveryPhotoUrl;
        return {
          stage: titleCaseToReadable(item?.value),
          images: [
            {
              label: 'Delivery Photo',
              imgPath: deliveryPhotoUrl
            }
          ]
        };
      }
      return {
        stage: titleCaseToReadable(item?.value),
        images: [
          {
            label: 'Front Body',
            imgPath: frontBody
          },
          {
            label: 'Left Body',
            imgPath: leftBody
          },
          {
            label: 'Back Body',
            imgPath: backBody
          },
          {
            label: 'Right Body',
            imgPath: rightBody
          },
          {
            label: 'Back Left Tyre',
            imgPath: backLeftTyre
          },
          {
            label: 'Back Right Tyre',
            imgPath: backRightTyre
          },
          {
            label: 'Front Left Tyre',
            imgPath: frontLeftTyre
          },
          {
            label: 'Front Right Tyre',
            imgPath: frontRightTyre
          },
          {
            label: 'Fuel Injection Pump Plate',
            imgPath: fuelInjectionPumpPlate
          },
          {
            label: 'Odometer',
            imgPath: odometer
          },
          {
            label: 'Chassis',
            imgPath: chassis
          },
          {
            label: 'Engine',
            imgPath: engine
          }
        ]
      };
    });

    setImages(image);
    if (image?.length) {
      setStageValue(image[0]);
    }
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 1050, flexGrow: 1, margin: 2 }}>
      <Autocomplete
        size="small"
        options={images.length ? images : []}
        value={stageValue}
        getOptionLabel={(option) => option?.stage}
        onChange={(e, newValue) => {
          setActiveStep(0)
          setStageValue(newValue);
        }}
        sx={{ width: 300, marginBottom: 1 }}
        renderInput={(params) => <TextField {...params} label="Select Stage" />}
      />
      {images.length ? (
        <Fragment>
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            enableMouseEvents
          >
            {stageValue?.images.map((step, index) => {
              console.log(step.imgPath, 'imgPath');
              return (
                <div
                  key={step.label}
                  style={{
                    background: '#f2f5f9',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        height: 355,
                        display: 'block',
                        // maxWidth: 1050,
                        overflow: 'hidden'
                        // width: '100%',
                      }}
                      src={step.imgPath ? step.imgPath : '/logo.png'}
                      alt={step.label}
                    ></Box>
                  ) : null}
                </div>
              );
            })}
          </AutoPlaySwipeableViews>
          <Paper
            square
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 2,
              bgcolor: 'background.default',
              justifyContent: 'center'
            }}
          >
            <Typography variant="h4">
              {stageValue?.images?.[activeStep].label}
            </Typography>
          </Paper>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Fragment>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '200px'
          }}
        >
          <Typography>Vehicle images not uploaded</Typography>
        </div>
      )}
    </Box>
  );
}
