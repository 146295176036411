import React, { Dispatch, useEffect, useRef, useState } from 'react';
import CtaButton from '../CTA_Button/CtaButton';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  tableCellClasses
} from '@mui/material';
import Input from 'src/components/Form/Input';

import { useFormik } from 'formik';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import EditIcon from '@mui/icons-material/Edit';
import { LeadCreateAndUpdateProps } from 'src/types';
import {
  IssueRef,
  LeadRef,
  PurchaseRef,
  RefurbishmentItemRef,
  RefurbishmentStatus,
  useGetCenterFormLeadQuery,
  useGetStocksDetailsLazyQuery,
  useRefurbishmentRequestDetailsQuery
} from 'src/generated/hooks_and_more';
import { ActionType } from 'src/constants/constant';
import { LeadFlowFormsInputs, titleCaseToReadable } from 'src/utils/utility';
import { CheckAll } from 'react-bootstrap-icons';
import { useReactiveVar } from '@apollo/client';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
function RefurbishmentRequest(props: LeadCreateAndUpdateProps) {
  const {
    toggleDrawer,
    leadId,
    regNo,
    desireStatus,
    desireButtonText,
    undesireButtonText,
    canGoBack,
    onPressOfDesiredOrUndesiredButton,
    refurbishmentRequestId
  } = props;
  const [refurbishmentList, setRefurbishmentList] = useState([]);
  const [selectedItems, setSelectedItems]: any = useState({
    selected: 0,
    sumAmount: 0
  });
  const [getStocks, { data: stockDetailData, loading: stockLoading }] =
    useGetStocksDetailsLazyQuery();
  const [currIndex, setIndex] = useState(null);
  const newRef = useRef(null);
  useEffect(() => {
    ['mousedown', 'keypress'].forEach((event) =>
      document.addEventListener(event, handleOutsideClick)
    );
    return () => {
      ['mousedown', 'keypress'].forEach((event) =>
        document.removeEventListener(event, handleOutsideClick)
      );
    };
  });
  const handleOutsideClick = (e) => {
    if (
      (newRef.current && !newRef.current.contains(e.target)) ||
      e?.key === 'Enter'
    ) {
      handleEdit('ok', currIndex);
    }
  };

  function getSparePartStockStatus(value: string) {
        const sparePartStockData = stockDetailData?.inventoryDetails?.items?.find(
      (i) =>{
        if ('OTHERS' === titleCaseToReadable(value)?.toUpperCase()) {
          return 0;
        } else {
          return (
            i?.product?.name?.toUpperCase() ===
            titleCaseToReadable(value)?.toUpperCase()
          );
        }
      }
       
      
    );

    return {
      isInStock: 
        sparePartStockData?.availableInStock > 0
          ? sparePartStockData?.availableInStock
          : 0,
      avgUnitPrice: sparePartStockData?.avgUnitPrice
    };
  }


  function getSparePartStockcheck(value: string, index?: number) {
    const sparePartStockData = stockDetailData?.inventoryDetails?.items?.find(
      i =>
        i?.product?.name?.toUpperCase() ===
        titleCaseToReadable(value)?.toUpperCase(),
    )
    return {
      isInStock:
        sparePartStockData?.availableInStock >= index + 1 &&
        sparePartStockData?.product?.name !== 'Others'
    }
  }
  const { data: centerData } = useGetCenterFormLeadQuery({
    variables: {
      leadId: leadId
    },
    onCompleted(data) {
      getStocks({
        variables: { centreId: data?.getLead?.centre?.id },
        onCompleted(data) {}
      });
    }
  });

  const { data: refurbishmentData, loading } =
    useRefurbishmentRequestDetailsQuery({
      variables: { leadId: leadId },
      onCompleted(data) {
        const result = [];

        data?.queryLead?.[0]?.refurbishmentDetails?.requests
          ?.find((req) => req?.id === refurbishmentRequestId)
          ?.items.map((i, index) => {
            result.push({
              ...i,
              price: Boolean(i?.price)
                ? i?.price?.toString()
                : getSparePartStockStatus(i?.product?.name)?.avgUnitPrice,
      
              checked: false,
              edit: false,
              description: i?.description,
              instockCheck: getSparePartStockcheck(i?.product?.name, index)

            });
          });
        setRefurbishmentList([...result]);
      }
    });

  useEffect(() => {
    const result = [];
    refurbishmentData?.queryLead?.[0]?.refurbishmentDetails?.requests
      ?.find((req) => req?.id === refurbishmentRequestId)
      ?.items.map((i, index) => {
        result.push({
          ...i,
          priceCheck: Boolean(i?.price)
            ? null
            : getSparePartStockStatus(i?.product?.name)?.avgUnitPrice,
          checked: false,
          edit: false,
          instockCheck: getSparePartStockcheck(i?.product?.name, index)

        });
      });

    setRefurbishmentList([...result]);
  }, [refurbishmentData, getStocks, stockDetailData]);

  console.log(refurbishmentList, "getSparePartStockStatus")

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkAll = [];

    if (event.target.value === 'select all') {
      let count = selectedItems?.selected;
      let totalAmount = selectedItems?.sumAmount;
      refurbishmentList?.map((i) => {
        if (event.target.checked) {
          count = refurbishmentList.length;
        } else {
          count -= 1;
        }

        checkAll.push({
          ...i,
          checked: event.target.checked ? true : false
        });
      });

      if (
        refurbishmentList.length !== selectedItems?.selected ||
        !event.target.checked
      ) {
        setSelectedItems({
          selected: count < 0 ? 0 : count,
          sumAmount: totalAmount < 0 ? 0 : totalAmount
        });
      }
    } else {
      let count = selectedItems?.selected ?? 0;
      let totalAmount = selectedItems?.sumAmount;
      refurbishmentList?.map((i, index) => {
        if (index === Number(event.target.value)) {
          if (event.target.checked) {
            count += 1;
          } else {
            count -= 1;
          }
          checkAll.push({
            ...i,
            checked: event?.target?.checked ? true : false
          });
        } else {
          checkAll.push(i);
        }
      });
      setSelectedItems({
        selected: count < 0 ? 0 : count,
        sumAmount: totalAmount < 0 ? 0 : totalAmount
      });
    }
    setRefurbishmentList([...checkAll]);
  };

  let leadInput: LeadRef;
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      let issueItems = refurbishmentList
        ?.filter((item) => item?.checked)
        ?.map((item) => {
          return {
            price:
              Boolean(item?.product?.name) && !Boolean(item?.price)
                ? Number(item?.priceCheck)
                : null,

            product: {
              name: item?.product?.name
            },
            description: item?.description ?? null,
            quantity: 1,
            isApproved: item?.checked,
            requestedItemId: item?.id

          };
        });
      let purchaseItems = refurbishmentList
        ?.filter(
          (item) =>
            item?.checked &&
            !Boolean(getSparePartStockStatus(item?.product?.name).isInStock)
        )
        ?.map((item) => {
          return {
            price: null,
            approvedPriceLimit: Boolean(
              getSparePartStockStatus(item?.product?.name).isInStock
            )
              ? null
              : Number(item?.price),
            product: {
              name: item?.product?.name
            },
            description: item?.description ??  null,
            quantity: 1,
            isApproved: item?.checked,
            requestedItemId: item?.id

          };
        });

      let requestItems = refurbishmentList
        ?.filter((item) => item?.checked)
        ?.map((item) => {
          const check = getSparePartStockStatus(item?.product?.name);
          return {
            id: item?.id,
            price:
              check?.isInStock > 0
                ? Number(check?.avgUnitPrice)
                : Number(item?.price),
            // price:
            //   Boolean(item?.product?.name) && !Boolean(item?.price)
            //     ? Number(item?.priceCheck)
            //     : Number(item?.price),
            product: {
              name: item?.product?.name
            },
          };
        });

      leadInput = {
        id: leadId,
        regNo: regNo,
        refurbishmentDetails: {
          requests: [
            {
              id: refurbishmentRequestId,
              items: requestItems as RefurbishmentItemRef[],
              issue: {
                items: issueItems as IssueRef[]
              },
              purchase: { items: purchaseItems as PurchaseRef[] }
            }
          ]
        }
      };

            onPressOfDesiredOrUndesiredButton(leadInput);
    }
  });
  const reject = () => {
    let leadInput: LeadRef = {
      regNo: regNo,
      id: leadId,
      refurbishmentDetails: {
        requests: [
          {
            id: refurbishmentRequestId
          }
        ]
      }
    };
    onPressOfDesiredOrUndesiredButton(leadInput, '', ActionType.UNDESIRED);
  };
  const formInputs = useReactiveVar(LeadFlowFormsInputs);

  const handleEdit = (action, itemPosition) => {
    let mutatedList = [];
    refurbishmentList?.map((i, index) => {
      if (index === itemPosition) {
        mutatedList.push({
          ...i,
          edit: action === 'edit' ? true : false
        });
      } else {
        mutatedList.push({ ...i, edit: false });
      }
    });
    setRefurbishmentList([...mutatedList]);
    setIndex(itemPosition);
  };

  const inputChange = (value, itemPosition) => {
    let mutatedList = [];
    refurbishmentList?.map((i, index) => {
      if (selectedItems && i?.checked) {
        setSelectedItems({
          ...selectedItems,
          sumAmount: Number(selectedItems?.[i]?.sumAmount) + Number(value)
        });
      }

      if (index === itemPosition) {
        mutatedList.push({
          ...i,
          price: Number(value)
        });
        mutatedList.map((i) => {});
      } else {
        mutatedList.push({ ...i });
      }
    });
    setRefurbishmentList([...mutatedList]);
  };
  const handleSelected = Boolean(
    refurbishmentList.every((i) => i.checked == true)
  );

  const [edit, setEdit] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const valueInput = refurbishmentList?.filter(
    (i) => i?.price !== null && i.checked === true && i?.instockCheck?.isInStock === false
  );

  const sumOutput = valueInput.reduce((accumulator, currentValue) => {
    return accumulator + (Number(currentValue?.price) || 0);
  }, 0);

  useEffect(() => {
    setEdit((allValue) => [...allValue, sumOutput]);
  }, [sumOutput]);

  useEffect(() => {
    LeadFlowFormsInputs({
      ...formInputs,
      totalAmount: sumOutput,
      totalCount: selectedItems?.selected
    });
  }, [sumOutput, selectedItems?.selected]);

  if (stockLoading) {
    return (
      <div className="d-flex flex-column height-100 justify-content-center align-items-center gap-4">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
        <h5>Loading..</h5>
      </div>
    );
  } else {
    return (
      <form onSubmit={formik.handleSubmit}>
        <TableContainer
          sx={{ mt: 4, backgroundColor: '#f6f6f6' }}
          component={Paper}
        >
          <Table>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableRow
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={handleSelected}
                      onChange={handleChange}
                      value={'select all'}
                      color="primary"
                    />
                    <p className="fw-bolder" style={{ fontSize: '9px' }}>
                      Select all
                    </p>
                  </TableCell>
                  <TableCell className="fw-bolder" align="left" width="250px">
                    Item Type
                  </TableCell>
                  <TableCell className="fw-bolder" align="left" width="100px">
                    Amount
                  </TableCell>
                  <TableCell className="fw-bolder" align="left">
                    View
                  </TableCell>
                </TableRow>
                {refurbishmentList?.map((item, index) => (
                  <>
                    <TableRow
                      key={item?.product?.id}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={item.checked}
                          onChange={handleChange}
                          value={index}
                          color="primary"
                        />
                      </TableCell>
                      
                      <TableCell align="left" width="250px">
                        <h6>{item?.product?.name} </h6>
                        <p style={{ fontSize: '0.9rem', fontWeight: '400' }}>{item?.description}</p>
                        <p style={{ fontSize: '10px' }}>
                          {getSparePartStockStatus(item?.product?.name)
                            .isInStock <= 0
                            ? 'Out Of Stock'
                            : 'In Stock'}
                        </p>
                      </TableCell>
                      <TableCell
                        align="left"
                        width="100px"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        {!item?.edit ? (
                          <>
                            <p>
                              {getSparePartStockStatus(item?.product?.name)
                                .isInStock <= 0
                                ? item?.price
                                : '-'}
                            </p>
                            {getSparePartStockStatus(item?.product?.name)
                              .isInStock <= 0 ? (
                              <EditIcon
                                fontSize="small"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleEdit('edit', index)}
                              />
                            ) : null}
                          </>
                        ) : (
                          <Box
                            ref={newRef}
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              style={{ width: '90px' }}
                              placeholder=""
                              onChange={(e) =>
                                inputChange(e.target.value, index)
                              }
                              value={Number(item?.price) ?? 0}
                            ></input>
                            <CheckBoxRoundedIcon
                              style={{ marginLeft: '0.1rem' }}
                              fontSize="medium"
                              color="success"
                              values=""
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleEdit('ok', index)}
                            />
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Link
                          href={item?.refurbishmentProofUrl}
                          target="_blank"
                        >
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Grid sx={{ mt: 5 }} item xs={12} md={6}>
          <Input placeholder="Enter the remarks" />
        </Grid>

        {selectedItems?.selected || selectedItems?.sumAmount ? (
          <Box
            sx={{
              marginTop: '100px',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <Typography variant="h4">
              {`(${selectedItems?.selected})`}items Selected
            </Typography>
            <Typography variant="h4">
              {Number(sumOutput) >= 0 ? Number(sumOutput) : 0}
            </Typography>
          </Box>
        ) : null}
        <CtaButton
          desireButtonText={desireButtonText}
          undesireButtonText={undesireButtonText}
          onlyRed={true}
          disabled={!!Boolean(selectedItems?.selected == 0)}
          reject={reject}
        />
      </form>
    );
  }
}

export default RefurbishmentRequest;

















