import { getYearFromDate, titleCaseToReadable } from '../../utils/utility';

import { TableCell, TableRow, Typography } from '@mui/material';
import { Lead, LeadPreviewFragment } from '../../generated/hooks_and_more';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';

type CenterLeadProps = {
  centerLead: any;
  index: number;
};

function CenterLead(props: CenterLeadProps) {
  const { centerLead, index } = props;

  const nav = useNavigate();

  const source = titleCaseToReadable(centerLead?.source ?? '-');
  const make = centerLead?.vehicle?.vehicleBrand?.name ?? '-';
  const year = centerLead?.vehicle?.manufacturingDate
    ? getYearFromDate(centerLead?.vehicle?.manufacturingDate ?? '-')
    : '-';
  const createdAt = centerLead?.createdAt;
  const lastEvent =
    centerLead?.statusEvents &&
    centerLead?.statusEvents?.length &&
    centerLead?.statusEvents;

  const lastEventUpdatedDate =
    lastEvent && lastEvent.length && lastEvent[0]?.createdAt;

  const currentStatus = lastEvent
    ? titleCaseToReadable(lastEvent?.[0]?.status ?? '-')
    : '-';



  const allotedCenter = centerLead?.centre?.name ?? '-';
  const deliveryCenter = centerLead?.deliveryCentre?.name ?? '-';
 
  const pendingOn = titleCaseToReadable(lastEvent?.[0]?.assignTaskTo) ?? '-';

  return (
    <TableRow
      hover
      onClick={() => nav(`/vms-admin/leads/${centerLead.regNo}`)}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell title={centerLead?.regNo ?? ''} sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {centerLead?.regNo ?? '-'}
        </Typography>
      </TableCell>
      <TableCell title={currentStatus} sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {currentStatus ?? '-'}
        </Typography>
      </TableCell>

      <TableCell title={pendingOn} sx={{ maxWidth: '180px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {pendingOn ?? '-'}
        </Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: '170px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {source}
        </Typography>
      </TableCell>

      <TableCell title={make ?? ''} sx={{ maxWidth: '100px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {make}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '140px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {centerLead?.vehicle?.vehicleModel?.name ?? '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '100px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {year ?? '-'}
        </Typography>
      </TableCell>
      <TableCell
        title={centerLead?.createdBy?.name ?? ''}
        sx={{ maxWidth: '160px' }}
      >
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {centerLead?.createdBy?.name ?? '-'}
        </Typography>
      </TableCell>
      

      <TableCell sx={{ maxWidth: '160px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {allotedCenter ?? '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '160px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {deliveryCenter ?? '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '120px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {!!createdAt ? format(Date.parse(createdAt), 'dd-MM-yyyy') : '-'}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '120px' }}>
        <Typography
          variant="body1"
          fontSize="13px"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {!!lastEventUpdatedDate
            ? format(Date.parse(lastEventUpdatedDate), 'dd-MM-yyyy')
            : '-'}
        </Typography>
      </TableCell>
   
    
     
    </TableRow>
  );
}

export default CenterLead;





 
     
   