import { useState, useEffect, useRef } from 'react';
import { isOverflowing } from 'rsuite/esm/DOMHelper';

type UseIsOverflowReturnType = {
  ref: React.RefObject<HTMLElement>;
  isOverflow: boolean;
};

const useIsOverflow = (ref): UseIsOverflowReturnType => {

  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (ref?.current) {
        const { scrollWidth, clientWidth } = ref?.current;
        setIsOverflow(scrollWidth > clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check initial overflow state

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isOverflow, ref };
};


export default useIsOverflow