import React from 'react';

import {
  getDesireDateFormate,
  getStatus,
  getYearFromDate,
  titleCaseToReadable
} from '../../utils/utility';

import { TableCell, TableRow, Typography } from '@mui/material';
import {
  CenterPreviewFragment,
  Centre,
  ItemInventoryDetails
} from '../../generated/hooks_and_more';
import { useNavigate } from 'react-router-dom';
import { TableCellProps } from '@material-ui/core';
import routeConstant from 'src/constants/routeContant';

type StockListRowProps = {
  stockData?: Partial<ItemInventoryDetails>;
  index?: number;
  centreId: string;
};

type RowItemsType = {
  value: string | number;
  align?: TableCellProps['align'];
};

function StockListRow(props: StockListRowProps) {
  const { stockData, index, centreId } = props;

  const rowItems: RowItemsType[] = [
    { value: stockData?.product?.name },
    { value: stockData?.totalPurchase ?? 0 },
    { value: stockData?.availableInStock },
    { value: stockData?.consumed },
    // { value: stockData?.purchaseQueue,  },
    { value: stockData?.avgUnitPrice },
    {
      value:
        stockData?.totalPurchase && stockData?.avgUnitPrice
          ? (
              Number(stockData?.totalPurchase) * Number(stockData?.avgUnitPrice)
            ).toFixed()
          : ''
    },
    {
      value: stockData?.createdAt
        ? getDesireDateFormate(stockData?.createdAt)
        : '-'
    }
    // {
    //   value: stockData?.updatedAt
    //     ? getDesireDateFormate(stockData?.updatedAt)
    //     : '-'
    // }
  ];

  const navigate = useNavigate();

  return (
    <TableRow
      hover
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        navigate(
          `${routeConstant.INVENTORY}/${routeConstant.CENTER}/${centreId}/stock/${stockData.id}`,
          {
            state: stockData.id
          }
        );
      }}
    >
      {rowItems.map((item, index) => {
        return (
          <TableCell align={item.align ?? 'left'} key={index}>
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
              noWrap
            >
              {item.value ?? '-'}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default StockListRow;
