import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { LoadingButton } from '@mui/lab';

type exportProps = {
  csvData?: any;
  fileName: string;
  callQuery?: any;
  loading?: any;
  reset?: any;
};
export const ExportCSV = ({
  csvData,
  fileName,
  callQuery,
  loading,
  reset
}: exportProps) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const colWidths = [
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 }
    ];
    ws['!cols'] = colWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const expport = () => {
    callQuery();
  };
  if (csvData?.length && !loading) {
    exportToCSV(csvData, fileName);
    reset();
  }
  return (
    <LoadingButton
      onClick={(e) => {
        expport();
      }}
      sx={{ mt: 0 }}
      fullWidth
      variant="contained"
      type="submit"
      loading={loading}
      loadingPosition="start"
    >
      Export
    </LoadingButton>
  );
};
