import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import Input from 'src/components/Form/Input';

function AddStock() {
  const initialValues = {
    name: '',
    state: '',
    district: '',
    pincode: '',
    inventoryCount: '',
    stockCount: '',
    status: '',
    centerSpocEmail: '',
    centerSpocName: '',
    centerSpocContact: '',
    lat: '',
    long: ''
  };

  return (
    <Box sx={{ mt: 3 }} display={'flex'} justifyContent={'center'}>
      <Box width={'80%'}>
        <Card>
          <Typography sx={{ my: 1, mx: 2 }} variant="h3">
            Add Center
          </Typography>
          <Divider />
          <CardContent>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Input
                          value={values.state}
                          onChange={handleChange}
                          placeholder="State"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          value={values.district}
                          onChange={handleChange}
                          placeholder="District"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          placeholder="Pincode"
                          value={values.pincode}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          placeholder="Inventory Count"
                          value={values.inventoryCount}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          placeholder="Stock Count"
                          value={values.stockCount}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Input
                          placeholder="Center SPOC Contact"
                          value={values.centerSpocContact}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          placeholder="Center SPOC Email"
                          value={values.centerSpocEmail}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          placeholder="Center SPOC Name"
                          value={values.centerSpocName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          placeholder="Lat"
                          value={values.lat}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Input
                          placeholder="Long"
                          value={values.long}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
            <Divider sx={{ my: 3 }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                variant="contained"
                // onClick={() => {
                //   navigate(
                //     `${routeConstant.INVENTORY}/${routeConstant.ADD_CENTER}`
                //   );
                // }}
              >
                Add Center
              </Button>
            </div>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default AddStock;
