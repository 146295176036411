export enum Environments {
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
  LOCALHOST = 'LOCALHOST'
}

const environments: Environments = Environments.PRODUCTION;

export { environments };
