import React from 'react';
import {
  GetLeadDealDetailsDocument,
  GetLeadDetailDocument,
  LeadStatus,
  LeadStatusEventDetailsFragmentDoc,
  ListingStatus,
  MyPendingTasksDocument,
  NumberOfPendingTasksDocument,
  PaymentFor,
  useCreateLeadStatusEventMutation,
  useMarkAndSoldLazyQuery,
  useRefurbishmentRequestIdQuery
} from '../../../generated/hooks_and_more';
import useLoggedInUser from 'src/customHooks/useLoggedInUser';
import { getScopedEventMetadata, titleCaseToReadable } from 'src/utils/utility';
import { useToasts } from 'react-toast-notifications';
import { useLocation, useNavigate } from 'react-router';
import { environments } from 'src/config';

function useCreateLeadStatusEvents(
  toggleDrawer,
  leadId = null,
  lseId = null,
  regNo = null,
  refetch = () => {},
  hasModalPopup = false
) {
  const { data: leadDetailsData } = useRefurbishmentRequestIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      leadId: leadId
    }
  });

  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const [createLeadStatusEvent, { loading: addingEvent }] =
    useCreateLeadStatusEventMutation();
  const { loggedInUser } = useLoggedInUser();
  const [getTheMarkAndSold, { data: soldData, loading: soldLoading }] =
    useMarkAndSoldLazyQuery();

  async function listVehicleOnCertifiedTractorList() {
    try {
      getTheMarkAndSold({
        variables: {
          regNo: regNo,
          env: environments
        },
        onCompleted(data) {
          addToast('Vehicle mark sold on website successfully.', {
            appearance: 'success',
            autoDismiss: true
          });
        },
        onError(err) {
          addToast('Failed to mark sold vehicle on website', {
            appearance: 'success',
            autoDismiss: true
          });
        }
      });
    } catch (error) {
      addToast(
        'Error occurred while mark vehicle as sold to website, Contact tech@tractorjunction.com.',
        {
          appearance: 'success',
          autoDismiss: true
        }
      );
      console.error('Error occurred while sending the POST request:', error);
    }
  }

  function onPressDesiredButton(
    desiredStatus,
    leadInput,
    depricatedLead = null,
    remark = '',
    hasModalPopup = false
  ) {
    let stepsToSkip = desiredStatus;

    let hasNoStock = true;
    if (LeadStatus.RefurbishmentApproved == desiredStatus) {
      hasNoStock =
        leadInput?.refurbishmentDetails?.requests[0]?.purchase?.items?.length >
        0;
      stepsToSkip = hasNoStock
        ? desiredStatus
        : LeadStatus.InstallationPaymentRequestRaisedSkip;
    }

    // console.log(
    //   'pressed desired button should take to status:',
    //   getScopedEventMetadata(lseId?.lseId, leadDetailsData, desiredStatus) ??
    //     null
    // );
    // if (buttonEnable) {
    const searchString = [
      leadInput?.regNo,
      titleCaseToReadable(leadInput?.vehicle?.vehicleBrand?.name ?? ''),
      leadInput?.vehicle?.vehicleModel?.name,
      leadInput?.vehicle?.manufacturingDate
    ].join(',');
    const remarks = remark ? remark?.trim() : null;
    const leadId = leadInput?.id ?? null;
    const myId = loggedInUser.userId;
    const regNo = leadInput?.regNo;
    console.log(desiredStatus, 'desiredStatus');
    createLeadStatusEvent({
      refetchQueries: [
        { query: GetLeadDetailDocument, variables: { regNo } },
        { query: GetLeadDealDetailsDocument, variables: { regNo } },
        {
          query: MyPendingTasksDocument,
          variables: { myId, role: loggedInUser?.role }
        },
        {
          query: NumberOfPendingTasksDocument,
          variables: { myId, role: loggedInUser?.role }
        }
      ],
      variables: {
        createdAt: new Date(),
        myId: myId as string,
        //TODO: Typecasting patch
        status: stepsToSkip,
        remarks: remarks,
        metadata:
          getScopedEventMetadata(lseId, leadDetailsData, desiredStatus) ?? null,
        lead:
          desiredStatus === LeadStatus.LeadGenerated
            ? {
                ...leadInput,
                regNo: leadInput?.regNo?.toUpperCase(),
                vehicle: {
                  ...leadInput?.vehicle,
                  regNo: leadInput?.regNo?.toUpperCase()
                },
                searchString,
                createdBy: { id: myId },
                createdAt: new Date()
              }
            : {
                ...leadInput,
                // Always pass lead Id except when generating a new lead
                id: leadId,
                regNo: regNo,
                // createdBy: {id: myId},
                updatedAt: new Date()
              }
      },
      onError: (error) => {
        console.log('error creating lead status event', error);

        addToast(`Error occurred`, {
          appearance: 'error',
          autoDismiss: true
        });
      },
      update: (cache, { data }) => {
        // add this lead status event at the beginning
        if (data?.addLeadStatusEvent?.leadStatusEvent) {
          const newLSERef = cache.writeFragment({
            id: `LeadStatusEvent:${data?.addLeadStatusEvent?.leadStatusEvent[0].id}`,
            data: data?.addLeadStatusEvent?.leadStatusEvent[0],
            fragment: LeadStatusEventDetailsFragmentDoc,
            fragmentName: 'LeadStatusEventDetails'
          });
          cache.modify({
            id: `Lead:${data?.addLeadStatusEvent?.leadStatusEvent[0]?.lead?.id}`,
            fields: {
              statusEvents(existingRefs = []) {
                return [...existingRefs, newLSERef];
              }
            }
          });
        }
      },
      onCompleted: ({ addLeadStatusEvent }) => {
        toggleDrawer();

        if (leadInput.id) {
          navigate(location.pathname, {
            replace: true,
            state: {
              lseId: addLeadStatusEvent.leadStatusEvent[0].id
            }
          });
        }
        if (!hasModalPopup) {
          addToast(`${titleCaseToReadable(desiredStatus)} Successfully`, {
            appearance: 'success',
            autoDismiss: true
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          addToast(`${titleCaseToReadable(desiredStatus)} Successfully`, {
            appearance: 'success',
            autoDismiss: true
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        if (
          leadDetailsData?.queryLead?.[0]?.listingStatus ===
            ListingStatus.ListedOnWebsite &&
          addLeadStatusEvent.leadStatusEvent[0].status ===
            LeadStatus.DeliveryPhotoUploaded
        ) {
          listVehicleOnCertifiedTractorList();
        }

        //   log(
        //     'event fired metadata',
        //     addLeadStatusEvent?.leadStatusEvent?.[0]?.metadata,
        //   )
        //   // const requestMetadata = getScopedEventMetadata(
        //   //   addLeadStatusEvent?.leadStatusEvent[0]?.id ?? '',
        //   // )
        //   // const metaData = requestMetadata.split(':')?.[1]
        //   // setRequestId(metaData)
        // if (
        //   addLeadStatusEvent?.leadStatusEvent?.[0].status ===
        //     LeadStatus.Archive ||
        //   addLeadStatusEvent?.leadStatusEvent?.[0]?.assignTaskTo ===
        //     loggedInUser?.role
        // ) {
        //   if (
        //     Platform.OS === 'android' &&
        //     addLeadStatusEvent?.leadStatusEvent?.[0].status
        //   ) {
        //     const message =
        //       titleCaseToReadable(
        //         addLeadStatusEvent.leadStatusEvent[0].status,
        //       ) + ' successfully'
        //     ToastAndroid.show(message, 3000)
        //   }
        //   if (navigation.canGoBack()) navigation.goBack()
        // } else if (!!addLeadStatusEvent?.leadStatusEvent) {
        //   newLeadId.current = addLeadStatusEvent.leadStatusEvent[0].lead.id
        //   // log('new lead id', newLeadId.current)
        //   if (Platform.OS === 'android') {
        //     const message =
        //       titleCaseToReadable(
        //         addLeadStatusEvent.leadStatusEvent[0].status,
        //       ) + ' successfully'
        //     ToastAndroid.show(message, 3000)
        //   }
        //   navigation.setParams({
        //     leadId: addLeadStatusEvent.leadStatusEvent[0].lead.id,
        //     regNo: addLeadStatusEvent.leadStatusEvent[0].lead.regNo,
        //     currentStatus: addLeadStatusEvent.leadStatusEvent[0].status,
        //   })
        //   // TODO : It's throwing bugs in some cases saying navigation can't handle it
        //   // navigation.navigate('LeadDetailsScreen', {
        //   //   leadId: addLeadStatusEvent.leadStatusEvent[0].lead.id,
        //   //   regNo: addLeadStatusEvent.leadStatusEvent[0].lead.regNo,
        //   //   currentStatus: addLeadStatusEvent.leadStatusEvent[0].status,
        //   // })
        //   // setLeadInput({id: addLeadStatusEvent.leadStatusEvent[0].lead.id})
        //   // setVisible(true);
        //   // console.log(
        //   //   `setParams => ${JSON.stringify(
        //   //     addLeadStatusEvent.leadStatusEvent[0],
        //   //   )}`,
        //   // )
        // }
        // setLeadInput({})
        // setRemarks(null)
        // if (!canGoForward) {
        //   const currentStatus = addLeadStatusEvent.leadStatusEvent[0].status
        //   const lse = addLeadStatusEvent.leadStatusEvent[0].id
        //   !!onGoBack && onGoBack(lse, currentStatus)
        //   requestAnimationFrame(() => {
        //     navigation.goBack()
        //     // navigation.navigate('ViewNotificationsDetails', {
        //     //   screen: 'LeadDetailsScreen',
        //     //   params: {
        //     //     leadId: addLeadStatusEvent.leadStatusEvent[0].lead.id,
        //     //     regNo: addLeadStatusEvent.leadStatusEvent[0].lead.regNo,
        //     //     currentStatus: addLeadStatusEvent.leadStatusEvent[0].status,
        //     //     lseId: addLeadStatusEvent.leadStatusEvent[0].id,
        //     //   },
        //     // })
        //     // navigation.navigate('Drawer', {
        //     //   screen: 'Leads',
        //     //   params: {
        //     //     screen: 'LeadDetailsScreen',
        //     //     params: {
        //     //       leadId: addLeadStatusEvent.leadStatusEvent[0].lead.id,
        //     //       regNo: addLeadStatusEvent.leadStatusEvent[0].lead.regNo,
        //     //       currentStatus: addLeadStatusEvent.leadStatusEvent[0].status,
        //     //       lseId: addLeadStatusEvent.leadStatusEvent[0].id,
        //     //     },
        //     //   },
        //     // })
        //   })
        // } else {
        //   navigation.setParams({
        //     leadId: addLeadStatusEvent.leadStatusEvent[0].lead.id,
        //     regNo: addLeadStatusEvent.leadStatusEvent[0].lead.regNo,
        //     currentStatus: addLeadStatusEvent.leadStatusEvent[0].status,
        //     lseId: addLeadStatusEvent.leadStatusEvent[0].id,
        //   })
        // }
      }
    });
    // } else {
    //   console.log('validation not passed should take to respective validation field')
    // }
    // function getScopedEventMetadata(lseId: string) {
    //   if (
    //     !leadDetailsData?.getLead?.statusEvents ||
    //     desiredStatus === LeadStatus.VehicleInspected
    //   ) {
    //     return
    //   } else {
    //     return leadDetailsData?.getLead?.statusEvents?.find(
    //       lse => lse?.id === lseId,
    //     )?.metadata
    //   }
    // }

    // const requestId = getScopedEventMetadata(lseId)?.split(':')?.[1]
  }

  function onPressUndesireButton(
    undesiredStatus,
    leadInput,
    depricatedLead,
    remark = '',
    hasModalPopup = false
  ) {
    const leadId = leadInput?.id ?? null;
    const myId = loggedInUser.userId;
    const regNo = leadInput?.regNo;
    createLeadStatusEvent({
      refetchQueries: [
        // {query: GetLeadDetailsDocument},
        // {query: AllLeadsDocument},
        // {
        //   query: MyPendingTasksDocument,
        //   variables: {myId, role: loggedInUser?.role},
        // },
        // {
        //   query: NumberOfPendingTasksDocument,
        //   variables: {myId, role: loggedInUser?.role},
        // },
      ],
      variables: {
        createdAt: new Date(),
        myId: myId as string,
        //TODO: Typecasting patch
        status: undesiredStatus,
        remarks: remark,
        metadata:
          getScopedEventMetadata(lseId, leadDetailsData, undesiredStatus) ??
          null,
        lead: {
          ...leadInput,
          // Always pass lead Id except when generating a new lead
          id: leadId,
          regNo: regNo,
          // createdBy: {id: myId},
          updatedAt: new Date()
        }
      },
      onError: (error) => {
        addToast(`Error occurred`, {
          appearance: 'error',
          autoDismiss: true
        });
        // captureSentryException('error creating lead status event', error)
        // ToastAndroid.showWithGravity(error.message, ToastAndroid.BOTTOM, 300)
      },
      update: (cache, { data }) => {
        // add this lead status event at the beginning
        if (data?.addLeadStatusEvent?.leadStatusEvent) {
          const newLSERef = cache.writeFragment({
            id: `LeadStatusEvent:${data?.addLeadStatusEvent?.leadStatusEvent[0].id}`,
            data: data?.addLeadStatusEvent?.leadStatusEvent[0],
            fragment: LeadStatusEventDetailsFragmentDoc,
            fragmentName: 'LeadStatusEventDetails'
          });
          cache.modify({
            id: `Lead:${data?.addLeadStatusEvent?.leadStatusEvent[0]?.lead?.id}`,
            fields: {
              statusEvents(existingRefs = []) {
                return [...existingRefs, newLSERef];
              }
            }
          });
        }
      },
      onCompleted: ({ addLeadStatusEvent }) => {
        if (!hasModalPopup) {
          addToast(`${titleCaseToReadable(undesiredStatus)} Successfully`, {
            appearance: 'success',
            autoDismiss: true
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        toggleDrawer();

        if (leadInput.id) {
          navigate(location.pathname, {
            replace: true,
            state: {
              lseId: addLeadStatusEvent.leadStatusEvent[0].id
            }
          });
        }

        if (
          addLeadStatusEvent?.leadStatusEvent?.[0].status ===
            LeadStatus.Archive ||
          addLeadStatusEvent?.leadStatusEvent?.[0]?.assignTaskTo ===
            loggedInUser?.role
        ) {
          const currentStatus = addLeadStatusEvent.leadStatusEvent[0].status;
          const lse = addLeadStatusEvent.leadStatusEvent[0].id;

          requestAnimationFrame(() => {
            // navigation.navigate('ViewNotificationsDetails', {
            //   screen: 'LeadDetailsScreen',
            //   params: {
            //     leadId: addLeadStatusEvent.leadStatusEvent[0].lead.id,
            //     regNo: addLeadStatusEvent.leadStatusEvent[0].lead.regNo,
            //     currentStatus: addLeadStatusEvent.leadStatusEvent[0].status,
            //     lseId: addLeadStatusEvent.leadStatusEvent[0].id,
            //   },
            // })
            // navigation.navigate('Drawer', {
            //   screen: 'Leads',
            //   params: {
            //     screen: 'LeadDetailsScreen',
            //     params: {
            //       leadId: addLeadStatusEvent.leadStatusEvent[0].lead.id,
            //       regNo: addLeadStatusEvent.leadStatusEvent[0].lead.regNo,
            //       currentStatus: addLeadStatusEvent.leadStatusEvent[0].status,
            //       lseId: addLeadStatusEvent.leadStatusEvent[0].id,
            //     },
            //   },
            // })
          });
        } else if (!!addLeadStatusEvent?.leadStatusEvent) {
          // TODO : It's throwing bugs in some cases saying navigation can't handle it
          // navigation.navigate('LeadDetailsScreen', {
          //   leadId: addLeadStatusEvent.leadStatusEvent[0].lead.id,
          //   regNo: addLeadStatusEvent.leadStatusEvent[0].lead.regNo,
          //   currentStatus: addLeadStatusEvent.leadStatusEvent[0].status,
          // })
          // setLeadInput({id: addLeadStatusEvent.leadStatusEvent[0].lead.id})
          // setVisible(true);
          console.log(
            `currentStatus => ${JSON.stringify(
              addLeadStatusEvent.leadStatusEvent[0]
            )}`
          );
        }
      }
    });
  }

  return {
    onPressDesiredButton,
    addingEvent,
    onPressUndesireButton
  };
}

export default useCreateLeadStatusEvents;
