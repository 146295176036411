import { Box, SwipeableDrawer, Button } from '@mui/material';


import { useEffect, useState } from 'react';
import {
    LeadRef,
  LeadStatus,
  PaymentFor,
  useGetLeadDetailQuery,
  useLeadStatusFlowQuery
} from 'src/generated/hooks_and_more';
import { useLocation, useParams } from 'react-router';
import RaisePaymentForEachRequest from '../DealershipForms/raisePaymentforDealership';
import { ActionType } from 'src/constants/constant';
import { useToasts } from 'react-toast-notifications';
import useCreateLeadStatusEvents from '../../hooks/useCreateLeadStatusEvents';
import ConfirmationPopup from './ConfirmPopforPR';

function RaisePaymentButton({payFor, title, open, setOpen, leadId, source, currentStatus, regNo}) {
  // const [open, setOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
//   const { addingEvent, onPressDesiredButton, onPressUndesireButton } =
//     useCreateLeadStatusEvents(toggleDrawer, refetch);

const { addingEvent, onPressDesiredButton, onPressUndesireButton } =
useCreateLeadStatusEvents(toggleDrawer);


  const [showPopup, setShowPopup] = useState(false);
  const [alert, setAlert] = useState(false);

function toggleDrawer(){
        setOpen(true);
}

function handleClose() {
    setShowPopup(!showPopup);
  }
 



  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);
  function getCurrentDimension() {
    return window.innerWidth;
  }

  
  return (
    <div>
      <SwipeableDrawer
        anchor={'right'}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={(e) => setAlert(true)}
        sx={{
          width:
            screenSize >= 600
              ? screenSize / 2
              : screenSize <= 600 && screenSize >= 400
              ? screenSize / 1.5
              : screenSize
        }}
      >
        <div>
        <Button
            color="secondary"
            size="medium"
            style={{
              fontWeight: 600,
              borderRadius: '50%',
              minWidth: 0
            }}
            onClick={() => setOpen(false)}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/507/507257.png"
              height={20}
              width={20}
            ></img>
          </Button>

        <Box
          style={{
            padding: '20px 50px 20px 50px',
            position: 'relative'
          }}
          sx={{
            width:
              screenSize >= 600
                ? screenSize / 2
                : screenSize <= 600 && screenSize >= 400
                ? screenSize / 1.5
                : screenSize
          }}
        >
         
         <RaisePaymentForEachRequest
            toggleDrawer={() => {
              setOpen(true);
            }}
            regNo={regNo}
            desireButtonText={"Raise Payment Request"}
            source={source}
            leadId={leadId}
            desireStatus={currentStatus}
            paymentFor={payFor}
          />
        </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default RaisePaymentButton;
